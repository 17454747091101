import React, { useContext, useEffect, useState } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { countryCodeEmoji } from 'country-code-emoji';

import DetailPageTitle from '../../../atomicComponents/DetailPageTitle';
import delIcon from '../../../assets/icons/del.svg';
import copyIcon from '../../../assets/icons/copy.svg';
import API from '../../../libs/axios';
import { toaster } from '../../../utils/toaster';
import { onError } from '../../../libs/errorLib';
import DetailText from '../../../atomicComponents/DetailText';
import { SharedContext } from '../../../utils/common';
import InvoiceDropdown from '../../../components/InvoiceDropdown';
import { ImagesList } from '../invoiceManagement/Payment';
import { constants } from '../invoiceManagement/constants';

export const formatPrice = (val) =>
  parseFloat(parseFloat(val).toFixed(2)).toLocaleString('en-US', {
    useGrouping: true
  }) || 0.0;

// const CompanyDropDown = ({ fieldText, onSelect, id }) => {
//   const [companies, setCompanies] = useState([]);
//   const { setAPILoader } = useContext(SharedContext);

//   const getCompanies = async (search) => {
//     try {
//       if (search) {
//         const companyData = await API.get(`companies`, { params: { search } });
//         setCompanies(companyData?.data?.rows);
//       } else {
//         const companyData = await API.get(`companies`);
//         setCompanies(companyData?.data?.rows);
//       }
//     } catch (err) {
//       onError(err);
//     } finally {
//       setAPILoader(false);
//     }
//   };

//   useEffect(() => {
//     getCompanies();
//   }, []);

//   return (
//     <InvoiceDropdown
//       fieldText={fieldText}
//       defaultText="Company"
//       options={companies}
//       id={id}
//       onSelect={onSelect}
//       fetchOptions={(search) => getCompanies(search)}
//     />
//   );
// };

export default function InvoiceForm(props) {
  const useStyles = makeStyles(() => ({
    table: {
      borderRadius: '10px',
      border: '1px solid #dcdcdc'
    },
    placeholder: {
      color: '#6A7289'
    },
    invoiceDropdown: {
      width: '100%'
    },
    tableCell: {
      paddingLeft: '0.4rem',
      paddingRight: 0
    },
    invFormHeader: {
      fontSize: '14px',
      fontWeight: 500
    },
    bottom: {
      '& .Mui-Focused': {
        border: '1px solid #0C71D7 !important'
      }
    }
  }));
  const classes = useStyles();

  const [arr, setArr] = useState(props.invoiceItems || []);
  const [taxes, setTaxes] = useState([]);
  const [unitTypes, setUnitTypes] = useState(props.unitTypes || []);
  const [invoiceTitles, setInvoiceTitles] = useState([]);
  const { setAPILoader } = useContext(SharedContext);

  const addNew = () => {
    let newEntity = {
      title: {},
      unitType: {},
      unit: 1,
      rate: null,
      tax: {},
      totalAmount: 0
    };

    if (props.type === 'bill') {
      newEntity.company = {};
    }
    setArr([...arr, newEntity]);
    props.setInvoiceItems([...arr, newEntity]);
  };

  const submitTax = async (values) => {
    try {
      const data = {
        name: values.name,
        rate: values.tax
      };
      setAPILoader(true);
      await API.post(`/taxes`, data);
      getTaxes();
      toaster('success', 'Tax has been created successfully');
    } catch (err) {
      onError(err);
      return false;
    } finally {
      setAPILoader(false);
    }
  };

  const getTaxes = async () => {
    try {
      setAPILoader(true);
      const tax = await API.get(`/taxes`);
      setTaxes(
        tax?.taxes.map((tax) => ({
          ...tax,
          name: `${tax.name} (${tax.rate}%)`
        }))
      );
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getInvoiceTitles = async () => {
    try {
      setAPILoader(true);
      const invoiceTitles = await API.get(`/invoice-titles`);
      // const expenseTitles = props.expense.map(e => ({ name: e.name }))
      setInvoiceTitles([
        ...(invoiceTitles?.invoiceTitles || []),
        { name: 'Freight' }
      ]);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const setter = (value, i, title) => {
    const updatedItem = [...arr];
    updatedItem[i] = { ...updatedItem[i], [title]: value };
    setArr(updatedItem);
    props.setInvoiceItems(updatedItem);
  };

  const submitUnitType = async (values) => {
    try {
      const data = {
        name: values.name,
        invoiceType: props.formik.values.invoiceType.toUpperCase()
      };
      setAPILoader(true);
      const unitType = await API.post(`/unit-types`, data);
      setUnitTypes([
        ...unitTypes,
        { ...unitType.unitType, isDeleteable: true }
      ]);
      toaster('success', 'Unit type has been created successfully');
    } catch (err) {
      onError(err);
      return false;
    } finally {
      setAPILoader(false);
    }
  };

  const submitInvoiceTitle = async (values) => {
    try {
      const data = {
        name: values.name
      };
      setAPILoader(true);
      await API.post(`/invoice-titles`, data);
      getInvoiceTitles();
      toaster('success', 'Invoice title has been created successfully');
    } catch (err) {
      onError(err);
      return false;
    } finally {
      setAPILoader(false);
    }
  };

  const deleteUnitType = async (id) => {
    try {
      setAPILoader(true);
      await API.delete(`unit-types/${id}`);
      let updatedUnitTypes = [...unitTypes];
      let updatedInvoiceInvoiceItems = [...props.invoiceItems];
      updatedUnitTypes = unitTypes.filter((type) => type.id != id);
      updatedInvoiceInvoiceItems = updatedInvoiceInvoiceItems.map((item) => {
        if (item?.unitType?.id == id) {
          item.unitType = {};
        }
        return item;
      });
      setUnitTypes(updatedUnitTypes);
      setArr(updatedInvoiceInvoiceItems);
      props.setInvoiceItems(updatedInvoiceInvoiceItems);
      toaster('success', 'Unit type has been deleted successfully');
    } catch (err) {
      onError(err);
      return false;
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    getTaxes();
    getInvoiceTitles();
  }, []);

  const onSelect = (idx, field, value) => {
    const newArr = [...arr];
    newArr[idx][field] = value;
    setArr(newArr);
    props.setInvoiceItems(newArr);
  };

  return (
    <Grid item container className="mt-20">
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <Typography style={{ fontSize: '24px', fontWeight: 600 }}>
            Fill in the invoice form
          </Typography>
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel className="select-input-label">Currency</InputLabel>
            <Select
              variant="outlined"
              value={props.formik?.values?.currency || 'PKR'}
              className="text-left"
              onChange={(e) =>
                props.formik.setFieldValue('currency', e.target.value)
              }
              label="Currency"
            >
              <MenuItem value="PKR">{countryCodeEmoji('PK')} PKR</MenuItem>
              <MenuItem value="AED">{countryCodeEmoji('AE')} AED</MenuItem>
              <MenuItem value="USD">{countryCodeEmoji('US')} USD</MenuItem>
              <MenuItem value="SAR">{countryCodeEmoji('SA')} SAR</MenuItem>
            </Select>
          </FormControl>
          {props.formik.errors['currency'] && (
            <Typography color="error">
              {props.formik.errors['currency']}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} className="mt-10">
        <div className={classes.table}>
          <Table>
            <TableHead className="table-header">
              <TableRow>
                <TableCell className="first-cell">
                  <Typography className={classes.invFormHeader}>
                    Title
                  </Typography>
                </TableCell>
                {/* {props.type === 'bill' && (
                  <TableCell className="first-cell">
                    <Typography className={classes.invFormHeader}>
                      Customer
                    </Typography>
                  </TableCell>
                )} */}
                <TableCell className="table-cell">
                  <Typography className={classes.invFormHeader}>
                    Unit Type
                  </Typography>
                </TableCell>
                <TableCell className="table-cell">
                  <Typography className={classes.invFormHeader}>
                    Unit
                  </Typography>
                </TableCell>
                <TableCell className="table-cell">
                  <Typography className={classes.invFormHeader}>
                    Rate
                  </Typography>
                </TableCell>
                <TableCell className="table-cell">
                  <Typography className={classes.invFormHeader}>Tax</Typography>
                </TableCell>
                <TableCell className="table-cell">
                  <Typography className={classes.invFormHeader}>
                    Total amount{' '}
                    <span style={{ color: '#6A7289' }}>(Tax Exclusive)</span>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {arr?.map((res, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell
                      className={classes.tableCell}
                      style={{ width: props.billing ? '18%' : '25%' }}
                    >
                      <div className={classes.invoiceDropdown}>
                        <InvoiceDropdown
                          fieldText={res?.title}
                          defaultText="Title"
                          options={[...invoiceTitles, { name: 'add' }]}
                          addFieldText="+ New Title"
                          onSubmit={submitInvoiceTitle}
                          onSelect={(value) => onSelect(i, 'title', value)}
                          id={`title-${i}`}
                          addText="Add new Title"
                          fetchOptions={() => {}}
                        />
                      </div>
                    </TableCell>
                    {/* {props.type === 'bill' && (
                      <TableCell
                        className={classes.tableCell}
                        style={{ width: '25%' }}
                      >
                        <div className={classes.invoiceDropdown}>
                          <CompanyDropDown
                            fieldText={res?.company}
                            onSelect={(value) => onSelect(i, 'company', value)}
                            id={`company-${i}`}
                          />
                        </div>
                      </TableCell>
                    )} */}
                    <TableCell
                      className={classes.tableCell}
                      style={{ width: '25%' }}
                    >
                      <div className={classes.invoiceDropdown}>
                        <InvoiceDropdown
                          fieldText={res?.unitType}
                          defaultText="Unit Type"
                          options={[...unitTypes, { name: 'add' }]}
                          addFieldText="+ New Unit"
                          onSubmit={submitUnitType}
                          onSelect={(value) => onSelect(i, 'unitType', value)}
                          id={`unitType-${i}`}
                          addText="Add new Unit type"
                          onDelete={deleteUnitType}
                          fetchOptions={() => {}}
                        />
                      </div>
                    </TableCell>
                    <TableCell style={{ width: props.billing ? '2%' : '3%' }}>
                      <div className="invoiceTitleSection">
                        <div className="invoiceTitle">
                          {res?.unit ? (
                            <DetailText text={res?.unit} />
                          ) : (
                            <span className={classes.placeholder}>Unit</span>
                          )}
                        </div>
                        <input
                          type="number"
                          name="unit"
                          id="unit"
                          min="0"
                          className="invoiceTitleField"
                          onChange={(e) => {
                            setter(e.target.value, i, 'unit');
                          }}
                          key="unit"
                          value={res?.unit}
                          defaultValue={1}
                        />
                      </div>
                    </TableCell>
                    <TableCell style={{ width: '2%' }}>
                      <div className="invoiceTitleSection">
                        <div className="invoiceTitle">
                          {res?.rate ? (
                            <DetailText text={res?.rate} />
                          ) : (
                            <span className={classes.placeholder}>Rate</span>
                          )}
                        </div>
                        <input
                          type="number"
                          name="rate"
                          id="rate"
                          min="0"
                          className="invoiceTitleField"
                          onChange={(e) => {
                            setter(e.target.value, i, 'rate');
                          }}
                          key="rate"
                          value={res?.rate}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ width: props.billing ? '15%' : '20%' }}
                    >
                      <div className={classes.invoiceDropdown}>
                        <InvoiceDropdown
                          fieldText={res?.tax}
                          defaultText="Tax"
                          options={[...taxes, { name: 'add' }]}
                          addFieldText="New Tax"
                          onSubmit={submitTax}
                          onSelect={(value) => onSelect(i, 'tax', value)}
                          type="tax"
                          id={`tax-${i}`}
                          addText="Add new Tax"
                          fetchOptions={() => {}}
                        />
                      </div>
                    </TableCell>
                    <TableCell style={{ width: props.billing ? '20%' : '25%' }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <DetailText
                          text={`${
                            constants.CURRENCY[props.formik.values.currency]
                          }  ${formatPrice(res?.rate * res?.unit)}`}
                        />
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem'
                          }}
                        >
                          <img
                            src={copyIcon}
                            alt="Copy icon"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setArr([...arr, { ...res }]);
                              props.setInvoiceItems([...arr, { ...res }]);
                            }}
                          />
                          <img
                            src={delIcon}
                            alt="Delete icon"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setArr(arr.filter((item, idx) => idx !== i));
                              props.setInvoiceItems(
                                arr.filter((item, idx) => idx !== i)
                              );
                              if (props.isDraft && res.id) {
                                props.setDeletedItems(res.id);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Grid>

      <Grid item xs={12} className="mt-10">
        <div onClick={() => addNew()} className="addNewRow">
          <Typography
            style={{ color: '#0C71D7', cursor: 'pointer', fontWeight: 500 }}
          >
            + New Row
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12} className="mt-20">
        <Grid item xs={12}>
          <DetailPageTitle text="Additional Information" />
        </Grid>
        <Grid item container className="mt-10" spacing={2}>
          <Grid item xs={5}>
            <Typography style={{ fontWeight: 500 }}>Additional Note</Typography>
            <div className={classes.bottom}>
              <TextField
                multiline
                onChange={(e) => props?.handleChange('notes', e.target.value)}
                value={props.formik.values.notes}
                variant="outlined"
                fullWidth
                className="mt-10"
              />
            </div>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={6}>
            <Typography style={{ fontWeight: 500 }}>
              Attach Supporting Documents
            </Typography>
            <div className="mt-10">
              <ImagesList formik={props?.formik} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
