/*eslint-disable*/
import { useContext, useEffect, useState, useCallback } from 'react';
import Button from '../../../core-components/atoms/Button';
import PopupButton from '../../../core-components/molecules/PopupButton';
import { Checkbox } from '@mui/material';
import { SharedContext } from '../../../utils/common';
import columnPreferenceIcon from '../../../assets/icons/columnPreference.svg';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import FLAGS from '../../../constants/featureFlags';

const FixedColumnsList = ({ fixedColumns }) => (
  <div>
    <p className="text-[#9DA3B3] text-xs">Fixed Columns</p>
    <ul className="mt-4">
      {fixedColumns.map((col) => (
        <li key={col} className="mt-4 text-xs font-medium">
          {col}
        </li>
      ))}
    </ul>
  </div>
);

const ColumnsList = ({ columns, checked, onChange }) => (
  <>
    {columns.map((col) => (
      <div className="flex gap-4 items-center mt-4" key={col}>
        <Checkbox
          checked={checked(col)}
          onChange={(e, v) => onChange(col, v)}
        />
        <p className="text-xs font-medium">{col}</p>
      </div>
    ))}
  </>
);

const EditableColumns = ({
  editableColumns = [],
  updatePreference,
  fixedColumns,
  variableColumns
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleUpdatePreference = useCallback(
    (col, value) => updatePreference(col, value),
    [updatePreference]
  );

  return (
    <PopupButton
      open={open}
      setOpen={setOpen}
      btn={
        <Button
          label="Edit Columns"
          className="py-2 px-3 h-8 rounded border-solid"
          labelClass="text-xs font-medium"
          variant="transparent"
          overrideSize
          onClick={handleOpen}
          icon={<img src={columnPreferenceIcon} className="mr-2" />}
        />
      }
      content={
        <div className="p-4 w-[200px]">
          <FixedColumnsList fixedColumns={fixedColumns} />
          <div className="text-center mt-4 mb-4">
            <hr className="m-auto border-[#E1E5F0]" />
          </div>
          <div>
            <p className="text-[#9DA3B3] text-xs">Active Columns</p>
            <ColumnsList
              columns={editableColumns}
              checked={() => true}
              onChange={handleUpdatePreference}
            />
          </div>
          <div className="text-center mt-4 mb-4">
            <hr className="m-auto border-[#E1E5F0]" />
          </div>
          <div>
            <p className="text-[#9DA3B3] text-xs">Available Columns</p>
            <ColumnsList
              columns={variableColumns.filter(
                (col) => !editableColumns.includes(col)
              )}
              checked={() => false}
              onChange={handleUpdatePreference}
            />
          </div>
        </div>
      }
    />
  );
};

export default EditableColumns;
