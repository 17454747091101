/* eslint-disable */
import { GoogleMapsOverlay as DeckOverlay } from '@deck.gl/google-maps';
import { COORDINATE_SYSTEM } from '@deck.gl/core';
import { ArcLayer, LineLayer } from 'deck.gl';
import { useEffect, useMemo, useRef, useState } from 'react';
import AdvancedMarker from '../../components/AdvancedMarker';
import TruckIcon from '../../assets/icons/TruckIcon.svg';
import LegIcon from '../../assets/icons/shipment.svg';
import ShipIcon from '../../assets/icons/ShipIcon.svg';
import AirplaneIcon from '../../assets/icons/AirplaneIcon.svg';
import { ShipmentMode } from './constants';
import clsx from 'clsx';

const LegMap = ({
  originAddress,
  destinationAddress,
  origin = { lat: 0, lng: 0 },
  destination = { lat: 0, lng: 0 },
  map,
  setMap,
  selectedShipmentMode,
  isTrackerPickup,
  infoWindow
}) => {
  const ref = useRef();
  const [pathRenderer, setPathRenderer] = useState();
  const [validCoordinates, setCoordinatesValidity] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(map?.getZoom());

  const overlay = useMemo(
    () => new DeckOverlay({ useDevicePixels: true, redraw: true }),
    []
  );

  useEffect(() => {
    setMap(new window.google.maps.Map(ref.current, mapOptions));
  }, []);

  useEffect(() => {
    const zoomListener = map?.addListener('zoom_changed', () => {
      setZoomLevel(map?.getZoom());
    });

    return () => {
      // Cleanup the event listener
      google.maps.event.removeListener(zoomListener);
    };
  }, [map]);

  const isValidLatLng = (latLng) => {
    if (latLng && typeof latLng === 'object') {
      if ('lat' in latLng && 'lng' in latLng) {
        if (latLng.lat !== 0 || latLng.lng !== 0) {
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    if (isValidLatLng(origin) && isValidLatLng(destination)) {
      setCoordinatesValidity(true);
    }
  }, [origin, destination]);

  const convertLatLng = (input) => {
    if (input) {
      if (typeof input === 'object' && 'lat' in input && 'lng' in input) {
        const { lat, lng } = input;
        return [lng, lat];
      } else {
        throw new Error('Input is not in the expected format');
      }
    }
  };

  useEffect(() => {
    if (!isValidLatLng(origin) || !isValidLatLng(destination) || !overlay) {
      return;
    }
    if (map && selectedShipmentMode === ShipmentMode.LAND) {
      overlay.setProps({
        layers: []
      });
      const wayPoints = [
        {
          location: originAddress,
          stopover: true
        },
        {
          location: destinationAddress,
          stopover: false
        }
      ];
      const google = window.google;
      const directionsService = new google.maps.DirectionsService();

      directionsService.route(
        {
          origin: originAddress,
          destination: destinationAddress,
          waypoints: wayPoints,
          travelMode: google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            const directionsRenderer = new google.maps.DirectionsRenderer();
            directionsRenderer.setOptions({
              directions: result,
              map: map,
              suppressMarkers: true,
              polylineOptions: {
                strokeColor: '#0B1940',
                strokeWeight: 2
              }
            });
            setPathRenderer(directionsRenderer);
          }
        }
      );
    } else if (map && selectedShipmentMode !== ShipmentMode.LAND) {
      if (pathRenderer) {
        pathRenderer?.setMap(null);
        setPathRenderer(null);
      }
      if (map && overlay) {
        if (selectedShipmentMode == ShipmentMode.AIR)
          overlay.setProps({
            layers: [
              new ArcLayer({
                id: 'arc-layer',
                data: [convertLatLng(destination)],
                numSegments: 300,
                getSourcePosition: () => convertLatLng(origin),
                getTargetPosition: (f) => f,
                getSourceColor: [11, 25, 64],
                getTargetColor: [11, 25, 64],
                getWidth: zoomLevel > 5 ? 4 : 2,
                getTilt: 15,
                parameters: { depthTest: false },
                coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
                updateTriggers: {
                  getSourcePosition: [zoomLevel],
                  getTargetPosition: [zoomLevel]
                }
              })
            ]
          });
        if (selectedShipmentMode == ShipmentMode.SEA)
          overlay.setProps({
            layers: [
              new LineLayer({
                id: 'line-layer',
                data: [convertLatLng(destination)],
                numSegments: 300,
                getSourcePosition: () => convertLatLng(origin),
                getTargetPosition: (f) => f,
                getSourceColor: [11, 25, 64],
                getTargetColor: [11, 25, 64],
                getWidth: zoomLevel > 5 ? 4 : 2,
                getTilt: 15,
                parameters: { depthTest: false },
                coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
                updateTriggers: {
                  getSourcePosition: [zoomLevel],
                  getTargetPosition: [zoomLevel]
                }
              })
            ]
          });
        overlay.setMap(map);
      }

      const positions = [origin, destination];
      const padding = 0;
      let minLat = Infinity;
      let minLng = Infinity;
      let maxLat = -Infinity;
      let maxLng = -Infinity;
      positions?.forEach((position) => {
        if (
          position &&
          position.lat !== undefined &&
          position.lng !== undefined
        ) {
          minLat = Math.min(minLat, position.lat);
          minLng = Math.min(minLng, position.lng);
          maxLat = Math.max(maxLat, position.lat);
          maxLng = Math.max(maxLng, position.lng);
        }
      });
      if (
        minLat === Infinity &&
        minLng === Infinity &&
        maxLat === -Infinity &&
        maxLng === -Infinity
      ) {
        const defaultBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(-1, -1),
          new google.maps.LatLng(1, 1)
        );
        map.fitBounds(defaultBounds);
        return;
      }
      const latPadding = (maxLat - minLat) * padding;
      const lngPadding = (maxLng - minLng) * padding;
      minLat -= latPadding;
      maxLat += latPadding;
      minLng -= lngPadding;
      maxLng += lngPadding;
      const bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(minLat, minLng),
        new google.maps.LatLng(maxLat, maxLng)
      );
      map.fitBounds(bounds);
    }
  }, [origin, destination, map, selectedShipmentMode]);

  useEffect(() => {
    const overlay = new DeckOverlay({
      layers: [
        new ArcLayer({
          id: 'arc-layer',
          data: [convertLatLng(destination)],
          getSourcePosition: (d) => d.source,
          getTargetPosition: (d) => d.target,
          getWidth: zoomLevel > 5 ? 4 : 2,
          getSourceColor: (d) => [255, 0, 0],
          getTargetColor: (d) => [0, 128, 255],
          parameters: { depthTest: false },
          coordinateSystem: COORDINATE_SYSTEM.LNGLAT
        })
      ]
    });

    overlay.setMap(map); // Attach overlay to map

    // Clean up the overlay when component unmounts
    return () => overlay.setMap(null);
  }, [zoomLevel, map]);

  return (
    <>
      <div className="w-full h-full">
        <div
          className={clsx('w-full h-full transition-all', {
            blur: !validCoordinates
          })}
        >
          <div
            ref={ref}
            id="map"
            style={{ width: '100%', height: '100%' }}
          ></div>
          {map && validCoordinates && (
            <>
              <AdvancedMarker
                map={map}
                position={origin}
                addMarkerRef={() => {}}
                showInfoWindow={isTrackerPickup}
                infoWindow={infoWindow}
              >
                <div className="w-10 h-8 mb-[-12px] rounded-full bg-white flex items-center justify-center">
                  {selectedShipmentMode === ShipmentMode.LAND &&
                    (!isTrackerPickup ? (
                      <img src={LegIcon} alt="truck" className="w-6 h-6" />
                    ) : (
                      <img src={TruckIcon} alt="truck" className="w-6 h-6" />
                    ))}
                  {selectedShipmentMode === ShipmentMode.SEA && (
                    <img src={ShipIcon} alt="Ship" className="w-6 h-6" />
                  )}
                  {selectedShipmentMode === ShipmentMode.AIR && (
                    <img
                      src={AirplaneIcon}
                      alt="Airplane"
                      className="w-6 h-6"
                    />
                  )}
                </div>
              </AdvancedMarker>
              <AdvancedMarker
                map={map}
                position={destination}
                addMarkerRef={() => {}}
                onClick={() => {}}
                showInfoWindow={false}
              >
                <div className="w-2 h-2 mb-[-4px] bg-white rounded-full border border-primaryBlue"></div>
              </AdvancedMarker>
            </>
          )}
        </div>
        {!validCoordinates && (
          <div className="w-100 h-full relative top-[-100%] flex items-center justify-center transition-all">
            <p>No location data</p>
          </div>
        )}
      </div>
    </>
  );
};

export default LegMap;

const mapOptions = {
  mapId: 'f821a93ab9a91eb9',
  center: { lat: 29.600087, lng: 70.008634 },
  zoom: 4,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  scrollwheel: false,
  // maxZoom: 5,
  zoomControl: true // Disable zoom control buttons
};
