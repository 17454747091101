import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, FormControl, Grid } from '@mui/material';
import TextField from '../../../../core-components/atoms/TextField';
import SupportingDocumentUpload from '../../../../core-components/molecules/SupportingDocumentsUpload';
import FormikTextField from '../../../../core-components/molecules/FormikTextField';
import FormikAutocomplete from '../../../../core-components/molecules/FormikAutocomplete';
import clsx from 'clsx';
import Switch from '../../../../core-components/atoms/Switch';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { isArabCountry, SharedContext } from '../../../../utils/common';
import API from '../../../../libs/axios';
import { onError } from '../../../../libs/errorLib';

const sxProps = {
  '& .MuiOutlinedInput-input': {
    padding: '10px 14px !important'
  },
  '& .MuiInputBase-root': {
    fontSize: '14px'
  },
  '& .MuiAutocomplete-input': {
    padding: '0px 4px 0px 6px !important'
  },
  '& input::placeholder': {
    fontSize: '14px'
  },
  '& textarea::placeholder': {
    fontSize: '14px'
  }
};

const VendorForm = ({ formik, edit, vendorTypes, businessTypes }) => {
  const { setAPILoader } = useContext(SharedContext);
  const [paymentTermSearchValue, setPaymentTermSearchValue] = useState('');
  const [paymentTerms, setPaymentTerms] = useState([]);
  const { organization } = useContext(SharedContext);
  useEffect(() => {
    getPaymentTerms(paymentTermSearchValue);
  }, [paymentTermSearchValue]);

  const getPaymentTerms = async (search) => {
    try {
      setAPILoader(true);
      const { data } = await API.get('paymentTerms', {
        params: { search }
      });
      setPaymentTerms(data);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const borderClass = 'mt-4 number-input-container';

  return (
    <div className={'px-8 pt-4 pb-5'}>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">
            Name & Category
            <span className="text-error ml-2">*</span>
          </p>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <FormikTextField
              placeholder="Vendor Name"
              name="name"
              size={'small'}
              formik={formik}
              disabled={!edit}
              sxProps={sxProps}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormikAutocomplete
            options={vendorTypes}
            formik={formik}
            name="vendorCategory"
            placeholder="Vendor Type"
            size={'small'}
            disabled={!edit}
            getOptionLabel={(option) => option.label || ''}
          />
        </Grid>
      </Grid>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">
            Company & Business Type<span className="text-error ml-2">*</span>
          </p>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <FormikTextField
              placeholder="Company Name"
              name="companyName"
              size={'small'}
              formik={formik}
              sxProps={sxProps}
              disabled={!edit}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormikAutocomplete
            options={businessTypes}
            formik={formik}
            name="businessType"
            placeholder="Business Type"
            size={'small'}
            disabled={!edit}
            getOptionLabel={(option) => option.label || ''}
          />
        </Grid>
      </Grid>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">
            Address
            <span className="text-error ml-2">*</span>
          </p>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth>
            <FormikTextField
              name="address"
              formik={formik}
              value={formik.values.address}
              onChange={(e) => {
                formik.setFieldValue('address', e.target.value);
              }}
              sxProps={sxProps}
              disabled={!edit}
              placeholder="Enter here"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">
            Billing Address
            <span className="text-error ml-2">*</span>
          </p>
        </Grid>
        <Grid item xs={8} className="flex flex-col gap-2 justify-center">
          <div className="flex gap-2 items-center">
            <Checkbox
              checked={formik.values.sameAsBilling}
              value={formik.values.sameAsBilling}
              onChange={(e, checked) => {
                formik.setFieldValue('sameAsBilling', checked);
                if (checked) {
                  formik.setFieldValue(
                    'shippingAddress',
                    formik.values.billingAddress
                  );
                }
              }}
              sxProps={sxProps}
              disabled={!edit}
            />
            <span className="text-sm">Same as main address</span>
          </div>
          {!formik.values.sameAsBilling && (
            <FormControl fullWidth>
              <FormikTextField
                formik={formik}
                name="billingAddress"
                value={formik.values.billingAddress}
                onChange={(e) =>
                  formik.setFieldValue('billingAddress', e.target.value)
                }
                placeholder="Enter here"
                disabled={!edit}
                sxProps={sxProps}
              />
            </FormControl>
          )}
        </Grid>
      </Grid>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">
            Email & Contact Number
            <span className="text-error ml-2">*</span>
          </p>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <FormikTextField
              formik={formik}
              name="email"
              placeholder="Enter Email"
              type="email"
              disabled={!edit}
              sxProps={sxProps}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <PhoneInput
              country={'pk'}
              value={formik?.values?.phoneNumber || ''}
              onChange={(phone) => {
                formik.setFieldValue('phoneNumber', phone);
              }}
              inputStyle={{ width: '100%', height: '38px' }}
              disabled={!edit}
              variant="outlined"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">
            {`${
              isArabCountry(organization)
                ? 'VAT'
                : 'Tax Identification Number (TIN)'
            }`}
          </p>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth>
            <TextField
              value={formik.values.taxIdentificationNumber}
              onChange={(e) =>
                formik.setFieldValue('taxIdentificationNumber', e.target.value)
              }
              placeholder="Enter Tax ID"
              disabled={!edit}
              sxProps={sxProps}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container className={borderClass} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">
            Payment Terms & Bank Account Information
          </p>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            {
              <FormikAutocomplete
                options={paymentTerms}
                value={paymentTerms.find(
                  (value) => value.id === formik.values.paymentTermId
                )}
                formik={formik}
                name="paymentTerm"
                placeholder="Enter Payment Terms"
                size={'small'}
                getOptionLabel={(option) => option.title || ''}
                disabled={!edit}
                onChange={(_, newValue) => {
                  if (newValue)
                    formik.setFieldValue('paymentTermId', newValue.id);
                }}
                onInputChange={(_, newValue) => {
                  if (newValue) {
                    setPaymentTermSearchValue(newValue);
                  }
                }}
                onFocus={() => {
                  setPaymentTermSearchValue('');
                }}
              />
            }
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField
              value={formik.values.bankAccountInfo}
              onChange={(e) =>
                formik.setFieldValue('bankAccountInfo', e.target.value)
              }
              placeholder="Bank Account Information"
              disabled={!edit}
              sxProps={sxProps}
            />
          </FormControl>
        </Grid>
      </Grid>
      <div className={clsx(borderClass, !edit && 'pointer-events-none')}>
        <SupportingDocumentUpload
          formik={formik}
          propertyName="supportingDocuments"
          title="Contract/Supporting Documents"
        />
      </div>
      <Grid container className={`${borderClass}`} spacing={1}>
        <Grid item xs={4} className="flex items-center">
          <p className="font-semibold">Status</p>
        </Grid>
        <Grid item xs={8}>
          <Switch
            checked={formik.values.isActive}
            onChange={() =>
              formik.setFieldValue('isActive', !formik.values.isActive)
            }
            label="Active"
            disabled={!edit}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default VendorForm;
