/*eslint-disable*/
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { debounce } from 'lodash';

import { DEBOUNCE_CONST } from '../../Config';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import OrderStatuses from '../../utils/enums/orderStatuses';
import MoveTypes from '../../utils/enums/moveTypes';
import Autocomplete from '../../core-components/atoms/Autocomplete';
import { SharedContext } from '../../utils/common';
import { makeStyles, styled } from '@mui/material';
import useFeatureFlags from '../../hooks/useFeatureFlags';

const DockAssignment = ({
  dockInitialValues,
  shipment,
  refetch,
  disabled,
  edit,
  submitHandler
}) => {
  const [warehouseDocks, setWarehouseDocks] = useState([]);
  const [dockVal, setDockVal] = useState('');
  const [dock, setDock] = useState({});

  const { setAPILoader } = useContext(SharedContext);

  useEffect(() => {
    getWarehouseDocks(dockVal);
  }, [dockVal]);

  const getWarehouseDocks = useCallback(
    debounce((value) => {
      _getWarehouseDocks(value)
        .then((warehouseDocks) => {
          setWarehouseDocks(warehouseDocks.data);
        })
        .catch((err) => {
          onError(err);
        });

      if (edit) {
        if (
          dockInitialValues?.moveType === MoveTypes.TRANSFER &&
          shipment?.dropOffDockId
        ) {
          setDock({
            id: shipment?.dropOffDock?.id,
            name: shipment?.dropOffDock?.name
          });
        } else {
          setDock({
            id: shipment?.WarehouseDock?.id,
            name: shipment?.WarehouseDock?.name
          });
        }
      }
    }, DEBOUNCE_CONST),
    []
  );

  const _getWarehouseDocks = async (value) => {
    let id;
    switch (dockInitialValues?.moveType) {
      case MoveTypes.OUTBOUND:
        id = dockInitialValues?.pickupNode?.Warehouse?.id;
        break;
      case MoveTypes.INWARD:
        id = dockInitialValues?.dropOffNode?.Warehouse?.id;
        break;
      case MoveTypes.TRANSFER:
        id = getDockId(dockInitialValues);
        break;
      default:
        id = dockInitialValues?.dropOffId;
        break;
    }
    if (value)
      return API.get(`warehouses/${id}/docks`, { params: { search: value } });
    return API.get(`warehouses/${id}/docks`);
  };

  const getDockId = (dockInitialValues) => {
    if (shipment?.status?.key == 'CREATED') {
      return dockInitialValues?.dropOffNode?.Warehouse?.id;
    }
    return dockInitialValues?.pickupNode?.Warehouse?.id;
  };

  console.log(dockInitialValues, 'docl');
  const dockText = 'Assign Dock';
  // dockInitialValues.moveType != 'TRANSFER'
  //   ? dockInitialValues.moveType == 'OUTBOUND'
  //     ? 'Assign for Loading'
  //     : 'Assign for Un-Loading'
  //   : `${shipment.dockId ? 'Assign for Un-Loading' : 'Assign for Loading'}`;

  const StyledAutocomplete = styled(Autocomplete)({
    '& .MuiAutocomplete-root': {
      height: '1.5rem !important'
    },
    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
      fontSize: '13px',
      fontWeight: 500
    },
    '& .MuiAutocomplete-input::placeholder': {
      fontSize: '13px'
    }
  });

  return (
    <div>
      <StyledAutocomplete
        options={warehouseDocks}
        getOptionLabel={(dock) => (dock?.id ? `${dock.name}` : '')}
        renderOption={(props, dock) => {
          return (
            <li {...props} className={`${props.className} text-[12px]`}>
              {dock.name}
            </li>
          );
        }}
        onChange={async (e, val) => {
          setAPILoader(true);
          await submitHandler(
            dockInitialValues.customId,
            shipment.customId,
            val.id
          );
          await refetch();
          setAPILoader(false);
        }}
        onKeyUp={(event, newValue) => {
          if (event.target.value) {
            setDockVal(event.target.value);
          }
        }}
        getOptionSelected={(option, value) => option.value === value.value}
        placeholder={dockText}
        disabled={disabled}
        value={dock}
        edit={edit}
      />
    </div>
  );
};

export default DockAssignment;
