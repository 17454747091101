import React, { useContext, useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';
import * as XLSX from 'xlsx';
import {
  Paper,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead
} from '@material-ui/core';
import { useCallback } from 'react';
import clsx from 'clsx';
import moment from 'moment';

import { getCarriers, getVendors } from '../../../apis/tms';
import { DEBOUNCE_CONST } from '../../../Config';
import { SharedContext } from '../../../utils/common';
import { Box, Grid, Tab, TableBody, Tabs, Tooltip } from '@mui/material';
import Header from '../components/Header';
import EditIcon from '../../../assets/icons/editIconDark.svg';
import AddcarrierPopup from './components/carrierPopup';
import API from '../../../libs/axios';
import Button from '../../../core-components/atoms/Button';
import Popup from '../../../core-components/atoms/Popup';
import { logError } from '../../../libs/errorLib';
import { toaster } from '../../../utils/toaster';
import WarningIcon from '../../../assets/icons/warning.svg';
import downloadFileIcon from '../../../assets/icons/templateDownload.svg';
import { checkPermission } from '../../../utils/auth';
import { Title } from '../../../core-components/atoms/Text';
import AddVendorPopUp from './vendors/AddVendorPopup';

const CarrierView = () => {
  const { setCurrentPageTitle, organizationType, setAPILoader, currentUser } =
    useContext(SharedContext);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [carriers, setcarriers] = useState([]);

  const [pageCountV, setPageCountV] = useState(1);
  const [pageV, setPageV] = useState(1);
  const [vendors, setVendors] = useState([]);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [, setContentHover] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(-1);
  const [addcarrierView, setAddcarrierView] = useState(false);
  const [selectedcarrier, setSelectedcarrier] = useState(null);
  const [addVendorView, setAddVendorView] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [bulkBtnOpen, setBulkBtnOpen] = useState(false);
  const [columnNames, setColumnNames] = useState([
    'Name',
    'Type',
    'Payment Type',
    'Contact Name',
    'Contact Email',
    'Tax Id'
  ]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [bulkCarrierViewOpen, setBulkCarrierViewOpen] = useState(false);
  const [correctRows, setCorrectRows] = useState([]);
  const [incorrectRows, setIncorrectRows] = useState([]);
  const [value, setValue] = React.useState('carrier');
  const tableContainerRef = useRef(null);
  const tableContainerRefV = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchKeyword('');
  };

  useEffect(() => {
    setCurrentPageTitle('Carriers & Vendors');
    setColumnNames(
      value === 'carrier'
        ? [
            'Name',
            'Type',
            'Payment Type',
            'Contact Name',
            'Contact Email',
            'Tax Id'
          ]
        : [
            'Name',
            'Company Name',
            'Business Type',
            'Category',
            'Tax Id',
            'Phone',
            'Email'
          ]
    );
  }, [organizationType, value]);

  const { refetch, isFetching: carrierLoading } = useQuery(
    ['fetchcarriers', {}],
    () => {
      const payload = {
        page,
        searchKeyword
      };
      return getCarriers(payload, setAPILoader);
    },
    {
      enabled: true,
      onSuccess: (data) => {
        if (data) {
          if (page === 1) {
            setcarriers(data.data.rows);
            setPageCount(data.pages);
          } else {
            setcarriers([...carriers, ...data.data.rows]);
            setPageCount(data.pages);
          }
        }
      },
      onError: (err) => {
        console.log(err);
      }
    }
  );

  const { refetch: refetchVendor, isFetching: vendorLoading } = useQuery(
    ['fetchvendors', {}],
    () => {
      const payload = {
        page,
        searchKeyword
      };
      return getVendors(payload, setAPILoader);
    },
    {
      enabled: true,
      onSuccess: (data) => {
        if (data) {
          if (page === 1) {
            setVendors(data.data);
            setPageCountV(data.pages);
          } else {
            setVendors([...carriers, ...data.data]);
            setPageCountV(data.pages);
          }
        }
      },
      onError: (err) => {
        console.log(err);
      }
    }
  );

  const _getcarriers = useCallback(
    debounce(() => {
      refetch();
    }, DEBOUNCE_CONST),
    []
  );

  const _getvendors = useCallback(
    debounce(() => {
      refetchVendor();
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    if (value === 'vendor') {
      _getvendors();
    } else {
      _getcarriers();
    }
  }, [page, searchKeyword, value]);

  const handleTableScroll = () => {
    const container = tableContainerRef.current;
    if (
      container.scrollTop + container.clientHeight + 20 >=
        container.scrollHeight &&
      !carrierLoading &&
      page < pageCount
    ) {
      setPage(page + 1);
    }
  };

  const handleTableScrollVendor = () => {
    const container = tableContainerRefV.current;
    if (
      container.scrollTop + container.clientHeight + 20 >=
        container.scrollHeight &&
      !vendorLoading &&
      pageV < pageCountV
    ) {
      setPageV(pageV + 1);
    }
  };

  const fetchcarrier = async (id) => {
    try {
      setAPILoader(true);
      const carrier = await API.get(`companies/${id}`, {
        params: { relationType: 'VENDOR' }
      });
      setSelectedcarrier(carrier.company);
    } catch (e) {
      logError(e);
    } finally {
      setAPILoader(false);
    }
  };

  const fileUpload = async (values) => {
    setAPILoader(true);
    const payload = values.map((value) => ({
      carrierName: value['Carrier Name'],
      origin: value['Origin'],
      destination: value['Destination'],
      shippingDays: value['Shipping Days'],
      startDate: value['Start Date'],
      endDate: value['End Date'],
      blNumber: value['BL Number'],
      vesselName: value['Vessel Name'],
      price: value['Price'],
      type: value['Type'],
      containerNumber: value['Container Number']
    }));

    const { response } = await API.post('carrierSchedules/verify', payload);

    setCorrectRows(response?.filter((row) => row.correct));
    setIncorrectRows(response?.filter((row) => !row.correct));

    setBulkCarrierViewOpen(true);
  };

  const createSchedules = async () => {
    try {
      setAPILoader(true);
      await API.post('carrierSchedules', correctRows);
      toaster('success', `Schedules Have been Uploaded`);
      setCorrectRows([]);
      setIncorrectRows([]);
      setBulkCarrierViewOpen(false);
      setBulkBtnOpen(false);
      setSelectedFile(null);
    } catch (e) {
      logError(e);
    } finally {
      setAPILoader(false);
    }
  };

  const exportToXlsx = () => {
    let schedules = incorrectRows?.map((row) => [
      row.carrierName,
      row.origin,
      row.destination,
      row.shippingDays,
      row.startDate,
      row.endDate,
      row.reason
    ]);
    const data = [
      [
        'Carrier Name',
        'Origin',
        'Destination',
        'Shipping Days',
        'Start Date',
        'End Date',
        'Reason'
      ],
      ...schedules
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'incorrect_schedules');

    // Save the workbook as an XLSX file
    XLSX.writeFile(wb, 'incorrect_schedules.xlsx');
  };

  const tableCellClass = 'py-2 h-12 pl-0 pr-2';
  const cellValue = 'table-cell-trucate  text-normal';
  const cellSubValue = clsx(cellValue, 'text-[10px]');

  return (
    <div className="no-scrollbar overflow-hidden">
      <Box
        sx={{ width: '100%' }}
        className={'bg-dark200 border-dark300 border-b px-4'}
      >
        <Tabs
          onChange={handleChange}
          value={value}
          aria-label="Tabs where selection follows focus"
          selectionFollowsFocus
        >
          {checkPermission(currentUser, 'OPS_CARRIER_READ') && (
            <Tab
              className="capitalize p-4"
              label={<Title variant={'sm'}>{`Carriers`}</Title>}
              value={'carrier'}
            />
          )}
          {checkPermission(currentUser, 'OPS_CARRIER_READ') && (
            <Tab
              className="capitalize p-4"
              label={<Title variant={'sm'}>{`Vendors`}</Title>}
              value={'vendor'}
            />
          )}
        </Tabs>
      </Box>
      <div className="w-full mt-10 px-8">
        <Header
          onClick={() => {
            if (value === 'carrier') {
              setAddcarrierView(true);
            } else {
              setAddVendorView(true);
            }
          }}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
          addBtnLabel={value === 'carrier' ? 'Add Carriers' : 'Add Vendors'}
          headerText={value === 'carrier' ? 'Carriers' : 'Vendors'}
          searchPlaceHolder={
            value === 'carrier' ? 'Search Carriers' : 'Search Vendor'
          }
          bulkBtnOpen={bulkBtnOpen}
          setBulkBtnOpen={setBulkBtnOpen}
          fileUpload={fileUpload}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          tab={value}
        />
        {value === 'vendor' ? (
          <div>
            <div className="mb-5">
              <Paper className="no-scrollbar rounded-t-lg shadow-none">
                <TableContainer
                  onMouseEnter={() => {
                    setContentHover(true);
                  }}
                  onMouseLeave={() => {
                    setContentHover(false);
                  }}
                  style={{ overflow: 'auto' }}
                  ref={tableContainerRefV}
                  onScroll={handleTableScrollVendor}
                  className="mt-6 no-scrollbar rounded-t-lg border border-solid border-dark300 inventory-table"
                >
                  <Table stickyHeader>
                    <TableHead className="z-40 relative">
                      <TableRow>
                        {columnNames.map((columnName, index) => (
                          <TableCell
                            key={index}
                            className={`text-[10px] text-dark500 bg-white py-2 pr-2 font-normal h-[40px] ${
                              index == 0 ? 'pl-2' : 'pl-0'
                            }`}
                          >
                            {columnName}
                          </TableCell>
                        ))}
                        <TableCell className="bg-white text-dark300 py-2 px-6 " />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {vendors?.map((row, index) => {
                        return (
                          <TableRow
                            key={index}
                            onMouseEnter={() => {
                              setHoveredRow(index);
                            }}
                            onMouseLeave={() => {
                              setHoveredRow(-1);
                            }}
                            className={clsx(
                              'hover:shadow-elevation-1 hover:z-20 hover:relative ease-in-out transition-all h-[48px]'
                            )}
                            onClick={() => {}}
                          >
                            <TableCell
                              className={`${tableCellClass} pl-2 group-hover:text-primaryBlue`}
                            >
                              <Tooltip title={`${row?.name}`}>
                                <p className={clsx(cellSubValue)}>
                                  <span>{row?.name}</span>
                                </p>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              className={`${tableCellClass} group-hover:text-primaryBlue`}
                            >
                              <Tooltip title={`${row?.companyName}`}>
                                <p className={clsx(cellSubValue)}>
                                  {`${row?.companyName}`}
                                </p>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              className={`${tableCellClass} group-hover:text-primaryBlue`}
                            >
                              <p className={clsx(cellSubValue)}>
                                {`${row?.businessType || '-'}`}
                              </p>
                            </TableCell>
                            <TableCell
                              className={`${tableCellClass} group-hover:text-primaryBlue`}
                            >
                              <p className={clsx(cellSubValue)}>
                                {`${row?.vendorCategory || '-'}`}
                              </p>
                            </TableCell>
                            <TableCell
                              className={`${tableCellClass} group-hover:text-primaryBlue`}
                            >
                              <p className={clsx(cellSubValue)}>
                                {`${row?.ntn || '-'}`}
                              </p>
                            </TableCell>
                            <TableCell
                              className={`${tableCellClass} group-hover:text-primaryBlue`}
                            >
                              <p className={clsx(cellSubValue)}>
                                {`${row?.phoneNumber || '-'}`}
                              </p>
                            </TableCell>
                            <TableCell
                              className={`${tableCellClass} group-hover:text-primaryBlue`}
                            >
                              <p className={clsx(cellSubValue)}>
                                {row?.email || '-'}
                              </p>
                            </TableCell>
                            <TableCell
                              className={`${tableCellClass} group-hover:text-primaryBlue`}
                            >
                              {hoveredRow == index && (
                                <div
                                  className="flex gap-2"
                                  onClick={async () => {
                                    setSelectedVendor(row);
                                    setAddVendorView(true);
                                  }}
                                >
                                  <img src={EditIcon} />
                                </div>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        ) : (
          value === 'carrier' && (
            <div className="mb-5">
              <Paper className="no-scrollbar rounded-t-lg shadow-none">
                <TableContainer
                  onMouseEnter={() => {
                    setContentHover(true);
                  }}
                  onMouseLeave={() => {
                    setContentHover(false);
                  }}
                  style={{ overflow: 'auto' }}
                  ref={tableContainerRef}
                  onScroll={handleTableScroll}
                  className="mt-6 no-scrollbar rounded-t-lg border border-solid border-dark300 inventory-table"
                >
                  <Table stickyHeader>
                    <TableHead className="z-40 relative">
                      <TableRow>
                        {columnNames.map((columnName, index) => (
                          <TableCell
                            key={index}
                            className={`text-[10px] text-dark500 bg-white py-2 pr-2 font-normal h-[40px] ${
                              index == 0 ? 'pl-2' : 'pl-0'
                            }`}
                          >
                            {columnName}
                          </TableCell>
                        ))}
                        <TableCell className="bg-white text-dark300 py-2 px-6 "></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {carriers.map((row, index) => {
                        let type =
                          (row.type?.length > 0 && row.type.split(',')) || [];
                        type = {
                          Logistics: type.indexOf('Logistics') != -1,
                          Warehousing: type.indexOf('Warehousing') != -1,
                          Technology: type.indexOf('Technology') !== -1,
                          Land: type.indexOf('Land') !== -1,
                          Air: type.indexOf('Air') !== -1,
                          Sea: type.indexOf('Sea') !== -1
                        };

                        const typeValue =
                          (type.Logistics || type.Land || type.Air || type.Sea
                            ? 'Carrier'
                            : '') +
                          ((type.Logistics ||
                            type.Land ||
                            type.Air ||
                            type.Sea) &&
                          (type.Warehousing || type.Technology)
                            ? ' , '
                            : '') +
                          (type.Warehousing || type.Technology ? 'Vendor' : '');

                        return (
                          <TableRow
                            key={index}
                            onMouseEnter={() => {
                              setHoveredRow(index);
                            }}
                            onMouseLeave={() => {
                              setHoveredRow(-1);
                            }}
                            className={clsx(
                              'hover:shadow-elevation-1 hover:z-20 hover:relative ease-in-out transition-all h-[48px]'
                            )}
                            onClick={() => {}}
                          >
                            <TableCell
                              className={`${tableCellClass} pl-2 group-hover:text-primaryBlue`}
                            >
                              <Tooltip title={`${row?.name}`}>
                                <p className={clsx(cellSubValue)}>
                                  <span>{row?.name}</span>
                                </p>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              className={`${tableCellClass} group-hover:text-primaryBlue`}
                            >
                              <Tooltip title={`${typeValue}`}>
                                <p className={clsx(cellSubValue)}>
                                  {`${typeValue}`}
                                </p>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              className={`${tableCellClass} group-hover:text-primaryBlue`}
                            >
                              <p className={clsx(cellSubValue)}>
                                {`${row?.paymentType || '-'}`}
                              </p>
                            </TableCell>
                            <TableCell
                              className={`${tableCellClass} group-hover:text-primaryBlue`}
                            >
                              <p className={clsx(cellSubValue)}>
                                {`${row?.contactName || '-'}`}
                              </p>
                            </TableCell>
                            <TableCell
                              className={`${tableCellClass} group-hover:text-primaryBlue`}
                            >
                              <p className={clsx(cellSubValue)}>
                                {`${row?.contactEmail || '-'}`}
                              </p>
                            </TableCell>
                            <TableCell
                              className={`${tableCellClass} group-hover:text-primaryBlue`}
                            >
                              <p className={clsx(cellSubValue)}>
                                {row?.ntn || '-'}
                              </p>
                            </TableCell>
                            <TableCell
                              className={`${tableCellClass} group-hover:text-primaryBlue`}
                            >
                              {hoveredRow == index && (
                                <div
                                  className="flex gap-2"
                                  onClick={async () => {
                                    // setSelectedcarrier(row);
                                    await fetchcarrier(row.id);
                                    setAddcarrierView(true);
                                  }}
                                >
                                  <img src={EditIcon} />
                                </div>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          )
        )}
      </div>
      {value === 'carrier' ? (
        <>
          {addcarrierView && (
            <AddcarrierPopup
              open={addcarrierView}
              setOpen={setAddcarrierView}
              organizationType={organizationType}
              selectedcarrier={selectedcarrier}
              refetch={_getcarriers}
              setSelectedCarrier={setSelectedcarrier}
            />
          )}
          <Popup
            open={bulkCarrierViewOpen}
            setOpen={setBulkCarrierViewOpen}
            title={`Add Carrier Schedules`}
            onClose={() => {
              setCorrectRows([]);
              setIncorrectRows([]);
              setBulkCarrierViewOpen(false);
              setBulkBtnOpen(false);
              setSelectedFile(null);
            }}
            actions={
              <div className="flex gap-2">
                <Button
                  label="Cancel"
                  onClick={() => setBulkCarrierViewOpen(false)}
                  variant="secondary"
                />
                <Button
                  label={
                    correctRows?.length > 0
                      ? `Create ${correctRows?.length} ${
                          correctRows?.length > 1 ? 'Schedules' : 'Schedule'
                        }`
                      : 'Create'
                  }
                  onClick={async () => {
                    await createSchedules();
                  }}
                  disabled={correctRows?.length == 0}
                  variant="primary"
                />
              </div>
            }
            content={
              <div className="min-w-[1200px]">
                {incorrectRows?.length > 0 && (
                  <div className="flex p-4 bg-[#FFEBEB] mt-4 justify-between">
                    <div className="flex gap-2">
                      <img src={WarningIcon} />
                      <p className="text-sm">
                        {incorrectRows?.length}{' '}
                        {incorrectRows?.length > 1 ? 'rows are ' : 'row is '}
                        not uploaded because of formatting issues in file.
                      </p>
                    </div>
                    <div
                      onClick={async () => {
                        await exportToXlsx();
                      }}
                      className="flex gap-2 cursor-pointer"
                    >
                      <img src={downloadFileIcon} />
                      <p className="text-sm">Download File</p>
                    </div>
                  </div>
                )}
                {correctRows?.length > 0 && (
                  <>
                    <div className="flex justify-between mt-4 pl-2">
                      <p className="font-medium mt-4">
                        {correctRows?.length}{' '}
                        {correctRows?.length > 1 ? 'schedules' : 'schedule'}{' '}
                        Detected
                      </p>
                    </div>
                    <Grid
                      className="mb-2 pl-4 min-h-[80px] w-full mt-4"
                      container
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={2}
                        className="flex items-center font-medium"
                      >
                        Carrier Name
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        className="flex items-center font-medium"
                      >
                        Price
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        className="flex items-center font-medium"
                      >
                        Shipping Days
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        className="flex items-center font-medium"
                      >
                        Origin
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        className="flex items-center font-medium"
                      >
                        Destination
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        className="flex items-center font-medium"
                      >
                        Bl Number
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        className="flex items-center font-medium"
                      >
                        Date
                      </Grid>
                    </Grid>
                    {correctRows?.map((row, id) => (
                      <Grid
                        className="p-2 pl-4 bg-[#F0F7FF] mb-2 min-h-[80px] w-full mt-4"
                        container
                        spacing={2}
                        key={id}
                      >
                        <Grid item xs={2} className="flex items-center">
                          <span className="text-xs">{row?.carrierName}</span>
                        </Grid>
                        <Grid item xs={1} className="flex items-center">
                          <span className="text-xs">${row?.price}</span>
                        </Grid>
                        <Grid item xs={2} className="flex items-center">
                          <span className="text-xs ml-0">
                            {row?.shippingDays} Days
                          </span>
                        </Grid>
                        <Grid item xs={2} className="flex items-center">
                          <span className="text-xs">{row?.origin}</span>
                        </Grid>
                        <Grid item xs={2} className="flex items-center">
                          <span className="text-xs">{row?.destination}</span>
                        </Grid>
                        <Grid item xs={1} className="flex items-center">
                          <span className="text-xs">{row?.blNumber}</span>
                        </Grid>
                        <Grid item xs={2} className="flex items-center">
                          <span className="text-xs">
                            {moment(row?.startDate).format('DD/MM/YYYY')} -{' '}
                            {moment(row?.endDate).format('DD/MM/YYYY')}
                          </span>
                        </Grid>
                      </Grid>
                    ))}
                  </>
                )}
              </div>
            }
          />
        </>
      ) : (
        <>
          {addVendorView && (
            <AddVendorPopUp
              open={addVendorView}
              setOpen={setAddVendorView}
              organizationType={organizationType}
              selectedVendor={selectedVendor}
              refetch={_getvendors}
              setSelectedVendor={setSelectedVendor}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CarrierView;
