/*eslint-disable*/
import Popup from '../../core-components/atoms/Popup';
import Vector from '../../assets/icons/unifiedDashboard/Vector.svg';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useContext, useEffect, useState } from 'react';
import { shipmentsTabsMapping } from '../../constants';
import {
  calculateETA,
  computeDistance,
  getDateAndTime,
  isArabCountry,
  SharedContext
} from '../../utils/common';
import clsx from 'clsx';
import ImagePreview from '../../atomicComponents/ImagePreview';
import { CircularProgress } from '@mui/material';
import { decimalDisplay } from '../../utils/common';
import FLAGS from '../../constants/featureFlags';
import useFeatureFlags from '../../hooks/useFeatureFlags';

const ShipmentDetail = ({
  shipmentDetailPopup,
  setShipmentDetailPopup,
  data,
  setOpen,
  trackingData = [],
  loading = true,
  setLoading = () => {}
}) => {
  const [tabValue, setTabValue] = useState(shipmentsTabsMapping.TRACKING);
  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
  };
  const { isFeatureEnabled } = useFeatureFlags();
  const { organization } = useContext(SharedContext);
  const valueClass =
    'text-xs font-medium font-roboto text-primaryBlue truncate';
  const labelClass =
    'w-100 flex flex-row h-[24px] text-[10px] font-roboto font-medium text-stormGray flex items-center';

  function calculateTimeDifference(dropoffDateTime) {
    const dateTime1 = new Date(dropoffDateTime);
    const currentDateTime = new Date();
    const timeDifferenceMillis = Math.abs(dateTime1 - currentDateTime);
    const hours = Math.floor(timeDifferenceMillis / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifferenceMillis % (1000 * 60 * 60)) / (1000 * 60)
    );

    return { hours, minutes };
  }

  function ShipmentData() {
    return (
      <TabContext value={tabValue} className="h-[580px] p-0 ">
        {loading ? (
          <div className="w-100 h-full flex items-center justify-center transition-all">
            <CircularProgress />
          </div>
        ) : (
          <>
            <Box
              className="pt-2 pl-6 bg-dark100"
              sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
              <TabList onChange={handleChange}>
                <Tab
                  className="capitalize p-4"
                  label={<span>{`Tracking`}</span>}
                  value={shipmentsTabsMapping.TRACKING}
                />
                <Tab
                  className="capitalize p-4"
                  label={<span>{`Details`}</span>}
                  value={shipmentsTabsMapping.DETAILS}
                />
              </TabList>
            </Box>
            <TabPanel
              className="w-full pt-8 h-[428px] overflow-x-scroll dark-scrollbar"
              value="1"
            >
              <div>
                {trackingData?.map((log) => {
                  return (
                    <>
                      <div className="flex flex-row items-center font-roboto text-xs font-normal">
                        <div
                          className={clsx(
                            'w-[6px] h-[6px] rounded-full',
                            log.id && 'bg-primaryBlue',
                            !log.id && 'bg-graySlate'
                          )}
                        ></div>
                        <div className="text-xs font-normal text-graySlate pl-4">
                          {!log.id &&
                          (log.action === 'GDN_CREATE' ||
                            log.action === 'GRN_CREATE') ? (
                            <>
                              ETA{' '}
                              {`${getDateAndTime(data?.dropoffDateTime).date}`}
                              <span className="mx-1 text-stormGray">•</span>
                              {`${getDateAndTime(data?.dropoffDateTime).time}`}
                            </>
                          ) : log.id ? (
                            <>
                              {`${getDateAndTime(log?.createdAt).date}`}
                              <span className="mx-1 text-stormGray">•</span>
                              {`${getDateAndTime(log?.createdAt).time}`}
                            </>
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>
                      <div className="mt-[3px]">
                        <div
                          className={clsx(
                            log.action !== 'ORDER_CREATED' && !log.id
                              ? 'border-l border-b-0 border-t-0 border-r-0 border-dashed border-graySlate'
                              : log.id && log.action !== 'ORDER_CREATED'
                              ? 'border-solid border-l border-b-0 border-t-0 border-r-0 border-primaryBlue'
                              : 'border-0',
                            'mt-[-4px] ml-[2.5px] pl-5 min-h-[45px] pb-[15px]'
                          )}
                        >
                          {!log.id ? (
                            <p className="text-sm text-graySlate">
                              {log.message}
                            </p>
                          ) : (
                            <p className="text-sm text-primaryBlue">
                              {<span className="font-medium">{log.title}</span>}{' '}
                              {log.message}
                              {log.subMessage && (
                                <span className="text-xs font-normal text-stormGray">
                                  <br></br>
                                  {log.subMessage}
                                </span>
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </TabPanel>
            <TabPanel
              className="w-full h-[428px] pt-8 pr-[38px] overflow-x-scroll dark-scrollbar"
              value="3"
            >
              <div>
                <div className="flex border-solid border-platinumGray border-b border-t-0 border-l-0 border-r-0 mb-2 pb-2">
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>ORDER TYPE</p>
                    <p className={`${valueClass}`}>
                      {data?.Order?.moveSubType
                        ? data?.Order?.moveSubType?.replace('_', ' ')
                        : data?.Order?.moveType}
                    </p>
                  </div>
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>CUSTOMER</p>
                    <p className={`${valueClass}`}>
                      {data?.Order?.Company?.name}
                    </p>
                  </div>
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>CREATED BY</p>
                    <p
                      className={`${valueClass}`}
                    >{`${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`}</p>
                  </div>
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>CREATED ON</p>
                    <p className={`${valueClass}`}>{`${
                      getDateAndTime(data?.createdAt).date
                    }`}</p>
                  </div>
                </div>
                <div className="flex border-solid border-platinumGray border-b border-t-0 border-l-0 border-r-0 mb-2 pb-2">
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>DISTANCE</p>
                    <p className={`${valueClass}`}>
                      {decimalDisplay(
                        computeDistance(
                          data?.Order?.pickupNode?.locationLatLng,
                          data?.Order?.dropOffNode?.locationLatLng
                        )
                      )}{' '}
                      km
                    </p>
                  </div>
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>ETA</p>
                    <p className={`${valueClass}`}>
                      {isFeatureEnabled(FLAGS.TMS) ||
                      isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                        ? `${calculateETA(
                            computeDistance(
                              data?.Order?.pickupNode?.locationLatLng,
                              data?.Order?.dropOffNode?.locationLatLng
                            ),
                            data?.ShipmentLegs?.[0]?.Vehicle?.avgSpeed,
                            data?.Order?.expectedDelivery,
                            data?.status?.key
                          )}`
                        : `${
                            calculateTimeDifference(
                              data?.dropoffDateTime,
                              data?.status?.key
                            ).hours
                          }h ${
                            calculateTimeDifference(
                              data?.dropoffDateTime,
                              data?.status?.key
                            ).minutes
                          }min`}
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p className={`${labelClass}`}>ORIGIN & DESTINATION</p>
                    <p className={`${valueClass}`}>{`${
                      data?.Order?.pickupNode?.name ||
                      data?.Order?.warehousePickUp?.name
                    } -> ${
                      data?.Order?.warehouseDropOff?.name ||
                      data?.Order?.dropOffNode?.name
                    }`}</p>
                  </div>
                </div>
                <div className="flex border-solid border-platinumGray border-b border-t-0 border-l-0 border-r-0 mb-2 pb-2">
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>CARRIER</p>
                    <p className={`${valueClass}`}>
                      {data?.carrierName
                        ? data?.carrierName
                        : data?.ShipmentLegs?.[0]?.Company?.name || '-'}
                    </p>
                  </div>
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>VEHICLE TYPE</p>
                    <p className={`${valueClass}`}>
                      {data?.vehicleType
                        ? data?.vehicleType
                        : data?.ShipmentLegs?.[0]?.Car?.Category?.name || '-'}
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p className={`${labelClass}`}>VEHICLE NO</p>
                    <p className={`${valueClass}`}>
                      {data?.vehicleNumber
                        ? data?.vehicleNumber
                        : data?.ShipmentLegs?.[0]?.Vehicle
                            ?.registrationNumber || '-'}
                    </p>
                  </div>
                </div>
                <div className="flex border-solid border-platinumGray border-b border-t-0 border-l-0 border-r-0 mb-2 pb-2">
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>DRIVER NAME</p>
                    <p className={`${valueClass}`}>
                      {data?.driverName
                        ? data?.driverName
                        : data?.ShipmentLegs?.[0]?.Driver?.name || '-'}
                    </p>
                  </div>
                  <div className="w-1/4">
                    <p className={`${labelClass}`}>
                      {isArabCountry(organization) ? 'IQAMA NUMBER' : 'TIN'}
                    </p>
                    <p className={`${valueClass}`}>
                      {data?.driverCnic
                        ? data?.driverCnic
                        : data?.ShipmentLegs?.[0]?.Driver?.cnicNumber || '-'}
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p className={`${labelClass}`}>CONTACT NUMBER</p>
                    <p className={`${valueClass}`}>
                      {data?.ShipmentLegs?.[0]?.Driver?.phone || '-'}
                    </p>
                  </div>
                </div>
                {data?.Order?.files?.length > 0 && (
                  <div className="w-100">
                    <p className="w-100 text-deepCove font-roboto font-medium">
                      Proof of document
                    </p>
                    <div className="w-100 flex flex-row">
                      {data?.Order?.files?.map((file) => (
                        <div className="w-[88px] h-[88px] mr-2" key={file.id}>
                          <ImagePreview file={file} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {data?.Order?.memo && (
                  <div className="w-[400px] h-[27px] mt-12">
                    <p className="w-[400px] text-deepCove font-roboto font-medium">
                      Additional Information
                    </p>
                    <div className="w-100">{data?.Order?.memo || '-'}</div>
                  </div>
                )}
              </div>
            </TabPanel>
          </>
        )}
      </TabContext>
    );
  }

  function getBackgroundColor(status) {
    switch (status) {
      case 'Ready for Pickup':
        return 'bg-blueCharcoal text-white';
      case 'Arrived Destination':
        return 'bg-shamrockGreen text-white';
      case 'IN_TRANSIT':
        return 'bg-blueEyes text-white';
      default:
        return 'bg-blueEyes text-white';
    }
  }

  function Title({ data }) {
    return (
      <div className="flex flex-row items-center">
        <img
          className="w-[7px] h-[14px] mr-3 cursor-pointer"
          src={Vector}
          alt="Vector Sign"
          onClick={() => {
            setShipmentDetailPopup(false);
            setOpen(true);
          }}
        />
        <p className="text-deepCove text-xl font-semibold">{`${data.customId}`}</p>
        <div
          className={`text-xs ml-4 px-2 py-1 font-roboto w-auto h-[25px] ${getBackgroundColor(
            data.status.key
          )} flex justify-center items-center rounded`}
        >
          <span>{data.status.name}</span>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (!shipmentDetailPopup) {
      setLoading(true);
    }
  }, [shipmentDetailPopup]);

  return (
    <Popup
      open={shipmentDetailPopup}
      setOpen={setShipmentDetailPopup}
      content={<ShipmentData data={data} />}
      title={loading ? '' : <Title data={data} />}
      noLineBreak={true}
      headerClass="pt-[14px] px-4 pb-0 border-platinum border-t-0 border-l-0 border-r-0 bg-dark100"
      closeIconSize={24}
      titleClass="text-primaryBlue text-lg font-semibold"
      dialogContentClasses="overflow-hidden"
      paperProps={{
        style: {
          borderRadius: '8px',
          height: '529px',
          width: '445px'
        }
      }}
      noPadding={true}
    />
  );
};

export default ShipmentDetail;
