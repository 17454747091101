/*eslint-disable*/
import React, { useState, useEffect, useContext } from 'react';
import { InputAdornment, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { decimalDisplay, SharedContext } from '../../../utils/common';
import CustomTextField from '../../../core-components/atoms/TextField';
import { NumericFormat } from 'react-number-format';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import UomPopup from '../../revampedOrder/components/UomPopup';
import { PRIMARY } from '../../../constants/colors';

const O360GrnProductsTableRow = (props) => {
  const {
    row,
    parentIndex,
    rows,
    setRows,
    settleRemainingQty,
    GRNview,
    enableActions,
    reInitialize,
    setReinitialize
  } = props;

  const uomValue = row?.secondaryUomId
    ? {
        primary: false,
        conversionFactor:
          row?.Product?.ProductUOMs?.find((u) => u.uomId == row?.secondaryUomId)
            ?.conversionFactor || 1,
        ...row?.SecondaryUOM
      }
    : {
        ...row?.Product?.UOM,
        conversionFactor: 1,
        primary: true
      };

  const [remainingQty, setRemainingQty] = useState(0);
  const [dispatchedQty, setDispatchedQty] = useState(0);
  const [receivedQuantity, setReceivedQuantity] = useState(
    rows[parentIndex].receivedQuantity / uomValue?.conversionFactor
  );
  const [damagedQuantity, setDamagedQuantity] = useState(
    rows[parentIndex]?.GRNGroupBatches?.[0]?.recoverableDamageQuantity || 0
  );
  const [uom, setUom] = useState(uomValue);
  const [uomView, setUomView] = useState(false);
  const [primaryUom, setPrimaryUom] = useState({
    ...row?.Product?.UOM,
    conversionFactor: 1,
    primary: true
  });
  const [secondaryUoms, setSecondaryUoms] = useState(
    row?.Product?.ProductUOMs?.map((uom) => ({
      id: uom?.id,
      name: uom?.UOM?.name,
      conversionFactor: uom?.conversionFactor,
      uomId: uom?.uomId
    }))
  );

  const uoms = [primaryUom, ...secondaryUoms];

  useEffect(() => {
    const [dispatchedQty] = row?.GDNGroupBatches?.reduce(
      (acc, obj) => {
        return [
          acc[0] + (+obj?.actualDispatchedQuantity || 0),
          acc[1] +
            (+obj?.recoverableDamageQuantity || 0) +
            (+obj?.unsellableDamageQuantity || 0)
        ];
      },
      [0, 0]
    ) || [0, 0];

    setDispatchedQty(parseFloat(dispatchedQty.toFixed(3)));
  }, [rows]);

  useEffect(() => {
    const remainingQty = row?.orderedQuantity
      ? row?.orderedQuantity - (row?.receivedQuantity || 0)
      : 0;
    setRemainingQty(remainingQty < 0 ? 0 : remainingQty.toFixed(3));
    settleRemainingQty(remainingQty < 0 ? 0 : remainingQty.toFixed(3));

    let toggleData = [...rows];

    if (!GRNview) {
      if (!uom?.primary) {
        toggleData[parentIndex].secondaryUomId = uom?.id || uom?.uomId;
        toggleData[parentIndex].secondaryUomQuantity = receivedQuantity;
      } else {
        toggleData[parentIndex].secondaryUomId = null;
        toggleData[parentIndex].secondaryUomQuantity = null;
      }
      setRows(toggleData);
    }
  }, [receivedQuantity]);

  useEffect(() => {
    if (row?.secondaryUomId) {
      setUom(uoms?.find((uom) => uom?.uomId == row?.secondaryUomId));
    }
  }, [row]);

  useEffect(() => {
    if (reInitialize) {
      const uomValue = row?.secondaryUomId
        ? {
            primary: false,
            conversionFactor:
              row?.Product?.ProductUOMs?.find(
                (u) => u.uomId == row?.secondaryUomId
              )?.conversionFactor || 1,
            ...row?.SecondaryUOM
          }
        : {
            ...row?.Product?.UOM,
            conversionFactor: 1,
            primary: true
          };

      setUom(uom);
      setReceivedQuantity(
        rows[parentIndex].receivedQuantity / uomValue?.conversionFactor
      );
      setDamagedQuantity(
        rows[parentIndex].unsellableDamageQuantity / uomValue?.conversionFactor
      );
      setReinitialize(false);
    }
  }, [reInitialize]);
  const { organization } = useContext(SharedContext);

  return (
    <>
      <TableRow
        className="group"
        sx={{
          '& td': {
            // borderBottom: 0,
            paddingBottom: 3,
            paddingTop: 3
          }
        }}
      >
        <TableCell className="text-base">
          <span>
            <Tooltip title={`${row?.Product?.name}`}>
              <p className="text-base font-medium ">
                {row?.Product.name}-{row?.Product.description}
              </p>
            </Tooltip>
          </span>
          <div className="text-xs truncate">
            {row?.Product?.barcode && (
              <span>BARCODE: {row?.Product?.barcode}</span>
            )}
          </div>
        </TableCell>
        <TableCell className="text-base">
          {
            <Autocomplete
              value={uom}
              onChange={(e, value) => {
                setReceivedQuantity(
                  (receivedQuantity * uom?.conversionFactor) /
                    value.conversionFactor
                );
                setDamagedQuantity(
                  (damagedQuantity * uom?.conversionFactor) /
                    value.conversionFactor
                );
                setUom(value);
              }}
              options={[
                ...uoms,
                ...(!GRNview && organization?.customUOM
                  ? [{ name: '+ Create UoM', type: 'add' }]
                  : [])
              ]}
              sx={{
                // border: "1px solid blue",
                '& .MuiOutlinedInput-root': {
                  // border: "1px solid yellow",
                  borderRadius: '0',
                  padding: '0'
                },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  border: '0px solid #eee'
                }
              }}
              placeholder="UoM"
              renderOption={(props, option) => {
                // eslint-disable-next-line no-unused-vars
                const { className, ...rest } = props;
                return option?.type == 'add' ? (
                  <li
                    className="auto-complete-option"
                    style={{
                      color: PRIMARY,
                      fontSize: '16px',
                      fontWeight: 500
                    }}
                    onClick={() => {
                      setUomView(true);
                    }}
                  >
                    {option.name}
                  </li>
                ) : (
                  <li {...rest} className="auto-complete-option-small">
                    <span>
                      {option.name} {option.primary && '(primary)'}
                    </span>
                  </li>
                );
              }}
              getOptionLabel={(uom) => uom.name}
            />
          }
        </TableCell>
        <TableCell className="text-base">
          {decimalDisplay(row.orderedQuantity / (uom?.conversionFactor || 1))}
        </TableCell>
        <TableCell className="text-base">
          {GRNview ? (
            decimalDisplay(receivedQuantity)
          ) : (
            <NumericFormat
              value={receivedQuantity || 0}
              onValueChange={(e) => {
                let toggleData = [...rows];
                setReceivedQuantity(e.value);
                if (toggleData[parentIndex].GRNGroupBatches?.length > 0) {
                  toggleData[parentIndex].GRNGroupBatches[0].receivedQuantity =
                    e.value * uom?.conversionFactor;
                  toggleData[parentIndex].receivedQuantity =
                    e.value * uom?.conversionFactor;
                } else {
                  toggleData[parentIndex].GRNGroupBatches = [
                    ...(toggleData[parentIndex]?.GRNGroupBatches || []),
                    { receivedQuantity: e.value * uom?.conversionFactor }
                  ];
                  toggleData[parentIndex].receivedQuantity =
                    e.value * uom?.conversionFactor;
                }

                if (enableActions) {
                  toggleData[
                    parentIndex
                  ].GRNGroupBatches[0].lossInTransitQuantity =
                    Number(toggleData[parentIndex].orderedQuantity) -
                    Number(e.value);
                }
                setRows(toggleData);
              }}
              customInput={CustomTextField}
              placeholder="Received Qty"
              variant="outlined"
              size="small"
              decimalScale={3}
              isAllowed={(values) => {
                const { floatValue } = values;

                const maxQuantity = Math.min(
                  row.quantity,
                  row?.GDNGroupBatches?.[0]?.availableQuantity
                );

                if (maxQuantity - floatValue < 0) {
                  return false;
                }
                return true;
              }}
              disabled={GRNview}
            />
          )}
        </TableCell>
        <TableCell className="text-base">
          {GRNview ? (
            (rows[parentIndex]?.GRNGroupBatches?.[0]
              ?.recoverableDamageQuantity || 0) / (uom?.conversionFactor || 1)
          ) : (
            <NumericFormat
              value={damagedQuantity}
              onValueChange={(e) => {
                let toggleData = [...rows];
                setDamagedQuantity(e.value);
                if (toggleData[parentIndex].GRNGroupBatches?.length > 0) {
                  toggleData[
                    parentIndex
                  ].GRNGroupBatches[0].recoverableDamageQuantity =
                    +e.value * (uom?.conversionFactor || 1);
                } else {
                  toggleData[parentIndex].GRNGroupBatches = [
                    ...(toggleData[parentIndex]?.GRNGroupBatches || []),
                    {
                      recoverableDamageQuantity:
                        +e.value * (uom?.conversionFactor || 1)
                    }
                  ];
                }

                setRows(toggleData);
              }}
              customInput={CustomTextField}
              placeholder="Damaged Qty"
              variant="outlined"
              size="small"
              decimalScale={3}
              isAllowed={(values) => {
                const { floatValue } = values;

                const maxQuantity = Math.min(
                  row.quantity,
                  row?.GDNGroupBatches?.[0]?.availableQuantity
                );

                if (maxQuantity - floatValue < 0) {
                  return false;
                }
                return true;
              }}
              disabled={GRNview}
            />
          )}
        </TableCell>
        <TableCell className="text-base">
          {decimalDisplay(remainingQty / uom.conversionFactor)}
        </TableCell>
      </TableRow>
      <UomPopup
        open={uomView}
        setOpen={setUomView}
        primaryUom={primaryUom}
        secondaryUoms={secondaryUoms}
        setPrimaryUom={setPrimaryUom}
        setSecondaryUoms={setSecondaryUoms}
        refetech={() => {}}
        value={{
          id: row?.Product?.id,
          isActive: true,
          batchEnabled: row?.Product?.batchEnabled
        }}
      />
    </>
  );
};

export default O360GrnProductsTableRow;
