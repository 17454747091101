import UpArrowDark from '../../../assets/icons/unifiedDashboard/upArrowDark.svg';
import UpArrowLight from '../../../assets/icons/unifiedDashboard/upArrowLight.svg';
import DownArrowDark from '../../../assets/icons/unifiedDashboard/downArrowDark.svg';
import DownArrowLight from '../../../assets/icons/unifiedDashboard/downArrowLight.svg';
import UpDownDark from '../../../assets/icons/unifiedDashboard/upDownDark.svg';
import UpDownLight from '../../../assets/icons/unifiedDashboard/upDownLight.svg';
import TruckDark from '../../../assets/icons/unifiedDashboard/truckDark.svg';
import TruckLight from '../../../assets/icons/unifiedDashboard/truckLight.svg';
import moment from 'moment';

export const getDirectionArrow = (direction, color) => {
  const images = {
    OUTBOUND: {
      Light: UpArrowLight,
      Dark: UpArrowDark
    },
    INWARD: {
      Light: DownArrowLight,
      Dark: DownArrowDark
    },
    BOTH: {
      Light: UpDownLight,
      Dark: UpDownDark
    },
    LOGISTIC: {
      Light: TruckLight,
      Dark: TruckDark
    }
  };
  if (!(direction in images) || !(color in images[direction])) {
    return 'Invalid parameters';
  }
  return images[direction][color];
};

export const isDayToday = (dateString) => {
  const yourDate = moment(dateString);
  const today = moment();
  return yourDate.isSame(today, 'day');
};
