/*eslint-disable*/
import { useContext, useState, useEffect, useRef } from 'react';
import {
  SharedContext,
  decimalDisplay,
  inventoryLocationFormat,
  isHighlighted,
  productLabelFormat
} from '../../utils/common';
import InventoryStats from './components/InventoryStats';
import InventoryHead from './components/InventoryHead';
import { useMutation, useQuery } from 'react-query';
import API from '../../libs/axios';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router';
import {
  getInventories,
  getInventoriesWeight,
  holdInventory,
  removeHoldFromInventory
} from '../../apis/wms';
import FileDownload from 'js-file-download';
import {
  InventoryManagementTabsMapping,
  ORGANIZATION_TYPES
} from '../../constants';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  TableRow,
  TableCell,
  TableHead
} from '@material-ui/core';
import * as XLSX from 'xlsx';
import Checkbox from '@mui/material/Checkbox';
import Button from '../../core-components/atoms/Button';
import DownloadIcon from '../../assets/icons/downloadOutlineIcon.svg';
import ViewIcon from '../../assets/icons/viewIcon.svg';
import AlertIcon from '../../assets/icons/error.svg';
import WarningIcon from '../../assets/icons/errorOutlinedIcon.svg';
import InfoIcon from '../../assets/icons/infoOutlinedIcon.svg';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import AddProductPopup from '../productMaster/ListViews/components/AddProductPopup';
import OptionsMenu from './components/Options';
import { onError } from '../../libs/errorLib';
import { toaster } from '../../utils/toaster';
import useStyles from './inventoryStyles';
import { getInventoryAlertMessage } from '../../utils/inventoryDetails';
import moment from 'moment';
import { useCallback } from 'react';
import { DEBOUNCE_CONST } from '../../Config';
import { checkPermission } from '../../utils/auth';
import Popup from '../../core-components/atoms/Popup';
import InventoryProductsTable from '../inventory/InventoryProductsTable';
import downloadFileIcon from '../../assets/icons/templateDownload.svg';
import ASC_SORT_ICON from '../../assets/icons/down-arrow.svg';
import DEC_SORT_ICON from '../../assets/icons/up-arrow.svg';
import OrderList from './components/OrderList';
import StockAdjustment from './stockAdjustment';
import StockAdjustmentPopover from './stockAdjustment/StockAdjustmentPopover';
import StockAdjustmentIcon from '../../assets/icons/stockAdjustment.svg';
import StockConditionPopover from './stockCondition/stockConditionPopover';
import StockCondition from './stockCondition';
import HoldInventory from './hold';
import ReleaseInventory from './release';
import HoldInventoryPopover from './hold/HoldInventoryPopover';
import ReleaseInventoryPopover from './release/ReleaseInventoryPopover';
import SectionRelocation from './sectionRelocation';
import SectionRelocationPopover from './sectionRelocation/SectionRelocationPopover';
import PlaceholderImageIcon from '../../assets/icons/placeholder.svg';
import HoldFadedIcon from '../../assets/icons/holdFadedIcon.svg';
import ConditionIcon from '../../assets/icons/stockConditionIconFaded.svg';
import SectionRelocationIcon from '../../assets/icons/stockRelocationIcon.svg';
import ReleaseFadedIcon from '../../assets/icons/releaseFadedIcon.svg';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';
import mixpanel from 'mixpanel-browser';
import EditBatch from './editBatch';

const InventoryView = () => {
  const {
    setCurrentPageTitle,
    organizationType,
    currentUser,
    setAPILoader,
    settings
  } = useContext(SharedContext);
  const { isFeatureEnabled } = useFeatureFlags();
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [addProductView, setAddProductView] = useState(false);
  const [totalInventoryWeight, setTotalInventoryWeight] = useState(0);
  const [inventories, setInventories] = useState([]);
  const [inventoriesBatches, setInventoriesBatches] = useState([]);
  const [inventoryCount, setInventoryCount] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [bulkPayload, setBulkPayload] = useState({});
  const [incorrect, setIncorrect] = useState([]);
  const [bulkBtnOpen, setBulkBtnOpen] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState(
    localStorage.getItem('inventorySearch') || ''
  );
  const [productNameVal] = useState('');
  const [companyNameVal] = useState('');
  const [popupViewOpen, setPopupViewOpen] = useState(false);
  const [warehouseNameVal] = useState('');
  const [selectedInventories, setSelectedInventories] = useState([]);
  const [selectedInventoriesWeight, setSelectedInventoriesWeight] =
    useState(null);
  const [allHoldInventoryIds, setAllHoldInventoryIds] = useState([]);
  const [allAvailableInventoryIds, setAllAvailableInventoryIds] = useState([]);
  const [allHoldBatchesIds, setAllHoldBatchesIds] = useState([]);
  const [allAvailableBatchesIds, setAllAvailableBatchesIds] = useState([]);
  const [selectedInventoriesForHold, setSelectedInventoriesForHold] = useState(
    []
  );
  const [selectedInventoriesForRelease, setSelectedInventoriesForRelease] =
    useState([]);
  const [selectedHoldQuantity, setSelectedHoldQuantity] = useState(0);
  const [selectedReleaseQuantity, setSelectedReleaseQuantity] = useState(0);
  const [allHoldQuantity, setAllHoldQuantity] = useState(0);
  const [allAvailableQuantity, setAllAvailableQuantity] = useState(0);
  const [allHoldBatchesQuantity, setAllHoldBatchesQuantity] = useState(0);
  const [allAvailableBatchesQuantity, setAllAvailableBatchesQuantity] =
    useState(0);
  const [allInventorySelected, setAllInventorySelection] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState();
  const [userPreferences, setUserPreferences] = useState(null);
  const [stockAdjusmtentViewOpen, setStockAdjustmentViewOpen] = useState(false);
  const [stockConditionViewOpen, setStockConditionViewOpen] = useState(false);
  const [holdInventoryViewOpen, setHoldInventoryViewOpen] = useState(false);
  const [releaseInventoryViewOpen, setReleaseInventoryViewOpen] =
    useState(false);
  const [sectionRelocationViewOpen, setSectionRelocationViewOpen] =
    useState(false);
  const [selectedWarehouseIds, setSelectedWarehouseIds] = useState([]);

  const [columnNames, setColumnNames] = useState([
    'Sku & Node Name',
    'Total Qty & UoM',
    'Secondary Qty & UoM',
    'Available',
    'On Hold',
    ...(isFeatureEnabled(FLAGS.PICKING) ? ['Reserved'] : []),
    'Damaged'
  ]);
  const [UoMs, setUoms] = useState([]);
  const [brands, setBrands] = useState([]);
  const [organizationNodes, setOrganizationNodes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [refetchStats, setRefetchStats] = useState(false);

  const filterInitialState = {
    Status: [],
    'SKU Code / Name': [],
    Brands: [],
    Categories: [],
    Nodes: [],
    UOM: []
  };

  if (isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)) {
    filterInitialState.Batches = [];
  }

  const [sortable] = useState('');
  const [hoveredRow, setHoveredRow] = useState(-1);
  const [, setOptionsClick] = useState(false);
  const tableContainerRef = useRef(null);

  const [contentHover, setContentHover] = useState(false);
  const [advancedfilters, setFilters] = useState(filterInitialState);
  const [batchMode, setBatchMode] = useState(false);
  const [reverseSort, setReverseSort] = useState(false);

  useEffect(() => {
    const savedFilters = localStorage.getItem('inventoryFilters');
    if (savedFilters) {
      setFilters(JSON.parse(savedFilters));
    }
    const savedSearch = localStorage.getItem('inventorySearch');
    if (savedSearch) {
      setSearchKeyword(savedSearch);
    }
  }, []);

  useEffect(() => {
    mixpanel.track('Inventory Management', {});
  }, []);

  useEffect(() => {
    setPage(1);
    localStorage.setItem('inventorySearch', searchKeyword);
  }, [searchKeyword]);

  useEffect(() => {
    localStorage.setItem('inventoryFilters', JSON.stringify(advancedfilters));
  }, [advancedfilters]);

  const setFilter = (key, value, type, filterType) => {
    let currentFilters;
    if (!filterType) {
      currentFilters = { ...advancedfilters };
    }
    let copiedArr = [...currentFilters[key]];
    if (type === 'add') {
      copiedArr.push(value);
    } else {
      copiedArr = currentFilters[key].filter((v) => v.value != value.value);
    }
    delete currentFilters[key];
    currentFilters[key] = copiedArr;
    if (!filterType) {
      setFilters(currentFilters);
      localStorage.setItem('inventoryFilters', JSON.stringify(currentFilters));
      mixpanel.track('Inventory Filters Applied', { currentFilters });
    }
  };
  useEffect(() => {
    const handleScrolling = (event) => {
      if (tableContainerRef) {
        const tableBottom =
          tableContainerRef.current?.getBoundingClientRect().bottom;
        const viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        if (contentHover === false && tableBottom + 68 === viewportHeight) {
          tableContainerRef.current.scrollTop += event.deltaY;
        }
      }
    };
    window.addEventListener('wheel', handleScrolling);
    return () => {
      window.removeEventListener('wheel', handleScrolling);
    };
  }, [contentHover, tableContainerRef.current]);

  useEffect(() => {
    setCurrentPageTitle('Inventory Management');
  }, [organizationType, userPreferences]);

  const flattenInventories = (data) => {
    if (batchMode) {
      return data?.flatMap((item) =>
        (item?.InventoryDetail ?? [])
          .filter(
            (detail) =>
              detail.availableQuantity > 0 ||
              detail.holdQuantity > 0 ||
              detail.reservedQuantity > 0 ||
              detail.recoverableDamageQuantity > 0
          )
          .map((detail) => ({
            ...detail,
            Product: item?.Product,
            Warehouse: item?.Warehouse
          }))
      );
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (organizationType) {
      if (batchMode) {
        setColumnNames([
          'Sku & Node Name',
          ...(userPreferences &&
          userPreferences?.payload?.columns?.indexOf('Brand & Category') !== -1
            ? ['Brand & Category']
            : []),
          'Batch No, MFG & Exp.',
          'Total Qty & UoM',
          ...(userPreferences &&
          userPreferences?.payload?.columns?.indexOf('Secondary Qty & UoM') !== -1
              ? ['Secondary Qty & UoM']
              : []),
          'Available',
          'On Hold',
          ...(isFeatureEnabled(FLAGS.PICKING) ? ['Reserved'] : []),
          'Damaged'
        ]);
        const flatInventories = flattenInventories(inventories);
        setInventoriesBatches(flatInventories);
      } else if (
        organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
      ) {
        setColumnNames([
          'Sku & Node Name',
          ...(userPreferences &&
          userPreferences?.payload?.columns?.indexOf('Brand & Category') !== -1
            ? ['Brand & Category']
            : []),
          'Total Qty & UoM',
          ...(userPreferences &&
          userPreferences?.payload?.columns?.indexOf('Secondary Qty & UoM') !== -1
              ? ['Secondary Qty & UoM']
              : []),
          'Available',
          'On Hold',
          ...(userPreferences &&
          userPreferences?.payload?.columns?.indexOf('In-Transit') !== -1
            ? ['In-Transit']
            : []),
          ...(isFeatureEnabled(FLAGS.PICKING) ? ['Reserved'] : []),
          'Damaged',
          ...(userPreferences &&
          userPreferences?.payload?.columns?.indexOf('Ordered') !== -1
            ? ['Ordered']
            : []),
          ...(userPreferences &&
          userPreferences?.payload?.columns?.indexOf('Weight & Volume') !== -1
            ? ['Weight/Volume']
            : [])
        ]);
      } else {
        setColumnNames([
          'Sku & Node Name',
          ...(userPreferences &&
          userPreferences?.payload?.columns?.indexOf('Brand & Category') !== -1
            ? ['Brand & Category']
            : []),
          'Total Qty & UoM',
          ...(userPreferences &&
          userPreferences?.payload?.columns?.indexOf('Secondary Qty & UoM') !== -1
              ? ['Secondary Qty & UoM']
              : []),
          'Available',
          'On Hold',
          ...(userPreferences &&
          userPreferences?.payload?.columns?.indexOf('In-Transit') !== -1
            ? ['In-Transit']
            : []),
          ...(isFeatureEnabled(FLAGS.PICKING) ? ['Reserved'] : []),
          'Damaged',
          ...(userPreferences &&
          userPreferences?.payload?.columns?.indexOf('Ordered') !== -1
            ? ['Ordered']
            : []),
          ...(userPreferences &&
          userPreferences?.payload?.columns?.indexOf('Weight & Volume') !== -1
            ? ['Weight/Volume']
            : []),
          ...(userPreferences &&
          userPreferences?.payload?.columns?.indexOf('Stock Value') !== -1
            ? ['Stock Value On Hand']
            : [])
        ]);
      }
    }
  }, [batchMode, userPreferences]);

  const { refetch, isFetching: inventoryLoading } = useQuery(
    ['fetchInventories', advancedfilters],
    () => {
      let colVal = {};
      if (productNameVal) {
        colVal['$Product.name$'] = productNameVal;
      }

      if (
        companyNameVal &&
        organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
      ) {
        colVal['$Company.name$'] = companyNameVal;
      }

      if (warehouseNameVal) {
        colVal['$Warehouse.businessWarehouseCode$'] = warehouseNameVal;
      }

      let filters = {
        colVal,
        sortable
      };

      let columns = [
        '$Product.name$',
        '$Product.description$',
        '$Warehouse.name$',
        '$Warehouse.businessWarehouseCode$'
      ];
      if (
        organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
      ) {
        columns.push('$Company.name$');
      }
      let advanceFilters = Object.entries(advancedfilters).map(
        ([key, values]) => ({
          [key]: values.flat().map((item) => item.value || item)
        })
      );
      const payload = {
        page,
        searchKeyword,
        filters,
        columns,
        order: reverseSort ? 'DESC' : 'ASC',
        advanceFilters,
        excludeBatches: advancedfilters['Batches']?.length > 0 ? false : true
      };
      return getInventories(payload);
    },
    {
      enabled: true,
      onSuccess: (data) => {
        if (data) {
          if (page === 1) {
            setInventories(data.data);
            setInventoryCount(data.count);
            setPageCount(data.pages);
            setSelectedReleaseQuantity(0);
            setSelectedHoldQuantity(0);
            setSelectedInventories([]);
            setSelectedInventoriesForHold([]);
            setSelectedInventoriesForRelease([]);
            setInventoriesBatches(flattenInventories(data.data));
          } else {
            setInventories([...inventories, ...data.data]);
            setInventoryCount(data.count);
            setPageCount(data.pages);
            setInventoriesBatches(
              flattenInventories([...inventories, ...data.data])
            );
          }
        }
      },
      onError: (err) => {
        console.log(err);
      }
    }
  );

  const _getInventories = useCallback(
    debounce(() => {
      refetch();
    }, DEBOUNCE_CONST),
    []
  );

  const handleAllCheckboxChanges = () => {
    let newSelected = [];
    let newSelectedForHold = [];
    let newSelectedForRelease = [];
    // eslint-disable-next-line no-unused-vars
    let totalWeight = 0;
    let allAvailable = 0;
    let allHold = 0;
    let inventoryIdHash = {};

    inventories.forEach((inventory) => {
      if (batchMode) {
        inventory?.InventoryDetail?.forEach((batch) => {
          if (batch?.availableQuantity > 0) {
            newSelectedForHold = [...newSelectedForHold, batch.id];
            allAvailable += batch?.availableQuantity;
          }
          if (batch?.holdQuantity > 0) {
            newSelectedForRelease = [...newSelectedForRelease, batch.id];
            allHold += batch?.holdQuantity;
          }
          newSelected = [...newSelected, batch.id];
        });
        if (!inventoryIdHash[inventory.id]) {
          inventoryIdHash[inventory.id] = true;
          totalWeight += inventory.Product?.weight || 0;
        }
      } else {
        if (inventory?.availableQuantity > 0) {
          newSelectedForHold = [...newSelectedForHold, inventory.id];
          allAvailable += inventory?.availableQuantity;
        }
        if (inventory?.holdQuantity > 0) {
          newSelectedForRelease = [...newSelectedForRelease, inventory.id];
          allHold += inventory?.holdQuantity;
        }
        newSelected = [...newSelected, inventory.id];
      }
    });

    if (batchMode) {
      setAllHoldBatchesQuantity(allHold);
      setAllAvailableBatchesQuantity(allAvailable);
      setAllAvailableBatchesIds(newSelectedForHold);
      setAllHoldBatchesIds(newSelectedForRelease);
    } else {
      setAllAvailableQuantity(allAvailable);
      setAllHoldQuantity(allHold);
      setAllAvailableInventoryIds(newSelectedForHold);
      setAllHoldInventoryIds(newSelectedForRelease);
    }

    setSelectedInventories(newSelected);
  };

  const clearAllSelectionState = () => {
    setAllAvailableQuantity(0);
    setAllHoldQuantity(0);
    setAllAvailableInventoryIds([]);
    setAllHoldInventoryIds([]);
    setAllHoldBatchesQuantity(0);
    setAllAvailableBatchesQuantity(0);
    setAllAvailableBatchesIds([]);
    setAllHoldBatchesIds([]);
    setSelectedInventories([]);
  };

  useEffect(() => {
    _getInventories();
    if (allInventorySelected) handleAllCheckboxChanges();
  }, [page, searchKeyword, reverseSort]);

  useEffect(() => {
    if (advancedfilters['Batches']?.length > 0) {
      setBatchMode(true);
    } else {
      setBatchMode(false);
    }
  }, [advancedfilters]);

  const handleTableScroll = () => {
    const container = tableContainerRef.current;
    if (
      container.scrollTop + container.clientHeight + 20 >=
        container.scrollHeight &&
      !inventoryLoading &&
      page < pageCount
    ) {
      setPage(page + 1);
    }
  };

  const applyHold = async (batches) => {
    setAPILoader(true);
    try {
      await API.put(`inventories/hold`, batches);
      await refetch();
      toaster('success', 'Hold Applied Successfully');
    } catch (e) {
    } finally {
      setAPILoader(false);
    }
  };

  const applyRelease = async (batches) => {
    setAPILoader(true);
    try {
      await API.put(`inventories/hold/remove`, batches);
      await refetch();
      setOpen(false);
      setBatches([]);
      toaster('success', 'Inventory Released Successfully');
    } catch (e) {
      console.error(e);
    } finally {
      setAPILoader(false);
    }
  };

  const applyStockConditioning = async (payload) => {
    try {
      await API.put('inventories/update-stock-condition', payload);
      await refetch();
      toaster('success', 'Stock Conditioning Performed Successfully');
    } catch (e) {
    } finally {
      setAPILoader(false);
    }
  };

  const inventoryOptions = [
    ...(checkPermission(currentUser, 'OPS_BATCH_UPDATE') &&
    selectedInventory?.Product?.batchEnabled &&
    selectedInventory?.reservedQuantity === 0 &&
    batchMode
      ? [
          {
            label: (
              <EditBatch
                selectedInventoryDetails={selectedInventory}
                currentUser={currentUser}
                product={selectedInventory?.Product}
                refetch={refetch}
                closeCallback={() => {
                  setOptionsClick(false);
                }}
              />
            )
          }
        ]
      : []),
    ...(checkPermission(currentUser, 'OPS_HOLD_APPLY') &&
    isFeatureEnabled(FLAGS.INVENTORY_HOLD) &&
    selectedInventory?.availableQuantity > 0
      ? [
          {
            label:
              selectedInventory?.InventoryDetail?.length > 1 ? (
                <div
                  onClick={() => {
                    setSelectedInventories([selectedInventory?.id]);
                    setHoldInventoryViewOpen(true);
                    setOptionsClick(false);
                  }}
                  className="flex gap-2 w-full"
                >
                  <img src={HoldFadedIcon} />
                  <span className="text-xs">Hold</span>
                </div>
              ) : !batchMode ? (
                <HoldInventoryPopover
                  available={
                    selectedInventory?.InventoryDetail?.[0]?.availableQuantity
                  }
                  damaged={
                    selectedInventory?.InventoryDetail?.[0]
                      ?.recoverableDamageQuantity
                  }
                  uoms={[
                    {
                      ...selectedInventory?.Product?.UOM,
                      primary: true,
                      conversionFactor: 1
                    }
                  ]}
                  actionCallback={async (
                    stockCondition,
                    goodQuantity,
                    damagedQuantity
                  ) => {
                    let payload = [];
                    if (stockCondition == 'RECOVERABLE') {
                      payload.push({
                        inventoryId: selectedInventory.id,
                        inventoryDetailId:
                          selectedInventory?.InventoryDetail?.[0]?.id,
                        goodQuantity: Number(goodQuantity),
                        source: {
                          damageQuantity: Number(damagedQuantity)
                        }
                      });
                    } else {
                      payload.push({
                        inventoryId: selectedInventory.id,
                        inventoryDetailId:
                          selectedInventory?.InventoryDetail?.[0]?.id,
                        damageQuantity: Number(damagedQuantity),
                        source: {
                          goodQuantity: Number(goodQuantity)
                        }
                      });
                    }
                    await applyStockConditioning(payload);
                  }}
                />
              ) : (
                <HoldInventoryPopover
                  available={selectedInventory?.availableQuantity}
                  uoms={[
                    {
                      ...selectedInventory?.Product?.UOM,
                      primary: true,
                      conversionFactor: 1
                    },
                    ...selectedInventory?.Product?.ProductUOMs?.map((uom) => ({
                      name: uom?.UOM?.name,
                      conversionFactor: uom?.UOM?.conversionFactor,
                      primary: false
                    }))
                  ]}
                  actionCallback={async (qty, comment) => {
                    let payload = [
                      {
                        inventoryId: selectedInventory?.inventoryId,
                        inventoryDetailId: selectedInventory?.id,
                        quantity: qty,
                        comment
                      }
                    ];
                    await applyHold(payload);
                    setRefetchStats(true);
                  }}
                />
              )
          }
        ]
      : []),
    ...(isFeatureEnabled(FLAGS.STOCK_CONDITION_MANAGEMENT) &&
    (selectedInventory?.availableQuantity > 0 ||
      selectedInventory?.recoverableDamageQuantity > 0)
      ? [
          {
            label:
              selectedInventory?.InventoryDetail?.length > 0 ? (
                <div
                  onClick={() => {
                    setSelectedInventories([selectedInventory?.id]);
                    setStockConditionViewOpen(true);
                    setOptionsClick(false);
                  }}
                  className="flex gap-2 w-full"
                >
                  <img src={ConditionIcon} />
                  <span className="text-xs">Stock Condition</span>
                </div>
              ) : !batchMode ? (
                <StockConditionPopover
                  available={
                    selectedInventory?.InventoryDetail?.[0]?.availableQuantity
                  }
                  damaged={
                    selectedInventory?.InventoryDetail?.[0]
                      ?.recoverableDamageQuantity
                  }
                  uoms={[
                    {
                      ...selectedInventory?.Product?.UOM,
                      primary: true,
                      conversionFactor: 1
                    }
                  ]}
                  actionCallback={async (
                    stockCondition,
                    goodQuantity,
                    damagedQuantity
                  ) => {
                    let payload = [];
                    if (stockCondition == 'RECOVERABLE') {
                      payload.push({
                        inventoryId: selectedInventory.id,
                        inventoryDetailId:
                          selectedInventory?.InventoryDetail?.[0]?.id,
                        goodQuantity: Number(goodQuantity),
                        source: {
                          damageQuantity: Number(damagedQuantity)
                        }
                      });
                    } else {
                      payload.push({
                        inventoryId: selectedInventory.id,
                        inventoryDetailId:
                          selectedInventory?.InventoryDetail?.[0]?.id,
                        damageQuantity: Number(damagedQuantity),
                        source: {
                          goodQuantity: Number(goodQuantity)
                        }
                      });
                    }
                    await applyStockConditioning(payload);
                  }}
                />
              ) : (
                <StockConditionPopover
                  available={selectedInventory?.availableQuantity}
                  damaged={selectedInventory?.recoverableDamageQuantity}
                  uoms={[
                    {
                      ...selectedInventory?.Product?.UOM,
                      primary: true,
                      conversionFactor: 1
                    }
                  ]}
                  actionCallback={async (
                    stockCondition,
                    goodQuantity,
                    damagedQuantity
                  ) => {
                    let payload = [];
                    if (stockCondition == 'RECOVERABLE') {
                      payload.push({
                        inventoryId: selectedInventory.inventoryId,
                        inventoryDetailId: selectedInventory?.id,
                        goodQuantity: Number(goodQuantity),
                        source: {
                          damageQuantity: Number(damagedQuantity)
                        }
                      });
                    } else {
                      payload.push({
                        inventoryId: selectedInventory.inventoryId,
                        inventoryDetailId: selectedInventory?.id,
                        damageQuantity: Number(damagedQuantity),
                        source: {
                          goodQuantity: Number(goodQuantity)
                        }
                      });
                    }
                    await applyStockConditioning(payload);
                  }}
                />
              )
          }
        ]
      : []),
    ...(checkPermission(currentUser, 'OPS_HOLD_REMOVE') &&
    isFeatureEnabled(FLAGS.INVENTORY_HOLD) &&
    selectedInventory?.holdQuantity > 0
      ? [
          {
            label:
              selectedInventory?.InventoryDetail?.filter(
                (id) => id.holdQuantity > 0
              )?.length > 1 ? (
                <div
                  onClick={() => {
                    setSelectedInventories([selectedInventory?.id]);
                    setReleaseInventoryViewOpen(true);
                    setOptionsClick(false);
                  }}
                  className="flex gap-2 w-full"
                >
                  <img src={ReleaseFadedIcon} />
                  <span className="text-xs">Release Inventory</span>
                </div>
              ) : !batchMode ? (
                <ReleaseInventoryPopover
                  holdQuantity={
                    selectedInventory?.InventoryDetail?.filter(
                      (id) => id.holdQuantity > 0
                    )?.[0]?.holdQuantity
                  }
                  uoms={[
                    { ...selectedInventory?.Product?.UOM, primary: true },
                    ...selectedInventory?.Product?.ProductUOMs?.map((uom) => ({
                      name: uom?.UOM?.name,
                      conversionFactor: uom?.UOM?.conversionFactor,
                      primary: false
                    }))
                  ]}
                  actionCallback={async (releaseQty) => {
                    let payload = [
                      {
                        inventoryId: selectedInventory?.id,
                        inventoryDetailId:
                          selectedInventory?.InventoryDetail?.filter(
                            (id) => id.holdQuantity > 0
                          )?.[0]?.id,
                        quantity: releaseQty
                      }
                    ];

                    await API.put(`inventories/hold/remove`, payload);
                    setRefetchStats(true);
                  }}
                />
              ) : (
                <ReleaseInventoryPopover
                  holdQuantity={selectedInventory?.holdQuantity}
                  uoms={[
                    { ...selectedInventory?.Product?.UOM, primary: true },
                    ...selectedInventory?.Product?.ProductUOMs?.map((uom) => ({
                      name: uom?.UOM?.name,
                      conversionFactor: uom?.UOM?.conversionFactor,
                      primary: false
                    }))
                  ]}
                  actionCallback={async (releaseQty) => {
                    let payload = [
                      {
                        inventoryId: selectedInventory?.inventoryId,
                        inventoryDetailId: selectedInventory?.id,
                        quantity: releaseQty
                      }
                    ];

                    await API.put(`inventories/hold/remove`, payload);
                    await refetch();
                    setRefetchStats(true);
                  }}
                />
              )
          }
        ]
      : [])
  ];

  const handleCheckboxChange = (id, inventory) => {
    const selectedIndex = selectedInventories.indexOf(id);
    let newSelected = [...selectedInventories];
    let newSelectedForHold = [...selectedInventoriesForHold];
    let newSelectedForRelease = [...selectedInventoriesForRelease];
    let warehouseIds = [...selectedWarehouseIds];
    let totalWeight = selectedInventoriesWeight || 0;
    if (selectedIndex === -1) {
      if (inventory?.availableQuantity > 0) {
        newSelectedForHold = [...newSelectedForHold, id];
      }
      if (inventory?.holdQuantity > 0) {
        newSelectedForRelease = [...newSelectedForRelease, id];
      }
      newSelected = [...newSelected, id];
      warehouseIds = [...warehouseIds, inventory.Warehouse?.id];
      totalWeight += inventory.Product?.weight || 0;
    } else {
      newSelected.splice(selectedIndex, 1);
      warehouseIds.splice(selectedIndex, 1);
      if (inventory.availableQuantity > 0) {
        const indexForHold = newSelectedForHold.indexOf(id);
        if (indexForHold !== -1) {
          newSelectedForHold.splice(indexForHold, 1);
        }
      }
      if (inventory.holdQuantity > 0) {
        const indexForRelease = newSelectedForRelease.indexOf(id);
        if (indexForRelease !== -1) {
          newSelectedForRelease.splice(indexForRelease, 1);
        }
      }
      totalWeight -= inventory.Product?.weight || 0;
    }
    setSelectedInventories(newSelected);
    setSelectedInventoriesForHold(newSelectedForHold);
    setSelectedInventoriesForRelease(newSelectedForRelease);
    calculateSelectedQuantities(newSelectedForHold, newSelectedForRelease);
    setSelectedInventoriesWeight(totalWeight);
    setSelectedWarehouseIds(warehouseIds);
  };

  const calculateSelectedQuantities = (
    newSelectedForHold,
    newSelectedForRelease
  ) => {
    let totalAvailable = 0;
    let totaHold = 0;

    newSelectedForHold.forEach((id) => {
      const inventory = batchMode
        ? inventoriesBatches.find((item) => item.id === id)
        : inventories.find((item) => item.id === id);
      if (inventory) {
        totalAvailable += inventory?.availableQuantity;
      }
    });
    newSelectedForRelease.forEach((id) => {
      const inventory = batchMode
        ? inventoriesBatches.find((item) => item.id === id)
        : inventories.find((item) => item.id === id);
      if (inventory) {
        totaHold += inventory?.holdQuantity;
      }
    });

    setSelectedHoldQuantity(totalAvailable);
    setSelectedReleaseQuantity(totaHold);
  };

  const { isLoading: loadingHoldInventory, mutate: mutateHoldInventory } =
    useMutation(holdInventory, {
      onMutate: () => {
        setAPILoader(true);
      },
      onSuccess: () => {
        if (page !== 1) {
          setPage(1);
        } else {
          refetch();
          if (allInventorySelected) handleAllCheckboxChanges();
        }
        toaster('success', 'Hold Successful!');
      },
      onError: (error) => {
        onError({ response: error.response });
      },
      onSettled: () => {
        setAPILoader(false);
      }
    });

  const holdSelectedInventories = () => {
    if (selectedInventories.length > 0) {
      if (batchMode) {
        mutateHoldInventory({
          inventoryDetailIds: selectedInventoriesForHold
        });
      } else {
        mutateHoldInventory({
          inventoryIds: selectedInventoriesForHold
        });
      }
    }
  };

  const holdAllInventories = () => {
    if (allHoldInventoryIds.length > 0) {
      if (batchMode) {
        mutateHoldInventory({
          inventoryDetailIds: allHoldBatchesIds
        });
      } else {
        mutateHoldInventory({
          inventoryIds: allHoldInventoryIds
        });
      }
    }
  };

  const {
    isLoading: loadingRemoveHoldInventory,
    mutate: mutateRemoveHoldInventory
  } = useMutation(removeHoldFromInventory, {
    onMutate: () => {
      setAPILoader(true);
    },
    onSuccess: () => {
      if (page !== 1) {
        setPage(1);
      } else {
        refetch();
        if (allInventorySelected) handleAllCheckboxChanges();
      }
      toaster('success', 'Hold Removed Successfully!');
    },
    onError: (error) => {
      onError({ response: error.response });
    },
    onSettled: () => {
      setAPILoader(false);
    }
  });

  const removedHoldFromSelectedInventories = () => {
    if (selectedInventories.length > 0) {
      if (batchMode) {
        mutateRemoveHoldInventory({
          inventoryDetailIds: selectedInventoriesForRelease
        });
      } else {
        mutateRemoveHoldInventory({
          inventoryIds: selectedInventoriesForRelease
        });
      }
    }
  };

  const removedHoldFromAllInventories = () => {
    if (allAvailableInventoryIds.length > 0) {
      if (batchMode) {
        mutateRemoveHoldInventory({
          inventoryDetailIds: allAvailableBatchesIds
        });
      } else {
        mutateRemoveHoldInventory({
          inventoryIds: allAvailableInventoryIds
        });
      }
    }
  };

  useQuery(
    ['inventoryWeights'],
    () => {
      return getInventoriesWeight();
    },
    {
      enabled: true,
      onSuccess: (data) => {
        const stats = data[0];
        const totalWeight =
          +stats?.totalAvailableWeight +
          +stats?.totalHoldWeight +
          +stats?.totalRecoverableDamageWeight +
          +stats?.totalReservedWeight;
        setTotalInventoryWeight(totalWeight);
      }
    }
  );

  const classes = useStyles();

  const tableCellClass = 'py-2 h-12 pl-0 pr-2';
  const cellValue = 'table-cell-trucate  text-normal';
  const cellSubValue = clsx(cellValue, 'text-[10px]');
  const topValue = clsx(cellValue, 'mb-[2px]', 'text-xs');
  const digitValue = clsx(cellValue, 'mt-[2px]', 'text-xs');

  const exportToExcel = async () => {
    let columns = [
      '$Product.name$',
      '$Product.description$',
      '$Warehouse.name$',
      '$Warehouse.businessWarehouseCode$'
    ];
    if (organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER) {
      columns.push('$Company.name$');
    }
    let colVal = {};
    if (productNameVal) {
      colVal = { '$Product.name$': productNameVal };
    }

    if (warehouseNameVal) {
      colVal = { '$Warehouse.businessWarehouseCode$': warehouseNameVal };
    }

    let filters = {
      colVal,
      sortable
    };
    let advanceFilters = Object.entries(advancedfilters).map(
      ([key, values]) => ({
        [key]: values.flat().map((item) => item.value || item)
      })
    );
    try {
      const inventories = await API.get(`inventories/export`, {
        responseType: 'blob',
        params: {
          offset: page,
          search: searchKeyword,
          filters,
          columns,
          advanceFilters
        }
      });
      FileDownload(
        inventories,
        `Inventory ${moment().format('DD-MM-yyyy')}.xlsx`
      );
      mixpanel.track('Product Export', { columns });
    } catch (err) {
      onError(err);
    }
  };

  const fileUpload = async (data) => {
    setAPILoader(true);
    const formattedData = data.map((row) => ({
      nodeName: row['Warehouse/ Node Name'],
      companyName: row['Entity Code/ Name'] || null,
      productName: row['Product Code/Name'],
      binLocation: row['Bin Location'],
      batchNumber: row['Batch number'],
      mfgDate: row['Mfg Date'],
      expiryDate: row['Expiry Date'],
      MRP: row['MRP'],
      qty: row['Qty']
    }));

    let filteredRows;

    if (organizationType == 'MANUFACTURER') {
      filteredRows = formattedData.filter(
        (row) => row.nodeName && row.productName && row.binLocation && row.qty
      );
    } else {
      filteredRows = formattedData.filter(
        (row) =>
          row.nodeName &&
          row.productName &&
          row.binLocation &&
          row.qty &&
          row.companyName
      );
    }

    const res = await API.post('inventories/bulk-validate', filteredRows);
    let payload = {};

    for (const row of res.correct) {
      const key = `${row.product.id}-${row.warehouseId}-${row.companyId ?? ''}`;
      if (!payload[key]) {
        payload[key] = {
          product: row.product,
          quantity: Number(row.qty),
          warehouseId: row.warehouseId,
          companyId: row.companyId,
          nodeName: row.nodeName
        };
        if (row.product.batchEnabled) {
          payload[key].batches = [
            {
              batchNumber: row.batchNumber,
              expiryDate: row.expiryDate,
              manufacturingDate: row.mfgDate,
              MRP: row.MRP ? Number(row.MRP) : null,
              quantity: Number(row.qty),
              hallId: row.hallId,
              hall: row.hall,
              binLocation: row.binLocation
            }
          ];
        } else {
          payload[key].batches = [
            {
              batchNumber: null,
              expiryDate: null,
              manufacturingDate: null,
              MRP: null,
              quantity: Number(row.qty),
              hallId: row.hallId,
              hall: row.hall,
              binLocation: row.binLocation
            }
          ];
        }
      } else {
        payload[key].quantity += Number(row.qty);
        const batches = payload[key].batches;
        if (row.product.batchEnabled) {
          if (!row.batchNumber) {
            let findIndex = batches.findIndex(
              (b) => b.expiryDate == row.expiryDate && b.hallId == row.hallId
            );
            if (findIndex >= 0) {
              batches[findIndex].quantity += Number(row.qty);
            } else {
              batches.push({
                batchNumber: row.batchNumber,
                expiryDate: row.expiryDate,
                manufacturingDate: row.mfgDate,
                MRP: row.MRP ? Number(row.MRP) : null,
                quantity: Number(row.qty),
                hallId: row.hallId,
                hall: row.hall,
                binLocation: row.binLocation
              });
            }
          } else {
            let findIndex = batches.findIndex(
              (b) =>
                b.batchNumber == row.batchNumber &&
                b.expiryDate == row.expiryDate &&
                b.hallId == row.hallId
            );
            if (findIndex >= 0) {
              batches[findIndex].quantity += Number(row.qty);
            } else {
              batches.push({
                batchNumber: row.batchNumber,
                expiryDate: row.expiryDate,
                manufacturingDate: row.mfgDate,
                MRP: row.MRP ? Number(row.MRP) : null,
                quantity: Number(row.qty),
                hallId: row.hallId,
                hall: row.hall,
                binLocation: row.binLocation
              });
            }
          }
        } else {
          const findIndex = batches.findIndex((b) => b.hallId == row.hallId);
          if (findIndex > 0) {
            batches[findIndex].quantity += Number(row.qty);
          } else {
            batches.push({
              batchNumber: null,
              expiryDate: null,
              manufacturingDate: null,
              MRP: null,
              quantity: Number(row.qty),
              hallId: row.hallId,
              hall: row.hall,
              binLocation: row.binLocation
            });
          }
        }
      }
    }

    setBulkPayload(payload);
    setPopupViewOpen(true);
    setBulkBtnOpen(false);
    setAPILoader(false);

    let incorrectPayload = formattedData.filter(
      (row) => !row.nodeName || !row.productName || !row.binLocation || !row.qty
    );
    setIncorrect([...incorrectPayload, ...res.incorrect]);
  };

  const bulkUpload = async () => {
    const payloadArray = Object.keys(bulkPayload).map(
      (key) => bulkPayload[key]
    );
    await API.post('inventories/bulk-upload', payloadArray);
    mixpanel.track('Product Bulk Upload', {
      productCount: payloadArray?.length
    });
  };

  const getUserColumnPreference = async () => {
    try {
      const response = await API.get('user-column-preferences/Inventories');
      setUserPreferences(response);
    } catch (e) {}
  };

  const updatePreference = async (col, checked) => {
    try {
      if (checked) {
        let payload = userPreferences
          ? {
              ...userPreferences,
              payload: {
                columns: userPreferences?.payload?.columns
                  ? [...userPreferences.payload.columns, col]
                  : [col]
              }
            }
          : { payload: { columns: [col] } };
        await API.put('user-column-preferences/Inventories', payload);
        await getUserColumnPreference();
        toaster('success', 'User Preference Updated Successfully!');
      } else {
        let payload = {
          ...userPreferences,
          payload: {
            columns: userPreferences?.payload?.columns?.filter(
              (column) => column !== col
            )
          }
        };
        await API.put('user-column-preferences/Inventories', payload);
        await getUserColumnPreference();
        toaster('success', 'User Preference Updated Successfully!');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const exportToXlsx = () => {
    let nodes = incorrect?.map((row) => [
      row.nodeName,
      row.companyName,
      row.productName,
      row.binLocation,
      row.batchNumber,
      row.mfgDate,
      row.expiryDate,
      row.MRP,
      row.qty,
      row.message
    ]);
    const data = [
      [
        'Warehouse/ Node Name',
        'Entity Code/ Name',
        'Product Code/Name',
        'Bin Location',
        'Batch number',
        'Mfg Date',
        'Expiry Date',
        'MRP',
        'Qty',
        'Reason'
      ],
      ...nodes
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'incorrect_inventories');

    // Save the workbook as an XLSX file
    XLSX.writeFile(wb, 'incorrect_inventories.xlsx');
  };

  useEffect(() => {
    refetch();
    getUserColumnPreference();
  }, []);

  const getCategories = async (search) => {
    try {
      const cols = ['name'];
      const res = await API.get('categories', {
        params: {
          search: search,
          columns: cols
        }
      });
      const newArr = res?.data;
      setCategories([...newArr]);
    } catch (error) {
      onError(error);
    }
  };

  const getBrands = async (search) => {
    try {
      const cols = ['name'];
      const res = await API.get(`brands`, {
        params: {
          search: search,
          columns: cols
        }
      });
      const newArr = res?.data;
      setBrands([...newArr]);
    } catch (error) {
      onError(error);
    }
  };

  const getProducts = async (search) => {
    try {
      const res = await API.get(`products`, {
        params: {
          search: search,
          columns: ['name', 'description']
        }
      });
      const newArr = res?.data;
      setProducts([...newArr]);
    } catch (error) {
      onError(error);
    }
  };

  const getUoms = async (searchKeyword) => {
    try {
      const uoms = await API.get(`uoms/`, {
        params: { search: searchKeyword }
      });
      setUoms(uoms.data?.filter((u) => u.isActive === true) || []);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };
  const getOrgNodes = async (search) => {
    try {
      const response = await API.get(`nodes`, {
        params: { search: search, nodeClass: 'INTERNAL' }
      });
      setOrganizationNodes(response?.data);
    } catch (err) {
      onError(err);
    }
  };

  const createStockAdjustment = async (batches) => {
    setAPILoader(true);
    const payload = [];
    for (let i = 0; i < batches.length; i++) {
      let index = payload.findIndex(
        (b) => b.inventoryId == batches[i].inventoryId
      );
      if (batches[i].quantity == 0) {
        continue;
      }
      if (index !== -1) {
        payload[index].batches.push({
          stockCondition: batches[i].stockCondition,
          quantity: batches[i].quantity,
          id: batches[i].id,
          type: batches[i].type?.id
        });
      } else {
        payload.push({
          batches: [
            {
              stockCondition: batches[i].stockCondition,
              quantity: batches[i].quantity,
              id: batches[i].id,
              type: batches[i].type?.id
            }
          ],
          inventoryId: batches[i].inventoryId
        });
      }
    }

    const url = window.location.href.replace(
      'create',
      'view/stockAdjustmentId'
    );
    await API.post(`stock-Adjustments`, {
      products: payload,
      hostUrl: url
    });
    await refetch();
    toaster('success', 'Stock Adjustment Performed Successfully');
    setAPILoader(false);
  };

  useEffect(() => {
    getUoms();
    getBrands();
    getOrgNodes();
    getCategories();
    getProducts();
  }, []);

  return (
    <div className="no-scrollbar overflow-hidden">
      <AddProductPopup
        open={addProductView}
        setOpen={setAddProductView}
        organizationType={organizationType}
      />
      <InventoryStats
        refetchStats={refetchStats}
        setRefetchStats={setRefetchStats}
      />
      <div className="w-full mt-10 pl-8 pr-6">
        <InventoryHead
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
          addProductView={addProductView}
          setAddProductView={setAddProductView}
          filters={advancedfilters}
          setFilter={setFilter}
          setFilters={setFilters}
          loading={inventoryLoading}
          bulkBtnOpen={bulkBtnOpen}
          setBulkBtnOpen={setBulkBtnOpen}
          fileUpload={fileUpload}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          products={products}
          brands={brands}
          categories={categories}
          UoMs={UoMs}
          organizationNodes={organizationNodes}
          getBrands={getBrands}
          getCategories={getCategories}
          getOrgNodes={getOrgNodes}
          getProducts={getProducts}
          getUoms={getUoms}
          updatePreference={updatePreference}
          userPreferences={userPreferences}
        />
        <div className="mb-5">
          <Paper className="no-scrollbar rounded-t-lg shadow-none">
            <TableContainer
              onMouseEnter={() => {
                setContentHover(true);
              }}
              onMouseLeave={() => {
                setContentHover(false);
              }}
              style={{ overflow: 'auto' }}
              ref={tableContainerRef}
              onScroll={handleTableScroll}
              className="mt-6 no-scrollbar rounded-t-lg border border-solid border-dark300 inventory-table"
            >
              <Table stickyHeader>
                <TableHead className="z-40 relative">
                  <TableRow>
                    <TableCell
                      key={'checkbox'}
                      className="bg-white text-dark300 text-base py-2 px-6"
                    >
                      <Checkbox
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 16
                          },
                          '& .Mui-checked.MuiSvgIcon-root': {
                            color: '#0C6BD7 !important'
                          }
                        }}
                        checked={allInventorySelected}
                        onChange={() => {
                          if (allInventorySelected) {
                            clearAllSelectionState();
                          } else {
                            handleAllCheckboxChanges();
                          }

                          setAllInventorySelection(!allInventorySelected);
                        }}
                      />
                    </TableCell>
                    {columnNames.map((columnName, index) => (
                      <TableCell
                        key={index}
                        className="text-[10px] text-dark500 bg-white py-2 pl-0 pr-2 font-normal h-[40px]"
                      >
                        <div className="flex">
                          <span>{columnName}</span>
                          <span>
                            {!index &&
                              (reverseSort ? (
                                <img
                                  src={DEC_SORT_ICON}
                                  alt="Sort Icon"
                                  className="ml-3 mt-[6.5px] cursor-pointer h-2.5"
                                  onClick={() => {
                                    setReverseSort(false);
                                  }}
                                />
                              ) : (
                                <img
                                  src={ASC_SORT_ICON}
                                  alt="Sort Icon"
                                  className="ml-3 mt-[6.5px] cursor-pointer h-2.5"
                                  onClick={() => {
                                    setReverseSort(true);
                                  }}
                                />
                              ))}
                          </span>
                        </div>
                      </TableCell>
                    ))}
                    <TableCell className="bg-white text-dark300 py-2 px-6 "></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!batchMode &&
                    inventories.map((row, index) => (
                      <InventoryRow
                        key={index}
                        row={row}
                        allInventorySelected={allInventorySelected}
                        handleCheckboxChange={handleCheckboxChange}
                        selectedInventories={selectedInventories}
                        tableCellClass={tableCellClass}
                        topValue={topValue}
                        searchKeyword={searchKeyword}
                        cellSubValue={cellSubValue}
                        organizationType={organizationType}
                        classes={classes}
                        digitValue={digitValue}
                        navigate={navigate}
                        currentUser={currentUser}
                        inventoryOptions={inventoryOptions}
                        setOptionsClick={setOptionsClick}
                        setSelectedInventory={setSelectedInventory}
                        extraColumns={userPreferences?.payload?.columns || []}
                        forceClose={
                          stockAdjusmtentViewOpen ||
                          sectionRelocationViewOpen ||
                          stockConditionViewOpen ||
                          holdInventoryViewOpen ||
                          releaseInventoryViewOpen
                        }
                        refetchStats={refetchStats}
                        setRefetchStats={setRefetchStats}
                      />
                    ))}
                  {batchMode &&
                    inventoriesBatches.map((row, index) => (
                      <TableRow
                        key={index}
                        onMouseEnter={() => {
                          setHoveredRow(index);
                        }}
                        onMouseLeave={() => {
                          setHoveredRow(-1);
                        }}
                        className={clsx(
                          {
                            'bg-snow text-auroMetal group':
                              row?.availableQuantity === 0 &&
                              row?.holdQuantity > 0
                          },
                          'hover:shadow-elevation-1 hover:z-20 hover:relative ease-in-out transition-all'
                        )}
                      >
                        <TableCell className="rounded-none text-dark300 py-4 px-6 ">
                          <Checkbox
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: 16
                              },
                              '& .Mui-checked.MuiSvgIcon-root': {
                                color: '#0C6BD7 !important'
                              }
                            }}
                            checked={
                              selectedInventories.includes(row?.id) ||
                              allInventorySelected
                            }
                            onChange={() => {
                              if (!allInventorySelected) {
                                handleCheckboxChange(row?.id, row);
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell
                          className={`${tableCellClass} group-hover:text-primaryBlue`}
                        >
                          <Tooltip title={productLabelFormat(row?.Product)}>
                            <p
                              className={clsx(
                                topValue,
                                {
                                  'text-primaryBlue':
                                    row?.holdQuantity === 0 &&
                                    row?.holdQuantity > 0,
                                  'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                    row?.holdQuantity > 0
                                },
                                {
                                  'bg-[#fff7d9]':
                                    searchKeyword &&
                                    (isHighlighted(
                                      row?.Product?.description,
                                      searchKeyword
                                    ) ||
                                      isHighlighted(
                                        row?.Product?.name,
                                        searchKeyword
                                      ))
                                }
                              )}
                            >
                              {productLabelFormat(row?.Product).length > 20
                                ? `${productLabelFormat(row?.Product).substring(
                                    0,
                                    20
                                  )}...`
                                : `${productLabelFormat(row?.Product)}`}
                            </p>
                          </Tooltip>
                          <Tooltip
                            title={inventoryLocationFormat(row?.Warehouse)}
                          >
                            <p
                              className={clsx(cellSubValue, {
                                'text-primaryBlue':
                                  row?.holdQuantity === 0 &&
                                  row?.holdQuantity > 0,
                                'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                  row?.holdQuantity > 0
                              })}
                            >
                              {inventoryLocationFormat(row?.Warehouse).length >
                              20
                                ? `${inventoryLocationFormat(
                                    row?.Warehouse
                                  ).substring(0, 20)}...`
                                : `${inventoryLocationFormat(row?.Warehouse)}`}
                            </p>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className={`${tableCellClass} group-hover:text-primaryBlue`}
                        >
                          <p
                            className={clsx(topValue, {
                              'text-primaryBlue':
                                row?.holdQuantity === 0 &&
                                row?.holdQuantity > 0,
                              'text-auroMetal': row?.holdQuantity > 0
                            })}
                          >
                            {row?.batchNumber}
                          </p>

                          <p
                            className={clsx(cellSubValue, {
                              'text-primaryBlue':
                                row?.holdQuantity === 0 &&
                                row?.holdQuantity > 0,
                              'text-auroMetal': row?.holdQuantity > 0
                            })}
                          >
                            {row?.manufacturingDate
                              ? moment(row?.manufacturingDate).format(
                                  'DD/MM/YY'
                                )
                              : ''}{' '}
                            -{' '}
                            {row?.expiryDate
                              ? moment(row?.expiryDate).format('DD/MM/YY')
                              : ''}
                          </p>
                        </TableCell>
                        {userPreferences &&
                          userPreferences?.payload?.columns?.indexOf(
                            'Brand & Category'
                          ) !== -1 && (
                            <TableCell
                              className={`${tableCellClass} group-hover:text-primaryBlue`}
                            >
                              <Tooltip title={row?.Product?.Brand?.name}>
                                <p
                                  className={clsx(topValue, {
                                    'text-primaryBlue':
                                      row?.holdQuantity === 0 &&
                                      row?.holdQuantity > 0,
                                    'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                      row?.holdQuantity > 0
                                  })}
                                >
                                  {row?.Product?.Brand?.name?.length > 20
                                    ? `${row?.Product?.Brand?.name?.substring(
                                        0,
                                        20
                                      )}...`
                                    : row?.Product?.Brand?.name}
                                </p>
                              </Tooltip>
                              <Tooltip title={row?.Product?.Category?.name}>
                                <p
                                  className={clsx(cellSubValue, {
                                    'text-primaryBlue':
                                      row?.holdQuantity === 0 &&
                                      row?.holdQuantity > 0,
                                    'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                      row?.holdQuantity > 0
                                  })}
                                >
                                  {row?.Product?.Category?.name?.length > 20
                                    ? `${row?.Product?.Category?.name?.substring(
                                        0,
                                        20
                                      )}...`
                                    : row?.Product?.Category?.name}
                                </p>
                              </Tooltip>
                            </TableCell>
                          )}
                        <TableCell
                          className={`${tableCellClass} group-hover:text-primaryBlue`}
                        >
                          <div className="flex items-start">
                            <p
                              className={clsx(
                                topValue,
                                {
                                  'text-primaryBlue':
                                    row?.holdQuantity === 0 &&
                                    row?.holdQuantity > 0,
                                  'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                    row?.holdQuantity > 0
                                },
                                'mr-1'
                              )}
                            >
                              {decimalDisplay(
                                row?.holdQuantity +
                                  row?.reservedQuantity +
                                  row?.availableQuantity +
                                  row?.recoverableDamageQuantity
                              )}
                            </p>
                            {(row?.expiredQuantity > 0 ||
                              row?.nearExpiryQuantity > 0) && (
                              <Tooltip
                                title={getInventoryAlertMessage({
                                  inventory: row,
                                  detailView: false,
                                  damages: false
                                })}
                                placement="top"
                                classes={{
                                  tooltip: classes.customTooltip,
                                  arrow: classes.arrow
                                }}
                                arrow
                              >
                                <span>
                                  <img
                                    src={
                                      row?.expiredQuantity > 0
                                        ? AlertIcon
                                        : WarningIcon
                                    }
                                    alt="alert"
                                  />
                                </span>
                              </Tooltip>
                            )}
                          </div>

                          <p
                            className={clsx(cellSubValue, {
                              'text-primaryBlue':
                                row?.holdQuantity === 0 &&
                                row?.holdQuantity > 0,
                              'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                row?.holdQuantity > 0
                            })}
                          >
                            {row?.Product?.UOM?.name?.length > 20
                              ? `${row?.Product?.UOM?.name?.substring(
                                  0,
                                  20
                                )}...`
                              : row?.Product?.UOM?.name}
                          </p>
                        </TableCell>
                        <TableCell
                          className={`${tableCellClass} group-hover:text-primaryBlue`}
                        >
                          <div className="flex items-start">
                            <p
                              className={clsx(
                                topValue,
                                {
                                  'text-primaryBlue':
                                    row?.holdQuantity === 0 &&
                                    row?.holdQuantity > 0,
                                  'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                    row?.holdQuantity > 0
                                },
                                'mr-1'
                              )}
                            >
                              {decimalDisplay(
                                row?.holdQuantity +
                                  row?.reservedQuantity +
                                  row?.availableQuantity +
                                  row?.recoverableDamageQuantity
                              )}
                            </p>
                          </div>
                          <p
                            className={clsx(cellSubValue, {
                              'text-primaryBlue':
                                row?.holdQuantity === 0 &&
                                row?.holdQuantity > 0,
                              'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                row?.holdQuantity > 0
                            })}
                          >
                            {row?.Product?.ProductUOMs?.[0]?.UOM?.name?.length >
                            20
                              ? `${row?.Product?.ProductUOMs?.[0]?.UOM?.name?.substring(
                                  0,
                                  20
                                )}...`
                              : row?.Product?.ProductUOMs?.[0]?.UOM?.name}
                          </p>
                        </TableCell>
                        <TableCell
                          className={`${tableCellClass} group-hover:text-primaryBlue`}
                        >
                          <div className="flex items-start h-full">
                            <p
                              className={clsx(digitValue, {
                                'text-primaryBlue':
                                  row?.holdQuantity === 0 &&
                                  row?.holdQuantity > 0,
                                'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                  row?.holdQuantity > 0
                              })}
                            >
                              {decimalDisplay(row?.availableQuantity)}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell
                          className={`${tableCellClass} group-hover:text-primaryBlue`}
                        >
                          <div className="flex items-start h-full">
                            <p
                              className={clsx(digitValue, {
                                'text-primaryBlue':
                                  row?.holdQuantity === 0 &&
                                  row?.holdQuantity > 0,
                                'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                  row?.holdQuantity > 0
                              })}
                            >
                              {decimalDisplay(row?.holdQuantity || 0)}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell
                          className={`${tableCellClass} group-hover:text-primaryBlue`}
                        >
                          <div className="flex items-start h-full">
                            <p
                              className={clsx(digitValue, {
                                'text-primaryBlue':
                                  row?.holdQuantity === 0 &&
                                  row?.holdQuantity > 0,
                                'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                  row?.holdQuantity > 0
                              })}
                            >
                              {decimalDisplay(row?.reservedQuantity || 0)}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell
                          className={`${tableCellClass} group-hover:text-primaryBlue`}
                        >
                          <div className="flex items-start h-full">
                            <p
                              className={clsx(digitValue, {
                                'text-primaryBlue':
                                  row?.holdQuantity === 0 &&
                                  row?.holdQuantity > 0,
                                'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                                  row?.holdQuantity > 0
                              })}
                            >
                              {decimalDisplay(
                                row?.recoverableDamageQuantity || 0
                              )}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell className="rounded-none">
                          <div
                            className={clsx('flex h-full items-center', {
                              'invisible ': hoveredRow !== index
                            })}
                          >
                            {(checkPermission(currentUser, 'OPS_HOLD_APPLY') ||
                              checkPermission(
                                currentUser,
                                'OPS_HOLD_REMOVE'
                              )) &&
                              (row?.availableQuantity > 0 ||
                                row?.holdQuantity > 0 ||
                                row?.recoverableDamageQuantity > 0) && (
                                <div onClick={(e) => e.stopPropagation()}>
                                  <OptionsMenu
                                    key={row?.id}
                                    options={inventoryOptions}
                                    closeCallback={() => setOptionsClick(false)}
                                    enterCallBack={() => {
                                      setSelectedInventory(row);
                                      setOptionsClick(true);
                                    }}
                                    forceClose={
                                      stockAdjusmtentViewOpen ||
                                      sectionRelocationViewOpen ||
                                      stockConditionViewOpen ||
                                      holdInventoryViewOpen ||
                                      releaseInventoryViewOpen
                                    }
                                  />
                                </div>
                              )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  {/* {page < pageCount && (
                    <TableRow>
                      <TableCell className="py-2 h-12" colSpan={12}>
                        <div className="flex w-full justify-center">
                          <CircularProgress size="14px" />
                        </div>
                      </TableCell>
                    </TableRow>
                  )} */}
                  {inventories?.length === 0 && !inventoryLoading && (
                    <TableRow>
                      <TableCell className="py-2 h-12" colSpan={12}>
                        <div className="flex w-full justify-center">
                          <p>No data found</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <div className="w-full flex justify-between items-center bg-dark200 rounded-b-lg h-14 border-solid border border-dark300 border-t-0 py-3 px-4">
            <div className="flex">
              <Button
                variant="transparent"
                icon={<img src={DownloadIcon} className={`mr-2`} />}
                label="Export"
                className="py-2 px-3 ml-2 h-8 border-solid border "
                overrideSize={true}
                labelClass="font-medium text-xs"
                iconClass="h-[14px] w-[14px] mr-1"
                onClick={() => exportToExcel()}
              />
              {selectedInventories?.length > 0 &&
                isFeatureEnabled(FLAGS.STOCK_ADJUSTMENT) && (
                  <StockAdjustment
                    inventoryIds={!batchMode ? selectedInventories : null}
                    batchIds={batchMode ? selectedInventories : null}
                    warehouseIds={selectedWarehouseIds}
                    refetch={_getInventories}
                    stock360Enabled={isFeatureEnabled(FLAGS.LOCATION_HIERARCHY)}
                    batchExpiryEnabled={isFeatureEnabled(
                      FLAGS.BATCH_AND_EXPIRY_TRACKING
                    )}
                    open={stockAdjusmtentViewOpen}
                    setOpen={setStockAdjustmentViewOpen}
                    callBack={() => {
                      setRefetchStats(true);
                    }}
                    allInventorySelected={allInventorySelected}
                  />
                )}
              {selectedInventories?.length > 0 &&
                isFeatureEnabled(FLAGS.STOCK_CONDITION_MANAGEMENT) && (
                  <StockCondition
                    inventoryIds={!batchMode ? selectedInventories : null}
                    batchIds={batchMode ? selectedInventories : null}
                    refetch={_getInventories}
                    stock360Enabled={isFeatureEnabled(FLAGS.LOCATION_HIERARCHY)}
                    batchExpiryEnabled={isFeatureEnabled(
                      FLAGS.BATCH_AND_EXPIRY_TRACKING
                    )}
                    open={stockConditionViewOpen}
                    setOpen={setStockConditionViewOpen}
                    warehouseIds={selectedWarehouseIds}
                    callBack={() => {
                      setRefetchStats(true);
                    }}
                    allInventorySelected={allInventorySelected}
                  />
                )}
              {selectedInventories?.length > 0 &&
                isFeatureEnabled(FLAGS.INVENTORY_HOLD) && (
                  <HoldInventory
                    inventoryIds={!batchMode ? selectedInventories : null}
                    batchIds={batchMode ? selectedInventories : null}
                    refetch={_getInventories}
                    stock360Enabled={isFeatureEnabled(FLAGS.LOCATION_HIERARCHY)}
                    batchExpiryEnabled={isFeatureEnabled(
                      FLAGS.BATCH_AND_EXPIRY_TRACKING
                    )}
                    open={holdInventoryViewOpen}
                    setOpen={setHoldInventoryViewOpen}
                    warehouseIds={selectedWarehouseIds}
                    callBack={() => {
                      setRefetchStats(true);
                    }}
                    allInventorySelected={allInventorySelected}
                  />
                )}
              {selectedInventories?.length > 0 &&
                isFeatureEnabled(FLAGS.INVENTORY_HOLD) && (
                  <ReleaseInventory
                    inventoryIds={!batchMode ? selectedInventories : null}
                    batchIds={batchMode ? selectedInventories : null}
                    refetch={_getInventories}
                    stock360Enabled={isFeatureEnabled(FLAGS.LOCATION_HIERARCHY)}
                    batchExpiryEnabled={isFeatureEnabled(
                      FLAGS.BATCH_AND_EXPIRY_TRACKING
                    )}
                    open={releaseInventoryViewOpen}
                    setOpen={setReleaseInventoryViewOpen}
                    warehouseIds={selectedWarehouseIds}
                    callBack={() => {
                      setRefetchStats(true);
                    }}
                    allInventorySelected={allInventorySelected}
                  />
                )}
              {selectedInventories?.length > 0 &&
                isFeatureEnabled(FLAGS.STOCK_RELOCATION) && (
                  <SectionRelocation
                    inventoryIds={!batchMode ? selectedInventories : null}
                    batchIds={batchMode ? selectedInventories : null}
                    refetch={_getInventories}
                    stock360Enabled={isFeatureEnabled(FLAGS.LOCATION_HIERARCHY)}
                    batchExpiryEnabled={isFeatureEnabled(
                      FLAGS.BATCH_AND_EXPIRY_TRACKING
                    )}
                    open={sectionRelocationViewOpen}
                    setOpen={setSectionRelocationViewOpen}
                    warehouseIds={selectedWarehouseIds}
                    callBack={() => {
                      setRefetchStats(true);
                    }}
                    allInventorySelected={allInventorySelected}
                  />
                )}
              {/* {selectedInventoriesForHold?.length > 0 &&
                selectedHoldQuantity > 0 &&
                !allInventorySelected &&
                checkPermission(currentUser, 'OPS_HOLD_APPLY') && (
                  <ReleaseOrHoldPopover
                    type={ActionType.HOLD}
                    inventoryAmount={selectedHoldQuantity}
                    SKUCount={selectedInventoriesForHold?.length}
                    actionCallback={holdSelectedInventories}
                    isLoading={loadingHoldInventory}
                    source={Source.INVENTORY}
                    origin={PopoverOrigin.FOOTER}
                  />
                )}
              {selectedInventoriesForRelease?.length > 0 &&
                selectedReleaseQuantity > 0 &&
                !allInventorySelected &&
                checkPermission(currentUser, 'OPS_HOLD_REMOVE') && (
                  <ReleaseOrHoldPopover
                    type={ActionType.REMOVE_HOLD}
                    inventoryAmount={selectedReleaseQuantity}
                    SKUCount={selectedInventoriesForRelease?.length}
                    actionCallback={removedHoldFromSelectedInventories}
                    isLoading={loadingRemoveHoldInventory}
                    source={Source.INVENTORY}
                    origin={PopoverOrigin.FOOTER}
                  />
                )}
              {!batchMode &&
                allHoldInventoryIds?.length > 0 &&
                allAvailableQuantity > 0 &&
                allInventorySelected &&
                checkPermission(currentUser, 'OPS_HOLD_APPLY') && (
                  <ReleaseOrHoldPopover
                    type={ActionType.HOLD}
                    inventoryAmount={allAvailableQuantity}
                    SKUCount={allAvailableInventoryIds?.length}
                    actionCallback={holdAllInventories}
                    isLoading={loadingHoldInventory}
                    source={Source.INVENTORY}
                    origin={PopoverOrigin.FOOTER}
                  />
                )}
              {!batchMode &&
                allAvailableInventoryIds?.length > 0 &&
                allHoldQuantity > 0 &&
                allInventorySelected &&
                checkPermission(currentUser, 'OPS_HOLD_REMOVE') && (
                  <ReleaseOrHoldPopover
                    type={ActionType.REMOVE_HOLD}
                    inventoryAmount={allHoldQuantity}
                    SKUCount={allHoldInventoryIds?.length}
                    actionCallback={removedHoldFromAllInventories}
                    isLoading={loadingRemoveHoldInventory}
                    source={Source.INVENTORY}
                    origin={PopoverOrigin.FOOTER}
                  />
                )}
              {batchMode &&
                allAvailableBatchesIds?.length > 0 &&
                allAvailableBatchesQuantity > 0 &&
                allInventorySelected &&
                checkPermission(currentUser, 'OPS_HOLD_APPLY') && (
                  <ReleaseOrHoldPopover
                    type={ActionType.HOLD}
                    inventoryAmount={allAvailableBatchesQuantity}
                    SKUCount={allAvailableBatchesIds?.length}
                    actionCallback={holdAllInventories}
                    isLoading={loadingHoldInventory}
                    source={Source.INVENTORY}
                    origin={PopoverOrigin.FOOTER}
                  />
                )}
              {batchMode &&
                allHoldBatchesIds?.length > 0 &&
                allHoldBatchesQuantity > 0 &&
                allInventorySelected &&
                checkPermission(currentUser, 'OPS_HOLD_REMOVE') && (
                  <ReleaseOrHoldPopover
                    type={ActionType.REMOVE_HOLD}
                    inventoryAmount={allHoldBatchesQuantity}
                    SKUCount={allHoldBatchesIds?.length}
                    actionCallback={removedHoldFromAllInventories}
                    isLoading={loadingRemoveHoldInventory}
                    source={Source.INVENTORY}
                    origin={PopoverOrigin.FOOTER}
                  />
                )} */}
            </div>
            <div className="flex">
              <p className="text-xs text-primaryBlue flex items-center mr-8">
                Total Weight:{' '}
                <span className="text-sm font-medium ml-1">
                  {selectedInventories?.length
                    ? decimalDisplay(selectedInventoriesWeight)
                    : decimalDisplay(totalInventoryWeight)}
                </span>{' '}
                <span className="text-[10px] ml-0.5">
                  {settings?.weight?.symbol || 'Kg'}
                </span>
              </p>
              <p className="text-xs text-primaryBlue flex items-center">
                Total SKUs:{' '}
                <span className="text-sm font-medium ml-1">
                  {decimalDisplay(
                    selectedInventories?.length || inventoryCount
                  )}
                </span>
              </p>
            </div>
          </div>

          <Popup
            open={popupViewOpen}
            setOpen={setPopupViewOpen}
            title={`Bulk Upload`}
            dialogContentClasses={`w-[900px]`}
            onClose={() => {
              setSelectedFile(null);
              setBulkPayload({});
            }}
            actions={
              <div className="flex gap-2">
                <Button
                  label="Cancel"
                  onClick={() => setPopupViewOpen(false)}
                  variant="secondary"
                />
                <Button
                  label={`Add ${Object.keys(bulkPayload).length} products`}
                  disabled={Object.keys(bulkPayload).length == 0}
                  variant="primary"
                  onClick={async () => {
                    setAPILoader(true);
                    await bulkUpload();
                    await refetch();
                    setSelectedFile(null);
                    setBulkPayload({});
                    setPopupViewOpen(false);
                    setAPILoader(false);
                    toaster('success', 'Inventory uploaded successfully!');
                  }}
                />
              </div>
            }
            content={
              <div>
                {incorrect?.length > 0 && (
                  <div className="flex p-4 bg-[#FFEBEB] mt-4 justify-between">
                    <div className="flex gap-2">
                      <img src={WarningIcon} />
                      <p className="text-sm">
                        {incorrect?.length}{' '}
                        {incorrect?.length > 1 ? "sku's are " : 'sku is '}
                        not uploaded because of formatting issues in file.
                      </p>
                    </div>
                    <div
                      onClick={async () => {
                        await exportToXlsx();
                      }}
                      className="flex gap-2 cursor-pointer"
                    >
                      <img src={downloadFileIcon} />
                      <p className="text-sm">Download File</p>
                    </div>
                  </div>
                )}
                <div className="font-medium mt-4">
                  <p>{Object.keys(bulkPayload).length} Products detected</p>
                </div>
                <div className="mt-10">
                  <InventoryProductsTable
                    data={Object.keys(bulkPayload).map(
                      (key) => bulkPayload[key]
                    )}
                  />
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

function InventoryRow({
  index,
  row,
  allInventorySelected,
  handleCheckboxChange,
  selectedInventories,
  tableCellClass,
  topValue,
  searchKeyword,
  cellSubValue,
  digitValue,
  navigate,
  currentUser,
  inventoryOptions,
  setOptionsClick,
  setSelectedInventory,
  extraColumns,
  forceClose
}) {
  const [inventoryDetail, setInventoryDetail] = useState(null);
  const [showOrderQtyList, setShowOrderQtyList] = useState(false);
  const [showReservedQty, setShowReservedQty] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(-1);
  const anchorRef = useRef(null);
  const { isFeatureEnabled } = useFeatureFlags();
  const { settings } = useContext(SharedContext);

  return (
    <>
      <OrderList
        anchorRef={anchorRef}
        setOpen={setShowOrderQtyList}
        isReserved={showReservedQty}
        open={showOrderQtyList}
        inventoryDetail={inventoryDetail}
      />
      <TableRow
        onMouseEnter={() => {
          setHoveredRow(index);
        }}
        onMouseLeave={() => {
          setHoveredRow(-1);
        }}
        className={clsx(
          {
            'bg-snow text-auroMetal group':
              row?.availableQuantity === 0 && row?.holdQuantity > 0
          },
          'hover:shadow-elevation-1 hover:z-20 hover:relative ease-in-out transition-all h-[48px]'
        )}
        onClick={() => {
          if (!allInventorySelected) {
            handleCheckboxChange(row?.id, row);
          }
        }}
      >
        <TableCell className="rounded-none text-dark300 py-4 px-6 ">
          <Checkbox
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 16
              },
              '& .Mui-checked.MuiSvgIcon-root': {
                color: '#0C6BD7 !important'
              }
            }}
            checked={
              selectedInventories.includes(row?.id) || allInventorySelected
            }
            onChange={() => {
              if (!allInventorySelected) {
                handleCheckboxChange(row?.id, row);
              }
            }}
          />
        </TableCell>
        <TableCell className={`${tableCellClass} group-hover:text-primaryBlue`}>
          <div className="flex gap-4">
            {extraColumns.indexOf('Product Image') !== -1 && (
              <div className="border border-[#E1E5F0] rounded-[4px]">
                <img
                  src={row?.file?.location || PlaceholderImageIcon}
                  className="w-10 h-10"
                />
              </div>
            )}
            <div>
              <Tooltip title={productLabelFormat(row?.Product)}>
                <p
                  className={clsx(
                    topValue,
                    {
                      'text-primaryBlue':
                        row?.holdQuantity === 0 && row?.holdQuantity > 0,
                      'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                        row?.holdQuantity > 0
                    },
                    {
                      'bg-[#fff7d9]':
                        searchKeyword &&
                        (isHighlighted(
                          row?.Product?.description,
                          searchKeyword
                        ) ||
                          isHighlighted(row?.Product?.name, searchKeyword))
                    }
                  )}
                >
                  {productLabelFormat(row?.Product).length > 20
                    ? `${productLabelFormat(row?.Product).substring(0, 20)}...`
                    : `${productLabelFormat(row?.Product)}`}
                </p>
              </Tooltip>
              <Tooltip title={inventoryLocationFormat(row?.Warehouse)}>
                <p
                  className={clsx(cellSubValue, {
                    'text-primaryBlue':
                      row?.holdQuantity === 0 && row?.holdQuantity > 0,
                    'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                      row?.holdQuantity > 0,
                    'bg-[#fff7d9]':
                      searchKeyword &&
                      (isHighlighted(row?.Warehouse?.name, searchKeyword) ||
                        isHighlighted(
                          row?.Warehouse?.businessWarehouseCode,
                          searchKeyword
                        ))
                  })}
                >
                  {inventoryLocationFormat(row?.Warehouse).length > 20
                    ? `${inventoryLocationFormat(row?.Warehouse).substring(
                        0,
                        20
                      )}...`
                    : `${inventoryLocationFormat(row?.Warehouse)}`}
                </p>
              </Tooltip>
            </div>
          </div>
        </TableCell>
        {extraColumns.indexOf('Brand & Category') !== -1 && (
          <TableCell
            className={`${tableCellClass} group-hover:text-primaryBlue`}
          >
            <Tooltip title={row?.Product?.Brand?.name}>
              <p
                className={clsx(topValue, {
                  'text-primaryBlue':
                    row?.holdQuantity === 0 && row?.holdQuantity > 0,
                  'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                    row?.holdQuantity > 0
                })}
              >
                {row?.Product?.Brand?.name?.length > 20
                  ? `${row?.Product?.Brand?.name?.substring(0, 20)}...`
                  : row?.Product?.Brand?.name}
              </p>
            </Tooltip>
            <Tooltip title={row?.Product?.Category?.name}>
              <p
                className={clsx(cellSubValue, {
                  'text-primaryBlue':
                    row?.holdQuantity === 0 && row?.holdQuantity > 0,
                  'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                    row?.holdQuantity > 0
                })}
              >
                {row?.Product?.Category?.name?.length > 20
                  ? `${row?.Product?.Category?.name?.substring(0, 20)}...`
                  : row?.Product?.Category?.name}
              </p>
            </Tooltip>
          </TableCell>
        )}
        <TableCell className={`${tableCellClass} group-hover:text-primaryBlue`}>
          <div className="flex items-start">
            <p
              className={clsx(
                topValue,
                {
                  'text-primaryBlue':
                    row?.holdQuantity === 0 && row?.holdQuantity > 0,
                  'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                    row?.holdQuantity > 0
                },
                'mr-1'
              )}
            >
              {decimalDisplay(
                row?.holdQuantity +
                  row?.reservedQuantity +
                  row?.availableQuantity +
                  row?.recoverableDamageQuantity
              )}
            </p>
            {(row?.expiredQuantity > 0 || row?.nearExpiryQuantity > 0) && (
              <Tooltip
                title={getInventoryAlertMessage({
                  inventory: row,
                  detailView: false,
                  damages: false
                })}
                placement="top"
                classes={
                  {
                    // tooltip: classes.customTooltip,
                    // arrow: classes.arrow
                  }
                }
                arrow
              >
                <span>
                  <img
                    src={row?.expiredQuantity > 0 ? AlertIcon : WarningIcon}
                    alt="alert"
                  />
                </span>
              </Tooltip>
            )}
          </div>

          <p
            className={clsx(cellSubValue, {
              'text-primaryBlue':
                row?.holdQuantity === 0 && row?.holdQuantity > 0,
              'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                row?.holdQuantity > 0
            })}
          >
            {row?.Product?.UOM?.name?.length > 20
              ? `${row?.Product?.UOM?.name?.substring(0, 20)}...`
              : row?.Product?.UOM?.name}
          </p>
        </TableCell>
        <TableCell className={`${tableCellClass} group-hover:text-primaryBlue`}>
          {row?.Product?.ProductUOMs?.length > 0 ? (
            <>
              <div className="flex items-start">
                <p
                  className={clsx(
                    topValue,
                    {
                      'text-primaryBlue':
                        row?.holdQuantity === 0 && row?.holdQuantity > 0,
                      'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                        row?.holdQuantity > 0
                    },
                    'mr-1'
                  )}
                >
                  {decimalDisplay(
                    (row?.holdQuantity +
                      row?.reservedQuantity +
                      row?.availableQuantity +
                      row?.recoverableDamageQuantity) /
                      row?.Product?.ProductUOMs?.[0]?.conversionFactor
                  )}
                </p>
              </div>
              <p
                className={clsx(cellSubValue, {
                  'text-primaryBlue':
                    row?.holdQuantity === 0 && row?.holdQuantity > 0,
                  'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                    row?.holdQuantity > 0
                })}
              >
                {row?.Product?.ProductUOMs?.[0]?.UOM?.name?.length > 20
                  ? `${row?.Product?.ProductUOMs?.[0]?.UOM?.name?.substring(
                      0,
                      20
                    )}...`
                  : row?.Product?.ProductUOMs?.[0]?.UOM?.name}
              </p>
            </>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell className={`${tableCellClass} group-hover:text-primaryBlue`}>
          <div className="flex items-center h-full">
            <p
              className={clsx(digitValue, {
                'text-primaryBlue':
                  row?.holdQuantity === 0 && row?.holdQuantity > 0,
                'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                  row?.holdQuantity > 0
              })}
            >
              {decimalDisplay(row?.availableQuantity)}
            </p>
          </div>
        </TableCell>
        <TableCell className={`${tableCellClass} group-hover:text-primaryBlue`}>
          <div className="flex items-center h-full">
            <p
              className={clsx(digitValue, {
                'text-primaryBlue':
                  row?.holdQuantity === 0 && row?.holdQuantity > 0,
                'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                  row?.holdQuantity > 0
              })}
            >
              {decimalDisplay(row?.holdQuantity || 0)}
            </p>
          </div>
        </TableCell>
        {extraColumns.indexOf('In-Transit') !== -1 && (
          <TableCell
            className={`${tableCellClass} group-hover:text-primaryBlue`}
          >
            <div className="flex items-center">
              <p
                className={clsx(digitValue, {
                  'text-primaryBlue':
                    row?.holdQuantity === 0 && row?.holdQuantity > 0,
                  'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                    row?.holdQuantity > 0
                })}
              >
                {decimalDisplay(row?.inTransitQuantity || 0)}
              </p>
              {row?.inTransitQuantity > 0 && (
                <div ref={anchorRef}>
                  <Button
                    variant="tiny-transparent"
                    icon={<img src={InfoIcon} alt="InfoIcon" />}
                    className={clsx(
                      'p-1 border-0 h-7 min-w-fit rounded-none mr-1'
                    )}
                    overrideSize={true}
                    labelClass="p-0"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowOrderQtyList(!showOrderQtyList);
                      setShowReservedQty(false);
                      setInventoryDetail(row);
                    }}
                  />
                </div>
              )}
            </div>
          </TableCell>
        )}
        {isFeatureEnabled(FLAGS.PICKING) && (
          <TableCell
            className={`${tableCellClass} group-hover:text-primaryBlue`}
          >
            <div className="flex items-center h-full">
              <p
                className={clsx(digitValue, {
                  'text-primaryBlue':
                    row?.holdQuantity === 0 && row?.holdQuantity > 0,
                  'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                    row?.holdQuantity > 0
                })}
              >
                {decimalDisplay(row?.reservedQuantity)}
              </p>
              {row?.reservedQuantity > 0 && (
                <div ref={anchorRef}>
                  <Button
                    variant="tiny-transparent"
                    icon={<img src={InfoIcon} alt="InfoIcon" />}
                    className={clsx(
                      'p-1 border-0 h-7 min-w-fit rounded-none mr-1'
                    )}
                    overrideSize={true}
                    labelClass="p-0"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowOrderQtyList(!showOrderQtyList);
                      setShowReservedQty(true);
                      setInventoryDetail(row);
                    }}
                  />
                </div>
              )}
            </div>
          </TableCell>
        )}
        <TableCell className={`${tableCellClass} group-hover:text-primaryBlue`}>
          <div className="flex items-center h-full">
            <p
              className={clsx(digitValue, {
                'text-primaryBlue':
                  row?.holdQuantity === 0 && row?.holdQuantity > 0,
                'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                  row?.holdQuantity > 0
              })}
            >
              {decimalDisplay(row?.recoverableDamageQuantity)}
            </p>
          </div>
        </TableCell>

        {extraColumns.indexOf('Ordered') !== -1 && (
          <TableCell
            className={`${tableCellClass} group-hover:text-primaryBlue`}
          >
            <div className="flex items-center">
              <p
                className={clsx(digitValue, {
                  'text-primaryBlue':
                    row?.holdQuantity === 0 && row?.holdQuantity > 0,
                  'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                    row?.holdQuantity > 0
                })}
              >
                {decimalDisplay(row?.orderedQuantity || 0)}
              </p>
            </div>
          </TableCell>
        )}

        {extraColumns.indexOf('Weight & Volume') !== -1 && (
          <TableCell
            className={`${tableCellClass} group-hover:text-primaryBlue`}
          >
            <p
              className={clsx(topValue, {
                'text-primaryBlue':
                  row?.holdQuantity === 0 && row?.holdQuantity > 0,
                'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                  row?.holdQuantity > 0
              })}
            >
              {decimalDisplay(
                row?.Product?.weight *
                  (row?.holdQuantity +
                    row?.reservedQuantity +
                    row?.availableQuantity +
                    row?.recoverableDamageQuantity)
              )}{' '}
              {settings?.weight?.symbol || 'Kg'}
            </p>
            <p
              className={clsx(cellSubValue, {
                'text-primaryBlue':
                  row?.holdQuantity === 0 && row?.holdQuantity > 0,
                'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                  row?.holdQuantity > 0
              })}
            >
              {decimalDisplay(
                row?.Product?.dimensionsCBM *
                  (row?.holdQuantity +
                    row?.reservedQuantity +
                    row?.availableQuantity +
                    row?.recoverableDamageQuantity)
              )}{' '}
              {settings?.volume?.symbol || 'cm³'}
            </p>
          </TableCell>
        )}

        {extraColumns.indexOf('Stock Value') !== -1 && (
          <TableCell
            className={`${tableCellClass} group-hover:text-primaryBlue`}
          >
            <div className="flex items-start h-full">
              <p
                className={clsx(digitValue, {
                  'text-primaryBlue':
                    row?.holdQuantity === 0 && row?.holdQuantity > 0,
                  'text-auroMetal hover:text-primaryBlue group-hover:text-primaryBlue':
                    row?.holdQuantity > 0
                })}
              >
                {decimalDisplay(
                  (row?.averageStockPrice || 0) *
                    (row?.goodQuantity + row?.recoverableDamageQuantity) || 0
                )}
              </p>
            </div>
          </TableCell>
        )}
        <TableCell className="rounded-none">
          <div
            className={clsx('flex h-full items-center justify-end', {
              'invisible ': hoveredRow !== index
            })}
          >
            {isFeatureEnabled(FLAGS.LOCATION_HIERARCHY) && (
              <Button
                variant="tiny-transparent"
                icon={<img src={ViewIcon} alt="view" />}
                className={clsx('p-1 border-0 h-7 min-w-fit rounded-none mr-1')}
                overrideSize={true}
                labelClass="p-0"
                onClick={() => {
                  navigate(`view/${row?.id}`);
                }}
              />
            )}
            {(checkPermission(currentUser, 'OPS_HOLD_APPLY') ||
              checkPermission(currentUser, 'OPS_HOLD_REMOVE')) &&
              (row?.availableQuantity > 0 || row?.holdQuantity > 0) && (
                <div onClick={(e) => e.stopPropagation()}>
                  <OptionsMenu
                    key={row?.id}
                    options={inventoryOptions}
                    closeCallback={() => setOptionsClick(false)}
                    enterCallBack={() => {
                      setSelectedInventory(row);
                      setOptionsClick(true);
                    }}
                    forceClose={forceClose}
                  />
                </div>
              )}
          </div>
        </TableCell>
      </TableRow>
    </>
  );
}

export default InventoryView;
