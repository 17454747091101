/*eslint-disable*/
import clsx from 'clsx';
import StarSolid from '../../../assets/icons/unifiedDashboard/starSolid.svg';
import StartOutline from '../../../assets/icons/unifiedDashboard/StartOutline.svg';
import { getCurrencySymbol, SharedContext } from '../../../utils/common';
import Chat from '../../../assets/icons/chat.svg';
import { Paragraph, Title } from '../../../core-components/atoms/Text';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import moment from 'moment/moment';
import { useContext } from 'react';
import LinearProgressBar from '../../../core-components/atoms/LinerProgressBar';
import { CRIMSON_500, JADE_700, PRIMARY } from '../../../constants/colors';
import GreenTruckIcon from '../../../assets/icons/greentTruckIcon.svg';
import RedTruckIcon from '../../../assets/icons/redTruckIcon.svg';
import BlueTruckIcon from '../../../assets/icons/TruckIcon.svg';
import { OrderTypeMapping } from '../../../constants/orderStatuses';

const WOTMSShipment = ({ record, index, handleFavourite, handleChat }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const expectedDeliveryDate = moment(record.dropoffDateTime);
  const currentDate = moment();
  const timeDifference = expectedDeliveryDate.diff(currentDate);
  const daysDifference = moment.duration(timeDifference).asDays() < 0 ? '0' : moment.duration(timeDifference).asDays().toFixed(0);
  const freightCost = record?.ShipmentLegs?.find(
    (val) => val?.shipmentId === record?.id
  )?.Expense?.cost;
  const totalCost = record?.Order?.Expenses?.filter(
    (value) => value?.shipmentId === record?.id
  ).reduce((acc, v) => acc + v.cost, 0);
  const { organization } = useContext(SharedContext);
  let cases = {
    CREATED: record.status.key === 'CREATED',
    DOCK_ASSIGNED: record.subStatus?.key === 'DOCK_ASSIGNED',
    RECEIVED:
      record.status.key === 'COMPLETED' &&
      (record.Order.moveType === 'INWARD' ||
        record.Order.moveType === 'TRANSFER'),
    DELIVERED:
      record.status.key === 'COMPLETED' && record.Order.moveType === 'OUTBOUND',
    CANCELLED: record.status.key === 'CANCELLED'
  };

  const className = `mt-4 mb-4 rounded py-2 px-4 w-full border
                        ${
                          !cases.RECEIVED &&
                          !cases.CANCELLED &&
                          !cases.DELIVERED
                            ? 'bg-bgCreated'
                            : cases.RECEIVED || cases.DELIVERED
                            ? 'bg-jade100'
                            : 'bg-bgCancelled'
                        }`;

  return (
    <div className={className} key={index}>
      <div className="flex justify-between">
        <div className="flex flex-row items-center gap-5">
          <Title variant={'md'}>Shipment {record.customId}</Title>
          <div
            className={clsx(
              'text-[10px] font-medium mt-0.5 py-1 px-2 w-auto flex justify-center items-center text-white bg-blueEyes',
              {
                'bg-blueEyes ': cases.CREATED,
                'bg-oasisPrimary500 ': cases.DOCK_ASSIGNED,
                'bg-crimson500': cases.CANCELLED,
                'bg-jade500 ': cases.RECEIVED || cases.DELIVERED
              },
              'p-1 rounded'
            )}
          >
            {cases.DOCK_ASSIGNED
              ? 'Dock Assigned'
              : cases.CREATED
              ? 'Created'
              : cases.RECEIVED
              ? 'Received'
              : cases.DELIVERED
              ? 'Delivered'
              : 'Cancelled'}
          </div>
        </div>
        <div className="flex flex-row items-center gap-2">
          <div
            className={clsx(
              'text-[10px] font-medium py-1 px-2 w-auto flex justify-center items-center text-black border border-black',
              'p-2 rounded'
            )}
          >
            {record.Order?.customId}
          </div>

          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleClick(e);
            }}
            size="small"
            sx={{ ml: 2 }}
          >
            <MoreVertIcon sx={{ width: 32, height: 32 }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 3px 4px rgba(0,0,0,0.12))',
                mt: 2
              }
            }}
            BackdropProps={{ style: { backgroundColor: 'transparent' } }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            elevation={0}
          >
            <MenuItem
              className="display-block pl-4 pr-4 pt-2 pb-2 hover:bg-[#F1F8FF]"
              onClick={(e) => {
                handleClose();
                e.preventDefault();
                e.stopPropagation();
                handleChat(record.id, index);
              }}
            >
              <div className={'flex items-center'}>
                <img className="w-[18px] h-[18px] mr-1" src={Chat} alt="chat" />
                <Title variant={'sm'}>Chat</Title>
              </div>
            </MenuItem>
            <MenuItem
              className="display-block pl-4 pr-4 pt-2 pb-2 hover:bg-[#F1F8FF]"
              onClick={(e) => {
                handleClose();
                e.preventDefault();
                e.stopPropagation();
                handleFavourite(
                  record.customId,
                  record?.ShipmentFavorites[0]?.isFavorite || false,
                  index
                );
              }}
            >
              <div className={'flex items-center'}>
                <img
                  className="w-[20px] h-[20px]"
                  src={
                    record?.ShipmentFavorites[0]?.isFavorite
                      ? StarSolid
                      : StartOutline
                  }
                  alt="Star Sign"
                />
                <Title variant={'sm'}>Mark Favourite</Title>
              </div>
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div>
        <div className="flex flex-row w-full mb-[14px] items-center">
          <div
            className={clsx(
              'text-[10px] font-medium mt-0.5 py-1 px-2 w-auto flex justify-center items-center text-white bg-blueCharcoal',
              'p-1 rounded mr-2'
            )}
          >
            {
              OrderTypeMapping[
                record.Order.moveSubType || record.Order.moveType
              ]
            }
          </div>
          <Title variant={'sm'}>{record?.Order?.Company?.name}</Title>
        </div>
      </div>
      <div className="flex items-center gap-2 mt-1">
        {record?.Order?.referenceId && (
          <Paragraph variant={'sm'}>
            {' '}
            REF ID: {record?.Order?.referenceId}
          </Paragraph>
        )}
        <Paragraph variant={'sm'}>
          {cases.CANCELLED
            ? `CANCELLED :   ${moment(record.updatedAt).format('DD/MM/YYYY')}`
            : cases.COMPLETED
            ? `Delivered in: ${moment
                .duration(moment(record.completedAt).diff(record.createdAt))
                .asDays()
                .toFixed(0)} days`
            : `EST: ${
                daysDifference && daysDifference != 'NaN'
                  ? `${daysDifference < 0 ? '0' : daysDifference} days`
                  : '-'
              }`}
        </Paragraph>
        {freightCost ? (
          <Paragraph variant={'sm'} className={'font-bold'}>
            {' '}
            Freight Cost: {`${getCurrencySymbol(
              organization.defaultCurrency
            )}`}{' '}
            {freightCost}
          </Paragraph>
        ) : (
          <></>
        )}
        {totalCost ? (
          <Paragraph variant={'sm'} className={'font-bold'}>
            {' '}
            Total Amount: {`${getCurrencySymbol(
              organization.defaultCurrency
            )}`}{' '}
            {totalCost}
          </Paragraph>
        ) : (
          <></>
        )}
      </div>
      <div className="flex items-center w-full mt-1">
        <div
          className={`rounded-full h-[6px] w-[6px] ${
            cases.DELIVERED || cases.RECEIVED
              ? 'bg-jade700'
              : cases.CANCELLED
              ? 'bg-crimson500'
              : 'bg-primary'
          }`}
        ></div>
        <LinearProgressBar
          variant="determinate"
          value={100}
          sx={{
            backgroundColor: 'white',
            '& .MuiLinearProgress-bar': {
              backgroundColor:
                cases.DELIVERED || cases.RECEIVED
                  ? JADE_700
                  : cases.CANCELLED
                  ? CRIMSON_500
                  : PRIMARY
            },
            border: `1px ${
              cases.DELIVERED || cases.RECEIVED
                ? JADE_700
                : cases.CANCELLED
                ? CRIMSON_500
                : PRIMARY
            } solid`,
            borderRadius: '1000px',
            height: '2px',
            width: '49%'
          }}
        />
        <img
          src={
            cases.DELIVERED || cases.RECEIVED
              ? GreenTruckIcon
              : cases.CANCELLED
              ? RedTruckIcon
              : BlueTruckIcon
          }
          className="w-4"
        />
        <LinearProgressBar
          variant="determinate"
          value={100}
          sx={{
            backgroundColor: 'white',
            '& .MuiLinearProgress-bar': {
              backgroundColor:
                cases.DELIVERED || cases.RECEIVED
                  ? JADE_700
                  : cases.CANCELLED
                  ? CRIMSON_500
                  : PRIMARY
            },
            border: `1px ${
              cases.DELIVERED || cases.RECEIVED
                ? JADE_700
                : cases.CANCELLED
                ? CRIMSON_500
                : PRIMARY
            } solid`,
            borderRadius: '1000px',
            height: '2px',
            width: '49%'
          }}
        />
        <div
          className={`rounded-full h-[6px] w-[6px] ${
            cases.DELIVERED || cases.RECEIVED
              ? 'bg-jade700'
              : cases.CANCELLED
              ? 'bg-crimson500'
              : 'bg-primary'
          }`}
        />
      </div>
      <div className="flex flex-row justify-between items-center">
        <Paragraph variant={'sm'} className={'flex-1 truncate'}>
          {`${
            record?.Order?.pickupNode?.name ||
            record?.Order?.warehousePickUp?.name ||
            '-'
          }`}
        </Paragraph>
        <Paragraph variant={'sm'} className={'flex-1 truncate text-end'}>
          {`${
            record?.Order?.warehouseDropOff?.name ||
            record?.Order?.dropOffNode?.name ||
            '-'
          }`}
        </Paragraph>
      </div>
    </div>
  );
};

export default WOTMSShipment;
