/* eslint-disable */
import { useEffect, useState, useCallback, useContext } from 'react';
import {
  makeStyles,
  Paper,
  Button,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  Grid
} from '@material-ui/core';
import {
  MdFilterAlt,
  MdOutlineAdjust,
  MdOutlineNorth,
  MdOutlineSouth
} from 'react-icons/md';
import classNames from 'classnames/bind';
import { DataGrid } from '@mui/x-data-grid';
import TableHeader from '../../../components/TableHeader';
import Filter from '../../../components/Filter';
import axios from 'axios';
import { getURL, SharedContext } from '../../../utils/common';
import { Alert } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/EditOutlined';
import ConfirmDelete from '../../../components/ConfirmDelete';
import AddUoMView from './AddUoMView';
import PaginationGrid from '../../../components/PaginationGrid';
import SearchInput from '../../../components/SearchInput';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import { checkPermission } from '../../../utils/auth';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { toaster } from '../../../utils/toaster';
import FilterButton from '../../../components/stats/FilterButton';
import mixpanel from 'mixpanel-browser';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '0px'
  },
  container: {
    padding: 20
  },
  active: {
    color: theme.palette.success.main
  },
  addUoMButton: {
    marginLeft: '12px',
    backgroundColor: '#2b3a53',
    float: 'right',
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'none'
  }
}));

export default function UoMView() {
  const classes = useStyles();
  const { currentUser, setAPILoader, organization } = useContext(SharedContext);

  const columnsNew = [
    {
      field: 'isActive',
      headerName: '',
      width: 50,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      className: (value) => (value ? classes.active : ''),
      renderCell: (value) => {
        return (
          <MdOutlineAdjust className={value.value ? 'online' : 'offline'} />
        );
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: 'justify-content-center',
      headerClassName: 'headerCenter',
      renderHeader: () => {
        return (
          <p className="w-100 parentActions">
            Name
            {fetchState && uomNameVal ? (
              <>
                <button
                  className="customHeaderIcon"
                  type="button"
                  onClick={() => {
                    setUomNameVal('');
                  }}
                >
                  <MdFilterAlt />
                </button>
              </>
            ) : (
              ''
            )}
            <button
              type="button"
              onClick={() => {
                sortBy('name');
              }}
              className={
                sortable.col == 'name' && sortable.sorted == 1
                  ? 'customHeaderIcon'
                  : 'customHeaderIcon display-sortable'
              }
            >
              {sortable.col == 'name' && sortable.sorted == 0 ? (
                <MdOutlineNorth />
              ) : (
                <MdOutlineSouth />
              )}
            </button>
          </p>
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      headerClassName: 'datatableActions',
      cellClassName: 'datatableActions',
      renderCell: (params) => (
        <>
          {checkPermission(currentUser, 'OPS_UOM_UPDATE') ? (
            <Tooltip title="Edit">
              <EditIcon
                key={'edit' + params.row}
                onClick={() => openEditView(params.row)}
              />
            </Tooltip>
          ) : (
            ''
          )}
        </>
      )
    }
  ];
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [uoms, setUoMs] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedUoM, setSelectedUoM] = useState(null);
  const [formErrors, setFormErrors] = useState('');
  const [addUoMViewOpen, setAddUoMViewOpen] = useState(false);
  const [deleteUoMViewOpen, setDeleteUoMViewOpen] = useState(false);

  const [filterView, setFilterView] = useState(false);
  const [fetchState, setFetchState] = useState(false);
  const [sortable, setSortable] = useState('');
  const [uomNameVal, setUomNameVal] = useState('');
  const [clickedAlready, setClickedAlready] = useState(false);
  const [muiLoader, setMuiLoader] = useState(false);

  const mainClass = classNames({
    'search-input': true,
    activeSearch: !!searchKeyword && searchKeyword != '' ? true : false
  });

  const addUoM = async (data) => {
    try {
      setClickedAlready(true);
      setAPILoader(true);
      if (!selectedUoM) await API.post(`uoms`, data);
      else await API.put(`uoms/${selectedUoM.id}`, data);
      toaster(
        'success',
        !selectedUoM ? 'UoM has been created.' : 'UoM updated successfully.'
      );
      mixpanel.track(!selectedUoM ? 'UoM Created' : 'UoM Updated', {});
      closeAddUoMView();
      getUoMs();
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setClickedAlready(false);
      setAPILoader(false);
    }
  };

  const deleteUoM = () => {
    axios.delete(getURL(`uom/${selectedUoM.id}`)).then((res) => {
      if (!res.data.success) {
        setFormErrors(
          <Alert
            elevation={6}
            variant="filled"
            severity="error"
            onClose={() => setFormErrors('')}
          >
            {res.data.message}
          </Alert>
        );
        return;
      }
      closeDeleteUoMView();
      getUoMs();
    });
  };

  const openEditView = (uom) => {
    setSelectedUoM(uom);
    setAddUoMViewOpen(true);
  };

  const closeAddUoMView = () => {
    setSelectedUoM(null);
    setAddUoMViewOpen(false);
  };

  const closeDeleteUoMView = () => {
    setSelectedUoM(null);
    setDeleteUoMViewOpen(false);
  };

  useEffect(() => {
    getUoMs(page, searchKeyword);
  }, [page, searchKeyword]);

  const { currentPageTitle, setCurrentPageTitle } = useContext(SharedContext);
  useEffect(() => {
    let title = 'UoM(s)';
    setCurrentPageTitle(title);
    mixpanel.track('UoM Page', {});
  }, [currentPageTitle, setCurrentPageTitle]);

  const addUoMModal = (
    <AddUoMView
      key={3}
      formErrors={formErrors}
      selectedUoM={selectedUoM}
      open={addUoMViewOpen}
      addUoM={addUoM}
      clickedAlready={clickedAlready}
      setClickedAlready={setClickedAlready}
      handleClose={() => closeAddUoMView()}
    />
  );
  const deleteUoMModal = (
    <ConfirmDelete
      key={4}
      confirmDelete={deleteUoM}
      open={deleteUoMViewOpen}
      handleClose={closeDeleteUoMView}
      selectedEntity={selectedUoM && selectedUoM.name}
      title={'UoM'}
    />
  );

  const _getUoMs = async (page, searchKeyword, filters, columns) => {
    try {
      setMuiLoader(true);
      const uoms = await API.get(`uoms`, {
        params: { offset: page, page, search: searchKeyword, filters, columns }
      });
      setPageCount(uoms.pages);
      setUoMs(uoms.data);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setMuiLoader(false);
    }
  };

  const fetchRes = () => {
    setFetchState(true);
    let colVal = {};
    if (uomNameVal) {
      colVal.name = uomNameVal;
    }

    let filters = {
      colVal,
      sortable
    };
    let columns = ['name'];

    _getUoMs(page, searchKeyword, filters, columns);
  };

  useEffect(() => {
    fetchRes();
  }, [uomNameVal, sortable]);

  const getUoMs = useCallback(
    debounce((page, searchKeyword) => {
      let filters = {
        name: uomNameVal ? uomNameVal : '',
        sortable
      };

      let columns = ['name'];
      _getUoMs(page, searchKeyword, filters, columns);
    }, DEBOUNCE_CONST),
    []
  );

  const sortBy = (colName) => {
    let sort = sortable.col == colName && sortable.sorted == 1 ? 0 : 1;
    setSortable({ sorted: sort, col: colName });
  };

  useEffect(() => {
    getUoMs(page, searchKeyword);
  }, [page, searchKeyword]);

  function CustomUOMToolbar() {
    return (
      <FilterButton searchKeyword openFilterView={() => openFilterView()} />
    );
  }

  const openFilterView = () => {
    let changeFilterView = filterView ? false : true;
    setFilterView(changeFilterView);
  };

  const addUoMButton = (
    <Tooltip title="Add UoM">
      <Button
        key={2}
        variant="contained"
        id="removeHoverBtn"
        className={classes.addUoMButton}
        size="small"
        onClick={() => setAddUoMViewOpen(true)}
      >
        ADD UoM
      </Button>
    </Tooltip>
  );

  const searchInputUOM = (
    <SearchInput
      mainClass={mainClass}
      searchKeyword={searchKeyword}
      setSearchKeyword={setSearchKeyword}
      setPage={() => setPage(1)}
    />
  );

  const visibleColumns = { name: true, id: false };

  const setNameVal = (StateVal, e) => {
    StateVal(e);
    setPage(1);
  };

  let nameObj = {
    type: 'text',
    className: 'textField',
    label: 'Name',
    field: 'name',
    value: uomNameVal ? uomNameVal : '',
    setNameVal: (e) => setNameVal(setUomNameVal, e)
  };

  const closeFilter = () => {
    setFilterView(false);
  };
  const filterCols = [nameObj];
  const filterModal = (
    <div className={filterView ? 'filterViewSec' : 'display-none'}>
      <Filter
        title={'UoMs'}
        show={filterView}
        onClickOutside={() => {
          setFilterView(false);
        }}
        handleClose={() => closeFilter()}
        fetchRes={() => fetchRes()}
        columnsNew={filterCols}
        className="headerFilters"
      />
    </div>
  );

  const headerButtons = [
    checkPermission(currentUser, 'OPS_UOM_CREATE') && organization?.customUOM
      ? addUoMButton
      : null,
    checkPermission(currentUser, 'OPS_UOM_CREATE') ||
    checkPermission(currentUser, 'OPS_UOM_UPDATE')
      ? addUoMModal
      : null,
    checkPermission(currentUser, 'OPS_UOM_DELETE') ? deleteUoMModal : null,
    checkPermission(currentUser, 'OPS_ROLE_READ') ? filterModal : null,
    checkPermission(currentUser, 'OPS_UOM_READ') ? searchInputUOM : null
  ];

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <TableHeader buttons={headerButtons} />
        <Grid className="UoMscroll-table">
          <Table aria-label="sticky table">
            <TableBody>
              <div style={{ height: 1100, width: '100%' }}>
                <DataGrid
                  rows={uoms}
                  hiddenColumnModel={['id']}
                  visibleColumns={visibleColumns}
                  columns={columnsNew}
                  components={{ Toolbar: CustomUOMToolbar }}
                  pageSize={20}
                  rowsPerPageOptions={[6]}
                  className="parentDataTable"
                  loading={muiLoader}
                />
              </div>
            </TableBody>
          </Table>
        </Grid>
      </TableContainer>
      <PaginationGrid
        pageCount={pageCount}
        color="primary"
        page={page}
        setPage={setPage}
      />
    </Paper>
  );
}
