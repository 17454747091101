import React, { useState } from 'react';
import deleteIcon from '../../../../assets/icons/deleteIcon.svg';
import { Paragraph } from '../../../../core-components/atoms/Text';
import API from '../../../../libs/axios';
import { toaster } from '../../../../utils/toaster';
import { checkValidationErr } from '../../../../services/BulkUploadServices';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import FLAGS from '../../../../constants/featureFlags';

const BaysListComponent = ({
  warehouse,
  data,
  searchValue,
  onClick,
  getWarehouseBays
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const removeBay = async () => {
    try {
      await API.delete(`warehouses/${warehouse?.id}/bays/${data.id}`);
      toaster('success', `Bay deleted successfully.`);

      getWarehouseBays();
    } catch (error) {
      checkValidationErr(
        error,
        `unique_${'Bay'}s_warehouseId`,
        `$ already exists it must be unique`
      );
    }
  };

  return (
    <div
      className="flex items-center justify-between node-card bg-white cursor-pointer shadow-elevation-1 mx-4 px-4 py-4 bd-4 my-2"
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      onClick={onClick}
    >
      <div className={'flex items-center gap-4'}>
        <div
          className={`h-3 w-3 rounded-full ${
            data.isActive
              ? 'border-[#1AAE2E] bg-[#1AAE2E]'
              : 'border border-solid border-[#9DA3B3]'
          }`}
        />
        <Paragraph
          variant={'sm'}
          className={`${searchValue && 'bg-[#FFF0C3]'}`}
        >
          {data.name}
        </Paragraph>
      </div>
      {isHovering && isFeatureEnabled(FLAGS.LOCATION_HIERARCHY) && (
        <button
          onClick={async (event) => {
            event.stopPropagation();
            await removeBay();
          }}
        >
          <img src={deleteIcon} alt="delete-icon" />
        </button>
      )}
    </div>
  );
};

export default BaysListComponent;
