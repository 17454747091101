/*eslint-disable*/
import API from '../../../libs/axios';

export const getSchedules = (
  originId,
  destinationId,
  date,
  blNumber,
  vesselName,
  shipmentMode
) => {
  return API.get('carrierSchedules', {
    params: {
      originId,
      destinationId,
      date,
      blNumber,
      vesselName,
      type: shipmentMode
    }
  });
};

export const getTransitNodes = (search, mode) => {
  return API.get('nodes/transit', {
    params: { search, portType: mode }
  });
};
