/*eslint-disable*/
import React, { useContext } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox
} from '@mui/material';
import CustomPagination from '../invoiceManagement/CustomPagination';
import { decimalDisplay, SharedContext } from '../../../utils/common';
import SearchBar from '../../../atomicComponents/SearchBar';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  searchBar: {
    minWidth: '250px',
    height: '32px',
    border: '1px solid #67718C',
    borderRadius: '4px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '100%',
    fontSize: '12px',
    '& input::placeholder': {
      fontSize: '12px'
    }
  }
}));

const ShipmentListing = ({
  setSelected,
  selected,
  rows,
  page,
  setPage,
  pages,
  search,
  setSearch,
  company
}) => {
  const classes = useStyles();
  const { organization } = useContext(SharedContext);

  // Check if a row is selected based on shipmentId and expenseId
  const isSelected = (shipmentId, expenseId) =>
    selected.some(
      (item) => item.shipmentId === shipmentId && item.expenseId === expenseId
    );

  // Handle row selection
  const handleSelect = (shipment) => {
    const currentIndex = selected.findIndex(
      (item) =>
        item.shipmentId === shipment.shipmentId &&
        item.expenseId === shipment.expenseId
    );
    const newSelected = [...selected];

    if (currentIndex === -1) {
      newSelected.push(shipment);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelected(newSelected);
  };

  const tableCellClass = 'py-2 h-12 text-xs';

  return (
    <div>
      {/* Search Bar */}
      <div
        style={{ marginBottom: '24px', marginLeft: '16px', marginTop: '24px' }}
      >
        <div className="flex justify-end items-center mb-10 mt-10">
          <div className="shipment-search">
            <SearchBar
              searchKeyword={search}
              setSearchValue={setSearch}
              className={classes.searchBar}
              iconClass="h-4 w-4"
            />
          </div>
        </div>
      </div>

      {/* Table */}
      <TableContainer
        sx={{
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          overflow: 'hidden',
          border: '1px solid #E7E6E6 !important',
          '& .MuiTable-root': {
            borderCollapse: 'unset'
          }
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox"></TableCell>
              <TableCell className="text-[10px] text-dark500 bg-white py-2 font-normal h-[40px]">
                Order ID
              </TableCell>
              <TableCell className="text-[10px] text-dark500 bg-white py-2 font-normal h-[40px]">
                Shipment ID
              </TableCell>
              <TableCell className="text-[10px] text-dark500 bg-white py-2 font-normal h-[40px]">
                Origin - Destination
              </TableCell>
              <TableCell className="text-[10px] text-dark500 bg-white py-2 font-normal h-[40px]">
                Title
              </TableCell>
              <TableCell className="text-[10px] text-dark500 bg-white py-2 font-normal h-[40px]">
                Total Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              const isItemSelected = isSelected(row.shipmentId, row.expenseId);
              const labelId = `checkbox-${row.shipmentId}-${row.expenseId}`;

              return (
                <TableRow
                  hover
                  onClick={() => handleSelect(row)}
                  role="checkbox"
                  tabIndex={-1}
                  key={`${row.shipmentId}-${row.expenseId}`}
                  className={`${row.isDisabled ? 'disabled-row' : ''}`}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </TableCell>
                  <TableCell
                    className={`${tableCellClass} group-hover:text-primaryBlue`}
                  >
                    {row.orderId}
                  </TableCell>
                  <TableCell
                    className={`${tableCellClass} group-hover:text-primaryBlue`}
                  >
                    {row.shipmentCustomId}
                  </TableCell>
                  <TableCell
                    className={`${tableCellClass} group-hover:text-primaryBlue w-[30%]`}
                  >{`${row.origin} - ${row.destination}`}</TableCell>
                  <TableCell
                    className={`${tableCellClass} group-hover:text-primaryBlue`}
                  >
                    {row?.expenseName
                      ? row.expenseName?.indexOf(company) == -1
                        ? row.expenseName
                        : 'Carrier Charges'
                      : '-'}
                  </TableCell>
                  <TableCell
                    className={`${tableCellClass} group-hover:text-primaryBlue`}
                  >
                    {row.expenseCost
                      ? `${organization?.defaultCurrency} ${decimalDisplay(
                          row.expenseCost
                        )}`
                      : '-'}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <CustomPagination page={page} setPage={setPage} pages={pages} />
    </div>
  );
};

export default ShipmentListing;
