/*eslint-disable*/
import { CircularProgress } from '@mui/material';
import { makeStyles, Paper, Popper } from '@material-ui/core';
import { checkPermission, isOwareSuperAdmin } from '../../../utils/auth';
import {
  decimalDisplay,
  getCurrencySymbol,
  SharedContext
} from '../../../utils/common';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { Label, Paragraph, Title } from '../../../core-components/atoms/Text';
import { ClickAwayListener } from '@mui/base';
import NewSearchBar from '../../../atomicComponents/NewSearchBar';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import clsx from 'clsx';
import EditIcon from '@material-ui/icons/EditOutlined';
import ExpenseEditListing from '../../multiModalShipment/expense/ExpenseEditListing';
import InfiniteScroll from 'react-infinite-scroll-component';
import DownloadIcon from '../../../assets/icons/downloadFileIcon.svg';
import FileDownload from 'js-file-download';
import moment from 'moment-timezone';
import OrderDetailStatsMobile from './OrderDetailStatsMobile';
import _ from 'lodash';
import Loader from '../../multiModalShipment/comments/components/Loader';

const useStyles = makeStyles(() => ({
  popper: {
    '& ::-webkit-scrollbar': {
      width: '0.3rem'
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: '#9DA0A6',
      height: '4px'
    },
    '& ::-webkit-scrollbar-thumb:hover': {
      background: '#9DA0A6'
    },
    zIndex: 1300
  },
  paper: {
    pointerEvents: 'auto',
    width: '100%',
    border: 'none',
    borderRadius: '8px',
    marginTop: '2px',
    boxShadow:
      '0px 6.400000095367432px 14.399999618530273px 0px #00000021,0px 1.2000000476837158px 3.5999999046325684px 0px #0000001A',
    overflowY: 'auto',
    '& ::-webkit-scrollbar': {
      width: '0.3rem'
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: '#9DA0A6',
      height: '4px'
    },
    '& ::-webkit-scrollbar-thumb:hover': {
      background: '#9DA0A6'
    },
    '& input': {
      fontSize: '12px !important'
    }
  }
}));

const OrderDetailMobile = ({
  order,
  currentUser,
  orderSummary,
  nonNegative,
  exportToExcel,
  getTotalByKey,
  resetExpense,
  setResetExpense,
  actionButtons
}) => {
  const anchorRef = useRef(null);
  const [poperOpen, setPopperOpen] = React.useState(false);
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [expense, setExpense] = useState([]);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState('');
  const [openExpense, setOpenExpense] = useState(false);
  const [shipmentExpenseTotal, setShipmentExpenseTotal] = useState(0);
  const [miscellaneousExpenseTotal, setMiscellaneousExpenseTotal] = useState(0);
  const [expenseTotal, setExpenseTotal] = useState(0);
  const [firstCall, setFirstCall] = useState(true);
  const { organization, settings } = useContext(SharedContext);

  useEffect(() => {
    if (resetExpense) {
      setExpense([]);
      setResetExpense(false);
      setFirstCall(true);
      setPage(1);
    }
  }, [resetExpense]);
  useEffect(() => {
    if (expense.length <= 0 && order?.id && firstCall) {
      setLoader(true);
      setFirstCall(false);
      getDelayedOrderExpense(1, [], order, search);
    }

    if (expense.length > 0) {
      setLoader(false);
    }
  }, [expense, order, firstCall]);

  const getDelayedOrderExpense = useCallback(
    debounce(
      async (page, oldData, orderDetail, searchData) => {
        getOrderExpense(page, oldData, orderDetail, searchData);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  const getOrderExpense = async (
    page,
    oldData = [],
    orderDetail,
    searchData
  ) => {
    try {
      const res = await API.get(`expenses`, {
        params: {
          page: page,
          limit: 20,
          ...(orderDetail?.id ? { orderId: orderDetail?.id } : {}),
          search: searchData,
          orderByShipmentId: true
        }
      });
      const newArr = res?.data;
      setExpense([...oldData, ...newArr]);
      setPageCount(res?.pages);
      setExpenseTotal(res?.totalCost || 0);
      setShipmentExpenseTotal(res?.totalShipmentCost || 0);
      setMiscellaneousExpenseTotal(res?.totalMiscellaneousCost || 0);
      setLoader(false);
    } catch (error) {
      onError(error);
      setLoader(false);
    }
  };

  const exportExpense = async () => {
    const response = await API.get(`/expenses/export`, {
      responseType: 'blob',
      params: { orderId: order?.id }
    });
    FileDownload(response, `Expenses ${moment().format('DD-MM-yyyy')}.xlsx`);
  };

  return (
    <>
      {_.isEmpty(order) ? (
        <Loader loader className={'ml-4'} />
      ) : (
        <div>
          <ExpenseEditListing
            open={openExpense}
            setOpen={setOpenExpense}
            order={order}
            shipment={null}
            expenses={expense}
            setExpenses={setExpense}
            getDelayedOrderExpense={getDelayedOrderExpense}
            page={page}
            setPage={setPage}
            pageCount={pageCount}
            setResetExpense={setResetExpense}
          />
          <OrderDetailStatsMobile order={order} getTotalByKey={getTotalByKey} />
          <div className="flex justify-between items-center mb-4 mt-14">
            <Title variant={'md'}>SKU Details</Title>
            {actionButtons()}
          </div>

          <div className="w-full p-2">
            {Object.keys(orderSummary).length > 0 &&
              Object.keys(orderSummary).map((key) => (
                <div
                  key={key}
                  className="bg-white rounded-2xl p-8 mb-8 shadow-lg border border-gray-200"
                >
                  <div className="grid grid-cols-2 gap-6 mb-6">
                    <div>
                      <Paragraph
                        variant={'sm'}
                        className="font-bold text-dark600 text-xl"
                      >
                        {orderSummary[key].productName}
                      </Paragraph>
                      <Label
                        variant={'xs'}
                        className="text-gray-500 tracking-wide"
                      >
                        SKU: {orderSummary[key].sku}
                      </Label>
                    </div>
                    {order.moveSubType === 'PURCHASE' &&
                      checkPermission(
                        currentUser,
                        'OPS_COST_PRICE_VISIBILITY'
                      ) && (
                        <div>
                          <Label variant={'xs'} className="text-gray-500">
                            Purchase Price
                          </Label>
                          <Paragraph
                            variant={'sm'}
                            className="text-dark600 font-semibold"
                          >
                            {decimalDisplay(orderSummary[key].costPrice)}
                          </Paragraph>
                        </div>
                      )}
                    {order.moveSubType === 'SALES' &&
                      checkPermission(
                        currentUser,
                        'OPS_SELLING_PRICE_VISIBILITY'
                      ) && (
                        <div>
                          <Label variant={'xs'} className="text-gray-500">
                            Selling Price
                          </Label>
                          <Paragraph
                            variant={'sm'}
                            className="text-dark600 font-semibold"
                          >
                            {decimalDisplay(orderSummary[key].sellingPrice)}
                          </Paragraph>
                        </div>
                      )}
                  </div>
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <Label
                        variant={'xs'}
                        className="text-gray-500 tracking-wide"
                      >
                        Order Qty (Primary)
                      </Label>
                      <Paragraph
                        variant={'sm'}
                        className="text-dark600 font-medium text-lg"
                      >
                        {`${orderSummary[key].total} ${orderSummary[key].uom}`}
                      </Paragraph>
                    </div>
                    <div>
                      <Label
                        variant={'xs'}
                        className="text-gray-500 tracking-wide"
                      >
                        Order Qty (Secondary)
                      </Label>
                      <Paragraph
                        variant={'sm'}
                        className="text-dark600 font-medium text-lg"
                      >
                        {orderSummary[key].secondaryUom
                          ? `${orderSummary[key].secondaryUomQuantity} ${orderSummary[key].secondaryUom}`
                          : '-'}
                      </Paragraph>
                    </div>
                    {order.moveType !== 'LOGISTIC' && (
                      <>
                        <div>
                          <Label
                            variant={'xs'}
                            className="text-gray-500 tracking-wide"
                          >
                            {order.moveType == 'INWARD'
                              ? 'Received Quantity'
                              : 'Dispatched Quantity'}
                          </Label>
                          <Paragraph
                            variant={'sm'}
                            className="text-dark600 font-medium text-lg"
                          >
                            {order.moveType == 'OUTBOUND'
                              ? orderSummary[key].dispatched
                              : orderSummary[key].received}
                          </Paragraph>
                        </div>
                        <div>
                          <Label
                            variant={'xs'}
                            className="text-gray-500 tracking-wide"
                          >
                            Remaining Qty
                          </Label>
                          <Paragraph
                            variant={'sm'}
                            className="text-dark600 font-medium text-lg"
                          >
                            {decimalDisplay(
                              nonNegative(
                                order.moveType == 'OUTBOUND'
                                  ? orderSummary[key].total -
                                      orderSummary[key].dispatched
                                  : orderSummary[key].total -
                                      orderSummary[key].received
                              )
                            )}
                          </Paragraph>
                        </div>
                      </>
                    )}
                    {order.moveSubType === 'PURCHASE' &&
                      checkPermission(
                        currentUser,
                        'OPS_COST_PRICE_VISIBILITY'
                      ) && (
                        <div>
                          <Label
                            variant={'xs'}
                            className="text-gray-500 tracking-wide"
                          >
                            Tax %
                          </Label>
                          <Paragraph
                            variant={'sm'}
                            className="text-dark600 font-medium text-lg"
                          >
                            {decimalDisplay(orderSummary[key].tax)}
                          </Paragraph>
                        </div>
                      )}
                    {order.moveSubType === 'SALES' &&
                      checkPermission(
                        currentUser,
                        'OPS_SELLING_PRICE_VISIBILITY'
                      ) && (
                        <div>
                          <Label
                            variant={'xs'}
                            className="text-gray-500 tracking-wide"
                          >
                            Tax %
                          </Label>
                          <Paragraph
                            variant={'sm'}
                            className="text-dark600 font-medium text-lg"
                          >
                            {decimalDisplay(orderSummary[key].tax)}
                          </Paragraph>
                        </div>
                      )}
                  </div>
                </div>
              ))}

            {/* Summary Section for All Products */}
            <div className="bg-white rounded-2xl p-8 shadow-lg border border-gray-200">
              <div className="grid grid-cols-3 gap-6">
                <div>
                  <Label variant={'xs'} className="text-gray-500 tracking-wide">
                    Calculated Weight:
                  </Label>
                  <Paragraph
                    variant={'sm'}
                    className="text-dark600 font-medium text-lg"
                  >
                    {decimalDisplay(order?.calculatedWeight)}{' '}
                    {settings?.weight?.symbol || 'Kg'}
                  </Paragraph>
                </div>
                <div>
                  <Label variant={'xs'} className="text-gray-500 tracking-wide">
                    Total Qty (Primary):
                  </Label>
                  <Paragraph
                    variant={'sm'}
                    className="text-dark600 font-medium text-lg"
                  >
                    {decimalDisplay(getTotalByKey('total'))}
                  </Paragraph>
                </div>
                <div>
                  <Label variant={'xs'} className="text-gray-500 tracking-wide">
                    Total Qty (Secondary):
                  </Label>
                  <Paragraph
                    variant={'sm'}
                    className="text-dark600 font-medium text-lg"
                  >
                    {decimalDisplay(getTotalByKey('secondaryUomQuantity'))}
                  </Paragraph>
                </div>
                <div className="text-right">
                  <Label variant={'xs'} className="text-gray-500 tracking-wide">
                    Expense Total:
                  </Label>
                  <Paragraph
                    variant={'sm'}
                    className="text-primaryBlue font-bold text-xl"
                  >
                    {getCurrencySymbol(organization?.defaultCurrency) + ' '}
                    {Object.keys(orderSummary).length > 0 &&
                    (order?.moveSubType === 'PURCHASE' ||
                      order?.moveSubType === 'SALES_RETURN')
                      ? decimalDisplay(
                          Object.keys(orderSummary).reduce(
                            (total, key) =>
                              total + (orderSummary[key]?.totalCost || 0),
                            0
                          ) + expenseTotal
                        )
                      : Object.keys(orderSummary).length > 0 &&
                        order?.moveSubType === 'SALES'
                      ? decimalDisplay(
                          Object.keys(orderSummary).reduce(
                            (total, key) =>
                              total + (orderSummary[key]?.totalRevenue || 0),
                            0
                          ) + expenseTotal
                        )
                      : expenseTotal}
                  </Paragraph>
                </div>
              </div>
            </div>
          </div>
          <Popper
            open={poperOpen}
            anchorEl={anchorRef.current}
            placement={'bottom-end'}
            disablePortal
            className={classes.popper}
            modifiers={{
              preventOverflow: {
                enabled: true,
                escapeWithReference: true,
                boundariesElement: 'viewport'
              }
            }}
          >
            <ClickAwayListener
              onClickAway={() => {
                setPopperOpen(false);
              }}
            >
              <Paper className={classes.paper} id={'scrollableDiv'}>
                <div className={'w-[300px] max-h-[400px] p-4'}>
                  {!!expense?.length && (
                    <NewSearchBar
                      showFilter={false}
                      filter={false}
                      setFilterView={() => {}}
                      setSearchValue={(e) => {
                        setSearch(e);
                        getDelayedOrderExpense(1, [], order, search);
                      }}
                      className={'border-0'}
                    />
                  )}
                  <InfiniteScroll
                    dataLength={expense.length}
                    next={() => {
                      getDelayedOrderExpense(page + 1, expense, order);
                      setPage(page + 1);
                    }}
                    style={{
                      overflow: 'hidden'
                    }}
                    hasMore={page < pageCount}
                    pullDownToRefresh={false}
                    loader={
                      <div
                        className={
                          'flex flex-1 overflow-hidden pb-4 justify-center'
                        }
                      >
                        <CircularProgress size={20} />
                      </div>
                    }
                    scrollThreshold={0.1}
                    scrollableTarget="scrollableDiv"
                  >
                    {expense?.map((value, index) => {
                      return (
                        <div
                          key={index}
                          className={clsx(
                            'px-1.5 py-3 flex justify-between items-center',
                            index % 2 === 0 ? ' bg-white' : ' bg-gray-200'
                          )}
                        >
                          <div>
                            <Paragraph variant={'sm'}>{value?.name}</Paragraph>
                            <Paragraph
                              variant={'xs'}
                              className={'text-dark500'}
                            >
                              {value?.Shipment?.customId}
                            </Paragraph>
                          </div>
                          <Paragraph variant={'sm'}>
                            {getCurrencySymbol(organization?.defaultCurrency) +
                              ' ' +
                              value?.cost}
                          </Paragraph>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                  {['SALES', 'PURCHASE'].includes(order?.moveSubType) && (
                    <div
                      className={clsx(
                        'px-1.5 py-3 flex justify-between items-center mt-1',
                        expense?.length ? ' border-t' : ''
                      )}
                    >
                      <Paragraph variant={'sm'} className={'text-dark500'}>
                        Total{' '}
                        {order?.moveSubType === 'SALES' ? 'Sales' : 'Purchase'}
                      </Paragraph>
                      <Paragraph variant={'sm'}>
                        {getCurrencySymbol(organization?.defaultCurrency) +
                          ' ' +
                          Object.values(orderSummary).reduce(
                            (total, val) =>
                              total +
                              (val?.[
                                order?.moveSubType === 'SALES'
                                  ? 'sellingPrice'
                                  : 'costPrice'
                              ] || 0) *
                                (val?.total ?? 0),
                            0
                          )}
                      </Paragraph>
                    </div>
                  )}
                  {!!expense?.length && !!miscellaneousExpenseTotal && (
                    <div
                      className={
                        'border-t px-1.5 py-3 flex justify-between items-center mt-1'
                      }
                    >
                      <Paragraph variant={'sm'} className={'text-dark500'}>
                        Total Miscellaneous Expense
                      </Paragraph>
                      <Paragraph variant={'sm'}>
                        {getCurrencySymbol(organization?.defaultCurrency) +
                          ' ' +
                          miscellaneousExpenseTotal}
                      </Paragraph>
                    </div>
                  )}
                  {!!expense?.length && !!shipmentExpenseTotal && (
                    <div
                      className={
                        'border-t px-1.5 py-3 flex justify-between items-center mt-1'
                      }
                    >
                      <Paragraph variant={'sm'} className={'text-dark500'}>
                        Total Shipment Expense
                      </Paragraph>
                      <Paragraph variant={'sm'}>
                        {getCurrencySymbol(organization?.defaultCurrency) +
                          ' ' +
                          shipmentExpenseTotal}
                      </Paragraph>
                    </div>
                  )}
                  {['SALES', 'PURCHASE'].includes(order?.moveSubType) && (
                    <div
                      className={
                        'border-t px-1.5 py-3 flex justify-between items-center mt-1'
                      }
                    >
                      <Paragraph variant={'sm'} className={'text-dark500'}>
                        Total Tax
                      </Paragraph>
                      <Paragraph variant={'sm'}>
                        {getCurrencySymbol(organization?.defaultCurrency) +
                          ' ' +
                          Object.values(orderSummary).reduce(
                            (total, val) =>
                              total +
                              (val?.[
                                order?.moveSubType === 'SALES'
                                  ? 'totalRevenue'
                                  : 'totalCost'
                              ] || 0) -
                              (val?.[
                                order?.moveSubType === 'SALES'
                                  ? 'sellingPrice'
                                  : 'costPrice'
                              ] || 0) *
                                (val?.total ?? 0),
                            0
                          )}
                      </Paragraph>
                    </div>
                  )}
                  {expense?.length > 0 && (
                    <div
                      className={
                        'border-t px-1.5 py-3 flex justify-between items-center mt-1'
                      }
                    >
                      <div className={'flex'}>
                        <Paragraph variant={'sm'} className={'text-dark500'}>
                          Total Order Expense
                        </Paragraph>
                        {(isOwareSuperAdmin(currentUser) ||
                          checkPermission(
                            currentUser,
                            'OPS_ORDER_EXPENSE_UPDATE'
                          )) && (
                          <EditIcon
                            key="edit"
                            fontSize={'small'}
                            onClick={() => {
                              setOpenExpense(true);
                              setPopperOpen(false);
                            }}
                            className={'ml-3'}
                          />
                        )}
                        <img
                          src={DownloadIcon}
                          className="h-[20px] w-[20px] ml-[6px]"
                          onClick={() => exportExpense()}
                        />
                      </div>
                      <Paragraph variant={'sm'}>
                        {getCurrencySymbol(organization?.defaultCurrency) +
                          ' ' +
                          expenseTotal}
                      </Paragraph>
                    </div>
                  )}
                </div>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </div>
      )}
    </>
  );
};

export default OrderDetailMobile;
