import React, { useCallback, useContext, useEffect, useState } from 'react';
import Popup from '../../../core-components/atoms/Popup';
import CustomButton from '../../../core-components/atoms/Button';
import ExpenseForm from './ExpenseForm';
import { Label } from '../../../core-components/atoms/Text';
import { Grid } from '@material-ui/core';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { SharedContext } from '../../../utils/common';
import { CircularProgress } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { checkPermission, isOwareSuperAdmin } from '../../../utils/auth';
import DeleteConfirmation from '../comments/components/DeleteConfirmation';
import { toaster } from '../../../utils/toaster';
import { debounce } from 'lodash';

const ExpenseEditListing = ({
  open,
  setOpen,
  order,
  shipment,
  expenses,
  setExpenses,
  getDelayedOrderExpense,
  page,
  setPage,
  pageCount,
  setResetExpense
}) => {
  const { setAPILoader, currentUser, organization } = useContext(SharedContext);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(false);
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState('');

  const fetchSearchResults = async (searchQuery, organization) => {
    setOptions([]);
    setAPILoader(true);
    try {
      const [response1, response2] = await Promise.all([
        await API.get(`/companies`, {
          params: { search: searchQuery, type: 'VENDOR' }
        }),
        await API.get(`/vendors`, {
          params: { search: searchQuery }
        })
      ]);
      // Normalize data to have a consistent label and type for each source
      const combinedResults = [
        ...[
          {
            label: organization?.name,
            category: 'Organization',
            id: organization?.id
          }
        ],
        ...response1.data.rows.map((item) => ({
          label: item?.name,
          category: 'Carrier',
          id: item.id,
          ...item
        })),
        ...response2.data.map((item) => ({
          label: item?.name,
          category: 'Vendors',
          id: item?.id,
          ...item
        }))
      ];
      setOptions(combinedResults);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
    setAPILoader(false);
  };

  // Debounce the search function
  const debouncedFetchSearchResults = useCallback(
    debounce(fetchSearchResults, 500),
    []
  );

  // Update search results based on query
  useEffect(() => {
    if (open) {
      debouncedFetchSearchResults(query, organization);
    }
  }, [query, open, organization]);

  // Handle search input change
  const handleSearchInputChange = (event, newInputValue) => {
    if (event) {
      setQuery(newInputValue);
    }
  };

  const postExpense = async () => {
    try {
      setAPILoader(true); // Assuming you need to set the loader before making API calls
      const promises = expenses?.map(async (value) => {
        const payload = {
          name: value?.name,
          cost: value?.cost,
          fileIds: value?.fileIds,
          isBillable: value?.isBillable,
          sourceId: value?.sourceId,
          sourceType: value?.sourceType,
          ...(order?.id ? { orderId: order?.id } : {}),
          ...(value?.shipmentId?.id !== 0
            ? { shipmentId: shipment ? shipment?.id : value?.shipmentId?.id }
            : { shipmentId: null })
        };
        try {
          if (value?.id) {
            const data = await API.put(`expenses/${value?.id}`, payload);
            let index = expenses.findIndex(
              (item) => item.id === data?.data?.id
            );
            if (index !== -1) {
              expenses[index] = data?.data;
            }
            setExpenses(expenses);
          } else {
            const data = await API.post(`expenses`, payload);
            let index = expenses.findIndex(
              (item) =>
                item?.name?.toLowerCase()?.trim() ===
                data?.data?.name?.toLowerCase()?.trim()
            );
            if (index !== -1) {
              expenses[index] = data?.data;
            }
            setExpenses(expenses);
          }
        } catch (e) {
          onError(e);
        }
      });
      await Promise.all(promises);
      toaster('success', `Shipment Expenses have been updated.`);
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      setAPILoader(false);
      setOpen(false);
      setResetExpense(true);
    }
  };

  const removeExpense = async (e) => {
    let objectAtIndex = expenses[e];
    if (objectAtIndex?.id) {
      setSelectedExpense(objectAtIndex);
      setDeletePopupOpen(true);
    } else {
      let array = expenses?.filter((_, index) => index !== e);
      setExpenses(array);
    }
  };
  const confirmDelete = async () => {
    setDeletePopupOpen(false);
    setAPILoader(true);
    if (selectedExpense) {
      try {
        await API.delete(`expenses/${selectedExpense?.id}`);
        let array = expenses?.filter((_, index) => index !== selectedExpense);
        setExpenses(array);
      } catch (e) {
        onError(e);
      } finally {
        setAPILoader(false);
        setSelectedExpense(null);
        setResetExpense(true);
      }
    }
  };

  const isAllValid = expenses?.every((item) => {
    return (
      item &&
      typeof item.name === 'string' &&
      item.name.trim() !== '' &&
      (typeof item.cost === 'string' || typeof item.cost === 'number') &&
      String(item.cost).trim() !== '' &&
      (item?.shipmentLegId || Number(item.cost) !== 0)
    );
  });

  return (
    <div style={{ display: 'inline' }}>
      <Popup
        open={open}
        setOpen={setOpen}
        title={'Edit All Expense'}
        content={
          <div id={'scrollableDiv'}>
            <DeleteConfirmation
              handleClose={() => setDeletePopupOpen(false)}
              open={deletePopupOpen}
              setOpen={setDeletePopupOpen}
              confirmDelete={confirmDelete}
              message={`This will delete ${selectedExpense?.name} from shipment expense permanently. Are you sure you want to delete?`}
            />
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Label variant={'xs'} className={'text-dark500'}>
                  Expense Name
                </Label>
              </Grid>
              {!shipment && (
                <Grid item xs={2}>
                  <Label variant={'xs'} className={'text-dark500'}>
                    type
                  </Label>
                </Grid>
              )}
              <Grid item xs={2}>
                <Label variant={'xs'} className={'text-dark500'}>
                  Cost
                </Label>
              </Grid>
              <Grid item xs={3}>
                <Label variant={'xs'} className={'text-dark500'}>
                  Supplier Name
                </Label>
              </Grid>
              <Grid item xs={1}>
                <Label variant={'xs'} className={'text-dark500'}>
                  Billable
                </Label>
              </Grid>
              <Grid item xs={1}>
                <Label variant={'xs'} className={'text-dark500'}>
                  Documents
                </Label>
              </Grid>
              <Grid item xs={1} />
            </Grid>
            <InfiniteScroll
              dataLength={expenses?.length}
              next={() => {
                getDelayedOrderExpense(page + 1, expenses, order);
                setPage(page + 1);
              }}
              style={{
                overflow: 'hidden'
              }}
              scrollThreshold={0.1}
              hasMore={page < pageCount}
              pullDownToRefresh={false}
              loader={
                <div
                  className={'flex flex-1 overflow-hidden pb-4 justify-center'}
                >
                  <CircularProgress size={20} />
                </div>
              }
              scrollableTarget="scrollableDiv"
            >
              {expenses?.map((value, index) => {
                return (
                  <div key={index}>
                    <ExpenseForm
                      expense={value}
                      setExpenses={setExpenses}
                      expenses={expenses}
                      removeExpense={removeExpense}
                      index={index}
                      order={order}
                      shipment={shipment}
                      handleSearchInputChange={handleSearchInputChange}
                      options={options}
                      deletePermission={
                        isOwareSuperAdmin(currentUser) ||
                        checkPermission(currentUser, 'OPS_ORDER_EXPENSE_DELETE')
                      }
                    />
                  </div>
                );
              })}
            </InfiniteScroll>
            <CustomButton
              label="+ Add More"
              onClick={() =>
                setExpenses([
                  ...expenses,
                  {
                    id: null,
                    name: '',
                    cost: 0,
                    shipmentId: null,
                    fileIds: null
                  }
                ])
              }
              className={'mt-2'}
              variant="text"
              size="large"
              overrideSize={true}
              labelClass="font-medium text-xs"
            />
          </div>
        }
        onClose={() => {
          setOpen(false);
          setResetExpense(true);
        }}
        dialogContentClasses={'w-[75vw]'}
        actions={
          <div className="flex gap-2">
            <CustomButton
              label="Cancel"
              className="py-2 px-3 ml-2 h-8 border-solid border "
              overrideSize={true}
              labelClass="font-medium text-xs"
              onClick={() => {
                setOpen(false);
                setResetExpense(true);
              }}
              variant="tertiary"
            />
            <CustomButton
              disabled={!isAllValid}
              className="py-2 px-3 ml-1 h-8 border-solid border border-Primary"
              overrideSize={true}
              labelClass="font-medium text-xs"
              label={'Save Changes'}
              onClick={async () => {
                await postExpense();
              }}
              variant="primary"
            />
          </div>
        }
      />
    </div>
  );
};

export default ExpenseEditListing;
