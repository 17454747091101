/*eslint-disable*/
import { Box, Tab } from '@material-ui/core';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import ProductIcon from '../../assets/icons/qr_code_scanner.svg';
import CustomerIcon from '../../assets/icons/account-outline.svg';
import TypeIcon from '../../assets/icons/button-pointer.svg';
import DownloadIcon from '../../assets/icons/download.svg';
import StatusIcon from '../../assets/icons/list-status.svg';
import AddIcon from '../../assets/icons/plusSymbolIcon.svg';
import WarehouseIcon from '../../assets/icons/warehouseFilter.svg';
import TruckLightIcon from '../../assets/icons/TruckIconGrey.svg';
import { ORGANIZATION_TYPES } from '../../constants';
import Button from '../../core-components/atoms/Button';
import Popup from '../../core-components/atoms/Popup';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import {
  SharedContext,
  capitalizeWords,
  exportToCSV
} from '../../utils/common';
import { toaster } from '../../utils/toaster';
import AddShipment from './AddShipment';
import AddShipmentTMS from './AddShipmentTMS';
import DateRangePickerSingleCalendar from './DateRangePicker';
import Filters from './Filters';
import OrderSearchBar from './OrderSearchBar';
import { OrderStats } from './OrderStats';
import useStyles from './OrderStyles';
import OrderTable from './OrderTable';
import QuickFilter from './QuickFilter';
import { constants, getColumns, mapStatuses } from './constants';
import AddShipmentValidation from './validationSchema/AddShipmentValidation';
import BulkUpload from './BulkUpload';
import FLAGS from '../../constants/featureFlags';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import TruckIcon from '../../assets/icons/Leg.svg';
import mixpanel from 'mixpanel-browser';
import EditableColumns from './components/editColumns';
import { BulkTrackingUpload } from './components/BulkTrackingUpload';

export const shipmentInitialValues = {
  pickupDateTime: new Date(moment()),
  dropoffDateTime: new Date(moment().add(2, 'hours')),
  logisticBy: 'CUSTOMER',
  pallete: 'Palletized',
  weight: 0,
  vehicleType: {},
  vehicleNumber: null,
  driverName: '',
  driverNumber: ''
};

const filterInitialState = {
  Status: [],
  Type: [],
  Customer: [],
  Warehouse: [],
  'SKU Code / Name': [],
  Driver: [],
  Vehicle: [],
  'External Node': []
};

const activeStatuses = Object.keys(constants.ORDER_STATUS).filter(
  (key) =>
    key != 'CANCELLED' &&
    key != 'COMPLETED' &&
    key != 'PARTIALLY_COMPLETED_CLOSED' &&
    key != 'DRAFT'
);

const OrderManagement = () => {
  const classes = useStyles();
  const { isFeatureEnabled } = useFeatureFlags();
  const [value, setValue] = useState(1);
  const [orderStats, setOrderStats] = useState({});
  const [orderTypeStats, setOrderTypeStats] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [products, setProducts] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [externalNodes, setExternalNodes] = useState([]);
  const [orders, setOrders] = useState([]);
  const [shipmentPopup, setShipmentPopup] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(activeStatuses);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(filterInitialState);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [orderIdFilter, setOrderIdFilter] = useState('');
  const [referenceIdFilter, setReferenceIdFilter] = useState('');
  const [shipmentIdFilter, setShipmentIdFilter] = useState('');
  const [companyNameFilter, setCompanyNameFilter] = useState('');
  const [showCreateShipmentTMS, setShowCreateShipmentTMS] = useState(false);
  const [userPreferences, setUserPreferences] = useState({});
  const [quickFilters, setQuickFilters] = useState({
    Status: [],
    Type: [],
    Customer: []
  });
  const [savedFilters, setSavedFilters] = useState({
    Status: [],
    Type: [],
    Customer: []
  });
  const { setAPILoader, setCurrentPageTitle, organizationType, organization } =
    useContext(SharedContext);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  const getOrderStats = async () => {
    let params = {
      startDate: moment.utc(startDate)?.toISOString(),
      statuses: selectedStatus,
      endDate: moment
        .utc(endDate)
        .add(23, 'hours')
        .add(59, 'minutes')
        .add(59, 'seconds')
        .toISOString(),
      filters
    };
    try {
      let url = `orders/stats`;
      if (companyNameFilter) {
        params.companyId = companyNameFilter;
      }
      let res = await API.get(url, { params });
      setOrderStats(res?.data);
      setOrderTypeStats(res?.orderTypes);
    } catch (err) {
      onError(err);
    }
  };

  const getOrders = async () => {
    const applyFilters = { ...filters };
    if (value > 1) {
      delete applyFilters['Status'];
    }
    try {
      let params = {
        statuses: selectedStatus,
        page,
        limit: 10,
        filters: applyFilters,
        startDate: moment.utc(startDate)?.toISOString(),
        endDate: moment
          .utc(endDate)
          .add(23, 'hours')
          .add(59, 'minutes')
          .add(59, 'seconds')
          .toISOString()
      };
      if (orderIdFilter) {
        params.orderId = orderIdFilter;
      }

      if (referenceIdFilter) {
        params.referenceId = referenceIdFilter;
      }

      if (shipmentIdFilter) {
        if (shipmentIdFilter.orderId) {
          params.orderId = shipmentIdFilter.orderId;
        } else {
          params.shipmentId = shipmentIdFilter;
        }
      }

      if (companyNameFilter) {
        params.company = companyNameFilter;
      }
      let res = await API.get('orders', {
        params,
        columns: []
      });
      setOrders(res.data);
      setPages(res.pages);
    } catch (err) {
      onError(err);
    }
  };

  const dummyDataNotification = async () => {
    if (!organization?.hasDummyData) return;

    try {
      let res = await API.get('orders/exists');
      if (!res.exists) {
        // toaster('warning', 'This is the dummy data');
      }
    } catch (err) {
      onError(err);
    }
  };

  const getQuickFilters = async () => {
    try {
      let response = await API.get('quick-filters/Orders');
      let updatedQuickFilters = {
        Status: response?.payload?.status || [],
        Type: response?.payload?.moveType || [],
        Customer: response?.payload?.companyId || []
      };
      setSavedFilters(updatedQuickFilters);
      setQuickFilters(updatedQuickFilters);
    } catch (err) {
      onError(err);
    }
  };

  const handleChange = (event, newValue) => {
    const statusMapping = {
      0: Object.keys(constants.ORDER_STATUS),
      1: activeStatuses,
      2: ['DRAFT'],
      3: ['COMPLETED'],
      4: ['PARTIALLY_COMPLETED_CLOSED'],
      5: ['CANCELLED']
    };

    const statusEventNames = {
      0: 'All Orders',
      1: 'Active Orders',
      2: 'Draft Orders',
      3: 'Completed Orders',
      4: 'Partially Completed Orders',
      5: 'Cancelled Orders'
    };

    const selectedStatus = statusMapping[newValue] || [];
    setSelectedStatus(selectedStatus);
    setValue(newValue);
    setPage(1);

    mixpanel.track('Order Management Tab', {
      status: statusEventNames[newValue] || 'Unknown Status'
    });
  };

  useEffect(() => {
    getQuickFilters();
    setCurrentPageTitle('Order Management');
    getUserColumnPreference();
    mixpanel.track('Order Management', {});
  }, []);

  useEffect(() => {
    dummyDataNotification();
  }, []);

  useEffect(() => {
    getOrders();
    getOrderStats();
  }, [
    value,
    page,
    filters,
    orderIdFilter,
    referenceIdFilter,
    companyNameFilter,
    startDate,
    endDate,
    shipmentIdFilter
  ]);

  const activeTabText =
    value == 0
      ? ''
      : value == 1
      ? 'Active'
      : value == 2
      ? 'Draft'
      : value == 3
      ? 'Completed'
      : value == 4
      ? 'Closed'
      : 'Cancelled';

  const formik = useFormik({
    initialValues: {
      ...shipmentInitialValues
    },
    onSubmit: (values) => {
      addShipment(values);
    },
    validationSchema: AddShipmentValidation
  });

  const getCompanies = async (type, search) => {
    try {
      let companyData;
      if (search) {
        companyData = await API.get(`companies`, {
          params: { search, type }
        });
      } else {
        companyData = await API.get(`companies`, {
          params: { type }
        });
      }

      type === 'SUPPLIER'
        ? setSuppliers(companyData?.data?.rows)
        : setCompanies(companyData?.data?.rows);
    } catch (err) {
      // onError(err)
    }
  };

  const getProducts = async (search) => {
    try {
      let productData;
      if (search) {
        productData = await API.get(`products`, {
          params: {
            search,
            columns: ['name', 'description']
          }
        });
      } else {
        productData = await API.get(`products`);
      }

      setProducts(productData?.data);
    } catch (err) {
      onError(err);
    }
  };

  const getWarehouses = (search) => {
    setAPILoader(true);
    let filters = {
      colVal: {
        isActive: 1
      },
      sortable: ''
    };

    API.get(`warehouses`, {
      params: { filters, search }
    }).then((res) => {
      if (res.data) {
        setWarehouses(res.data);
      }
    });
    setAPILoader(false);
  };

  const getVehicles = (search) => {
    setAPILoader(true);

    API.get(`vehicles`, {
      params: { search }
    }).then((res) => {
      if (res.data) {
        setVehicles(res.data);
      }
    });
    setAPILoader(false);
  };

  const getDrivers = (search) => {
    setAPILoader(true);

    API.get(`drivers`, {
      params: { search }
    }).then((res) => {
      if (res.data) {
        setDrivers(res.data);
      }
    });
    setAPILoader(false);
  };

  const getExternalNodes = (search) => {
    setAPILoader(true);
    API.get(`nodes`, {
      params: { search, nodeClass: 'EXTERNAL', fetchAll: true }
    }).then((res) => {
      if (res.data) {
        setExternalNodes(res.data);
      }
    });
    setAPILoader(false);
  };

  const addShipment = async (values) => {
    try {
      const payload = {
        shipmentPayload: {
          ...values,
          palletize: values.pallete == 'Palletized'
        },
        legsPayload: [],
        hostUrl: window.location.href
      };
      setAPILoader(true);
      await API.post(`orders/${selectedOrder.customId}/shipments`, payload);
      if (selectedOrder?.status == 'PENDING') {
        await API.put(`orders/${selectedOrder.customId}/status/IN_PROGRESS`);
      }
      await refetch();
      toaster('success', 'Shipment created successfully');
    } catch (e) {
      onError(e);
    } finally {
      setAPILoader(false);
    }
  };

  const refetch = async () => {
    await getOrderStats();
    await getOrders();
  };

  const setFilter = (keyValue, value, type, filterType) => {
    let key = keyValue;
    let currentFilters = !filterType ? { ...filters } : { ...quickFilters };
    let copiedArr = [...currentFilters[key]];
    if (type === 'add') {
      copiedArr.push(value);
    } else {
      copiedArr = currentFilters[key].filter((v) => v.value != value.value);
    }
    delete currentFilters[key];
    currentFilters[key] = copiedArr;
    !filterType ? setFilters(currentFilters) : setQuickFilters(currentFilters);
  };

  const navigate = useNavigate();

  const orderTypes =
    organizationType === ORGANIZATION_TYPES.MANUFACTURER
      ? constants.MANUFACTURER_ORDER_TYPES
      : constants.PL3_ORDER_TYPES;

  let filterOptions = [
    {
      label: 'Type',
      options: Object.keys(orderTypes)
        ?.filter((type) =>
          isFeatureEnabled(FLAGS.TMS) ? true : type !== 'LOGISTIC'
        )
        .map((key, id) => ({
          value: key,
          label: capitalizeWords(orderTypes[key]),
          id
        })),
      icon: TypeIcon
    },
    {
      label: 'SKU Code / Name',
      options: products?.map((product, id) => ({
        value: product.id,
        label: `${product.name} - ${product.description}`,
        id
      })),
      icon: ProductIcon
    },
    {
      label: 'Customer',
      options: companies?.map((company, id) => ({
        value: company.id,
        label: company.name,
        id
      })),
      icon: CustomerIcon
    },
    ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
      ? [
          {
            label: 'Supplier',
            options: suppliers?.map((company, id) => ({
              value: company.id,
              label: company.name,
              id
            })),
            icon: CustomerIcon
          }
        ]
      : []),
    {
      label: 'Warehouse',
      options: warehouses?.map((warehouse, id) => ({
        value: warehouse.nodeId,
        label:
          warehouse.businessWarehouseCode || warehouse.name
            ? `${warehouse.name} ${
                warehouse?.businessWarehouseCode
                  ? `- ${warehouse.businessWarehouseCode}`
                  : ''
              }`
            : '',
        id
      })),
      icon: WarehouseIcon
    },
    {
      label: 'External Node',
      options: externalNodes?.map((node, id) => ({
        value: node.id,
        label:
          node.code || node.name
            ? `${node.name} ${node?.code ? `- ${node.code}` : ''}`
            : '',
        id
      })),
      icon: WarehouseIcon
    },
    ...(isFeatureEnabled(FLAGS.TMS)
      ? [
          {
            label: 'Driver',
            options: drivers?.map((driver, id) => ({
              value: driver.id,
              label: driver.name,
              id
            })),
            icon: CustomerIcon
          }
        ]
      : []),
    ...(isFeatureEnabled(FLAGS.TMS)
      ? [
          {
            label: 'Vehicle',
            options: vehicles?.map((vehicle, id) => ({
              value: vehicle.id,
              label: vehicle.registrationNumber,
              id
            })),
            icon: TruckLightIcon
          }
        ]
      : [])
  ];

  let quickFilterOptions = [
    {
      label: 'Type',
      options: Object.keys(
        organizationType === ORGANIZATION_TYPES.MANUFACTURER
          ? constants.MANUFACTURER_ORDER_TYPES
          : constants.PL3_ORDER_TYPES
      ).map((key, id) => ({
        value: key,
        label: capitalizeWords(
          organizationType === ORGANIZATION_TYPES.MANUFACTURER
            ? constants.MANUFACTURER_ORDER_TYPES[key]
            : constants.PL3_ORDER_TYPES[key]
        ),
        id
      })),
      icon: TypeIcon
    },
    {
      label: 'Customer',
      options: companies?.map((company, id) => ({
        value: company.id,
        label: company.name,
        id
      })),
      icon: CustomerIcon
    }
  ];

  if (value === 1 || value == 0) {
    filterOptions.unshift({
      label: 'Status',
      options:
        value == 1
          ? Object.keys(
              mapStatuses(
                isFeatureEnabled(FLAGS.TMS),
                isFeatureEnabled(FLAGS.PICKING)
              )
            )
              .filter(
                (key) =>
                  key != 'CANCELLED' &&
                  key != 'COMPLETED' &&
                  key != 'PARTIALLY_COMPLETED_CLOSED' &&
                  key != 'DRAFT'
              )
              .map((key, id) => ({
                value: key,
                label: constants.ORDER_STATUS[key],
                id
              }))
          : Object.keys(
              mapStatuses(
                isFeatureEnabled(FLAGS.TMS),
                isFeatureEnabled(FLAGS.PICKING)
              )
            ).map((key, id) => ({
              value: key,
              label: constants.ORDER_STATUS[key],
              id
            })),
      icon: StatusIcon
    });
    quickFilterOptions.push({
      label: 'Status',
      options:
        value == 1
          ? Object.keys(
              mapStatuses(
                isFeatureEnabled(FLAGS.TMS),
                isFeatureEnabled(FLAGS.PICKING)
              )
            )
              .filter(
                (key) =>
                  key != 'CANCELLED' &&
                  key != 'COMPLETED' &&
                  key != 'PARTIALLY_COMPLETED_CLOSED' &&
                  key != 'DRAFT'
              )
              .map((key, id) => ({
                value: key,
                label: constants.ORDER_STATUS[key],
                id
              }))
          : Object.keys(
              mapStatuses(
                isFeatureEnabled(FLAGS.TMS),
                isFeatureEnabled(FLAGS.PICKING)
              )
            ).map((key, id) => ({
              value: key,
              label: constants.ORDER_STATUS[key],
              id
            })),
      icon: StatusIcon
    });
  }

  const exportToExcel = () => {
    API.get(`orders/export`, {
      params: {
        filters
      }
    }).then((response) => {
      const orders = response?.data.map((data) => {
        const {
          customId,
          status,
          createdAt,
          pickupNode,
          dropOffNode,
          Company,
          moveType,
          pickupDate,
          dropoffDate,
          referenceId,
          memo
        } = data;

        let origin = pickupNode?.name.includes(',')
          ? `"${pickupNode?.name}"`
          : pickupNode?.name;
        let destination = dropOffNode?.name.includes(',')
          ? `"${dropOffNode?.name}"`
          : dropOffNode?.name;

        return {
          orderId: customId,
          orderStatus: constants.ORDER_STATUS[status],
          createdAt: createdAt
            ? moment(createdAt).format('DD/MM/yy HH:mm')
            : '-',
          pickupNode: origin || '',
          dropOffNode: destination || '',
          Company: Company?.name.includes(',')
            ? `"${Company?.name}"`
            : Company?.name || '',
          moveType: moveType,
          pickupDate: pickupDate
            ? moment(pickupDate).format('DD/MM/yy HH:mm')
            : '-',
          dropoffDate: dropoffDate
            ? moment(dropoffDate).format('DD/MM/yy HH:mm')
            : '-',
          referenceId: `'${referenceId || ' '}`,
          memo: memo || ''
        };
      });
      let title = `orders ${moment().format('DD-MM-yyyy')}`;
      const header = [
        'Order ID',
        'Order Status',
        'Date of Order',
        'Origin',
        'Destination',
        'Customer',
        'Move Type',
        'Pickup Date',
        'Dropoff Date',
        'Supporting Document ID',
        'Additional Instruction'
      ];
      mixpanel.track('Orders Export', { header });
      exportToCSV(header, orders, title);
    });
  };

  const saveQuickFilters = async () => {
    setAPILoader(true);
    try {
      await API.put('quick-filters/Orders', {
        payload: {
          status: quickFilters.Status || [],
          companyId: quickFilters.Customer || [],
          moveType: quickFilters.Type || []
        }
      });
      await getQuickFilters();
    } catch (err) {
      // onError(err)
    }
    setAPILoader(false);
  };

  const getUserColumnPreference = async () => {
    try {
      const response = await API.get('user-column-preferences/Orders');
      setUserPreferences(response || {});
    } catch (e) {
      console.error(e);
    }
  };

  const updatePreference = async (col, checked) => {
    try {
      if (checked) {
        let payload = userPreferences
          ? {
              ...userPreferences,
              payload: {
                columns: userPreferences?.payload?.columns
                  ? [...userPreferences.payload.columns, col]
                  : [col]
              }
            }
          : { payload: { columns: [col] } };
        await API.put('user-column-preferences/Orders', payload);
        await getUserColumnPreference();
        toaster('success', 'User Preference Updated Successfully!');
      } else {
        let payload = {
          ...userPreferences,
          payload: {
            columns: userPreferences?.payload?.columns?.filter(
              (column) => column !== col
            )
          }
        };
        await API.put('user-column-preferences/Orders', payload);
        await getUserColumnPreference();
        toaster('success', 'User Preference Updated Successfully!');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const columns = getColumns(organizationType);

  const closeOrder = async (order) => {
    try {
      setAPILoader(true);
      await API.put(`orders/${order.customId}/close`, {
        hostUrl: window.location.href
      });
      await refetch();
      toaster('success', 'Order closed successfully');
    } catch (e) {
      onError(e);
    } finally {
      setAPILoader(false);
    }
  };

  const orderCreatable = () =>
    (organizationType === ORGANIZATION_TYPES.MANUFACTURER
      ? isFeatureEnabled(FLAGS.SALES_ORDER_CREATION) ||
        isFeatureEnabled(FLAGS.SALES_RETURN_ORDER_CREATION) ||
        isFeatureEnabled(FLAGS.PURCHASE_ORDER_CREATION)
      : isFeatureEnabled(FLAGS.INBOUND_ORDER_CREATION) ||
        isFeatureEnabled(FLAGS.OUTBOUND_ORDER_CREATION) ||
        isFeatureEnabled(FLAGS.LOGISTICS_ORDER_CREATION)) ||
    isFeatureEnabled(FLAGS.TRANSFER_ORDER_CREATION);

  return (
    <>
      {isMobile ? (
        <>
          <OrderTable
            orders={orders}
            setShipmentPopup={setShipmentPopup}
            setSelectedOrder={setSelectedOrder}
            refetch={refetch}
            formik={formik}
            currentTab={value}
            pages={pages}
            setPage={setPage}
            page={page}
            closeOrder={closeOrder}
            orderIdFilter={orderIdFilter}
            referenceIdFilter={referenceIdFilter}
            companyNameFilter={companyNameFilter}
            shipmentIdFilter={shipmentIdFilter}
            setShowCreateShipmentTMS={setShowCreateShipmentTMS}
            extraColumns={userPreferences?.payload?.columns || []}
            isMobile
            onCreateOrder={() => navigate('/routing/create-order')}
            orderCreatable={orderCreatable}
            handleTabChange={handleChange}
            orderStats={orderStats}
            tabValue={value}
          />

          <Popup
            open={shipmentPopup}
            setOpen={setShipmentPopup}
            content={<AddShipment order={selectedOrder} formik={formik} />}
            onClose={() => {
              setShipmentPopup(false);
              setSelectedOrder(null);
              formik.setValues({});
            }}
            actions={
              <div className="flex gap-2">
                <Button
                  label="cancel"
                  variant="transparent"
                  onClick={() => {
                    setShipmentPopup(false);
                    setSelectedOrder(null);
                    formik.setValues({});
                  }}
                  size="large"
                />
                {isFeatureEnabled(FLAGS.BASIC_SHIPMENT_CREATION) && (
                  <Button
                    label="Create Shipment"
                    variant="primary"
                    onClick={async () => {
                      await addShipment(formik.values);
                      setSelectedOrder(null);
                      setShipmentPopup(false);
                      formik.setValues({ ...shipmentInitialValues });
                    }}
                    size="large"
                    disabled={
                      isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                        ? !formik.values.carrier?.id ||
                          !formik.values.vehicleType?.id
                        : false
                    }
                  />
                )}
              </div>
            }
            title={
              <div className="flex items-center justify-center gap-2">
                <img src={TruckIcon} />
                <span>{selectedOrder?.pickupNode?.name}</span>
                <div className="text-[40px] flex items-center mb-[10px]">
                  &#8594;
                </div>
                <span>{selectedOrder?.dropOffNode?.name}</span>
              </div>
            }
            headerClass={'border-b w-full border-b-[#E1E5F0] mb-4'}
          />
        </>
      ) : (
        <div>
          <AddShipmentTMS
            open={showCreateShipmentTMS}
            setOpen={setShowCreateShipmentTMS}
            selectedOrder={selectedOrder}
            refetch={refetch}
          />
          <OrderStats
            orderStats={orderStats || {}}
            orderTypeStats={orderTypeStats || []}
          />
          <div className="mx-4 mt-8 md:mr-4 md:ml-8 md:mt-16">
            <div className="flex flex-col md:flex-row items-center justify-between border-b-[1px] border-solid border-t-0 border-r-0 border-l-0 border-[#E1E5F0]">
              <div className="w-full md:w-auto">
                <TabContext value={value}>
                  <Box className={classes.tabContainer}>
                    <TabList
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab
                        className="min-w-fit"
                        label={
                          <span className="flex">
                            Active ({orderStats?.[5]?.value})
                          </span>
                        }
                        value={1}
                      />
                      <Tab
                        className="min-w-fit"
                        label={
                          <span className="flex">
                            Draft ({orderStats?.[4]?.value})
                          </span>
                        }
                        value={2}
                      />
                      <Tab
                        className="min-w-fit"
                        label={
                          <span className="flex">
                            Completed ({orderStats?.[1]?.value})
                          </span>
                        }
                        value={3}
                      />
                      <Tab
                        className="min-w-fit"
                        label={
                          <span className="flex">
                            Closed ({orderStats?.[2]?.value})
                          </span>
                        }
                        value={4}
                      />
                      <Tab
                        className="min-w-fit"
                        label={
                          <span className="flex">
                            Cancelled ({orderStats?.[3]?.value})
                          </span>
                        }
                        value={5}
                      />
                      <Tab
                        className="min-w-fit"
                        label={
                          <span className="flex">
                            All ({orderStats?.[0]?.value})
                          </span>
                        }
                        value={0}
                      />
                    </TabList>
                  </Box>
                </TabContext>
              </div>
              <div className="flex flex-col md:flex-row items-center justify-start md:justify-end gap-2 md:gap-4">
                {isFeatureEnabled(FLAGS.ORDERS_BULK_UPLOAD) && (
                  <BulkUpload refetch={refetch} />
                )}
                {((organizationType === ORGANIZATION_TYPES.MANUFACTURER
                  ? isFeatureEnabled(FLAGS.SALES_ORDER_CREATION) ||
                    isFeatureEnabled(FLAGS.SALES_RETURN_ORDER_CREATION) ||
                    isFeatureEnabled(FLAGS.PURCHASE_ORDER_CREATION)
                  : isFeatureEnabled(FLAGS.INBOUND_ORDER_CREATION) ||
                    isFeatureEnabled(FLAGS.OUTBOUND_ORDER_CREATION) ||
                    isFeatureEnabled(FLAGS.LOGISTICS_ORDER_CREATION)) ||
                  isFeatureEnabled(FLAGS.TRANSFER_ORDER_CREATION)) && (
                  <Button
                    label="Create Order"
                    className="py-2 px-3 h-8 border-0 rounded w-full md:w-auto"
                    variant="primary"
                    overrideSize={true}
                    icon={<img src={AddIcon} className="mr-2" />}
                    labelClass="font-medium text-xs"
                    iconClass="h-[14px] w-[14px] mr-[6px] self-center"
                    size="large"
                    onClick={() => navigate('/routing/create-order')}
                  />
                )}
              </div>
            </div>
            <div className="mt-6 flex flex-col md:flex-row justify-between">
              <p className="text-base md:text-lg font-semibold text-primaryBlue">
                {orderIdFilter && shipmentIdFilter && referenceIdFilter
                  ? `Search Results for: ${orderIdFilter}`
                  : orderIdFilter
                  ? `Search result for “Order ID: ${orderIdFilter}”`
                  : companyNameFilter
                  ? `Search result for orders containing “${companyNameFilter}”`
                  : `All ${activeTabText} Orders`}
              </p>
              <div className="flex flex-col md:flex-row mt-4 md:mt-0">
                <OrderSearchBar
                  setOrderIdFilter={setOrderIdFilter}
                  setReferenceIdFilter={setReferenceIdFilter}
                  params={{ filters, selectedStatus, startDate, endDate }}
                  setCompanyNameFilter={setCompanyNameFilter}
                  setCurrentTab={setValue}
                  tabValue={value}
                  orderFilterId={orderIdFilter}
                  referenceFilterId={referenceIdFilter}
                  companyFiterName={companyNameFilter}
                  shipmentIdFilter={shipmentIdFilter}
                  setShipmentIdFilter={setShipmentIdFilter}
                />
                <BulkTrackingUpload />
                <EditableColumns
                  fixedColumns={columns[value].fixed}
                  updatePreference={updatePreference}
                  variableColumns={columns[value].variable}
                  editableColumns={userPreferences?.payload?.columns || []}
                />
                <DateRangePickerSingleCalendar
                  startDate={startDate}
                  endDate={endDate}
                  updateRange={(input) => {
                    setStartDate(input.selection.startDate);
                    setEndDate(input.selection.endDate);
                  }}
                />
                <Filters
                  options={filterOptions}
                  filters={filters}
                  getCompanies={getCompanies}
                  getWarehouses={getWarehouses}
                  getDrivers={getDrivers}
                  getVehicles={getVehicles}
                  getExternalNodes={getExternalNodes}
                  getProducts={getProducts}
                  setFilter={setFilter}
                />
                <Button
                  icon={<img src={DownloadIcon} className="mr-[6px]" />}
                  className="p-2 mt-4 md:mt-0 md:ml-2 h-8 rounded border-solid"
                  label="Export"
                  iconClass="h-[14px] w-[14px] mr-1"
                  variant="transparent"
                  overrideSize={true}
                  labelClass="font-medium text-xs"
                  onClick={exportToExcel}
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-wrap gap-2">
                <div className="px-2 py-2.5 border-x border-t border-solid border-dark300 w-full md:flex md:flex-wrap md:gap-3 items-center rounded-t-lg">
                  <QuickFilter
                    options={quickFilterOptions}
                    filters={quickFilters}
                    getCompanies={getCompanies}
                    setFilter={setFilter}
                    saveQuickFilters={saveQuickFilters}
                  />
                  {savedFilters?.Type?.map((t, index) => {
                    let isSelected =
                      filters?.Type?.findIndex(
                        (type) => type.value == t.value
                      ) != -1;
                    let className = isSelected ? 'bg-[#274E77] text-white' : '';
                    return (
                      <div
                        key={index}
                        className={`px-1.5 py-1.5 border-solid cursor-pointer border border-[#67718C] inline-block ${className} rounded flex items-center`}
                        onClick={() =>
                          setFilter('Type', t, isSelected ? 'remove' : 'add')
                        }
                      >
                        <span className="text-xs">{t.label}</span>
                        <img className="ml-2 h-[8.5px] w-[8.5px]" />
                      </div>
                    );
                  })}
                  {(savedFilters?.Status.length > 0 ||
                    savedFilters?.Customer.length > 0 ||
                    savedFilters?.Type.length > 0) && (
                    <div
                      className="px-3 py-[2px] cursor-pointer border-[#BDBDBD] border-solid border-0 border-l"
                      onClick={(e) => {
                        e.preventDefault();
                        setFilters({
                          Customer: [],
                          Type: [],
                          Warehouse: [],
                          'SKU Code / Name': [],
                          Vehicle: [],
                          'External Node': [],
                          Driver: [],
                          Status: []
                        });
                      }}
                    >
                      <span className="text-primary font-medium">Reset</span>
                    </div>
                  )}
                </div>
              </div>
              <OrderTable
                orders={orders}
                setShipmentPopup={setShipmentPopup}
                setSelectedOrder={setSelectedOrder}
                refetch={refetch}
                formik={formik}
                currentTab={value}
                pages={pages}
                setPage={setPage}
                page={page}
                closeOrder={closeOrder}
                orderIdFilter={orderIdFilter}
                referenceIdFilter={referenceIdFilter}
                companyNameFilter={companyNameFilter}
                shipmentIdFilter={shipmentIdFilter}
                setShowCreateShipmentTMS={setShowCreateShipmentTMS}
                extraColumns={userPreferences?.payload?.columns || []}
                isMobile
                onCreateOrder={() => navigate('/routing/create-order')}
                orderCreatable={orderCreatable}
                handleTabChange={handleChange}
                orderStats={orderStats}
                tabValue={value}
              />
            </div>
          </div>

          <Popup
            open={shipmentPopup}
            setOpen={setShipmentPopup}
            content={<AddShipment order={selectedOrder} formik={formik} />}
            onClose={() => {
              setShipmentPopup(false);
              setSelectedOrder(null);
              formik.setValues({});
            }}
            actions={
              <div className="flex gap-2">
                <Button
                  label="cancel"
                  variant="transparent"
                  onClick={() => {
                    setShipmentPopup(false);
                    setSelectedOrder(null);
                    formik.setValues({});
                  }}
                  size="large"
                />
                {isFeatureEnabled(FLAGS.BASIC_SHIPMENT_CREATION) && (
                  <Button
                    label="Create Shipment"
                    variant="primary"
                    onClick={async () => {
                      await addShipment(formik.values);
                      setSelectedOrder(null);
                      setShipmentPopup(false);
                      formik.setValues({ ...shipmentInitialValues });
                    }}
                    size="large"
                    disabled={
                      isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                        ? !formik.values.carrier?.id ||
                          !formik.values.vehicleType?.id
                        : false
                    }
                  />
                )}
              </div>
            }
            title={
              <div className="flex items-center justify-center gap-2">
                <img src={TruckIcon} />
                <span>{selectedOrder?.pickupNode?.name}</span>
                <div className="text-[40px] flex items-center mb-[10px]">
                  &#8594;
                </div>
                <span>{selectedOrder?.dropOffNode?.name}</span>
              </div>
            }
            headerClass={'border-b w-full border-b-[#E1E5F0] mb-4'}
          />
        </div>
      )}
    </>
  );
};

export default OrderManagement;
