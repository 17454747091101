/*eslint-disable*/

import RightArrowIcon from '../../../../assets/icons/rightArrow.svg';
import CancelledIcon from '../../../../assets/icons/cancelled.svg';
import EditIcon from '../../../../assets/icons/edit-row.svg';
import { useContext, useState } from 'react';
import AddShipmentTMS from '../../AddShipmentTMS';
import clsx from 'clsx';
import ShipmentIcon from '../../../../assets/icons/shipment.svg';
import truckIcon from '../../../../assets/icons/TruckIcon.svg';
import moment from 'moment';
import { ActionBtns } from '../tracking/LogisticTMSShipmentTracking';
import CalendarIcon from '../../../../assets/icons/calendarIcon.svg';
import ShipmentTab from '../../../../assets/icons/shipmentTab.svg';
import { toaster } from '../../../../utils/toaster';
import { onError } from '../../../../libs/errorLib';
import API from '../../../../libs/axios';
import ShipIcon from '../../../../assets/icons/ShipIcon.svg';
import AirplaneIcon from '../../../../assets/icons/AirplaneIcon.svg';
import DarkShipIcon from '../../../../assets/icons/darkShip.svg';
import DarkAeroplaneIcon from '../../../../assets/icons/darkAeroPlane.svg';
import DarkTruckIcon from '../../../../assets/icons/shipment.svg';
import BlueTruckIcon from '../../../../assets/icons/TruckIcon.svg';
import GreenTruckIcon from '../../../../assets/icons/greentTruckIcon.svg';
import GreenShipIcon from '../../../../assets/icons/greenShip.svg';
import GreenAeroPlaneIcon from '../../../../assets/icons/greenAeroplane.svg';
import RedTruckIcon from '../../../../assets/icons/redTruckIcon.svg';
import RedShipIcon from '../../../../assets/icons/redShip.svg';
import RedAeroplaneIcon from '../../../../assets/icons//redAeroPlane.svg';
import { SharedContext } from '../../../../utils/common';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import FLAGS from '../../../../constants/featureFlags';
import Popup from '../../../../core-components/atoms/Popup';
import LatLngForm from '../LatLngForm';
import Button from '../../../../core-components/atoms/Button';

export const LogisticShipmentTracking = ({ shipment, order, refetch }) => {
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState('edit');
  const [locationPopup, setLocationPopup] = useState(false);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const { isFeatureEnabled } = useFeatureFlags();

  const { organization } = useContext(SharedContext);

  let cases = {
    AWAITING_CARRIER:
      shipment.status.key == 'CREATED' &&
      shipment?.ShipmentLegs?.[0]?.status == 'AWAITING_CARRIER',
    CARRIER_ASSIGNED:
      shipment.status.key == 'CREATED' &&
      shipment?.ShipmentLegs?.[0]?.status == 'CARRIER_ASSIGNED' &&
      shipment.subStatus?.key !== 'LOADING_IN_PROGRESS',
    LOADING_IN_PROGRESS:
      shipment.status.key == 'CREATED' &&
      shipment?.subStatus?.key == 'LOADING_IN_PROGRESS',
    OUT_FOR_DELIVERY:
      shipment?.status?.key == 'IN_TRANSIT' && !shipment?.subStatus?.key,
    ARRIVED_AT_DESTINATION: shipment?.status?.key == 'ARRIVED_AT_DESTINATION',
    UNLOADING_COMPLETE:
      shipment.status.key == 'ARRIVED_AT_DESTINATION' &&
      shipment?.subStatus?.key == 'UNLOADING_COMPLETE',
    COMPLETED: shipment.status.key == 'COMPLETED',
    CANCELLED: shipment.status.key == 'CANCELLED'
  };

  const bgStyle = cases.OUT_FOR_DELIVERY
    ? 'bg-[#000] text-[#fff]'
    : cases.ARRIVED_AT_DESTINATION || cases.UNLOADING_COMPLETE
    ? 'border-jade700 text-jade700 border'
    : cases.COMPLETED
    ? 'bg-jade700 border-jade700'
    : cases.CANCELLED
    ? 'bg-[#870000] border-none'
    : 'border-[#000] border';
  const textStyle = cases.OUT_FOR_DELIVERY
    ? 'text-[#fff]'
    : cases.ARRIVED_AT_DESTINATION || cases.UNLOADING_COMPLETE
    ? 'text-jade700 font-medium'
    : cases.COMPLETED
    ? 'text-[#fff] font-medium'
    : cases.CANCELLED
    ? 'text-[#fff] font-medium'
    : '';

  const updateTrackings = async () => {
    setAPILoader(true);
    try {
      await API.put('shipmentTrackings', [
        { shipmentId: shipment.customId, latitude: lat, longitude: lng }
      ]);
      setLat(null);
      setLng(null);
      setLocationPopup(false);
      await refetch();
    } catch (e) {
      logError(e);
    } finally {
      setAPILoader(false);
    }
  };

  const mode = shipment?.ShipmentLegs?.[0]?.mode;

  return (
    <>
      <div
        className={clsx(
          'mt-4 mb-4 p-4 w-full border border-transparent border-collapse rounded',
          {
            'bg-dark100 border-dark400':
              cases.AWAITING_CARRIER ||
              cases.CARRIER_ASSIGNED ||
              cases.LOADING_IN_PROGRESS ||
              cases.OUT_FOR_DELIVERY,
            'bg-jade100':
              cases.COMPLETED ||
              cases.ARRIVED_AT_DESTINATION ||
              cases.UNLOADING_COMPLETE,
            'bg-crimson100': cases.CANCELLED
          }
        )}
      >
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <img
              src={
                mode == 'LAND'
                  ? cases.ARRIVED_AT_DESTINATION || cases.UNLOADING_COMPLETE
                    ? GreenTruckIcon
                    : cases.CANCELLED
                    ? RedTruckIcon
                    : cases.COMPLETED
                    ? DarkTruckIcon
                    : BlueTruckIcon
                  : mode == 'SEA'
                  ? cases.ARRIVED_AT_DESTINATION || cases.UNLOADING_COMPLETE
                    ? GreenShipIcon
                    : cases.CANCELLED
                    ? RedShipIcon
                    : cases.COMPLETED
                    ? DarkShipIcon
                    : ShipIcon
                  : cases.ARRIVED_AT_DESTINATION || cases.UNLOADING_COMPLETE
                  ? GreenAeroPlaneIcon
                  : cases.CANCELLED
                  ? RedAeroplaneIcon
                  : cases.COMPLETED
                  ? DarkAeroplaneIcon
                  : AirplaneIcon
              }
              className="w-4"
            />
            <p className="flex gap-2 justify-center items-center">
              <span className="text-xs text-primaryBlue flex-shrink-0 text-right">
                {order.pickupNode?.name || '-'}
              </span>
              {order.dropOffNode?.name && (
                <img
                  src={RightArrowIcon}
                  alt="arrow"
                  className="flex-shrink-0 w-[20px]"
                />
              )}
              <span className="text-xs text-primaryBlue flex-shrink-0 text-left">
                {order.dropOffNode?.name}
              </span>
            </p>
            <div className={`${bgStyle} px-2 py-1 flex items-center rounded`}>
              {cases.AWAITING_CARRIER ? (
                <span className={`text-xs ${textStyle}`}>
                  Awaiting Carrier Details
                </span>
              ) : cases.CARRIER_ASSIGNED ? (
                <span className={`text-xs ${textStyle}`}>Carrier Assigned</span>
              ) : cases.OUT_FOR_DELIVERY ? (
                <span className={`text-xs ${textStyle}`}>Out For Delivery</span>
              ) : cases.LOADING_IN_PROGRESS ? (
                <span className={`text-xs ${textStyle}`}>Loading</span>
              ) : cases.ARRIVED_AT_DESTINATION ? (
                <span className={`text-xs ${textStyle}`}>Reached</span>
              ) : cases.UNLOADING_COMPLETE ? (
                <span className={`text-xs ${textStyle}`}>Unloaded</span>
              ) : cases.CANCELLED ? (
                <span className={`text-xs ${textStyle}`}>Cancelled</span>
              ) : (
                <span className={`text-xs ${textStyle}`}>Completed</span>
              )}
            </div>
            {shipment?.shipmentTracking?.currentEvent && (
              <div
                className={`flex items-center justify-center py-1 px-[6px] border rounded-[5px] ${bgStyle}`}
              >
                <span className={`text-[10px] ${textStyle} font-medium`}>
                  {shipment?.shipmentTracking?.currentEvent}
                </span>
              </div>
            )}
          </div>
          <div>
            <ActionBtns
              shipment={shipment}
              order={order}
              refetch={refetch}
              onAssignCarrier={() => {
                setOpen(true);
                setAction('assign');
              }}
            />
          </div>
        </div>
        <div className="w-full h-4 border-b border-dashed mt-3 mb-3 border-[#9DA3B3]"></div>
        <div className="flex justify-between">
          <div className="flex gap-2">
            <div className="flex gap-2 items-center">
              <img src={CalendarIcon} />
              <span className="text-xs">
                {shipment.pickupDateTime
                  ? moment(shipment?.pickupDateTime).format('D MMMM YYYY')
                  : 'NA'}
              </span>
              <span>-</span>
              <span className="text-xs">
                {shipment.dropoffDateTime
                  ? moment(shipment?.dropoffDateTime).format('D MMMM YYYY')
                  : 'NA'}
              </span>
            </div>
            <div className="flex items-center gap-2 ml-4">
              <img
                src={
                  shipment.ShipmentLegs?.[0]?.mode == 'LAND'
                    ? ShipmentTab
                    : shipment.ShipmentLegs?.[0]?.mode == 'SEA'
                    ? DarkShipIcon
                    : DarkAeroplaneIcon
                }
                className="w-4"
              />
              <span className="text-xs">
                {shipment.ShipmentLegs?.[0]?.Company?.name || '-'}
              </span>
            </div>
            <div className="flex items-center gap-2 ml-4">
              <span className="text-xs">
                {shipment.ShipmentLegs?.[0]?.mode == 'LAND'
                  ? !isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                    ? shipment.ShipmentLegs?.[0]?.Vehicle?.registrationNumber ||
                      '-'
                    : shipment.ShipmentLegs?.[0]?.vehicleNumber || '-'
                  : shipment.ShipmentLegs?.[0]?.mblNumber ||
                    shipment.ShipmentLegs?.[0]?.hblNumber ||
                    '-'}
              </span>
            </div>
          </div>
          <div className="flex gap-4">
            {(shipment.ShipmentLegs?.[0]?.Vehicle?.trackingLink ||
              shipment.trackingLink) &&
              shipment?.status?.key !== 'COMPLETED' && (
                <div>
                  <span className="text-primary text-xs">
                    <a
                      href={
                        shipment.ShipmentLegs?.[0]?.Vehicle?.trackingLink ||
                        shipment.trackingLink
                      }
                      target="_blank"
                    >
                      View Tracking
                    </a>
                  </span>
                </div>
              )}
            <div className="flex gap-4">
              <span
                className="text-primary text-xs cursor-pointer mt-[6px]"
                onClick={() => {
                  setOpen(true);
                  setAction('assign');
                }}
              >
                {shipment?.ShipmentLegs?.[0]?.carrierId
                  ? 'Carrier Details'
                  : 'Add Carrier'}
              </span>

              {shipment?.shipmentTracking?.id && (
                <span
                  className="text-xs cursor-pointer"
                  onClick={() => setLocationPopup(true)}
                >
                  Update location
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      <Popup
        open={locationPopup}
        setOpen={setLocationPopup}
        title="Update Location"
        content={
          <LatLngForm lat={lat} lng={lng} setLat={setLat} setLng={setLng} />
        }
        actions={
          <div className="flex gap-4">
            <Button
              label="Cancel"
              onClick={() => {
                setLat(null);
                setLng(null);
                setLocationPopup(false);
              }}
            />
            <Button
              label="Update"
              onClick={async () => {
                await updateTrackings();
              }}
              disabled={!lat || !lng}
              variant="primary"
            />
          </div>
        }
      />

      {open && (
        <AddShipmentTMS
          shipment={shipment}
          open={open}
          setOpen={setOpen}
          selectedOrder={order}
          edit={true}
          refetch={refetch}
          action={action}
          onClose={() => setAction('edit')}
          editable={
            !(
              (shipment?.status?.key == 'IN_TRANSIT' &&
                shipment.subStatus?.key == 'ARRIVED_AT_DESTINATION') ||
              (shipment?.status?.key == 'IN_TRANSIT' &&
                shipment.subStatus?.key == 'UNLOADING_COMPLETE') ||
              shipment?.status?.key == 'COMPLETED' ||
              shipment?.status?.key == 'CANCELLED'
            )
          }
        />
      )}
    </>
  );
};
