/*eslint-disable*/
import React, { useEffect, useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import API from '../../libs/axios';
import { SharedContext } from '../../utils/common';
import { useNavigate, useParams } from 'react-router';
import GrnProductsTable from '../orders/grn/GrnProductsTable';
import FixedLayout from '../../core-components/molecules/FixedLayout';
import { Modal, Tabs, Tab } from '@mui/material';
import clsx from 'clsx';
import ProgressDetails from '../orders/grn/ProgressDetails';
import { onError } from '../../libs/errorLib';
import GdnProductsTable from '../orders/gdn/GdnProductsTable';
import BackArrowIcon from '../../assets/icons/chevronLeft.svg';
import UserProfilePic from '../../core-components/molecules/UserProfilePic';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';

const ViewReversedGRNGDN = () => {
  const { oid, sid } = useParams();
  const { isFeatureEnabled } = useFeatureFlags();
  const [initialValues, setInitialValues] = useState({});
  const [activeEntry, setActiveEntry] = useState(null);
  const [reversedEntries, setReversedEntries] = useState([]);
  const [GRNview, setGRNview] = useState(false);
  const [moveType, setMoveType] = useState('');
  const { setAPILoader } = useContext(SharedContext);
  const navigate = useNavigate();
  const [vehicleType, setVehicleType] = useState(null);
  const [cars, setCars] = useState([]);
  const [reversed, setReversed] = useState(false);
  const [totalOrderedQuantity, setTotalOrderedQuantity] = useState(0);
  const [totalReceivedQuantity, setTotalReceivedQuantity] = useState(0);
  const [totalDispatchedQuantity, setTotalDispatchedQuantity] = useState(0);

  const [totalOrderedSecQuantity, setTotalOrderedSecQuantity] = useState(0);
  const [totalReceivedSecQuantity, setTotalReceivedSecQuantity] = useState(0);
  const [totalDispatchedSecQuantity, setTotalDispatchedSecQuantity] =
    useState(0);

  const [value, setValue] = useState(0);
  const handleChange = (e, newValue) => {
    setValue(newValue);
    setActiveEntry(reversedEntries[newValue]);
  };

  const getOrder = async () => {
    try {
      setAPILoader(true);
      let shipment;
      shipment = await API.get(`orders/${oid}/shipments/${sid}`);
      let reversalEntries = [
        ...shipment?.data?.GRNs?.filter((g) => g.reversedAt).map((g, id) => ({
          ...g,
          type: 'GRN',
          index: id
        })),
        ...shipment?.data?.GDNs?.filter((g) => g.reversedAt).map((g, id) => ({
          ...g,
          type: 'GDN',
          index: id
        }))
      ];
      if (reversalEntries.length > 0) {
        reversalEntries.sort((a, b) => a.reversedAt > b.reversedAt);
        setReversedEntries(reversalEntries);
        setActiveEntry(reversalEntries[0]);
      }
    } catch (err) {
      // onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getCars = async () => {
    try {
      const vehicles = await API.get(`vehicles/types`);
      setCars(
        vehicles?.data
          ? vehicles?.data.filter(
              (vehicleType) => vehicleType.isActive === true
            )
          : []
      );
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const initObjInGRNsCase = async (order, files, initData) => {
    if (order?.draftObject?.isDraft) {
      initData.Order = order?.draftObject?.Order;
      initData.Order.GRNId = order?.id;
      initData.shipment = order?.draftObject?.Order?.Shipments[0];
      initData.images = files;
    } else {
      initData.Order = {
        ...order?.Shipment?.Order,
        memo: order?.memo,
        GRNId: order?.id,
        // eslint-disable-next-line no-unused-expressions,no-unsafe-optional-chaining
        products: [...order?.GRNGroup],
        supportingDocuments: order?.supportingDocuments || [],
        vehicleTypeId: order?.vehicleTypeId,
        vehicleType: order?.vehicleType,
        driverName: order?.driverName,
        driverPhone: order?.driverPhone,
        vehicleNumber: order?.vehicleNumber,
        activityDate: order?.activityDate,
        user: order?.User
      };
      initData.shipment = {
        ...order?.Shipment,
        supportingDocuments: order?.supportingDocuments || [],
        vehicleTypeId: order?.vehicleTypeId,
        vehicleType: order?.vehicleType,
        driverName: order?.driverName,
        driverPhone: order?.driverPhone,
        vehicleNumber: order?.vehicleNumber
      };
      initData.images = files;
      initData.reversalReason = order?.reversalReason;
      setMoveType(order.Shipment.Order.moveType);
      getVehicleType(order.companyId, order.id);
    }

    if (order.reversedAt) {
      setGRNview(true);
      setReversed(true);
    }

    const showGRNView = ['COMPLETED'].includes(initData?.shipment?.status?.key);
    if (showGRNView) {
      setGRNview(true);
    }
    // setInitialValues(initData);
    await formik.setValues(initData);
  };

  const initObjInGDNsCase = async (order, files, initData) => {
    if (order?.draftObject?.isDraft) {
      initData = order?.draftObject;
      initData.Order.GRNId = order?.id;
      initData.images = files;
    } else {
      initData = {
        Order: {
          ...order?.GDNData?.Shipment.Order,
          GDNId: order?.GDNData?.id,
          memo: order?.GDNData?.memo,
          products: [...order?.GDNData?.GDNGroup],
          // eslint-disable-next-line no-unused-expressions
          supportingDocuments: order?.GDNData?.supportingDocuments,
          driverName: order?.GDNData?.driverName,
          driverPhone: order?.GDNData?.driverPhone,
          vehicleNumber: order?.GDNData?.vehicleNumber,
          sealNumber: order?.GDNData?.sealNumber,
          activityDate: order?.GDNData?.activityDate,
          user: order?.GDNData?.User
        },
        shipment: {
          ...order?.GDNData?.Shipment,
          GDNId: order?.GDNData?.id,
          supportingDocuments: order?.GDNData?.supportingDocuments,
          driverName: order?.GDNData?.driverName,
          driverPhone: order?.GDNData?.driverPhone,
          vehicleNumber: order?.GDNData?.vehicleNumber,
          sealNumber: order?.GDNData?.sealNumber
        },
        reversalReason: order.GDNData?.reversalReason
      };

      initData.images = files;
      setMoveType(order?.GDNData?.Shipment.Order.moveType);
      getVehicleType(
        order?.GDNData?.Shipment.Order?.companyId,
        order?.GDNData?.Shipment.Order.id
      );
    }

    const showGRNView = ['COMPLETED'].includes(initData?.shipment?.status?.key);
    if (showGRNView) {
      setGRNview(true);
    }
    await formik.setValues(initData);
  };

  useEffect(() => {
    if (oid) {
      getOrder();
      getCars();
    }
  }, [oid]);

  const getVehicleType = async (companyId, orderId) => {
    if (!companyId || !orderId) return;
    try {
      setAPILoader(true);
      const order = await API.get(`orders/order/${oid}`);
      setVehicleType(order.data.vehicleType);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true
  });

  const fetch = async () => {
    if (activeEntry?.type == 'GRN') {
      const order = await API.get(
        `warehouses/shipments/${sid}/grns?id=${activeEntry.id}`,
        { params: { allowReversed: true } }
      );
      initObjInGRNsCase(order?.data?.GRNData, order?.data?.files, {});
    } else if (activeEntry?.type == 'GDN') {
      const order = await API.get(
        `warehouses/shipments/${sid}/gdns?id=${activeEntry.id}`,
        { params: { allowReversed: true } }
      );
      initObjInGDNsCase(order?.data, order?.data?.files, {});
    }
  };

  useEffect(() => {
    if (activeEntry) fetch();
  }, [activeEntry]);

  useEffect(() => {
    formik.setFieldValue('files', formik.values?.images);
  }, [formik.values?.images]);

  const tabSelectedClass =
    'border-b-[4px] border-solid border-t-0 border-r-0 border-l-0 border-primary text-primary';
  const tabClass = 'font-medium text-sm normal-case';

  return (
    <Modal
      sx={{
        minHeight: '100%',
        minWidth: '100%',
        background: 'white',
        border: 'none',
        overflowY: 'auto',
        margin: 0,
        boxShadow: 'none',
        outline: 'none'
      }}
      open
      BackdropProps={{ style: { backgroundColor: 'white' } }}
    >
      <div>
        <FixedLayout
          header={
            <>
              <div className="flex justify-between w-full items-center ml-16">
                <div className="flex">
                  <img
                    src={BackArrowIcon}
                    alt="Back Icon"
                    className="cursor-pointer"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  <p className="font-bold text-2xl">
                    {moveType === 'INWARD' &&
                      isFeatureEnabled(FLAGS.GRN_CREATION) &&
                      'View GRN'}
                    {moveType === 'OUTBOUND' &&
                      isFeatureEnabled(FLAGS.GDN_CREATION) &&
                      'View GDN'}
                    {moveType === 'TRANSFER' &&
                      (isFeatureEnabled(FLAGS.GRN_CREATION) ||
                        isFeatureEnabled(FLAGS.GDN_CREATION)) &&
                      'View Reversed entries'}
                  </p>
                </div>
                <div>
                  <UserProfilePic />
                </div>
              </div>
            </>
          }
          content={
            <div>
              <Grid container>
                {reversedEntries.length > 1 && (
                  <Grid xs={9}>
                    <div className="flex items-center justify-between border-b-[1px] border-solid border-t-0 border-r-0 border-l-0 border-[#C1D6EC] ml-24 mb-10">
                      <Tabs value={value} onChange={handleChange}>
                        {reversedEntries.length > 0 &&
                          reversedEntries.map((r, id) => (
                            <Tab
                              key={id}
                              label={
                                <div className="flex gap-2">
                                  {r.type == 'GRN'
                                    ? `GRN ${r.index + 1}`
                                    : `GDN ${r.index + 1}`}
                                </div>
                              }
                              className={`${tabClass} && ${
                                value == id && tabSelectedClass
                              }`}
                              active={id == value}
                            />
                          ))}
                      </Tabs>
                    </div>
                  </Grid>
                )}
              </Grid>

              <Grid className="flex h-full">
                <Grid xs={9} className="pr-10">
                  <div className="mx-24 mt-16">
                    {formik?.values?.Order?.products ? (
                      activeEntry.type == 'GRN' ? (
                        <GrnProductsTable
                          formik={formik}
                          GRNview={true}
                          reversed={true}
                          totalOrderedQuantity={totalOrderedQuantity}
                          setTotalOrderedQuantity={setTotalOrderedQuantity}
                          totalReceivedQuantity={totalReceivedQuantity}
                          setTotalReceivedQuantity={setTotalReceivedQuantity}
                          values={formik.values}
                          reverseFlag={true}
                          totalDispatchedQuantity={totalDispatchedQuantity}
                          vehicleType={vehicleType}
                          totalOrderedSecQuantity={totalOrderedSecQuantity}
                          setTotalOrderedSecQuantity={
                            setTotalOrderedSecQuantity
                          }
                          totalDispatchedSecQuantity={
                            totalDispatchedSecQuantity
                          }
                          totalReceivedSecQuantity={totalReceivedSecQuantity}
                          setTotalReceivedSecQuantity={
                            setTotalReceivedSecQuantity
                          }
                        />
                      ) : (
                        <GdnProductsTable
                          formik={formik}
                          GDNview={true}
                          reversed={true}
                          setResetProducts={() => {}}
                          totalOrderedQuantity={totalOrderedQuantity}
                          setTotalOrderedQuantity={setTotalOrderedQuantity}
                          totalDispatchedQuantity={totalDispatchedQuantity}
                          setTotalDispatchedQuantity={
                            setTotalDispatchedQuantity
                          }
                          reverseFlag={true}
                          totalReceivedQuantity={totalReceivedQuantity}
                          vehicleType={vehicleType}
                          totalOrderedSecQuantity={totalOrderedSecQuantity}
                          setTotalOrderedSecQuantity={
                            setTotalOrderedSecQuantity
                          }
                          totalDispatchedSecQuantity={
                            totalDispatchedSecQuantity
                          }
                          totalReceivedSecQuantity={totalReceivedSecQuantity}
                          setTotalReceivedSecQuantity={
                            setTotalReceivedSecQuantity
                          }
                        />
                      )
                    ) : (
                      ''
                    )}

                    <div className="mt-16">
                      <p className="text-xl font-semibold">
                        Additional Information
                      </p>
                      <p className="my-5 break-words">
                        {formik.values.Order?.memo}
                      </p>

                      <Grid
                        container
                        className={clsx(
                          'border-y',
                          'border-dashed',
                          'border-gray-400',
                          GRNview && 'border-b-0',
                          'border-x-0',
                          'py-5'
                        )}
                      >
                        <Grid item xs={4} className="flex flex-col gap-1">
                          <p className="font-semibold">Proof of Document</p>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
                <Grid xs={3}>
                  {activeEntry?.type == 'GRN' ? (
                    <ProgressDetails
                      total={totalOrderedQuantity}
                      occupied={totalReceivedQuantity}
                      dockName={
                        (
                          formik.values.shipment?.dropOffDock ||
                          formik.values.shipment?.WarehouseDock
                        )?.name
                      }
                      products={formik?.values?.Order?.products}
                      formik={formik}
                      type="GRN"
                      view
                    />
                  ) : (
                    <ProgressDetails
                      total={totalOrderedQuantity}
                      occupied={totalDispatchedQuantity}
                      dockName={
                        (
                          formik.values.shipment?.dropOffDock ||
                          formik.values.shipment?.WarehouseDock
                        )?.name
                      }
                      products={formik?.values?.Order?.products}
                      formik={formik}
                      view={true}
                      type="GDN"
                    />
                  )}
                </Grid>
              </Grid>
            </div>
          }
          footer={<div className="flex justify-between w-full"></div>}
        />
      </div>
    </Modal>
  );
};

export default ViewReversedGRNGDN;
