import React, { useRef } from 'react';

import { makeStyles } from '@material-ui/core';
import { ClickAwayListener } from '@mui/base';
import { Paper, Popper } from '@mui/material';

const PopupButton = ({
  content,
  open,
  setOpen,
  btn,
  placement,
  clickAwayCallback
}) => {
  const anchorRef = useRef(null);

  const useStyles = makeStyles(() => ({
    popper: {
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      },
      zIndex: 1300
    },
    paper: {
      pointerEvents: 'auto',
      width: '100%',
      border: 'none',
      borderRadius: '8px',
      marginTop: '2px',
      boxShadow:
        '0px 6.400000095367432px 14.399999618530273px 0px #00000021,0px 1.2000000476837158px 3.5999999046325684px 0px #0000001A',
      overflowY: 'auto',
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      },
      '& input': {
        fontSize: '12px !important'
      }
    }
  }));

  const classes = useStyles();

  return (
    <>
      <div className="flex items-center" ref={anchorRef}>
        {btn}
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement={placement || 'bottom-end'}
        disablePortal
        className={classes.popper}
      >
        <ClickAwayListener
          onClickAway={() => {
            clickAwayCallback && clickAwayCallback();
            setOpen(false);
          }}
        >
          <Paper className={classes.paper} onClick={(e) => e.stopPropagation()}>
            {content}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default PopupButton;
