import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '../../assets/icons/add-icon-light.svg';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import PopupButton from '../../core-components/molecules/PopupButton';
import { List, ListItem } from '@mui/material';
import DownloadIcon from '../../assets/icons/file_download.svg';
import BulkFileAttacher from './ListViews/components/BulkFileAttacher';
import BullkUpload from './ListViews/components/BullkUpload';
import { exportToCSV, SharedContext } from '../../utils/common';
import { toaster } from '../../utils/toaster';
import { onError } from '../../libs/errorLib';
import API from '../../libs/axios';
import { ORGANIZATION_TYPES } from '../../constants';
import AddProductPopup from './ListViews/components/AddProductPopup';
import { checkPermission } from '../../utils/auth';
import { Paragraph, Title } from '../../core-components/atoms/Text';
import { makeStyles, Tooltip } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import RemoveRedEye from '@mui/icons-material/RemoveRedEyeOutlined';
import MoreVert from '@mui/icons-material/MoreVert';
import { BACKDROP } from '../../constants/colors';
import FLAGS from '../../constants/featureFlags';
import useFeatureFlags from '../../hooks/useFeatureFlags';

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: '#0B1940'
  },
  tooltip: {
    backgroundColor: '#0B1940'
  }
}));

function Row({
  row,
  showHighlight,
  setSelectedCompany,
  setAddProductView,
  getCustomers,
  getOrgNodes
}) {
  const navigate = useNavigate();
  const [company, setCompany] = useState(null);
  const [bulkUploadResponse, setBulkUploadResponse] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [bulkUploadOpen, setBulkUploadOpen] = useState(false);
  const [bulkUploadPopup, setBulkUploadPopup] = useState(false);
  const [bulkUploadData, setBulkUploadData] = useState(null);
  const [checkValidations, setCheckValidations] = useState(true);
  const [correctBulkUploadData, setCorrectBulkUploadData] = useState(null);
  const { organizationType, setAPILoader, currentUser } =
    useContext(SharedContext);
  const { isFeatureEnabled } = useFeatureFlags();

  const handleDownloadTemplate = async () => {
    const title = `Template for categories. brands and products`;
    const header = [
      'Product Code',
      'Product Name',
      'Product Type',
      ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
        ? ['Supplier']
        : []),
      'Category',
      'Brand',
      'Barcode',
      'HS Code',
      'Primary UoM',
      'Secondary UoM',
      'Conversion Rate',
      'UoM per Pallet',
      ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
        ? ['Shelf Life (In Days)']
        : []),
      ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
        ? ['Product Life (In Days)']
        : []),
      'Height',
      'Length',
      'Breadth',
      'Volume',
      'Lead Time',
      'Weight',
      'Min Ordered Quantity',
      'Max Ordered Quantity',
      'Stock Handling Type',
      'Re Order Point',
      ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
        ? ['Cost Price']
        : []),
      ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
        ? ['Selling Price']
        : []),
      ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
        ? ['SKU requires Expiry Tracking']
        : []),
      ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
        ? ['MRP Enabled']
        : []),
      'Additional Information'
    ];

    const exportSortedHalls = [
      {
        name: 'COKE ZERO',
        description: 'product of Coco Cola(sample product)',
        productType: 'Raw Material',
        ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
          ? { supplier: 'GT Store' }
          : {}),
        category: 'Drinks',
        brand: 'CocaCola',
        barcode: 'XXXXX XXXX',
        hsCode: 'XXXXX XXXX',
        uom: 'Bottle',
        secondaryUom: 'Can',
        conversionFactor: 2,
        perPallet: '10',
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? { shelfLife: '2' }
          : {}),
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? { productLife: '2' }
          : {}),
        height: '1.4',
        length: '1.4',
        breadth: '1.4',
        dimensionsCBM: '4.2',
        leadTime: '2',
        weight: '2',
        minOrderedQuantity: '10',
        maxOrderedQuantity: '200',
        stockHandlingType: 'FIFO',
        reOrderPoint: '1',
        ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
          ? { costPrice: '10' }
          : {}),
        ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
          ? { sellingPrice: '200' }
          : {}),
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? { batchEnabled: 'FALSE' }
          : {}),
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? { MRPEnabled: 'FALSE' }
          : {}),
        additonalInformation: ''
      },
      {
        name: 'COKE ZERO',
        description: 'product of Coco Cola(sample product)',
        productType: 'Raw Material',
        ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
          ? { supplier: 'GT Store' }
          : {}),
        category: 'Drinks',
        brand: 'CocaCola',
        barcode: 'XXXXX XXXX',
        hsCode: 'XXXXX XXXX',
        uom: 'Bottle',
        secondaryUom: 'Can',
        conversionFactor: 2,
        perPallet: '10',
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? { shelfLife: '2' }
          : {}),
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? { productLife: '2' }
          : {}),
        height: '1.4',
        length: '1.4',
        breadth: '1.4',
        dimensionsCBM: '4.2',
        leadTime: '2',
        weight: '2',
        minOrderedQuantity: '10',
        maxOrderedQuantity: '200',
        stockHandlingType: 'FIFO',
        reOrderPoint: '1',
        ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
          ? { costPrice: '10' }
          : {}),
        ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
          ? { sellingPrice: '200' }
          : {}),
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? { batchEnabled: 'FALSE' }
          : {}),
        ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
          ? { MRPEnabled: 'FALSE' }
          : {}),
        additonalInformation: ''
      }
    ];
    exportToCSV(header, exportSortedHalls, title);
  };

  const bulkUpload = (data) => {
    setBulkUploadData(data);
    setBulkUploadPopup(true);
    setBulkUploadOpen(false);
    setSelectedFile(null);
  };

  const createBulkUpload = async () => {
    if (checkValidations) {
      try {
        setAPILoader(true);
        let response;
        const arr = [];
        bulkUploadData?.map((value) => {
          let data = {
            name: value['Product Code'] ? value['Product Code'] : ' ',
            description: value['Product Name'] ? value['Product Name'] : ' ',
            productType: value['Product Type'] ? value['Product Type'] : ' ',
            ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
              ? { supplier: value['Supplier'] ? value['Supplier'] : ' ' }
              : {}),
            category: value['Category'] ? value['Category'] : ' ',
            brand: value['Brand'] ? value['Brand'] : ' ',
            barcode: value['Barcode'] ? value['Barcode'] : ' ',
            hsCode: value['HS Code'] ? value['HS Code'] : ' ',
            uom: value['Primary UoM'] ? value['Primary UoM'] : ' ',
            secondaryUom: value['Secondary UoM'] ? value['Secondary UoM'] : ' ',
            conversionFactor: value['Conversion Rate']
              ? value['Conversion Rate']
              : ' ',
            perPallet: value['UoM per Pallet'] ? value['UoM per Pallet'] : ' ',
            ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
              ? {
                  shelfLife: value['Shelf Life (In Days)']
                    ? value['Shelf Life (In Days)']
                    : ' '
                }
              : { shelfLife: '' }),
            ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
              ? {
                  productLife: value['Product Life (In Days)']
                    ? value['Product Life (In Days)']
                    : ' '
                }
              : { productLife: '' }),
            height: value['Height'] ? value['Height'] : ' ',
            length: value['Length'] ? value['Length'] : ' ',
            breadth: value['Breadth'] ? value['Breadth'] : ' ',
            dimensionsCBM: value['Volume'] ? value['Volume'] : 0,
            leadTime: value['Lead Time'] ? value['Lead Time'] : ' ',
            weight: value['Weight'] ? value['Weight'] : ' ',
            minOrderedQuantity: value['Min Ordered Quantity']
              ? value['Min Ordered Quantity']
              : ' ',
            maxOrderedQuantity: value['Max Ordered Quantity']
              ? value['Max Ordered Quantity']
              : ' ',
            stockHandlingType: value['Stock Handling Type']
              ? value['Stock Handling Type']
              : ' ',
            reOrderPoint: value['Re Order Point']
              ? value['Re Order Point']
              : ' ',
            ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
              ? { costPrice: value['Cost Price'] ? value['Cost Price'] : ' ' }
              : { costPrice: ' ' }),
            ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
              ? {
                  sellingPrice: value['Selling Price']
                    ? value['Selling Price']
                    : ' '
                }
              : { sellingPrice: ' ' }),
            ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
              ? {
                  batchEnabled: value['SKU requires Expiry Tracking']
                    ? value['SKU requires Expiry Tracking']
                    : ' '
                }
              : { batchEnabled: 'FALSE' }),
            ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
              ? {
                  MRPEnabled: value['MRP Enabled'] ? value['MRP Enabled'] : ' '
                }
              : { batchEnabled: 'FALSE' }),
            additonalInformation: value['Additional Information']
              ? value['Additional Information']
              : ' '
          };
          arr.push(data);
        });
        let url = `products/validate-bulk`;
        if (
          organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
        ) {
          url = `products/validate-bulk?companyId=${company?.id}`;
        }
        response = await API.post(url, { products: arr });
        await setBulkUploadResponse(response);
        if (response?.validationErrors?.length <= 0) {
          await dataBulkUpload(arr);
        } else {
          let newArrData = [];
          let newArr = [];
          response?.validProducts?.map((value) => {
            let data = {
              ['Product Code']: value?.name,
              ['Product Name']: value?.description,
              ['Product Type']: value?.productType,
              ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
                ? { ['Supplier']: value?.supplier }
                : {}),
              ['Category']: value?.category,
              ['Brand']: value?.brand,
              ['Barcode']: value?.barcode,
              ['HS Code']: value?.hsCode,
              ['Primary UoM']: value?.uom,
              ['Secondary UoM']: value?.secondaryUom,
              ['Conversion Rate']: value?.conversionFactor,
              ['UoM per Pallet']: value?.perPallet,
              ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
                ? { ['Shelf Life (In Days)']: value?.shelfLife }
                : {}),
              ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
                ? { ['Product Life (In Days)']: value?.productLife }
                : {}),
              ['Height']: value?.height,
              ['Length']: value?.length,
              ['Breadth']: value?.breadth,
              ['Volume']: value?.dimensionsCBM,
              ['Lead Time']: value?.leadTime,
              ['Weight']: value?.weight,
              ['Min Ordered Quantity']: value?.minOrderedQuantity,
              ['Max Ordered Quantity']: value?.maxOrderedQuantity,
              ['Stock Handling Type']: value?.stockHandlingType,
              ['Re Order Point']: value?.reOrderPoint,
              ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
                ? { ['Cost Price']: value?.costPrice }
                : {}),
              ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
                ? { ['Selling Price']: value?.sellingPrice }
                : {}),
              ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
                ? { ['SKU requires Expiry Tracking']: value?.batchEnabled }
                : {}),
              ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
                ? { ['MRP Enabled']: value?.MRPEnabled }
                : {}),
              ['Additional Information']: value?.additonalInformation
            };
            const newData = {
              name: value?.name ? value?.name : ' ',
              description: value?.description ? value?.description : ' ',
              productType: value?.productType ? value?.productType : ' ',
              ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
                ? { supplier: value?.supplier ? value?.supplier : ' ' }
                : {}),
              category: value?.category ? value?.category : ' ',
              brand: value?.brand ? value?.brand : ' ',
              barcode: value?.barcode ? value?.barcode : ' ',
              hsCode: value?.hsCode ? value?.hsCode : ' ',
              uom: value?.uom ? value?.uom : ' ',
              secondaryUom: value?.secondaryUom ? value?.secondaryUom : ' ',
              conversionFactor: value?.conversionFactor
                ? value?.conversionFactor
                : ' ',
              perPallet: value?.perPallet ? value?.perPallet : ' ',
              ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
                ? { shelfLife: value?.shelfLife ? value?.shelfLife : ' ' }
                : {}),
              ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
                ? { productLife: value?.productLife ? value?.productLife : ' ' }
                : {}),
              height: value?.height ? value?.height : ' ',
              length: value?.length ? value?.length : ' ',
              breadth: value?.breadth ? value?.breadth : ' ',
              dimensionsCBM: value?.dimensionsCBM ? value?.dimensionsCBM : ' ',
              leadTime: value?.leadTime ? value?.leadTime : ' ',
              weight: value?.weight ? value?.weight : ' ',
              minOrderedQuantity: value?.minOrderedQuantity
                ? value?.minOrderedQuantity
                : ' ',
              maxOrderedQuantity: value?.maxOrderedQuantity
                ? value?.maxOrderedQuantity
                : ' ',
              stockHandlingType: value?.stockHandlingType
                ? value?.stockHandlingType
                : ' ',
              reOrderPoint: value?.reOrderPoint ? value?.reOrderPoint : ' ',
              ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
                ? { costPrice: value?.costPrice ? value?.costPrice : ' ' }
                : {}),
              ...(organizationType === ORGANIZATION_TYPES.MANUFACTURER
                ? {
                    sellingPrice: value?.sellingPrice
                      ? value?.sellingPrice
                      : ' '
                  }
                : {}),
              ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
                ? {
                    batchEnabled: value?.batchEnabled
                      ? value?.batchEnabled
                      : ' '
                  }
                : {}),
              ...(isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
                ? {
                    MRPEnabled: value?.MRPEnabled ? value?.MRPEnabled : ' '
                  }
                : {}),
              additonalInformation: value?.additonalInformation
                ? value?.additonalInformation
                : ' '
            };
            newArr.push(data);
            newArrData.push(newData);
          });
          setBulkUploadData(newArr);
          setCorrectBulkUploadData(newArrData);
          setCheckValidations(false);
        }
      } catch (error) {
        onError(error.message);
      } finally {
        setAPILoader(false);
      }
    } else {
      await dataBulkUpload(correctBulkUploadData);
    }
  };

  const dataBulkUpload = async (newArrData) => {
    setAPILoader(true);
    let uploadUrl = `products/bulk`;
    if (organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER) {
      uploadUrl = `products/bulk?companyId=${company?.id}`;
    }
    const res = await API.post(uploadUrl, {
      products: newArrData
    });
    if (res) {
      setBulkUploadResponse(null);
      setBulkUploadData(null);
      setBulkUploadPopup(false);
      setCheckValidations(true);
      setCorrectBulkUploadData(null);
      await getCustomers();
      await getOrgNodes();
      toaster('success', `Bulk upload created successfully.`);
    }
    setAPILoader(false);
  };
  const toolTipClasses = useStylesBootstrap();

  return (
    <>
      <BullkUpload
        bulkUploadResponse={bulkUploadResponse}
        company={company}
        data={bulkUploadData}
        setBulkUploadResponse={setBulkUploadResponse}
        setCheckValidations={setCheckValidations}
        bulkUploadPopup={bulkUploadPopup}
        setBulkUploadPopup={setBulkUploadPopup}
        createBulkUpload={createBulkUpload}
      />
      <TableRow onClick={() => {}} className="group hover:cursor-pointer">
        <TableCell style={{ width: '12%', padding: 0, textAlign: 'left' }}>
          <div className="pl-[24px] h-full min-w-[248px] py-4 text-dark800 font-medium text-sm">
            <Title
              className={`${showHighlight && 'bg-highlight'}`}
              variant={'md'}
            >
              {row.name}
            </Title>
            <Paragraph variant={'sm'} className="mt-1">
              Code: {row.internalIdForBusiness || '-'}
            </Paragraph>
          </div>
        </TableCell>
        <TableCell style={{ width: '15%', padding: 0, textAlign: 'left' }}>
          <div className="h-full text-xs py-4 text-dark800 font-normal">
            <Paragraph
              variant={'sm'}
              className="truncate w-[180px] inline-block"
            >
              {row?.brands?.length > 0
                ? row?.brands?.map((brand) => brand.name).join(', ')
                : '-'}
            </Paragraph>
            {row?.brandsCount > 3 ? (
              <div className="text-xs font-medium text-dark800">
                +{row?.brandsCount - 3} More
              </div>
            ) : (
              ''
            )}
          </div>
        </TableCell>
        <TableCell style={{ width: '15%', padding: 0 }}>
          <div className="h-full text-xs py-4 text-dark800 font-normal">
            <Paragraph
              variant={'sm'}
              className="truncate w-[180px] inline-block"
            >
              {row?.products?.length > 0
                ? row?.products
                    ?.map((product) => product.description)
                    .join(', ')
                : '-'}
            </Paragraph>
            {row?.productsCount > 2 ? (
              <div className="text-xs font-medium text-dark800">
                +{row?.productsCount - 2} More
              </div>
            ) : (
              ''
            )}
          </div>
        </TableCell>
        <TableCell style={{ width: '20%', padding: 0, paddingRight: '10px' }}>
          <div className="h-full py-4">
            <div className="flex gap-3 items-center justify-end">
              <div className="invisible group-hover:visible">
                <Tooltip
                  arrow
                  title="View Entity"
                  aria-label="add"
                  placement="top"
                  classes={toolTipClasses}
                >
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/administration/products/${row.id}`);
                    }}
                  >
                    <RemoveRedEye style={{ color: BACKDROP }} />
                  </IconButton>
                </Tooltip>
              </div>
              {(checkPermission(currentUser, 'OPS_PRODUCT_BULK_UPLOAD') ||
                checkPermission(currentUser, 'OPS_PRODUCT_CREATE')) && (
                <PopupButton
                  open={bulkUploadOpen}
                  setOpen={setBulkUploadOpen}
                  btn={
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        setBulkUploadOpen(!bulkUploadOpen);
                      }}
                    >
                      <MoreVert style={{ color: BACKDROP }} />
                    </IconButton>
                  }
                  content={
                    <List className="py-4">
                      {checkPermission(currentUser, 'OPS_PRODUCT_CREATE') && (
                        <ListItem
                          className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                          onClick={async (e) => {
                            e.preventDefault();
                            setSelectedCompany(row);
                            setAddProductView(true);
                            setBulkUploadOpen(false);
                          }}
                        >
                          <img src={AddIcon} className={`mr-[2px]`} />
                          <span>Add a Product</span>
                        </ListItem>
                      )}
                      {checkPermission(
                        currentUser,
                        'OPS_PRODUCT_BULK_UPLOAD'
                      ) && (
                        <>
                          <ListItem
                            className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer"
                            onClick={async (e) => {
                              e.preventDefault();
                              await handleDownloadTemplate();
                              setCompany(row);
                              setBulkUploadOpen(false);
                            }}
                          >
                            <img src={DownloadIcon} className={`mr-[2px]`} />
                            <span>Download Template</span>
                          </ListItem>
                          <BulkFileAttacher
                            bulkUpload={(data) => {
                              bulkUpload(data);
                              setCompany(row);
                            }}
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                          />
                        </>
                      )}
                    </List>
                  }
                />
              )}
            </div>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
}

const ExpandableTable = ({
  // staticRow,
  rows,
  // page,
  showHighlight,
  setSelectedCompany,
  selectedCompany,
  getCustomers,
  getOrgNodes
}) => {
  const [addProductView, setAddProductView] = useState(false);
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: '16px',
        overflow: 'hidden',
        border: '1px solid #E7E6E6 !important',
        '& .MuiTable-root': {
          borderCollapse: 'unset'
        }
      }}
    >
      {selectedCompany && (
        <AddProductPopup
          open={addProductView}
          setOpen={setAddProductView}
          company={selectedCompany}
          companyName={selectedCompany?.name}
        />
      )}
      <Table>
        <TableHead>
          <TableRow className="h-[46px]">
            <TableCell className="text-xs py-2 pl-[24px] min-w-[171px] font-medium text-stormGray">
              ENTITY
            </TableCell>
            <TableCell className="text-xs text-stormGray font-medium p-0 py-2">
              BRAND(s)
            </TableCell>
            <TableCell className="text-xs text-stormGray font-medium p-0 py-2">
              PRODUCT(s)
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {page == 1 && Object.keys(staticRow).length > 0 && (
            <Row row={staticRow} tableRef={ref} />
          )} */}
          {rows.map((row) => {
            let entity = {
              name: row?.name,
              paymentTerms: row.paymentTerms,
              creditLimit: row.creditLimit,
              ntn: row?.ntn,
              billingAddress: row?.billingAddress,
              numberOfNodes: row.Nodes?.length || 0,
              nodes: row?.Nodes || [],
              code: row?.code,
              id: row?.id,
              brands: row?.Brands,
              products: row?.Products,
              internalIdForBusiness: row?.internalIdForBusiness,
              brandsCount: row?.brandsCount,
              productsCount: row?.productsCount
            };
            return (
              <Row
                row={entity}
                key={row?.id}
                showHighlight={showHighlight}
                setAddProductView={setAddProductView}
                setSelectedCompany={setSelectedCompany}
                getCustomers={getCustomers}
                getOrgNodes={getOrgNodes}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ExpandableTable;
