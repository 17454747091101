/*eslint-disable*/
import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { SupportingDocumentUpload, formatFileName } from './AddOrder';
import ProductTable from './ProductsTable';
import ProductTableMobile from './ProductsTableMobile';
import { FormControl, Grid, Popover } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as XLSX from 'xlsx';
import * as csv2json from 'csvtojson';
import { SharedContext } from '../../utils/common';
import CancelIcon from '../../assets/icons/cancelIcon.svg';
import UploadDocIcon from '../../assets/icons/uploadDocument.svg';
import DownloadIcon from '../../assets/icons/downloadIconNew.svg';
import UploadIcon from '../../assets/icons/uploads.svg';
import Autocomplete from '../../core-components/atoms/Autocomplete';
import TextField from '../../core-components/atoms/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useParams } from 'react-router';
import { PRIMARY } from '../../constants/colors';
import { OrderTypeRowText, OrderTypeRowTextMobile } from './constants';
import Button from '../../core-components/atoms/Button';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import clsx from 'clsx';
import { ORGANIZATION_TYPES } from '../../constants';
import orderBulkProductTemplate from '../../assets/files/OrderProductsBulkTemplate.xlsx';
import OrderProductsBulkTemplateSales from '../../assets/files/OrderProductsBulkTemplateSales.xlsx';
import OrderProductsBulkTemplatePurchase from '../../assets/files/OrderProductsBulkTemplatePurchase.xlsx';
import { checkPermission } from '../../utils/auth';
import CreateSupplier from '../supplyChainNetwork/AddEntities/CreateSupplier';
import CreateCustomer from '../supplyChainNetwork/AddEntities/CreateCustomer';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';

const {
  INBOUND_ORDER_CREATION,
  OUTBOUND_ORDER_CREATION,
  TRANSFER_ORDER_CREATION,
  LOGISTICS_ORDER_CREATION,
  PURCHASE_ORDER_CREATION,
  SALES_ORDER_CREATION,
  SALES_RETURN_ORDER_CREATION,
  CREATING_CUSTOMER_MASTER
} = FLAGS;

const OrderFormBody = ({
  formik,
  companies,
  internalNodes,
  externalNodes,
  secondaryInternalNodes,
  secondaryExternalNodes,
  setProductSearchKeyword,
  products,
  _getProducts,
  setAddNodeView,
  getCompanies,
  organizationType,
  salesOrders,
  setSelectedReferenceOrder,
  inventoryLoading,
  moveType,
  setCompanyType,
  nodeFormik,
  getSalesOrders,
  // getSalesPurchaseOrders,
  paymentTerms,
  getUsers,
  getPaymentTerms,
  getExternalNodes,
  getInternalNodes,
  getSecondaryInternalNodes,
  getSecondaryExternalNodes,
  warehouseId,
  setPrefillLastOrderDetails
}) => {
  const { oid } = useParams();
  const { isFeatureEnabled } = useFeatureFlags();
  const [csvProds, setCsvProds] = useState([]);
  const inputRef = useRef(null);
  const [openCompanyPopup, setOpenCompanyPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { setAPILoader, currentUser } = useContext(SharedContext);
  const mainRef = useRef(null);

  const useStyles = makeStyles(() => ({
    popOver: {
      maxHeight: '350px',
      '& ::-webkit-scrollbar': {
        width: '0.3rem'
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#9DA0A6',
        height: '4px'
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#9DA0A6'
      },
      pointerEvents: 'none'
    },
    paper: {
      pointerEvents: 'auto',
      border: '1px solid #E7E6E6',
      marginTop: '12px',
      marginRight: '16px'
    },
    subMenuPaper: {
      pointerEvents: 'auto',
      border: '1px solid #E7E6E6',
      height: '300px'
    }
  }));

  const classes = useStyles();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(mainRef.current);
    setOpenCompanyPopup(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenCompanyPopup(false);
  };

  useEffect(() => {
    if (formik.values.orderType?.label === 'Sales Return') getSalesOrders();
    else if (['Sales', 'Purchase'].includes(formik.values.orderType?.label)) {
      getPaymentTerms();
    }
  }, [formik.values.orderType?.label]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      const hasCostPricePermission = checkPermission(
        currentUser,
        'OPS_COST_PRICE_VISIBILITY'
      );
      const hasSellPricePermission = checkPermission(
        currentUser,
        'OPS_SELLING_PRICE_VISIBILITY'
      );
      csv2json
        .csv({
          output: 'json'
        })
        .fromString(data)
        .then((csvRow) => {
          const rows = [];
          csvRow?.forEach((row) => {
            if (!row['SKU'] && !row['Quantity']) return;
            row['sku'] = row['SKU'];
            row['quantity'] = row['Quantity'];
            row['uom'] = row['UoM'];
            if (hasCostPricePermission) {
              row['costPrice'] = row['Purchase Price'];
            }
            if (hasSellPricePermission) {
              row['sellingPrice'] = row['Selling Price'];
            }
            if (hasSellPricePermission || hasCostPricePermission) {
              row['tax'] = row['Tax'];
            }
            delete row['SKU'];
            delete row['Quantity'];
            delete row['Purchase Price'];
            delete row['Selling Price'];
            delete row['Tax'];
            delete row['UoM'];
            rows.push(row);
          });
          setCsvProds(rows);
        });
    };
    reader.readAsBinaryString(file);
    inputRef.current.value = '';
  };

  const getCSVProducts = async (products) => {
    const cuid = formik?.values?.company?.id;
    try {
      setAPILoader(true);
      const res = await API.post(`orders/products/bulk/verify`, {
        products,
        companyId: cuid,
        warehouseId
      });

      if (res?.products?.length) {
        const { products } = res;
        let prodsArr =
          formik.values.products?.length > 0 ? [...formik.values.products] : [];
        products.forEach((newProd) => {
          const index = prodsArr.findIndex((prod) => prod.id === newProd.id);
          if (index > -1) {
            prodsArr[index].quantity =
              parseInt(prodsArr[index].quantity) + parseInt(newProd.quantity);
          } else {
            const productUoms = [
              { ...newProd.product.UOM, conversionFactor: 1, primary: true },
              // eslint-disable-next-line no-unsafe-optional-chaining
              ...newProd.product?.ProductUOMs?.map((u) => ({
                name: u?.UOM?.name,
                conversionFactor: u.conversionFactor,
                id: u.uomId,
                primary: false
              }))
            ];

            const uom = productUoms.find((u) => u.name == newProd.uom) || {
              ...newProd.product.UOM,
              conversionFactor: 1,
              primary: true
            };

            const prod = {
              id: newProd?.id,
              product: newProd.product,
              orderedQuantity: newProd.quantity,
              tax: newProd?.tax,
              costPrice: newProd?.costPrice || 0,
              sellingPrice: newProd?.sellingPrice || 0,
              totalCost: newProd?.totalCost,
              totalRevenue: newProd?.totalRevenue,
              weight: newProd?.weight,
              uom
            };
            prodsArr = [...prodsArr, { ...prod }];
          }
        });
        formik.setFieldValue('products', prodsArr);
        if (formik.values?.products?.length > 0) {
          const weight = prodsArr.reduce(
            (a, b) =>
              +a + (+b?.product?.weight || 0) * (+b?.orderedQuantity || 0),
            0
          );
          formik.setFieldValue(`calculatedWeight`, weight);
        }
      }
    } catch (error) {
      let errors = error?.response?.data;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }
  };
  useEffect(() => {
    if (csvProds?.length > 0) {
      getCSVProducts(csvProds);
    }
  }, [csvProds]);

  const sxProps = {
    '& .MuiInputBase-root': {
      padding: '8px 16px !important',
      fontSize: '14px'
    },
    '& .MuiAutocomplete-input': {
      padding: '0px 4px 0px 6px !important'
    },
    '& input::placeholder': {
      fontSize: '14px'
    },
    '& textarea::placeholder': {
      fontSize: '14px'
    }
  };

  const listBoxProps = {
    fontSize: '14px !important'
  };

  const inputProps = {
    classes: {
      input: 'text-sm',
      textarea: 'text-sm',
      'MuiInputBase-root': 'py-2 px-4 text-sm'
    }
  };

  const rowClasses = clsx(
    'w-full flex flex-col md:flex-row justify-between mb-4 gap-4 lg:gap-0'
  );

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  return (
    <div className="m-auto w-full max-w-[70%] mt-8 px-4 lg:px-8 sm:px-6">
      <p className="text-xl font-bold text-primaryBlue mb-8 text-xl sm:text-2xl md:text-3xl">
        Order Detail
      </p>
      {!(
        organizationType === ORGANIZATION_TYPES.MANUFACTURER &&
        moveType === 'Transfer' &&
        isMobile
      ) && (
        <div className={rowClasses}>
          <div className="flex items-center">
            <p className="font-semibold text-sm text-primaryBlue">
              {isMobile
                ? OrderTypeRowTextMobile[formik.values?.orderType.value]
                : OrderTypeRowText[formik.values?.orderType.value]}
              <span className="text-error ml-2">*</span>
            </p>
          </div>
          <div className="flex w-full max-w-[532px]">
            {!isMobile && (
              <div
                className={clsx(
                  'w-full',
                  organizationType === ORGANIZATION_TYPES.MANUFACTURER &&
                    moveType === 'Transfer'
                    ? 'max-w-[532px]'
                    : 'max-w-[266px]'
                )}
              >
                <Autocomplete
                  options={
                    organizationType ===
                    ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
                      ? [
                          ...(isFeatureEnabled(INBOUND_ORDER_CREATION)
                            ? [{ label: 'Inbound', value: 'Inward' }]
                            : []),
                          ...(isFeatureEnabled(OUTBOUND_ORDER_CREATION)
                            ? [{ label: 'Outbound', value: 'Outbound' }]
                            : []),
                          ...(isFeatureEnabled(TRANSFER_ORDER_CREATION)
                            ? [{ label: 'Transfer', value: 'Transfer' }]
                            : []),
                          ...(isFeatureEnabled(LOGISTICS_ORDER_CREATION)
                            ? [{ label: 'Logistic', value: 'Logistic' }]
                            : [])
                        ]
                      : [
                          ...(isFeatureEnabled(PURCHASE_ORDER_CREATION)
                            ? [{ label: 'Purchase', value: 'Purchase' }]
                            : []),
                          ...(isFeatureEnabled(SALES_ORDER_CREATION)
                            ? [{ label: 'Sales', value: 'Sales' }]
                            : []),
                          ...(isFeatureEnabled(TRANSFER_ORDER_CREATION)
                            ? [{ label: 'Transfer', value: 'Transfer' }]
                            : []),
                          ...(isFeatureEnabled(SALES_RETURN_ORDER_CREATION)
                            ? [{ label: 'Sales Return', value: 'Sales Return' }]
                            : [])
                        ]
                  }
                  onChange={async (e, value) => {
                    formik.setFieldValue('orderType', value);
                    getSalesOrders();
                    setSelectedReferenceOrder();
                    await formik.setValues({
                      ...formik.values,
                      pickupNode: {},
                      dropOffNode: {},
                      referenceOrderId: null,
                      referenceID: null,
                      company: {},
                      orderType: value
                    });
                    if (value.label === 'Purchase') {
                      setCompanyType('SUPPLIER');
                    }
                    if (value.label === 'Sales') {
                      setCompanyType('CUSTOMER');
                    }
                  }}
                  value={formik.values?.orderType || {}}
                  placeholder="Order Type"
                  getOptionLabel={(option) => option.label || ''}
                  sxProps={sxProps}
                  customInputProps={inputProps}
                  listBoxProps={listBoxProps}
                />
              </div>
            )}
            {!(
              organizationType === ORGANIZATION_TYPES.MANUFACTURER &&
              moveType === 'Transfer'
            ) && (
              <div
                className={clsx(
                  isMobile ? 'max-w-[532px]' : 'pl-2 max-w-[266px]',
                  'w-full'
                )}
                ref={mainRef}
              >
                <Autocomplete
                  className={`${
                    formik.values?.orderType.value === 'Sales Return'
                      ? 'hidden'
                      : 'block'
                  }`}
                  options={[
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    ...companies?.map((company) => ({
                      id: company.id,
                      name: company.name,
                      PaymentTerm: company.PaymentTerm
                    })),
                    ...(isFeatureEnabled(CREATING_CUSTOMER_MASTER) &&
                    checkPermission(currentUser, 'OPS_COMPANY_CREATE')
                      ? [
                          {
                            type: 'add',
                            label:
                              formik.values?.orderType.value === 'Purchase'
                                ? 'New Supplier'
                                : 'New Customer'
                          }
                        ]
                      : [])
                  ]}
                  renderOption={(props, option) => {
                    // eslint-disable-next-line no-unused-vars
                    const { className, ...rest } = props;
                    return option?.type == 'add' ? (
                      <li
                        className="auto-complete-option"
                        style={{
                          color: PRIMARY,
                          fontSize: '16px',
                          fontWeight: 500
                        }}
                        onClick={handleClick}
                      >
                        + {option.label}
                      </li>
                    ) : (
                      <li {...rest} className="auto-complete-option-small">
                        <span>{option.name}</span>
                      </li>
                    );
                  }}
                  onChange={(e, value) => {
                    formik.setValues({
                      ...formik.values,
                      products: [],
                      pickupNode: {},
                      dropOffNode: {},
                      company: value
                    });
                    // getSalesPurchaseOrders(
                    //   value,
                    //   formik.values.orderType?.label
                    // );
                    setPrefillLastOrderDetails(true);
                    if (
                      ['Sales', 'Purchase'].includes(
                        formik.values.orderType?.value
                      )
                    ) {
                      formik.setFieldValue(
                        'paymentTermTitle',
                        value.PaymentTerm?.title ?? ''
                      );
                      formik.setFieldValue(
                        'paymentTerm',
                        value.PaymentTerm ?? {}
                      );
                    }
                  }}
                  value={formik.values?.company || {}}
                  placeholder={
                    formik.values?.orderType.value !== 'Purchase'
                      ? 'Select Customer'
                      : 'Select Supplier'
                  }
                  getOptionLabel={(option) => option.name || ''}
                  onInputChange={(_, newValue, reason) => {
                    if (reason === 'input') {
                      getCompanies(newValue);
                    }
                  }}
                  sxProps={sxProps}
                  customInputProps={inputProps}
                  listBoxProps={listBoxProps}
                />

                <Autocomplete
                  className={`${
                    formik.values?.orderType.value === 'Sales Return'
                      ? 'block'
                      : 'hidden'
                  }`}
                  options={(salesOrders || []).map((order) => ({
                    customId: order?.customId,
                    id: order?.id
                  }))}
                  onInputChange={(_, newValue, reason) => {
                    if (reason === 'input') {
                      getSalesOrders(newValue);
                    }
                  }}
                  onChange={(e, value) => {
                    if (value) {
                      setSelectedReferenceOrder(value.id);
                    }
                    formik.setValues({
                      ...formik.values,
                      referenceOrderId: value ? value.id : null,
                      referenceOrderCustomId: value ? value.customId : null
                    });
                  }}
                  value={
                    formik.values.referenceOrderId !== null &&
                    formik.values.referenceOrderId !== undefined
                      ? {
                          id: formik.values.referenceOrderId,
                          customId: formik.values.referenceOrderCustomId
                        }
                      : null
                  }
                  placeholder={'Select Sales Order'}
                  getOptionLabel={(option) =>
                    option && option.customId ? option.customId.toString() : ''
                  }
                  filterOptions={(options, { inputValue }) => {
                    if (!inputValue) {
                      return options || [];
                    }
                    return (options || []).filter((option) =>
                      option && option.customId
                        ? option.customId
                            .toString()
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        : false
                    );
                  }}
                  sxProps={sxProps}
                  customInputProps={inputProps}
                  listBoxProps={listBoxProps}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className={rowClasses}>
        <div className="flex items-center">
          <p className="font-semibold text-sm text-primaryBlue">
            Select Origin & Destination
            <span className="text-error ml-2">*</span>
          </p>
        </div>
        <div className="flex w-full max-w-[532px]">
          <div className="w-full max-w-[266px]">
            <FormControl fullWidth={true} variant="outlined">
              <Autocomplete
                options={
                  formik.values.company?.id ||
                  formik.values.orderType?.value === 'Transfer'
                    ? [
                        ...(formik.values.orderType?.value === 'Inward' ||
                        formik.values.orderType?.value === 'Purchase' ||
                        formik.values.orderType?.value === 'Sales Return' ||
                        formik.values.orderType?.value === 'Logistic'
                          ? externalNodes
                          : internalNodes
                        ).filter(
                          (node) =>
                            node.isActive === true &&
                            node.id !== formik.values.dropOffId
                        ),
                        isFeatureEnabled(CREATING_CUSTOMER_MASTER) &&
                          checkPermission(currentUser, 'OPS_NODE_CREATE') && {
                            type: 'add',
                            label: 'New Location'
                          }
                      ].filter((n) => n?.id || n?.label)
                    : []
                }
                renderOption={(props, option) => {
                  // eslint-disable-next-line no-unused-vars
                  const { className, ...rest } = props;
                  return option?.type == 'add' ? (
                    <li
                      className="auto-complete-option"
                      style={{
                        color: PRIMARY,
                        fontSize: '16px',
                        fontWeight: 500
                      }}
                      onClick={() => {
                        if (
                          formik.values.orderType?.value !== 'Inward' &&
                          formik.values.orderType?.value !== 'Purchase' &&
                          formik.values.orderType?.value !== 'Sales Return' &&
                          formik.values.orderType?.value !== 'Logistic'
                        ) {
                          nodeFormik?.setFieldValue('nodeClass', 'INTERNAL');
                        }
                        getUsers();
                        setAddNodeView(true);
                      }}
                    >
                      + {option.label}
                    </li>
                  ) : (
                    <li {...rest} className="auto-complete-option-small">
                      <span>
                        {option.code || option.name ? `${option.name}` : ''}
                      </span>
                      <span style={{ color: '#67718C' }}>
                        {option?.code ? ` • ${option?.code}` : ''}
                      </span>
                    </li>
                  );
                }}
                getOptionLabel={(node) =>
                  node?.label
                    ? node?.label
                    : node.code || node.name
                    ? `${node.name}`
                    : ''
                }
                onChange={(e, val) => {
                  formik.setFieldValue('pickupId', val !== null && val.id);
                  formik.setFieldValue('pickupNode', val !== null && val);
                }}
                value={formik.values.pickupNode}
                isOptionEqualToValue={(option, value) =>
                  option?.value?.id === value?.value?.id
                }
                placeholder="Origin"
                onInputChange={(_, newValue, reason) => {
                  if (reason === 'input') {
                    formik.values.orderType?.value === 'Inward' ||
                    formik.values.orderType?.value === 'Purchase' ||
                    formik.values.orderType?.value === 'Sales Return' ||
                    formik.values.orderType?.value === 'Logistic'
                      ? formik.values.company?.id && getExternalNodes(newValue)
                      : (formik.values.company?.id ||
                          organizationType ===
                            ORGANIZATION_TYPES.MANUFACTURER) &&
                        getInternalNodes(newValue);
                  }
                }}
                disabled={formik.values.orderType?.value === 'Sales Return'}
                clearOnBlur
                sxProps={sxProps}
                customInputProps={inputProps}
                listBoxProps={listBoxProps}
                filterOptions={(options, state) => {
                  return options.filter(
                    (option) =>
                      option.name
                        ?.toLowerCase()
                        ?.includes(state.inputValue?.toLowerCase()) ||
                      option.code
                        ?.toLowerCase()
                        .includes(state.inputValue?.toLowerCase()) ||
                      option.label
                  );
                }}
              />
            </FormControl>
          </div>
          <div className="w-full max-w-[266px] pl-2">
            <FormControl fullWidth={true} variant="outlined">
              <Autocomplete
                options={
                  formik.values.company?.id ||
                  formik.values.orderType?.value === 'Transfer'
                    ? [
                        ...(formik.values.orderType?.value === 'Inward' ||
                        formik.values.orderType?.value === 'Purchase' ||
                        formik.values.orderType?.value === 'Sales Return'
                          ? internalNodes
                          : formik.values.orderType?.value === 'Transfer'
                          ? secondaryInternalNodes
                          : formik.values.orderType?.value === 'Logistic'
                          ? secondaryExternalNodes
                          : externalNodes
                        ).filter(
                          (node) =>
                            node.isActive === true &&
                            node.id !== formik.values.pickupId
                        ),
                        ...(isFeatureEnabled(CREATING_CUSTOMER_MASTER)
                          ? [
                              {
                                type: 'add',
                                label: 'New Location'
                              }
                            ]
                          : [])
                      ].filter((n) => n?.id || n?.label)
                    : []
                }
                renderOption={(props, option) => {
                  // eslint-disable-next-line no-unused-vars
                  const { className, ...rest } = props;
                  return option?.type == 'add' ? (
                    <li
                      className="auto-complete-option"
                      style={{
                        color: PRIMARY,
                        fontSize: '16px',
                        fontWeight: 500
                      }}
                      onClick={() => {
                        if (
                          formik.values.orderType?.value === 'Inward' ||
                          formik.values.orderType?.value === 'Purchase' ||
                          formik.values.orderType?.value === 'Sales Return' ||
                          formik.values.orderType?.value === 'Transfer'
                        ) {
                          nodeFormik?.setFieldValue('nodeClass', 'INTERNAL');
                        }
                        setAddNodeView(true);
                      }}
                    >
                      + {option.label}
                    </li>
                  ) : (
                    <li {...rest} className="auto-complete-option-small">
                      <span>
                        {option.code || option.name ? `${option.name}` : ''}
                      </span>
                      <span style={{ color: '#67718C' }}>
                        {option?.code ? ` • ${option?.code}` : ''}
                      </span>
                    </li>
                  );
                }}
                getOptionLabel={(node) =>
                  node?.label
                    ? node?.label
                    : node.code || node.name
                    ? `${node.name}`
                    : ''
                }
                onChange={(e, val) => {
                  formik.setFieldValue('dropOffId', val !== null && val.id);
                  formik.setFieldValue('dropOffNode', val !== null && val);
                }}
                value={formik.values.dropOffNode}
                isOptionEqualToValue={(option, value) =>
                  option?.value?.id === value?.value?.id
                }
                filterOptions={(options, state) => {
                  return options.filter(
                    (option) =>
                      option.name
                        ?.toLowerCase()
                        ?.includes(state.inputValue?.toLowerCase()) ||
                      option.code
                        ?.toLowerCase()
                        .includes(state.inputValue?.toLowerCase()) ||
                      option?.label
                  );
                }}
                placeholder="Destination"
                onInputChange={(event, newValue, reason) => {
                  if (reason === 'input') {
                    formik.values.orderType?.value === 'Inward' ||
                    formik.values.orderType?.value === 'Purchase' ||
                    formik.values.orderType?.value === 'Sales Return'
                      ? (formik.values.company?.id ||
                          organizationType ===
                            ORGANIZATION_TYPES.MANUFACTURER) &&
                        getInternalNodes(newValue)
                      : formik.values.orderType?.value === 'Transfer'
                      ? getSecondaryInternalNodes(newValue)
                      : formik.values.orderType?.value === 'Logistic'
                      ? getSecondaryExternalNodes(newValue)
                      : formik.values.company?.id && getExternalNodes(newValue);
                  }
                }}
                disabled={formik.values.orderType?.value === 'Sales Return'}
                clearOnBlur
                sxProps={sxProps}
                customInputProps={inputProps}
                listBoxProps={listBoxProps}
              />
            </FormControl>
          </div>
        </div>
      </div>
      <div className={rowClasses}>
        <div className="flex items-center">
          <p className="font-semibold text-sm text-primaryBlue">
            Expected Delivery Date
            <span className="text-error ml-2">*</span>
          </p>
        </div>
        <div className="flex w-full max-w-[532px]">
          <FormControl fullWidth variant="outlined">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                showDaysOutsideCurrentMonth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onBlur={formik.handleBlur}
                    size="small"
                    sxProps={{
                      '& input::placeholder': {
                        fontSize: '14px'
                      },
                      '& input': {
                        fontSize: '14px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        height: '20px'
                      },
                      '& textarea::placeholder': {
                        fontSize: '14px'
                      },
                      ...(formik?.values?.expectedDelivery
                        ? {
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: '1px solid #0B1940 !important'
                            }
                          }
                        : [])
                    }}
                    placeholder="Delivery Date"
                  />
                )}
                inputFormat={'dd/MM/yyyy'}
                value={formik?.values?.expectedDelivery || new Date()}
                placeholder="Delivery Date"
                onChange={(newValue) => {
                  formik.setFieldValue('expectedDelivery', newValue);
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </div>
      </div>
      {['Sales', 'Purchase'].includes(formik.values.orderType?.value) && (
        <div className={rowClasses}>
          <div className="flex items-center">
            <p className="font-semibold text-sm text-primaryBlue">
              Payment Terms
              <span className="text-error ml-2">*</span>
            </p>
          </div>
          <div className="flex w-full max-w-[532px]">
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                options={paymentTerms}
                value={formik.values.paymentTerm}
                formik={formik}
                freeSolo
                name="paymentTerm"
                placeholder="Enter Payment Terms"
                getOptionLabel={(option) => option.title || ''}
                onChange={(_, newValue) => {
                  if (newValue)
                    formik.setFieldValue('paymentTermTitle', newValue.title);
                  formik.setFieldValue('paymentTerm', newValue);
                }}
                sxProps={sxProps}
                onInputChange={(_, newValue, reason) => {
                  formik.setFieldValue('paymentTermTitle', newValue);
                  if (reason === 'input') {
                    getPaymentTerms(newValue);
                  }
                }}
              />
            </FormControl>
          </div>
        </div>
      )}
      <div className={rowClasses}>
        <div className="flex items-center">
          <p className="font-semibold text-sm text-primaryBlue">
            Enter Additional information
          </p>
        </div>
        <div className="flex w-full max-w-[532px]">
          <FormControl fullWidth variant="outlined">
            <TextField
              value={formik.values.additionalInstructions}
              onChange={(e) =>
                formik.setFieldValue('additionalInstructions', e.target.value)
              }
              sxProps={{
                '& input::placeholder': {
                  fontSize: '14px'
                },
                '& textarea::placeholder': {
                  fontSize: '14px'
                }
              }}
              placeholder="Type here"
              multiline
              rows={2}
            />
          </FormControl>
        </div>
      </div>

      <div className={rowClasses}>
        <div className="flex items-center">
          <p className="font-semibold text-sm text-primaryBlue">Reference ID</p>
        </div>
        <div className="flex w-full max-w-[532px]">
          <FormControl fullWidth variant="outlined">
            <TextField
              value={formik.values.referenceID}
              onChange={(e) =>
                formik.setFieldValue('referenceID', e.target.value)
              }
              placeholder="Type here"
              sxProps={{
                '& input::placeholder': {
                  fontSize: '14px'
                },
                '& textarea::placeholder': {
                  fontSize: '14px'
                }
              }}
              size="small"
              disabled={formik.values.orderType?.value === 'Sales Return'}
            />
          </FormControl>
        </div>
      </div>

      {isFeatureEnabled(FLAGS.ADD_ORDER_SUPPORTING_DOCUMENT) && (
        <div className="pt-6 pb-6 w-full flex justify-between">
          <div className="flex flex-col gap-1">
            <p className="font-semibold text-sm text-primaryBlue">
              Upload Proof of Document
            </p>
            <p className="text-xs">File must be pdf, jpeg & png</p>
          </div>
          <div className="flex items-center w-full max-w-[532px] justify-between border border-dashed p-4 border-[#C4C6CB]">
            {!isMobile && formik.values.supportingDocument?.length == 0 ? (
              <p className="flex gap-1 text-sm">
                <img src={UploadDocIcon} className="mr-2" /> Upload a picture or
                document
              </p>
            ) : (
              <div className="grid grid-cols-2 gap-4">
                {formik.values.supportingDocument?.map((doc, id) => (
                  <div
                    key={id}
                    className="rounded-full w-[140px] justify-between text-xs pt-2 pb-2 pl-3 pr-3 border border-solid border-primary flex items-center gap-2 cursor-pointer"
                    onClick={async (e) => {
                      e.stopPropagation();
                      let fileUrl;
                      if (!doc?.location) fileUrl = URL.createObjectURL(doc);
                      else {
                        fileUrl = doc.location;
                      }
                      const a = document.createElement('a');
                      a.href = fileUrl;
                      a.target = '_blank';
                      a.click();
                    }}
                  >
                    <p className="text-primary">{formatFileName(doc)}</p>

                    <div
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (formik.values.supportingDocument.length > 1) {
                          let updatedDocs = [
                            ...formik.values.supportingDocument
                          ];
                          updatedDocs = updatedDocs.filter((v, i) => i != id);
                          formik.setFieldValue(
                            'supportingDocument',
                            updatedDocs
                          );
                        } else {
                          formik.setFieldValue('supportingDocument', []);
                        }
                      }}
                    >
                      <img src={CancelIcon} className="w-2 h-2" />
                    </div>
                  </div>
                ))}
              </div>
            )}
            <SupportingDocumentUpload
              formik={formik}
              propertyName="supportingDocument"
            />
          </div>
        </div>
      )}
      <div className="h-[1px] w-full bg-dark300 my-[35px]"></div>
      <Grid container className="mb-[80px]">
        <div
          className={clsx(
            'flex flex-col md:flex-row justify-between w-full',
            !isMobile && 'mb-10'
          )}
        >
          <div className="font-bold text-xl mb-[10px]">
            Product(s) <span className="text-error">*</span>
          </div>
          {!isMobile && (
            <div className="flex gap-4">
              <a
                href={
                  organizationType === ORGANIZATION_TYPES.MANUFACTURER
                    ? formik.values?.orderType.value !== 'Purchase'
                      ? OrderProductsBulkTemplateSales
                      : OrderProductsBulkTemplatePurchase
                    : orderBulkProductTemplate
                }
                className="h-8 text-xs text-primaryBlue border-black border bg-white hover:bg-tertiary active:bg-tertiary px-3 py-2 flex items-center normal-case border-solid rounded gap-[6px] flex-nowrap w-auto whitespace-nowrap font-medium cursor-pointer"
              >
                <img
                  src={DownloadIcon}
                  className="h-[14px] w-[14px] mr-[6px]"
                />{' '}
                Download Templates
              </a>
              <input
                hidden
                ref={inputRef}
                type="file"
                onChange={(e) => handleFileUpload(e)}
                accept=".xlsx"
              />
              <Button
                label="Upload Products"
                className={'py-2 px-3 ml-2 h-8 rounded border-solid'}
                variant="transparent"
                overrideSize={true}
                icon={
                  <img src={UploadIcon} className="h-[14px] w-[14px] mr-2" />
                }
                labelClass="font-medium text-xs "
                iconClass="h-[14px] w-[14px] mr-[6px]"
                onClick={() => inputRef.current.click()}
                disabled={
                  !formik.values.company?.id &&
                  organizationType ===
                    ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
                }
              />
            </div>
          )}
        </div>
        {isMobile ? (
          <ProductTableMobile
            formik={formik}
            products={products}
            _getProducts={_getProducts}
            setProductSearchKeyword={setProductSearchKeyword}
            inventoryLoading={inventoryLoading}
            update={!!oid}
            warehouseId={warehouseId}
            isLogistic={
              formik.values?.orderType.value == 'LOGISTIC' ||
              formik.values?.orderType.value == 'Logistic'
            }
          />
        ) : (
          <ProductTable
            formik={formik}
            products={products}
            _getProducts={_getProducts}
            setProductSearchKeyword={setProductSearchKeyword}
            inventoryLoading={inventoryLoading}
            update={!!oid}
            warehouseId={warehouseId}
            isLogistic={
              formik.values?.orderType.value == 'LOGISTIC' ||
              formik.values?.orderType.value == 'Logistic'
            }
          />
        )}
        <Popover
          open={openCompanyPopup}
          anchorEl={anchorEl}
          style={{ pointerEvents: 'none' }}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          classes={{
            paper: classes.paper
          }}
          BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        >
          <div>
            {formik.values.orderType.value === 'Purchase' ? (
              <CreateSupplier
                setOpen={setOpenCompanyPopup}
                getCustomer={getCompanies}
                supplierOnly={true}
              />
            ) : (
              <CreateCustomer
                setOpen={setOpenCompanyPopup}
                getCustomer={getCompanies}
                customerOnly={true}
              />
            )}
          </div>
        </Popover>
      </Grid>
    </div>
  );
};

export default OrderFormBody;
