import React from 'react';

export const UploadButton = ({ isBillGenerated, uploadRef, addIcon }) => (
  <div
    onClick={(e) => {
      if (!isBillGenerated) {
        e.preventDefault();
        uploadRef.current.click();
      }
    }}
    className="flex border rounded border-dotted min-h-[30px] min-w-[30px] items-center justify-center"
  >
    <img src={addIcon} alt="add-icon" />
  </div>
);
