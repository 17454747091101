/*eslint-disable*/

import { decimalDisplay, formatNumberToKAndM } from '../../../utils/common';
import StatCard from '../../revampedOrder/StatCard';

export const Stats = ({ stats }) => {
  return (
    <div className="w-full h-[90px] bg-dark200 pl-6 pr-2 py-2 flex shadow-sm items-center">
      <StatCard>
        <div className="mr-6">
          <p className="text-xs text-dark600">TOTAL SHIPMENTS</p>
          <p className="text-lg text-primaryBlue font-semibold">
            {stats?.totalShipments < 1000
              ? decimalDisplay(stats?.totalShipments || 0)
              : formatNumberToKAndM(stats?.totalShipments || 0)}
          </p>
        </div>
      </StatCard>
      <StatCard>
        <div className="mr-6">
          <p className="text-xs text-dark600">ACTIVE SHIPMENTS</p>
          <p className="text-lg text-primaryBlue font-semibold">
            {stats?.activeShipments < 1000
              ? decimalDisplay(stats?.activeShipments || 0)
              : formatNumberToKAndM(stats?.activeShipments || 0)}
          </p>
        </div>
        <div className="mr-6">
          <p className="text-xs text-dark600">COMPLETED SHIPMENTS</p>
          <p className="text-lg text-primaryBlue font-semibold">
            {stats?.completedShipments < 1000
              ? decimalDisplay(stats?.completedShipments || 0)
              : formatNumberToKAndM(stats?.completedShipments || 0)}
          </p>
        </div>
        <div className="mr-6">
          <p className="text-xs text-dark600">CANCELLED SHIPMENTS</p>
          <p className="text-lg text-primaryBlue font-semibold">
            {stats?.cancelledShipments < 1000
              ? decimalDisplay(stats?.cancelledShipments || 0)
              : formatNumberToKAndM(stats?.cancelledShipments || 0)}
          </p>
        </div>
      </StatCard>
    </div>
  );
};
