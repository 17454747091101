import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from '../src/layouts/DashboardLayout';
import MainLayout from '../src/layouts/MainLayout';

import BrandView from '../src/views/administration/brand/BrandView';
import CategoryView from '../src/views/administration/category/CategoryView';
import CityView from '../src/views/administration/country/CityView';
import CountryView from '../src/views/administration/country/CountryView';
import RegionView from '../src/views/administration/country/RegionView';
import CompanyDetails from '../src/views/administration/customer/CompanyDetails';
import CompanyView from '../src/views/administration/customer/CompanyView';
import OrganizationView from '../src/views/administration/organization/OrganizationView';
import ProductView from '../src/views/administration/product/ProductView';
import UoMView from '../src/views/administration/uom/UoMView';
import UserView from '../src/views/administration/user/UserView';
import WarehouseHallView from '../src/views/administration/warehouse/WarehouseHallView';
import LoginView from '../src/views/auth/LoginView';
import NotFoundView from '../src/views/errors/NotFoundView';
import ScriptsDetailsView from '../src/views/scriptRunner/ScriptsDetailView';
import PalletView from './views/administration/pallet/PalletView';

import RoleView from './views/rolesandpermissions/role/RoleView';
import InventoryDetailsView from '../src/views/inventoryRevamped/InventoryDetailsView';
import InventoryView from '../src/views/inventoryRevamped/InventoryView';
import ExportView from '../src/views/reporting/exports/ExportView';
import {
  ADMINISTRATION_ORGANIZATION,
  ADMINISTRATION_VENDOR,
  T_M_ROUTE_MANAGEMENT
} from './constants/features';
import {
  checkPermission,
  isOwareSubdomain,
  isOwareSuperAdmin,
  isSuperAdmin
} from './utils/auth';
import { FeatureFlag } from './utils/featureFlag';
import QRCodeGeneration from './views/QRCode/QRCodeGeneration';
import BulkUpload from './views/administration/product/BulkUpload';
import AddWarehouse from './views/administration/warehouse/AddWarehouse/AddWarehouse';
import UploadAisleView from './views/administration/warehouse/UploadAisleView';
import UploadBayView from './views/administration/warehouse/UploadBayView';
import WarehouseListing from './views/administration/warehouse/WarehouseListing/WarehouseListing';
import ResetPassword from './views/auth/ResetPassword';
import Dashboard from './views/home/dashboard/dashboard';
import AddExpense from './views/invoiceandbilling/expenseManagement/AddExpense';
import ExpenseManagement from './views/invoiceandbilling/expenseManagement/ExpenseManagement';
import InvoiceManagement from './views/invoiceandbilling/invoiceManagement/invoiceManagement';
import AddInvoice from './views/invoiceandbilling/invoices/AddInvoice';
import Settings from './views/invoiceandbilling/settings/Settings';
import DriverView from './views/logisticsRevamped/driver/driver';
import VehicleView from './views/logisticsRevamped/vehicle/VehicleView';
import VehicleTypeView from './views/logisticsRevamped/vehicleType/vehicleTypeView';
import CustomerOnboarding from './views/onexus/CustomerOnboarding/CustomerOnboarding';
import OnexusListing from './views/onexus/Listing/OnexusListing';
import OnexusDetailPage from './views/onexus/OnexusDetailPage';
import WarehouseBookingList from './views/onexus/warehouseBooking/WarehouseBookingList';
import AddPalletizeStock from './views/operations/palletizeStock/AddPalletizeStock';
import DetailPalletizeStock from './views/operations/palletizeStock/DetailPalletizeStock';
import PalletizeStockView from './views/operations/palletizeStock/PalletizeStockView';
import AddStockManagement from './views/operations/stockmanagement/AddStockManagement';
import StockManagementView from './views/operations/stockmanagement/StockManagementView';
import ViewStockManagementDetails from './views/operations/stockmanagement/ViewStockManagementDetails';
import AddBulkOrder from './views/orders/AddBulkOrder';
import NodeBulkUpload from './views/orders/NodeBulkUpload';
import AddGdnView from './views/orders/gdn/AddGdnView';
import AddGrnView from './views/orders/grn/AddGrnView';
import ProfileSettingsView from './views/profile/ProfileSettingsView';
import EmbedReport from './views/reporting/embeddedReports/EmbeddedReportView';
import ShowEmbedReport from './views/reporting/embeddedReports/ShowEmbedReport';
import LedgerDetailsView from './views/reporting/ledgerReports/LedgerDetailsView';
import AddViewNotificationUsers from './views/reporting/notificationconsole/AddViewNotificationUsers';
import NotificationConsoleView from './views/reporting/notificationconsole/NotificationConsoleView.js';
import AddOrder from './views/revampedOrder/AddOrder';
import OrderDetails from './views/revampedOrder/OrderDetails';
import OrderManagement from './views/revampedOrder/OrderManagement';
import ViewReversedGRNGDN from './views/revampedOrder/ViewReversedGrnGdn';
import AddRoute from './views/route/AddRoute';
import RouteManagement from './views/route/RouteManagement';
import AddCustomer from './views/supplyChainNetwork/AddCustomer';
import AddEntityNode from './views/supplyChainNetwork/AddEntityNode';
import AddNode from './views/supplyChainNetwork/AddNode';
import AddSupplier from './views/supplyChainNetwork/AddSupplier';
import SupplyChainNetwork from './views/supplyChainNetwork/SupplyChainNetwork';
import ProductMaster from './views/productMaster/ProductMaster';
import WarehouseNodeView from './views/supplyChainNetwork/WarehouseNodeView';
import ViewPickingDetails from './views/tasks/PickingDetail/ViewPickingDetails';
import ViewPutawayDetails from './views/tasks/PickingDetail/ViewPickingDetails';
import AddCycleCountView from './views/tasks/createCycleCountView';
import ViewCycleCountDetails from './views/tasks/cycleCountDetailView';
import TaskManagementView from './views/tasks/TaskManagment/index';
import UnifiedDashboardView from './views/unifiedDashboard/DashboardView';
import ProductsView from './views/productMaster/ProductsView';
import ViewCustomer from './views/supplyChainNetwork/ViewEntities/ViewCustomer';
import AddCustomPicking from './views/tasks/createCustomPicking.js';
import AccessScreen from './views/home/dashboard/access/access.js';
import { ORGANIZATION_TYPES } from './constants';
import CarrierView from './views/logisticsRevamped/carrier/carrier.js';
import ShipmentDetail from './views/revampedOrder/ShipmentDetail.js';
import Integrations from './views/integrations/index';
import Intelligence from './views/intelligence';
import PricingTable from './views/stripePricing/pricingTable.js';
import EditOrganization from './views/supplyChainNetwork/EditOrganization.js';
import FLAGS from './constants/featureFlags';
import OrganizationSettings from './views/administration/Settings/index.js';
import StockRelocationRevamped from './views/operations/stockRelocationRevamped/index.js';
import StockRelocationBulkUpload from './views/operations/sectionRelocation/AddBulkSectionRelocation';
import OrganizationSetup from './views/organization-setup/index.jsx';
import CustomerPortal from './views/payments/customerPortal';
import Plans from './views/payments/plans';
import { ShipmentManagement } from './views/shipmentManagement/index.js';

const routes = (
  user,
  organizationType,
  isFeatureEnabled,
  enableOrgSetupOnly = false,
  token = ''
) =>
  enableOrgSetupOnly
    ? [
        {
          path: '*',
          element: <Navigate to="/404" />
        },
        {
          path: '/',
          element: <OrganizationSetup />
        }
      ]
    : [
        {
          path: 'administration',
          element: user ? <DashboardLayout /> : <Navigate to="/login" />,
          children: [
            {
              path: 'access',
              element: <AccessScreen />
            },
            ...(FeatureFlag(ADMINISTRATION_VENDOR)
              ? [
                  {
                    path: 'carrier',
                    element: checkPermission(user, 'OPS_CARRIER_READ') ? (
                      <CarrierView
                        key={window.location.pathname}
                        relationType="VENDOR"
                      />
                    ) : (
                      <Navigate to="/administration/access" />
                    )
                  }
                ]
              : []),
            {
              path: 'home',
              element: <Dashboard />
            },
            ...(FeatureFlag(ADMINISTRATION_ORGANIZATION)
              ? [
                  {
                    path: 'organization',
                    element: isOwareSuperAdmin(user) ? (
                      <OrganizationView />
                    ) : isFeatureEnabled(FLAGS.UNIFIED_DASHBOARD) &&
                      checkPermission(user, 'OPS_CONTROL_TOWER_VIEW') ? (
                      <Navigate to="/control-tower" />
                    ) : (
                      <Navigate to="/administration/access" />
                    )
                  }
                ]
              : []),
            {
              path: 'user',
              element: checkPermission(user, 'OPS_USER_READ') ? (
                <UserView />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'supply-chain-network',
              element:
                isFeatureEnabled(FLAGS.CREATING_CUSTOMER_MASTER) &&
                checkPermission(user, 'OPS_COMPANY_READ') ? (
                  <SupplyChainNetwork />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'products',
              element:
                isFeatureEnabled(FLAGS.PRODUCT_HIERARCHY_CREATION) &&
                checkPermission(
                  user,
                  organizationType ===
                    ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
                    ? 'OPS_COMPANY_READ'
                    : 'OPS_PRODUCT_READ'
                ) ? (
                  <ProductMaster />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'node/create',
              element:
                isFeatureEnabled(FLAGS.CREATING_CUSTOMER_MASTER) &&
                checkPermission(user, 'OPS_COMPANY_READ') ? (
                  <AddNode isEntity={true} />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'settings',
              element: <OrganizationSettings />
            },
            {
              path: 'company/:id/node/create',
              element:
                isFeatureEnabled(FLAGS.CREATING_CUSTOMER_MASTER) &&
                checkPermission(user, 'OPS_COMPANY_READ') ? (
                  <AddEntityNode type="Customer" />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'supplier/:id/node/create',
              element:
                isFeatureEnabled(FLAGS.CREATING_CUSTOMER_MASTER) &&
                checkPermission(user, 'OPS_COMPANY_READ') ? (
                  <AddEntityNode type="Supplier" />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'organization/node/create',
              element:
                isFeatureEnabled(FLAGS.CREATING_CUSTOMER_MASTER) &&
                checkPermission(user, 'OPS_COMPANY_READ') ? (
                  <AddEntityNode type="Organization" internal={true} />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'company',
              element:
                isFeatureEnabled(FLAGS.CREATING_CUSTOMER_MASTER) &&
                checkPermission(user, 'OPS_COMPANY_READ') ? (
                  <CompanyView
                    key={window.location.pathname}
                    relationType="CUSTOMER"
                  />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'company/create',
              element:
                isFeatureEnabled(FLAGS.CREATING_CUSTOMER_MASTER) &&
                checkPermission(user, 'OPS_COMPANY_CREATE') ? (
                  <AddCustomer
                    key={window.location.pathname}
                    relationType="CUSTOMER"
                  />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'supplier/create',
              element:
                isFeatureEnabled(FLAGS.CREATING_CUSTOMER_MASTER) &&
                checkPermission(user, 'OPS_COMPANY_CREATE') ? (
                  <AddSupplier
                    key={window.location.pathname}
                    relationType="SUPPLIER"
                  />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'node/:id/view',
              element:
                isFeatureEnabled(FLAGS.CREATING_CUSTOMER_MASTER) &&
                checkPermission(user, 'OPS_COMPANY_CREATE') ? (
                  <WarehouseNodeView key={window.location.pathname} />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'products/:uid',
              element:
                isFeatureEnabled(FLAGS.PRODUCT_HIERARCHY_CREATION) &&
                checkPermission(user, 'OPS_PRODUCT_READ') ? (
                  <ProductsView />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'company/:id/edit',
              element:
                isFeatureEnabled(FLAGS.CREATING_CUSTOMER_MASTER) &&
                checkPermission(user, 'OPS_COMPANY_READ') ? (
                  <AddCustomer edit={true} />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'organization/edit',
              element: checkPermission(user, 'OPS_COMPANY_READ') ? (
                <EditOrganization edit={true} />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'node/:id/edit',
              element:
                isFeatureEnabled(FLAGS.CREATING_CUSTOMER_MASTER) &&
                checkPermission(user, 'OPS_COMPANY_READ') ? (
                  <AddNode edit={true} />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'supplier/:id/edit',
              element:
                isFeatureEnabled(FLAGS.CREATING_CUSTOMER_MASTER) &&
                checkPermission(user, 'OPS_COMPANY_READ') ? (
                  <AddSupplier edit={true} />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'customer/:id/view',
              element: checkPermission(user, 'OPS_COMPANY_READ') ? (
                <ViewCustomer edit={true} />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'supplier/:id/view',
              element: checkPermission(user, 'OPS_COMPANY_READ') ? (
                <ViewCustomer edit={true} />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'company/:id/nodes/create',
              element:
                isFeatureEnabled(FLAGS.CREATING_CUSTOMER_MASTER) &&
                checkPermission(user, 'OPS_COMPANY_READ') ? (
                  <AddNode />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'company-details/:uid',
              element:
                isFeatureEnabled(FLAGS.CREATING_CUSTOMER_MASTER) &&
                checkPermission(user, 'OPS_COMPANY_READ') ? (
                  <CompanyDetails
                    key={window.location.pathname}
                    relationType="CUSTOMER"
                  />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'warehouse',
              element: checkPermission(user, 'OPS_WAREHOUSE_READ') ? (
                <WarehouseListing />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'warehouse/create',
              element: checkPermission(user, 'OPS_WAREHOUSE_CREATE') ? (
                <AddWarehouse />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'warehouse/edit/:uid',
              element: checkPermission(user, 'OPS_WAREHOUSE_UPDATE') ? (
                <AddWarehouse />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'warehouse/:uid',
              element: checkPermission(user, 'OPS_WAREHOUSE_READ') ? (
                <WarehouseHallView />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'warehouse/bulk-create-aisle/:uid',
              element: checkPermission(user, 'OPS_WAREHOUSE_READ') ? (
                <UploadAisleView />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'warehouse/bulk-create-bay/:uid',
              element: checkPermission(user, 'OPS_WAREHOUSE_READ') ? (
                <UploadBayView />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'brand',
              element: checkPermission(user, 'OPS_BRAND_READ') ? (
                <BrandView />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'uom',
              element:
                isFeatureEnabled(FLAGS.PRODUCT_HIERARCHY_CREATION) &&
                checkPermission(user, 'OPS_UOM_READ') ? (
                  <UoMView />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'category',
              element: checkPermission(user, 'OPS_CATEGORY_READ') ? (
                <CategoryView />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'country',
              element: checkPermission(user, 'OPS_COUNTRY_READ') ? (
                <CountryView />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'country/:country_id/regions',
              element: checkPermission(user, 'OPS_REGION_READ') ? (
                <RegionView />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'country/:country_id/regions/:region_id/cities',
              element: checkPermission(user, 'OPS_CITY_READ') ? (
                <CityView />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'product',
              element: checkPermission(user, 'OPS_PRODUCT_READ') ? (
                <ProductView />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'pallets',
              element: checkPermission(user, 'OPS_QR_READ') ? (
                <PalletView />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'product/bulk-upload/:company_id',
              element: checkPermission(user, 'OPS_PRODUCT_BULK_UPLOAD') ? (
                <BulkUpload />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'node/bulk-upload/:company_id',
              element: checkPermission(user, 'OPS_PRODUCT_BULK_UPLOAD') ? (
                <NodeBulkUpload />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: '',
              element: checkPermission(user, 'OPS_USER_READ') ? (
                <Navigate to="/control-tower" />
              ) : (
                <Navigate to="/administration/customer" />
              )
            },
            {
              path: '*',
              element: <Navigate to="/404" />
            }
          ]
        },
        {
          path: 'access-permissions',
          element: user ? <DashboardLayout /> : <Navigate to="/login" />,
          children: [
            {
              path: 'roles',
              element: checkPermission(user, 'OPS_ROLE_READ') ? (
                <RoleView />
              ) : (
                <Navigate to="/administration/access" />
              )
            }
            // {
            //   path: "permissions",
            //   element: checkPermission(user, "OPS_PERMISSION_READ") ? <PermissionView /> : <Navigate to="/administration/access" />,
            // },
            // {
            //   path: "add-permissions-to-role",
            //   element: checkPermission(user, "OPS_PERMISSION_READ") && checkPermission(user, "OPS_ROLE_READ") ? <PermissionRoleView /> : <Navigate to="/administration/access" />,
            // }
          ]
        },
        {
          path: 'integrations',
          element: user ? <DashboardLayout /> : <Navigate to="/login" />,
          children: [
            {
              path: '/',
              element:
                isFeatureEnabled(FLAGS.QUICK_BOOKS_INTEGRATION) &&
                isSuperAdmin(user) ? (
                  <Integrations />
                ) : (
                  <Navigate to="/administration/access" />
                )
            }
          ]
        },
        {
          path: 'operations',
          element: user ? <DashboardLayout /> : <Navigate to="/login" />,
          children: [
            // {
            //   path: 'product-inward',
            //   element: checkPermission(user, 'OPS_PRODUCT_INWARD_READ') ? (
            //     <ProductInwardView />
            //   ) : (
            //     <Navigate to="/administration/access" />
            //   ),
            // },
            // {
            //   path: 'product-inward/bulk-upload',
            //   element: checkPermission(user, 'OPS_PRODUCT_INWARD_BULK_UPLOAD') ? (
            //     <BulkProductInwardView />
            //   ) : (
            //     <Navigate to="/administration/access" />
            //   ),
            // },
            // {
            //   path: 'product-inward/create',
            //   element: checkPermission(user, 'OPS_PRODUCT_INWARD_CREATE') ? (
            //     <AddProductInwardView />
            //   ) : (
            //     <Navigate to="/administration/access" />
            //   ),
            // },
            // {
            //   path: 'product-inward/edit',
            //   element: checkPermission(user, 'OPS_PRODUCT_INWARD_UPDATE') ? (
            //     <AddProductInwardView />
            //   ) : (
            //     <Navigate to="/administration/access" />
            //   ),
            // },
            // {
            //   path: 'product-inward/view/:uid',
            //   element: checkPermission(user, 'OPS_PRODUCT_INWARD_READ') ? (
            //     <InwardProductDetailsView />
            //   ) : (
            //     <Navigate to="/administration/access" />
            //   ),
            // },
            // {
            //   path: 'dispatch-order',
            //   element: checkPermission(user, 'OPS_DISPATCH_ORDER_READ') ? (
            //     <DispatchOrderView />
            //   ) : (
            //     <Navigate to="/administration/access" />
            //   ),
            // },
            // {
            //   path: 'dispatch-order/bulk-upload',
            //   element: checkPermission(user, 'OPS_DISPATCH_ORDER_BULK_UPLOAD') ? (
            //     <OrderBulkUpload />
            //   ) : (
            //     <Navigate to="/administration/access" />
            //   ),
            // },
            // {
            //   path: 'dispatch-order/create',
            //   element: checkPermission(user, 'OPS_DISPATCH_ORDER_CREATE') ? (
            //     <AddDispatchOrderView />
            //   ) : (
            //     <Navigate to="/administration/access" />
            //   ),
            // },
            // {
            //   path: 'dispatch-order/edit/:uid',
            //   element: checkPermission(user, 'OPS_DISPATCH_ORDER_UPDATE') ? (
            //     <AddDispatchOrderView />
            //   ) : (
            //     <Navigate to="/administration/access" />
            //   ),
            // },
            // {
            //   path: 'dispatch-order/view/:uid',
            //   element: checkPermission(user, 'OPS_DISPATCH_ORDER_READ') ? (
            //     <ViewDispatchOrderDetails />
            //   ) : (
            //     <Navigate to="/administration/access" />
            //   ),
            // },
            // {
            //   path: 'product-outward',
            //   element: checkPermission(user, 'OPS_PRODUCT_OUTWARD_READ') ? (
            //     <ProductOutwardView />
            //   ) : (
            //     <Navigate to="/administration/access" />
            //   ),
            // },
            {
              path: 'palletizer',
              element:
                isFeatureEnabled(FLAGS.WMS) &&
                checkPermission(user, 'OPS_STOCK_PALLETIZATION_READ') ? (
                  <PalletizeStockView />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'palletizer/:uid',
              element:
                isFeatureEnabled(FLAGS.WMS) &&
                checkPermission(user, 'OPS_STOCK_PALLETIZATION_READ') ? (
                  <DetailPalletizeStock />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'palletize-stock',
              element:
                isFeatureEnabled(FLAGS.WMS) &&
                checkPermission(user, 'OPS_STOCK_PALLETIZATION_CREATE') ? (
                  <AddPalletizeStock />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            // {
            //   path: 'product-outward/create',
            //   element: checkPermission(user, 'OPS_PRODUCT_OUTWARD_CREATE') ? (
            //     <AddProductOutwardView />
            //   ) : (
            //     <Navigate to="/administration/access" />
            //   ),
            // },
            // {
            //   path: 'product-outward/edit',
            //   element: checkPermission(user, 'OPS_PRODUCT_OUTWARD_UPDATE') ? (
            //     <AddProductOutwardView />
            //   ) : (
            //     <Navigate to="/administration/access" />
            //   ),
            // },
            // {
            //   path: 'product-outward/view/:uid',
            //   element: checkPermission(user, 'OPS_PRODUCT_OUTWARD_READ') ? (
            //     <ViewProductOutwardDetails />
            //   ) : (
            //     <Navigate to="/administration/access" />
            //   ),
            // },
            {
              path: 'stock-adjustment',
              element:
                isFeatureEnabled(FLAGS.WMS) &&
                isFeatureEnabled(FLAGS.STOCK_ADJUSTMENT) &&
                checkPermission(user, 'OPS_STOCK_ADJUSTMENT_READ') ? (
                  <StockManagementView />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'stock-adjustment/create',
              element:
                isFeatureEnabled(FLAGS.WMS) &&
                isFeatureEnabled(FLAGS.STOCK_ADJUSTMENT) &&
                checkPermission(user, 'OPS_STOCK_ADJUSTMENT_CREATE') ? (
                  <AddStockManagement />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'stock-adjustment/edit/:uid',
              element:
                isFeatureEnabled(FLAGS.WMS) &&
                isFeatureEnabled(FLAGS.STOCK_ADJUSTMENT) &&
                checkPermission(user, 'OPS_STOCK_ADJUSTMENT_UPDATE') ? (
                  <AddStockManagement />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'stock-adjustment/view/:uid',
              element:
                isFeatureEnabled(FLAGS.WMS) &&
                isFeatureEnabled(FLAGS.STOCK_ADJUSTMENT) &&
                checkPermission(user, 'OPS_STOCK_ADJUSTMENT_READ') ? (
                  <ViewStockManagementDetails />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'section-relocation',
              element:
                isFeatureEnabled(FLAGS.WMS) &&
                isFeatureEnabled(FLAGS.STOCK_RELOCATION) &&
                checkPermission(user, 'OPS_SECTION_RELOCATION_READ') ? (
                  <StockRelocationRevamped />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'section-relocation/bulk-create',
              element:
                isFeatureEnabled(FLAGS.WMS) &&
                isFeatureEnabled(FLAGS.STOCK_RELOCATION) &&
                checkPermission(user, 'OPS_SECTION_RELOCATION_READ') ? (
                  <StockRelocationBulkUpload />
                ) : (
                  <Navigate to="/administration/access" />
                )
            }
            // {
            //   path: 'section-relocation/create',
            //   element:
            //     isFeatureEnabled(FLAGS.WMS) &&
            //     isFeatureEnabled(FLAGS.STOCK_RELOCATION) &&
            //     checkPermission(user, 'OPS_SECTION_RELOCATION_CREATE') ? (
            //       <AddSectionRelocationView />
            //     ) : (
            //       <Navigate to="/administration/access" />
            //     )
            // },
            // {
            //   path: 'section-relocation/bulk-create',
            //   element:
            //     isFeatureEnabled(FLAGS.WMS) &&
            //     isFeatureEnabled(FLAGS.STOCK_RELOCATION) &&
            //     checkPermission(user, 'OPS_SECTION_RELOCATION_BULK_UPLOAD') ? (
            //       <AddBulkSectionRelocation />
            //     ) : (
            //       <Navigate to="/administration/access" />
            //     )
            // },
            // { path: '*', element: <Navigate to="/404" /> }
          ]
        },
        {
          path: 'logistics',
          element: user ? <DashboardLayout /> : <Navigate to="/login" />,
          children: [
            {
              path: 'driver',
              element: checkPermission(user, 'OPS_DRIVER_READ') ? (
                <DriverView />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'vehicle',
              element: checkPermission(user, 'OPS_VEHICLE_READ') ? (
                <VehicleView />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'vehicle-types',
              element: checkPermission(user, 'OPS_VEHICLE_TYPE_READ') ? (
                <VehicleTypeView />
              ) : (
                <Navigate to="/administration/access" />
              )
            }
            // {
            //   path: 'load',
            //   element: checkPermission(user, 'OPS_RIDE_READ') ? (
            //     <RideView />
            //   ) : (
            //     <Navigate to="/administration/access" />
            //   ),
            // },
            // {
            //   path: 'load/create/:uid',
            //   element: checkPermission(user, 'OPS_RIDE_CREATE') ? (
            //     <AddRideView />
            //   ) : (
            //     <Navigate to="/administration/access" />
            //   ),
            // },
            // {
            //   path: 'load/create',
            //   element: checkPermission(user, 'OPS_RIDE_CREATE') ? (
            //     <AddRideView />
            //   ) : (
            //     <Navigate to="/administration/access" />
            //   ),
            // },
            // {
            //   path: 'load/bulk-upload',
            //   element: checkPermission(user, 'OPS_RIDE_CREATE') ? (
            //     <LoadBulkUpload />
            //   ) : (
            //     <Navigate to="/administration/access" />
            //   ),
            // },
            // {
            //   path: 'load/view/:uid',
            //   element: checkPermission(user, 'OPS_RIDE_READ') ? (
            //     <RideDetailsView />
            //   ) : (
            //     <Navigate to="/administration/access" />
            //   ),
            // },
          ]
        },
        ...(isFeatureEnabled(FLAGS.BILLING_AND_INVOICING)
          ? [
              {
                path: 'billing-invoice',
                element: user ? <DashboardLayout /> : <Navigate to="/login" />,
                children: [
                  {
                    path: 'receivables',
                    element: <InvoiceManagement />
                  },
                  {
                    path: 'payables',
                    element: <ExpenseManagement />
                  },
                  {
                    path: 'receivables/create',
                    element: checkPermission(user, 'OPS_INVOICE_CREATE') ? (
                      <AddInvoice />
                    ) : (
                      <Navigate to="/administration/access" />
                    )
                  },
                  {
                    path: 'payables/create',
                    element: checkPermission(user, 'OPS_EXPENSE_CREATE') ? (
                      <AddExpense type="bill" />
                    ) : (
                      <Navigate to="/administration/access" />
                    )
                  },
                  {
                    path: 'payables/edit/:id',
                    element: checkPermission(user, 'OPS_EXPENSE_UPDATE') ? (
                      <AddExpense type="bill" />
                    ) : (
                      <Navigate to="/administration/access" />
                    )
                  },
                  {
                    path: 'receivables/edit/:id',
                    element: checkPermission(user, 'OPS_INVOICE_UPDATE') ? (
                      <AddInvoice />
                    ) : (
                      <Navigate to="/administration/access" />
                    )
                  },
                  {
                    path: 'settings',
                    element: <Settings />
                  }
                ]
              }
            ]
          : []),

        // {
        //   path: 'billing-invoice',
        //   element: !!user ? <DashboardLayout /> : <Navigate to="/login" />,
        //   children: [
        //     {
        //       path: 'receivables',
        //       element: <InvoiceManagement />
        //     },
        //     {
        //       path: 'payables',
        //       element: <ExpenseManagement />
        //     },
        //     {
        //       path: 'receivables/create',
        //       element: checkPermission(user, 'OPS_INVOICE_CREATE') ? <AddInvoice /> : <Navigate to="/404" />
        //     },
        //     {
        //       path: 'payables/create',
        //       element: checkPermission(user, 'OPS_EXPENSE_CREATE') ? <AddExpense type="bill" /> : <Navigate to="/404" />
        //     },
        //     {
        //       path: 'payables/edit/:id',
        //       element: checkPermission(user, 'OPS_EXPENSE_UPDATE') ? <AddExpense type="bill" /> : <Navigate to="/404" />
        //     },
        //     {
        //       path: 'receivables/edit/:id',
        //       element: checkPermission(user, 'OPS_INVOICE_UPDATE') ? <AddInvoice /> : <Navigate to="/404" />
        //     },
        //     {
        //       path: 'settings',
        //       element: <Settings />
        //     },
        //   ]
        // },
        {
          path: 'billing-invoice',
          element: user ? <DashboardLayout /> : <Navigate to="/login" />,
          children: [
            {
              path: 'receivables',
              element: isFeatureEnabled(FLAGS.BILLING_AND_INVOICING) ? (
                <InvoiceManagement />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'payables',
              element: isFeatureEnabled(FLAGS.BILLING_AND_INVOICING) ? (
                <ExpenseManagement />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'receivables/create',
              element:
                isFeatureEnabled(FLAGS.BILLING_AND_INVOICING) &&
                checkPermission(user, 'OPS_INVOICE_CREATE') ? (
                  <AddInvoice />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'payables/create',
              element:
                isFeatureEnabled(FLAGS.BILLING_AND_INVOICING) &&
                checkPermission(user, 'OPS_EXPENSE_CREATE') ? (
                  <AddExpense type="bill" />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'payables/edit/:id',
              element:
                isFeatureEnabled(FLAGS.BILLING_AND_INVOICING) &&
                checkPermission(user, 'OPS_EXPENSE_UPDATE') ? (
                  <AddExpense type="bill" />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'receivables/edit/:id',
              element:
                isFeatureEnabled(FLAGS.BILLING_AND_INVOICING) &&
                checkPermission(user, 'OPS_INVOICE_UPDATE') ? (
                  <AddInvoice />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'settings',
              element: isFeatureEnabled(FLAGS.BILLING_AND_INVOICING) ? (
                <Settings />
              ) : (
                <Navigate to="/administration/access" />
              )
            }
          ]
        },
        ...(isFeatureEnabled(FLAGS.BILLING_AND_INVOICING)
          ? [
              {
                path: 'billing-invoice',
                element: user ? <DashboardLayout /> : <Navigate to="/login" />,
                children: [
                  {
                    path: 'receivables',
                    element: <InvoiceManagement />
                  },
                  {
                    path: 'payables',
                    element: <ExpenseManagement />
                  },
                  {
                    path: 'receivables/create',
                    element: checkPermission(user, 'OPS_INVOICE_CREATE') ? (
                      <AddInvoice />
                    ) : (
                      <Navigate to="/administration/access" />
                    )
                  },
                  {
                    path: 'payables/create',
                    element: checkPermission(user, 'OPS_EXPENSE_CREATE') ? (
                      <AddExpense type="bill" />
                    ) : (
                      <Navigate to="/administration/access" />
                    )
                  },
                  {
                    path: 'payables/edit/:id',
                    element: checkPermission(user, 'OPS_EXPENSE_UPDATE') ? (
                      <AddExpense type="bill" />
                    ) : (
                      <Navigate to="/administration/access" />
                    )
                  },
                  {
                    path: 'receivables/edit/:id',
                    element: checkPermission(user, 'OPS_INVOICE_UPDATE') ? (
                      <AddInvoice />
                    ) : (
                      <Navigate to="/administration/access" />
                    )
                  },
                  {
                    path: 'settings',
                    element: <Settings />
                  }
                ]
              }
            ]
          : []),

        // {
        //   path: 'billing-invoice',
        //   element: !!user ? <DashboardLayout /> : <Navigate to="/login" />,
        //   children: [
        //     {
        //       path: 'receivables',
        //       element: <InvoiceManagement />
        //     },
        //     {
        //       path: 'payables',
        //       element: <ExpenseManagement />
        //     },
        //     {
        //       path: 'receivables/create',
        //       element: checkPermission(user, 'OPS_INVOICE_CREATE') ? <AddInvoice /> : <Navigate to="/404" />
        //     },
        //     {
        //       path: 'payables/create',
        //       element: checkPermission(user, 'OPS_EXPENSE_CREATE') ? <AddExpense type="bill" /> : <Navigate to="/404" />
        //     },
        //     {
        //       path: 'payables/edit/:id',
        //       element: checkPermission(user, 'OPS_EXPENSE_UPDATE') ? <AddExpense type="bill" /> : <Navigate to="/404" />
        //     },
        //     {
        //       path: 'receivables/edit/:id',
        //       element: checkPermission(user, 'OPS_INVOICE_UPDATE') ? <AddInvoice /> : <Navigate to="/404" />
        //     },
        //     {
        //       path: 'settings',
        //       element: <Settings />
        //     },
        //   ]
        // },
        {
          path: '/',
          element: <MainLayout />,
          children: [
            { path: 'login', element: <LoginView /> },
            { path: '404', element: <NotFoundView /> },
            {
              path: 'subscription-plans',
              element: <Plans />
            },
            { path: 'reset-password/:token', element: <ResetPassword /> },
            {
              path: '/',
              element: user ? (
                <Navigate to="/administration" />
              ) : (
                <Navigate
                  to={'/login' + (token ? `?verificationToken=${token}` : '')}
                />
              )
            }
          ]
        },
        {
          path: 'reporting',
          element: <DashboardLayout />,
          children: [
            {
              path: 'embed-report',
              element: checkPermission(user, 'OPS_REPORT_READ') ? (
                <EmbedReport />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'embed-report/:uid',
              element: checkPermission(user, 'OPS_REPORT_READ') ? (
                <ShowEmbedReport />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'export',
              element: checkPermission(user, 'OPS_INVENTORY_EXPORT') ? (
                <ExportView />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'inventory/view/:uid',
              element:
                isFeatureEnabled(FLAGS.INVENTORY_DETAILS_TABLE) &&
                checkPermission(user, 'OPS_INVENTORY_READ') ? (
                  <InventoryDetailsView />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'notification-console',
              element:
                isFeatureEnabled(FLAGS.NOTIFICATION_CONSOLE) &&
                checkPermission(user, 'OPS_NOTIFICATION_READ') ? (
                  <NotificationConsoleView />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'notification-console/edit/:notificationUsersId',
              element:
                isFeatureEnabled(FLAGS.NOTIFICATION_CONSOLE) &&
                checkPermission(user, 'OPS_NOTIFICATION_UPDATE') ? (
                  <AddViewNotificationUsers />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'notification-console/create',
              element:
                isFeatureEnabled(FLAGS.NOTIFICATION_CONSOLE) &&
                checkPermission(user, 'OPS_NOTIFICATION_CREATE') ? (
                  <AddViewNotificationUsers />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'ledger-report',
              element:
                isFeatureEnabled(FLAGS.INVENTORY_LEDGER_REPORT) &&
                checkPermission(user, 'OPS_LEDGER_READ') ? (
                  <LedgerDetailsView />
                ) : (
                  <Navigate to="/administration/access" />
                )
            }
          ]
        },
        {
          path: 'routing',
          element: <DashboardLayout />,
          children: [
            {
              path: 'inventory',
              element:
                isFeatureEnabled(FLAGS.INVENTORY_DETAILS_TABLE) &&
                checkPermission(user, 'OPS_INVENTORY_READ') ? (
                  <InventoryView />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'add-route',
              element: <AddRoute />
            },

            {
              path: 'add-route/:companyId/:routeId',
              element: <AddRoute />
            },

            ...(FeatureFlag(T_M_ROUTE_MANAGEMENT)
              ? [
                  {
                    path: 'route-management',
                    element: checkPermission(user, 'OPS_ROUTE_READ') ? (
                      <RouteManagement />
                    ) : (
                      <Navigate to="/administration/access" />
                    )
                  }
                ]
              : []),
            {
              path: 'order-management',
              element: isFeatureEnabled(FLAGS.OMS) ? (
                <OrderManagement />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'shipment-management',
              element: isFeatureEnabled(FLAGS.OMS) ? (
                <ShipmentManagement />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'shipment/:sid',
              element: checkPermission(user, 'OPS_ORDER_READ') ? (
                <ShipmentDetail />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'view-reversed-entries/:oid/:sid',
              element: checkPermission(user, 'OPS_ORDER_READ') ? (
                <ViewReversedGRNGDN />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'task-management',
              element:
                checkPermission(user, 'OPS_ORDER_READ') &&
                (isFeatureEnabled(FLAGS.PICKING) ||
                  isFeatureEnabled(FLAGS.PUTAWAY) ||
                  isFeatureEnabled(FLAGS.CYCLE_COUNT)) ? (
                  <TaskManagementView />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'cycle-count',
              element:
                isFeatureEnabled(FLAGS.CYCLE_COUNT) &&
                checkPermission(user, 'OPS_ORDER_READ') ? (
                  <AddCycleCountView />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'custom-picking',
              element:
                isFeatureEnabled(FLAGS.PICKING) &&
                checkPermission(user, 'OPS_ORDER_READ') ? (
                  <AddCustomPicking />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'cycle-count/view/:uid',
              element: checkPermission(user, 'OPS_ORDER_READ') ? (
                <ViewCycleCountDetails />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'picking/view/:uid',
              element: checkPermission(user, 'OPS_ORDER_READ') ? (
                <ViewPickingDetails />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'putaway/view/:uid',
              element: checkPermission(user, 'OPS_ORDER_READ') ? (
                <ViewPutawayDetails />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'create-order',
              element: checkPermission(user, 'OPS_ORDER_CREATE') ? (
                <AddOrder />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'bulk-create-order',
              element: checkPermission(user, 'OPS_ORDER_CREATE') ? (
                <AddBulkOrder />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'edit-order/:oid',
              element: checkPermission(user, 'OPS_ORDER_UPDATE') ? (
                <AddOrder />
              ) : (
                <Navigate to="/administration/access" />
              )
            },
            {
              path: 'view-order/:oid',
              element: <OrderDetails />
            },
            {
              path: 'inventory/view/:uid',
              element:
                isFeatureEnabled(FLAGS.INVENTORY_DETAILS_TABLE) &&
                checkPermission(user, 'OPS_INVENTORY_READ') ? (
                  <InventoryDetailsView />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'view-grn/:oid/:sid/:id',
              element:
                isFeatureEnabled(FLAGS.GRN_CREATION) &&
                checkPermission(user, 'OPS_ORDER_READ') ? (
                  <AddGrnView />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'create-grn/:oid/:sid',
              element:
                isFeatureEnabled(FLAGS.GRN_CREATION) &&
                checkPermission(user, 'OPS_ORDER_READ') ? (
                  <AddGrnView />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'view-gdn/:oid/:sid/:id',
              element:
                isFeatureEnabled(FLAGS.GDN_CREATION) &&
                checkPermission(user, 'OPS_ORDER_READ') ? (
                  <AddGdnView />
                ) : (
                  <Navigate to="/administration/access" />
                )
            },
            {
              path: 'create-gdn/:oid/:sid',
              element:
                isFeatureEnabled(FLAGS.GDN_CREATION) &&
                checkPermission(user, 'OPS_ORDER_READ') ? (
                  <AddGdnView />
                ) : (
                  <Navigate to="/administration/access" />
                )
            }
          ]
        },
        {
          path: 'onexus',
          element: isOwareSubdomain() ? (
            user ? (
              <DashboardLayout />
            ) : (
              <Navigate to="/login" />
            )
          ) : (
            <Navigate to="/administration/access" />
          ),
          children: [
            {
              path: 'listing',
              element: <OnexusListing />
            },
            {
              path: 'warehouse/:uid',
              element: <OnexusDetailPage />
            },
            {
              path: 'booking',
              element: <WarehouseBookingList />
            },
            {
              path: 'customer-onboardings',
              element: <CustomerOnboarding />
            }
          ]
        },
        {
          path: 'QR',
          element: user ? <DashboardLayout /> : <Navigate to="/login" />,
          children: [
            {
              path: 'generate',
              element: isFeatureEnabled(FLAGS.STOCK_RELOCATION) ? (
                <QRCodeGeneration />
              ) : (
                <Navigate to="/administration/access" />
              )
            }
          ]
        },
        {
          path: 'profile',
          element: user ? <DashboardLayout /> : <Navigate to="/login" />,
          children: [
            {
              path: 'settings',
              element: <ProfileSettingsView />
            }
          ]
        },
        {
          path: 'scripts/:scriptName',
          element: user ? <ScriptsDetailsView /> : <Navigate to="/login" />
        },
        {
          path: 'control-tower',
          element: user ? <DashboardLayout /> : <Navigate to="/login" />,
          children: [
            {
              path: '',
              element:
                isFeatureEnabled(FLAGS.UNIFIED_DASHBOARD) &&
                checkPermission(user, 'OPS_CONTROL_TOWER_VIEW') ? (
                  <UnifiedDashboardView />
                ) : (
                  <Navigate to="/administration/access" />
                )
            }
          ]
        },
        {
          path: 'pricing',
          element: <PricingTable />
        },
        {
          path: 'intelligence',
          element: user ? <DashboardLayout /> : <Navigate to="/login" />,
          children: [
            {
              path: '',
              element:
                isFeatureEnabled(FLAGS.INTELLIGENCE_MODULE) &&
                checkPermission(user, 'OPS_INTELLIGENCE') ? (
                  <Intelligence />
                ) : (
                  <Navigate to="/administration/access" />
                )
            }
          ]
        },
        {
          path: 'payment',
          element: user ? <DashboardLayout /> : <Navigate to="/login" />,
          children: [
            {
              path: 'customer-portal',
              element: <CustomerPortal />
            }
          ]
        }
      ];

export default routes;
