/*eslint-disable */
import React, { useContext } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import CustomPagination from '../invoiceManagement/CustomPagination';
import {
  calculateEuclideanDistance,
  decimalDisplay,
  SharedContext
} from '../../../utils/common';
import SearchBar from '../../../atomicComponents/SearchBar';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  searchBar: {
    minWidth: '250px',
    height: '32px',
    border: '1px solid #67718C',
    borderRadius: '4px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '100%',
    fontSize: '12px',
    '& input::placeholder': {
      fontSize: '12px'
    }
  }
}));

const ShipmentListing = ({
  setSelected,
  selected = [], // Default selected as an array
  rows,
  page,
  setPage,
  pages,
  selectedUnit,
  setSelectedUnit,
  search,
  setSearch,
  isBilling
}) => {
  const classes = useStyles();
  const { organization } = useContext(SharedContext);

  // Check if the current row is selected
  const isSelected = (row) => selected.some((item) => item.id === row.id);

  // Handle selection (multiple rows)
  const handleSelect = (row) => {
    if (isSelected(row)) {
      // Remove the row if already selected
      setSelected(selected.filter((item) => item.id !== row.id));
    } else {
      // Add the row to the selected list
      setSelected([...selected, row]);
    }
  };

  // Calculate total distance for selected shipments
  const computeTotalDistance = () => {
    if (selected.length === 0) return 0;

    return selected.reduce((total, shipment) => {
      const pickup = shipment.pickupNode?.locationLatLng;
      const dropOff = shipment.dropOffNode?.locationLatLng;

      return pickup && dropOff
        ? total + Number(calculateEuclideanDistance(pickup, dropOff))
        : total;
    }, 0);
  };

  const tableCellClass = 'py-2 h-12 text-xs';

  return (
    <div>
      <div
        style={{ marginBottom: '24px', marginLeft: '16px', marginTop: '24px' }}
      >
        <div className="flex justify-end items-center mb-10 mt-10">
          <div className="shipment-search">
            <SearchBar
              searchKeyword={search}
              setSearchValue={setSearch}
              className={classes.searchBar}
              iconClass="h-4 w-4"
            />
          </div>
        </div>
      </div>
      <TableContainer
        sx={{
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          overflow: 'hidden',
          border: '1px solid #E7E6E6 !important',
          '& .MuiTable-root': {
            borderCollapse: 'unset'
          }
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell className="text-[10px] text-dark500 bg-white py-2 font-normal h-[40px]">
                Shipment ID
              </TableCell>
              <TableCell className="text-[10px] text-dark500 bg-white py-2 font-normal h-[40px]">
                Order ID
              </TableCell>
              <TableCell className="text-[10px] text-dark500 bg-white py-2 font-normal h-[40px]">
                Origin - Destination
              </TableCell>
              <TableCell className="text-[10px] text-dark500 bg-white py-2 font-normal h-[40px]">
                Title
              </TableCell>
              <TableCell className="text-[10px] text-dark500 bg-white py-2 font-normal h-[40px]">
                Total Amount
              </TableCell>
              <TableCell className="text-[10px] text-dark500 bg-white py-2 font-normal h-[40px]">
                Reference ID
              </TableCell>
              <TableCell className="text-[10px] text-dark500 bg-white py-2 font-normal h-[40px]">
                PoD Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              const isItemSelected = isSelected(row);

              return (
                <TableRow
                  hover
                  onClick={() => handleSelect(row)}
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  className={`${row.isDisabled ? 'disabled-row' : ''} ${
                    isItemSelected ? 'selected-row' : ''
                  }`}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onChange={(e) => {
                        e.stopPropagation(); // Prevent row click
                        handleSelect(row);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    className={`${tableCellClass} group-hover:text-primaryBlue`}
                  >
                    {row.shipmentCustomId}
                  </TableCell>
                  <TableCell
                    className={`${tableCellClass} group-hover:text-primaryBlue`}
                  >
                    {row.orderId}
                  </TableCell>
                  <TableCell
                    className={`${tableCellClass} group-hover:text-primaryBlue w-[30%]`}
                  >{`${row.origin} - ${row.destination}`}</TableCell>
                  <TableCell
                    className={`${tableCellClass} group-hover:text-primaryBlue`}
                  >
                    {row?.type == 'Expense' ? row?.expenseName : row.type}
                  </TableCell>
                  <TableCell
                    className={`${tableCellClass} group-hover:text-primaryBlue`}
                  >
                    {row.expenseCost
                      ? `${organization?.defaultCurrency} ${decimalDisplay(
                          row.expenseCost
                        )}`
                      : '-'}
                  </TableCell>
                  <TableCell
                    className={`${tableCellClass} group-hover:text-primaryBlue`}
                  >
                    {row.referenceId || '-'}
                  </TableCell>
                  <TableCell
                    className={`${tableCellClass} group-hover:text-primaryBlue`}
                  >
                    <div className="flex gap-4 items-center">
                      <span
                        className={`w-4 h-4 rounded-[50%] ${
                          row.isDisabled ? 'bg-[#FFCC00]' : 'bg-success'
                        }`}
                      ></span>
                      <span>{row.podStatus?.replace('Pod', 'PoD')}</span>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination page={page} setPage={setPage} pages={pages} />
    </div>
  );
};

export default ShipmentListing;
