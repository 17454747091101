import { useState } from 'react';
import { List, ListItem } from '@mui/material';

import Button from '../../../core-components/atoms/Button';
import PopupButton from '../../../core-components/molecules/PopupButton';
import BulkIcon from '../../../assets/icons/blue-bulk-upload.svg';
import downloadFileIcon from '../../../assets/icons/templateDownload.svg';
import template from '../../../assets/files/bulkTrackingTemplate.xlsx';
import BulkFileAttacher from '../../productMaster/ListViews/components/BulkFileAttacher';

export const BulkTrackingVerification = ({
  bulkBtnOpen,
  setBulkBtnOpen,
  fileUpload
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  return (
    <PopupButton
      open={bulkBtnOpen}
      setOpen={setBulkBtnOpen}
      clickAwayCallback={() => {
        setSelectedFile(null);
      }}
      btn={
        <Button
          label="Upload Trackings"
          onClick={(e) => {
            e.preventDefault();
            setBulkBtnOpen(!bulkBtnOpen);
          }}
          className={'py-2 px-3 ml-2 h-8 rounded border-solid'}
          variant="transparent"
          icon={<img src={BulkIcon} className={`mr-[2px]`} />}
        />
      }
      content={
        <List className="py-4">
          <ListItem className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer">
            <a href={template} className="flex gap-2">
              <img src={downloadFileIcon} className={`mr-[2px]`} />
              <span>Download Template</span>
            </a>
          </ListItem>
          <BulkFileAttacher
            bulkUpload={(vals) => fileUpload(vals)}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          />
        </List>
      }
    />
  );
};
