/*eslint-disable*/
import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import { Tooltip } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteConfirmation from '../DeleteConfirmation';
import ProductSubTable from './ProductSubTable';
import Button from '../../../core-components/atoms/Button';
import Popup from '../../../core-components/atoms/Popup';
import QR_ICON from '../../../assets/icons/qr.svg';
import AddBatch from './AddDefaultGdnBatch';
import ScanBatch from '../grn/ScanBatch';
import { toaster } from '../../../utils/toaster';
import { decimalDisplay, SharedContext } from '../../../utils/common';
import { PRIMARY } from '../../../constants/colors';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import UomPopup from '../../revampedOrder/components/UomPopup';
const GDNTableHeaders = [
  {
    key: 'skuName',
    value: 'SKU Code & Name'
  },
  {
    key: 'batch',
    value: 'Batch'
  },
  {
    key: 'mfgd',
    value: 'Mfg Date'
  },
  {
    key: 'expd',
    value: 'Exp Date'
  },
  {
    key: 'actualDispatchedQuantity',
    value: 'Quantity'
  }
];

const GdnProductsTableRowMobile = (props) => {
  const {
    row,
    parentIndex,
    rows,
    setRows,
    settleRemainingQty,
    formik,
    setResetProducts,
    inventoryBatches,
    getBatches,
    GDNview,
    pickingTask
  } = props;

  const uomValue = row?.SecondaryUOM?.id
    ? {
        primary: false,
        conversionFactor:
          row?.Product?.ProductUOMs?.find(
            (u) => u.uomId == row?.SecondaryUOM?.id
          )?.conversionFactor || 1,
        ...row?.SecondaryUOM
      }
    : {
        ...row?.Product?.UOM,
        conversionFactor: 1,
        primary: true
      };

  const [open, setOpen] = useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [parentRow, setParentRow] = useState(null);
  const [childRow, setChildRow] = useState(null);
  const [remainingQty, setRemainingQty] = useState(0);
  const [dispatchedQty, setDispatchedQty] = useState(0);
  const [openSubTable, setOpenSubTable] = useState(false);
  const [scanOpen, setScanOpen] = useState(false);
  const [damagedQuantity, setDamagedQuantity] = useState(0);

  const [uom, setUom] = useState(uomValue);
  const [uomView, setUomView] = useState(false);
  const [primaryUom, setPrimaryUom] = useState({
    ...row?.Product?.UOM,
    conversionFactor: 1,
    primary: true
  });

  const [secondaryUoms, setSecondaryUoms] = useState(
    row?.Product?.ProductUOMs?.map(
      (uom) =>
        ({
          id: uom?.id,
          name: uom?.UOM?.name,
          conversionFactor: uom?.conversionFactor,
          uomId: uom?.uomId
        }) || []
    )
  );

  const uoms = [primaryUom, ...secondaryUoms];

  const onDelete = () => {
    let data = rows;
    if (childRow == 0 || childRow) {
      data[parentRow].GDNGroupBatches = data[parentRow].GDNGroupBatches.filter(
        (product, index) => {
          return product && index !== childRow;
        }
      );
      let actualQuantity = 0;
      if (data[parentRow]?.GDNGroupBatches.length) {
        actualQuantity = data[parentRow]?.GDNGroupBatches?.reduce(
          function (acc, obj) {
            return Number(acc) + (Number(obj?.actualDispatchedQuantity) || 0);
          }
        );
      }

      data[parentRow].actualQuantity = actualQuantity;
      setDispatchedQty(0);
    } else {
      data = data.filter((product, index) => {
        return product && index !== parentRow;
      });
    }

    setResetProducts(JSON.stringify([...data]));
    setRows([...data]);
    setDeletePopupOpen(false);
    setParentRow(null);
    setChildRow(null);
    setLoading(false);
  };

  const addBatch = (parentIndex, batch) => {
    let toggleData = rows;
    toggleData[parentIndex].actualQuantity =
      (+toggleData[parentIndex].actualQuantity || 0) +
      +batch.actualDispatchedQuantity * (uom?.conversionFactor || 1);
    if (!toggleData[parentIndex].Product.batchEnabled) {
      if (toggleData[parentIndex].GDNGroupBatches.length > 0) {
        toggleData[parentIndex].GDNGroupBatches[0].actualDispatchedQuantity +=
          batch.actualDispatchedQuantity * (uom?.conversionFactor || 1);
      } else {
        toggleData[parentIndex].GDNGroupBatches = [
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...toggleData[parentIndex]?.GDNGroupBatches,
          {
            ...batch,
            actualDispatchedQuantity:
              batch.actualDispatchedQuantity * (uom?.conversionFactor || 1)
          }
        ];
      }
    } else {
      let index;
      if (batch.promoName) {
        // eslint-disable-next-line no-unused-expressions
        index = toggleData[parentIndex].GDNGroupBatches.findIndex(
          (t) =>
            t.batchNumber == batch.batchNumber &&
            moment(t.expiryDate).isSame(batch.expiryDate) &&
            t.promoName == batch.promoName
        );
      } else {
        // eslint-disable-next-line no-unused-expressions
        index = toggleData[parentIndex].GDNGroupBatches.findIndex(
          (t) =>
            t.batchNumber == batch.batchNumber &&
            moment(t.expiryDate).isSame(batch.expiryDate) &&
            !t.promoName
        );
      }
      if (index >= 0) {
        toggleData[parentIndex].GDNGroupBatches[
          index
        ].actualDispatchedQuantity +=
          +batch.actualDispatchedQuantity * (uom?.conversionFactor || 1);
        if (batch.promoName) {
          toggleData[parentIndex].GDNGroupBatches[index].promoQuantity +=
            +batch.actualDispatchedQuantity * (uom?.conversionFactor || 1);
        }
      } else {
        if (batch.promoName) {
          toggleData[parentIndex].GDNGroupBatches = [
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...toggleData[parentIndex]?.GDNGroupBatches,
            {
              ...batch,
              promoQuantity:
                +batch.actualDispatchedQuantity * (uom?.conversionFactor || 1)
            }
          ];
        } else {
          toggleData[parentIndex].GDNGroupBatches = [
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...toggleData[parentIndex]?.GDNGroupBatches,
            {
              ...batch,
              actualDispatchedQuantity:
                batch.actualDispatchedQuantity * (uom?.conversionFactor || 1)
            }
          ];
        }
      }
    }

    setRows([...toggleData]);
  };

  const editBatch = (parentIndex, childIndex, batch) => {
    let toggleData = rows;
    toggleData[parentIndex].GDNGroupBatches[childIndex] = {
      ...batch,
      actualDispatchedQuantity:
        batch.actualDispatchedQuantity * (uom?.conversionFactor || 1)
    };
    if (batch.promoName) {
      toggleData[parentIndex].GDNGroupBatches[childIndex].promoQuantity =
        (+toggleData[parentIndex].actualQuantity || 0) +
        +batch.actualDispatchedQuantity * (uom?.conversionFactor || 1) -
        +toggleData[parentIndex].GDNGroupBatches[childIndex]
          .actualDispatchedQuantity;
    }

    setRows([...toggleData]);
  };

  const deleteBatch = (childIndex, promo = false) => {
    let toggleData = rows;
    if (
      !+toggleData[parentIndex].GDNGroupBatches[childIndex].promoQuantity ||
      (promo &&
        +toggleData[parentIndex].GDNGroupBatches[childIndex]
          .actualDispatchedQuantity ===
          +toggleData[parentIndex].GDNGroupBatches[childIndex].promoQuantity)
    ) {
      toggleData[parentIndex].actualQuantity -=
        +toggleData[parentIndex].GDNGroupBatches[childIndex]
          .actualDispatchedQuantity;
      toggleData[parentIndex].GDNGroupBatches.splice(childIndex, 1);
    } else if (promo) {
      toggleData[parentIndex].actualQuantity -=
        toggleData[parentIndex].GDNGroupBatches[childIndex].promoQuantity;
      toggleData[parentIndex].GDNGroupBatches[
        childIndex
      ].actualDispatchedQuantity -=
        toggleData[parentIndex].GDNGroupBatches[childIndex].promoQuantity;
      toggleData[parentIndex].GDNGroupBatches[childIndex].promoName = '';
      toggleData[parentIndex].GDNGroupBatches[childIndex].promoQuantity = 0;
    } else {
      toggleData[parentIndex].actualQuantity -=
        +toggleData[parentIndex].GDNGroupBatches[childIndex]
          .actualDispatchedQuantity +
        +toggleData[parentIndex].GDNGroupBatches[childIndex].promoQuantity;
      toggleData[parentIndex].GDNGroupBatches[
        childIndex
      ].actualDispatchedQuantity =
        +toggleData[parentIndex].GDNGroupBatches[childIndex].promoQuantity;
      toggleData[parentIndex].GDNGroupBatches[
        childIndex
      ].unsellableDamageQuantity = 0;
      toggleData[parentIndex].GDNGroupBatches[
        childIndex
      ].recoverableDamageQuantity = 0;
    }

    setRows([...toggleData]);
  };

  useEffect(() => {
    const [dispatchedQty, damagedQty] = row?.GDNGroupBatches?.reduce(
      (acc, obj) => {
        return [
          acc[0] + (+obj?.actualDispatchedQuantity || 0),
          acc[1] +
            (+obj?.recoverableDamageQuantity || 0) +
            (+obj?.unsellableDamageQuantity || 0)
        ];
      },
      [0, 0]
    ) || [0, 0];

    setDispatchedQty(parseFloat(dispatchedQty.toFixed(3)));
    setDamagedQuantity(parseFloat(damagedQty.toFixed(3)));
  }, [rows]);

  useEffect(() => {
    const remainingQty = row?.orderedQuantity
      ? row?.orderedQuantity - dispatchedQty
      : 0;
    setRemainingQty(remainingQty < 0 ? 0 : remainingQty.toFixed(3));
    settleRemainingQty(remainingQty < 0 ? 0 : remainingQty.toFixed(3));

    let toggleData = [...rows];

    if (!GDNview) {
      if (!uom?.primary) {
        toggleData[parentIndex].secondaryUomId = uom?.uomId || uom?.id;
        toggleData[parentIndex].secondaryUomQuantity =
          dispatchedQty / uom?.conversionFactor;
      } else {
        toggleData[parentIndex].secondaryUomId = null;
        toggleData[parentIndex].secondaryUomQuantity = null;
      }
      setRows(toggleData);
    }
  }, [dispatchedQty]);

  useEffect(() => {
    if (row?.secondaryUomId) {
      setUom(uoms?.find((uom) => uom?.uomId == row?.secondaryUomId));
    }
  }, [row]);

  const showAddButton = pickingTask ? false : remainingQty > 0;
  const { organization } = useContext(SharedContext);

  return (
    <>
      <div className="card group mb-4 p-4 shadow-lg border rounded-lg bg-white">
        <div className="card-header flex justify-between items-center">
          <div className="product-info">
            <Tooltip title={`${row?.Product?.name}`}>
              <p className="text-base font-bold">
                {row?.Product.name} - {row?.Product.description}
              </p>
            </Tooltip>
            {row?.Product?.barcode && (
              <div className="font-xs truncate w-[180px] text-gray-600">
                <span>Barcode: {row?.Product?.barcode}</span>
              </div>
            )}
          </div>

          {!!row?.GDNGroupBatches?.length && (
            <IconButton
              aria-label="expand card"
              size="small"
              onClick={() => setOpenSubTable(!openSubTable)}
            >
              {openSubTable ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          )}
        </div>

        <div className="card-body grid grid-cols-1 gap-4 mt-4">
          <div className="uom-section">
            <p className="text-sm font-bold mb-2">Unit of Measure</p>
            <Autocomplete
              value={uom}
              onChange={(e, value) => setUom(value)}
              options={[
                ...uoms,
                ...(!GDNview && organization?.customUOM
                  ? [{ name: '+ Create UoM', type: 'add' }]
                  : [])
              ]}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                  padding: '0 8px',
                  fontSize: '0.875rem',
                  width: '10rem'
                },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #e0e0e0'
                },
                width: '100%'
              }}
              renderOption={(props, option) =>
                option?.type === 'add' ? (
                  <li
                    className="auto-complete-option"
                    style={{
                      color: PRIMARY,
                      fontSize: '16px',
                      fontWeight: 500
                    }}
                    onClick={() => setUomView(true)}
                  >
                    {option.name}
                  </li>
                ) : (
                  <li {...props} className="auto-complete-option-small">
                    <span>{option.name}</span>
                  </li>
                )
              }
              getOptionLabel={(uom) => uom.name}
              placeholder="Select UoM"
            />
          </div>

          <div className="quantity-info">
            <p className="text-sm font-bold mb-2">Quantities</p>
            <p className="text-base">
              <span className="font-bold">Ordered: </span>
              {decimalDisplay(
                row.orderedQuantity / (uom?.conversionFactor || 1)
              )}
            </p>
            <p className="text-base">
              <span className="font-bold">Dispatched: </span>
              {decimalDisplay(dispatchedQty / (uom?.conversionFactor || 1))}
            </p>
            <p className="text-base">
              <span className="font-bold">Remaining: </span>
              {decimalDisplay(remainingQty / (uom?.conversionFactor || 1))}
            </p>
          </div>
        </div>

        {!!remainingQty && showAddButton && (
          <div className="card-footer flex justify-end mt-4">
            {!GDNview && (
              <>
                <Button
                  label="Scan"
                  icon={<img src={QR_ICON} alt="Scan Icon" className="mr-2" />}
                  onClick={async () => {
                    const invBatches = await getBatches(
                      formik.values.Order.pickupId,
                      formik.values.Order.companyId,
                      row.Product.id
                    );
                    if (!row.Product.batchEnabled || invBatches?.length) {
                      return setScanOpen(true);
                    }
                    toaster(
                      'warning',
                      `Inventory is not available in staging for ${row.Product?.name}`
                    );
                  }}
                  component="label"
                  className="mr-2 w-20 bg-indigo-600 text-black rounded-lg hover:bg-indigo-700 transition"
                />
                <Button
                  label="Add"
                  className="w-16 bg-green-500 text-black rounded-lg hover:bg-green-600 transition"
                  onClick={async () => {
                    const invBatches = await getBatches(
                      formik.values.Order.pickupId,
                      formik.values.Order.companyId,
                      row.Product.id
                    );
                    if (!row.Product.batchEnabled || invBatches?.length) {
                      return setOpen(true);
                    }
                    toaster(
                      'warning',
                      `Inventory is not available in staging for ${row.Product?.name}`
                    );
                  }}
                />
              </>
            )}
          </div>
        )}

        {openSubTable && !!row?.GDNGroupBatches?.length && (
          <div className="sub-table mt-4">
            <Collapse in={openSubTable} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 2, width: 20 }}>
                <div className="GDNScroll">
                  <ProductSubTable
                    batches={row?.GDNGroupBatches}
                    enableActions={true}
                    deleteBatch={deleteBatch}
                    GDNview={GDNview}
                    addBatch={addBatch}
                    editBatch={editBatch}
                    parentIndex={parentIndex}
                    remainingQty={remainingQty}
                    dispatchedQty={dispatchedQty}
                    damagedQuantity={dispatchedQty}
                    inventoryBatches={inventoryBatches}
                    getBatches={getBatches}
                    row={row}
                    Order={formik.values.Order}
                    pickingTask={pickingTask}
                    pickedProducts={rows}
                    uom={uom}
                  />
                </div>
              </Box>
            </Collapse>
          </div>
        )}

        <Popup
          title={`Scan Batch Details for ${row?.Product?.name} - ${row?.Product?.description}`}
          subTitle={`Dispatched Qty: ${decimalDisplay(
            dispatchedQty
          )} · Good Qty: ${decimalDisplay(dispatchedQty - damagedQuantity)}`}
          open={scanOpen}
          setOpen={setScanOpen}
          content={
            <ScanBatch
              setOpen={setScanOpen}
              formik={formik}
              source={'GDN'}
              tableHeaders={GDNTableHeaders}
              rows={rows}
              setRows={setRows}
              selectedProduct={row?.Product}
              parentIndex={parentIndex}
            />
          }
        />
        <Popup
          title={`Add Batch Details for ${row?.Product?.name} - ${row?.Product?.description}`}
          open={open}
          setOpen={setOpen}
          content={
            <AddBatch
              addBatch={addBatch}
              parentIndex={parentIndex}
              requiredQty={row.orderedQuantity}
              remainingQty={remainingQty}
              setOpen={setOpen}
              product={row?.Product}
              row={row}
              dispatchedQty={dispatchedQty}
              damagedQuantity={damagedQuantity}
              inventoryBatches={inventoryBatches}
              pickingTask={pickingTask}
              uom={uom}
            />
          }
        />
      </div>

      {deletePopupOpen && (
        <DeleteConfirmation
          handleClose={() => setDeletePopupOpen(false)}
          open={deletePopupOpen}
          confirmDelete={onDelete}
          loading={loading}
        />
      )}

      <UomPopup
        open={uomView}
        setOpen={setUomView}
        primaryUom={primaryUom}
        secondaryUoms={secondaryUoms}
        setPrimaryUom={setPrimaryUom}
        setSecondaryUoms={setSecondaryUoms}
        refetech={() => {}}
        value={{ id: row?.Product?.id }}
      />
    </>
  );
};

export default GdnProductsTableRowMobile;
