import { Box, Divider, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import owareLogo from '../../assets/icons/O360DarkLogo.svg';
import Print from '@mui/icons-material/PrintOutlined';
import Button from '../../core-components/atoms/Button';
import clsx from 'clsx';
import API from '../../libs/axios';
import {
  formatDate,
  replaceSHPwithWB,
  SharedContext
} from '../../utils/common';
import PrintStyles from '../../components/printGrnGdn/PrintStyles';
import { Paragraph } from '../../core-components/atoms/Text';
import ProductDetail from './OrderDetails/OrderPrint/ProductDetail';
import { isLinxSubdomain } from '../../utils/auth';

const PrintDocument = ({ orderId, shipment, isIcon, leg }) => {
  const classes = PrintStyles();
  const componentRef = useRef();
  const [order, setOrder] = useState({});
  const [orderSummary, setOrderSummary] = useState({});

  const { darkOrganizationLogoFile, organization } = useContext(SharedContext);

  const handlePrint = async () => {
    handlePrintTaskDetails();
  };
  const handlePrintTaskDetails = useReactToPrint({
    content: () => componentRef.current
  });

  const getOrder = async () => {
    const { data } = await API.get(`orders/order/${orderId}`);
    setOrder(data);
  };

  useEffect(() => {
    getOrder();
  }, [orderId]);

  useEffect(() => {
    const processGRNs = (grns) => {
      const summary = {};

      grns.forEach((grn) => {
        grn.GRNGroup.forEach((group) => {
          const productId = group.productId;
          const batches = group.GRNGroupBatches;
          const orderProductIndex = order.OrderProductGroups.findIndex(
            (op) => op.ProductId === productId
          );
          const conversionFactor =
            orderProductIndex !== -1 &&
            order.OrderProductGroups[orderProductIndex]?.secondaryUomId
              ? order.OrderProductGroups[
                  orderProductIndex
                ].Product?.ProductUOMs?.find(
                  (u) =>
                    u.uomId ===
                    order.OrderProductGroups[orderProductIndex].secondaryUomId
                )?.conversionFactor
              : 1;
          const existing = summary[productId] || {
            total: group.orderedQuantity,
            uom: group?.Product?.UOM?.name,
            productName: group.Product.description,
            sku: group.Product.name,
            received: 0,
            costPrice: group.costPrice,
            tax: group.tax,
            totalCost: group.costPrice * group.orderedQuantity,
            recoverable: 0,
            unsellable: 0,
            good: 0,
            secondaryUomQuantity: order.OrderProductGroups[orderProductIndex]
              .SecondaryUOM?.name
              ? order.OrderProductGroups[orderProductIndex].secondaryUomQuantity
              : group.orderedQuantity /
                order.OrderProductGroups[orderProductIndex].Product
                  ?.ProductUOMs?.[0]?.conversionFactor,
            secondaryUom:
              order.OrderProductGroups[orderProductIndex].SecondaryUOM?.name ||
              order.OrderProductGroups[orderProductIndex].Product
                ?.ProductUOMs?.[0]?.UOM?.name,
            conversionFactor
          };

          existing.received += group.receivedQuantity / conversionFactor;
          existing.recoverable +=
            batches.reduce(
              (acc, batch) => acc + batch.recoverableDamageQuantity,
              0
            ) / conversionFactor;
          existing.unsellable +=
            batches.reduce(
              (acc, batch) => acc + batch.unsellableDamageQuantity,
              0
            ) / conversionFactor;
          existing.good +=
            batches.reduce(
              (acc, batch) =>
                acc +
                (batch.receivedQuantity -
                  (batch.recoverableDamageQuantity +
                    batch.unsellableDamageQuantity)),
              0
            ) / conversionFactor;

          summary[productId] = existing;
        });
      });

      return summary;
    };

    const processOrderProductGroups = (orderProductGroups) => {
      const summary = {};

      orderProductGroups.forEach((group) => {
        const productId = group.ProductId;
        const conversionFactor = group?.secondaryUomId
          ? group.Product?.ProductUOMs?.find(
              (u) => u.uomId === group.secondaryUomId
            )?.conversionFactor
          : 1;
        summary[productId] = {
          total: group.orderedQuantity,
          productName: group.Product.description,
          uom: group.Product.UOM.name,
          sku: group.Product.name,
          costPrice: group.costPrice,
          tax: group.tax,
          totalCost: group.costPrice * group.orderedQuantity,
          received: 0,
          dispatched: 0,
          sellingPrice: group.sellingPrice,
          totalRevenue: group.totalRevenue,
          secondaryUomQuantity: group.secondaryUomQuantity,
          secondaryUom: group.SecondaryUOM?.name,
          conversionFactor
        };
      });

      return summary;
    };

    const mergeSummaries = (summary1, summary2) => {
      return { ...summary1, ...summary2 };
    };

    const handleInward = (order) => {
      const grns = order.Shipments.flatMap((s) => s.GRNs).filter(
        (grn) => !grn.reversedAt
      );
      let summary =
        grns.length > 0
          ? processGRNs(grns)
          : processOrderProductGroups(order.OrderProductGroups);

      const allProducts = processOrderProductGroups(order.OrderProductGroups);
      summary = mergeSummaries(allProducts, summary);

      setOrderSummary(summary);
    };

    const handleTransfer = (order) => {
      const grns = order.Shipments.flatMap((s) => s.GRNs).filter(
        (grn) => !grn.reversedAt
      );
      const gdns = order.Shipments.flatMap((s) => s.GDNs).filter(
        (gdn) => !gdn.reversedAt
      );
      let summary = grns.length > 0 ? processGRNs(grns) : {};

      if (gdns.length > 0) {
        gdns.forEach((gdn) => {
          gdn.GDNGroup.forEach((group) => {
            const productId = group.productId;
            const orderProductIndex = order.OrderProductGroups.findIndex(
              (op) => op.ProductId === productId
            );
            const conversionFactor = order.OrderProductGroups[orderProductIndex]
              ?.secondaryUomId
              ? order.OrderProductGroups[
                  orderProductIndex
                ].Product?.ProductUOMs?.find(
                  (u) =>
                    u.uomId ===
                    order.OrderProductGroups[orderProductIndex].secondaryUomId
                )?.conversionFactor
              : 1;
            const existing = summary[productId] || {
              total: group.orderedQuantity,
              productName: group.Product.description,
              uom: order.OrderProductGroups[orderProductIndex].Product.UOM.name,
              sku: order.OrderProductGroups[orderProductIndex].Product.name,
              dispatched: 0,
              secondaryUomQuantity:
                order.OrderProductGroups[orderProductIndex]
                  .secondaryUomQuantity,
              secondaryUom:
                order.OrderProductGroups[orderProductIndex].SecondaryUOM?.name,
              conversionFactor
            };

            existing.dispatched += group.quantity / conversionFactor;
            summary[productId] = existing;
          });
        });
      } else {
        summary = processOrderProductGroups(order.OrderProductGroups);
      }

      setOrderSummary(summary);
    };

    const handleLogistic = (order) => {
      const summary = processOrderProductGroups(order.OrderProductGroups);
      setOrderSummary(summary);
    };

    const handleOutbound = (order) => {
      const gdns = order.Shipments.flatMap((s) => s.GDNs).filter(
        (gdn) => !gdn.reversedAt
      );
      let summary = {};

      if (gdns.length > 0) {
        gdns.forEach((gdn) => {
          gdn.GDNGroup.forEach((group) => {
            const productId = group.productId;
            const orderProductIndex = order.OrderProductGroups.findIndex(
              (op) => op.ProductId === productId
            );
            const conversionFactor =
              orderProductIndex !== -1 &&
              order.OrderProductGroups[orderProductIndex]?.secondaryUomId
                ? order.OrderProductGroups[
                    orderProductIndex
                  ].Product?.ProductUOMs?.find(
                    (u) =>
                      u.uomId ===
                      order.OrderProductGroups[orderProductIndex].secondaryUomId
                  )?.conversionFactor
                : 1;
            const existing = summary[productId] || {
              total: group.orderedQuantity,
              productName: group.Product.description,
              uom: order.OrderProductGroups[orderProductIndex].Product.UOM.name,
              sku: order.OrderProductGroups[orderProductIndex].Product.name,
              dispatched: 0,
              sellingPrice: group.sellingPrice,
              tax: group.tax,
              totalRevenue: group.sellingPrice * group.orderedQuantity,
              secondaryUomQuantity:
                order.OrderProductGroups[orderProductIndex]
                  .secondaryUomQuantity,
              secondaryUom:
                order.OrderProductGroups[orderProductIndex].SecondaryUOM?.name,
              conversionFactor
            };

            existing.dispatched += group.quantity / conversionFactor;
            summary[productId] = existing;
          });
        });
      } else {
        summary = processOrderProductGroups(order.OrderProductGroups);
      }

      setOrderSummary(summary);
    };

    const handleOrderSummary = (order) => {
      if (!order?.id) return;
      switch (order.moveType) {
        case 'INWARD':
          handleInward(order);
          break;
        case 'TRANSFER':
          handleTransfer(order);
          break;
        case 'LOGISTIC':
          handleLogistic(order);
          break;
        case 'OUTBOUND':
          handleOutbound(order);
          break;
        default:
          break;
      }
    };
    handleOrderSummary(order);
  }, [order]);

  const LogoText = ({ renderText, src }) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {darkOrganizationLogoFile || src ? (
        <img
          src={src || darkOrganizationLogoFile.location}
          alt="Logo"
          style={{
            width: '100px',
            height: 'auto',
            maxHeight: '80px'
          }}
        />
      ) : (
        <img
          style={{
            width: 'auto',
            height: '18px',
            marginLeft: '8px',
            marginTop: '20px'
          }}
          src={owareLogo}
          alt="Default Logo"
        />
      )}
      <Typography
        variant="h2"
        className={classes.pageSubHeading}
        style={{ marginTop: 3, textAlign: 'center' }}
      >
        {renderText}
      </Typography>
    </div>
  );

  return (
    <>
      <Box
        display="none"
        className={clsx(classes.pageContainer, classes.root)}
        displayPrint="block"
        ref={componentRef}
      >
        <Box
          display="none"
          displayPrint="block"
          className={classes.padding5mm}
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <LogoText renderText={'Shipment Waybill'} />

          <LogoText
            renderText={
              isLinxSubdomain
                ? `${replaceSHPwithWB(shipment?.customId)}`
                : `${shipment?.customId}`
            }
            src={
              organization?.subdomain == 'linxarabia'
                ? 'https://i.imghippo.com/files/vGzg6995aU.png'
                : ''
            }
          />
        </Box>
        <Box
          display="none"
          displayPrint="block"
          className={classes.borderBottom}
        >
          <Grid container spacing={2}>
            {/* Row 1 */}
            <Grid container item xs={12}>
              <Grid item xs={3}>
                <Grid item xs={12} className={classes.label}>
                  Order ID
                </Grid>
                <Grid item xs={12} className={classes.basicFieldValue}>
                  {order?.customId}
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid item xs={12} className={classes.label}>
                  Order Date
                </Grid>
                <Grid item xs={12} className={classes.basicFieldValue}>
                  {formatDate(order?.createdAt)}
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid item xs={12} className={classes.label}>
                  Trip Initiator
                </Grid>
                <Grid item xs={12} className={classes.basicFieldValue}>
                  {shipment?.createdBy?.firstName +
                    ' ' +
                    shipment?.createdBy?.lastName}
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid item xs={12} className={classes.label}>
                  Requestor From Client
                </Grid>
                <Grid item xs={12} className={classes.basicFieldValue}>
                  {order?.pickupNode?.pocName || 'N/A'}
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={3}>
                <Grid item xs={12} className={classes.label}>
                  Shipper Name
                </Grid>
                <Grid item xs={12} className={classes.basicFieldValue}>
                  {order?.Company?.name}
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid item xs={12} className={classes.label}>
                  Consignee Name
                </Grid>
                <Grid item xs={12} className={classes.basicFieldValue}>
                  {order?.dropOffNode?.name || 'N/A'}
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid item xs={12} className={classes.label}>
                  Loading From
                </Grid>
                <Grid item xs={12} className={classes.basicFieldValue}>
                  {order.pickupNode?.address || 'N/A'}
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid item xs={12} className={classes.label}>
                  Loading To
                </Grid>
                <Grid item xs={12} className={classes.basicFieldValue}>
                  {order?.dropOffNode?.address || 'N/A'}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* conditional block of Land , Air & Sea */}

        <Divider className={classes.dividerProperties} />
        <Box
          display="none"
          displayPrint="block"
          className={classes.borderBottom}
        >
          <Grid container spacing={2}>
            {/* Check for Shipment Type */}
            {leg?.mode === 'LAND' ? (
              <>
                {/* Land Shipment Fields */}
                {/* <Typography
                  variant="h4"
                  className={classes.pageSubHeading}
                  style={{
                    marginTop: 16,
                    margingLeft: 46,
                    textAlign: 'center'
                  }}
                >
                  {leg?.Company?.name}
                </Typography> */}
                <Grid container item xs={12}>
                  <Grid item xs={3}>
                    <Grid item xs={12} className={classes.label}>
                      Driver Name
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.Driver?.name || 'N/A'}
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid item xs={12} className={classes.label}>
                      Driver Nationality
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.Driver?.nationality || 'N/A'}
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid item xs={12} className={classes.label}>
                      Driver ID
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.Driver?.cnicNumber || 'N/A'}
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid item xs={12} className={classes.label}>
                      Driver Contact
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.Driver?.phone || 'N/A'}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid item xs={3}>
                    <Grid item xs={12} className={classes.label}>
                      Driver Sponsor
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.Driver?.sponsor || 'N/A'}
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid item xs={12} className={classes.label}>
                      Vehicle Number
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.Vehicle?.registrationNumber || 'N/A'}
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid item xs={12} className={classes.label}>
                      Vehicle Model
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.Car?.CarModel?.name || 'N/A'}
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid item xs={12} className={classes.label}>
                      Vehicle Type
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.Car?.Category?.name || 'N/A'}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid item xs={3}>
                    <Grid item xs={12} className={classes.label}>
                      Vehicle Supplier
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.Vehicle?.supplier || 'N/A'}
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <Grid item xs={12} className={classes.label}>
                      Vehicle Owner
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.Vehicle?.owner || 'N/A'}
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid item xs={12} className={classes.label}>
                      Carrier Name:
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.Company?.name || 'N/A'}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid item xs={3}>
                    <Grid item xs={12} className={classes.label}>
                      Arrival Loading Date
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ marginTop: 3 }}
                      className={classes.basicFieldValue}
                    >
                      {formatDate(leg?.pickupDate) || 'N/A'}
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid item xs={12} className={classes.label}>
                      Arrival Loading Time
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      <div
                        style={{
                          borderBottom: '1px solid black',
                          marginTop: 20,
                          width: '60%'
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid item xs={12} className={classes.label}>
                      Loading Complete Date
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      <div
                        style={{
                          borderBottom: '1px solid black',
                          marginTop: 20,
                          width: '60%'
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid item xs={12} className={classes.label}>
                      Loading Complete Time
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      <div
                        style={{
                          borderBottom: '1px solid black',
                          marginTop: 20,
                          width: '60%'
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : leg?.mode === 'SEA' ? (
              <>
                {/* Sea Shipment Fields */}
                <Grid container item xs={12}>
                  <Grid item xs={4}>
                    <Grid item xs={12} className={classes.label}>
                      Bill of Lading Number (BL)
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.hblNumber || leg?.mblNumber || 'N/A'}
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item xs={12} className={classes.label}>
                      Vessel Name
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.vesselName || 'N/A'}
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item xs={12} className={classes.label}>
                      Port of Loading (POL)
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.portOfLoading || 'N/A'}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid item xs={4}>
                    <Grid item xs={12} className={classes.label}>
                      Port of Discharge (PoD)
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.portOfDischarge || 'N/A'}
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item xs={12} className={classes.label}>
                      Container Number
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.containerNumber || 'N/A'}
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item xs={12} className={classes.label}>
                      Freight Charges
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.Expense?.cost || leg?.freightCharges || 'N/A'}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : leg?.mode === 'AIR' ? (
              <>
                {/* Air Shipment Fields */}
                <Grid container item xs={12}>
                  <Grid item xs={4}>
                    <Grid item xs={12} className={classes.label}>
                      Air Waybill (AWB) Number
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.mblNumber || 'N/A'}
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item xs={12} className={classes.label}>
                      Flight Information
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.flightNumber || 'N/A'}
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item xs={12} className={classes.label}>
                      Departure Airport Code
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.departureAirportCode || 'N/A'}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid item xs={4}>
                    <Grid item xs={12} className={classes.label}>
                      Arrival Airport Code
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.arrivalAirportCode || 'N/A'}
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item xs={12} className={classes.label}>
                      Freight Charges
                    </Grid>
                    <Grid item xs={12} className={classes.basicFieldValue}>
                      {leg?.Expense?.cost || leg?.freightCharges || 'N/A'}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid container item xs={12}>
                <Grid item xs={12} className={classes.basicFieldValue}>
                  No shipment details available.
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>

        {/* Product Details Start */}
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          {order?.OrderProductGroups?.length > 0 && (
            <>
              <Divider className={classes.dividerProperties} />
              <ProductDetail order={order} orderSummary={orderSummary} />
            </>
          )}
        </Grid>

        <Divider
          style={{ marginBottom: '20px' }}
          className={classes.dividerProperties}
        />
        {/* Product Details end */}

        {/* Mandatory Details of waybill */}
        <Box
          display="none"
          displayPrint="block"
          style={{
            border: `1px solid #d3d3d3`,
            padding: '16px',
            marginTop: '16px',
            marginBottom: '16px'
          }}
        >
          <Grid container spacing={2}>
            {/* Row 1 */}
            <Grid container item xs={12} spacing={1} alignItems="center">
              <Grid item xs={6}>
                <Grid container alignItems="center">
                  <Grid item xs={6} className={classes.label}>
                    Arrival at Offloading Point Date:
                    <div
                      style={{
                        borderBottom: '0.5px solid black',
                        width: '50%',
                        marginTop: 3,
                        marginLeft: '70%'
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container alignItems="center">
                  <Grid item xs={6} className={classes.label}>
                    Arrival at Offloading Point Time:
                    <div
                      style={{
                        borderBottom: '0.5px solid black',
                        width: '50%',
                        marginTop: 3,
                        marginLeft: '70%'
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Row 2 */}
            <Grid container item xs={12} spacing={1} alignItems="center">
              <Grid item xs={6}>
                <Grid container alignItems="center">
                  <Grid item xs={6} className={classes.label}>
                    Offloading Complete Date:
                    <div
                      style={{
                        borderBottom: '0.5px solid black',
                        width: '50%',
                        marginTop: 3,
                        marginLeft: '70%'
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container alignItems="center">
                  <Grid item xs={6} className={classes.label}>
                    Offloading Complete Time:
                    <div
                      style={{
                        borderBottom: '0.5px solid black',
                        width: '50%',
                        marginTop: 3,
                        marginLeft: '70%'
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={12} alignItems="center">
              <div
                style={{
                  borderBottom: `1px solid #d3d3d3`,
                  width: '100%',
                  marginTop: '3px'
                }}
              />
            </Grid>

            {/* Row 3 */}
            <Grid
              container
              item
              xs={12}
              spacing={1}
              alignItems="center"
              className={classes.label}
            >
              I received the entire goods in good condition and I am responsible
              for it until the final destination.
            </Grid>
            <Grid container item xs={12} spacing={1} alignItems="center">
              <Grid item xs={6} className={classes.label}>
                Driver Signature:
                <div
                  style={{
                    borderBottom: '1px solid black',
                    width: '25%',
                    marginTop: '3px',
                    marginLeft: '33%'
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Grid container alignItems="center">
                  <Grid item xs={3} className={classes.label}>
                    Date:
                    <div
                      style={{
                        borderBottom: '1px solid black',
                        width: '50%',
                        marginTop: '3px',
                        marginLeft: '30%'
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems="center">
              <div
                style={{
                  borderBottom: `1px solid #d3d3d3`,
                  width: '100%',
                  marginTop: '3px'
                }}
              />
            </Grid>
            {/* Row 4 */}
            <Grid container item xs={12} spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs={6} className={classes.label}>
                    We received the entire cargo complete:
                  </Grid>
                  <Grid item xs={3} className={classes.basicFieldValue}>
                    <label
                      style={{ display: 'inline-flex', alignItems: 'center' }}
                    >
                      Yes:{' '}
                      <input
                        type="checkbox"
                        style={{
                          marginRight: 10,
                          marginLeft: 5,
                          height: '20px',
                          width: '20px',
                          verticalAlign: 'middle'
                        }}
                      />
                    </label>
                    <label
                      style={{ display: 'inline-flex', alignItems: 'center' }}
                    >
                      No:{' '}
                      <input
                        type="checkbox"
                        style={{
                          marginLeft: 5,
                          height: '20px',
                          width: '20px',
                          verticalAlign: 'middle'
                        }}
                      />
                    </label>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Row 5 */}
            <Grid container item xs={12} spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs={6} className={classes.label}>
                    Or we have the following remarks (if any):
                    <div
                      style={{
                        borderBottom: '1px solid black',
                        width: '100%',
                        marginTop: 3,
                        marginLeft: '40%'
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={1} alignItems="center">
              <Grid item xs={6}>
                <Grid container alignItems="center">
                  <Grid item xs={6} className={classes.label}>
                    Receiver Signature:
                    <div
                      style={{
                        borderBottom: '1px solid black',
                        width: '100%',
                        marginTop: 3,
                        marginLeft: 60
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container alignItems="center">
                  <Grid item xs={3} className={classes.label}>
                    Date:
                  </Grid>
                  <Grid item xs={3} className={classes.basicFieldValue}>
                    <div
                      style={{
                        borderBottom: '1px solid black',
                        width: '100%',
                        marginTop: 3,
                        marginLeft: 20
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Grid container item xs={12} alignItems="center">
          <div
            style={{
              height: '22px',
              width: '100%',
              background: `linear-gradient(135deg, ${organization?.primaryColor} 30%, ${organization?.primaryColor} 30%, ${organization?.secondaryColor} 30%)`
            }}
          />
        </Grid>
      </Box>
      <Divider className={classes.dividerProperties} />

      {/* NO changes below this line needed */}
      {isIcon ? (
        <div className="flex gap-3 items-center" onClick={handlePrint}>
          <Print color={'disabled'} />
          <Paragraph>Print Shipment Waybill</Paragraph>
        </div>
      ) : (
        <Button
          color="default"
          variant="contained"
          label={'Print Waybill'}
          onClick={handlePrint}
          icon={<Print fontSize={'small'} className={'mr-2'} />}
        />
      )}
    </>
  );
};

export default PrintDocument;
