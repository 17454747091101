export const constants = {
  TYPES: {
    WAREHOUSING: 'Warehousing',
    LOGISTICS: 'Logistics',
    TECHNOLOGY: 'Technology',
    ADMINISTRATION: 'Administration'
  },
  WAREHOUSING: {
    ON_DEMAND: 'On-Demand',
    CONTRACTUAL: 'Contractual'
  },
  LOGISTICS: {
    SPOT: 'Spot',
    DEDICATED: 'Dedicated'
  },
  TECHNOLOGY: {
    ON_DEMAND: 'On-Demand',
    CONTRACTUAL: 'Contractual'
  },
  ADMINISTRATION: {
    ON_DEMAND: 'On-Demand',
    CONTRACTUAL: 'Contractual'
  },
  STATUSES: {
    PENDING: 'Open',
    OVERDUE: 'Overdue',
    CANCELLED: 'Cancelled',
    PARTIALLY_PAID: 'Partially Paid',
    PAID: 'Paid'
  },
  DRAFT_STATUSES: {
    READY_TO_SEND: 'Ready to Send',
    INCOMPLETE: 'Incomplete'
  },
  CURRENCY: {
    PKR: 'Rs.',
    USD: '$',
    AED: 'Dhs',
    SAR: 'SAR'
  },
  CONTRACT_TYPES: {
    CONTRACTUAL: 'Contractual',
    DEDICATED: 'Dedicated',
    SPOT: 'Spot',
    ON_DEMAND: 'On-demand'
  }
};
