import React from 'react';

const StepConnector = ({ steps, activeStep, changeTab }) => {
  return (
    <div className="step-connector">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`step-container`}
          onClick={() => changeTab(step)}
        >
          <div className={`step ${activeStep === step ? 'active-step' : ''}`}>
            {index + 1}. {step}
          </div>
          {index < steps.length - 1 && (
            <div
              className={`connector-line ${
                activeStep > index ? 'active-line' : ''
              }`}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default StepConnector;
