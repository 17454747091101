/*eslint-disable*/
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import moment from 'moment';
import React, { useContext } from 'react';
import { decimalDisplay, SharedContext } from '../../../utils/common';
import { Label, Paragraph, Title } from '../../../core-components/atoms/Text';

const OrderActivityLogs = ({ logs, moveType }) => {
  const { settings } = useContext(SharedContext);

  return (
    <div className="mt-10 mb-10">
      <TableContainer
        className={'rounded-lg border border-solid border-platinum'}
      >
        <Table
          sx={{
            width: '100%'
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Label variant={'xs'} className={'text-[#67718C]'}>
                  Date & Time
                </Label>
              </TableCell>
              <TableCell>
                <Label variant={'xs'} className={'text-[#67718C]'}>
                  Description
                </Label>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs
              ?.filter(
                (l) =>
                  l.status !== 'IN_TRANSIT' &&
                  l.action !== 'PICKING_TASK_ASSIGNED' &&
                  l.action !== 'LOAD_DISPATCH'
              )
              .map((log, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      <Paragraph variant={'sm'}>
                        {moment(log.createdAt).format(
                          settings?.dateFormat || 'DD/MM/YYYY'
                        )}
                      </Paragraph>
                      <Title variant={'xs'} className="text-[#67718C]">
                        {moment(log.createdAt)
                          .tz(settings?.timezone?.tzCode)
                          .format('hh:mm A')}
                      </Title>
                    </TableCell>
                    <TableCell>
                      <Paragraph variant={'sm'}>
                        {moveType == 'OUTBOUND' && (
                          <OutboundOrderLogDescription log={log} />
                        )}
                        {moveType == 'INWARD' && (
                          <OrderLogDescription log={log} />
                        )}
                        {moveType == 'TRANSFER' && (
                          <TransferLogDescription log={log} />
                        )}
                        {moveType == 'LOGISTIC' && (
                          <LogitsicsLogDescription log={log} />
                        )}
                      </Paragraph>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const LogitsicsLogDescription = ({ log }) => {
  if (!log.shipmentId) {
    return orderLogs(log);
  } else {
    if (log.action != 'LOAD_DISPATCH') {
      return commonShipmentLogs(log, 'LOGISTIC');
    }
    return null;
  }
};

const TransferLogDescription = ({ log }) => {
  if (!log.shipmentId) {
    return orderLogs(log);
  } else {
    if (
      log.action != 'LOAD_DISPATCH' &&
      log.action !== 'GRN_CREATE' &&
      log.action !== 'GDN_CREATE'
    ) {
      return commonShipmentLogs(log);
    } else if (log.action == 'LOAD_DISPATCH') {
      return <p>Shipment Loading Completed</p>;
    } else if (log.action == 'GRN_CREATE') {
      const received = log.payload.receivedQuantity;
      if (log.Order.status != 'COMPLETED')
        return (
          <div>
            <p>
              <span className="font-bold">GRN Completed</span> on Shipment{' '}
              {log.Shipment.customId}. Partial Quantity received
            </p>
            <p>Received Quantity is {received}.</p>
          </div>
        );
      else
        return (
          <div>
            <p>
              <span className="font-bold">GRN Completed</span> on Shipment{' '}
              {log.Shipment.customId}. Full Quantity received
            </p>
            <p>Received Quantity is {received}.</p>
          </div>
        );
    } else if (log.action == 'GDN_CREATE') {
      const dispatched = log.payload.dispatchedQuantity;
      const ordered = log.payload.orderedQuantity;
      if (ordered - dispatched > 0)
        return (
          <div>
            <p>
              <span className="font-bold">GDN Completed</span> on Shipment{' '}
              {log.Shipment.customId}. Partial Quantity dispatched
            </p>
            <p>Dispatched Quantity is {dispatched}.</p>
          </div>
        );
      else
        return (
          <div>
            <p>
              <span className="font-bold">GRN Completed</span> on Shipment{' '}
              {log.Shipment.customId}. Full Quantity dispatched
            </p>
            <p>Dispatched Quantity is {dispatched}.</p>
          </div>
        );
    }
  }

  return null;
};

const OrderLogDescription = ({ log }) => {
  if (!log.shipmentId) {
    return orderLogs(log);
  } else {
    if (log.action != 'GRN_CREATE' && log.action != 'GRN_REVERSE')
      return commonShipmentLogs(log);
    else {
      if (log.action == 'GRN_CREATE') {
        const received = log.payload.receivedQuantity;
        const total = log.payload.orderedQuantity;
        if (total - received > 0)
          return (
            <div>
              <p>
                <span className="font-bold">GRN Completed</span> on Shipment{' '}
                {log.Shipment.customId}. Partial Quantity received
              </p>
              <p>
                Received Quantity is {decimalDisplay(received)}. Remaining
                quantity is {decimalDisplay(total - received)}.
              </p>
            </div>
          );
        else
          return (
            <div>
              <p>
                <span className="font-bold">GRN Completed</span> on Shipment{' '}
                {log.Shipment.customId}. Full Quantity received
              </p>
              <p>
                Received Quantity is {decimalDisplay(received)}. Remaining
                quantity is {decimalDisplay(total - received)}.
              </p>
            </div>
          );
      } else {
        const reversedGrn = log.Shipment.GRNs.filter(
          (g) => g.id == log.payload.grnId
        )[0];
        return (
          <div>
            <span className="font-bold">GRN Reversed</span> on Shipment{' '}
            {log.Shipment.customId} by{' '}
            {reversedGrn.reversedBy?.firstName +
              ' ' +
              reversedGrn.reversedBy?.lastName}
          </div>
        );
      }
    }
  }
};

const orderLogs = (log) => {
  if (log.action == 'CREATE') {
    return (
      <div>
        <p>Order created by {log.User.firstName + ' ' + log.User.lastName}</p>
      </div>
    );
  } else if (log.action == 'PARTIALLY_COMPLETE') {
    return (
      <div>
        <p>
          Order status is <b>Partially Complete</b>
        </p>
      </div>
    );
  } else if (log.action == 'PARTIALLY_COMPLETED_CLOSE') {
    return (
      <div>
        Order status is <b>Closed</b>
      </div>
    );
  } else if (log.action == 'COMPLETE') {
    return (
      <div>
        Order status is <b>Completed</b>
      </div>
    );
  } else if (log.action == 'IN_PROGRESS') {
    return (
      <div>
        Order status is <b>In Progress</b>
      </div>
    );
  } else if (log.action == 'PICKING_IN_PROGRESS') {
    return (
      <div>
        Picking task created By User{' '}
        {log.User.firstName + ' ' + log.User.lastName}. Order status is{' '}
        <b>Picking In Progress</b>
      </div>
    );
  } else if (log.action == 'PICKING_TASK_COMPLETED') {
    return (
      <div>
        Picking task completed By User{' '}
        {log.User.firstName + ' ' + log.User.lastName}
      </div>
    );
  } else if (log.action == 'PICKING_TASK_CANCELLED') {
    return (
      <div>
        Picking task cancelled By User{' '}
        {log.User.firstName + ' ' + log.User.lastName}
      </div>
    );
  }

  return null;
};

const commonShipmentLogs = (log, orderType) => {
  if (log.action == 'CREATE') {
    return (
      <p>
        {' '}
        <span className="font-bold">
          Shipment {log.Shipment.customId}{' '}
        </span>{' '}
        created by {log.User.firstName + ' ' + log.User.lastName}
      </p>
    );
  } else if (log.action == 'CANCEL') {
    return (
      <p>
        {' '}
        <span className="font-bold">
          Shipment {log.Shipment.customId}{' '}
        </span>{' '}
        cancelled by {log.User.firstName + ' ' + log.User.lastName}
      </p>
    );
  } else if (log.action == 'VEHICLE_ASSIGN') {
    return (
      <div className="text-sm">
        <p>
          <span className="font-bold">Vehicle assigned</span> by{' '}
          {log.User.firstName + ' ' + log.User.lastName}
        </p>
        <p>
          Vehicle is{' '}
          {`${log.Shipment?.vehicleType?.CarMake?.name}-${log.Shipment?.vehicleType?.CarModel?.name} ${log.Shipment?.vehicleType?.Category?.name}`}{' '}
          with plate NO {log.Shipment?.vehicleNumber}. Driver is{' '}
          {log.Shipment.driverName} with contact number{' '}
          {log.Shipment?.driverPhone}
        </p>
      </div>
    );
  } else if (log.action == 'DOCK_ASSIGN') {
    return (
      <p>
        <span className="font-bold">Dock assigned</span> on Shipment{' '}
        {log.Shipment?.customId} by{' '}
        {log.User.firstName + ' ' + log.User.lastName}. Dock is{' '}
        {log.Shipment?.WarehouseDock?.name}
      </p>
    );
  } else if (log.action == 'COMPLETE') {
    return (
      <p>
        <span className="font-bold">Shipment {log.Shipment.customId}</span> is
        {orderType == 'OUTBOUND'
          ? ' Delivered'
          : orderType == 'LOGISTIC'
          ? ' Completed'
          : ' Received'}
      </p>
    );
  } else if (log.action == 'LOADING_IN_PROGRESS') {
    return (
      <p>
        <span className="font-bold">Shipment {log.Shipment.customId}</span>{' '}
        status set to Loading in progress
      </p>
    );
  } else if (log.action == 'IN_TRANSIT') {
    return (
      <p>
        <span className="font-bold">Shipment {log.Shipment.customId}</span>{' '}
        status set to In-Transit
      </p>
    );
  } else if (log.action == 'UNLOADING_COMPLETE') {
    return (
      <p>
        <span className="font-bold">Shipment {log.Shipment.customId}</span>{' '}
        status set to Unloaded
      </p>
    );
  } else if (log.action == 'ARRIVED_AT_DESTINATION') {
    return (
      <p>
        <span className="font-bold">Shipment {log.Shipment.customId}</span>{' '}
        status set to Arrived
      </p>
    );
  }

  return '';
};

const OutboundOrderLogDescription = ({ log }) => {
  if (!log.shipmentId) {
    return orderLogs(log);
  } else {
    if (log.action !== 'GDN_CREATE' && log.action != 'GDN_REVERSE')
      return commonShipmentLogs(log, 'OUTBOUND');
    if (log.action == 'GDN_REVERSE') {
      const reversedGdn = log.Shipment.GDNs.filter(
        (g) => g.id == log.payload.gdnId
      )[0];
      return (
        <div>
          <span className="font-bold">GDN Reversed</span> on Shipment{' '}
          {log.Shipment.customId} by{' '}
          {reversedGdn.reversedBy?.firstName +
            ' ' +
            reversedGdn.reversedBy?.lastName}
        </div>
      );
    } else {
      const dispatched = log.payload.dispatchedQuantity;
      const ordered = log.payload.orderedQuantity;
      if (ordered - dispatched > 0)
        return (
          <div>
            <p>
              <span className="font-bold">GDN Completed</span> on Shipment{' '}
              {log.Shipment.customId}. Partial Quantity dispatched
            </p>
            <p>
              Dispatched Quantity is {decimalDisplay(dispatched)}. Remaining
              quantity is {decimalDisplay(ordered - dispatched)}.
            </p>
          </div>
        );
      else
        return (
          <div>
            <p>
              <span className="font-bold">GDN Completed</span> on Shipment{' '}
              {log.Shipment.customId}. Full Quantity dispatched
            </p>
            <p>
              Dispatched Quantity is {decimalDisplay(dispatched)}. Remaining
              quantity is {decimalDisplay(ordered - dispatched)}.
            </p>
          </div>
        );
    }
  }
};

export default OrderActivityLogs;
