/*eslint-disable*/
import API from '../../../libs/axios';

export const getShipments = async (params) => {
  const response = await API.get('orders/shipments', { params });
  return response;
};

export const createShipment = async (orderId, payload) => {
  return API.post(`orders/${orderId}/shipments`, payload);
};

export const updateShipmentService = async (orderId, shipmentId, payload) => {
  return API.put(`orders/${orderId}/shipments/${shipmentId}`, payload);
};

export const updateOrderStatus = async (orderId, status) => {
  return API.put(`orders/${orderId}/status/${status}`);
};

export const cancelShipmentService = async (orderId, shipmentId, payload) => {
  return API.put(`orders/${orderId}/shipments/${shipmentId}/cancel`, payload);
};

export const getOrders = async (search) => {
  return API.get('/orders', { params: { search, shipmentSelection: true } });
};

export const getShipmentsExport = async (params) => {
  return API.get(
    '/orders/shipments/export',
    { responseType: 'blob' },
    { params }
  );
};

export const getShipmentStats = async (payload) => {
  return API.get('/orders/shipments/stats', { params: { ...payload } });
};

export const getCarriers = async (search) => {
  return API.get('companies', { params: { type: 'VENDOR', search } });
};

export const getDrivers = async (search) => {
  return API.get('drivers', { params: { search } });
};

export const getVehicles = async (search) => {
  return API.get('vehicles', { params: { search } });
};
