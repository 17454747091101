import Button from '../../../core-components/atoms/Button';
import SearchBar from '../../../atomicComponents/SearchBar';
import { makeStyles } from '@material-ui/core';
// import API from '../../../libs/axios';
import React, { useContext } from 'react';
import PopupButton from '../../../core-components/molecules/PopupButton';
import { List, ListItem } from '@mui/material';
import BulkFileAttacher from '../../productMaster/ListViews/components/BulkFileAttacher';
import BulkIcon from '../../../assets/icons/blue-bulk-upload.svg';
import downloadFileIcon from '../../../assets/icons/templateDownload.svg';
import template from '../../../assets/files/bulkCarrierScheduleTemplate.xlsx';
import { SharedContext } from '../../../utils/common';

const useStyles = makeStyles(() => ({
  searchBar: {
    maxWidth: '188px',
    height: '32px',
    border: '1px solid #67718C',
    borderRadius: '4px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '100%',
    fontSize: '12px',
    '& input::placeholder': {
      fontSize: '12px'
    }
  }
}));

const Header = ({
  searchKeyword,
  setSearchKeyword,
  headerText,
  addBtnLabel,
  onClick,
  searchPlaceHolder,
  filterComponent,
  bulkBtnOpen,
  setBulkBtnOpen,
  fileUpload,
  selectedFile,
  setSelectedFile,
  tab
}) => {
  const classes = useStyles();

  const { subdomain } = useContext(SharedContext);

  return (
    <div>
      <div className="w-full flex justify-between">
        <div className="flex items-center gap-2">
          <p className="text-lg text-primaryBlue font-semibold">{headerText}</p>
        </div>
        <div className="flex items-center">
          <SearchBar
            className={classes.searchBar}
            iconClass="h-4 w-4"
            setSearchValue={setSearchKeyword}
            searchKeyword={searchKeyword}
            placeholder={searchPlaceHolder || 'Search'}
          />
          {filterComponent}
          <Button
            label={addBtnLabel}
            className={'py-2 px-3 ml-2 h-8 rounded border-solid'}
            variant="primary"
            overrideSize={true}
            labelClass="font-medium text-xs "
            onClick={onClick}
          />
          {subdomain === 'coc' && tab === 'carrier' && (
            <PopupButton
              open={bulkBtnOpen}
              setOpen={setBulkBtnOpen}
              btn={
                <Button
                  label="Upload carrier schedules"
                  onClick={(e) => {
                    e.preventDefault();
                    setBulkBtnOpen(!bulkBtnOpen);
                  }}
                  className={'py-2 px-3 ml-2 h-8 rounded border-solid'}
                  variant="transparent"
                  icon={<img src={BulkIcon} className={`mr-[2px]`} />}
                />
              }
              content={
                <List className="py-4">
                  <ListItem className="flex gap-2 items-center hover:bg-oasisPrimary100 cursor-pointer">
                    <a href={template} className="flex gap-2">
                      <img src={downloadFileIcon} className={`mr-[2px]`} />
                      <span>Download Template</span>
                    </a>
                  </ListItem>
                  <BulkFileAttacher
                    bulkUpload={(vals) => fileUpload(vals)}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                  />
                </List>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
