import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Grid, Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import FormikTextField from '../../../core-components/molecules/FormikTextField';
import FormikAutocomplete from '../../../core-components/molecules/FormikAutocomplete';
import addIcon from '../../../assets/icons/add-icon-light.svg';
import placement from '../../../assets/icons/img_placement.png';
import { upload } from '../../../utils/upload';
import { toaster } from '../../../utils/toaster';
import { onError } from '../../../libs/errorLib';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { NumericFormat } from 'react-number-format';
import CustomTextField from '../../../core-components/atoms/TextField';
import { UploadButton } from './component/UploadButton';
import { LoadingIndicator } from './component/LoadingIndicator';
import { FileDisplay } from './component/FileDisplay';
import { UploadedFile } from './component/UploadedFile';
import TextField from '../../../core-components/atoms/TextField';

const ExpenseForm = ({
  expense,
  removeExpense,
  expenses,
  setExpenses,
  order,
  index,
  shipment,
  deletePermission,
  handleSearchInputChange,
  options
}) => {
  const uploadRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState();
  const [files, setFiles] = useState([]);
  const [types, setTypes] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: expense?.name,
      cost: expense?.cost,
      fileIds: [],
      isBillable: expense?.isBillable || false,
      shipmentId: expense?.shipmentId,
      sourceType: expense?.sourceType?.toUpperCase(),
      sourceId: expense?.sourceId,
      shipment: {
        name: expense?.Shipment?.customId || 'Miscellaneous',
        id: expense?.shipmentId || 0
      }
    }
  });
  const sxProps = {
    '& .MuiOutlinedInput-input': {
      padding: '11px 14px !important'
    },
    '& .MuiInputBase-root': {
      fontSize: '14px'
    },
    '& .MuiAutocomplete-input': {
      padding: '0px 4px 0px 6px !important'
    },
    '& input::placeholder': {
      fontSize: '14px'
    },
    '& textarea::placeholder': {
      fontSize: '14px'
    }
  };

  useEffect(() => {
    if (types.length <= 0) {
      const shipments =
        order?.Shipments?.map((val) => {
          return { name: 'Shipment ' + val?.customId, id: val?.id };
        }) || [];
      setTypes([{ name: 'Miscellaneous', id: 0 }, ...shipments]);
    }
  }, [types]);

  useEffect(() => {
    if (selectedFile) handleFileUpload(selectedFile);
  }, [selectedFile]);

  useEffect(() => {
    if (formik?.values) {
      formik.setFieldValue('fileIds', files);
      const newArr = expenses.map((item, idx) => {
        if (idx === index) {
          const updatedItem = { ...item };
          Object.keys(formik.values).forEach((key) => {
            updatedItem[key] = formik.values[key];
          });
          return updatedItem;
        }
        return item;
      });
      setExpenses(newArr);
    }
  }, [formik?.values, files]);

  const handleFileUpload = async (e) => {
    setFileLoading(true);
    const files = e.target.files;
    const filesArr = [];
    for (let file of files) {
      filesArr.push(file);
    }
    try {
      let uploadedData = await upload(
        filesArr.filter((file) => !file?.id),
        'orders'
      );
      if (uploadedData) {
        setFiles((prevFiles) => [...prevFiles, ...uploadedData]);
      } else {
        toaster('error', 'File not uploaded');
      }
    } catch (err) {
      onError(err);
    } finally {
      setFileLoading(false);
    }
  };

  function openFileInNewTab(url) {
    const newTab = window.open(url, '_blank');
    newTab.focus();
  }

  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className={'display-flex mt-5 justify-between'}
      >
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <FormikTextField
              name="name"
              size="small"
              disabled={expense?.shipmentLegId}
              formik={formik}
              placeholder="Add Expense"
              type="text"
              sxProps={sxProps}
            />
          </Grid>
          {!shipment && (
            <Grid item xs={2}>
              <FormikAutocomplete
                name="shipment"
                formik={formik}
                options={types}
                onChange={async (e, value) => {
                  formik.setFieldValue('shipmentId', value);
                }}
                value={
                  formik.values?.shipmentId
                    ? formik.values?.shipmentId
                    : {
                        id:
                          expense?.shipmentId === null ||
                          expenses?.shipmentId === 0 ||
                          expenses?.shipmentId === '0' ||
                          expenses?.shipmentId === ''
                            ? null
                            : expenses?.shipmentId,
                        name:
                          expense?.shipmentId === null ||
                          expenses?.shipmentId === 0 ||
                          expenses?.shipmentId === '0' ||
                          expenses?.shipmentId === ''
                            ? 'Miscellaneous'
                            : `Shipment ${expense?.Shipment?.customId}`
                      }
                }
                placeholder="Select Type"
                getOptionLabel={(option) => option.name || ''}
              />
            </Grid>
          )}
          <Grid item xs={2}>
            <NumericFormat
              value={formik?.values?.cost}
              customInput={TextField}
              size="small"
              variant="outlined"
              onValueChange={(e) => {
                if (parseFloat(e.value) >= 0) {
                  formik.setFieldValue('cost', parseFloat(e.value));
                }
              }}
              thousandSeparator=","
              decimalSeparator="."
              placeholder="-"
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              name="supplier"
              options={options}
              groupBy={(option) => option.category}
              getOptionLabel={(option) => option.label || ''}
              value={
                options.find(
                  (option) => option.id === formik.values.sourceId
                ) || null
              }
              onInputChange={handleSearchInputChange}
              disableClearable={true}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  placeholder="Select Supplier"
                  size="small"
                  InputProps={{
                    ...params.InputProps
                  }}
                  disabled={expense.billGenerated}
                />
              )}
              onChange={async (e, value) => {
                if (value && value.id !== formik.values.sourceId) {
                  formik.setFieldValue('sourceId', value?.id);
                  formik.setFieldValue(
                    'sourceType',
                    value?.category === 'Organization'
                      ? 'ORGANIZATION'
                      : value?.category === 'Carrier'
                      ? 'COMPANY'
                      : 'VENDOR'
                  );
                }
              }}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
            />
          </Grid>

          <Grid item xs={1}>
            <Checkbox
              checked={formik.values?.isBillable}
              onChange={(e, checked) => {
                formik.setFieldValue('isBillable', checked);
              }}
              disabled={expense.billGenerated}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
              name={'billable'}
            />
          </Grid>
          <Grid item xs={1}>
            <div className="flex">
              {/* Upload Button */}
              <UploadButton
                isBillGenerated={expense.billGenerated}
                uploadRef={uploadRef}
                addIcon={addIcon}
              />

              {/* Loading Indicator */}
              {fileLoading && <LoadingIndicator />}

              {/* Expense Files */}
              {expense.Files?.slice(0, 2).map((file, index) => (
                <FileDisplay
                  key={index}
                  file={file}
                  openFileInNewTab={openFileInNewTab}
                />
              ))}

              {/* Uploaded Files */}
              {files?.slice(0, 2).map((file, index) => (
                <UploadedFile
                  key={index}
                  file={file}
                  files={files}
                  setFiles={setFiles}
                  placementIcon={placement}
                />
              ))}
            </div>
          </Grid>
          <Grid item xs={1}>
            {(expense?.id || index !== 0) &&
              !expense?.shipmentLegId &&
              deletePermission && (
                <DeleteIcon
                  fontSize="small"
                  onClick={() => {
                    removeExpense(index);
                  }}
                />
              )}
          </Grid>
        </Grid>
      </form>
      <input
        ref={uploadRef}
        hidden
        type="file"
        multiple
        onClick={(e) => {
          setSelectedFile(null);
          e.target.value = null;
        }}
        onChange={(e) => {
          setSelectedFile(e);
        }}
        accept=".jpg,.png,.jpeg,.pdf,.csv,.xlsx,.xls,.doc,.docx,.txt"
      />
    </div>
  );
};

export default ExpenseForm;
