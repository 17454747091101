export const icons = {
  IN_PROGRESS: 'h-2.5 w-2.5 bg-inProgress rounded-full',
  IN_TRANSIT: 'h-2.5 w-2.5 bg-inProgress rounded-full',
  CREATED: 'h-2.5 w-2.5 bg-inProgress rounded-full',
  PENDING: 'h-2.5 w-2.5 bg-pending rounded-full',
  COMPLETED: 'w-2.5 h-2.5 bg-completed rounded-full',
  PICKING_IN_PROGRESS: 'w-2.5 h-2.5 bg-blue rounded-full',
  PUTAWAY_IN_PROGRESS: 'w-2.5 h-2.5 bg-blue rounded-full',
  LOADING_COMPLETE: 'w-2.5 h-2.5 bg-completed rounded-full',
  ASSIGNED: 'h-2.5 w-2.5 bg-inProgress rounded-full',
  LOADING_IN_PROGRESS: 'h-2.5 w-2.5 bg-blue rounded-full',
  ON_THE_WAY_DESTINATION: 'h-2.5 w-2.5 bg-inProgress rounded-full',
  ARRIVED_AT_DESTINATION: 'h-2.5 w-2.5 bg-inProgress rounded-full',
  UNLOADING_IN_PROGRESS: 'h-2.5 w-2.5 bg-blue rounded-full',
  UNLOADING_COMPLETE: 'w-2.5 h-2.5 bg-completed rounded-full',
  CANCELLED: 'w-2.5 h-2.5 bg-[#870000] rounded-full',
  DRAFT: 'w-2.5 h-2.5 bg-[#696F80] rounded-full',
  PARTIALLY_COMPLETED_CLOSED:
    'w-2.5 h-2.5 bg-white rounded-full border border-solid border-[#000]'
};
