/* eslint-disable */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { NumericFormat } from 'react-number-format';
import Autocomplete from '../../core-components/atoms/Autocomplete';
import {
  SharedContext,
  decimalDisplay,
  productLabelFormat
} from '../../utils/common';
import API from '../../libs/axios';
import { onError } from '../../libs/errorLib';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../Config';
import TextField from '../../core-components/atoms/TextField';
import Button from '../../core-components/atoms/Button';
import { FormControl, Paper, TableContainer } from '@mui/material';
import Delete from '../../assets/icons/delete.svg';
import EditIcon from '../../assets/icons/editIconDark.svg';
import { useEffect } from 'react';
import clsx from 'clsx';
import { useRef } from 'react';
import { checkPermission } from '../../utils/auth';
import { useContext } from 'react';
import { ORGANIZATION_TYPES } from '../../constants';
import { PRIMARY } from '../../constants/colors';
import Popup from '../../core-components/atoms/Popup';
import UomConversion from './components/UomConversion';
import { Label } from '../../core-components/atoms/Text';

const ProductSelect = ({
  companyId,
  productIds,
  warehouseId,
  onChange,
  value,
  refetch,
  setRefetch,
  orderType
}) => {
  const [products, setProducts] = React.useState([]);
  const { setAPILoader, organizationType } = React.useContext(SharedContext);

  const getProducts = async (search = '') => {
    try {
      setAPILoader(true);
      const columns = ['name', 'description', 'barcode', 'perPallet'];

      const filters = {
        colVal: {
          isActive: '1'
        }
      };
      let company_id = companyId;

      if (organizationType === ORGANIZATION_TYPES.MANUFACTURER) {
        company_id = null;
      }

      const res = await API.get(`products`, {
        params: {
          search,
          companyId: company_id,
          warehouseId,
          filters,
          columns,
          excludeExtraInclusions: true
        }
      });
      const filteredProducts = res?.data;
      setProducts(filteredProducts || []);
    } catch (error) {
      onError(error);
    } finally {
      setAPILoader(false);
    }
  };

  const _getProducts = React.useCallback(
    debounce((productSearchKeyword) => {
      getProducts(productSearchKeyword);
    }, DEBOUNCE_CONST),
    [companyId, warehouseId]
  );

  React.useEffect(() => {
    if (companyId || organizationType == 'MANUFACTURER') {
      getProducts();
    }
  }, [companyId, warehouseId]);

  useEffect(() => {
    if (refetch) {
      getProducts();
      setRefetch(false);
    }
  }, [refetch]);

  const options = products.filter((p) => productIds.indexOf(p.id) === -1);

  return (
    <Autocomplete
      options={options}
      onChange={onChange}
      value={value}
      placeholder="Select Product"
      isOptionEqualToValue={(option, value) =>
        option.product?.id === value.product?.id
      }
      onInputChange={(_, newValue, reason) => {
        if (
          reason === 'input' &&
          (companyId || organizationType === ORGANIZATION_TYPES.MANUFACTURER)
        ) {
          if (newValue === '-') {
            _getProducts('');
          } else {
            _getProducts(newValue);
          }
        }
      }}
      getOptionLabel={(option) => productLabelFormat(option)}
      hover
      sxProps={{
        '& input::placeholder': {
          fontSize: '14px'
        },
        '& input': {
          fontSize: '14px',
          paddingTop: '10px',
          paddingBottom: '10px',
          height: '20px'
        }
      }}
    />
  );
};

export const ProductRow = ({
  availableQuantity,
  productIds,
  value,
  companyId,
  onChange,
  uom,
  prevOrderedQuantity,
  orderedQuantity,
  updateOrderedQuantity,
  summary,
  removeProduct,
  costPrice,
  updateCostPrice,
  sellingPrice,
  updateSellingPrice,
  tax,
  updateTaxAmount,
  totalCost,
  updateTotalCost,
  totalRevenue,
  updateTotalRevenue,
  showCostPrice,
  orderType,
  showSellingPrice,
  inventoryLoading,
  isLogistic,
  warehouseId,
  uoms,
  onUoMChange,
  primaryUomValue
}) => {
  const { organization } = useContext(SharedContext);
  const [uomView, setUomView] = React.useState(false);
  const [secondaryUoms, setSecondaryUoms] = React.useState(
    uoms?.filter((uom) => !uom.primary) || []
  );
  const [refetch, setRefetch] = React.useState(false);

  const { setAPILoader } = useContext(SharedContext);

  useEffect(() => {
    if (uoms?.length && secondaryUoms?.length == 0) {
      setSecondaryUoms(uoms?.filter((uom) => !uom.primary));
    }
  }, [uoms]);

  const handleUomCreation = async (uomId, uoms) => {
    setAPILoader(true);
    try {
      await API.put(`products/${value.id}`, {
        uoms,
        isActive: value?.isActive,
        batchEnabled: value?.batchEnabled
      });
      setRefetch(true);
      setUomView(false);
    } catch (e) {
    } finally {
      setAPILoader(false);
    }
  };

  return (
    <>
      <TableRow
        sx={{
          '& td, & th': {
            borderBottom: 'none', // Removes the bottom border of the row,
            paddingBottom: 0
          }
        }}
      >
        <TableCell
          component="th"
          scope="row"
          className="w-full lg:w-auto"
          style={{ width: '21%' }}
        >
          {summary || orderType === 'Sales Return' ? (
            <span className="truncate w-[180px] block">
              {productLabelFormat(value)}
            </span>
          ) : (
            <ProductSelect
              productIds={productIds}
              value={value}
              companyId={companyId}
              onChange={onChange}
              disabled={inventoryLoading}
              warehouseId={warehouseId}
              onUoMChange={onUoMChange}
              refetch={refetch}
              setRefetch={setRefetch}
              orderType={orderType}
            />
          )}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          className="w-full lg:w-auto"
          style={{ width: '15%' }}
        >
          {summary ? (
            uom?.name
          ) : (
            <Autocomplete
              options={
                uoms.length > 0
                  ? [
                      ...uoms,
                      ...(!summary && organization?.customUOM
                        ? [{ name: '+ Create UoM', type: 'add' }]
                        : [])
                    ]
                  : []
              }
              onChange={(e, value) => {
                if (value.name == '+ Create Secondary UoM') {
                } else onUoMChange(value);
              }}
              value={uom}
              placeholder="UoM"
              getOptionLabel={(option) => option.name || ''}
              hover
              sxProps={{
                '& input::placeholder': {
                  fontSize: '14px'
                },
                '& input': {
                  fontSize: '14px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  height: '20px'
                }
              }}
              renderOption={(props, option) => {
                // eslint-disable-next-line no-unused-vars
                const { className, ...rest } = props;
                return option?.type == 'add' ? (
                  <li
                    className="auto-complete-option"
                    style={{
                      color: PRIMARY,
                      fontSize: '16px',
                      fontWeight: 500
                    }}
                    onClick={() => {
                      setUomView(true);
                    }}
                  >
                    {option.name}
                  </li>
                ) : (
                  <li {...rest} className="auto-complete-option-small">
                    <span>
                      {`${option.name} `}
                      {!option?.primary &&
                        `- ${option.conversionFactor} ${
                          uoms?.find((value) => value.primary)?.name
                        }`}
                      <span className="text-[10px] ml-1">
                        {option?.primary && '(primary)'}
                      </span>
                    </span>
                  </li>
                );
              }}
            />
          )}
        </TableCell>
        {orderType !== 'Sales Return' && (
          <TableCell
            component="th"
            scope="row"
            className="number-input-container w-full lg:w-auto"
            style={{ width: '15%' }}
          >
            {summary ? (
              orderedQuantity
            ) : (
              <NumericFormat
                value={orderedQuantity}
                error={!orderedQuantity || orderedQuantity < 0}
                customInput={TextField}
                onValueChange={(e) => {
                  if (e.value >= 0) {
                    updateOrderedQuantity(parseFloat(e.value));
                    if (orderType === 'Purchase' && costPrice) {
                      const cost = e.value * costPrice;
                      const newTotalCost = cost + cost * ((tax || 0) / 100);
                      updateTotalCost(newTotalCost);
                    }
                    if (orderType === 'Sales' && sellingPrice) {
                      const cost = e.value * sellingPrice;
                      const newTotalRevenue = cost + cost * ((tax || 0) / 100);
                      updateTotalRevenue(newTotalRevenue);
                    }
                  }
                }}
                thousandSeparator=","
                decimalSeparator="."
                placeholder="-"
              />
            )}
          </TableCell>
        )}
        {orderType === 'Sales Return' && (
          <>
            <TableCell
              component="th"
              scope="row"
              className="w-full lg:w-auto"
              style={{ width: '10%' }}
            >
              {prevOrderedQuantity}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              style={{ width: '15%' }}
              className="number-input-container w-full lg:w-auto"
            >
              <TextField
                type="number"
                value={orderedQuantity}
                onChange={(e) => {
                  if (
                    e.target.value >= 0 &&
                    e.target.value <= prevOrderedQuantity
                  ) {
                    updateOrderedQuantity(e.target.value);
                    const cost = e.target.value * sellingPrice;
                    const newTotalRevenue = cost + cost * ((tax || 0) / 100);
                    updateTotalRevenue(newTotalRevenue);
                  } else {
                    e.target.value = prevOrderedQuantity;
                  }
                }}
                placeholder="-"
              />
            </TableCell>
          </>
        )}

        {orderType !== 'Sales Return' && !isLogistic && (
          <TableCell
            component="th"
            scope="row"
            className="w-full lg:w-auto"
            style={{ width: '10%' }}
          >
            <p className={`${uom && availableQuantity == 0 && 'text-error'}`}>
              {decimalDisplay(availableQuantity)}
            </p>
          </TableCell>
        )}
        {showCostPrice && (
          <TableCell
            component="th"
            scope="row"
            className="number-input-container w-full lg:w-auto"
            style={{ width: '15%' }}
          >
            {summary ? (
              costPrice
            ) : (
              <TextField
                type="number"
                error={costPrice !== null || costPrice < 0}
                disabled={orderType === 'Sales Return'}
                value={costPrice?.toString()}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    updateCostPrice(e.target.value);
                    if (orderedQuantity) {
                      const cost = orderedQuantity * e.target.value;
                      const newTotalCost = cost + cost * ((tax || 0) / 100);
                      updateTotalCost(newTotalCost);
                    }
                  }
                }}
                placeholder="-"
              />
            )}
          </TableCell>
        )}
        {showSellingPrice && (
          <TableCell
            component="th"
            scope="row"
            style={{ width: '15%' }}
            className="number-input-container w-full lg:w-auto"
          >
            {summary ? (
              sellingPrice
            ) : (
              <TextField
                type="number"
                value={sellingPrice?.toString()}
                error={sellingPrice !== null || sellingPrice < 0}
                disabled={orderType === 'Sales Return'}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    updateSellingPrice(e.target.value);
                    if (orderedQuantity) {
                      const cost = orderedQuantity * e.target.value;
                      const newTotalRevenue = cost + cost * ((tax || 0) / 100);
                      updateTotalRevenue(newTotalRevenue);
                    }
                  }
                }}
                placeholder="-"
              />
            )}
          </TableCell>
        )}
        {(showCostPrice || showSellingPrice) && (
          <>
            <TableCell
              component="th"
              scope="row"
              style={{ width: '10%' }}
              className="number-input-container w-full lg:w-auto"
            >
              {summary ? (
                tax
              ) : (
                <TextField
                  type="number"
                  value={tax}
                  disabled={orderType === 'Sales Return'}
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      updateTaxAmount(e.target.value);
                      if (
                        orderType === 'Purchase' &&
                        orderedQuantity &&
                        costPrice
                      ) {
                        const cost = orderedQuantity * costPrice;
                        const newTotalCost =
                          cost + cost * (e.target.value / 100);
                        updateTotalCost(newTotalCost);
                      }
                      if (
                        orderType === 'Sales' &&
                        orderedQuantity &&
                        sellingPrice
                      ) {
                        const cost = orderedQuantity * sellingPrice;
                        const newTotalRevenue =
                          cost + cost * (e.target.value / 100);
                        updateTotalRevenue(newTotalRevenue);
                      }
                    }
                  }}
                  placeholder="-"
                />
              )}
            </TableCell>
          </>
        )}
        {showCostPrice && (
          <TableCell
            style={{ maxWidth: '5%' }}
            component="th"
            scope="row"
            className="w-full lg:w-auto"
          >
            <p>{decimalDisplay(totalCost) || '-'}</p>
          </TableCell>
        )}
        {showSellingPrice && (
          <TableCell
            style={{ maxWidth: '5%' }}
            component="th"
            scope="row"
            className="w-full lg:w-auto"
          >
            <p>{decimalDisplay(totalRevenue) || '-'}</p>
          </TableCell>
        )}
        {!summary && (
          <TableCell style={{ maxWidth: '8%' }} component="th" scope="row">
            <img
              src={Delete}
              onClick={removeProduct}
              className="cursor-pointer inline-block"
              alt="delete"
            />
          </TableCell>
        )}

        <Popup
          open={uomView}
          setOpen={setUomView}
          title={'Unit of Measure'}
          content={
            <div className="mt-4">
              <UomConversion
                primaryUom={primaryUomValue}
                secondaryUoms={secondaryUoms}
                setSecondaryUoms={setSecondaryUoms}
              />
            </div>
          }
          actions={
            <div className="flex gap-2">
              <Button
                label="Cancel"
                variant="transparent"
                onClick={() => {
                  setUomView(false);
                  setSecondaryUoms(uoms?.filter((uom) => !uom.primary));
                }}
              />
              <Button
                label="Save"
                variant="primary"
                onClick={async () => {
                  await handleUomCreation({}, secondaryUoms);
                }}
              />
            </div>
          }
        />
      </TableRow>
      {value.hasOwnProperty('name') && (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell
            colSpan={9}
            sx={{
              '& td, & th': {
                padding: 0,
                borderBottom: 'none'
              }
            }}
          >
            <Label variant={'xs'}>{value?.name + value.description}</Label>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default function ProductTable({
  formik,
  summary,
  inventoryLoading,
  isLogistic,
  warehouseId,
  _getProducts
}) {
  let productIds = formik.values.products?.map((p) => p.product?.id) || [];

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [showWeightPopup, setWeightPopup] = React.useState(false);
  const [calculatedWeight, setCalculatedWeight] = React.useState(
    formik.values?.calculatedWeight || 0
  );
  const [calculatedWeightTemp, setCalculatedWeightTemp] = React.useState(0);
  const [useInitialWeight, setUseInitialWeight] = React.useState(true);
  const { currentUser, organizationType, settings } = useContext(SharedContext);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        showWeightPopup
      ) {
        setWeightPopup(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showWeightPopup]);

  useEffect(() => {
    if (
      formik.values?.products?.length > 0 &&
      !summary &&
      !(formik.values?.calculatedWeight && useInitialWeight)
    ) {
      const weight = formik.values.products?.reduce(
        (a, b) =>
          +a +
          (+b?.product?.weight || 0) *
            (+b?.orderedQuantity * (b?.uom?.conversionFactor || 1) || 0),
        0
      );
      setCalculatedWeight(weight);
      formik.setFieldValue(`calculatedWeight`, weight);
    } else if (formik.values?.calculatedWeight && useInitialWeight) {
      setCalculatedWeight(formik.values?.calculatedWeight);
      setUseInitialWeight(false);
    }
  }, [formik.values?.products]);

  const totalTax =
    formik.values.products?.length > 0
      ? decimalDisplay(
          formik.values.products.reduce(
            (a, b) =>
              +a +
              ((+b?.tax *
                +b?.orderedQuantity *
                (+b?.sellingPrice || +b?.costPrice)) /
                100 || 0),
            0
          )
        )
      : 0;

  return (
    <div className="min-w-[850px] w-full flex flex-col">
      <TableContainer
        component={Paper}
        className="mt-6 w-full"
        sx={{
          borderRadius: '16px',
          // overflow: 'hidden',
          border: '1px solid #E7E6E6 !important',
          outline: 'none !important'
          // '& .MuiTable-root': {
          //   borderCollapse: 'unset'
          // }
        }}
        elevation={0}
      >
        <Table sx={{ border: 'none' }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={isLogistic ? { width: '35%' } : { opacity: 1 }}
                className="font-medium text-[#67718C] text-xs whitespace-nowrap"
              >
                SKU CODE & NAME
              </TableCell>
              <TableCell
                className="font-medium text-[#67718C] text-xs whitespace-nowrap"
                style={isLogistic ? { width: '13%' } : { opacity: 1 }}
              >
                UoM
              </TableCell>
              <TableCell
                className="font-medium text-[#67718C] text-xs whitespace-nowrap"
                style={isLogistic ? { width: '15%' } : { opacity: 1 }}
              >
                ORDER QTY
              </TableCell>
              {!isLogistic && (
                <TableCell className="font-medium text-[#67718C] text-xs whitespace-nowrap">
                  {formik.values?.orderType.value === 'Sales Return' &&
                  checkPermission(currentUser, 'OPS_SELLING_PRICE_VISIBILITY')
                    ? 'RETURN QTY'
                    : 'AVAILABLE QTY'}
                </TableCell>
              )}
              {formik.values?.orderType.value === 'Purchase' &&
                checkPermission(currentUser, 'OPS_COST_PRICE_VISIBILITY') && (
                  <>
                    <TableCell
                      className="font-medium text-[#67718C] text-xs whitespace-nowrap"
                      sx={{ width: '20%' }}
                    >
                      PRICE
                    </TableCell>
                    <TableCell
                      className="font-medium text-[#67718C] text-xs whitespace-nowrap"
                      sx={{ width: '10%' }}
                    >
                      TAX %
                    </TableCell>
                    <TableCell className="font-medium text-[#67718C] text-xs whitespace-nowrap">
                      TOTAL COST
                    </TableCell>
                  </>
                )}
              {formik.values?.orderType.value === 'Sales' &&
                checkPermission(
                  currentUser,
                  'OPS_SELLING_PRICE_VISIBILITY'
                ) && (
                  <>
                    <TableCell
                      className="font-medium text-[#67718C] text-xs whitespace-nowrap"
                      sx={{ width: '20%' }}
                    >
                      PRICE
                    </TableCell>
                    <TableCell
                      className="font-medium text-[#67718C] text-xs whitespace-nowrap"
                      sx={{ width: '10%' }}
                    >
                      TAX %
                    </TableCell>
                    <TableCell className="font-medium text-[#67718C] text-xs whitespace-nowrap">
                      TOTAL REVENUE
                    </TableCell>
                  </>
                )}
              {formik.values?.orderType.value === 'Sales Return' &&
                checkPermission(
                  currentUser,
                  'OPS_SELLING_PRICE_VISIBILITY'
                ) && (
                  <>
                    <TableCell
                      className="font-medium text-[#67718C] text-xs whitespace-nowrap"
                      sx={{ width: '20%' }}
                    >
                      PRICE
                    </TableCell>
                    <TableCell
                      className="font-medium text-[#67718C] text-xs whitespace-nowrap"
                      sx={{ width: '10%' }}
                    >
                      TAX %
                    </TableCell>
                    <TableCell className="font-medium text-[#67718C] text-xs whitespace-nowrap">
                      TOTAL RETURN VALUE
                    </TableCell>
                  </>
                )}
              <TableCell
                sx={{ width: '5%' }}
                className="font-medium text-[#67718C] text-xs"
              >
                {' '}
                &nbsp;
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formik.values.products?.map((row, i) => {
              let availableQuantity = row?.product?.Inventories?.reduce(
                (a, b) => a + b.availableQuantity,
                0
              );

              return (
                <ProductRow
                  productIds={productIds}
                  value={formik.values.products?.[i]?.product || {}}
                  companyId={formik.values.company?.id}
                  refetch={_getProducts}
                  onChange={(e, v) => {
                    formik.setFieldValue(`products.${i}.product`, {
                      ...v
                    });
                    if (formik.values.products?.[i]?.uom?.id) {
                      formik.setFieldValue(
                        `products.${i}.uom`,
                        formik.values.products?.[i]?.uom
                      );
                    } else {
                      formik.setFieldValue(`products.${i}.uom`, {
                        ...v?.UOM,
                        conversionFactor: 1,
                        primary: true
                      });
                    }
                  }}
                  uom={formik.values.products?.[i]?.uom || []}
                  onUoMChange={(v) => {
                    let orderedQuantity =
                      formik.values.products?.[i]?.orderedQuantity;
                    if (orderedQuantity) {
                      orderedQuantity =
                        orderedQuantity *
                        (formik.values.products?.[i].uom?.conversionFactor ||
                          1);
                      orderedQuantity = orderedQuantity / v.conversionFactor;
                    }
                    formik.setFieldValue(`products.${i}.uom`, v);
                    if (orderedQuantity) {
                      formik.setFieldValue(
                        `products.${i}.orderedQuantity`,
                        orderedQuantity
                      );
                    }
                  }}
                  primaryUomValue={
                    {
                      ...formik.values.products?.[i]?.product?.UOM,
                      primary: true,
                      conversionFactor: 1
                    } || {}
                  }
                  uoms={
                    formik.values.products?.[i]?.product?.id
                      ? [
                          {
                            ...formik.values.products?.[i]?.product?.UOM,
                            conversionFactor: 1,
                            primary: true
                          },
                          ...(formik.values.products?.[
                            i
                          ]?.product?.ProductUOMs?.map((uom) => ({
                            name: uom?.UOM?.name,
                            conversionFactor: uom?.conversionFactor,
                            primary: false,
                            uomId: uom?.uomId
                          })) || [])
                        ]
                      : []
                  }
                  availableQuantity={
                    availableQuantity /
                    (formik.values.products?.[i]?.uom?.conversionFactor || 1)
                  }
                  inventoryLoading={inventoryLoading}
                  orderedQuantity={formik.values.products?.[i]?.orderedQuantity}
                  costPrice={formik.values.products?.[i]?.costPrice}
                  sellingPrice={formik.values.products?.[i]?.sellingPrice}
                  secondaryUomId={formik.values.products?.[i]?.secondaryUomId}
                  secondaryUomQuantity={
                    formik.values.products?.[i]?.secondaryUomQuantity
                  }
                  tax={formik.values.products?.[i]?.tax}
                  totalCost={formik.values.products?.[i]?.totalCost}
                  totalRevenue={formik.values.products?.[i]?.totalRevenue}
                  updateOrderedQuantity={(v) => {
                    formik.setFieldValue(`products.${i}.orderedQuantity`, v);
                  }}
                  updateCostPrice={(v) => {
                    formik.setFieldValue(`products.${i}.costPrice`, v);
                  }}
                  updateSellingPrice={(v) => {
                    formik.setFieldValue(`products.${i}.sellingPrice`, v);
                  }}
                  updateTaxAmount={(v) => {
                    formik.setFieldValue(`products.${i}.tax`, v);
                  }}
                  updateTotalCost={(v) => {
                    formik.setFieldValue(`products.${i}.totalCost`, v);
                  }}
                  updateTotalRevenue={(v) => {
                    formik.setFieldValue(`products.${i}.totalRevenue`, v);
                  }}
                  removeProduct={() =>
                    formik.setFieldValue(
                      'products',
                      formik.values.products?.filter((p, id) => id != i)
                    )
                  }
                  summary={summary}
                  showCostPrice={
                    formik.values?.orderType.value === 'Purchase' &&
                    checkPermission(currentUser, 'OPS_COST_PRICE_VISIBILITY')
                  }
                  showSellingPrice={
                    (formik.values?.orderType.value === 'Sales' ||
                      formik.values?.orderType.value === 'Sales Return') &&
                    checkPermission(currentUser, 'OPS_SELLING_PRICE_VISIBILITY')
                  }
                  orderType={formik.values?.orderType.value}
                  prevOrderedQuantity={
                    formik.values.products?.[i]?.prevOrderedQuantity
                  }
                  isLogistic={isLogistic}
                  warehouseId={warehouseId}
                />
              );
            })}
          </TableBody>
        </Table>

        <div className="lg:bg-light flex justify-between pl-4 pr-12 py-[14px] items-center">
          <div>
            {!summary && formik.values?.orderType.value !== 'Sales Return' && (
              <Button
                variant="transparent"
                className={'p-2 ml-2 mr-2 h-8 rounded border-solid'}
                overrideSize={true}
                labelClass="font-medium text-xs"
                label="+ New Product"
                onClick={() => {
                  formik.setFieldValue('products', [
                    ...(formik.values.products || []),
                    { product: { uom: {} } }
                  ]);
                }}
              />
            )}
          </div>
          <div className="flex items-center">
            <div className="relative">
              <div className="flex items-center h-5 border-r border-solid border-primaryBlue border-l-0 border-t-0 border-b-0 pr-8 w-full lg:w-auto">
                <p className="text-xs text-primaryBlue">Calculated Weight:</p>
                <p className="text-normal font-semibold ml-1 text-primaryBlue">
                  {decimalDisplay(calculatedWeight)}
                </p>
                <p className="text-[10px] text-primaryBlue ml-0.5">
                  {settings?.weight?.symbol || 'Kg'}
                </p>
                {!summary && (
                  <img
                    ref={buttonRef}
                    src={EditIcon}
                    alt="edit"
                    className="ml-[6px] cursor-pointer"
                    onClick={() => setWeightPopup(!showWeightPopup)}
                  />
                )}
              </div>
              {!summary && (
                <div
                  ref={dropdownRef}
                  className={clsx(
                    'w-[262px] px-4 rounded-[8px] bg-white z-10 absolute right-6 top-6 shadow-elevation-2 transition-all overflow-hidden',
                    {
                      'h-[180px] py-4': showWeightPopup,
                      'h-0 py-0': !showWeightPopup
                    }
                  )}
                >
                  <p className="text-primaryBlue text-base font-medium my-2">
                    Override Weight
                  </p>
                  <FormControl outlined={true} fullWidth>
                    <TextField
                      type="number"
                      className="mt-3 w-full"
                      placeholder="Weight"
                      value={calculatedWeightTemp}
                      onChange={(e) => {
                        setCalculatedWeightTemp(e.target.value);
                      }}
                    />
                  </FormControl>
                  <div className="w-full flex justify-end mt-4">
                    <Button
                      size="small"
                      variant="text"
                      label="Cancel"
                      onClick={() => {
                        setWeightPopup(false);
                        setCalculatedWeightTemp(0);
                      }}
                    />
                    <Button
                      className="ml-1"
                      size="small"
                      variant="primary"
                      label="Save"
                      onClick={async () => {
                        setCalculatedWeight(calculatedWeightTemp);
                        await formik.setFieldValue(
                          'calculatedWeight',
                          calculatedWeightTemp
                        );
                        setWeightPopup(false);
                        setCalculatedWeightTemp(0);
                      }}
                      disabled={!calculatedWeightTemp}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="flex ml-8 items-center h-5 w-full lg:w-auto pr-8">
              <p className="text-xs text-primaryBlue">Total Qty (Primary):</p>
              <p className="text-normal font-semibold ml-1 text-primaryBlue">
                {formik.values.products?.length > 0
                  ? decimalDisplay(
                      formik.values.products.reduce(
                        (a, b) =>
                          +a +
                          (+b?.orderedQuantity *
                            (+b.uom?.conversionFactor || 1) || 0),
                        0
                      )
                    )
                  : 0}
                <span className="ml-1 text-xs">units</span>
              </p>
            </div>
            {(checkPermission(currentUser, 'OPS_SELLING_PRICE_VISIBILITY') ||
              checkPermission(currentUser, 'OPS_COST_PRICE_VISIBILITY')) &&
              organizationType === ORGANIZATION_TYPES.MANUFACTURER && (
                <div className="flex ml-8 items-center h-5 w-full lg:w-auto">
                  <p className="text-xs text-primaryBlue">Total Tax:</p>
                  <p className="text-normal font-semibold ml-1 text-primaryBlue">
                    {totalTax}
                  </p>
                </div>
              )}
            {(checkPermission(currentUser, 'OPS_SELLING_PRICE_VISIBILITY') ||
              checkPermission(currentUser, 'OPS_COST_PRICE_VISIBILITY')) &&
              organizationType === ORGANIZATION_TYPES.MANUFACTURER && (
                <div className="flex ml-8 items-center h-5 w-full lg:w-auto">
                  <p className="text-xs text-primaryBlue">Grand Total:</p>
                  <p className="text-normal font-semibold ml-1 text-primaryBlue">
                    {formik.values.products?.length > 0 &&
                    formik.values?.orderType.value === 'Purchase'
                      ? decimalDisplay(
                          formik.values.products.reduce(
                            (a, b) => +a + (+b?.totalCost || 0),
                            0
                          )
                        )
                      : formik.values.products?.length > 0 &&
                        (formik.values?.orderType.value === 'Sales' ||
                          formik.values?.orderType.value === 'Sales Return')
                      ? decimalDisplay(
                          formik.values.products.reduce(
                            (a, b) => +a + (+b?.totalRevenue || 0),
                            0
                          )
                        )
                      : 0}
                  </p>
                </div>
              )}
          </div>
        </div>
      </TableContainer>
    </div>
  );
}
