import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

import { decimalDisplay, productLabelFormat } from '../../../utils/common';
import useStyles from './../taskStyles';
import { TableFooter } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment/moment';

function Row(props) {
  const classes = useStyles();
  const { row } = props;

  const batchDetails = (
    <Box className={classes.headDetail2} sx={{ margin: 2 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width={'300px'}>
              <b className={classes.tableTextCopy}>LOCATION</b>
            </TableCell>
            <TableCell width={'180px'} className={classes.columnPadding}>
              <b className={classes.tableTextCopy}>BATCH NO</b>
            </TableCell>
            <TableCell width={'280px'} className={classes.columnPadding}>
              <b className={classes.tableTextCopy}>ORDER ID</b>
            </TableCell>
            <TableCell className={classes.columnPadding} width={'340px'}>
              <b className={classes.tableTextCopy}>Mfg. & Exp. Date</b>
            </TableCell>
            <TableCell className={classes.columnPadding} width={'180px'}>
              <b className={classes.tableTextCopy}>Pallet ID</b>
            </TableCell>
            <TableCell className={classes.columnPadding} width={'180px'}>
              <b className={classes.tableTextCopy}>MRP</b>
            </TableCell>
            <TableCell className={classes.columnPadding} width={'260px'}>
              <b className={classes.tableTextCopy}>Pick Plan Qty</b>
            </TableCell>
            <TableCell className={classes.columnPadding} width={'260px'}>
              <b className={classes.tableTextCopy}>Pick Actual Qty</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {row?.WarehouseTaskProductDetails?.map((batch) => (
            <TableRow key={batch.id}>
              <TableCell
                width={'300px'}
                className={clsx(classes.tableTextDesp2)}
              >
                {batch?.InventoryDetail?.WarehouseHall?.name}
                <div className={classes.tableTextDesp3}>
                  <b>{'Aisle:'}</b>
                  {batch?.InventoryDetail?.WarehouseHall?.Aisle?.name}
                  <b className={'ml-2'}>{' Bay:'} </b>
                  {batch?.InventoryDetail?.WarehouseHall?.Bay?.name}
                </div>
              </TableCell>
              <TableCell
                width={'180px'}
                className={clsx(classes.columnPadding, classes.tableTextDesp2)}
              >
                {batch?.InventoryDetail?.batchNumber || '-'}
              </TableCell>
              <TableCell
                width={'280px'}
                className={clsx(classes.columnPadding, classes.tableTextDesp2)}
              >
                {batch?.OrdersTask?.Order?.customId || '-'}
              </TableCell>
              <TableCell
                width={'340px'}
                className={clsx(classes.columnPadding, classes.tableTextDesp2)}
              >
                {batch?.InventoryDetail?.manufacturingDate
                  ? moment(batch?.InventoryDetail?.manufacturingDate).format(
                      'DD/MM/YY'
                    )
                  : 'N/A'}{' '}
                -{' '}
                {batch?.InventoryDetail?.expiryDate
                  ? moment(batch?.InventoryDetail?.expiryDate).format(
                      'DD/MM/YY'
                    )
                  : 'N/A'}
              </TableCell>
              <TableCell
                width={'180px'}
                className={clsx(classes.columnPadding, classes.tableTextDesp2)}
              >
                {batch?.Pallet?.palletId || '-'}
              </TableCell>
              <TableCell
                width={'180px'}
                className={clsx(classes.columnPadding, classes.tableTextDesp2)}
              >
                {batch?.InventoryDetail?.MRP || '-'}
              </TableCell>
              <TableCell
                width={'260px'}
                className={clsx(classes.columnPadding, classes.tableTextDesp2)}
              >
                {batch?.cocQuantity || '-'}
              </TableCell>
              <TableCell
                width={'260px'}
                className={clsx(classes.columnPadding, classes.tableTextDesp2)}
              >
                {batch?.physicalQuantity || '-'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );

  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: '1px solid rgba(224, 224, 224, 1)' } }}
      >
        <TableCell
          align="left"
          // component="th"
          // scope="row"
          className={classes.topColumnWrapper}
        >
          {productLabelFormat(row?.Product)}
        </TableCell>
        <TableCell align="center" className={classes.topColumnWrapper}>
          {row?.WarehouseTaskProductDetails?.length}
        </TableCell>
        <TableCell align="right" className={classes.topColumnWrapper}>
          {row?.Product?.UOM?.name}
        </TableCell>
        <TableCell align="right" className={classes.topColumnWrapper}>
          {row?.totalCOCQty}
        </TableCell>
        <TableCell align="right" className={classes.topColumnWrapper}>
          {row?.totalPhysicalQty}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.padding0} colSpan={12}>
          {batchDetails}
        </TableCell>
      </TableRow>
    </>
  );
}

const PickingDetailTable = ({ values }) => {
  const classes = useStyles();
  let totalTasks = 0;
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" className={classes.tableBorder2}>
        <TableHead>
          <TableRow>
            <TableCell align="left" xs={3}>
              <b className={classes.tableText}>SKU Code & Name</b>
            </TableCell>
            <TableCell align="center" xs={3}>
              <b className={classes.tableText}>NO of Locations</b>
            </TableCell>
            <TableCell align="right" xs={2}>
              <b className={classes.tableText}>UoM</b>
            </TableCell>
            <TableCell align="right" xs={2}>
              <b className={classes.tableText}>Pick Plan Qty</b>
            </TableCell>
            <TableCell align="right" xs={2}>
              <b className={classes.tableText}>Pick Actual Qty</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values?.WarehouseTaskProducts?.map((row) => {
            totalTasks = row?.WarehouseTaskProductDetails?.length + totalTasks;
            return <Row key={row.Product.id} row={row} values={values} />;
          })}
        </TableBody>
      </Table>
      <TableFooter>
        <TableRow
          className={classes.tableMidContainer}
          style={{
            minWidth: '100%',
            overflow: 'hidden'
          }}
        >
          <TableCell
            className={classes.topColumnWrapper}
            style={{
              minWidth: 'auto',
              width: '20%'
            }}
          >
            <div className={classes.totalRow}>
              <span className={classes.topColumnWrapperSimple}>
                Total SKUs Count:
              </span>
              <span className={classes.topColumnWrapperBold}>
                {decimalDisplay(values?.WarehouseTaskProducts?.length)}
              </span>
            </div>
          </TableCell>
          <TableCell
            className={classes.topColumnWrapper}
            style={{ minWidth: 'auto', width: '1%' }}
          >
            |
          </TableCell>
          <TableCell
            className={classes.topColumnWrapper}
            style={{ minWidth: 'auto', width: '27%' }}
          >
            <div className={classes.totalRow}>
              <span className={classes.topColumnWrapperSimple}>
                Total Items Count:
              </span>
              <span className={classes.topColumnWrapperBold}>
                {decimalDisplay(totalTasks)}
              </span>
            </div>
          </TableCell>
        </TableRow>
      </TableFooter>
    </TableContainer>
  );
};

export default PickingDetailTable;
