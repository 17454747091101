/*eslint-disable*/
import { useContext, useEffect, useRef, useState } from 'react';
import {
  FormControl,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  dateFormatSlashDivider,
  dateToPickerEndFormat,
  decimalDisplay,
  exportToCSV,
  SharedContext
} from '../../../utils/common';
import { isNotEmptyArray, isRequired } from '../../../utils/validators';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { CircularProgress, MenuItem } from '@mui/material';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import moment from 'moment';
import { ORGANIZATION_TYPES } from '../../../constants/index';
import CheckIcon from '@mui/icons-material/Check';
import Frame from '../../../assets/icons/Frame1.svg';
import LoadingInventory from '../../../assets/icons/loading_inventory.svg';
import DateRangePickerSingleCalendar from './DateRangePicker';
import Button from '../../../core-components/atoms/Button';
import Checkbox from '@mui/material/Checkbox';
import clsx from 'clsx';
import ViewIcon from '../../../assets/icons/viewIcon.svg';
import DownloadIcon from '../../../assets/icons/downloadOutlineIcon.svg';
import Popup from '../../../core-components/atoms/Popup';
import LedgerReportPopupView from './LedgerReportPopupView';
import { Close } from '@material-ui/icons';
import { Paragraph, Title } from '../../../core-components/atoms/Text';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import FLAGS from '../../../constants/featureFlags';
import mixpanel from 'mixpanel-browser';
import * as XLSX from 'xlsx';

const useStyles = makeStyles(() => ({
  parentContainer: {
    boxSizing: 'border-box',
    padding: '20px 20px',
    width: '100% !important',
    '@media only screen and (max-width: 578px) and (min-width: 360px)': {
      padding: '20px 14px'
    },
    margin: '0px',
    paddingTop: '16px',
    paddingLeft: '32px',
    paddingRight: '24px',
    // paddingBlock: '24px',
    minHeight: '152px',
    backgroundColor: '#F6F7FB'
  },
  root: {
    width: '100%',
    marginBottom: '20px'
  },
  container: {
    padding: 20
  },
  datepicker: {
    margin: '0px !important'
  },
  ledgerReportFields: {
    marginTop: '0px'
  }
}));

export default function LedgerDetailsView() {
  const { isFeatureEnabled } = useFeatureFlags();
  const classes = useStyles();
  const sxProps = {
    '& .MuiInputBase-root': {
      padding: '8px 16px !important',
      fontSize: '14px'
    },
    '& .MuiAutocomplete-input': {
      padding: '0px 4px 0px 6px !important'
    },
    '& input::placeholder': {
      fontSize: '14px'
    },
    '& textarea::placeholder': {
      fontSize: '14px'
    }
  };
  const listBoxProps = {
    fontSize: 14
  };

  const inputProps = {
    classes: {
      input: 'text-sm',
      textarea: 'text-sm',
      'MuiInputBase-root': 'py-2 px-4 text-sm'
    }
  };

  const {
    currentPageTitle,
    setCurrentPageTitle,
    setAPILoader,
    organizationType
  } = useContext(SharedContext);

  const IS_MANUFACTURER = organizationType === ORGANIZATION_TYPES.MANUFACTURER;
  const [ledgerData, setLedgerData] = useState([]);
  const [sortable] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [customerId, setCustomerId] = useState('');
  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [batches, setBatches] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [searchCompanyValue, setSearchCompanyValue] = useState('');
  const [searchWarehouseValue, setSearchWarehouseValue] = useState('');
  const [searchProductValue, setSearchProductValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [validation, setValidation] = useState({
    startDate: dateToPickerEndFormat(null),
    endDate: dateToPickerEndFormat(null),
    customerId: false,
    warehouseId: false
  });
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const tableContainerRef = useRef(null);
  const [singleCustomer, setSingleCustomer] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState([]);
  const [selectedWarehouseIds, setSelectedWarehouseIds] = useState([]);
  const [selectedBatches, setSelectedBatches] = useState([]);
  const [selectedBatchesIds, setSelectedBatchesIds] = useState([]);
  const [weight, setWeight] = useState(null);
  const [totalWeight, setTotalWeight] = useState(null);
  const [totalSKU, setTotalSKU] = useState(null);
  const [columnNames] = useState(
    IS_MANUFACTURER
      ? [
          'SKU & NODE',
          'UoM',
          'OPENING STOCK QTY',
          'PURCHASE',
          'SALES',
          'SALES RETURN',
          'TRANSFER',
          'ADJUSTMENTS',
          'GRN REVERSAL',
          'GDN REVERSAL',
          'CLOSING STOCK',
          ''
        ]
      : [
          'SKU & NODE',
          'UoM',
          'OPENING STOCK QTY',
          'INBOUND',
          'OUTBOUND',
          'TRANSFER',
          'ADJUSTMENTS',
          'GRN REVERSAL',
          'GDN REVERSAL',
          'CLOSING STOCK',
          ''
        ]
  );
  const [viewProduct, setViewProduct] = useState(false);
  const [selectedSingleProduct, setSelectedSingleProduct] = useState({});
  const [hoveredRow, setHoveredRow] = useState(-1);
  const [showWareHouseAutocomplete, setShowWareHouseAutocomplete] =
    useState(true);
  const [showProductAutocomplete, setShowProductAutoComplete] = useState(true);
  const [showBatchesAutoComplete, setShowBatchesAutoComplete] = useState(true);
  const [searchBatchValue, setSearchBatchValue] = useState('');

  const [allLedgerSelected, setAllLedgerSelection] = useState(false);
  const [selectedLedgers, setSelectedLedger] = useState([]);

  useEffect(() => {
    let title = 'Ledger Report';
    setCurrentPageTitle(title);
    mixpanel.track('Ledger Reports', {});
  }, [currentPageTitle, setCurrentPageTitle]);

  useEffect(() => {
    if (!IS_MANUFACTURER) {
      getCompanies(searchCompanyValue);
    }
  }, [searchCompanyValue]);

  const getCompanies = (searchCompanyValue) => {
    let search = searchCompanyValue;
    setAPILoader(true);
    API.get('companies', {
      params: { search }
    })
      .then((res) => {
        if (res.data) {
          setCustomers(res.data.rows);
          if (res.data?.rows?.length == 1) {
            setSingleCustomer(res.data?.rows?.[0]);
            setCustomerId(res.data?.rows?.[0]?.id);
          }
        }
      })
      .finally(() => setAPILoader(false));
  };

  useEffect(() => {
    getWarehouses(searchWarehouseValue);
  }, [searchWarehouseValue]);

  const getWarehouses = (searchWarehouseValue) => {
    let filters = {
      colVal: {
        isActive: 1
      },
      sortable: ''
    };
    let search = searchWarehouseValue;
    setAPILoader(true);
    let columns = ['code', 'name'];
    API.get(`dispatch-orders/internal-nodes`, {
      params: {
        filters,
        search,
        columns,
        companyId: !IS_MANUFACTURER ? customerId : null
      }
    })
      .then((res) => {
        if (res.data) {
          setWarehouses(res.data);
        }
      })
      .finally(() => setAPILoader(false));
  };

  useEffect(() => {
    if (customerId || selectedWarehouseIds?.length > 0) {
      getProducts(searchProductValue);
    }
  }, [searchProductValue, selectedWarehouseIds, customerId]);

  const getProducts = (searchProductValue) => {
    let colVal = {
      isActive: 1
    };
    let productNameVal;
    if (productNameVal) {
      colVal['$Product.name$'] = productNameVal;
    }
    let filters = {
      colVal,
      sortable
    };
    let columns = ['$Product.name$', '$Product.description$'];
    setAPILoader(true);
    API.get('reports/inventoryLedger/products', {
      params: {
        search: searchProductValue,
        companyId: !IS_MANUFACTURER ? customerId : null,
        nodeIds: selectedWarehouseIds,
        limit: 20,
        filters,
        columns
      }
    })
      .then((res) => {
        if (res.data) {
          setProducts(res?.data);
        }
      })
      .finally(() => setAPILoader(false));
  };

  useEffect(() => {
    if (IS_MANUFACTURER) {
      if (selectedWarehouseIds?.length > 0 || selectedProduct.length > 0) {
        getProductBatches(searchBatchValue);
      }
    } else {
      if (
        selectedWarehouseIds?.length > 0 ||
        (selectedProduct.length > 0 && customerId)
      ) {
        getProductBatches(searchBatchValue);
      }
    }
  }, [selectedProduct, searchBatchValue]);

  const getProductBatches = (searchBatchValue) => {
    let filters = {
      colVal: {},
      sortable: ''
    };
    let search = searchBatchValue;
    let columns = ['batchNumber', 'batchName'];
    setAPILoader(true);
    API.get('reports/inventoryLedger/batches', {
      params: {
        search,
        filters,
        columns,
        companyId: customerId,
        nodeIds: selectedWarehouseIds,
        ...(selectedProduct.filter((item) => item.id !== null).length > 0
          ? { productIds: selectedProduct.map((item) => item.id) }
          : {})
      }
    })
      .then((res) => {
        if (res.data) {
          setBatches(mergeObjectsWithSameProperties(res.data));
        }
      })
      .finally(() => setAPILoader(false));
  };

  function mergeObjectsWithSameProperties(array) {
    const mergedObjects = {};
    array.forEach((obj) => {
      const key = `${obj.batchNumber}-${obj.manufacturingDate}-${obj.expiryDate}`;
      if (key in mergedObjects) {
        mergedObjects[key] = { ...mergedObjects[key], ...obj };
      } else {
        mergedObjects[key] = obj;
      }
    });
    return Object.values(mergedObjects);
  }

  const generateLedgerReport = async (page, oldData, totalWeight) => {
    setValidation({
      customerId: !IS_MANUFACTURER,
      warehouseId: selectedWarehouse.length <= 0
    });
    try {
      if (
        (IS_MANUFACTURER || isRequired(customerId)) &&
        isNotEmptyArray(selectedWarehouse)
      ) {
        setIsLoading(true);
        const params = {
          page: page,
          startDate: moment.utc(startDate)?.toISOString(),
          endDate: moment
            .utc(endDate)
            .add(23, 'hours')
            .add(59, 'minutes')
            .add(59, 'seconds')
            .toISOString(),
          companyId: !IS_MANUFACTURER ? customerId : null,
          nodeIds: selectedWarehouseIds,
          ...(selectedProductIds?.length > 0
            ? { productIds: selectedProductIds }
            : {}),
          ...(selectedBatchesIds?.length > 0
            ? { batchIds: selectedBatchesIds }
            : {})
        };
        const newLedgerData = await API.get(`reports/inventoryLedger`, {
          params
        });
        const newArr = newLedgerData?.data;
        setLedgerData([...oldData, ...newArr]);
        const total = newLedgerData.data.reduce((sum, item) => {
          const { closingStock, Inventory } = item;
          const productWeight = Inventory?.Product?.weight;
          const weight =
            closingStock !== undefined && productWeight !== undefined
              ? closingStock * productWeight
              : 0;

          return sum + weight;
        }, 0);
        setPageCount(newLedgerData.pages);
        setTotalSKU(newLedgerData.totalSKUs);
        setTotalWeight(totalWeight + total);
        mixpanel.track('Generated Ledger Report', {
          totalSKUs: newLedgerData.totalSKUs,
          totalWeight: totalWeight + total,
          ...params
        });
      }
    } catch (err) {
      onError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckboxChange = (id, ledger) => {
    const selectedIndex = selectedLedgers.some((item) => item.id === id);
    let newSelected = [...selectedLedgers];
    if (!selectedIndex) {
      newSelected = [...selectedLedgers, ledger];
    } else {
      newSelected = selectedLedgers.filter((item) => item.id !== id);
    }
    const total = newSelected.reduce((sum, item) => {
      const { closingStock, Inventory } = item;
      const productWeight = Inventory?.Product?.weight;
      const weight =
        closingStock !== undefined && productWeight !== undefined
          ? closingStock * productWeight
          : 0;
      return sum + weight;
    }, 0);
    setSelectedLedger(newSelected);
    setWeight(total);
  };

  const handleTableScroll = async () => {
    if (isLoading) {
      return;
    }
    const container = tableContainerRef.current;
    if (
      container.scrollTop + container.clientHeight + 20 >=
        container.scrollHeight &&
      page < pageCount
    ) {
      setPage(page + 1);
      await generateLedgerReport(page + 1, ledgerData, totalWeight);
    }
  };

  const exportToExcel = async () => {
    setIsLoading(true);
    const ledgerReportData = await getExportData();
    const exportData =
      selectedLedgers.length > 0 ? selectedLedgers : ledgerReportData;
    const data = exportData.map((data) => [
      data?.Inventory?.Warehouse.name || ' ',
      data?.Inventory?.Product.name || ' ',
      data?.Inventory?.Product.description || ' ',
      data?.openingStock || ' ',
      ...(!IS_MANUFACTURER ? [data?.inboundQuantity || ' '] : []),
      ...(!IS_MANUFACTURER ? [data?.outboundQuantity || ' '] : []),
      ...(IS_MANUFACTURER ? [data?.purchaseQuantity || ' '] : []),
      ...(IS_MANUFACTURER ? [data?.salesQuantity || ' '] : []),
      ...(IS_MANUFACTURER ? [data?.salesReturnQuantity || ' '] : []),
      data?.addTransferQuantity - data?.subtractTransferQuantity || ' ',
      data?.adjustments || ' ',
      data?.grnReversalQuantity || ' ',
      data?.gdnReversalQuantity || ' ',
      data?.closingStock || ' '
    ]);
    const dataToExport = [
      [
        'Warehouse',
        'SKU Code',
        'SKU Description',
        'Opening Stock',
        ...(!IS_MANUFACTURER ? ['Inbound'] : []),
        ...(!IS_MANUFACTURER ? ['Outbound'] : []),
        ...(IS_MANUFACTURER ? ['Purchase QTY'] : []),
        ...(IS_MANUFACTURER ? ['Sales QTY'] : []),
        ...(IS_MANUFACTURER ? ['Sales Return'] : []),
        'Transfer QTY',
        'Adjustments',
        'GRN Reversal',
        'GDN Reversal',
        'Closing Stock'
      ],
      ...data
    ];
    // exportToCSV(header, dataToExport, title);
    let title = `Ledger Report ${moment().format('DD/MM/YY')}`;
    const ws = XLSX.utils.aoa_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Inventory Ledger');
    XLSX.writeFile(wb, `${title}.xlsx`);
    setIsLoading(false);
  };

  const getExportData = async () => {
    setValidation({
      customerId: !IS_MANUFACTURER,
      warehouseId: selectedWarehouse.length <= 0
    });
    try {
      if (
        (IS_MANUFACTURER || isRequired(customerId)) &&
        isNotEmptyArray(selectedWarehouse)
      ) {
        setIsLoading(true);
        const params = {
          startDate: moment.utc(startDate)?.toISOString(),
          endDate: moment
            .utc(endDate)
            .add(23, 'hours')
            .add(59, 'minutes')
            .add(59, 'seconds')
            .toISOString(),
          companyId: !IS_MANUFACTURER ? customerId : null,
          nodeIds: selectedWarehouseIds,
          ...(selectedProductIds?.length > 0
            ? { productIds: selectedProductIds }
            : {}),
          ...(selectedBatchesIds?.length > 0
            ? { batchIds: selectedBatchesIds }
            : {}),
          exportLedger: true
        };
        const newLedgerData = await API.get(`reports/inventoryLedger`, {
          params
        });
        return newLedgerData?.data;
      }
    } catch (err) {
      onError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Grid container className={classes.parentContainer} spacing={3}>
        {!IS_MANUFACTURER && (
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className="flex flex-row">
              <Title className="py-1 w-[72px] mt-2 flex" variant={'sm'}>
                Customer<p className={'text-red-500'}>*</p>
              </Title>
              <div className="w-full">
                <FormControl fullWidth={true} variant="outlined">
                  <Autocomplete
                    id="customer"
                    defaultValue={''}
                    value={singleCustomer || null}
                    options={customers}
                    sxProps={sxProps}
                    customInputProps={inputProps}
                    listBoxProps={listBoxProps}
                    placeholder={'Select Customers'}
                    getOptionLabel={(customer) => customer.name || ''}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setCustomerId(newValue.id);
                        setSingleCustomer(newValue);
                      } else {
                        setCustomerId(null);
                        setSingleCustomer(null);
                      }
                      setSelectedProduct([]);
                      setBatches([]);
                    }}
                    onFocus={() => {
                      setSearchCompanyValue('');
                    }}
                    onInputChange={(event, newValue) => {
                      setSearchCompanyValue(newValue);
                    }}
                  />
                </FormControl>
                {validation.customerId && !isRequired(customerId) && (
                  <Title variant={'sm'} className={'text-red-500'}>
                    Company is required!
                  </Title>
                )}
              </div>
            </div>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <div className="flex flex-row items-center">
            <Title className="py-1 w-[72px] flex" variant={'sm'}>
              Nodes<p className={'text-red-500'}>*</p>
            </Title>
            <div className="w-full min-h-[32px]">
              <FormControl fullWidth={true} variant="outlined">
                {selectedWarehouse.length > 0 && !showWareHouseAutocomplete ? (
                  <TextField
                    size="small"
                    variant="outlined"
                    value={
                      selectedWarehouse.length <= 3
                        ? selectedWarehouse
                            .map((option) =>
                              option?.Warehouse?.businessWarehouseCode ||
                              option?.Warehouse?.name
                                ? `${option?.Warehouse?.name} ${
                                    option?.Warehouse?.businessWarehouseCode
                                      ? `- ${option?.Warehouse?.businessWarehouseCode}`
                                      : ''
                                  }`
                                : ''
                            )
                            .join(', ')
                        : `${selectedWarehouse
                            .slice(0, 3)
                            .map((option) =>
                              option?.Warehouse?.businessWarehouseCode ||
                              option?.Warehouse?.name
                                ? `${option?.Warehouse?.name} ${
                                    option?.Warehouse?.businessWarehouseCode
                                      ? `- ${option?.Warehouse?.businessWarehouseCode}`
                                      : ''
                                  }`
                                : ''
                            )
                            .join(', ')}, +${selectedWarehouse.length - 3} more`
                    }
                    onClick={() => setShowWareHouseAutocomplete(true)}
                    InputProps={{
                      endAdornment: (
                        <Close
                          size="small"
                          onClick={() => {
                            setSelectedWarehouse([]);
                            setShowWareHouseAutocomplete(true);
                          }}
                        />
                      )
                    }}
                  />
                ) : (
                  (showWareHouseAutocomplete ||
                    selectedWarehouse.length === 0) && (
                    <div
                      onMouseLeave={() => setShowWareHouseAutocomplete(false)}
                    >
                      <Autocomplete
                        multiple
                        options={[
                          {
                            label: 'All Nodes',
                            divider: true,
                            Warehouse: {
                              id: '*',
                              businessWarehouseCode: 'Nodes',
                              name: 'All'
                            }
                          },
                          ...(warehouses || [])
                        ]}
                        value={selectedWarehouse || []}
                        getOptionLabel={(option) =>
                          option?.Warehouse?.businessWarehouseCode ||
                          option?.Warehouse?.name
                            ? `${option?.Warehouse?.name} ${
                                option?.Warehouse?.businessWarehouseCode
                                  ? `- ${option?.Warehouse?.businessWarehouseCode}`
                                  : ''
                              }`
                            : ''
                        }
                        placeholder={'Select Nodes'}
                        disableCloseOnSelect
                        onChange={(event, newValue) => {
                          if (newValue.length === 0) {
                            setSelectedWarehouse([]);
                            setSelectedWarehouseIds([]);
                          } else if (
                            newValue.length === 1 &&
                            newValue[0].label === 'All Nodes'
                          ) {
                            setSelectedWarehouse([newValue[0]]);
                            setSelectedWarehouseIds([]);
                          } else if (
                            newValue[newValue.length - 1].label === 'All Nodes'
                          ) {
                            setSelectedWarehouse(
                              newValue.filter(
                                (option) => option.label === 'All Nodes'
                              )
                            );
                            setSelectedWarehouseIds([]);
                          } else if (
                            newValue.length > 1 &&
                            newValue.some(
                              (option) => option.label === 'All Nodes'
                            )
                          ) {
                            setSelectedWarehouse(
                              newValue.filter(
                                (option) => option.label !== 'All Nodes'
                              )
                            );
                            selectedWarehouseIds(
                              newValue
                                .filter(
                                  (option) => option.label !== 'All Nodes'
                                )
                                .map((item) => item.Warehouse.id)
                            );
                          } else {
                            setSelectedWarehouse(newValue);
                            setSelectedWarehouseIds(
                              newValue.map((item) => item.Warehouse.id)
                            );
                          }
                        }}
                        onInputChange={(event, newValue) => {
                          setSearchWarehouseValue(newValue);
                        }}
                        onFocus={() => {
                          setSearchWarehouseValue('');
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <div
                              key={index}
                              {...getTagProps({ index })}
                              style={{
                                backgroundColor: '#FFF',
                                paddingLeft: '8px',
                                paddingRight: '8px',
                                paddingBottom: '4px',
                                marginRight: '5px',
                                display: 'inline-block',
                                borderRadius: '5px',
                                height: '23px'
                              }}
                            >
                              {option?.Warehouse?.businessWarehouseCode ||
                              option?.Warehouse?.name
                                ? `${option?.Warehouse?.name} ${
                                    option?.Warehouse?.businessWarehouseCode
                                      ? `- ${option?.Warehouse?.businessWarehouseCode}`
                                      : ''
                                  }`
                                : ''}
                              <Close
                                style={{
                                  width: '12px',
                                  height: '12px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => {
                                  getTagProps({ index }).onDelete();
                                }}
                              />
                            </div>
                          ))
                        }
                        sxProps={sxProps}
                        customInputProps={inputProps}
                        listBoxProps={listBoxProps}
                        renderOption={(props, option, { selected }) => (
                          <div>
                            {option.divider ? (
                              <div className="border">
                                <MenuItem
                                  {...props}
                                  key={option.value}
                                  value={option}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                  }}
                                >
                                  {option?.Warehouse?.businessWarehouseCode ||
                                  option?.Warehouse?.name
                                    ? `${option?.Warehouse?.name} ${
                                        option?.Warehouse?.businessWarehouseCode
                                          ? ` ${option?.Warehouse?.businessWarehouseCode}`
                                          : ''
                                      }`
                                    : ''}
                                  {selected ? (
                                    <CheckIcon
                                      sx={{
                                        marginLeft: 'auto'
                                      }}
                                      color="info"
                                    />
                                  ) : null}
                                </MenuItem>
                                <div
                                  style={{ borderBottom: '1px solid #9DA3B3' }}
                                />{' '}
                              </div>
                            ) : (
                              <MenuItem
                                {...props}
                                key={option.value}
                                value={option}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                {option?.Warehouse?.businessWarehouseCode ||
                                option?.Warehouse?.name
                                  ? `${option?.Warehouse?.name} ${
                                      option?.Warehouse?.businessWarehouseCode
                                        ? `- ${option?.Warehouse?.businessWarehouseCode}`
                                        : ''
                                    }`
                                  : ''}
                                {selected ? (
                                  <CheckIcon
                                    sx={{
                                      marginLeft: 'auto'
                                    }}
                                    color="info"
                                  />
                                ) : null}
                              </MenuItem>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  )
                )}
              </FormControl>
              {validation.warehouseId &&
                !isNotEmptyArray(selectedWarehouse) && (
                  <Title variant={'sm'} className={'text-red-500'}>
                    Node is required!
                  </Title>
                )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <div className="flex flex-row items-center">
            <Title className="py-1 w-[72px]" variant={'sm'}>{`SKU's`}</Title>
            <div className="w-full">
              <FormControl fullWidth={true} variant="outlined">
                {selectedProduct.length > 0 && !showProductAutocomplete ? (
                  <TextField
                    size="small"
                    variant="outlined"
                    value={
                      selectedProduct.length <= 3
                        ? selectedProduct
                            .map((option) =>
                              option?.name || option?.description
                                ? `${option?.name} - ${option?.description}`
                                : ''
                            )
                            .join(', ')
                        : `${selectedProduct
                            .slice(0, 3)
                            .map((option) =>
                              option?.name || option?.description
                                ? `${option?.name} - ${option?.description}`
                                : ''
                            )
                            .join(', ')}, +${selectedProduct.length - 3} more`
                    }
                    onClick={() => setShowProductAutoComplete(true)}
                    InputProps={{
                      endAdornment: (
                        <Close
                          size="small"
                          onClick={() => {
                            setSelectedProduct([]);
                            setShowProductAutoComplete(true);
                          }}
                        />
                      )
                    }}
                  />
                ) : (
                  (showProductAutocomplete || selectedProduct.length == 0) && (
                    <div onMouseLeave={() => setShowProductAutoComplete(false)}>
                      <Autocomplete
                        multiple
                        options={[
                          {
                            id: null,
                            name: 'All',
                            description: "SKU's",
                            label: "All SKU's",
                            divider: true
                          },
                          ...(products || [])
                        ]}
                        getOptionLabel={(product) =>
                          `${product?.name} - ${product?.description}` || ''
                        }
                        value={selectedProduct}
                        disableCloseOnSelect
                        onChange={(event, newValue) => {
                          if (newValue.length === 0) {
                            setSelectedProduct([]);
                            setSelectedProductIds([]);
                          } else if (
                            newValue.length === 1 &&
                            newValue[0].label === "All SKU's"
                          ) {
                            setSelectedProduct([newValue[0]]);
                            setSelectedProductIds([]);
                          } else if (
                            newValue[newValue.length - 1].label === "All SKU's"
                          ) {
                            setSelectedProduct(
                              newValue.filter(
                                (option) => option.label === "All SKU's"
                              )
                            );
                            setSelectedProductIds([]);
                          } else if (
                            newValue.length > 1 &&
                            newValue.some(
                              (option) => option.label === "All SKU's"
                            )
                          ) {
                            setSelectedProduct(
                              newValue.filter(
                                (option) => option.label !== "All SKU's"
                              )
                            );
                            setSelectedProductIds(
                              newValue
                                .filter(
                                  (option) => option.label !== "All SKU's"
                                )
                                .map((item) => item.id)
                            );
                          } else {
                            setSelectedProduct(newValue);
                            setSelectedProductIds(
                              newValue.map((item) => item.id)
                            );
                          }
                        }}
                        placeholder={'Select SKU(s)'}
                        sxProps={sxProps}
                        customInputProps={inputProps}
                        listBoxProps={listBoxProps}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <div
                              key={index}
                              {...getTagProps({ index })}
                              style={{
                                backgroundColor: '#FFF',
                                paddingLeft: '8px',
                                paddingRight: '8px',
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                marginRight: '5px',
                                display: 'inline-block',
                                borderRadius: '5px',
                                height: '23px'
                              }}
                            >
                              {`${option?.name} - ${option?.description}` || ''}
                              <Close
                                style={{
                                  width: '12px',
                                  height: '12px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => {
                                  getTagProps({ index }).onDelete();
                                }}
                              />
                            </div>
                          ))
                        }
                        onInputChange={(event, newValue) => {
                          setSearchProductValue(newValue);
                        }}
                        onFocus={() => {
                          setSearchProductValue('');
                        }}
                        renderOption={(props, option, { selected }) => (
                          <div>
                            {option.divider ? ( // Check if it's the "All Nodes" option
                              <div className="border">
                                <MenuItem
                                  {...props}
                                  key={option.value}
                                  value={option}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                  }}
                                >
                                  {`${option?.name} - ${option?.description}` ||
                                    ''}
                                  {selected ? (
                                    <CheckIcon
                                      sx={{
                                        marginLeft: 'auto'
                                      }}
                                      color="info"
                                    />
                                  ) : null}
                                </MenuItem>
                                <div
                                  style={{ borderBottom: '1px solid #9DA3B3' }}
                                />{' '}
                              </div>
                            ) : (
                              <MenuItem
                                {...props}
                                key={option.value}
                                value={option}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                {`${option?.name} - ${option?.description}` ||
                                  ''}
                                {selected ? (
                                  <CheckIcon
                                    sx={{
                                      marginLeft: 'auto'
                                    }}
                                    color="info"
                                  />
                                ) : null}
                              </MenuItem>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  )
                )}
              </FormControl>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <div className="flex flex-row items-center">
            <Title className="py-1 w-[72px]" variant={'sm'}>
              Batches
            </Title>
            <div className="w-full">
              <FormControl fullWidth={true} variant="outlined">
                {selectedBatches.length > 0 && !showBatchesAutoComplete ? (
                  <TextField
                    size="small"
                    variant="outlined"
                    value={
                      selectedBatches.length <= 3
                        ? selectedBatches
                            .map((option) =>
                              option?.batchNumber
                                ? `${option?.batchNumber}`
                                : ''
                            )
                            .join(', ')
                        : `${selectedBatches
                            .slice(0, 3)
                            .map((option) =>
                              option?.batchNumber
                                ? `${option?.batchNumber}`
                                : ''
                            )
                            .join(', ')}, +${selectedBatches.length - 3} more`
                    }
                    onClick={() => setShowBatchesAutoComplete(true)}
                    InputProps={{
                      endAdornment: (
                        <Close
                          size="small"
                          onClick={() => {
                            setSelectedProduct([]);
                            setShowBatchesAutoComplete(true);
                          }}
                        />
                      )
                    }}
                  />
                ) : (
                  (showBatchesAutoComplete || selectedBatches.length == 0) && (
                    <div onMouseLeave={() => setShowBatchesAutoComplete(false)}>
                      <Autocomplete
                        multiple
                        options={[
                          {
                            label: 'All Batches',
                            divider: true,
                            batchNumber: 'All Batches'
                          },
                          ...(batches || [])
                        ]}
                        getOptionLabel={(batch) => `${batch.batchNumber}` || ''}
                        value={selectedBatches}
                        disableCloseOnSelect
                        disabled={
                          !isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING)
                        }
                        onChange={(event, newValue) => {
                          if (newValue.length === 0) {
                            setSelectedBatches([]);
                            setSelectedBatchesIds([]);
                          } else if (
                            newValue.length === 1 &&
                            newValue[0].label === 'All Batches'
                          ) {
                            setSelectedBatches([newValue[0]]);
                            setSelectedBatchesIds([]);
                          } else if (
                            newValue[newValue.length - 1].label ===
                            'All Batches'
                          ) {
                            setSelectedBatches(
                              newValue.filter(
                                (option) => option.label === 'All Batches'
                              )
                            );
                            setSelectedBatchesIds([]);
                          } else if (
                            newValue.length > 1 &&
                            newValue.some(
                              (option) => option.label === 'All Batches'
                            )
                          ) {
                            setSelectedBatches(
                              newValue.filter(
                                (option) => option.label !== 'All Batches'
                              )
                            );
                            setSelectedBatchesIds(
                              newValue
                                .filter(
                                  (option) => option.label !== 'All Batches'
                                )
                                .map((item) => item.id)
                            );
                          } else {
                            setSelectedBatches(newValue);
                            setSelectedBatchesIds(
                              newValue.map((item) => item.id)
                            );
                          }
                        }}
                        placeholder={'Select Batch(es)'}
                        sxProps={sxProps}
                        customInputProps={inputProps}
                        listBoxProps={listBoxProps}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <div
                              key={index}
                              {...getTagProps({ index })}
                              style={{
                                backgroundColor: '#FFF',
                                paddingLeft: '8px',
                                paddingRight: '8px',
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                marginRight: '5px',
                                display: 'inline-block',
                                borderRadius: '5px',
                                height: '23px'
                              }}
                            >
                              {`${option.batchNumber}` || ''}
                              <Close
                                style={{
                                  width: '12px',
                                  height: '12px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => {
                                  getTagProps({ index }).onDelete();
                                }}
                              />
                            </div>
                          ))
                        }
                        onInputChange={(event, newValue) => {
                          setSearchBatchValue(newValue);
                        }}
                        onFocus={() => {
                          setSearchBatchValue('');
                        }}
                        renderOption={(props, option, { selected }) => (
                          <div>
                            {option.divider ? ( // Check if it's the "All Nodes" option
                              <div className="border">
                                <MenuItem
                                  {...props}
                                  key={option.value}
                                  value={option}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                  }}
                                >
                                  {option.batchNumber || ''}
                                  {selected ? (
                                    <CheckIcon
                                      sx={{ marginLeft: 'auto' }}
                                      color="info"
                                    />
                                  ) : null}
                                </MenuItem>
                                <div
                                  style={{ borderBottom: '1px solid #9DA3B3' }}
                                />{' '}
                              </div>
                            ) : (
                              <MenuItem
                                {...props}
                                key={option.value}
                                value={option}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                <div>
                                  <div>{`${option.batchNumber}` || ''}</div>
                                  <div>
                                    {`${dateFormatSlashDivider(
                                      option.manufacturingDate
                                    )} - ${dateFormatSlashDivider(
                                      option.expiryDate
                                    )}` || ''}
                                  </div>
                                </div>

                                {selected ? (
                                  <CheckIcon
                                    sx={{ marginLeft: 'auto' }}
                                    color="info"
                                  />
                                ) : null}
                              </MenuItem>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  )
                )}
              </FormControl>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={12}>
          <div className="flex flex-row justify-end">
            <div>
              <DateRangePickerSingleCalendar
                startDate={startDate}
                endDate={endDate}
                updateRange={(input) => {
                  setStartDate(input.selection.startDate);
                  setEndDate(input.selection.endDate);
                }}
                showDatesInButton
              />
              {(validation.startDate && !isRequired(startDate)) ||
              (validation.endDate && !isRequired(endDate)) ? (
                <Typography color="error">Date range is required!</Typography>
              ) : (
                ''
              )}
            </div>
            <div className="ml-2">
              <Button
                className="py-2 px-3 ml-2 h-8 border-0"
                overrideSize={true}
                labelClass="font-medium text-xs"
                variant="primary"
                label={'Generate Report'}
                size="large"
                onClick={async () => {
                  setPage(1);
                  await generateLedgerReport(1, [], 0);
                }}
              ></Button>
            </div>
          </div>
        </Grid>
      </Grid>
      {ledgerData.length > 0 ? (
        <div className={'no-scrollbar'}>
          <Paper className="no-scrollbar rounded-t-lg shadow-none mx-[28px] mt-[32px]">
            <TableContainer
              style={{ overflow: 'auto' }}
              className="mt-6 no-scrollbar rounded-t-lg border border-solid border-dark300 inventory-report-table"
              onScroll={handleTableScroll}
              ref={tableContainerRef}
            >
              <Table stickyHeader>
                <TableHead className="z-40 relative">
                  <TableRow>
                    <TableCell
                      key={'checkbox'}
                      className="bg-white text-dark300 text-base py-2 px-6"
                    >
                      <Checkbox
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 16
                          },
                          '& .Mui-checked.MuiSvgIcon-root': {
                            color: '#0C6BD7 !important'
                          }
                        }}
                        checked={allLedgerSelected}
                        onChange={() => {
                          setAllLedgerSelection(!allLedgerSelected);
                        }}
                      />
                    </TableCell>
                    {columnNames.map((columnName, index) => (
                      <TableCell
                        key={index}
                        className="text-[10px] text-dark500 bg-white py-2 pl-0 pr-2 font-normal h-[40px]"
                      >
                        {columnName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ledgerData.map((row, index) => (
                    <TableRow
                      key={index}
                      className={clsx(
                        'hover:shadow-elevation-1 hover:z-20 hover:relative ease-in-out transition-all h-[48px]'
                      )}
                      onMouseEnter={() => {
                        setHoveredRow(index);
                      }}
                      onMouseLeave={() => {
                        setHoveredRow(-1);
                      }}
                    >
                      <TableCell className="rounded-none text-dark300 py-4 px-6 ">
                        <Checkbox
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 16
                            },
                            '& .Mui-checked.MuiSvgIcon-root': {
                              color: '#0C6BD7 !important'
                            }
                          }}
                          onClick={() => {
                            if (!allLedgerSelected) {
                              handleCheckboxChange(row?.id, row);
                            }
                          }}
                          checked={
                            selectedLedgers.some(
                              (item) => item.id === row?.id
                            ) || allLedgerSelected
                          }
                        />
                      </TableCell>
                      <TableCell
                        className={
                          'group-hover:text-primaryBlue pl-0 h-[40px] '
                        }
                      >
                        <Tooltip
                          title={`${row?.Inventory?.Product?.name}-${row?.Inventory?.Product?.description}`}
                        >
                          <p
                            className="text-xs font-normal w-[200px]"
                            style={{ textOverflow: 'ellipsis' }}
                          >
                            {row?.Inventory?.Product?.name}-
                            {row?.Inventory?.Product?.description}
                          </p>
                        </Tooltip>
                        <Tooltip title={row?.Inventory?.Warehouse?.name}>
                          <p className="text-[10px] font-normal w-[200px]">
                            {row?.Inventory?.Warehouse?.name}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={'group-hover:text-primaryBlue pl-0'}
                      >
                        <Tooltip title={row?.Inventory?.Product?.UOM?.name}>
                          <p className="text-xs font-normal">
                            {row?.Inventory?.Product?.UOM?.name}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={'group-hover:text-primaryBlue pl-0'}
                      >
                        <Tooltip title={row.openingStock}>
                          <p className="text-xs font-normal">
                            {decimalDisplay(row?.openingStock || 0)}
                          </p>
                        </Tooltip>
                      </TableCell>
                      {IS_MANUFACTURER && (
                        <>
                          <TableCell
                            className={'group-hover:text-primaryBlue pl-0'}
                          >
                            <Tooltip title={row?.purchaseQuantity}>
                              <p className="text-xs font-normal">
                                {decimalDisplay(row.purchaseQuantity)}
                              </p>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className={'group-hover:text-primaryBlue pl-0'}
                          >
                            <Tooltip title={row.salesQuantity}>
                              <p className="text-xs font-normal">
                                {decimalDisplay(row.salesQuantity)}
                              </p>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className={'group-hover:text-primaryBlue pl-0'}
                          >
                            <Tooltip title={row.salesReturnQuantity}>
                              <p className="text-xs font-normal">
                                {decimalDisplay(row.salesReturnQuantity)}
                              </p>
                            </Tooltip>
                          </TableCell>
                        </>
                      )}
                      {!IS_MANUFACTURER && (
                        <>
                          <TableCell
                            className={'group-hover:text-primaryBlue pl-0'}
                          >
                            <Tooltip title={row?.inboundQuantity}>
                              <p className="text-xs font-normal">
                                {decimalDisplay(row.inboundQuantity)}
                              </p>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className={'group-hover:text-primaryBlue pl-0'}
                          >
                            <Tooltip title={row.outboundQuantity}>
                              <p className="text-xs font-normal">
                                {decimalDisplay(row.outboundQuantity)}
                              </p>
                            </Tooltip>
                          </TableCell>
                        </>
                      )}
                      <TableCell
                        className={'group-hover:text-primaryBlue pl-0'}
                      >
                        <Tooltip
                          title={
                            row.addTransferQuantity -
                            row.subtractTransferQuantity
                          }
                        >
                          <p className="text-xs font-normal">
                            {decimalDisplay(
                              row.addTransferQuantity -
                                row.subtractTransferQuantity
                            )}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={'group-hover:text-primaryBlue pl-0'}
                      >
                        <Tooltip title={row.adjustments}>
                          <p className="text-xs font-normal">
                            {decimalDisplay(row.adjustments)}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={'group-hover:text-primaryBlue pl-0'}
                      >
                        <Tooltip title={row?.grnReversalQuantity}>
                          <p className="text-xs font-normal">
                            {decimalDisplay(row?.grnReversalQuantity)}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={'group-hover:text-primaryBlue pl-0'}
                      >
                        <Tooltip title={row?.gdnReversalQuantity}>
                          <p className="text-xs font-normal">
                            {decimalDisplay(row?.gdnReversalQuantity)}
                          </p>
                        </Tooltip>
                      </TableCell>

                      <TableCell
                        className={'group-hover:text-primaryBlue pl-0'}
                      >
                        <Tooltip title={row.closingStock}>
                          <p className="text-xs font-normal">
                            {decimalDisplay(row.closingStock)}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell className="rounded-none">
                        <div
                          className={clsx(
                            'flex h-full items-center justify-end',
                            {
                              'invisible ': hoveredRow !== index
                            }
                          )}
                        >
                          <Button
                            variant="tiny-transparent"
                            icon={<img src={ViewIcon} alt="view" />}
                            className={clsx(
                              'p-1 border-0 h-7 min-w-fit rounded-none mr-1'
                            )}
                            overrideSize={true}
                            labelClass="p-0"
                            onClick={() => {
                              setViewProduct(true);
                              setSelectedSingleProduct(row);
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  {page < pageCount && (
                    <TableRow>
                      <TableCell className="py-2 h-12" colSpan={12}>
                        <div className="flex w-full justify-center">
                          <CircularProgress size="14px" />
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <div className="flex justify-between items-center bg-dark200 rounded-b-lg h-14 border-solid border border-dark300 border-t-0 py-3 px-4 mx-[28px]">
            <div className="flex">
              <Button
                variant="transparent"
                icon={<img src={DownloadIcon} className={`mr-2`} />}
                label="Export"
                className="py-2 px-3 ml-2 h-8 border-solid border ]"
                overrideSize={true}
                labelClass="font-medium text-xs"
                iconClass="h-[14px] w-[14px] mr-1"
                onClick={() => exportToExcel()}
              />
            </div>
            <div className="flex">
              <p className="text-xs text-primaryBlue flex items-center mr-8">
                Total Weight:
                <span className="text-sm font-medium ml-1">
                  {selectedLedgers.length > 0
                    ? decimalDisplay(weight)
                    : decimalDisplay(totalWeight)}
                </span>
                <span className="text-[10px] ml-0.5">KG</span>
              </p>
              <p className="text-xs text-primaryBlue flex items-center">
                Total SKUs:
                <span className="text-sm font-medium ml-1">
                  {selectedLedgers.length || totalSKU}
                </span>
              </p>
            </div>
          </div>
          <Popup
            open={viewProduct}
            setOpen={setViewProduct}
            title={
              <div>
                <div className="text-[18px]">{`${selectedSingleProduct?.Inventory?.Product?.name} - ${selectedSingleProduct?.Inventory?.Product?.description}`}</div>
                <div className="flex text-[12px] text-dark500 bg-white font-normal">
                  <div>
                    SKU: {selectedSingleProduct?.Inventory?.Warehouse?.name}
                  </div>
                  <span className="px-[2px] justify-center align-middle">
                    .
                  </span>
                  <div>
                    UoM: {selectedSingleProduct?.Inventory?.Product?.UOM?.name}
                  </div>
                </div>
              </div>
            }
            dialogContentClasses={`w-[988px]`}
            onClose={() => {
              setViewProduct(false);
              setSelectedSingleProduct({});
            }}
            actions={
              <div className="flex gap-2">
                <Button
                  label="Got It"
                  onClick={() => setViewProduct(false)}
                  variant="primary"
                />
              </div>
            }
            content={
              <LedgerReportPopupView
                product={selectedSingleProduct}
                selectedStartDate={startDate}
                selectedEndDate={endDate}
              />
            }
          />
        </div>
      ) : isLoading ? (
        <div className="w-full h-[573px]">
          <div className="flex justify-center items-center flex-col h-full">
            <img src={LoadingInventory} alt="Report Loading" />
            <Paragraph
              variant={'md'}
              className="text-dark400 mt-3 flex items-center"
            >
              Please wait while we generate your report
            </Paragraph>
            <Button
              variant="tiny-transparent"
              className={clsx('p-1 border-0 h-7 min-w-fit rounded-none mr-1')}
              label={'Cancel Generation'}
              overrideSize={true}
              labelClass="p-0 text-darkPrimary underline"
              onClick={() => {}}
            />
          </div>
        </div>
      ) : (
        <div className="w-full h-[573px]">
          <div className="flex justify-center items-center flex-col h-full">
            <img src={Frame} alt="No Report" />
            <Paragraph
              variant={'md'}
              className="text-dark400 mt-3 flex items-center"
            >
              Select the filters and
              <Title variant={'md'} className={'px-0.5'}>
                {' Generate Report '}
              </Title>
              to view the data
            </Paragraph>
          </div>
        </div>
      )}
    </div>
  );
}
