import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { useMediaQuery } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as XLSX from 'xlsx';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router';
import API from '../../../libs/axios';
import {
  compareDateFormat,
  decimalDisplay,
  SharedContext
} from '../../../utils/common';
import GdnProductsTableRow from './GdnProductsTableRow';
import GdnBulkUpload from './GdnBulkUpload';
import { ORGANIZATION_TYPES } from '../../../constants';
import WarningIcon from '../../../assets/icons/warning.svg';
import downloadFileIcon from '../../../assets/icons/templateDownload.svg';
import O360GdnProductsTableRow from './O360GdnProductsTableRow';

const GdnProductsTable = ({
  vehicleType,
  formik,
  GDNview,
  setResetProducts,
  reversed,
  totalOrderedQuantity,
  setTotalOrderedQuantity,
  totalDispatchedQuantity,
  setTotalDispatchedQuantity,
  reverseFlag,
  pickingTask,
  totalReceivedQuantity,
  batchExpiry
}) => {
  const { setAPILoader, organizationType } = useContext(SharedContext);
  const location = useLocation();
  const [rows, setRows] = useState(formik?.values?.Order?.products);
  const [batches, setBatches] = useState([]);
  const [batchDisable, setBatchDisable] = useState(false);
  const [disabledAvailableQuantity, setDisabledAvailableQuantity] =
    useState('');
  const [, setErrorDialog] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [data, setData] = useState([]);
  let initialValidation = {
    productId: false,
    batchNumber: false,
    expiryDate: false,
    actualDispatchedQuantity: false,
    recoverableDamageQuantity: false,
    unsellableDamageQuantity: false
  };

  const [, setValidation] = useState(initialValidation);

  let initialProductValues = {
    id: '',
    Product: {},
    orderedQuantity: 0,
    batch: '',
    useState,
    actualDispatchedQuantity: ''
  };
  const [productValues, setProductValues] = useState(initialProductValues);
  const [, setRemainingQty] = useState(productValues?.orderedQuantity || 0);
  const [searchProductValue] = useState('');
  const [, setProducts] = useState([]);
  const [, setParentIndex] = useState(null);
  const [, setScanOpen] = useState(false);
  const [, setProductCount] = useState(null);

  useEffect(() => {
    if (reverseFlag) {
      setRows(formik.values.Order.products);
      setTotalOrderedQuantity(
        formik.values.Order.products.reduce(
          (acc, val) => acc + (+val.orderedQuantity || 0),
          0
        )
      );
      setTotalDispatchedQuantity(
        formik.values.Order.products?.reduce((acc, val) => {
          if (!val.GDNGroupBatches?.length) return acc;

          return (
            acc +
              val.GDNGroupBatches?.reduce((a, obj) => {
                return a + (Number(obj?.actualDispatchedQuantity) || 0);
              }, 0) || 0
          );
        }, 0)
      );
    } else {
      formik.setFieldValue('Order.products', rows);
      setTotalOrderedQuantity(
        rows.reduce((acc, val) => acc + (+val.orderedQuantity || 0), 0)
      );
      setTotalDispatchedQuantity(
        rows.reduce((acc, val) => {
          if (!val.GDNGroupBatches?.length) return acc;

          return (
            acc +
              val.GDNGroupBatches?.reduce((a, obj) => {
                return a + (Number(obj?.actualDispatchedQuantity) || 0);
              }, 0) || 0
          );
        }, 0)
      );
    }
  }, [rows, formik.values]);

  useEffect(() => {
    getProducts(searchProductValue);
  }, [searchProductValue]);

  const resetProductSection = () => {
    setBatches([]);
    setProductValues(initialProductValues);
    setParentIndex(null);
    setBatchDisable(false);
    setDisabledAvailableQuantity('');
    setValidation(initialValidation);
    setProductCount(null);
  };

  const insertChildRowHandler = (parentIndex, rowData) => {
    setBatches([]);
    setBatchDisable(false);
    setDisabledAvailableQuantity('');
    setProductValues({
      ...productValues,
      ...{
        Product: { ...rowData.Product },
        ...{ orderedQuantity: rowData.orderedQuantity }
      }
    });
    setParentIndex(parentIndex);
  };

  const getValidDate = (date) => {
    return date ? moment(date) : null;
  };

  const getProducts = (searchProductValue) => {
    let filters = {
      colVal: {
        isActive: 1
      },
      sortable: ''
    };
    setAPILoader(true);
    API.get(`/products`, {
      params: {
        search: searchProductValue,
        filters,
        columns: ['name', 'barcode'],
        companyId: formik?.values?.Order?.companyId
      }
    })
      .then((res) => {
        if (res.data) {
          setProducts(res.data);
        }
      })
      .finally(() => setAPILoader(false));
  };

  const getBatches = async (nodeId, companyId, productId) => {
    setAPILoader(true);
    let batch = [];
    let res = await API.get(`inventories/batches`, {
      params: {
        nodeId,
        ...(organizationType === ORGANIZATION_TYPES.THIRD_PARTY_SERVICE_PROVIDER
          ? { companyId }
          : {}),
        ...(productId ? { productId } : {}),
        orderId: formik?.values?.Order?.id,
        rejectReserved: pickingTask ? false : true
      }
    });
    if (res.data) {
      if (batchDisable) {
        let availableQuantity = res?.data[0]?.availableQuantity;
        setDisabledAvailableQuantity(availableQuantity);
      } else {
        if (productId?.length) {
          batch = res?.data?.map((idetail) => {
            return {
              productId: idetail?.Product?.id,
              batches: idetail.InventoryDetail?.flatMap((batch) => {
                let batchDetails = {
                  id: batch?.id,
                  batchNumber: batch?.batchNumber,
                  expiryDate: null,
                  actualExpiry: null,
                  actualMfg: null,
                  manufacturingDate: null,
                  MRP: null,
                  promoName: null,
                  promoQuantity: null
                };
                if (
                  batch?.promoName &&
                  batch?.availableQuantity - batch?.promoQuantity > 0
                ) {
                  return [
                    {
                      ...batchDetails,
                      availableQuantity:
                        batch?.availableQuantity - batch?.promoQuantity,
                      promoName: null,
                      promoQuantity: 0
                    },
                    {
                      ...batchDetails,
                      availableQuantity: batch?.promoQuantity
                    }
                  ];
                } else {
                  return {
                    ...batchDetails,
                    availableQuantity: batch?.availableQuantity
                  };
                }
              })
            };
          });
        } else {
          batch = res?.data[0]?.InventoryDetail?.flatMap((batch) => {
            let batchDetails = {
              id: batch?.id,
              batchNumber: batch?.batchNumber,
              expiryDate: compareDateFormat(batch?.expiryDate),
              actualExpiry: batch?.expiryDate,
              actualMfg: batch?.manufacturingDate,
              manufacturingDate: compareDateFormat(batch?.manufacturingDate),
              MRP: batch?.MRP?.toString(),
              promoName: batch?.promoName?.toString(),
              promoQuantity: batch?.promoQuantity?.toString()
            };
            if (
              batch?.promoName &&
              batch?.availableQuantity - batch?.promoQuantity > 0
            ) {
              return [
                {
                  ...batchDetails,
                  availableQuantity:
                    batch?.availableQuantity - batch?.promoQuantity,
                  promoName: null,
                  promoQuantity: 0
                },
                {
                  ...batchDetails,
                  availableQuantity: batch?.promoQuantity
                }
              ];
            } else {
              return {
                ...batchDetails,
                availableQuantity: batch?.availableQuantity
              };
            }
          });
          const totalAvailable = (res?.data[0]?.InventoryDetail || []).reduce(
            (acc, inventoryDetail) =>
              acc + (inventoryDetail.availableQuantity || 0),
            0
          );
          setProductCount(totalAvailable);
          setBatches(batch);
        }
      }
      setAPILoader(false);
    }

    return batch;
  };

  useEffect(() => {
    const setProductValues = async () => {
      const batches = await getBatches(
        formik.values.Order.pickupId,
        formik.values.Order.companyId,
        rows?.map((row) => row.Product?.id)
      );
      const copiedRows = [...rows];

      for (let i = 0; i < copiedRows.length; i++) {
        let productBatches = batches.filter(
          (b) => b.productId == copiedRows[i].Product?.id
        )?.[0];
        if (location.state == 'Dispatch_full' && !pickingTask) {
          // eslint-disable-next-line no-undef
          const conversionFactor = element?.secondaryUomId
            ? // eslint-disable-next-line no-undef
              element?.Product?.ProductUOMs?.find(
                // eslint-disable-next-line no-undef
                (u) => u.uomId === element?.secondaryUomId
              )?.conversionFactor
            : 1;
          copiedRows[i].GDNGroupBatches = productBatches.batches.map((b) => ({
            ...b,
            actualDispatchedQuantity: Math.min(
              b.availableQuantity / conversionFactor,
              copiedRows[i].orderedQuantity / conversionFactor
            )
          }));
          copiedRows[i].actualQuantity = Math.min(
            productBatches?.batches?.[0]?.availableQuantity / conversionFactor,
            copiedRows[i].orderedQuantity / conversionFactor
          );
        } else {
          if (!GDNview && !pickingTask) {
            copiedRows[i].GDNGroupBatches = productBatches.batches;
            copiedRows[i].actualQuantity = 0;
          }
        }
      }
      setRows(copiedRows);
    };

    if (batchExpiry) {
      if (productValues.Product?.id) {
        getBatches(
          formik.values.Order.pickupId,
          formik.values.Order.companyId,
          productValues.Product.id
        );
      }
      const currentProduct = rows?.find(
        (product) => product?.Product?.id === productValues?.Product?.id
      );
      let actualQuantity = returnActualDispatchedQuantity(currentProduct);
      const remainingQty = productValues?.orderedQuantity
        ? productValues?.orderedQuantity - actualQuantity
        : 0;
      if (productValues?.actualDispatchedQuantity) {
        setRemainingQty(
          remainingQty - Number(productValues?.actualDispatchedQuantity) < 0
            ? 0
            : remainingQty - productValues?.actualDispatchedQuantity
        );
      } else {
        setRemainingQty(remainingQty < 0 ? 0 : remainingQty);
      }
    } else {
      setProductValues();
    }
  }, [productValues, batchExpiry, location]);

  const returnActualDispatchedQuantity = (product) => {
    let actualQuantity = product?.GDNGroupBatches?.reduce(function (acc, obj) {
      return Number(acc) + (Number(obj?.actualDispatchedQuantity) || 0);
    }, 0);

    return actualQuantity;
  };

  const exportToXlsx = () => {
    const incorrect = data.products?.filter((p, i) =>
      errorData.find((e) => e?.row == i + 2)
    );
    const messages = errorData
      .filter((e) => data.products.find((p, i) => e.row == i + 2))
      .map((e) => e.message);
    let rows = incorrect?.map((row, i) => [
      row?.name,
      row?.batchNumber,
      row?.manufacturingDate,
      row?.expiryDate,
      row?.dispatchedQuantity,
      messages[i]
    ]);
    const exportData = [
      [
        'SKU Name/SKU Code',
        'Batch #',
        'Manufacturing Date',
        'Expiry Date',
        'Dispatched Qty',
        'Reason'
      ],
      ...rows
    ];

    const ws = XLSX.utils.aoa_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Incorrect rows');

    XLSX.writeFile(wb, 'incorrect_rows.xlsx');
  };

  const isMobile = useMediaQuery('(max-width: 768px)');

  const handleExportSection = () => {};

  return (
    <>
      <div className="flex justify-between">
        {GDNview ? (
          <p className="text-xl font-semibold">
            GDN Details
            {reversed && (
              <span className="text-error">
                <pre className="font-bold inline"> · </pre>REVERSED
              </span>
            )}
          </p>
        ) : (
          <p className="text-xl font-semibold">
            Fill in The Inventory Details <span className="text-error">*</span>
          </p>
        )}
        <GdnBulkUpload
          setErrorData={setErrorData}
          setErrorDialog={setErrorDialog}
          formik={formik}
          rows={rows}
          setRows={setRows}
          getValidDate={getValidDate}
          GDNview={GDNview}
          reversed={reversed}
          errorData={errorData}
          returnActualDispatchedQuantity={returnActualDispatchedQuantity}
          setData={setData}
          vehicleType={vehicleType}
          totalOrderedQuantity={totalOrderedQuantity}
          totalDispatchedQuantity={totalDispatchedQuantity}
          totalReceivedQuantity={totalReceivedQuantity}
          handleExportSection={handleExportSection}
        />
      </div>
      {reversed && <p>{formik.values?.reversalReason}</p>}
      {errorData?.length > 0 && (
        <div className="flex p-4 bg-[#FFEBEB] mt-4 justify-between">
          <div className="flex gap-2">
            <img src={WarningIcon} alt="Warning" />
            <p className="text-sm">
              File not uploaded because of issues in file.
            </p>
          </div>
          <div onClick={exportToXlsx} className="flex gap-2 cursor-pointer">
            <img src={downloadFileIcon} alt="Download" />
            <p className="text-sm">Download File</p>
          </div>
        </div>
      )}
      <TableContainer
        component={Paper}
        className="mt-10"
        sx={{
          borderRadius: '16px',
          overflow: 'hidden',
          border: '1px solid rgba(75, 137, 200, 0.2) !important',
          boxShadow: '4px 4px 16px rgb(75 137 200 / 12%) !important',
          '& .MuiTable-root': {
            borderCollapse: 'unset'
          }
        }}
      >
        <Grid className="scroll-table">
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell className="w-1/5 text-sm color-headerCell">
                  Sku Code & Name
                </TableCell>
                <TableCell className="w-1/6 text-sm color-headerCell">
                  UoM
                </TableCell>
                <TableCell className="text-sm color-headerCell">
                  Required Qty
                </TableCell>
                <TableCell className="w-[15%] text-sm color-headerCell">
                  Dispatched Qty
                </TableCell>
                <TableCell className="text-sm color-headerCell">
                  Remaining Qty
                </TableCell>
                {batchExpiry && (
                  <TableCell className="text-sm color-headerCell"></TableCell>
                )}
                {!batchExpiry && !GDNview && (
                  <TableCell className="text-sm color-headerCell">
                    Available Qty
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, parentIndex) =>
                batchExpiry ? (
                  <GdnProductsTableRow
                    formik={formik}
                    key={parentIndex}
                    GDNview={GDNview}
                    row={row}
                    headers={
                      isMobile
                        ? [
                            'Sku Code',
                            'UoM',
                            'Required Qty',
                            'Dispatched Qty',
                            'Remaining Qty'
                          ]
                        : []
                    }
                    batchDisable={batchDisable}
                    setBatchDisable={(val) => setBatchDisable(val)}
                    disabledAvailableQuantity={disabledAvailableQuantity}
                    setDisabledAvailableQuantity={(value) =>
                      setDisabledAvailableQuantity(value)
                    }
                    parentIndex={parentIndex}
                    rows={rows}
                    settleRemainingQty={(value) => setRemainingQty(value)}
                    setRows={setRows}
                    batchInfo={row.GDNGroupBatches}
                    insertChildRowHandler={() =>
                      insertChildRowHandler(parentIndex, row)
                    }
                    resetProductSection={() => resetProductSection()}
                    setScanOpen={setScanOpen}
                    setResetProducts={setResetProducts}
                    inventoryBatches={batches}
                    getBatches={getBatches}
                    pickingTask={pickingTask}
                  />
                ) : (
                  <O360GdnProductsTableRow
                    key={parentIndex}
                    formik={formik}
                    GDNview={GDNview}
                    row={row}
                    headers={
                      isMobile
                        ? [
                            'Sku Code',
                            'UoM',
                            'Required Qty',
                            'Dispatched Qty',
                            'Remaining Qty'
                          ]
                        : []
                    }
                    batchDisable={batchDisable}
                    setBatchDisable={(val) => setBatchDisable(val)}
                    disabledAvailableQuantity={disabledAvailableQuantity}
                    setDisabledAvailableQuantity={(value) =>
                      setDisabledAvailableQuantity(value)
                    }
                    parentIndex={parentIndex}
                    rows={rows}
                    settleRemainingQty={(value) => setRemainingQty(value)}
                    setRows={setRows}
                    batchInfo={row.GDNGroupBatches}
                    insertChildRowHandler={() =>
                      insertChildRowHandler(parentIndex, row)
                    }
                    resetProductSection={() => resetProductSection()}
                    setScanOpen={setScanOpen}
                    setResetProducts={setResetProducts}
                    inventoryBatches={batches}
                    getBatches={getBatches}
                    pickingTask={pickingTask}
                  />
                )
              )}
            </TableBody>
          </Table>

          {!isMobile && (
            <Table
              aria-label="collapsible table"
              className="bg-light w-full rounded-b-lg"
            >
              <TableBody>
                <TableRow className="flex justify-between items-center p-5">
                  <div></div>
                  <div className="flex">
                    <div className={'justify-center'}>
                      <p className="text-xs mx-2">
                        Total Ordered (Primary):{' '}
                        <span className="text-base">
                          {decimalDisplay(totalOrderedQuantity)}
                        </span>
                        <span className="text-xs ml-1">units</span>
                      </p>
                    </div>
                    <div>
                      <p className="text-xs mx-2">
                        Total Received (Primary):{' '}
                        <span className="text-base">
                          {decimalDisplay(totalDispatchedQuantity)}
                        </span>
                        <span className="text-xs ml-1">units</span>
                      </p>
                    </div>
                    <div>
                      <p className="text-xs mx-2">
                        Total Remaining (Primary):{' '}
                        <span className="text-base">
                          {totalOrderedQuantity - totalDispatchedQuantity < 0
                            ? 0
                            : decimalDisplay(
                                totalOrderedQuantity - totalDispatchedQuantity
                              )}
                        </span>
                        <span className="text-xs ml-1">units</span>
                      </p>
                    </div>
                  </div>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Grid>
      </TableContainer>
    </>
  );
};

export default GdnProductsTable;
