import { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  Typography,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import { isRequired } from '../../../utils/validators';

const useStyles = makeStyles(() => ({
  textBox: {
    height: 34
  },
  labelBox: {
    '& label': {
      paddingTop: 7
    }
  }
}));

export default function AddUoMView({
  addUoM,
  open,
  handleClose,
  selectedUoM,
  formErrors,
  clickedAlready,
  setClickedAlready
}) {
  const classes = useStyles();

  const [validation, setValidation] = useState({});
  const [name, setName] = useState('');
  const [isActive, setActive] = useState(true);
  const [weightMetric, setWeightMetric] = useState(true);

  useEffect(() => {
    if (open) {
      if (selectedUoM) {
        setName(selectedUoM.name || '');
        setActive(!!selectedUoM.isActive);
        setWeightMetric(!!selectedUoM.weightMetric);
      } else {
        setName('');
        setActive(true);
        setWeightMetric(false);
        setValidation({});
      }
    }
  }, [selectedUoM, open]);

  const handleSubmit = () => {
    const newUoM = {
      name,
      isActive,
      weightMetric
    };
    setValidation({
      name: true
    });
    if (isRequired(name)) {
      setClickedAlready(true);
      addUoM(newUoM);
    }
  };

  return (
    <div style={{ display: 'inline' }}>
      <form>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle disableTypography className="title">
            {!selectedUoM ? 'Add UoM' : 'Edit UoM'}
          </DialogTitle>
          <DialogContent>
            {formErrors}
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth={true}
                  margin="dense"
                  id="name"
                  label="Name"
                  type="text"
                  variant="outlined"
                  value={name}
                  inputProps={{ className: classes.textBox }}
                  className={classes.labelBox}
                  onChange={(e) => setName(e.target.value)}
                  onBlur={() => setValidation({ ...validation, name: true })}
                />
                {validation.name && !isRequired(name) ? (
                  <Typography color="error">Name is required!</Typography>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  checked={weightMetric}
                  onChange={(e) => setWeightMetric(e.target.checked)}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                Weight Metric
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  checked={isActive}
                  onChange={(e) => setActive(e.target.checked)}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                Active
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="groupBtn">
            <Tooltip title="Cancel">
              <Button onClick={handleClose} color="default" variant="contained">
                Cancel
              </Button>
            </Tooltip>
            <Tooltip title={!selectedUoM ? 'Add UoM' : 'Update UoM'}>
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
                disabled={clickedAlready}
              >
                {!selectedUoM ? 'Add UoM' : 'Update UoM'}
              </Button>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
