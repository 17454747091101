/*eslint-disable*/
import { LinearProgress, TableCell, TableRow, Tooltip } from '@mui/material';
import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { capitalizeWords } from './AddOrder';
import OptionsMenu from './Options';
import { constants } from './constants';
import { icons } from './statusIcons';
import PartiallyCompleteIcon from '../../assets/icons/partial_complete.svg';
import { ExpandedRow } from './OrderTable';
import Button from '../../core-components/atoms/Button';
import { BLACK } from '../../constants/colors';
import DeleteIcon from '../../assets/icons/delete.svg';
import ClosedIcon from '../../assets/icons/closed.svg';
import InboundIcon from '../../assets/icons/inboundNew.svg';
import OutboundIcon from '../../assets/icons/outboundNew.svg';
import TransferIcon from '../../assets/icons/transferNew.svg';
import LogisticsIcon from '../../assets/icons/shipment.svg';
import ReturnIcon from '../../assets/icons/returnOrder.svg';
import CancelledIcon from '../../assets/icons/cancelled.svg';
import putwayProgress from '../../assets/icons/putaway.svg';
import EditIcon from '../../assets/icons/edit-row.svg';
import LinearProgressBar from '../../core-components/atoms/LinerProgressBar';
import { DialogueConfirmationOpen } from '../../atomicComponents/DialogueConfirmation';
import {
  decimalDisplay,
  getCurrencySymbol,
  SharedContext
} from '../../utils/common';
import { OutboundTMSShipmentTracking } from './components/tracking/OutboundTMSShipmentTracking';
import { Label, Paragraph, Title } from '../../core-components/atoms/Text';
import clsx from 'clsx';
import { InboundTMSShipmentTracking } from './components/tracking/InboundTMSShipmentTracking';
import { TransferTMSShipmentTracking } from './components/tracking/TransferTMSShipmentTracking';
import {
  InBoundShipmentProgress,
  OutboundShipmentProgress,
  TransferShipmentProgress
} from './components/shipmentProgress';
import RightArrowIcon from '../../assets/icons/rightArrow.svg';
import { LogisticTMSShipmentTracking } from './components/tracking/LogisticTMSShipmentTracking';
import AlertIcon from '../../assets/icons/error.svg';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';
import { ORGANIZATION_TYPES } from '../../constants';

const calculateProgress = (order) => {
  let progress = 0;
  if (order.moveType) progress += 20;
  if (order.Company?.id) progress += 20;
  if (order.warehouseDropOffId || order.warehousePickUpId) progress += 20;
  if (order.dropOffId || order.pickupId) progress += 20;
  if (order.OrderProductGroups?.length > 0 || order.products?.length > 0)
    progress += 20;

  return progress;
};

const CommonCells = ({
  order,
  orderIdFilter,
  settings,
  extraColumns,
  draft = false
}) => {
  const expectedDeliveryDate = moment(order.expectedDelivery);
  const currentDate = moment();
  const timeDifference = expectedDeliveryDate.diff(currentDate);
  const daysDifference = moment.duration(timeDifference).asDays().toFixed(0);

  return (
    <>
      <TableCell
        component="th"
        scope="row"
        style={{
          verticalAlign: 'top'
        }}
      >
        <div className="flex gap-2">
          <div className="flex items-center">
            {order.moveType == 'INWARD' &&
            order.moveSubType === 'SALES_RETURN' ? (
              <img src={ReturnIcon} className="w-4 h-4" />
            ) : order.moveType == 'INWARD' ? (
              <img src={InboundIcon} className="w-4 h-4" />
            ) : order.moveType == 'OUTBOUND' ? (
              <img src={OutboundIcon} className="w-4 h-4" />
            ) : order.moveType == 'TRANSFER' ? (
              <img src={TransferIcon} className="w-4 h-4" />
            ) : (
              <img src={LogisticsIcon} className="w-4 h-4" />
            )}
          </div>
          <div className="flex flex-col gap-1">
            <div
              className={`${
                orderIdFilter &&
                order.customId == orderIdFilter &&
                'bg-highlight'
              }`}
            >
              <Paragraph variant={'xs'} className={`text-primary`}>
                {order.customId}
              </Paragraph>
            </div>

            <div className={'flex flex-row items-center'}>
              <Title variant={'xs'}>Ref No.</Title>
              <Paragraph
                variant={'xs'}
                className="font-semibold text-xs text-center pl-1"
              >
                {order.referenceId
                  ? order.referenceId.length > 13
                    ? order.referenceId.substring(0, 13) + '...'
                    : order.referenceId
                  : '-'}
              </Paragraph>
            </div>

            {order.orderLoss ? (
              <Tooltip placement="top" title={`${order.orderLoss} items lost`}>
                <div>
                  <img src={AlertIcon} />
                </div>
              </Tooltip>
            ) : null}
          </div>
        </div>
      </TableCell>
      <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
        <div className="flex flex-row gap-1.5 items-center">
          <Paragraph variant={'sm'}>
            {order.pickupNode?.name
              ? order.pickupNode?.name?.length > 20
                ? order.pickupNode?.name?.substring(0, 20) + '...'
                : order.pickupNode?.name
              : '-'}
          </Paragraph>
          {order.dropOffNode?.name && order.dropOffNode?.name && (
            <img
              src={RightArrowIcon}
              alt="arrow"
              className="flex-shrink-0 w-[20px]"
            />
          )}
          <Paragraph variant={'sm'}>
            {order.dropOffNode?.name
              ? order.dropOffNode?.name?.length > 20
                ? order.dropOffNode?.name?.substring(0, 20) + '...'
                : order.dropOffNode?.name
              : '-'}
          </Paragraph>
        </div>
      </TableCell>
      <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
        <div className="flex flex-col gap-1">
          <div className="pl-1">
            <Paragraph className="text-[12px] font-normal font-roboto text-dark800">
              {capitalizeWords(
                order.moveSubType
                  ? order.moveSubType
                  : order.moveType == 'INWARD'
                  ? 'INBOUND'
                  : order?.moveType
              )}
            </Paragraph>
          </div>
          <p className="text-xs pl-1">
            <span className="text-[10px] font-normal font-roboto text-dark600">
              {order?.Company?.name}
            </span>
          </p>
        </div>
      </TableCell>
      {extraColumns?.indexOf('Creation Date') !== -1 && !draft && (
        <TableCell
          component="th"
          scope="row"
          style={{
            verticalAlign: 'top'
          }}
        >
          <div className="flex flex-col gap-1">
            <Paragraph className="text-xs">
              {moment(order.createdAt).format(
                settings?.dateFormat || 'DD/MM/YY'
              )}
            </Paragraph>
            {daysDifference >= 0 && (
              <div className="flex flex-row gap-0.5 items-center">
                <Title variant={'xs'}>EST:</Title>
                <Paragraph variant={'xs'}>{daysDifference} Days Left</Paragraph>
              </div>
            )}
          </div>
        </TableCell>
      )}
    </>
  );
};

export const AllOrderRow = ({
  order,
  setShipmentPopup,
  closeOrder,
  setSelectedOrder,
  refetch,
  setActiveShipment,
  setActiveShipmentOrder,
  setActiveShipmentPopup,
  setAssignVehiclePopup,
  setCancelPopUp,
  setView,
  orderIdFilter,
  companyNameFilter,
  shipmentIdFilter,
  activeShipment,
  setShowCreateShipmentTMS,
  extraColumns
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const [expanded, setExpanded] = React.useState(false);
  const [optionsClick, setOptionsClick] = React.useState(false);
  const [shipmentAnchorEl, setShipmentAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const { settings, organization } = useContext(SharedContext);
  const orderOptions = [];

  if (
    order.status == 'IN_PROGRESS' &&
    !order.Shipments.find((s) => s.status.key === 'COMPLETED') &&
    isFeatureEnabled(FLAGS.ORDER_CANCELLATION)
  ) {
    orderOptions.push({
      label: (
        <div className="flex gap-3">
          <img src={CancelledIcon} className="w-4" />
          <span>Cancel Order</span>
        </div>
      ),
      onClick: () => {
        setCancelPopUp(true, 'order');
        setActiveShipmentOrder(order);
      }
    });
  }
  if (order.status == 'PENDING') {
    orderOptions.push({
      label: (
        <div className="flex gap-3">
          <img src={EditIcon} className="w-4" />
          <span>Edit Order</span>
        </div>
      ),
      onClick: () => {
        navigate(`/routing/edit-order/${order.customId}`);
      }
    });
    if (isFeatureEnabled(FLAGS.ORDER_CANCELLATION)) {
      orderOptions.push({
        label: (
          <div className="flex gap-3">
            <img src={CancelledIcon} className="w-4" />
            <span>Cancel Order</span>
          </div>
        ),
        onClick: () => {
          setActiveShipmentOrder(order);
          setCancelPopUp(true, 'order');
        }
      });
    }
  } else if (
    order.status == 'PARTIALLY_COMPLETE' ||
    order.status == 'PUTAWAY_IN_PROGRESS'
  ) {
    const closeable = order.Shipments.reduce(
      (acc, curr) =>
        acc &&
        (curr.status.key == 'COMPLETED' || curr.status.key == 'CANCELLED'),
      true
    );

    closeable &&
      isFeatureEnabled(FLAGS.CLOSE_ORDER) &&
      orderOptions.push({
        label: (
          <div className="flex gap-3">
            <img src={ClosedIcon} className="w-4" />
            <span>Close Order</span>
          </div>
        ),
        onClick: (e) => {
          e.stopPropagation();
          return DialogueConfirmationOpen(
            order,
            false,
            closeOrder,
            '',
            'Are you sure you want to Close this Order'
          );
        }
      });
  } else if (order.status == 'DRAFT') {
    orderOptions.push({
      label: (
        <div className="flex gap-3">
          <img src={EditIcon} className="w-4" />
          <span>Edit Order</span>
        </div>
      ),
      onClick: () => {
        navigate(`/routing/edit-order/${order.customId}`);
      }
    });
  }

  const checkIsOrderCompleted =
    order?.OrderProductGroups?.length == 0
      ? false
      : order?.OrderProductGroups?.reduce(
          (acc, curr) => acc.quantity == 0 && curr,
          true
        );

  const activeShipments = order?.Shipments?.filter(
    (s) => s.status.key !== 'CANCELLED' && s.status.key !== 'COMPLETED'
  );

  useEffect(() => {
    if (companyNameFilter || orderIdFilter || shipmentIdFilter) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [companyNameFilter, orderIdFilter, shipmentIdFilter]);

  const expectedDeliveryDate = moment(order.expectedDelivery);
  const currentDate = moment();
  const timeDifference = expectedDeliveryDate.diff(currentDate);
  const daysDifference = moment.duration(timeDifference).asDays().toFixed(0);

  const totalQty = order.totalOriginalOrderedQuantity;

  const totalCost = order.OrderProductGroups?.reduce(
    (acc, curr) =>
      acc +
      (curr.originalOrderedQuantity || 0) *
        (curr.sellingPrice || curr.costPrice || 0),
    0
  );

  return (
    <>
      <TableRow
        sx={
          expanded &&
          order.Shipments?.length > 0 && { '& > *': { borderBottom: 'unset' } }
        }
        className="cursor-pointer group"
        onClick={(e) => {
          e.stopPropagation();
          if (order?.Shipments?.length > 0)
            expanded ? setExpanded(false) : setExpanded(true);
        }}
      >
        <TableCell
          component="th"
          scope="row"
          style={{
            width: '15%',
            verticalAlign: 'top',
            paddingLeft: '10px',
            paddingTop: '16px'
          }}
        >
          <div className="flex gap-2">
            <div className="flex items-center">
              {order.moveType == 'INWARD' &&
              order.moveSubType === 'SALES_RETURN' ? (
                <img src={ReturnIcon} className="w-4 h-4" />
              ) : order.moveType == 'INWARD' ? (
                <img src={InboundIcon} className="w-4 h-4" />
              ) : order.moveType == 'OUTBOUND' ? (
                <img src={OutboundIcon} className="w-4 h-4" />
              ) : order.moveType == 'TRANSFER' ? (
                <img src={TransferIcon} className="w-4 h-4" />
              ) : (
                <img src={LogisticsIcon} className="w-4 h-4" />
              )}
            </div>
            <div className="flex flex-col gap-1">
              <div
                className={`${
                  orderIdFilter &&
                  order.customId == orderIdFilter &&
                  'bg-highlight'
                }`}
              >
                <Paragraph variant={'xs'} className={`text-primary`}>
                  {order.customId}
                </Paragraph>
              </div>
              <div className={'flex flex-row items-center'}>
                <Title variant={'xs'}>Ref No.</Title>
                <Paragraph
                  variant={'xs'}
                  className="font-semibold text-xs text-center pl-1"
                >
                  {order.referenceId
                    ? order.referenceId.length > 16
                      ? order.referenceId.substring(0, 16) + '...'
                      : order.referenceId
                    : '-'}
                </Paragraph>
              </div>
            </div>
            {order.orderLoss ? (
              <div className="flex items-center">
                <Tooltip
                  placement="top"
                  title={`${order.orderLoss} items lost`}
                >
                  <img src={AlertIcon} />
                </Tooltip>
              </div>
            ) : null}
          </div>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{
            width: '23%',
            verticalAlign: 'top'
          }}
        >
          <div className="flex flex-row gap-1.5 items-center">
            <Paragraph variant={'sm'}>
              {order.pickupNode?.name
                ? order.pickupNode?.name?.length > 20
                  ? order.pickupNode?.name?.substring(0, 20) + '...'
                  : order.pickupNode?.name
                : '-'}
            </Paragraph>
            {order.dropOffNode?.name && order.dropOffNode?.name && (
              <img
                src={RightArrowIcon}
                alt="arrow"
                className="flex-shrink-0 w-[20px]"
              />
            )}
            <Paragraph variant={'sm'}>
              {order.dropOffNode?.name
                ? order.dropOffNode?.name?.length > 20
                  ? order.dropOffNode?.name?.substring(0, 20) + '...'
                  : order.dropOffNode?.name
                : '-'}
            </Paragraph>
          </div>
        </TableCell>
        {extraColumns?.indexOf('Creation Date') !== -1 && (
          <TableCell
            component="th"
            scope="row"
            style={{
              width: '12%',
              verticalAlign: 'top'
            }}
          >
            <Paragraph variant={'sm'}>
              {moment(order.createdAt).format(
                settings?.dateFormat || 'DD/MM/YYYY'
              )}
            </Paragraph>
            {daysDifference >= 0 && (
              <div className="flex flex-row gap-0.5 items-center">
                <Title variant={'xs'}>EST:</Title>
                <Paragraph variant={'xs'}>{daysDifference} Days Left</Paragraph>
              </div>
            )}
          </TableCell>
        )}

        <TableCell
          component="th"
          scope="row"
          style={{
            width: '20%',
            verticalAlign: 'top'
          }}
        >
          <div className="flex flex-col gap-1">
            <Paragraph variant={'sm'}>
              {capitalizeWords(
                order.moveSubType
                  ? order.moveSubType
                  : order.moveType == 'INWARD'
                  ? 'INBOUND'
                  : order?.moveType
              )}
            </Paragraph>
            <Paragraph variant={'xs'} className="text-dark600">
              {order?.Company?.name}
            </Paragraph>
          </div>
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          style={{
            verticalAlign: 'top'
          }}
        >
          <div className="flex gap-2 items-center">
            {order.status == 'PARTIALLY_COMPLETE' ? (
              <img src={PartiallyCompleteIcon} className="w-3 h-3" />
            ) : (
              <div className={clsx(icons[order.status], 'w-1.5 h-1.5')} />
            )}
            <Paragraph variant={'sm'}>
              {constants.ORDER_STATUS[order.status]}
            </Paragraph>
          </div>
          <div className="flex gap-2 mt-1">
            <div className="w-2.5 h-2.5" />
            <p className="flex gap-1">
              <Label variant={'xs'}>
                {moment(order.updatedAt).format('DD/MM/YYYY')}
              </Label>
              <Label variant={'xs'}>
                {moment(order.updatedAt).format('hh:mm A')}
              </Label>
            </p>
          </div>
        </TableCell>

        {(extraColumns.indexOf('Order Value & Items') !== -1 ||
          extraColumns.indexOf('Order Items') !== -1) && (
          <TableCell
            style={{
              verticalAlign: 'top'
            }}
          >
            <p className="text-xs">
              <span>{decimalDisplay(totalQty)}</span>
              <span className="text-[10px] ml-1">items</span>
            </p>
            {organization?.type == ORGANIZATION_TYPES.MANUFACTURER && (
              <p className="text-xs mt-2">
                <span className="text-[10px] mr-1">
                  {getCurrencySymbol(organization?.defaultCurrency)}
                </span>
                <span>{decimalDisplay(totalCost)}</span>
              </p>
            )}
          </TableCell>
        )}
        <TableCell component="th" scope="row" style={{ paddingTop: '16px' }}>
          <div
            className={`flex gap-4 items-center justify-end ${
              !expanded && !optionsClick ? 'invisible' : 'visible'
            } group-hover:visible`}
          >
            <div
              className={`invisible ${
                !expanded ? 'group-hover:visible' : 'invisible'
              }`}
            >
              {(isFeatureEnabled(FLAGS.BASIC_SHIPMENT_CREATION) ||
                isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)) &&
              activeShipments.length == 0
                ? order.status !== 'CANCELLED' &&
                  order.status !== 'DRAFT' &&
                  order.status !== 'COMPLETED' &&
                  order.status !== 'CLOSED' &&
                  (isFeatureEnabled(FLAGS.TMS) ||
                    isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)) &&
                  !order.isClosed &&
                  order.status !== 'PUTAWAY_IN_PROGRESS' &&
                  !checkIsOrderCompleted && (
                    <Button
                      variant="tertiary"
                      label="Create Shipment"
                      className={'p-2 h-8 rounded border-solid'}
                      overrideSize={true}
                      labelClass="font-medium text-xs "
                      onClick={(e) => {
                        e.stopPropagation();
                        if (
                          (isFeatureEnabled(FLAGS.TMS) || organization.sms) &&
                          isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                        ) {
                          setShowCreateShipmentTMS(true);
                        } else {
                          setShipmentPopup(true);
                        }
                        setSelectedOrder(order);
                      }}
                    />
                  )
                : !isFeatureEnabled(FLAGS.TMS) &&
                  !isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) &&
                  (activeShipments.length == 1 &&
                  order.status !== 'PUTAWAY_IN_PROGRESS' ? (
                    order.moveType !== 'TRANSFER' ? (
                      !activeShipments[0].dockId &&
                      isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) ? (
                        <Button
                          label={
                            'Assign Dock'
                            // order.moveType == 'OUTBOUND'
                            //   ? 'Assign For Loading'
                            //   : 'Assign for Un-Loading'
                          }
                          className={'p-2 h-8 rounded border-solid'}
                          overrideSize={true}
                          labelClass="font-medium text-xs "
                          onClick={() => setExpanded(true)}
                        />
                      ) : order.moveType == 'INWARD' ? (
                        isFeatureEnabled(FLAGS.GRN_CREATION) && (
                          <Button
                            label="Receive Inventory"
                            className={'p-2 h-8 rounded border-solid'}
                            overrideSize={true}
                            labelClass="font-medium text-xs "
                            onClick={() => setExpanded(true)}
                          />
                        )
                      ) : (
                        order.status !== 'PICKING_IN_PROGRESS' &&
                        isFeatureEnabled(FLAGS.GDN_CREATION) && (
                          <Button
                            label="Dispatch Inventory"
                            className={'p-2 h-8 rounded border-solid'}
                            overrideSize={true}
                            labelClass="font-medium text-xs "
                            onClick={() => setExpanded(true)}
                          />
                        )
                      )
                    ) : !activeShipments[0].dockId &&
                      isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) ? (
                      <Button
                        label={'Assign Dock'}
                        // label="Assign For Loading"
                        className={'p-2 h-8 rounded border-solid'}
                        overrideSize={true}
                        labelClass="font-medium text-xs "
                        onClick={() => setExpanded(true)}
                      />
                    ) : (activeShipments[0].dockId ||
                        !isFeatureEnabled(
                          FLAGS.DOCK_CREATION_AND_ASSIGNMENT
                        )) &&
                      activeShipments[0].subStatus?.key !== 'LOAD_DISPATCHED' &&
                      order.status !== 'PICKING_IN_PROGRESS' ? (
                      isFeatureEnabled(FLAGS.GDN_CREATION) && (
                        <Button
                          label="Dispatch Inventory"
                          className={'p-2 h-8 rounded border-solid'}
                          overrideSize={true}
                          labelClass="font-medium text-xs "
                          onClick={() => setExpanded(true)}
                        />
                      )
                    ) : activeShipments[0].dockId &&
                      activeShipments[0].subStatus?.key == 'LOAD_DISPATCHED' &&
                      !activeShipments[0].dropOffDock &&
                      isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) ? (
                      <Button
                        label="Receive Inventory"
                        className={'p-2 h-8 rounded border-solid'}
                        overrideSize={true}
                        labelClass="font-medium text-xs "
                        onClick={() => setExpanded(true)}
                      />
                    ) : (activeShipments[0].dropOffDockId ||
                        !isFeatureEnabled(
                          FLAGS.DOCK_CREATION_AND_ASSIGNMENT
                        )) &&
                      isFeatureEnabled(FLAGS.GRN_CREATION) ? (
                      <Button
                        label="Receive Inventory"
                        className={'p-2 h-8 rounded border-solid'}
                        overrideSize={true}
                        labelClass="font-medium text-xs "
                        onClick={() => setExpanded(true)}
                      />
                    ) : null
                  ) : null)}
            </div>
            {order.status === 'DRAFT' || (
              <RemoveRedEyeIcon
                className={'h-[20px] w-[20px]'}
                onClick={() => {
                  navigate(`/routing/view-order/${order.customId}`);
                }}
              />
            )}
            {(isFeatureEnabled(FLAGS.TMS) ||
            isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
              ? true
              : order.moveType !== 'LOGISTIC') &&
              order.Shipments?.length > 0 &&
              (expanded ? (
                <ExpandLessIcon
                  className={'h-[20px] w-[20px]'}
                  onClick={() => setExpanded(false)}
                />
              ) : (
                <ExpandMoreIcon
                  className={'h-[20px] w-[20px]'}
                  onClick={() => setExpanded(true)}
                />
              ))}
            {orderOptions.length > 0 && (
              <div onClick={(e) => e.stopPropagation()}>
                <OptionsMenu
                  options={orderOptions}
                  closeCallback={() => setOptionsClick(false)}
                  enterCallBack={() => setOptionsClick(true)}
                />
              </div>
            )}
          </div>
        </TableCell>
      </TableRow>
      {expanded &&
        order.Shipments?.length > 0 &&
        (isFeatureEnabled(FLAGS.TMS) ||
        isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) ? (
          order.moveType == 'OUTBOUND' ? (
            <OutboundTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          ) : order.moveType == 'INWARD' ? (
            <InboundTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          ) : order.moveType == 'TRANSFER' ? (
            <TransferTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          ) : (
            <LogisticTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          )
        ) : (
          <ExpandedRow
            expanded={expanded}
            order={order}
            anchorEl={shipmentAnchorEl}
            setAnchorEl={setShipmentAnchorEl}
            setActiveShipmentOrder={setActiveShipmentOrder}
            setActiveShipment={setActiveShipment}
            setCancelPopUp={setCancelPopUp}
            setAssignVehiclePopup={setAssignVehiclePopup}
            refetch={refetch}
            setActiveShipmentPopup={setActiveShipmentPopup}
            setView={setView}
            shipmentIdFilter={shipmentIdFilter}
            shipments={order.Shipments}
          />
        ))}
    </>
  );
};

export const ActiveOrderRow = ({
  order,
  setShipmentPopup,
  closeOrder,
  setSelectedOrder,
  refetch,
  setActiveShipment,
  activeShipment,
  setActiveShipmentOrder,
  setActiveShipmentPopup,
  setAssignVehiclePopup,
  setCancelPopUp,
  setView,
  orderIdFilter,
  companyNameFilter,
  shipmentIdFilter,
  setShowCreateShipmentTMS,
  extraColumns
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const [expanded, setExpanded] = React.useState(false);
  const [optionsClick, setOptionsClick] = React.useState(false);
  const [shipmentAnchorEl, setShipmentAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const { settings, organization, currentUser } = useContext(SharedContext);

  const orderOptions = [];
  if (
    order.status == 'IN_PROGRESS' &&
    (order.moveType !== 'OUTBOUND' || order.S) &&
    isFeatureEnabled(FLAGS.ORDER_CANCELLATION)
  ) {
    orderOptions.push({
      label: (
        <div className="flex gap-3">
          <img src={CancelledIcon} className="w-4" />
          <span>Cancel Order</span>
        </div>
      ),
      onClick: () => {
        setCancelPopUp(true, 'order');
        setActiveShipmentOrder(order);
      }
    });
  }
  if (order.status == 'PENDING') {
    !order.OrdersTasks?.length &&
      orderOptions.push({
        label: (
          <div className="flex gap-3">
            <img src={EditIcon} className="w-4" />
            <span>Edit Order</span>
          </div>
        ),
        onClick: () => {
          navigate(`/routing/edit-order/${order.customId}`);
        }
      });
    if (isFeatureEnabled(FLAGS.ORDER_CANCELLATION)) {
      orderOptions.push({
        label: (
          <div className="flex gap-3">
            <img src={CancelledIcon} className="w-4" />
            <span>Cancel Order</span>
          </div>
        ),
        onClick: () => {
          setActiveShipmentOrder(order);
          setCancelPopUp(true, 'order');
        }
      });
    }
  } else if (
    order.status == 'PARTIALLY_COMPLETE' ||
    order.status == 'PUTAWAY_IN_PROGRESS'
  ) {
    const closeable = order.Shipments.reduce(
      (acc, curr) =>
        acc &&
        (curr.status.key == 'COMPLETED' || curr.status.key == 'CANCELLED'),
      true
    );

    closeable &&
      isFeatureEnabled(FLAGS.CLOSE_ORDER) &&
      orderOptions.push({
        label: (
          <div className="flex gap-3">
            <img src={ClosedIcon} className="w-4" />
            <span>Close Order</span>
          </div>
        ),
        onClick: (e) => {
          e.stopPropagation();
          return DialogueConfirmationOpen(
            order,
            false,
            closeOrder,
            '',
            'Are you sure you want to Close this Order'
          );
        }
      });
  }

  const checkIsOrderCompleted =
    order?.OrderProductGroups?.length == 0
      ? false
      : order?.OrderProductGroups?.reduce(
          (acc, curr) => acc.quantity == 0 && curr,
          true
        );

  const activeShipments = order?.Shipments?.filter(
    (s) => s.status.key !== 'CANCELLED' && s.status.key !== 'COMPLETED'
  );

  const totalQty = order.totalOriginalOrderedQuantity;

  const totalCost = order.OrderProductGroups?.reduce(
    (acc, curr) =>
      acc +
      (curr.originalOrderedQuantity || 0) *
        (curr.sellingPrice || curr.costPrice || 0),
    0
  );

  useEffect(() => {
    if (companyNameFilter || orderIdFilter || shipmentIdFilter) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [companyNameFilter, orderIdFilter, shipmentIdFilter]);

  return (
    <>
      <TableRow
        sx={
          expanded &&
          order.Shipments?.length > 0 && { '& > *': { borderBottom: 'unset' } }
        }
        className="cursor-pointer group"
        onClick={(e) => {
          e.stopPropagation();
          if (order?.Shipments?.length > 0)
            expanded ? setExpanded(false) : setExpanded(true);
        }}
      >
        <CommonCells
          order={order}
          orderIdFilter={orderIdFilter}
          companyNameFilter={companyNameFilter}
          settings={settings}
          extraColumns={extraColumns}
        />
        <TableCell
          component="th"
          scope="row"
          style={{
            verticalAlign: 'top'
          }}
        >
          <div className="flex gap-2 items-center">
            {order.status == 'PARTIALLY_COMPLETE' ? (
              <img src={PartiallyCompleteIcon} className="w-3 h-3" />
            ) : (
              <div className={clsx(icons[order.status], 'w-1.5 h-1.5')} />
            )}
            <p className="text-xs">{constants.ORDER_STATUS[order.status]}</p>
          </div>
          <div className="flex gap-2 mt-1">
            <div className="w-2.5 h-2.5" />
            <Paragraph className=" flex gap-1 text-[10px]">
              <span>{moment(order.updatedAt).format('DD/MM/YYYY')}</span>
              <span>{moment(order.updatedAt).format('hh:mm A')}</span>
            </Paragraph>
          </div>
        </TableCell>
        {(extraColumns.indexOf('Order Value & Items') !== -1 ||
          extraColumns.indexOf('Order Items') !== -1) && (
          <TableCell
            style={{
              verticalAlign: 'top'
            }}
          >
            <p className="text-xs">
              <span>{decimalDisplay(totalQty)}</span>
              <span className="text-[10px] ml-1">items</span>
            </p>
            {organization?.type == ORGANIZATION_TYPES.MANUFACTURER && (
              <p className="text-xs mt-2">
                <span className="text-[10px] mr-1">
                  {getCurrencySymbol(organization?.defaultCurrency)}
                </span>
                <span>{decimalDisplay(totalCost)}</span>
              </p>
            )}
          </TableCell>
        )}
        <TableCell
          component="th"
          scope="row"
          style={{
            verticalAlign: 'top'
          }}
        >
          {!isFeatureEnabled(FLAGS.TMS) &&
          !isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) ? (
            <ShipmentProgress order={order} />
          ) : order.moveType == 'OUTBOUND' ? (
            <OutboundShipmentProgress order={order} />
          ) : order.moveType == 'INWARD' ? (
            <InBoundShipmentProgress order={order} />
          ) : order.moveType == 'TRANSFER' ? (
            <TransferShipmentProgress order={order} />
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell component="th" scope="row" style={{ paddingTop: '16px' }}>
          <div
            className={`flex gap-4 items-center justify-end ${
              !expanded && !optionsClick ? 'invisible' : 'visible'
            } group-hover:visible`}
          >
            <div
              className={`invisible ${
                !expanded ? 'group-hover:visible' : 'invisible'
              }`}
            >
              {isFeatureEnabled(FLAGS.BASIC_SHIPMENT_CREATION) &&
              activeShipments.length == 0
                ? order.status !== 'CANCELLED' &&
                  order.status !== 'DRAFT' &&
                  order.status !== 'COMPLETED' &&
                  order.status !== 'CLOSED' &&
                  !order.isClosed &&
                  order.status !== 'PUTAWAY_IN_PROGRESS' &&
                  (isFeatureEnabled(FLAGS.TMS) ||
                    isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)) &&
                  !checkIsOrderCompleted && (
                    <Button
                      variant="tertiary"
                      label="Create Shipment"
                      size="large"
                      className={'p-2 h-8 rounded border-solid'}
                      overrideSize={true}
                      labelClass="font-medium text-xs "
                      onClick={(e) => {
                        e.stopPropagation();
                        if (
                          isFeatureEnabled(FLAGS.TMS) ||
                          isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
                        ) {
                          setShowCreateShipmentTMS(true);
                        } else {
                          setShipmentPopup(true);
                        }
                        setSelectedOrder(order);
                      }}
                    />
                  )
                : !isFeatureEnabled(FLAGS.TMS) &&
                  !isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) &&
                  (activeShipments.length == 1 &&
                  order.status !== 'PUTAWAY_IN_PROGRESS' ? (
                    order.moveType !== 'TRANSFER' ? (
                      !activeShipments[0].dockId &&
                      isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) ? (
                        <Button
                          label={
                            'Assign Dock'
                            // order.moveType == 'OUTBOUND'
                            //   ? 'Assign For Loading'
                            //   : 'Assign for Un-Loading'
                          }
                          className={'p-2 h-8 rounded border-solid'}
                          overrideSize={true}
                          labelClass="font-medium text-xs "
                          onClick={() => setExpanded(true)}
                        />
                      ) : order.moveType == 'INWARD' ? (
                        isFeatureEnabled(FLAGS.GRN_CREATION) && (
                          <Button
                            label="Receive Inventory"
                            className={'p-2 h-8 rounded border-solid'}
                            overrideSize={true}
                            labelClass="font-medium text-xs "
                            onClick={() => setExpanded(true)}
                          />
                        )
                      ) : (
                        order.status !== 'PICKING_IN_PROGRESS' &&
                        isFeatureEnabled(FLAGS.GDN_CREATION) && (
                          <Button
                            label="Dispatch Inventory"
                            className={'p-2 h-8 rounded border-solid'}
                            overrideSize={true}
                            labelClass="font-medium text-xs "
                            onClick={() => setExpanded(true)}
                          />
                        )
                      )
                    ) : !activeShipments[0].dockId &&
                      isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) ? (
                      <Button
                        label={'Assign Dock'}
                        // label="Assign For Loading"
                        className={'p-2 h-8 rounded border-solid'}
                        overrideSize={true}
                        labelClass="font-medium text-xs "
                        onClick={() => setExpanded(true)}
                      />
                    ) : ((activeShipments[0].dockId &&
                        !activeShipments[0].dropOffDockId) ||
                        !isFeatureEnabled(
                          FLAGS.DOCK_CREATION_AND_ASSIGNMENT
                        )) &&
                      activeShipments[0].subStatus?.key !== 'LOAD_DISPATCHED' &&
                      order.status !== 'PICKING_IN_PROGRESS' ? (
                      isFeatureEnabled(FLAGS.GDN_CREATION) && (
                        <Button
                          label="Dispatch Inventory"
                          className={'p-2 h-8 rounded border-solid'}
                          overrideSize={true}
                          labelClass="font-medium text-xs "
                          onClick={() => setExpanded(true)}
                        />
                      )
                    ) : activeShipments[0].dockId &&
                      isFeatureEnabled(FLAGS.DOCK_CREATION_AND_ASSIGNMENT) &&
                      activeShipments[0].subStatus?.key == 'LOAD_DISPATCHED' &&
                      !activeShipments[0].dropOffDock ? (
                      <Button
                        label={'Assign Dock'}
                        // label="Assign For Un-Loading"
                        className={'p-2 h-8 rounded border-solid'}
                        overrideSize={true}
                        labelClass="font-medium text-xs "
                        onClick={() => setExpanded(true)}
                      />
                    ) : (!isFeatureEnabled(
                        FLAGS.DOCK_CREATION_AND_ASSIGNMENT
                      ) ||
                        activeShipments[0].dropOffDockId) &&
                      isFeatureEnabled(FLAGS.GRN_CREATION) ? (
                      <Button
                        label="Receive Inventory"
                        className={'p-2 h-8 rounded border-solid'}
                        overrideSize={true}
                        labelClass="font-medium text-xs "
                        onClick={() => setExpanded(true)}
                      />
                    ) : null
                  ) : null)}
            </div>
            {order.status === 'DRAFT' || (
              <RemoveRedEyeIcon
                className={'h-[20px] w-[20px]'}
                onClick={() => {
                  navigate(`/routing/view-order/${order.customId}`);
                }}
              />
            )}
            {(isFeatureEnabled(FLAGS.TMS) ||
            isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
              ? true
              : order.moveType !== 'LOGISTIC') &&
              order.Shipments?.length > 0 &&
              (expanded ? (
                <ExpandLessIcon
                  className={'h-[20px] w-[20px]'}
                  onClick={() => setExpanded(false)}
                />
              ) : (
                <ExpandMoreIcon
                  className={'h-[20px] w-[20px]'}
                  onClick={() => setExpanded(true)}
                />
              ))}
            {(order.status == 'PENDING' ||
              order.status == 'DRAFT' ||
              order.status == 'PARTIALLY_COMPLETE' ||
              order.status == 'PUTAWAY_IN_PROGRESS' ||
              order.status == 'IN_PROGRESS') &&
              orderOptions.length > 0 && (
                <div onClick={(e) => e.stopPropagation()}>
                  <OptionsMenu
                    options={orderOptions}
                    closeCallback={() => setOptionsClick(false)}
                    enterCallBack={() => setOptionsClick(true)}
                  />
                </div>
              )}
          </div>
        </TableCell>
      </TableRow>
      {expanded &&
        order.Shipments?.length > 0 &&
        (isFeatureEnabled(FLAGS.TMS) ||
        isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) ? (
          order.moveType == 'OUTBOUND' ? (
            <OutboundTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          ) : order.moveType == 'INWARD' ? (
            <InboundTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          ) : order.moveType == 'TRANSFER' ? (
            <TransferTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          ) : (
            <LogisticTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          )
        ) : (
          <ExpandedRow
            expanded={expanded}
            order={order}
            anchorEl={shipmentAnchorEl}
            setAnchorEl={setShipmentAnchorEl}
            setActiveShipmentOrder={setActiveShipmentOrder}
            setActiveShipment={setActiveShipment}
            setCancelPopUp={setCancelPopUp}
            setAssignVehiclePopup={setAssignVehiclePopup}
            refetch={refetch}
            setActiveShipmentPopup={setActiveShipmentPopup}
            setView={setView}
            shipmentIdFilter={shipmentIdFilter}
            shipments={order.Shipments}
          />
        ))}
    </>
  );
};

export const DraftOrderRow = ({
  order,
  deleteOrder,
  orderIdFilter,
  companyNameFilter,
  extraColumns
}) => {
  const navigate = useNavigate();
  const { settings, organization } = useContext(SharedContext);

  const totalQty = order.totalOriginalOrderedQuantity;

  const totalCost = order.OrderProductGroups?.reduce(
    (acc, curr) =>
      acc +
      (curr.originalOrderedQuantity || 0) *
        (curr.sellingPrice || curr.costPrice || 0),
    0
  );

  return (
    <>
      <TableRow className="cursor-pointer group">
        <CommonCells
          order={order}
          orderIdFilter={orderIdFilter}
          companyNameFilter={companyNameFilter}
          settings={settings}
          extraColumns={extraColumns}
          draft={true}
        />
        {(extraColumns.indexOf('Order Value & Items') !== -1 ||
          extraColumns.indexOf('Order Items') !== -1) && (
          <TableCell
            style={{
              verticalAlign: 'top'
            }}
          >
            <p className="text-xs">
              <span>{decimalDisplay(totalQty)}</span>
              <span className="text-[10px] ml-1">items</span>
            </p>
            {organization?.type == ORGANIZATION_TYPES.MANUFACTURER && (
              <p className="text-xs mt-2">
                <span className="text-[10px] mr-1">
                  {getCurrencySymbol(organization?.defaultCurrency)}
                </span>
                <span>{decimalDisplay(totalCost)}</span>
              </p>
            )}
          </TableCell>
        )}
        <TableCell component="th" scope="row">
          <LinearProgress
            variant="determinate"
            value={calculateProgress(order)}
            sx={{
              backgroundColor: 'white',
              '& .MuiLinearProgress-bar': {
                backgroundColor: BLACK
              },
              border: `1px ${BLACK} solid`,
              borderRadius: '1000px',
              height: '5px'
            }}
          />
        </TableCell>

        <TableCell></TableCell>
        <TableCell>
          <div className="flex gap-3 mr-4">
            <Button
              label="Edit"
              size="medium"
              className={'p-2 h-8 rounded border-solid'}
              overrideSize={true}
              labelClass="font-medium text-xs "
              onClick={() => navigate(`/routing/edit-order/${order.customId}`)}
            />
            <img
              src={DeleteIcon}
              onClick={(e) => {
                e.stopPropagation();
                return DialogueConfirmationOpen(
                  order,
                  false,
                  deleteOrder,
                  '',
                  'Are you sure you want to delete this Order'
                );
              }}
            />
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

export const CompleteOrderRow = ({
  order,
  refetch,
  setView,
  setActiveShipment,
  setActiveShipmentOrder,
  setActiveShipmentPopup,
  setAssignVehiclePopup,
  setCancelPopUp,
  orderIdFilter,
  companyNameFilter,
  shipmentIdFilter,
  activeShipment,
  extraColumns
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl] = React.useState(null);
  const [shipmentAnchorEl, setShipmentAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const { settings, organization } = useContext(SharedContext);

  const getShipmentProgress = (shipments) => {
    const activeShipment = shipments?.filter(
      (s) => s.status.key !== 'CANCELLED' && s.status.key !== 'COMPLETED'
    );
    if (activeShipment?.length == 0) {
      let lastCompletedShipment = order?.Shipments?.filter(
        (s) => s.status.key == 'COMPLETED'
      );
      lastCompletedShipment.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      let length = lastCompletedShipment.length;
      let suffix =
        length == 1 ? '' : length == 2 ? 'nd' : length == 3 ? 'rd' : 'th';
      return (
        <div className="flex justify-center flex-col pt-[26px]">
          <img src={putwayProgress} />
          <Paragraph variant={'sm'} className="mt-2">
            {length > 1 && length + suffix} Shipment Completed, Put Away Pending
          </Paragraph>
        </div>
      );
    }
  };

  const totalQty = order.totalOriginalOrderedQuantity;

  const totalCost = order.OrderProductGroups?.reduce(
    (acc, curr) =>
      acc +
      (curr.originalOrderedQuantity || 0) *
        (curr.sellingPrice || curr.costPrice || 0),
    0
  );

  useEffect(() => {
    if (companyNameFilter || orderIdFilter || shipmentIdFilter) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [companyNameFilter, orderIdFilter, shipmentIdFilter]);

  return (
    <>
      <TableRow
        sx={
          expanded &&
          order.Shipments?.length > 0 && { '& > *': { borderBottom: 'unset' } }
        }
        className="cursor-pointer group"
        onClick={(e) => {
          e.stopPropagation();
          if (order?.Shipments?.length > 0)
            expanded ? setExpanded(false) : setExpanded(true);
        }}
      >
        <CommonCells
          order={order}
          orderIdFilter={orderIdFilter}
          companyNameFilter={companyNameFilter}
          settings={settings}
          extraColumns={extraColumns}
        />
        <TableCell
          component="th"
          scope="row"
          style={{ verticalAlign: 'top', paddingLeft: '4px' }}
        >
          <div className="flex gap-2 items-center">
            <div className={icons[order.status]} />
            <p className="text-xs">{constants.ORDER_STATUS[order.status]}</p>
          </div>
          <div className="flex gap-2 mt-2">
            <div className="w-2.5 h-2.5" />
            <Label variant={'xs'} className="flex gap-1">
              <span>{moment(order.updatedAt).format('DD/MM/YYYY')}</span>
              <span>{moment(order.updatedAt).format('hh:mm A')}</span>
            </Label>
          </div>
        </TableCell>
        {(extraColumns.indexOf('Order Value & Items') !== -1 ||
          extraColumns.indexOf('Order Items') !== -1) && (
          <TableCell
            style={{
              verticalAlign: 'top'
            }}
          >
            <p className="text-xs">
              <span>{decimalDisplay(totalQty)}</span>
              <span className="text-[10px] ml-1">items</span>
            </p>
            {organization?.type == ORGANIZATION_TYPES.MANUFACTURER && (
              <p className="text-xs mt-2">
                <span className="text-[10px] mr-1">
                  {getCurrencySymbol(organization?.defaultCurrency)}
                </span>
                <span>{decimalDisplay(totalCost)}</span>
              </p>
            )}
          </TableCell>
        )}
        <TableCell
          component="th"
          scope="row"
          sx={{ verticalAlign: 'top', paddingLeft: '4px', paddingTop: 0 }}
        >
          {order.moveType == 'LOGISTIC' ? (
            <span>Order completed</span>
          ) : order.moveType == 'INWARD' &&
            (!order.configurations || order.configurations?.length == 0) ? (
            getShipmentProgress(order.Shipments)
          ) : (
            <Paragraph variant={'sm'} className="pt-4 text-sm">
              Order Completed in&nbsp;
              {order.Shipments.filter((s) => s.status.key == 'COMPLETED')
                ?.length || 0}{' '}
              Shipments
            </Paragraph>
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          <div
            className={`flex gap-4 items-center justify-end ${
              !expanded && !anchorEl ? 'invisible' : 'visible'
            } group-hover:visible`}
          >
            <Button
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/routing/view-order/${order.customId}`);
              }}
              className={'p-2 h-8 rounded border-solid'}
              overrideSize={true}
              labelClass="font-medium text-xs "
              label="View Order"
              variant="tertiary"
              size="large"
            />
            {order.moveType !== 'LOGISTIC' &&
              order.Shipments?.length > 0 &&
              (expanded ? (
                <ExpandLessIcon
                  className={'h-[20px] w-[20px]'}
                  onClick={() => setExpanded(false)}
                />
              ) : (
                <ExpandMoreIcon
                  className={'h-[20px] w-[20px]'}
                  onClick={() => setExpanded(true)}
                />
              ))}
          </div>
        </TableCell>
      </TableRow>
      {expanded &&
        order.Shipments?.length > 0 &&
        (isFeatureEnabled(FLAGS.TMS) ||
        isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) ? (
          order.moveType == 'OUTBOUND' ? (
            <OutboundTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          ) : order.moveType == 'INWARD' ? (
            <InboundTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          ) : order.moveType == 'TRANSFER' ? (
            <TransferTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          ) : (
            <LogisticTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          )
        ) : (
          <ExpandedRow
            expanded={expanded}
            order={order}
            anchorEl={shipmentAnchorEl}
            setAnchorEl={setShipmentAnchorEl}
            setActiveShipmentOrder={setActiveShipmentOrder}
            setActiveShipment={setActiveShipment}
            setCancelPopUp={setCancelPopUp}
            setAssignVehiclePopup={setAssignVehiclePopup}
            refetch={refetch}
            setActiveShipmentPopup={setActiveShipmentPopup}
            setView={setView}
            shipmentIdFilter={shipmentIdFilter}
            shipments={order.Shipments}
          />
        ))}
    </>
  );
};

export const ClosedOrderRow = ({
  order,
  setView,
  refetch,
  setActiveShipment,
  setActiveShipmentOrder,
  setActiveShipmentPopup,
  setAssignVehiclePopup,
  setCancelPopUp,
  orderIdFilter,
  companyNameFilter,
  shipmentIdFilter,
  activeShipment,
  extraColumns
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl] = React.useState(null);
  const [shipmentAnchorEl, setShipmentAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const { settings, organization } = useContext(SharedContext);

  const totalQty = order.totalOriginalOrderedQuantity;

  const totalCost = order.OrderProductGroups?.reduce(
    (acc, curr) =>
      acc +
      (curr.originalOrderedQuantity || 0) *
        (curr.sellingPrice || curr.costPrice || 0),
    0
  );

  useEffect(() => {
    if (companyNameFilter || orderIdFilter || shipmentIdFilter) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [companyNameFilter, orderIdFilter, shipmentIdFilter]);

  return (
    <>
      <TableRow
        sx={
          expanded &&
          order.Shipments?.length > 0 && { '& > *': { borderBottom: 'unset' } }
        }
        className="cursor-pointer group"
        onClick={() => {
          if (order?.Shipments?.length > 0)
            expanded ? setExpanded(false) : setExpanded(true);
        }}
      >
        <CommonCells
          order={order}
          orderIdFilter={orderIdFilter}
          companyNameFilter={companyNameFilter}
          settings={settings}
          extraColumns={extraColumns}
        />
        <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
          <p className="text-xs">
            {moment(order.updatedAt).format('DD/MM/YYYY')}
          </p>
        </TableCell>
        {(extraColumns.indexOf('Order Value & Items') !== -1 ||
          extraColumns.indexOf('Order Items') !== -1) && (
          <TableCell
            style={{
              verticalAlign: 'top'
            }}
          >
            <p className="text-xs">
              <span>{decimalDisplay(totalQty)}</span>
              <span className="text-[10px] ml-1">items</span>
            </p>
            {organization?.type == ORGANIZATION_TYPES.MANUFACTURER && (
              <p className="text-xs mt-2">
                <span className="text-[10px] mr-1">
                  {getCurrencySymbol(organization?.defaultCurrency)}
                </span>
                <span>{decimalDisplay(totalCost)}</span>
              </p>
            )}
          </TableCell>
        )}
        <TableCell component="th" scope="row" style={{ paddingTop: '16px' }}>
          <div
            className={`flex gap-4 items-center justify-end ${
              !expanded && !anchorEl ? 'invisible' : 'visible'
            } group-hover:visible`}
          >
            <Button
              label="View Order"
              size="large"
              className={'p-2 h-8 rounded border-solid'}
              overrideSize={true}
              labelClass="font-medium text-xs "
              onClick={() => {
                navigate(`/routing/view-order/${order.customId}`);
              }}
            />
            {(isFeatureEnabled(FLAGS.TMS) ||
            isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
              ? true
              : order.moveType !== 'LOGISTIC') &&
              order.Shipments?.length > 0 &&
              (expanded ? (
                <ExpandLessIcon
                  className={'h-[20px] w-[20px]'}
                  onClick={() => setExpanded(false)}
                />
              ) : (
                <ExpandMoreIcon
                  className={'h-[20px] w-[20px]'}
                  onClick={() => setExpanded(true)}
                />
              ))}
          </div>
        </TableCell>
      </TableRow>
      {expanded &&
        order.Shipments?.length > 0 &&
        (isFeatureEnabled(FLAGS.TMS) ||
        isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) ? (
          order.moveType == 'OUTBOUND' ? (
            <OutboundTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          ) : order.moveType == 'INWARD' ? (
            <InboundTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          ) : order.moveType == 'TRANSFER' ? (
            <TransferTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          ) : (
            <LogisticTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          )
        ) : (
          <ExpandedRow
            expanded={expanded}
            order={order}
            anchorEl={shipmentAnchorEl}
            setAnchorEl={setShipmentAnchorEl}
            setActiveShipmentOrder={setActiveShipmentOrder}
            setActiveShipment={setActiveShipment}
            setCancelPopUp={setCancelPopUp}
            setAssignVehiclePopup={setAssignVehiclePopup}
            refetch={refetch}
            setActiveShipmentPopup={setActiveShipmentPopup}
            setView={setView}
            shipmentIdFilter={shipmentIdFilter}
            shipments={order.Shipments}
          />
        ))}
    </>
  );
};

export const CancelledOrderRow = ({
  order,
  setView,
  refetch,
  setActiveShipment,
  setActiveShipmentOrder,
  setActiveShipmentPopup,
  setAssignVehiclePopup,
  setCancelPopUp,
  orderIdFilter,
  companyNameFilter,
  shipmentIdFilter,
  activeShipment,
  extraColumns
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl] = React.useState(null);
  const [shipmentAnchorEl, setShipmentAnchorEl] = React.useState(null);

  const navigate = useNavigate();

  const { settings } = useContext(SharedContext);

  useEffect(() => {
    if (companyNameFilter || orderIdFilter || shipmentIdFilter) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [companyNameFilter, orderIdFilter, shipmentIdFilter]);

  return (
    <>
      <TableRow
        sx={
          expanded &&
          order.Shipments?.length > 0 && { '& > *': { borderBottom: 'unset' } }
        }
        className="cursor-pointer group"
        onClick={(e) => {
          e.stopPropagation();
          if (order?.Shipments?.length > 0)
            expanded ? setExpanded(false) : setExpanded(true);
        }}
      >
        <CommonCells
          order={order}
          orderIdFilter={orderIdFilter}
          companyNameFilter={companyNameFilter}
          settings={settings}
          extraColumns={extraColumns}
        />

        <TableCell
          component="th"
          scope="row"
          style={{
            verticalAlign: 'top'
          }}
        >
          <div className="flex gap-2 items-center">
            {order.status == 'PARTIALLY_COMPLETE' ? (
              <img src={PartiallyCompleteIcon} className="w-3 h-3" />
            ) : (
              <div className={clsx(icons[order.status], 'w-1.5 h-1.5')} />
            )}
            <p className="text-xs">{constants.ORDER_STATUS[order.status]}</p>
          </div>
        </TableCell>

        {extraColumns?.indexOf('Reason Of Cancelling') !== -1 && (
          <TableCell
            component="th"
            scope="row"
            style={{ verticalAlign: 'top' }}
          >
            <div className="flex flex-col gap-2">
              <p className="text-xs">{order?.cancelReason || 'N/A'}</p>
              <p className="text-xs">
                <span className="semibold">Date : </span>
                {moment(order?.updatedAt).format('DD/MM/YYYY')}
              </p>
            </div>
          </TableCell>
        )}
        <TableCell component="th" scope="row">
          <div
            className={`flex gap-4 items-center justify-end ${
              !expanded && !anchorEl ? 'invisible' : 'visible'
            } group-hover:visible`}
          >
            <Button
              label="View Order"
              size="large"
              className={'p-2 h-8 rounded border-solid'}
              overrideSize={true}
              labelClass="font-medium text-xs "
              onClick={() => {
                navigate(`/routing/view-order/${order.customId}`);
              }}
            />
            {(isFeatureEnabled(FLAGS.TMS) ||
            isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION)
              ? true
              : order.moveType !== 'LOGISTIC') &&
              order.Shipments?.length > 0 &&
              (expanded ? (
                <ExpandLessIcon
                  className={'h-[20px] w-[20px]'}
                  onClick={() => setExpanded(false)}
                />
              ) : (
                <ExpandMoreIcon
                  className={'h-[20px] w-[20px]'}
                  onClick={() => setExpanded(true)}
                />
              ))}
          </div>
        </TableCell>
      </TableRow>
      {expanded &&
        order.Shipments?.length > 0 &&
        (isFeatureEnabled(FLAGS.TMS) ||
        isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) ? (
          order.moveType == 'OUTBOUND' ? (
            <OutboundTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          ) : order.moveType == 'INWARD' ? (
            <InboundTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          ) : order.moveType == 'TRANSFER' ? (
            <TransferTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          ) : (
            <LogisticTMSShipmentTracking
              expanded={expanded}
              order={order}
              anchorEl={shipmentAnchorEl}
              setAnchorEl={setShipmentAnchorEl}
              setActiveShipmentOrder={setActiveShipmentOrder}
              setActiveShipment={setActiveShipment}
              setCancelPopUp={setCancelPopUp}
              setAssignVehiclePopup={setAssignVehiclePopup}
              refetch={refetch}
              setActiveShipmentPopup={setActiveShipmentPopup}
              setView={setView}
              shipmentIdFilter={shipmentIdFilter}
              activeShipment={activeShipment}
            />
          )
        ) : (
          <ExpandedRow
            expanded={expanded}
            order={order}
            anchorEl={shipmentAnchorEl}
            setAnchorEl={setShipmentAnchorEl}
            setActiveShipmentOrder={setActiveShipmentOrder}
            setActiveShipment={setActiveShipment}
            setCancelPopUp={setCancelPopUp}
            setAssignVehiclePopup={setAssignVehiclePopup}
            refetch={refetch}
            setActiveShipmentPopup={setActiveShipmentPopup}
            setView={setView}
            shipmentIdFilter={shipmentIdFilter}
            shipments={order.Shipments}
          />
        ))}
    </>
  );
};

const ShipmentProgress = ({ order }) => {
  if (order.moveType == 'LOGISTIC') return <p className="pt-[26px]">-</p>;
  else if (order.moveType != 'TRANSFER') {
    if (order.status == 'PENDING') {
      return (
        <div className="flex flex-col pt-[26px]">
          <p className="text-sm">
            <LinearProgressBar value={0} />
          </p>
          <Paragraph variant={'sm'} className="mt-2">
            Create a shipment for this order.
          </Paragraph>
        </div>
      );
    } else if (
      order.status == 'IN_PROGRESS' ||
      order.status == 'PICKING_IN_PROGRESS'
    ) {
      const activeShipment = order?.Shipments?.filter(
        (s) => s.status.key !== 'CANCELLED'
      );
      if (activeShipment?.length > 1) {
        return (
          <Paragraph variant={'sm'} className="pt-4">
            {activeShipment?.length} Active shipments. Expand to see details
          </Paragraph>
        );
      } else {
        const shipment = activeShipment[0];
        if (shipment) {
          if (
            shipment.status.key == 'CREATED' ||
            shipment.subStatus?.key == 'VEHICLE_ASSIGNED'
          ) {
            return (
              <div className="flex justify-center flex-col pt-[26px]">
                <p className="text-sm">
                  {order.status == 'PICKING_IN_PROGRESS' ? (
                    <img src={putwayProgress} />
                  ) : (
                    <LinearProgressBar value={10} />
                  )}
                </p>
                <Paragraph variant={'sm'} className="mt-2">
                  {order.status == 'PICKING_IN_PROGRESS'
                    ? 'Shipment Created. Picking in progress'
                    : 'Shipment Created.'}{' '}
                </Paragraph>
              </div>
            );
          } else if (
            shipment.status.key == 'IN_TRANSIT' &&
            shipment.subStatus?.key == 'DOCK_ASSIGNED'
          ) {
            return (
              <div className="flex justify-center flex-col pt-[26px]">
                <p className="text-sm">
                  {order.status == 'PICKING_IN_PROGRESS' ? (
                    <img src={putwayProgress} />
                  ) : (
                    <LinearProgressBar value={60} />
                  )}
                </p>
                <Paragraph variant={'sm'} className="mt-2">
                  Shipment is at {shipment?.WarehouseDock?.name}
                </Paragraph>
              </div>
            );
          }
        }
      }
    } else if (order.status == 'PARTIALLY_COMPLETE') {
      const activeShipment = order?.Shipments?.filter(
        (s) => s.status.key !== 'CANCELLED' && s.status.key !== 'COMPLETED'
      );
      if (activeShipment.length == 0) {
        return (
          <Paragraph variant={'sm'} className="pt-4">
            Requires new shipment
          </Paragraph>
        );
      } else if (activeShipment.length > 1) {
        return (
          <Paragraph variant={'sm'} className="pt-4">
            {activeShipment.length} Active shipments. Expand to see details
          </Paragraph>
        );
      } else {
        const shipment = activeShipment[0];
        let length = order?.Shipments.map((s) => s.id).indexOf(shipment.id) + 1;
        let suffix =
          length == 1 ? '' : length == 2 ? 'nd' : length == 3 ? 'rd' : 'th';
        if (shipment.status.key == 'CREATED') {
          return (
            <div className="flex justify-center gap-2 flex-col pt-[26px]">
              <p className="text-sm">
                <LinearProgressBar value={10} />
              </p>
              <Paragraph variant={'sm'} className="mt-2">
                {length > 1 && length + suffix} Shipment Created.
              </Paragraph>
            </div>
          );
        } else if (
          shipment.status.key == 'IN_TRANSIT' &&
          shipment.subStatus?.key == 'DOCK_ASSIGNED'
        ) {
          return (
            <div className="flex justify-center flex-col pt-[26px]">
              <LinearProgressBar value={60} />
              <Paragraph variant={'sm'} className="mt-2">
                {length > 1 && length + suffix} Shipment is at{' '}
                {shipment?.WarehouseDock?.name}
              </Paragraph>
            </div>
          );
        }
      }
    } else if (order.status == 'PUTAWAY_IN_PROGRESS') {
      const activeShipment = order?.Shipments?.filter(
        (s) => s.status.key !== 'CANCELLED' && s.status.key !== 'COMPLETED'
      );
      if (activeShipment.length == 0) {
        let lastCompletedShipment = order?.Shipments?.filter(
          (s) => s.status.key == 'COMPLETED'
        );
        lastCompletedShipment.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        let length =
          lastCompletedShipment.length > 0
            ? order?.Shipments.map((s) => s.id).indexOf(
                lastCompletedShipment[0]?.id
              ) + 1
            : 0;
        let suffix =
          length == 1 ? '' : length == 2 ? 'nd' : length == 3 ? 'rd' : 'th';
        return (
          <div className="flex justify-center flex-col pt-[26px]">
            <img src={putwayProgress} />
            <Paragraph variant={'sm'} className="mt-2">
              {length > 1 && length + suffix} Shipment Completed, Put Away in
              Progress
            </Paragraph>
          </div>
        );
      } else if (activeShipment.length > 1) {
        return (
          <Paragraph variant={'sm'} className="pt-4">
            {activeShipment.length} Active shipments. Expand to see details
          </Paragraph>
        );
      } else {
        const shipment = activeShipment[0];
        let length = order?.Shipments.map((s) => s.id).indexOf(shipment.id) + 1;
        let suffix =
          length == 1 ? '' : length == 2 ? 'nd' : length == 3 ? 'rd' : 'th';
        if (shipment.status.key == 'CREATED') {
          return (
            <div className="flex justify-center flex-col pt-[26px]">
              <LinearProgressBar value={10} />
              <Paragraph variant={'sm'} className="mt-2">
                {length > 1 && length + suffix} Shipment Created.
              </Paragraph>
            </div>
          );
        } else if (
          shipment.status.key == 'IN_TRANSIT' &&
          shipment.subStatus?.key == 'DOCK_ASSIGNED'
        ) {
          return (
            <div className="flex justify-center flex-col pt-[26px]">
              <LinearProgressBar value={60} />
              <Paragraph variant={'sm'} className="mt-2">
                {length > 1 && length + suffix} Shipment is at{' '}
                {shipment?.WarehouseDock?.name}
              </Paragraph>
            </div>
          );
        }
      }
    }
  } else {
    if (order.status == 'PENDING') {
      return (
        <div className="flex justify-center flex-col pt-[26px]">
          <LinearProgressBar value={0} />
          <Paragraph variant={'sm'} className="mt-1">
            Create a Shipment for this order.
          </Paragraph>
        </div>
      );
    } else if (
      order.status === 'IN_PROGRESS' ||
      order.status === 'PICKING_IN_PROGRESS'
    ) {
      const activeShipment = order?.Shipments?.filter(
        (s) => s.status.key !== 'CANCELLED'
      );

      if (activeShipment.length > 1) {
        return (
          <Paragraph variant={'sm'} className="pt-4">
            {activeShipment.length} Active shipments. Expand to see details
          </Paragraph>
        );
      } else {
        const shipment = activeShipment[0];
        if (shipment) {
          if (shipment.status.key == 'CREATED') {
            return (
              <div className="flex justify-center flex-col pt-[26px]">
                <p className="text-sm">
                  {order.status == 'PICKING_IN_PROGRESS' ? (
                    <img src={putwayProgress} />
                  ) : (
                    <LinearProgressBar value={10} />
                  )}
                </p>
                <Paragraph variant={'sm'} className="mt-2">
                  {order.status == 'PICKING_IN_PROGRESS'
                    ? 'Shipment Created. Picking in progress'
                    : 'Shipment Created.'}{' '}
                </Paragraph>
              </div>
            );
          } else if (
            shipment.subStatus?.key == 'DOCK_ASSIGNED' &&
            !shipment.dropOffDockId
          ) {
            return (
              <div className="flex justify-center flex-col pt-[26px]">
                <p className="text-sm">
                  {order.status == 'PICKING_IN_PROGRESS' ? (
                    <img src={putwayProgress} />
                  ) : (
                    <LinearProgressBar value={40} />
                  )}
                </p>
                <Paragraph variant={'sm'} className="mt-2">
                  Shipment is at {shipment?.WarehouseDock?.name}
                </Paragraph>
              </div>
            );
          } else if (
            shipment.status.key == 'IN_TRANSIT' &&
            shipment.subStatus?.key == 'LOAD_DISPATCHED' &&
            !shipment.dropOffDockId
          ) {
            return (
              <div className="flex justify-center flex-col pt-4">
                <LinearProgressBar value={60} />
                <Paragraph variant={'sm'} className="mt-2">
                  Shipment Loading Complete
                </Paragraph>
              </div>
            );
          } else if (
            shipment.status.key == 'IN_TRANSIT' &&
            shipment.subStatus?.key == 'DOCK_ASSIGNED' &&
            shipment.dropOffDockId
          ) {
            return (
              <div className="flex justify-center flex-col pt-[26px]">
                <p className="text-sm">
                  <LinearProgressBar value={80} />
                </p>
                <Paragraph variant={'sm'} className="mt-2">
                  Shipment is at {shipment?.dropOffDock?.name}
                </Paragraph>
              </div>
            );
          }
        }
      }
    } else if (order.status == 'PUTAWAY_IN_PROGRESS') {
      const activeShipment = order?.Shipments?.filter(
        (s) => s.status.key !== 'CANCELLED' && s.status.key !== 'COMPLETED'
      );
      if (activeShipment.length == 0) {
        let lastCompletedShipment = order?.Shipments?.filter(
          (s) => s.status.key == 'COMPLETED'
        );
        lastCompletedShipment.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        let length =
          lastCompletedShipment.length > 0
            ? order?.Shipments.map((s) => s.id).indexOf(
                lastCompletedShipment[0]?.id
              ) + 1
            : 0;
        let suffix =
          length == 1 ? '' : length == 2 ? 'nd' : length == 3 ? 'rd' : 'th';
        return (
          <div className="flex justify-center flex-col pt-[26px]">
            <img src={putwayProgress} />
            <Paragraph variant={'sm'} className="mt-2">
              {length > 1 && length + suffix} Shipment Completed, Put Away in
              Progress
            </Paragraph>
          </div>
        );
      } else if (activeShipment.length > 1) {
        return (
          <Paragraph variant={'sm'} className="pt-4">
            {activeShipment.length} Active shipments. Expand to see details
          </Paragraph>
        );
      } else {
        const shipment = activeShipment[0];
        let length = order?.Shipments.map((s) => s.id).indexOf(shipment.id) + 1;
        let suffix =
          length == 1 ? '' : length == 2 ? 'nd' : length == 3 ? 'rd' : 'th';
        if (shipment.status.key == 'CREATED') {
          return (
            <div className="flex justify-center flex-col pt-[26px]">
              <LinearProgressBar value={10} />
              <p className="text-xs mt-4">
                {length > 1
                  ? `${length + suffix} Shipment Created.`
                  : 'Shipment Created'}
              </p>
            </div>
          );
        } else if (
          shipment.subStatus?.key == 'DOCK_ASSIGNED' &&
          !shipment.dropOffDockId
        ) {
          return (
            <div className="flex justify-center flex-col pt-[26px]">
              <p className="text-sm">
                {order.status == 'PICKING_IN_PROGRESS' ? (
                  <img src={putwayProgress} />
                ) : (
                  <LinearProgressBar value={40} />
                )}
              </p>
              <Paragraph variant={'sm'} className="mt-2">
                Shipment is at {shipment?.WarehouseDock?.name}
              </Paragraph>
            </div>
          );
        } else if (
          shipment.status.key == 'IN_TRANSIT' &&
          shipment.subStatus?.key == 'LOAD_DISPATCHED' &&
          !shipment.dropOffDockId
        ) {
          return (
            <div className="flex justify-center flex-col pt-4">
              <LinearProgressBar value={60} />
              <Paragraph variant={'sm'} className="mt-2">
                Shipment Loading Complete
              </Paragraph>
            </div>
          );
        } else if (
          shipment.status.key == 'IN_TRANSIT' &&
          shipment.subStatus?.key == 'DOCK_ASSIGNED' &&
          shipment.dropOffDockId
        ) {
          return (
            <div className="flex justify-center flex-col pt-[26px]">
              <p className="text-sm">
                <LinearProgressBar value={80} />
              </p>
              <Paragraph variant={'sm'} className="mt-2">
                Shipment is at {shipment?.dropOffDock?.name}
              </Paragraph>
            </div>
          );
        }
      }
    } else if (order.status == 'PARTIALLY_COMPLETE') {
      const activeShipment = order?.Shipments?.filter(
        (s) => s.status.key !== 'CANCELLED' && s.status.key !== 'COMPLETED'
      );
      if (activeShipment.length == 0) {
        return (
          <Paragraph variant={'sm'} className="pt-4">
            Requires new shipment
          </Paragraph>
        );
      } else if (activeShipment.length > 1) {
        return (
          <Paragraph variant={'sm'} className="pt-4">
            {activeShipment.length} Active shipments. Expand to see details
          </Paragraph>
        );
      } else {
        const shipment = activeShipment[0];
        let length = order?.Shipments.map((s) => s.id).indexOf(shipment.id) + 1;
        let suffix =
          length == 1 ? '' : length == 2 ? 'nd' : length == 3 ? 'rd' : 'th';
        if (shipment.status.key == 'CREATED') {
          return (
            <div className="flex justify-center flex-col pt-[26px]">
              <p className="text-sm mt-3.5">
                <LinearProgressBar value={10} />
              </p>
              <Paragraph variant={'sm'} className="mt-2">
                {length > 1 && length + suffix} Shipment Created.
              </Paragraph>
            </div>
          );
        } else if (
          shipment.status.key == 'IN_TRANSIT' &&
          shipment.subStatus?.key == 'DOCK_ASSIGNED'
        ) {
          return (
            <div className="flex justify-center gap-2 flex-col pt-[26px]">
              <p className="text-sm">
                <LinearProgressBar value={60} />
              </p>
              <Paragraph variant={'sm'} className="mt-2">
                {length > 1 && length + suffix} Shipment is at{' '}
                {shipment?.WarehouseDock?.name}
              </Paragraph>
            </div>
          );
        }
      }
    }
  }

  return <p className="pt-[26px]">-</p>;
};
