import {
  decimalDisplay,
  formatNumberToKAndM,
  getCurrencySymbol,
  SharedContext
} from '../../../utils/common';
import StatCard from '../../revampedOrder/StatCard';
import { useContext } from 'react';

export const PayableStats = ({ stats }) => {
  const { organization } = useContext(SharedContext);

  return (
    <div className="w-full h-[90px] bg-dark200 pl-6 pr-2 py-2 flex shadow-sm items-center">
      <StatCard>
        <div className="mr-6">
          <p className="text-xs text-dark600">
            TOTAL BILLS ({stats?.totalBillablesCount})
          </p>
          <div className={'flex items-end'}>
            <p
              className={'text-xs text-primaryBlue font-semibold leading-loose'}
            >
              {getCurrencySymbol(organization?.defaultCurrency)}
            </p>{' '}
            <p className="text-lg text-primaryBlue font-semibold leading-normal">
              {stats?.totalBillablesAmount < 1000
                ? decimalDisplay(stats?.totalBillablesAmount || 0)
                : formatNumberToKAndM(stats?.totalBillablesAmount || 0)}
            </p>
          </div>
        </div>
      </StatCard>
      <StatCard>
        <div className="mr-6">
          <p className="text-xs text-dark600">
            OPEN ({stats?.pendingBillablesCount})
          </p>
          <div className={'flex items-end'}>
            <p
              className={'text-xs text-primaryBlue font-semibold leading-loose'}
            >
              {getCurrencySymbol(organization?.defaultCurrency)}
            </p>{' '}
            <p className="text-lg text-primaryBlue font-semibold leading-normal">
              {stats?.pendingBillablesAmount < 1000
                ? decimalDisplay(stats?.pendingBillablesAmount || 0)
                : formatNumberToKAndM(stats?.pendingBillablesAmount || 0)}
            </p>
          </div>
        </div>
        <div className="mr-6">
          <p className="text-xs text-dark600">
            PAID ({stats?.paidBillablesCount})
          </p>
          <div className={'flex items-end'}>
            <p className={'text-xs text-jade500 font-semibold leading-loose'}>
              {getCurrencySymbol(organization?.defaultCurrency)}
            </p>{' '}
            <p className="text-lg text-jade500 font-semibold leading-normal">
              {stats?.paidBillablesAmount < 1000
                ? decimalDisplay(stats?.paidBillablesAmount || 0)
                : formatNumberToKAndM(stats?.paidBillablesAmount || 0)}
            </p>
          </div>
        </div>
        <div className="mr-6">
          <p className="text-xs text-dark600">
            OVERDUE ({stats?.overdueBillablesCount})
          </p>
          <div className={'flex items-end'}>
            <p
              className={'text-xs text-crimson500 font-semibold leading-loose'}
            >
              {getCurrencySymbol(organization?.defaultCurrency)}
            </p>{' '}
            <p className="text-lg text-crimson500 font-semibold leading-normal">
              {stats?.overdueBillablesAmount < 1000
                ? decimalDisplay(stats?.overdueBillablesAmount || 0)
                : formatNumberToKAndM(stats?.overdueBillablesAmount || 0)}
            </p>
          </div>
        </div>
      </StatCard>
    </div>
  );
};
