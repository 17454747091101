import React, { useCallback, useContext, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../../Config';
import API from '../../../../libs/axios';
import { onError } from '../../../../libs/errorLib';
import { Box, Grid } from '@mui/material';
import addIcon from '../../../../assets/icons/addCard.svg';
import downloadIcon from '../../../../assets/icons/downloadIconNew.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import BinListComponent from '../components/BinListComponent';
import { makeStyles, Tooltip } from '@material-ui/core';
import {
  capitalize,
  exportToCSV,
  SharedContext
} from '../../../../utils/common';
import AddBinView from '../components/AddBinView';
import { toaster } from '../../../../utils/toaster';
import { checkValidationErr } from '../../../../services/BulkUploadServices';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import FLAGS from '../../../../constants/featureFlags';
import BinQRCode from './BinQRCode';
import qrIcon from '../../../../assets/icons/qr.svg';

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: '#0B1940'
  },
  tooltip: {
    backgroundColor: '#0B1940'
  }
}));

const BinView = ({
  warehouse,
  searchValue,
  warehouseDetail,
  bins,
  setBins,
  capacity,
  setCapacity
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const limit = 10;
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [updateHallValues, setUpdateHallValues] = useState(false);
  const toolTipClasses = useStylesBootstrap();
  const [openAddBin, setOpenAddBin] = useState(false);
  const [selectedBin, setSelectedBin] = useState();
  const [openQrBin, setOpenQrBin] = useState(false);
  const [selectedBinQR, setSelectedBinQR] = useState([]);
  const [warehouseCapacity] = useState({
    capacity: warehouseDetail?.capacity,
    pallets: warehouseDetail?.pallets
  });
  const { setAPILoader } = useContext(SharedContext);

  const getBins = useCallback(
    debounce((page, searchValue, oldData = []) => {
      let filters = {};

      let columns = ['name'];
      _getBins(page, searchValue, filters, columns, oldData);
    }, DEBOUNCE_CONST),
    []
  );

  useEffect(() => {
    getBins(page, searchValue);
  }, [searchValue, updateHallValues]);

  const _getBins = async (
    page,
    searchValue,
    filters,
    columns,
    oldData = []
  ) => {
    setAPILoader(true);
    try {
      const halls = await API.get(`warehouses/${warehouse?.id}/halls`, {
        params: {
          page,
          limit,
          search: searchValue,
          filters,
          columns
        }
      });
      setPageCount(halls.pages);
      const finalHalls = [...halls.data];
      const finalHallMapper = finalHalls.map((d, index) => ({
        ...d,
        orderId: index
      }));
      const { totalPalletCapacity, totalSqCapacity } =
        finalHallMapper?.reduce((total, singleValue) => {
          if (!total.totalPalletCapacity) total.totalPalletCapacity = 0;
          if (!total.totalSqCapacity) total.totalSqCapacity = 0;

          total.totalPalletCapacity =
            singleValue?.sectionType === 'RACKED' ||
            singleValue?.sectionType === 'GROUNDED'
              ? total?.totalPalletCapacity + singleValue?.pallets
              : total.totalPalletCapacity;

          total.totalSqCapacity =
            singleValue?.sectionType === 'RACKED' ||
            singleValue?.sectionType === 'GROUNDED'
              ? total?.totalSqCapacity + singleValue?.capacity
              : total.totalSqCapacity;
          return total;
        }, {}) || {};
      setCapacity({ pallets: totalPalletCapacity, capacity: totalSqCapacity });
      setBins([...oldData, ...finalHallMapper]);
      setAPILoader(false);
    } catch (err) {
      onError(err);
      setAPILoader(false);
    }
  };

  const handleExportSection = async () => {
    const halls = await API.get(`warehouses/${warehouse?.id}}/halls/export`);
    const hallsData = [...halls.data];

    const sortedHalls = getSortedHalls(hallsData);

    const exportSortedHalls = sortedHalls.map((hall) => {
      return {
        name: hall?.name || ' ',
        sectionType: capitalize(hall?.sectionType) || ' ',
        aisle: hall?.Aisle?.name || ' ',
        bay: hall?.Bay?.name || ' ',
        capacity: hall?.capacity || ' ',
        pallets: hall?.pallets || ' ',
        isActive: hall?.isActive,
        classifications: hall?.classifications || ' ',
        coordinates: `${hall?.coordinates?.x || ' '},${
          hall?.coordinates?.y || ' '
        },${hall?.coordinates?.z || ' '}`
      };
    });

    const title = `${warehouseDetail.name} Sections`;
    const header = [
      'BIN NAME',
      'BIN TYPE',
      'AISLE',
      'BAY',
      'CAPACITY (SQ. FT)',
      'CAPACITY (PALLETS)',
      'IS ACTIVE',
      'CLASSIFICATION',
      'COORDINATES (x)',
      'COORDINATES (y)',
      'COORDINATES (z)'
    ];

    exportToCSV(header, exportSortedHalls, title);
  };

  const handlePrintQrCode = async () => {
    const halls = await API.get(`warehouses/${warehouse?.id}}/halls/export`);
    const hallsData = [...halls.data];

    const sortedHalls = getSortedHalls(hallsData);
    setSelectedBinQR(sortedHalls);
    setOpenQrBin(true);
  };

  const getSortedHalls = (halls) => {
    const sortOrder = {
      id: 1,
      warehouseName: 2,
      name: 3,
      capacity: 4,
      pallets: 5,
      isActive: 6
    };

    halls.map((hall) => {
      delete hall['memo'];
      delete hall['createdAt'];
      delete hall['createdBy'];
      delete hall['deletedAt'];
      delete hall['updatedAt'];
      delete hall['warehouseId'];
      hall['warehouseName'] = warehouseDetail.name;
    });

    const sortedHalls = halls.map((hall) =>
      Object.assign(
        {},
        ...Object.keys(hall)
          .sort((a, b) => sortOrder[a] - sortOrder[b])
          .map((x) => {
            return { [x]: hall[x] };
          })
      )
    );

    return sortedHalls;
  };

  const addHall = async (data) => {
    setAPILoader(true);
    try {
      if (!selectedBin) {
        await API.post(`warehouses/${warehouse?.id}/halls`, data);
      } else {
        await API.put(
          `warehouses/${warehouse?.id}/halls/${selectedBin.id}`,
          data
        );
      }
      toaster(
        'success',
        !selectedBin ? 'New Bin has been created.' : 'Bin updated successfully.'
      );
      closeAddBinView();
      getBins(page, searchValue, bins);
      setUpdateHallValues(!updateHallValues);
      setAPILoader(false);
    } catch (err) {
      checkValidationErr(
        err,
        'unique_hall_warehouseId',
        `Bin already exists it must be unique`
      );
      setAPILoader(false);
    }
  };

  const closeAddBinView = () => {
    setSelectedBin(null);
    setOpenAddBin(false);
  };

  return searchValue && bins.length <= 0 ? (
    <></>
  ) : (
    <Grid
      item
      flex={1}
      className="bg-dark200 h-full rounded"
      style={{ boxSizing: 'border-box' }}
    >
      <Box>
        {openAddBin && (
          <AddBinView
            open
            handleClose={closeAddBinView}
            addHall={addHall}
            selectedHall={selectedBin}
            warehouse={warehouse}
            capacity={capacity}
            warehouseCapacity={warehouseCapacity}
          />
        )}
        <BinQRCode
          open={openQrBin}
          setOpen={setOpenQrBin}
          selectedBin={selectedBinQR}
          setSelectedBin={setSelectedBinQR}
        />

        <div className="mx-4 mt-4 mb-2 items-center">
          <div className="flex justify-between">
            <p>BINS</p>
            {isFeatureEnabled(FLAGS.LOCATION_HIERARCHY) && (
              <div className="flex items-center">
                <button className="flex mr-2" onClick={handlePrintQrCode}>
                  <img src={qrIcon} alt="qr-icon" />
                </button>
                <button className="flex mr-2" onClick={handleExportSection}>
                  <img src={downloadIcon} alt="download-icon" />
                </button>
                <Tooltip
                  arrow
                  title="Add Bins"
                  aria-label="add"
                  placement="top"
                  classes={toolTipClasses}
                >
                  <button
                    className="flex"
                    onClick={() => {
                      setOpenAddBin(true);
                    }}
                  >
                    <img src={addIcon} alt="add-icon" />
                  </button>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
        <div
          id={'scrollableDivBins'}
          className="items-center overflow-auto h-[60vh] no-scrollbar"
        >
          <InfiniteScroll
            dataLength={bins?.length} //This is important field to render the next data
            next={() => {
              setPage(page + 1);
              getBins(page + 1, searchValue, bins);
            }}
            hasMore={page < pageCount}
            loader={
              <p style={{ textAlign: 'center' }}>
                <b>Loading</b>
              </p>
            }
            refreshFunction={() => {
              setPage(1);
              getBins(page, searchValue, []);
            }}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={
              <h3 style={{ textAlign: 'center' }}>
                &#8595; Pull down to refresh
              </h3>
            }
            scrollableTarget="scrollableDivBins"
            releaseToRefreshContent={
              <h3 style={{ textAlign: 'center' }}>
                &#8593; Release to refresh
              </h3>
            }
          >
            {bins?.length > 0 &&
              bins.map((bin, id) => {
                return (
                  <BinListComponent
                    data={bin}
                    key={id}
                    searchValue={searchValue}
                    warehouse={warehouse}
                    _getBins={_getBins}
                    onClick={() => {
                      setSelectedBin(bin);
                      if (isFeatureEnabled(FLAGS.LOCATION_HIERARCHY)) {
                        setOpenAddBin(true);
                      }
                    }}
                    onClickQr={() => {
                      setSelectedBinQR([bin]);
                      setOpenQrBin(true);
                    }}
                  />
                );
              })}
          </InfiniteScroll>
        </div>
      </Box>
    </Grid>
  );
};

export default BinView;
