/*eslint-disable*/
import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Tooltip } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import grnStyles from './grnMakeStyles';
import MoveTypes from '../../../utils/enums/moveTypes';
import DeleteConfirmation from '../DeleteConfirmation';
import Button from '../../../core-components/atoms/Button';
import QR_ICON from '../../../assets/icons/qr.svg';
import Popup from '../../../core-components/atoms/Popup';
import AddBatch from './AddBatch';
import ProductSubTable from './ProductSubTable';
import ScanBatch from './ScanBatch';
import { decimalDisplay, SharedContext } from '../../../utils/common';
import DeleteBatch from './DeleteProduct';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import CustomTextField from '../../../core-components/atoms/TextField';
import { PRIMARY } from '../../../constants/colors';
import UomPopup from '../../revampedOrder/components/UomPopup';

const GRNTableHeaders = [
  {
    key: 'skuName',
    value: 'SKU Code & Name'
  },
  {
    key: 'batch',
    value: 'Batch'
  },
  {
    key: 'mfgd',
    value: 'Mfg Date'
  },
  {
    key: 'expd',
    value: 'Exp Date'
  },
  {
    key: 'receivedQuantity',
    value: 'Received Quantity'
  }
];
const GrnProductsTableRow = (props) => {
  const {
    row,
    parentIndex,
    rows,
    setRows,
    settleRemainingQty,
    formik,
    GRNview,
    addBatch,
    returnBatches
  } = props;

  const uomValue = row?.SecondaryUOM?.id
    ? {
        primary: false,
        conversionFactor:
          row?.Product?.ProductUOMs?.find(
            (u) => u.uomId == row?.SecondaryUOM?.id
          )?.conversionFactor || 1,
        ...row?.SecondaryUOM
      }
    : {
        ...row?.Product?.UOM,
        conversionFactor: 1,
        primary: true
      };
  const { organization } = useContext(SharedContext);
  const [open, setOpen] = useState(false);
  const classes = grnStyles();
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [parentRow, setParentRow] = useState(null);
  const [childRow, setChildRow] = useState(null);
  const [remainingQty, setRemainingQty] = useState(0);
  const [receivedQuantity, setReceivedQuantity] = useState(0);
  const [openSubTable, setOpenSubTable] = useState(false);
  const [scanOpen, setScanOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [damagedQuantity, setDamagedQuantity] = useState(0);
  const [uom, setUom] = useState(uomValue);
  const [uomView, setUomView] = useState(false);
  const [primaryUom, setPrimaryUom] = useState({
    ...row?.Product?.UOM,
    conversionFactor: 1,
    primary: true
  });

  const [secondaryUoms, setSecondaryUoms] = useState(
    row?.Product?.ProductUOMs?.map(
      (uom) =>
        ({
          id: uom?.id,
          name: uom?.UOM?.name,
          conversionFactor: uom?.conversionFactor,
          uomId: uom?.uomId
        }) || []
    )
  );

  const uoms = [primaryUom, ...secondaryUoms];

  const orderType = formik.values.Order.moveType;

  function onDelete() {
    let data = rows;
    if (childRow == 0 || childRow) {
      data[parentRow].GRNGroupBatches = data[parentRow].GRNGroupBatches.filter(
        (product, index) => {
          return index !== childRow;
        }
      );
    } else {
      data = data.filter((product, index) => {
        return index !== parentRow;
      });
    }

    setRows([...data]);
    setDeletePopupOpen(false);
    setParentRow(null);
    setChildRow(null);
    setLoading(false);
  }

  const editBatch = (parentIndex, childIndex, batch) => {
    let toggleData = rows;
    toggleData[parentIndex].GRNGroupBatches[childIndex] = batch;

    setRows([...toggleData]);
  };

  const deleteBatch = (childIndex, promo = false) => {
    let toggleData = rows;
    if (
      !+toggleData[parentIndex].GRNGroupBatches[childIndex].promoQuantity ||
      (promo &&
        +toggleData[parentIndex].GRNGroupBatches[childIndex]
          .receivedQuantity ===
          +toggleData[parentIndex].GRNGroupBatches[childIndex].promoQuantity)
    ) {
      toggleData[parentIndex].GRNGroupBatches.splice(childIndex, 1);
    } else if (promo) {
      toggleData[parentIndex].GRNGroupBatches[childIndex].receivedQuantity -=
        +toggleData[parentIndex].GRNGroupBatches[childIndex].promoQuantity || 0;
      toggleData[parentIndex].GRNGroupBatches[childIndex].promoName = '';
      toggleData[parentIndex].GRNGroupBatches[childIndex].promoQuantity = 0;
    } else {
      toggleData[parentIndex].GRNGroupBatches[childIndex].receivedQuantity =
        +toggleData[parentIndex].GRNGroupBatches[childIndex].promoQuantity;
      toggleData[parentIndex].GRNGroupBatches[
        childIndex
      ].unsellableDamageQuantity = 0;
      toggleData[parentIndex].GRNGroupBatches[
        childIndex
      ].recoverableDamageQuantity = 0;
    }

    setRows([...toggleData]);
  };

  useEffect(() => {
    const [recQty, damagedQty] = row?.GRNGroupBatches?.reduce(
      function (acc, obj) {
        return [
          acc[0] + (+obj?.receivedQuantity || 0),
          acc[1] +
            (+obj?.recoverableDamageQuantity || 0) +
            (+obj?.unsellableDamageQuantity || 0)
        ];
      },
      [0, 0]
    ) || [0, 0];
    setReceivedQuantity(recQty.toFixed(3));
    setDamagedQuantity(damagedQty.toFixed(3));
  }, [rows]);

  useEffect(() => {
    const remainingQty = decimalDisplay(
      row?.orderedQuantity
        ? (row?.orderedQuantity - receivedQuantity) /
            (uom?.conversionFactor || 1)
        : 0
    );
    setRemainingQty(
      row?.orderedQuantity - receivedQuantity < 0 ? 0 : remainingQty
    );
    settleRemainingQty(
      row?.orderedQuantity - receivedQuantity < 0 ? 0 : remainingQty
    );

    let toggleData = [...rows];

    if (!GRNview) {
      if (!uom?.primary) {
        toggleData[parentIndex].secondaryUomId = uom?.id || uom?.uomId;
        toggleData[parentIndex].secondaryUomQuantity =
          receivedQuantity / uom.conversionFactor;
      } else {
        toggleData[parentIndex].secondaryUomId = null;
        toggleData[parentIndex].secondaryUomQuantity = null;
      }
      setRows(toggleData);
    }
  }, [receivedQuantity, uom]);

  useEffect(() => {
    if (row?.secondaryUomId) {
      setUom(uoms?.find((uom) => uom?.uomId == row?.secondaryUomId));
    }
  }, []);

  const deleteNewProduct = () => {
    let toggleData = rows;
    toggleData.splice([parentIndex], 1);

    setRows([...toggleData]);
  };

  return (
    <>
      <TableRow
        className="group"
        sx={{
          '& td,th': {
            borderBottom: 0,
            paddingTop: 3,
            paddingBottom: 3
          }
        }}
      >
        <TableCell className="text-base">
          <span>
            <Tooltip title={`${row?.Product?.name}`}>
              <p className="text-base font-medium ">
                {row?.Product.name}-{row?.Product.description}
              </p>
            </Tooltip>
          </span>
          <div className="text-xs truncate">
            {row?.Product?.barcode && (
              <span>BARCODE: {row?.Product?.barcode}</span>
            )}
          </div>
        </TableCell>
        <TableCell className="text-base">
          <Autocomplete
            value={uom}
            onChange={(e, value) => {
              setUom(value);
            }}
            options={[
              ...uoms,
              ...(!GRNview && organization?.customUOM
                ? [{ name: '+ Create UoM', type: 'add' }]
                : [])
            ]}
            sx={{
              // border: "1px solid blue",
              '& .MuiOutlinedInput-root': {
                // border: "1px solid yellow",
                borderRadius: '0',
                padding: '0'
              },
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                border: '0px solid #eee'
              }
            }}
            renderOption={(props, option) => {
              // eslint-disable-next-line no-unused-vars
              const { className, ...rest } = props;
              return option?.type == 'add' ? (
                <li
                  className="auto-complete-option"
                  style={{
                    color: PRIMARY,
                    fontSize: '16px',
                    fontWeight: 500
                  }}
                  onClick={() => {
                    setUomView(true);
                  }}
                >
                  {option.name}
                </li>
              ) : (
                <li {...rest} className="auto-complete-option-small">
                  <span>
                    {option.name} {option.primary && '(primary)'}
                  </span>
                </li>
              );
            }}
            getOptionLabel={(uom) => uom.name}
            placeholder="UoM"
          />
        </TableCell>
        <TableCell className="text-base">
          {decimalDisplay(row.orderedQuantity / (uom?.conversionFactor || 1))}
        </TableCell>
        <TableCell
          className={`text-base ${receivedQuantity == 0 && 'text-error'}`}
        >
          {decimalDisplay(receivedQuantity / (uom?.conversionFactor || 1))}
          {row?.GRNGroupBatches?.some((batch) => batch.promoName) ? (
            <p className={classes.rowPromoQuanitity} style={{ marginLeft: 0 }}>
              Promo:{' '}
              {decimalDisplay(
                row.GRNGroupBatches.reduce(
                  (total, item) => total + parseFloat(item.promoQuantity),
                  0
                ) / (uom?.conversionFactor || 1)
              )}
            </p>
          ) : null}
        </TableCell>
        <TableCell className="text-base">{remainingQty}</TableCell>
        <TableCell className="flex pr-0">
          {GRNview || orderType == 'TRANSFER' || (
            <>
              <Button
                label="Scan"
                icon={<img src={QR_ICON} alt="Scan Icon" className="mr-2" />}
                onClick={() => {
                  setScanOpen(true);
                }}
                component="label"
                className="mr-1 w-24 inset-0 opacity-0 group-hover:opacity-100 transition-opacity"
              />
              <Button
                label="Add"
                className="ml-1 w-14"
                onClick={() => {
                  setOpen(true);
                }}
              />
              {!row.orderedQuantity && (
                <Button
                  label="Delete"
                  className="ml-2 w-14"
                  onClick={() => {
                    setDeleteOpen(true);
                  }}
                />
              )}
            </>
          )}
        </TableCell>
        {!!row?.GRNGroupBatches?.length && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenSubTable(!openSubTable)}
            >
              {openSubTable ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
        )}
        <Popup
          title={`Scan Batch Details for ${row?.Product?.name}-${row?.Product?.description}`}
          subTitle={`Received Qty: ${decimalDisplay(
            receivedQuantity
          )} · Good Qty: ${decimalDisplay(
            receivedQuantity / (uom?.conversionFactore || 1) -
              damagedQuantity / (uom?.conversionFactore || 1)
          )}`}
          open={scanOpen}
          setOpen={setScanOpen}
          content={
            <ScanBatch
              setOpen={setScanOpen}
              formik={formik}
              source={'GRN'}
              tableHeaders={GRNTableHeaders}
              rows={rows}
              setRows={setRows}
              selectedProduct={row?.Product}
              parentIndex={parentIndex}
            />
          }
        />
        <Popup
          title={`Add Batch Details for ${row?.Product?.name}-${row?.Product?.description}`}
          subTitle={`Received Qty: ${decimalDisplay(
            receivedQuantity
          )} · Good Qty: ${decimalDisplay(
            receivedQuantity / (uom?.conversionFactore || 1) -
              damagedQuantity / (uom?.conversionFactore || 1)
          )}`}
          open={open}
          setOpen={setOpen}
          content={
            <AddBatch
              addBatch={addBatch}
              editBatch={editBatch}
              parentIndex={parentIndex}
              remainingQty={remainingQty}
              setOpen={setOpen}
              product={row?.Product}
              existingBatches={row?.GRNGroupBatches || []}
              returnBatches={
                returnBatches?.find(
                  (dispatched) => dispatched?.productId === row?.Product?.id
                )?.batches || []
              }
              uom={uom}
            />
          }
        />
        <Popup
          title="Delete this SKU?"
          open={deleteOpen}
          setOpen={setDeleteOpen}
          content={
            <DeleteBatch
              setOpen={setDeleteOpen}
              productTitle={`${row?.Product?.name}-${row?.Product?.description}`}
              deleteNewProduct={deleteNewProduct}
            />
          }
        />
        <Popup
          title="Delete this SKU?"
          open={deleteOpen}
          setOpen={setDeleteOpen}
          content={
            <DeleteBatch
              setOpen={setDeleteOpen}
              productTitle={`${row?.Product?.name}-${row?.Product?.description}`}
              deleteNewProduct={deleteNewProduct}
            />
          }
        />
      </TableRow>
      {!!row?.GRNGroupBatches?.length && (
        <TableRow
          sx={
            openSubTable
              ? {
                  '& th,td': {
                    borderBottom: '0'
                  },
                  '& .childRow td': {
                    borderBottom: 'inset'
                  }
                }
              : null
          }
        >
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={12}
            className="subRow"
          >
            <Collapse in={openSubTable} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 2 }}>
                <div className="GRNScroll">
                  <ProductSubTable
                    batches={row?.GRNGroupBatches}
                    enableActions={
                      formik?.values?.Order?.moveType === MoveTypes?.TRANSFER
                    }
                    deleteBatch={deleteBatch}
                    GRNview={GRNview}
                    product={row?.Product}
                    addBatch={addBatch}
                    editBatch={editBatch}
                    parentIndex={parentIndex}
                    remainingQty={remainingQty}
                    receivedQuantity={receivedQuantity}
                    damagedQuantity={damagedQuantity}
                    row={row}
                    returnBatches={
                      returnBatches?.find(
                        (dispatched) =>
                          dispatched?.productId === row?.Product?.id
                      )?.batches || []
                    }
                    uom={uom}
                  />
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
      {deletePopupOpen ? (
        <DeleteConfirmation
          handleClose={() => setDeletePopupOpen(false)}
          open={deletePopupOpen}
          confirmDelete={onDelete}
          loading={loading}
        />
      ) : null}
      <UomPopup
        open={uomView}
        setOpen={setUomView}
        primaryUom={primaryUom}
        secondaryUoms={secondaryUoms}
        setPrimaryUom={setPrimaryUom}
        setSecondaryUoms={setSecondaryUoms}
        refetech={() => {}}
        value={{
          id: row?.Product?.id,
          isActive: true,
          batchEnabled: row?.Product?.batchEnabled
        }}
      />
    </>
  );
};

export default GrnProductsTableRow;
