import React, { useRef } from 'react';
import Popup from '../../../../../core-components/atoms/Popup';
import CustomButton from '../../../../../core-components/atoms/Button';
import QRCode from 'react-qr-code';
import { Title } from '../../../../../core-components/atoms/Text';
import { ReactToPrint } from 'react-to-print';

const BinQRCode = ({ selectedBin, setSelectedBin, open, setOpen }) => {
  const componentRef = useRef();
  console.log(selectedBin);
  return (
    <div style={{ display: 'inline' }}>
      <Popup
        open={open}
        setOpen={setOpen}
        title={'LOCATION QR CODE'}
        content={
          <div className="p-4">
            <div
              id="qr-print-section"
              ref={componentRef}
              className="grid grid-cols-[min-content_1fr] place-content-center"
            >
              {selectedBin?.map((item, index) => (
                <div
                  key={index}
                  className="m-4 p-4 border rounded page-break-bin"
                >
                  <QRCode
                    value={JSON.stringify({ id: item.id, name: item.name })}
                  />
                  <Title variant={'sm'}>Name: {item.name}</Title>
                </div>
              ))}
            </div>
          </div>
        }
        onClose={() => {
          setOpen(false);
          setSelectedBin([]);
        }}
        actions={
          <div className="flex gap-2">
            <CustomButton
              label="Cancel"
              className="py-2 px-3 ml-2 h-8 border-solid border "
              overrideSize={true}
              labelClass="font-medium text-xs"
              onClick={() => {
                setOpen(false);
              }}
              variant="tertiary"
            />
            <ReactToPrint
              trigger={() => (
                <CustomButton
                  className="py-2 px-3 ml-1 h-8 border-solid border border-Primary"
                  overrideSize={true}
                  labelClass="font-medium text-xs"
                  label={`Print Code`}
                  variant="primary"
                />
              )}
              content={() => componentRef.current}
            />
            {/*<CustomButton*/}
            {/*  className="py-2 px-3 ml-1 h-8 border-solid border border-Primary"*/}
            {/*  overrideSize={true}*/}
            {/*  labelClass="font-medium text-xs"*/}
            {/*  label={`Print Code`}*/}
            {/*  onClick={async () => {*/}
            {/*    handlePrint();*/}
            {/*  }}*/}
            {/*  variant="primary"*/}
            {/*/>*/}
          </div>
        }
      />
    </div>
  );
};

export default BinQRCode;
