export const user = null;

export const getUserToken = () => {
  return localStorage.getItem('token');
};

export const getPageTitle = () => {
  return localStorage.getItem('pageTitle');
};

export const getUser = () => {
  let user = localStorage.getItem('user');
  return user && JSON.parse(user);
};

export const setPageTitle = (title) => {
  localStorage.setItem('pageTitle', title);
};
export const setUserToken = (token) => {
  localStorage.setItem('token', token);
};

export const setUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const removeUserToken = () => {
  localStorage.removeItem('token');
};

export const getDontAskForAutoPickingConfirmation = () => {
  return localStorage.getItem('dontAskForAutoPickingConfirmation');
};

export const dontAskForAutoPickingConfirmation = () => {
  localStorage.setItem('dontAskForAutoPickingConfirmation', true);
};

export const removePageTitle = () => {
  localStorage.removeItem('pageTitle');
};

export const removeUser = () => {
  localStorage.removeItem('user');
};

export const removeAuth = () => {
  removeUserToken();
  removeUser();
};

export const checkPermission = (user, permission) => {
  return (
    (user &&
      user.Role &&
      user.Role.PermissionAccesses.find(
        (permissionAccess) => permissionAccess?.permissionType === permission
      )) ||
    (user &&
      user.GroupAccesses &&
      user.GroupAccesses.find(
        (permissionAccess) => permissionAccess?.permissionType === permission
      ))
  );
};

export const isForceUpdatePassword = (user) => {
  return Boolean(user?.changePasswordRequired);
};

export const isOwareSubdomain = () => {
  const host = window.location.host;
  const subdomain = host.split('.')[0];
  return subdomain === 'coc';
};

export const isTpplSubdomain = () => {
  const host = window.location.host;
  const subdomain = host.split('.')[0];
  return ['tppl', 'tppl-test'].includes(subdomain);
};

export const isLinxSubdomain = () => {
  const host = window.location.host;
  const subdomain = host.split('.')[0];
  return ['linxarabia'].includes(subdomain);
};

export const isSuperAdmin = (user) => user?.Role?.type === 'SUPER_ADMIN';

export const isOwareSuperAdmin = (user) =>
  isOwareSubdomain() && user?.Role?.type === 'SUPER_ADMIN';

export const isTpplSuperAdmin = (user) =>
  isTpplSubdomain() && user?.Role?.type === 'SUPER_ADMIN';
