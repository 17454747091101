import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Grid,
  TextField,
  Dialog,
  InputAdornment,
  Button,
  FormControl,
  Tooltip,
  Typography,
  DialogContent,
  MenuItem
} from '@material-ui/core';
import { debounce } from 'lodash';
import { SketchPicker } from 'react-color';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import FormContext from './OrganizationFormContext';
import FormikInputField from '../../../atomicComponents/FormikInputField';
import CancelButton from '../../../components/CancelButton';
import { organizationDetailsValidationSchema } from './formikFields';
import { DEBOUNCE_CONST } from '../../../Config';
import { logError } from '../../../libs/errorLib';
import useStyles from './OrganizationFormStyles';
import { DialogActions } from '@mui/material';
import API from '../../../libs/axios';
import { ORGANIZATION_TYPES, CURRENCIES, COUNTRIES } from '../../../constants';
import { SharedContext } from '../../../utils/common';

const OrganizationDetailsForm = ({
  getOrganizationByName,
  getOrganizationBySubdomain,
  edit
}) => {
  const {
    organizationDetails,
    setOrganizationDetails,
    next,
    handleClose,
    reset
  } = useContext(FormContext);
  const { setAPILoader } = useContext(SharedContext);
  const [primaryColorOpen, setPrimaryColorOpen] = useState(false);
  const [secondaryColorOpen, setSecondaryColorOpen] = useState(false);
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [nameError, setNameError] = useState(false);
  const [subdomainError, setSubdomainError] = useState('');
  const [prevName, setPrevName] = useState('');
  const [prevSubdomain, setPrevSubdomain] = useState('');

  const formik = useFormik({
    initialValues: organizationDetails,
    onSubmit: async (values) => {
      try {
        setAPILoader(true);
        const err = await formik.validateForm();
        !edit &&
          (await API.post('organizations/verify-organization-details', {
            email: values.email
          }));
        if (Object.keys(err).length === 0 && !nameError && !subdomainError) {
          next();
        } else {
          Object.keys(err).forEach((key) => logError(err[key]));
          if (nameError) logError('Organization name already exists');
          if (subdomainError) logError('Organization subdomain already exists');
        }
      } catch (e) {
        logError(e);
      } finally {
        setAPILoader(false);
      }
    },
    validationSchema: organizationDetailsValidationSchema,
    validate: async (values) => {
      const fetchOrganization = debounce(async (values) => {
        if (values.name) {
          if (values.name != prevName) {
            const org = await getOrganizationByName(values.name);
            if (org.organization) {
              setNameError(true);
            } else {
              setNameError(false);
            }
            setPrevName(values.name);
          }
        } else {
          setNameError(false);
        }
        if (values.subdomain) {
          if (values.subdomain != prevSubdomain) {
            const org = await getOrganizationBySubdomain(values.subdomain);
            if (org.organization) {
              setSubdomainError(
                'Organization with this subdomain already exists'
              );
            } else {
              setSubdomainError('');
            }
            setPrevSubdomain(values.subdomain);
          }
          if (/[\W_]/.test(values.subdomain)) {
            setSubdomainError(
              'Organization Subdomain cannot have special characters'
            );
          }
        } else {
          setSubdomainError('');
        }
      }, DEBOUNCE_CONST);
      !edit && (await fetchOrganization(values));
    }
  });

  const validateLogoImage = (event) => {
    const checkFile = event.target.files[0];
    if (!checkFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert('Logo image must be only image file!');
      return false;
    }
    const isLt2M = checkFile.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      alert('Logo image must smaller than 1MB!');
      return false;
    }
    formik.setFieldValue('logo', checkFile);
  };

  const classes = useStyles();

  useEffect(() => {
    setOrganizationDetails(formik.values);
  }, [formik.values]);

  return (
    <Grid container>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <FormikInputField
              name="name"
              type="text"
              formik={formik}
              title="Organization Name"
              disabled={edit}
              max={255}
            />
            {nameError && (
              <Typography className={classes.error}>
                Organization with this name already exists
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormikInputField
              name="subdomain"
              type="text"
              formik={formik}
              title="Subdomain"
              disabled={edit}
              max={255}
            />
            {subdomainError && (
              <Typography className={classes.error}>
                {subdomainError}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextField
              name="type"
              label="Organization Type"
              select
              fullWidth
              formik={formik}
              variant="outlined"
              value={formik.values.type}
              onChange={formik.handleChange}
              disabled={edit}
              error={formik.touched.type && formik.errors.type}
              helperText={formik.touched.type && formik.errors.type}
            >
              {Object.keys(ORGANIZATION_TYPES).map((key) => (
                <MenuItem
                  key={ORGANIZATION_TYPES[key]}
                  value={ORGANIZATION_TYPES[key]}
                >
                  {key.replace(/_/g, ' ')}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormikInputField
              name="email"
              type="email"
              formik={formik}
              title="Organization Email"
              // disabled={edit}
              max={255}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormikInputField
              name="industry"
              type="text"
              formik={formik}
              title="Industry"
              max={255}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormikInputField
              name="address"
              type="text"
              formik={formik}
              title="Address"
              max={255}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextField
              name="defaultCurrency"
              label="Default Currency"
              select
              fullWidth
              formik={formik}
              variant="outlined"
              value={formik.values.defaultCurrency}
              onChange={formik.handleChange}
              error={
                formik.touched.defaultCurrency && formik.errors.defaultCurrency
              }
              helperText={
                formik.touched.defaultCurrency && formik.errors.defaultCurrency
              }
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 150
                    }
                  }
                }
              }}
            >
              {Object.keys(CURRENCIES).map((key) => (
                <MenuItem key={CURRENCIES[key]} value={CURRENCIES[key]}>
                  {key.replace(/_/g, ' ')}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextField
              name="country"
              label="Country"
              select
              fullWidth
              formik={formik}
              variant="outlined"
              value={formik.values.country}
              onChange={formik.handleChange}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 150
                    }
                  }
                }
              }}
            >
              {COUNTRIES.map((country, id) => (
                <MenuItem key={id} value={country.name}>
                  {country.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Dialog
              open={primaryColorOpen}
              onClose={() => {
                setPrimaryColorOpen(false);
                setPrimaryColor('');
              }}
            >
              <DialogContent>
                <SketchPicker
                  color={primaryColor || formik?.values?.primaryColor}
                  onChangeComplete={(v) => {
                    setPrimaryColor(v.hex);
                  }}
                />
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={() => {
                    setPrimaryColorOpen(false);
                    formik?.setFieldValue('primaryColor', primaryColor);
                    setPrimaryColor('');
                  }}
                  color="primary"
                  variant="contained"
                >
                  ok
                </Button>
                <Button
                  onClick={() => {
                    setPrimaryColorOpen(false);
                    setPrimaryColor('');
                  }}
                  color="primary"
                  variant="contained"
                >
                  cancel
                </Button>
              </DialogActions>
            </Dialog>

            <TextField
              fullWidth={true}
              inputProps={{ className: classes.textBox }}
              className={classes.labelBox}
              id="primaryColor"
              label="Primary Color"
              type="text"
              variant="outlined"
              value={formik?.values?.primaryColor}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      onClick={() => setPrimaryColorOpen(true)}
                      color="primary"
                      variant="contained"
                    >
                      Choose color
                    </Button>
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={12} lg={12}>
            <Dialog
              open={secondaryColorOpen}
              onClose={() => {
                setSecondaryColorOpen(false);
                setSecondaryColor('');
              }}
            >
              <DialogContent>
                <SketchPicker
                  color={secondaryColor || formik?.values?.secondaryColor}
                  onChangeComplete={(v) => {
                    setSecondaryColor(v.hex);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setSecondaryColorOpen(false);
                    formik?.setFieldValue('secondaryColor', secondaryColor);
                    setSecondaryColor('');
                  }}
                  color="primary"
                  variant="contained"
                >
                  ok
                </Button>
                <Button
                  onClick={() => {
                    setSecondaryColorOpen(false);
                    setSecondaryColor('');
                  }}
                  color="primary"
                  variant="contained"
                >
                  cancel
                </Button>
              </DialogActions>
            </Dialog>

            <TextField
              fullWidth={true}
              inputProps={{ className: classes.textBox }}
              className={classes.labelBox}
              id="secondaryColor"
              label="Secondary Color"
              type="text"
              variant="outlined"
              value={formik?.values?.secondaryColor}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      onClick={() => setSecondaryColorOpen(true)}
                      color="primary"
                      variant="contained"
                    >
                      Choose color
                    </Button>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormControl margin="dense" fullWidth={true} variant="outlined">
              <Tooltip title="Upload Organization Logo">
                <Button
                  variant="contained"
                  component="label"
                  color={
                    formik?.values?.logo || organizationDetails.logo
                      ? 'primary'
                      : 'default'
                  }
                  startIcon={<CloudUploadIcon />}
                >
                  Logo{' '}
                  {formik?.values?.logo || organizationDetails.logo
                    ? 'Uploaded'
                    : ''}
                  <input
                    type="file"
                    hidden
                    onChange={(e) => validateLogoImage(e)}
                    accept=".jpg,.png,.jpeg"
                  />
                </Button>
              </Tooltip>
              {formik?.values?.logo && (
                <Button
                  variant="contained"
                  component="label"
                  onClick={() => formik?.setFieldValue('logo', '')}
                  className={classes.removeLogoBtn}
                >
                  Remove Logo
                </Button>
              )}
            </FormControl>
          </Grid>

          <div className={classes.btnContainer}>
            <CancelButton
              handleClose={handleClose}
              setValidation={() => {}}
              resetStates={reset}
            />
            <Tooltip>
              <Button color="primary" variant="contained" type="submit">
                Next
              </Button>
            </Tooltip>
          </div>
        </Grid>
      </form>
    </Grid>
  );
};
export default OrganizationDetailsForm;
