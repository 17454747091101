import React, { useCallback, useEffect, useRef, useState } from 'react';
import Popup from '../../../core-components/atoms/Popup';
import CustomButton from '../../../core-components/atoms/Button';
import { Label, Paragraph } from '../../../core-components/atoms/Text';
import { debounce } from 'lodash';
import { DEBOUNCE_CONST } from '../../../Config';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment/moment';

export default function ViewPodPopup({ open, setOpen, shipmentId, orderId }) {
  const tableCellClass = 'py-2 h-12 pl-4 pr-2 text-xs';
  const tableContainerRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [filesLoading, setFilesLoading] = useState(false);

  useEffect(() => {
    if (open) {
      if (orderId && shipmentId) {
        setPage(1);
        setFilesLoading(true);
        getDelayedFiles(1, [], orderId, shipmentId);
      }
      if (files.length > 0) {
        setFilesLoading(false);
      }
    }
  }, [orderId, shipmentId, open]);

  const getDelayedFiles = useCallback(
    debounce(
      async (page, oldData, orderId, shipmentId) => {
        getUserFiles(page, oldData, orderId, shipmentId);
      },
      [DEBOUNCE_CONST]
    ),
    []
  );

  const getUserFiles = async (page, oldData = [], orderID, shipmentID) => {
    try {
      const res = await API.get(`files`, {
        params: {
          page: page,
          limit: 20,
          columns: ['originalName'],
          shipmentIds: [shipmentID],
          ...(orderID ? { orderId: orderID } : {})
        }
      });
      const newArr = res?.data;
      setFiles([...oldData, ...newArr]);
      setPageCount(res?.pages);
      setFilesLoading(false);
    } catch (error) {
      onError(error);
      setFilesLoading(false);
    }
  };

  const [columnNames] = useState([
    'DOCUMENT NAME',
    'DOCUMENT TYPE',
    'REF ID',
    'DATE UPLOADED',
    'UPLOADED BY'
  ]);

  const handleTableScroll = () => {
    const container = tableContainerRef.current;
    if (
      container.scrollTop + container.clientHeight + 20 >=
        container.scrollHeight &&
      !filesLoading &&
      page < pageCount
    ) {
      setPage(page + 1);
      getDelayedFiles(page + 1, files, orderId, shipmentId);
    }
  };

  function getFileExtension(filename) {
    return filename.split('.').pop().toLowerCase();
  }

  function bytesToSize(bytes) {
    const mb = bytes / (1024 * 1024);
    const gb = bytes / (1024 * 1024 * 1024);

    if (gb >= 1) {
      return gb.toFixed(2) + ' GB';
    } else {
      return mb.toFixed(2) + ' MB';
    }
  }

  function openFileInNewTab(url) {
    const newTab = window.open(url, '_blank');
    newTab.focus();
  }

  return (
    <Popup
      title={'PoD Documents'}
      open={open}
      setOpen={setOpen}
      content={
        <div className="mb-5">
          <Paper className="mt-6 no-scrollbar rounded-lg border border-solid border-dark300">
            <TableContainer
              style={{ overflow: 'auto' }}
              ref={tableContainerRef}
              onScroll={handleTableScroll}
              className="no-scrollbar inventory-table rounded-lg"
            >
              <Table stickyHeader className="z-0">
                <TableHead className="relative z-0">
                  <TableRow>
                    {columnNames.map((columnName, index) => (
                      <TableCell
                        key={index}
                        className="text-[10px] text-dark500 bg-white py-2 pl-4 pr-2 font-medium h-[40px]"
                      >
                        {columnName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files
                    .filter((value) => value.sourceType === 'shipment')
                    .map((row, index) => {
                      const fileType = getFileExtension(row?.originalName);
                      return (
                        <TableRow
                          key={index}
                          className={clsx(
                            'hover:shadow-elevation-1 hover:z-20 hover:relative ease-in-out transition-all h-[48px]'
                          )}
                        >
                          <TableCell className={`${tableCellClass}`}>
                            <div className={'flex'}>
                              <div
                                onClick={() => {
                                  openFileInNewTab(row?.location);
                                }}
                                className={clsx(
                                  'flex items-center justify-center rounded h-[30px] w-[30px]',
                                  fileType === 'pdf'
                                    ? 'bg-[#CE375C]'
                                    : fileType === 'csv' ||
                                      fileType === 'xlsx' ||
                                      fileType === 'xls'
                                    ? 'bg-[#4DA954]'
                                    : fileType === 'doc' ||
                                      fileType === 'docx' ||
                                      fileType === 'txt'
                                    ? 'bg-[#5187ED]'
                                    : 'bg-dusk500'
                                )}
                              >
                                <Label variant={'xs'} className={'text-white'}>
                                  {fileType}
                                </Label>
                              </div>
                              <div className={'ml-2 overflow-hidden w-48'}>
                                <Paragraph
                                  variant={'sm'}
                                  className={'truncate'}
                                >
                                  {row?.originalName}
                                </Paragraph>
                                <Label
                                  variant={'xs'}
                                  className={'truncate text-dark500'}
                                >
                                  {bytesToSize(row?.size)}
                                </Label>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            className={`${tableCellClass} text-dark600 capitalize`}
                          >
                            {row?.sourceType === 'grn'
                              ? `${row?.sourceType?.toUpperCase()} - ${
                                  row?.sourceId
                                }`
                              : row?.sourceType === 'gdn'
                              ? `${row?.sourceType?.toUpperCase()} - ${
                                  row?.sourceId
                                }`
                              : row?.sourceType === 'shipment'
                              ? 'PoD'
                              : row?.sourceType}
                          </TableCell>
                          <TableCell
                            className={`${tableCellClass} text-dark600 capitalize`}
                          >
                            {row?.Shipment?.referenceId || '-'}
                          </TableCell>
                          <TableCell
                            className={`${tableCellClass} text-dark600 capitalize`}
                          >
                            {moment(row?.updatedAt).format('DD/MM/YY')}
                          </TableCell>
                          <TableCell
                            className={`${tableCellClass} text-dark600 capitalize`}
                          >
                            {(row?.createdBy?.firstName || '-') +
                              ' ' +
                              (row?.createdBy?.lastName || '-')}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {page < pageCount && (
                    <TableRow>
                      <TableCell className="py-2 h-12" colSpan={12}>
                        <div className="flex w-full justify-center">
                          <CircularProgress size="14px" />
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                  {files?.length === 0 && !filesLoading && (
                    <TableRow>
                      <TableCell className="py-2 h-12" colSpan={12}>
                        <div className="flex w-full justify-center">
                          <p>No data found</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      }
      dialogContentClasses="w-[50vw]"
      actions={
        <CustomButton
          label="Close"
          className="py-2 px-3 ml-2 h-8 border-solid border "
          overrideSize={true}
          labelClass="font-medium text-xs text-white"
          onClick={() => {
            setOpen(false);
          }}
          variant="primary"
        />
      }
    />
  );
}
