/*eslint-disable*/
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import moment from 'moment';

import PlaceholderImageIcon from '../../../assets/icons/placeholder.svg';
import DhlLogo from '../../../assets/files/dhl.png';
import MaerskLogo from '../../../assets/files/maersk.png';
import TrellaLogo from '../../../assets/files/trella.png';

const columnNames = ['NAME', 'VESSEL NAME', 'PRICE', 'SHIPPING DAYS', 'DATE'];

const ScheduleTable = ({
  schedules,
  onRowClick,
  shipmentMode,
  showSchedules
}) => {
  return (
    <Paper className="no-scrollbar rounded-t-lg shadow-none">
      <TableContainer
        style={{ overflow: 'auto' }}
        className="mt-10 no-scrollbar rounded-t-lg border border-solid border-dark300 min-w-[800px]"
      >
        <Table stickyHeader>
          <TableHead className="z-40 relative">
            <TableRow>
              <TableCell className="bg-white text-dark300 py-2 px-6 "></TableCell>
              {columnNames
                .filter((col) =>
                  shipmentMode == 'AIR' ? col !== 'VESSEL NAME' : true
                )
                .map((columnName, index) => (
                  <TableCell
                    key={index}
                    className={`text-[12px] text-dark500 bg-white py-2 pr-2 font-medium h-[40px] ${'pl-0'}`}
                  >
                    {columnName}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {showSchedules &&
              schedules?.map((schedule, index) => {
                return (
                  <TableRow
                    className="cursor-pointer hover:bg-[#f5f4f4]"
                    onClick={() => onRowClick(schedule)}
                  >
                    <TableCell
                      key={index}
                      className={`text-[12px] text-dark500 p-4 font-normal h-[40px]`}
                    >
                      <img
                        src={
                          schedule.carrierName?.toLowerCase() === 'trella'
                            ? TrellaLogo
                            : schedule.carrierName?.toLowerCase() === 'maersk'
                            ? MaerskLogo
                            : schedule.carrierName?.toLowerCase() === 'dhl'
                            ? DhlLogo
                            : PlaceholderImageIcon
                        }
                        className="w-10 h-10 ml-2"
                      />
                    </TableCell>
                    <TableCell
                      key={index}
                      className={`text-[12px] text-dark500 p-4 font-normal h-[40px] pl-0`}
                    >
                      {schedule.carrierName}
                    </TableCell>
                    {shipmentMode == 'SEA' && (
                      <TableCell
                        key={index}
                        className={`text-[12px] text-dark500 p-4 font-normal h-[40px] pl-0`}
                      >
                        {schedule.vesselName}
                      </TableCell>
                    )}
                    <TableCell
                      key={index}
                      className={`text-[12px] text-dark500 p-4 font-normal h-[40px] pl-0`}
                    >
                      ${schedule.price}
                    </TableCell>
                    <TableCell
                      key={index}
                      className={`text-[12px] text-dark500 p-4 font-normal h-[40px] pl-0`}
                    >
                      {schedule.shippingDays} Days
                    </TableCell>
                    <TableCell
                      key={index}
                      className={`text-[12px] text-dark500 p-4 font-normal h-[40px] pl-0`}
                    >
                      {moment(new Date(schedule.startDate)).format(
                        'DD/MM/YYYY'
                      )}{' '}
                      -{' '}
                      {moment(new Date(schedule.endDate)).format('DD/MM/YYYY')}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ScheduleTable;
