/*eslint-disable*/
import React, { useState, useContext, useEffect } from 'react';

import moment from 'moment';
import API from '../../../libs/axios';
import { SharedContext } from '../../../utils/common';
import CALENDAR_ICON from '../../../assets/icons/calendar.svg';
import REVERSE_ICON from '../../../assets/icons/reverse.svg';
import { useNavigate } from 'react-router';
import Button from '../../../core-components/atoms/Button';
import ProductDetailsInwardCsvReaderNew from '../../../components/ProductDetailsInwardCsvReaderNew';
import Popup from '../../../core-components/atoms/Popup';
import ReversalForm from '../grn/ReversalForm';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import template from '../../../assets/files/GDNBulkUploadTemplate.xlsx';
import O360GDNTemplate from '../../../assets/files/GDNBulkUploadTemplate.O360.xlsx';
import PrintOrder from '../../../components/printGrnGdn/PrintOrder';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import FLAGS from '../../../constants/featureFlags';
import DownloadIcon from '../../../assets/icons/downloadIconNew.svg';

const GdnBulkUpload = ({
  rows,
  setRows,
  errorData,
  setData,
  setErrorData,
  setErrorDialog,
  returnActualDispatchedQuantity,
  formik,
  GDNview,
  reversed,
  vehicleType,
  totalOrderedQuantity,
  totalDispatchedQuantity,
  totalReceivedQuantity,
  handleExportSection
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const [productFile, setProductFile] = useState(null);
  const [addProducts, setAddProducts] = useState(null);
  const [open, setOpen] = useState(false);
  const [datepickerOpen, setDatepickerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { setAPILoader, organization } = useContext(SharedContext);

  useEffect(() => {
    if (!addProducts) {
      return;
    }
    validateProductsData(addProducts);
  }, [rows, addProducts]);

  const bulkUpload = (data) => {
    if (!(Array.isArray(data.products) && data.products.length > 0)) {
      return;
    }
    let count = 2;
    let errorsArray = [];

    for (let prod of data.products) {
      if (!prod.name) {
        errorsArray = [
          ...errorsArray,
          `Row ${count} : SKU Name/SKU Code should not be null.`
        ];
      }
      if (prod.batchNumber && prod.batchNumber.length > 255) {
        errorsArray = [
          ...errorsArray,
          `Row ${count} : Batch Number should be less than 255 characters.`
        ];
      }
      if (prod.dispatchedQuantity < 0) {
        errorsArray = [
          ...errorsArray,
          `Row ${count} : Dispatched Quantity should not be less than 0.`
        ];
      }
      count++;
    }
    setData(data);
    if (errorsArray.length > 0) {
      setErrorDialog(true);
      setErrorData(errorsArray);
    }
    setAddProducts(
      data.products?.filter((p, i) => !errorData.find((e) => e?.row == i + 2))
    );
  };
  const validateBatchNumber = async (fileProducts) => {
    const companyId = formik.values.Order.companyId;
    const warehouseId = formik.values.Order.pickupNode.Warehouse.id;
    let response = null;
    const products = fileProducts.map((product) => {
      const date = moment(product.expiryDate).add('days', 1);
      const expiry = moment(date).utcOffset(0);
      expiry.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      return {
        ...product,
        expiryDate: expiry.toISOString(),
        quantity: product.dispatchedQuantity
      };
    });
    try {
      setAPILoader(true);
      response = await API.post(
        `warehouses/${warehouseId}/gdn-validate-product`,
        {
          products,
          companyId: organization?.type == 'MANUFACTURER' ? null : companyId
        }
      );
      return response;
    } catch (err) {
      if (err?.response?.data?.length > 0) {
        errorData = [...errorData, ...err.response.data];
        setErrorData(errorData);
        return false;
      }
    } finally {
      setAPILoader(false);
    }
  };

  const validateProductsData = async (fileProducts) => {
    let products = rows;
    const foundProducts = [];
    const errorFound = [];
    const foundBatches = {};
    setAddProducts(null);

    fileProducts.map((fileProduct, index) => {
      let isProductVerified = true;
      const foundProduct = products.find(
        (product, i) =>
          product.Product.name === fileProduct.name ||
          product.Product.description === fileProduct.name
      );

      if (foundProduct) {
        isProductVerified = validateProduct(fileProduct, foundProduct, index);
        if (isProductVerified)
          foundProducts.push({ fileProduct, foundProduct });
      } else {
        errorData.push({
          row: index + 2,
          message: `Product ${fileProduct.name} not found in Table.`
        });
        isProductVerified = false;
      }
      errorFound.push(isProductVerified);
    });
    const verifyBatch = await validateBatchNumber(fileProducts);

    foundProducts.forEach(({ fileProduct, foundProduct }, index) => {
      if (
        foundProduct?.Product?.MRPEnabled ||
        foundProduct?.Product?.batchEnabled
      ) {
        verifyBatch?.inventories?.forEach((inventory) => {
          if (inventory.Product.name === foundProduct.Product.name) {
            inventory?.InventoryDetail?.forEach((batch) => {
              if (
                batch.batchNumber === fileProduct.batchNumber &&
                moment(batch?.expiryDate).format('DD/MM/YYYY') ===
                  moment(fileProduct?.expiryDate).format('DD/MM/YYYY')
              ) {
                fileProduct.MRP = batch.MRP;
                if (
                  !foundBatches[
                    `${fileProduct?.batchNumber}_${fileProduct.expiryDate}`
                  ]
                ) {
                  foundBatches[
                    `${fileProduct?.batchNumber}_${fileProduct.expiryDate}`
                  ] = batch.availableQuantity;
                }
              }
            });
          }
        });
      }
    });

    if (verifyBatch.validationErrors?.length > 0) {
      errorData = errorData.concat(verifyBatch.validationErrors);
      setErrorData(errorData);
    }

    addProductFromFile(
      foundProducts?.filter((p, i) => !errorData.find((e) => e?.row == i + 2)),
      foundBatches
    );
  };

  const validateProduct = (fileProduct, foundProduct, index) => {
    let isBatch = true;
    let isExpiry = true;
    const actualQuantity = returnActualDispatchedQuantity(foundProduct);
    const newQuantity =
      Number(actualQuantity) + Number(fileProduct.dispatchedQuantity);
    const isQuantityRequired =
      newQuantity <= foundProduct.orderedQuantity ? true : false;

    if (
      foundProduct?.Product?.batchEnabled &&
      !fileProduct.expiryDate?.length > 0
    ) {
      errorData.push({
        row: index + 2,
        message: `Product ${fileProduct?.name} must have expiry date`
      });
      isExpiry = false;
    }
    if (
      foundProduct?.Product?.batchEnabled &&
      !fileProduct.batchNumber?.length > 0
    ) {
      errorData.push({
        row: index + 2,
        message: `Product ${fileProduct?.name} must have a batch number`
      });
      isExpiry = false;
    }

    if (!isQuantityRequired) {
      errorData.push({
        row: index + 2,
        message: `Product ${fileProduct?.name}'s quantity should not be greater than remaining quantity`
      });
    }

    setErrorData(errorData);
    return isBatch && isExpiry && isQuantityRequired;
  };

  const addProductFromFile = (data, foundBatches) => {
    let products = rows;

    data.map(({ fileProduct, foundProduct }, index) => {
      const productDetails = foundProduct?.Product;
      const actualQuantity = returnActualDispatchedQuantity(foundProduct);
      if (
        !foundProduct.Product.batchEnabled ||
        foundBatches[`${fileProduct.batchNumber}_${fileProduct.expiryDate}`] >=
          Number(fileProduct.dispatchedQuantity)
      ) {
        const newQuantity =
          Number(actualQuantity) + Number(fileProduct.dispatchedQuantity);
        if (foundProduct.Product.batchEnabled) {
          foundBatches[
            `${fileProduct.batchNumber}_${fileProduct.expiryDate}`
          ] -= Number(fileProduct.dispatchedQuantity);
        }
        productDetails.batchEnabled
          ? addBatchEnabledProduct(foundProduct, fileProduct, newQuantity)
          : addBatchDisabledProduct(foundProduct, newQuantity);
      }
    });

    setRows([...products]);
  };

  const addBatchDisabledProduct = (product, newQuantity) => {
    const batch = product.GDNGroupBatches[0];
    product.actualQuantity = newQuantity;
    if (batch) {
      batch.actualDispatchedQuantity = newQuantity;
    } else {
      product.GDNGroupBatches.push({
        batchNumber: null,
        manufacturingDate: null,
        expiryDate: null,
        actualDispatchedQuantity: newQuantity,
        MRP: null,
        id: 0
      });
    }
  };

  const addBatchEnabledProduct = async (product, fileProduct, newQuantity) => {
    let newBatch = true;
    product.actualQuantity = Number(newQuantity);
    product.GDNGroupBatches?.map((batch) => {
      if (
        batch.batchNumber === fileProduct.batchNumber &&
        moment(batch?.expiryDate).format('DD/MM/YYYY') ===
          moment(fileProduct?.expiryDate).format('DD/MM/YYYY')
      ) {
        batch.actualDispatchedQuantity =
          Number(batch.actualDispatchedQuantity) +
          Number(fileProduct.dispatchedQuantity);
        newBatch = false;
      }
    });

    if (newBatch) {
      product.GDNGroupBatches.push({
        batchNumber: fileProduct?.batchNumber,
        manufacturingDate: fileProduct?.manufacturingDate,
        expiryDate: fileProduct?.expiryDate,
        actualDispatchedQuantity: Number(fileProduct?.dispatchedQuantity),
        MRP: product?.Product?.MRPEnabled && fileProduct?.MRP,
        id: 0
      });
    }
  };

  return (
    <div className="flex">
      {reversed ||
        (GDNview && (
          <Button
            label="Reverse"
            icon={<img src={REVERSE_ICON} alt="Reverse" className="mr-2" />}
            className="ml-1"
            onClick={() => setOpen(true)}
          />
        )) || (
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                open={datepickerOpen}
                onClose={() => setDatepickerOpen(false)}
                value={formik.values.Order.activityDate}
                onChange={(date) =>
                  formik.setFieldValue('Order.activityDate', date)
                }
                PopperProps={{
                  placement: 'bottom-end',
                  anchorEl: anchorEl
                }}
                renderInput={({
                  ref,
                  inputProps,
                  disabled,
                  onChange,
                  value,
                  ...other
                }) => (
                  <div ref={ref} {...other}>
                    <input
                      style={{ display: 'none' }}
                      value={value}
                      onChange={onChange}
                      disabled={disabled}
                      {...inputProps}
                    />
                    <Button
                      label="Date"
                      icon={
                        <img
                          src={CALENDAR_ICON}
                          alt="Calendar Icon"
                          className="mr-2"
                        />
                      }
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                        setDatepickerOpen((isOpen) => !isOpen);
                      }}
                      className="mr-1"
                      ref={ref}
                    />
                  </div>
                )}
              />
            </LocalizationProvider>
            <ProductDetailsInwardCsvReaderNew
              bulkUpload={bulkUpload}
              selectedProductFile={productFile}
              setSelectedProductFile={setProductFile}
              orderType="gdn"
              template={
                !isFeatureEnabled(FLAGS.BATCH_AND_EXPIRY_TRACKING) &&
                !isFeatureEnabled(FLAGS.LOCATION_HIERARCHY)
                  ? O360GDNTemplate
                  : template
              }
            />
          </>
        )}
      {GDNview && (
        <PrintOrder
          formik={formik}
          orderType={'GDN'.toLowerCase()}
          vehicleType={vehicleType}
          tms={isFeatureEnabled(FLAGS.TMS)}
          totalOrderedQuantity={totalOrderedQuantity}
          totalDispatchedQuantity={totalDispatchedQuantity}
          totalReceivedQuantity={totalReceivedQuantity}
        />
      )}
      {/*{GDNview && (*/}
      {/*    <Button*/}
      {/*        label="Download"*/}
      {/*        className={*/}
      {/*          'py-2 px-2 ml-2 h-10 rounded border-solid border-primaryBlue'*/}
      {/*        }*/}
      {/*        variant="transparent"*/}
      {/*        overrideSize={true}*/}
      {/*        icon={<img src={DownloadIcon} className={`mr-[2px]`} />}*/}
      {/*        labelClass="font-medium text-xs"*/}
      {/*        iconClass="h-[14px] w-[14px] mr-1"*/}
      {/*        onClick={async (e) => {*/}
      {/*          e.preventDefault();*/}
      {/*          await handleExportSection();*/}
      {/*        }}*/}
      {/*    />*/}
      {/*)}*/}
      <Popup
        title="Why are you reversing this GDN?"
        open={open}
        setOpen={setOpen}
        content={
          <ReversalForm
            setOpen={setOpen}
            type="GDN"
            id={formik?.values?.Order?.GDNId}
          />
        }
      />
    </div>
  );
};

export default GdnBulkUpload;
