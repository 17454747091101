import React from 'react';
import { Close } from '@mui/icons-material';

export const UploadedFile = ({ file, files, setFiles, placementIcon }) => (
  <div className="relative">
    <Close
      onClick={() => {
        const updatedFiles = files.filter(
          (currentFile) => currentFile !== file
        );
        setFiles(updatedFiles);
      }}
      className="absolute top-[-5px] right-[-5px] w-[10px] h-[10px] rounded-full bg-primaryBlue text-white"
    />
    <div className="border rounded border-dotted h-[30px] w-[30px] items-center justify-center ml-2">
      <img src={placementIcon} alt="file-icon" />
    </div>
  </div>
);
