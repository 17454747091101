import React from 'react';
import { Label } from '../../../../core-components/atoms/Text';
import clsx from 'clsx';

export const FileDisplay = ({ file, openFileInNewTab }) => {
  function getFileExtension(filename) {
    return filename.split('.').pop().toLowerCase();
  }

  const fileType = getFileExtension(file?.originalName);
  const bgColor = clsx(
    fileType === 'pdf'
      ? 'bg-[#CE375C]'
      : ['csv', 'xlsx', 'xls'].includes(fileType)
      ? 'bg-[#4DA954]'
      : ['doc', 'docx', 'txt'].includes(fileType)
      ? 'bg-[#5187ED]'
      : 'bg-dusk500'
  );

  return (
    <div
      onClick={() => openFileInNewTab(file?.location)}
      className={`h-[30px] w-[30px] rounded items-center justify-center ml-2 ${bgColor}`}
    >
      <Label variant="xs" className="text-white">
        {fileType}
      </Label>
    </div>
  );
};
