/*eslint-disable*/
import React, { useContext, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EDIT_ICON from '../../../assets/icons/edit-row.svg';
import DELETE_ICON from '../../../assets/icons/delete-row.svg';
import AddBatch from './AddBatch';
import Popup from '../../../core-components/atoms/Popup';
import { decimalDisplay, SharedContext } from '../../../utils/common';

const ProductSubTable = ({
  batches,
  enableActions,
  deleteBatch,
  GRNview,
  row,
  addBatch,
  editBatch,
  parentIndex,
  remainingQty,
  receivedQuantity,
  damagedQuantity,
  returnBatches,
  uom
}) => {
  const [childIndex, setChildIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const { settings } = useContext(SharedContext);

  const dateFormat = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  };

  return (
    <>
      <Table
        size="small"
        className="bg-light border-2 border-dashed rounded-md py-3"
      >
        <TableHead className="border-none">
          <TableCell className="py-2 text-xs border-none text-dark500 font-normal">
            BATCH NUMBER
          </TableCell>
          {!row?.Product?.UOM?.weightMetric && (
              <TableCell className="py-2 text-xs border-none text-dark500 font-normal">
                ACTUAL WEIGHT ({settings?.weight?.symbol || 'Kg'})
              </TableCell>
          )}
          <TableCell className="py-2 text-xs border-none text-dark500 font-normal">
            RECEIVED QTY
          </TableCell>
          <TableCell className="py-2 text-xs border-none text-dark500 font-normal">
            DAMAGED
          </TableCell>
          <TableCell className="py-2 text-xs border-none text-dark500 font-normal">
            GOOD QTY
          </TableCell>
          {GRNview || <TableCell className="py-2 text-xs"></TableCell>}
        </TableHead>
        <TableBody className="border-none">
          {batches.map((batch, index) => {
            return (
              <>
                {+batch.receivedQuantity === +batch.promoQuantity || (
                  <TableRow className="group">
                    <TableCell className="py-2  border-none">
                      <p className="text-sm">{batch.batchNumber}</p>
                      <p className="text-xs">
                        {batch.manufacturingDate && (
                          <span className="mr-2">
                            Mfg:{' '}
                            {new Date(batch.manufacturingDate).toLocaleString(
                              undefined,
                              dateFormat
                            )}
                          </span>
                        )}
                        {batch.expiryDate && (
                          <span>
                            Exp:{' '}
                            {new Date(batch.expiryDate).toLocaleString(
                              undefined,
                              dateFormat
                            )}
                          </span>
                        )}
                      </p>
                    </TableCell>
                    {!row?.Product?.UOM?.weightMetric && (
                        <TableCell className="py-2 text-sm border-none">
                          {decimalDisplay(batch.actualWeight || 0)}
                        </TableCell>
                    )}
                    <TableCell className="py-2 text-sm border-none">
                      {decimalDisplay(
                        batch.receivedQuantity / (uom?.conversionFactor || 1) -
                          (batch.promoQuantity / (uom?.conversionFactor || 1) ||
                            0)
                      )}
                    </TableCell>
                    <TableCell className="py-2 text-sm text-warning border-none">
                      {decimalDisplay(
                        batch.recoverableDamageQuantity /
                          (uom?.conversionFactor || 1)
                      )}
                    </TableCell>
                    <TableCell className="py-2 text-sm border-none">
                      {decimalDisplay(
                        (batch.receivedQuantity -
                          (+batch.recoverableDamageQuantity || 0) -
                          (batch.promoQuantity || 0)) /
                          (uom?.conversionFactor || 1)
                      )}
                    </TableCell>
                    {GRNview || (
                      <TableCell className="w-24 p-0 border-none">
                        <span className="flex inset-0 opacity-0 group-hover:opacity-100 transition-opacity">
                          <img
                            src={EDIT_ICON}
                            alt="Edit Icon"
                            className="mx-2"
                            onClick={() => {
                              setChildIndex(index);
                              setOpen(true);
                            }}
                          />
                          {!enableActions && (
                            <img
                              src={DELETE_ICON}
                              alt="Delete Icon"
                              className="mx-2"
                              onClick={() => deleteBatch(index)}
                            />
                          )}
                        </span>
                      </TableCell>
                    )}
                  </TableRow>
                )}
                {(batch.promoName || !!batch.promoQuantity) && (
                  <TableRow className="group">
                    <TableCell className="py-2 text-sm text-darkPrimary border-none">
                      <div>
                        <span className="text-black">{batch.batchNumber}</span>
                        <pre className="font-bold inline"> · </pre>
                        {batch.promoName}
                      </div>
                      <p className="text-xs">
                        {batch.manufacturingDate && (
                          <span className="mr-2">
                            Mfg:{' '}
                            {new Date(batch.manufacturingDate).toLocaleString(
                              undefined,
                              dateFormat
                            )}
                          </span>
                        )}
                        {batch.expiryDate && (
                          <span>
                            Exp:{' '}
                            {new Date(batch.expiryDate).toLocaleString(
                              undefined,
                              dateFormat
                            )}
                          </span>
                        )}
                      </p>
                    </TableCell>
                    <TableCell className="py-2 text-sm border-none">
                      {decimalDisplay(batch.actualWeight)}
                    </TableCell>
                    <TableCell className="py-2 text-sm border-none">
                      -
                    </TableCell>
                    <TableCell className="py-2 text-sm border-none">
                      -
                    </TableCell>
                    <TableCell className="py-2 text-sm border-none">
                      {decimalDisplay(
                        batch.promoQuantity / (uom?.conversionFactor || 1)
                      )}
                    </TableCell>
                    {GRNview || (
                      <TableCell className="w-24 p-0 border-none">
                        <span className="flex inset-0 opacity-0 group-hover:opacity-100 transition-opacity">
                          <img
                            src={EDIT_ICON}
                            alt="Edit Icon"
                            className="mx-2"
                            onClick={() => {
                              setChildIndex(index);
                              setOpen(true);
                            }}
                          />
                          {!enableActions && (
                            <img
                              src={DELETE_ICON}
                              alt="Delete Icon"
                              className="mx-2"
                              onClick={() => deleteBatch(index, true)}
                            />
                          )}
                        </span>
                      </TableCell>
                    )}
                  </TableRow>
                )}
              </>
            );
          })}
        </TableBody>
      </Table>
      <Popup
        title={`Edit Batch Details for ${row?.Product?.name}-${row?.Product?.description}`}
        subTitle={`Received Qty: ${decimalDisplay(
          receivedQuantity
        )} · Good Qty: ${decimalDisplay(receivedQuantity - damagedQuantity)}`}
        open={open}
        setOpen={setOpen}
        content={
          <AddBatch
            addBatch={addBatch}
            editBatch={editBatch}
            parentIndex={parentIndex}
            remainingQty={remainingQty}
            setOpen={setOpen}
            product={row?.Product}
            edit={true}
            childIndex={childIndex}
            batch={batches[childIndex]}
            returnBatches={returnBatches}
            enableActions={enableActions}
            uom={uom}
          />
        }
      />
    </>
  );
};

export default ProductSubTable;
