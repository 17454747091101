/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Checkbox,
  IconButton,
  InputAdornment
} from '@mui/material';
import FilterIcon from '../../../assets/icons/filterDarkIcon.svg';
import SearchIcon from '@mui/icons-material/Search';
import Button from '../../../core-components/atoms/Button';
import TextField from '../../../core-components/atoms/TextField';
import PopupButton from '../../../core-components/molecules/PopupButton';
import { SharedContext } from '../../../utils/common';
import API from '../../../libs/axios';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  listItem: {
    '&:hover': {
      backgroundColor: '#F1F8FF'
    },
    cursor: 'pointer',
    display: 'flex',
    gap: '8px'
  },
  popOver: {
    maxHeight: '350px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.3rem'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#9DA0A6',
      borderRadius: '4px'
    }
  },
  search: {
    margin: '1rem auto',
    width: '90%',
    display: 'block'
  },
  paper: {
    pointerEvents: 'auto',
    width: '252px',
    border: '1px solid #E7E6E6',
    marginTop: '12px',
    marginRight: '16px'
  },
  subMenuPaper: {
    pointerEvents: 'auto',
    border: '1px solid #E7E6E6',
    height: '300px'
  }
}));

const LocationsFilter = ({
  open,
  setOpen,
  values,
  warehouseIds,
  setValues,
  callBack
}) => {
  const [search, setSearch] = useState('');
  const [locations, setLocations] = useState([]);
  const classes = useStyles();
  const { setAPILoader } = useContext(SharedContext);

  const fetchLocations = async () => {
    setAPILoader(true);
    try {
      const res = await API.get(`warehouses/halls`, {
        params: { warehouseIds, limit: 10, search }
      });

      const mapLocations = res.data.map((location) => ({
        id: location.id,
        name: location.name,
        warehouseName: location?.Warehouse?.name
      }));
      setLocations(mapLocations);
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    if (warehouseIds?.length > 0) fetchLocations();
  }, [search]);

  const handleCheckboxChange = (option, checked) => {
    const newValues = { ...values };
    if (checked) {
      newValues[option.id] = option;
    } else {
      delete newValues[option.id];
    }
    setValues(newValues);
    callBack();
  };

  return (
    <div>
      <PopupButton
        setOpen={setOpen}
        open={open}
        btn={
          <Button
            onClick={() => setOpen(!open)}
            variant="transparent"
            icon={<img src={FilterIcon} alt="Filter" className="mr-[6px]" />}
            labelClass="font-medium text-xs"
            iconClass="h-[14px] w-[14px] mr-1"
            label="All Locations"
            className="py-3 px-4 ml-2 h-10 rounded border-solid"
          />
        }
        content={
          <List style={{ width: '300px', maxHeight: '300px' }}>
            <ListItem>
              <TextField
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                fullWidth
              />
            </ListItem>
            {locations.map((option) => (
              <ListItem key={option} className={classes.listItem}>
                <Checkbox
                  onChange={(e) =>
                    handleCheckboxChange(option, e.target.checked)
                  }
                  checked={Boolean(values[option.id])}
                />
                <p className="text-sm">
                  {option.name}{' '}
                  <span className="text-xs">({option.warehouseName})</span>
                </p>
              </ListItem>
            ))}
          </List>
        }
      />
    </div>
  );
};

export default LocationsFilter;
