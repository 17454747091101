import { useState, useContext, useEffect } from 'react';
import { Grid, TextField, Typography, makeStyles } from '@material-ui/core';
import CreateIcon from '@mui/icons-material/Create';
import DetailPageTitle from '../../../atomicComponents/DetailPageTitle';
import { isArabCountry, SharedContext } from '../../../utils/common';
import API from '../../../libs/axios';
import { onError } from '../../../libs/errorLib';
import { checkPermission } from '../../../utils/auth';

export default function OrganizationDetails({ organization, formik, edit }) {
  const { setAPILoader, currentUser } = useContext(SharedContext);
  const [logo, setLogo] = useState('');

  const useStyles = makeStyles(() => ({
    logoContainer: {
      padding: '0.5rem',
      border: '1px solid #ACC9E6',
      width: '200px',
      position: 'relative',
      borderRadius: '8px'
    },
    logo: {
      width: '100%'
    },
    file: {
      display: 'none'
    },
    editIcon: {
      position: 'absolute',
      right: -10,
      top: -10,
      cursor: 'pointer'
    }
  }));

  const classes = useStyles();

  const fetchLogo = async () => {
    try {
      setAPILoader(true);
      const preview = await API.get(`rides/loads/preview/${organization.logo}`);
      setLogo(preview.preview);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const handleLogoEdit = () => {
    const upload = document.getElementById('logo-upload');
    upload.click();
  };

  useEffect(() => {
    if (organization && organization.logo) {
      fetchLogo();
    }
  }, [organization]);

  const disabled =
    !edit || !checkPermission(currentUser, 'OPS_UPDATE_BUSINESS_INFORMATION');

  return (
    <Grid item container className="mt-10">
      <Grid item xs={12} className="mt-10">
        <DetailPageTitle text="Organization Details" />
      </Grid>

      <Grid item container alignItems="center" className="mt-10">
        <Grid item xs={2}>
          <Typography>Logo</Typography>
        </Grid>
        <Grid item xs={4}>
          {logo && (
            <div className={classes.logoContainer}>
              <img src={logo} className={classes.logo} />
              {!disabled && (
                <>
                  <div className={classes.editIcon} onClick={handleLogoEdit}>
                    <CreateIcon style={{ fill: '#0C71D7' }} />
                  </div>
                  <input
                    type="file"
                    className={classes.file}
                    id="logo-upload"
                    onChange={(e) => {
                      setLogo(URL.createObjectURL(e.target.files[0]));
                      formik.setFieldValue(
                        'organization.logo',
                        e.target.files[0]
                      );
                    }}
                  />
                </>
              )}
            </div>
          )}
        </Grid>
        <Grid item xs={6} />
        <Grid xs={6} item>
          <hr className="dashedLineBreak" />
        </Grid>
      </Grid>
      <Grid item container alignItems="center">
        <Grid item xs={2}>
          <Typography>Company Name</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            value={formik?.values?.organization?.name}
            onChange={(e) => {
              formik.setFieldValue('organization.name', e.target.value);
            }}
            variant="outlined"
            disabled={disabled}
          />
          {formik.errors.organization?.name && (
            <Typography color="error">
              {formik.errors.organization.name}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <hr className="dashedLineBreak" />
        </Grid>
      </Grid>
      <Grid item container alignItems="center">
        <Grid item xs={2}>
          <Typography>
            Company{' '}
            {`${
              isArabCountry(organization)
                ? 'VAT'
                : 'Tax Identification Number (TIN)'
            }`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            value={formik?.values?.organization?.ntn}
            onChange={(e) => {
              formik.setFieldValue('organization.ntn', e.target.value);
            }}
            variant="outlined"
            disabled={disabled}
          />
          {formik.errors.organization?.ntn && (
            <Typography color="error">
              {formik.errors.organization.ntn}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <hr className="dashedLineBreak" />
        </Grid>
      </Grid>
      <Grid item container alignItems="center">
        <Grid item xs={2}>
          <Typography>Finance Email</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            value={formik?.values?.organization?.email}
            onChange={(e) => {
              formik.setFieldValue('organization.email', e.target.value);
            }}
            variant="outlined"
            type="email"
            disabled={disabled}
          />
          {formik.errors.organization?.email && (
            <Typography color="error">
              {formik.errors.organization.email}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <hr className="dashedLineBreak" />
        </Grid>
      </Grid>
      <Grid item container alignItems="center">
        <Grid item xs={2}>
          <Typography>Company Address</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            value={formik?.values?.organization?.address}
            multiline
            onChange={(e) => {
              formik.setFieldValue('organization.address', e.target.value);
            }}
            variant="outlined"
            disabled={disabled}
          />
          {formik.errors.organization?.address && (
            <Typography color="error">
              {formik.errors.organization.address}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <hr className="dashedLineBreak" />
        </Grid>
      </Grid>

      <Grid item container alignItems="center">
        <Grid item xs={2}>
          <Typography>Invoice notes</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            value={formik?.values?.organization?.notes}
            multiline
            onChange={(e) => {
              formik.setFieldValue('organization.notes', e.target.value);
            }}
            variant="outlined"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <hr className="dashedLineBreak" />
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: '5rem' }}>
        <Grid item xs={12}>
          <DetailPageTitle text="Finance Details" />
        </Grid>
        <Grid item container alignItems="center" className="mt-10">
          <Grid item xs={2}>
            <Typography>Bank Name</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={formik?.values?.organization?.bankName}
              onChange={(e) => {
                formik.setFieldValue('organization.bankName', e.target.value);
              }}
              variant="outlined"
              disabled={disabled}
            />
            {formik.errors.organization?.bankName && (
              <Typography color="error">
                {formik.errors.organization.bankName}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <hr className="dashedLineBreak" />
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={2}>
            <Typography>Bank Branch</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={formik?.values?.organization?.bankBranch}
              onChange={(e) => {
                formik.setFieldValue('organization.bankBranch', e.target.value);
              }}
              variant="outlined"
              disabled={disabled}
            />
            {formik.errors.organization?.bankBranch && (
              <Typography color="error">
                {formik.errors.organization.bankBranch}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <hr className="dashedLineBreak" />
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={2}>
            <Typography>Account Title</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={formik?.values?.organization?.accountTitle}
              onChange={(e) => {
                formik.setFieldValue(
                  'organization.accountTitle',
                  e.target.value
                );
              }}
              variant="outlined"
              disabled={disabled}
            />
            {formik.errors.organization?.accountTitle && (
              <Typography color="error">
                {formik.errors.organization.accountTitle}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <hr className="dashedLineBreak" />
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={2}>
            <Typography>Account Number</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={formik?.values?.organization?.accountNumber}
              multiline
              onChange={(e) => {
                formik.setFieldValue(
                  'organization.accountNumber',
                  e.target.value
                );
              }}
              variant="outlined"
              disabled={disabled}
            />
            {formik.errors.organization?.accountNumber && (
              <Typography color="error">
                {formik.errors.organization.accountNumber}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <hr className="dashedLineBreak" />
          </Grid>
        </Grid>

        <Grid item container alignItems="center">
          <Grid item xs={2}>
            <Typography>IBAN Number</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={formik?.values?.organization?.iban}
              multiline
              onChange={(e) => {
                formik.setFieldValue('organization.iban', e.target.value);
              }}
              variant="outlined"
              disabled={disabled}
            />
            {formik.errors.organization?.iban && (
              <Typography color="error">
                {formik.errors.organization.iban}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <hr className="dashedLineBreak" />
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={2}>
            <Typography>Swiftcode</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={formik?.values?.organization?.swiftCode}
              multiline
              onChange={(e) => {
                formik.setFieldValue('organization.swiftCode', e.target.value);
              }}
              variant="outlined"
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <hr className="dashedLineBreak" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
