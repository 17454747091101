/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';
import Button from '../../core-components/atoms/Button';
import FixedLayout from '../../core-components/molecules/FixedLayout';
import { Modal } from '@mui/material';
import { ChevronLeft, CloseOutlined } from '@material-ui/icons';

import { useFormik } from 'formik';
import CustomerForm from './AddCustomerForm';
import { companyTypes, nodeTypes } from './constants';
import { useNavigate, useParams } from 'react-router';
import { SharedContext } from '../../utils/common';
import { toaster } from '../../utils/toaster';
import { onError } from '../../libs/errorLib';
import { upload } from '../../utils/upload';
import API from '../../libs/axios';
import NodeForm from './AddNodeForm';
import Popup from '../../core-components/atoms/Popup';
import NodeSummaryView from './NodeSummaryView';
import { customerSchema } from './validationSchemas/customerSchema';
import { convertToTitleCase } from '../revampedOrder/AddOrder';
import { nodeValidation, resetNodeValidation } from './AddNode';
import { nodeSchema } from './validationSchemas/nodeSchema';
import NodeList from './NodeList';

export const nodeInitialValues = {
  name: '',
  nodeType: {},
  nodeClass: 'EXTERNAL',
  adhocFlag: true,
  isActive: true,
  description: '',
  pocName: '',
  pocContact: '',
  latLng: {
    lat: '',
    lng: ''
  },
  code: '',
  capacity: 0,
  pallets: 0,
  companyId: null,
  cityId: null,
  entity: null,
  IMS: true,
  WMS: true,
  savedNodes: []
};

export const getSuffix = (n) => {
  if (n == 2) {
    return 'nd';
  } else if (n == 3) {
    return 'rd';
  } else return 'th';
};

function AddCustomer({ edit, refetch }) {
  const { setAPILoader } = useContext(SharedContext);
  const [addNode, setAddNode] = useState(false);
  const [users, setUsers] = useState([]);
  const [summaryView, setSummaryView] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [customer, setCustomer] = useState({});
  const [nodeSearch, setNodeSearch] = useState('');
  const [deletedNodes, setDeletedNodes] = useState([]);
  const [nodePages, setNodePages] = useState(0);
  const [nodePage, setNodePage] = useState(1);
  const [nodes, setNodes] = useState([]);
  const [search, setSearch] = useState('');

  const { id } = useParams();

  const customerFormik = useFormik({
    initialValues: {
      supportingDocuments: [],
      name: '',
      code: '',
      type: '',
      ntn: '',
      categoryName: null,
      category: {},
      paymentTerm: {},
      paymentTermInDays: '',
      strn: '',
      pocName: '',
      pocId: '',
      pocEmail: '',
      pocContact: '',
      additionalInfo: '',
      paymentTermTitle: '',
      creditLimit: null,
      shippingAddress: '',
      billingAddress: '',
      sameAsBilling: false,
      email: '',
      isSupplier: false,
      isActive: true
    },
    validationSchema: customerSchema,
    onSubmit: async (values) => {
      await submitHandler(values);
    },
    validateOnMount: true
  });

  const nodeFormik = useFormik({
    initialValues: {
      name: '',
      nodeType: {},
      nodeClass: 'EXTERNAL',
      adhocFlag: true,
      isActive: true,
      description: '',
      pocContact: '',
      latLng: {
        lat: '',
        lng: ''
      },
      pocName: '',
      code: '',
      capacity: '',
      pallets: '',
      companyId: null,
      cityId: null,
      entity: {},
      isEntity: false,
      savedNodes: []
    },
    validationSchema: nodeSchema,
    validateOnMount: true
  });

  const editNodeFormik = useFormik({
    initialValues: {
      name: '',
      nodeType: {},
      nodeClass: 'EXTERNAL',
      adhocFlag: true,
      isActive: true,
      description: '',
      pocName: '',
      pocContact: '',
      latLng: {
        lat: '',
        lng: ''
      },
      code: '',
      capacity: '',
      pallets: '',
      companyId: null,
      cityId: null,
      entity: {}
    },
    validationSchema: nodeSchema,
    validateOnMount: true
  });

  const addCompany = async (data) => {
    let company = {};
    setAPILoader(true);
    try {
      company = await API.post(`companies`, data);
      toaster('success', 'New Customer has been created.');
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }

    return company;
  };

  const getUsers = async (value) => {
    try {
      let columns = [
        'firstName',
        'lastName',
        'username',
        'email',
        'phone',
        '$UserRoles.Role.name$'
      ];

      let colVal = {
        '$UserRoles.Role.allowedApps$': 'OPERATIONS',
        isActive: '1'
      };

      let filters = {
        colVal
      };

      const { data } = await API.get(`organizations/${subdomain}/users`, {
        params: { search: value || '', filters, columns }
      });

      setUsers(data);
    } catch (err) {
      onError(err);
    }
  };

  const addNodes = async (companyId) => {
    let payload = [...savedNodes];
    if (payload.length == 0) {
      payload = [
        {
          ...nodeFormik.values,
          nodeType: nodeFormik.values.nodeType.value,
          locationLatLng: nodeFormik.values.latLng,
          managerId: nodeFormik.values.manager?.id,
          companyId
        }
      ];
    } else {
      payload = savedNodes.map((node) => ({
        ...node,
        nodeType: node.nodeType.value,
        locationLatLng: node.latLng,
        managerId: node.manager?.id,
        companyId
      }));
      payload.push({
        ...nodeFormik.values,
        nodeType: nodeFormik.values.nodeType.value,
        locationLatLng: nodeFormik.values.latLng,
        companyId
      });
    }

    if (deletedNodes.length > 0) {
      payload = payload.filter((n, idx) => deletedNodes.indexOf(idx) == -1);
    }
    if (payload.length > 0) {
      setAPILoader(true);
      try {
        await API.post(`nodes/bulk?companyId=${companyId}`, payload);
        toaster('success', 'New Node has been created.');
      } catch (err) {
        onError(err);
      } finally {
        setAPILoader(false);
      }
    }
  };

  const getCompany = async (id) => {
    try {
      const company = await API.get(`companies/${id}`);
      setCustomer(company.company);
      customerFormik.setValues({ ...company.company });
      customerFormik.setFieldValue('customerType', {
        label: convertToTitleCase(company.company.type),
        value: company.company.type
      });
      customerFormik.setFieldValue(
        'category',
        company.company.CustomerCategory
      );
      customerFormik.setFieldValue('paymentTerm', company.company.PaymentTerm);
      customerFormik.setFieldValue(
        'paymentTermInDays',
        company.company.paymentTermInDays
      );
      if (company.company.billingAddress == company.company.shippingAddress) {
        customerFormik.setFieldValue('sameAsBilling', true);
      }
      if (company.company.supportingDocuments?.length > 0) {
        customerFormik.setFieldValue(
          'supportingDocuments',
          company.company.files
        );
      } else {
        customerFormik.setFieldValue('supportingDocuments', []);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCustomerNodes = async (search, companyId) => {
    try {
      let response = {};
      response = await API.get(`nodes`, {
        params: {
          page: nodePage,
          companyId: companyId || null,
          limit: 5,
          search: search
        }
      });
      setNodes(response.data);
      setNodePages(response.pages);
    } catch (err) {
      onError(err);
    } finally {
    }
  };

  const submitHandler = async () => {
    let payload = {
      ...customerFormik.values,
      currency: 'PKR',
      type: customerFormik.values.customerType?.value,
      isActive: true
    };
    if (customerFormik.values.supportingDocuments) {
      let fileIds = await upload(
        customerFormik.values.supportingDocuments,
        'customer'
      );
      payload.supportingDocuments = [...fileIds];
    }
    const company = await addCompany(payload);
    if (addNode) await addNodes(company.company.id);
  };

  const updateCompany = async () => {
    setAPILoader(true);
    let payload = {
      ...customerFormik.values,
      currency: 'PKR',
      type: customerFormik.values.customerType?.value
    };
    if (customerFormik.values.supportingDocuments) {
      let alreadyUploaded = customerFormik.values.supportingDocuments?.filter(
        (d) => d.id
      );
      let newDocs = customerFormik.values.supportingDocuments?.filter(
        (d) => !d.id
      );
      let fileIds = [];
      if (newDocs?.length > 0) {
        fileIds = await upload(newDocs, 'customer');
      }
      payload.supportingDocuments = [
        ...alreadyUploaded.map((d) => d.id),
        ...fileIds
      ];
    }
    try {
      await API.put(`companies/${id}`, payload);
      toaster(
        'success',
        customerFormik.values.relationType == 'SUPPLIER'
          ? 'Supplier has been updated.'
          : 'Customer has been updated.'
      );
      navigate('/administration/supply-chain-network');
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const { organizationType, subdomain } = useContext(SharedContext);

  const orgCompanyTypes = organizationType
    ? organizationType == 'THIRD_PARTY_SERVICE_PROVIDER'
      ? Object.keys(companyTypes['3PL']).map((key) => ({
          value: key,
          label: companyTypes['3PL'][key]
        }))
      : Object.keys(companyTypes.MANUFACTURER).map((key) => ({
          value: key,
          label: companyTypes.MANUFACTURER[key]
        }))
    : [];

  const nodeTypeOptions = Object.keys(nodeTypes.EXTERNAL).map((key) => ({
    value: key,
    label: nodeTypes.EXTERNAL[key]
  }));

  const navigate = useNavigate();

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (id) {
      getCompany(id);
    }
  }, [id]);

  let currentNode = {
    ...nodeFormik.values,
    nodeType: nodeFormik.values.nodeType,
    locationLatLng: nodeFormik.values.latLng
  };

  const savedNodes = nodeFormik.values.savedNodes;

  const entity = {
    name: customerFormik.values.name,
    type: customerFormik.values.type,
    address: customerFormik.values.billingAddress,
    code: customerFormik.values.code,
    type:
      customerFormik.values.customerType &&
      convertToTitleCase(customerFormik.values.customerType.value),
    label: 'Customer',
    entityType: 'CUSTOMER'
  };

  const customerValidation = async () => {
    await customerFormik.setFieldTouched('name', true);
    await customerFormik.setFieldTouched('customerType', true);
    await customerFormik.setFieldTouched('billingAddress', true);
    await customerFormik.setFieldTouched('shippingAddress', true);
    await customerFormik.setFieldTouched('email', true);
    await customerFormik.setFieldTouched('phone', true);
    const errs = await customerFormik.validateForm();
    return errs;
  };

  const Header = !edit ? (
    <>
      <div className="flex justify-between w-full items-center">
        <div className="flex gap-2">
          {addNode && (
            <div className="flex items-center">
              <ChevronLeft
                onClick={() => setAddNode(false)}
                className="cursor-pointer"
              />
            </div>
          )}
          <p className="font-bold text-2xl">
            {addNode
              ? `Create ${
                  savedNodes?.length > 0
                    ? savedNodes.length + 1 + getSuffix(savedNodes.length + 1)
                    : ''
                } node for ${customerFormik.values.name}`
              : 'Create Customer'}
          </p>
        </div>
        <div>
          <CloseOutlined
            onClick={() => {
              navigate('/administration/supply-chain-network');
            }}
            className="cursor-pointer"
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="flex justify-between w-full items-center">
        <div className="flex gap-2">
          <p className="font-bold text-2xl">{`Update ${
            customer
              ? customer.relationType == 'SUPPLIER'
                ? 'Supplier'
                : 'Customer'
              : ''
          }`}</p>
        </div>
        <div>
          <CloseOutlined
            onClick={() => {
              navigate('/administration/supply-chain-network');
            }}
            className="cursor-pointer"
          />
        </div>
      </div>
    </>
  );

  useEffect(() => {
    if (search) {
      getCustomerNodes(search, id);
    } else {
      getCustomerNodes('', id);
    }
  }, [search, nodePage]);

  return (
    <Modal
      sx={{
        minHeight: '100%',
        minWidth: '100%',
        background: 'white',
        border: 'none',
        overflowY: 'auto',
        margin: 0,
        boxShadow: 'none',
        outline: 'none'
      }}
      open
      BackdropProps={{ style: { backgroundColor: 'white' } }}
    >
      <div>
        <form>
          <FixedLayout
            header={Header}
            content={
              <div className="m-auto w-[60%] mt-10 mb-10">
                {!addNode ? (
                  <>
                    <CustomerForm
                      formik={customerFormik}
                      customerTypes={orgCompanyTypes}
                    />
                    <hr className="mb-8 mt-4" />
                    <NodeList
                      nodes={nodes}
                      refetch={() => getCustomerNodes(search, id)}
                      search={search}
                      setSearch={setSearch}
                      customer={{
                        name: customerFormik.values.name,
                        id,
                        value: id,
                        nodeClass: 'EXTERNAL',
                        entityType: 'Customer',
                        type:
                          customerFormik.values.customerType &&
                          convertToTitleCase(
                            customerFormik.values.customerType.value
                          )
                      }}
                      pages={nodePages}
                      setPage={setNodePage}
                      page={nodePage}
                    />
                  </>
                ) : (
                  <NodeForm
                    formik={nodeFormik}
                    nodeTypes={nodeTypeOptions}
                    users={users}
                    isEntity={false}
                    search={nodeSearch}
                    setSearch={setNodeSearch}
                    entity={entity}
                  />
                )}
                <Popup
                  open={summaryView}
                  setOpen={setSummaryView}
                  title={
                    activeIndex || activeIndex == 0
                      ? 'Edit this Node'
                      : 'Create This Company?'
                  }
                  content={
                    summaryView &&
                    (activeIndex || activeIndex == 0 ? (
                      <NodeForm
                        formik={editNodeFormik}
                        nodeTypes={nodeTypeOptions}
                        users={users}
                        isEntity={false}
                        edit={true}
                        search={nodeSearch}
                        setSearch={setNodeSearch}
                      />
                    ) : (
                      <NodeSummaryView
                        nodes={addNode ? [...savedNodes, currentNode] : []}
                        entity={entity}
                        deletedNodes={deletedNodes}
                        setDeletedNodes={setDeletedNodes}
                        onEdit={(id) => {
                          setActiveIndex(id);
                          if (id == [...savedNodes, currentNode].length - 1)
                            editNodeFormik.setValues({
                              ...nodeFormik.values
                            });
                          else
                            editNodeFormik.setValues({
                              ...savedNodes[id]
                            });
                        }}
                      />
                    ))
                  }
                  onClose={() => {
                    setSummaryView(false);
                    setActiveIndex(null);
                    setDeletedNodes([]);
                  }}
                  dialogContentClasses="w-[60vw]"
                  actions={
                    activeIndex || activeIndex == 0 ? (
                      <div className="flex gap-2">
                        <Button
                          label="Cancel"
                          onClick={() => setActiveIndex(null)}
                          variant="tertiary"
                        />
                        <Button
                          label="Edit this Node"
                          onClick={async () => {
                            if (
                              activeIndex ==
                              [...savedNodes, currentNode].length - 1
                            ) {
                              nodeFormik.setValues({
                                ...editNodeFormik.values,
                                savedNodes
                              });
                            } else
                              nodeFormik.setFieldValue(
                                `savedNodes[${activeIndex}]`,
                                { ...editNodeFormik.values }
                              );
                            setActiveIndex(null);
                          }}
                          disabled={!editNodeFormik.isValid}
                          variant="primary"
                        />
                      </div>
                    ) : (
                      <div className="flex gap-2">
                        <Button
                          label="Cancel"
                          onClick={() => setSummaryView(false)}
                          variant="tertiary"
                        />
                        <Button
                          label="Create Customer"
                          onClick={async () => {
                            await submitHandler(nodeFormik.values);
                            setSummaryView(false);
                            navigate('/administration/supply-chain-network');
                          }}
                          variant="primary"
                        />
                      </div>
                    )
                  }
                />
              </div>
            }
            footer={
              edit ? (
                <div className="flex justify-between w-full">
                  <Button
                    label="Cancel"
                    variant="tertiary"
                    onClick={() =>
                      navigate('/administration/supply-chain-network')
                    }
                  />

                  <div className="flex justify-between mr-4 gap-4">
                    <Button
                      label="Update Customer"
                      variant="primary"
                      disabled={!customerFormik.isValid}
                      onClick={async () => {
                        await updateCompany();
                        navigate('/administration/supply-chain-network');
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex justify-between">
                  <Button
                    label="Cancel"
                    variant="tertiary"
                    onClick={() =>
                      navigate('/administration/supply-chain-network')
                    }
                  />

                  <div className="flex justify-between mr-4 gap-4">
                    <Button
                      label={
                        !addNode
                          ? 'Create Customer & Add Node'
                          : 'Save & Add Another Node'
                      }
                      variant="secondary"
                      disabled={
                        !addNode ? !customerFormik.isValid : !nodeFormik.isValid
                      }
                      onClick={async () => {
                        if (!addNode) {
                          const errs = await customerValidation();
                          if (Object.keys(errs).length == 0) setAddNode(true);
                        } else {
                          const errs = await nodeValidation(nodeFormik);
                          if (Object.keys(errs).length == 0) {
                            let payload = {
                              ...nodeFormik.values,
                              nodeType: nodeFormik.values.nodeType,
                              locationLatLng: nodeFormik.values.latLng
                            };
                            nodeFormik.setValues({
                              ...nodeInitialValues,
                              savedNodes: [...savedNodes, payload]
                            });
                            setNodeSearch('');
                            await resetNodeValidation(nodeFormik);
                          }
                        }
                      }}
                    />
                    <Button
                      label={
                        !addNode ? 'Create Customer' : 'Create Customer & Node'
                      }
                      variant="primary"
                      disabled={
                        !addNode ? !customerFormik.isValid : !nodeFormik.isValid
                      }
                      onClick={async () => {
                        if (!addNode) {
                          const errs = await customerValidation();
                          if (Object.keys(errs).length == 0) {
                            // await submitHandler();
                            // navigate(`/administration/supply-chain-network`);
                            setSummaryView(true);
                          }
                        } else {
                          const errs = await nodeValidation(nodeFormik);
                          if (Object.keys(errs).length == 0) {
                            setSummaryView(true);
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              )
            }
          />
        </form>
      </div>
    </Modal>
  );
}

export default AddCustomer;
