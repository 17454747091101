/*eslint-disable*/
import { useContext, useState } from 'react';
import * as XLSX from 'xlsx';
import { Grid } from '@mui/material';

import { BulkTrackingVerification } from './BulkTrackingVerification';
import WarningIcon from '../../../assets/icons/warning.svg';
import downloadFileIcon from '../../../assets/icons/templateDownload.svg';
import { SharedContext } from '../../../utils/common';
import Popup from '../../../core-components/atoms/Popup';
import Button from '../../../core-components/atoms/Button';
import API from '../../../libs/axios';
import { logError } from '../../../libs/errorLib';

export const BulkTrackingUpload = () => {
  const [correctRows, setCorrectRows] = useState([]);
  const [incorrectRows, setIncorrectRows] = useState([]);
  const [bulkBtnOpen, setBulkBtnOpen] = useState(false);
  const [bulkTrackingViewOpen, setBulkTrackingViewOpen] = useState(false);

  const { setAPILoader } = useContext(SharedContext);

  const fileUpload = async (values) => {
    setAPILoader(true);
    const payload = values.map((value) => ({
      shipmentId: value['Shipment ID'],
      status: value['Status'],
      longitude: value['Longitude'],
      latitude: value['Latitude'],
      eta: value['ETA'],
      currentEvent: value['Additional Event']
    }));

    const { response } = await API.post('shipmentTrackings/verify', payload);

    setCorrectRows(response?.filter((row) => row.correct));
    setIncorrectRows(response?.filter((row) => !row.correct));

    setBulkTrackingViewOpen(true);
  };

  const exportToXlsx = () => {
    let trackings = incorrectRows?.map((row) => [
      row.shipmentId,
      row.status,
      row.latitude,
      row.longitude,
      row.eta,
      row.currentEvent,
      row.reason
    ]);
    const data = [
      [
        'Shipment ID',
        'Status',
        'Latitude',
        'Longitude',
        'ETA',
        'Additional Event',
        'Reason'
      ],
      ...trackings
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'incorrect_trackings');

    // Save the workbook as an XLSX file
    XLSX.writeFile(wb, 'incorrect_trackings.xlsx');
  };

  const createOrUpdateTrackings = async () => {
    setAPILoader(true);
    try {
      await API.put('shipmentTrackings', correctRows);
      setCorrectRows([]);
      setIncorrectRows([]);
      setBulkTrackingViewOpen(false);
      setBulkBtnOpen(false);
    } catch (e) {
      logError(e);
    } finally {
      setAPILoader(false);
    }
  };

  return (
    <div>
      <BulkTrackingVerification
        bulkBtnOpen={bulkBtnOpen}
        setBulkBtnOpen={setBulkBtnOpen}
        fileUpload={fileUpload}
      />
      <Popup
        open={bulkTrackingViewOpen}
        setOpen={setBulkTrackingViewOpen}
        title={`Upload Trackings`}
        dialogContentClasses={`min-w-[1000px]`}
        onClose={() => {
          setCorrectRows([]);
          setIncorrectRows([]);
          setBulkTrackingViewOpen(false);
          setBulkBtnOpen(false);
        }}
        actions={
          <div className="flex gap-2">
            <Button
              label="Cancel"
              onClick={() => setBulkTrackingViewOpen(false)}
              variant="secondary"
            />
            <Button
              label={
                correctRows?.length > 0
                  ? `Create ${correctRows?.length} ${
                      correctRows?.length > 1 ? 'Trackings' : 'Tracking'
                    }`
                  : 'Create'
              }
              onClick={async () => createOrUpdateTrackings()}
              disabled={correctRows?.length == 0}
              variant="primary"
            />
          </div>
        }
        content={
          <div>
            {incorrectRows?.length > 0 && (
              <div className="flex p-4 bg-[#FFEBEB] mt-4 justify-between">
                <div className="flex gap-2">
                  <img src={WarningIcon} />
                  <p className="text-sm">
                    {incorrectRows?.length}{' '}
                    {incorrectRows?.length > 1 ? 'rows are ' : 'row is '}
                    not uploaded because of formatting issues in file.
                  </p>
                </div>
                <div
                  onClick={async () => {
                    await exportToXlsx();
                  }}
                  className="flex gap-2 cursor-pointer"
                >
                  <img src={downloadFileIcon} />
                  <p className="text-sm">Download File</p>
                </div>
              </div>
            )}
            {correctRows?.length > 0 && (
              <>
                <div className="flex justify-between mt-4 pl-2">
                  <p className="font-medium mt-4">
                    {correctRows?.length}{' '}
                    {correctRows?.length > 1 ? 'trackings' : 'tracking'}{' '}
                    Detected
                  </p>
                </div>
                <Grid
                  className="mb-2 pl-4 min-h-[80px] w-full mt-4"
                  container
                  spacing={2}
                >
                  <Grid item xs={2} className="flex items-center font-medium">
                    Shipment ID
                  </Grid>
                  <Grid item xs={2} className="flex items-center font-medium">
                    Status
                  </Grid>
                  <Grid item xs={2} className="flex items-center font-medium">
                    Latitude
                  </Grid>
                  <Grid item xs={2} className="flex items-center font-medium">
                    Longitude
                  </Grid>
                  <Grid item xs={2} className="flex items-center font-medium">
                    ETA
                  </Grid>
                  <Grid item xs={2} className="flex items-center font-medium">
                    Additional Event
                  </Grid>
                </Grid>
                {correctRows?.map((row, id) => (
                  <Grid
                    className="p-2 pl-4 bg-[#F0F7FF] mb-2 min-h-[80px] w-full mt-4"
                    container
                    spacing={2}
                    key={id}
                  >
                    <Grid item xs={2} className="flex items-center">
                      <span className="text-xs">{row?.shipmentId}</span>
                    </Grid>
                    <Grid item xs={2} className="flex items-center">
                      <span className="text-xs">{row?.status}</span>
                    </Grid>
                    <Grid item xs={2} className="flex items-center">
                      <span className="text-xs">{row?.latitude}</span>
                    </Grid>
                    <Grid item xs={2} className="flex items-center">
                      <span className="text-xs">{row?.longitude}</span>
                    </Grid>
                    <Grid item xs={2} className="flex items-center">
                      <span className="text-xs">{row?.eta}</span>
                    </Grid>
                    <Grid item xs={2} className="flex items-center">
                      <span className="text-xs">{row?.currentEvent}</span>
                    </Grid>
                  </Grid>
                ))}
              </>
            )}
          </div>
        }
      />
    </div>
  );
};
