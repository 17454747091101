/*eslint-disable*/
import React, { useEffect, useState, useContext } from 'react';
import { Grid, FormControl, useMediaQuery } from '@material-ui/core';
import TextField from '../../../core-components/atoms/TextField';
import { useFormik } from 'formik';
import API from '../../../libs/axios';
import mixpanel from 'mixpanel-browser';
import { SharedContext } from '../../../utils/common';
import { useLocation, useNavigate, useParams } from 'react-router';
import { onError } from '../../../libs/errorLib';
import { upload } from '../../../utils/upload';
import { toaster } from '../../../utils/toaster';
import { DEBOUNCE_CONST } from '../../../Config';
import { debounce } from 'lodash';
import Button from '../../../core-components/atoms/Button';
import GrnProductsTable from './GrnProductsTable';
import {
  SupportingDocumentUpload,
  formatFileName
} from '../../revampedOrder/AddOrder';
import CANCEL_ICON from '../../../assets/icons/cancelIcon.svg';
import FixedLayout from '../../../core-components/molecules/FixedLayout';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import CLOUD_UPLOAD_ICON from '../../../assets/icons/cloud-upload.svg';
import TRUCK_ICON from '../../../assets/icons/shipment.svg';
import clsx from 'clsx';
import ProgressDetails from './ProgressDetails';
import Summary from './Summary';
import Popup from '../../../core-components/atoms/Popup';
import UserProfilePic from '../../../core-components/molecules/UserProfilePic';
import BackArrowIcon from '../../../assets/icons/chevronLeft.svg';
import ImagePreview from '../../../atomicComponents/ImagePreview';
import DOWN_ARROW_ICON from '../../../assets/icons/blue-down-arrow.svg';
import { PRIMARY, TERTIARY } from '../../../constants/colors';
import PhoneInput from 'react-phone-input-2';
import VehiclePopup from '../../logisticsRevamped/vehicle/VehiclePopup';
import AddVehicleTypePopup from '../../logisticsRevamped/vehicleType/components/vehicleTypePopup';
import AddcarrierPopup from '../../logisticsRevamped/carrier/components/carrierPopup';
import AddDriverPopup from '../../logisticsRevamped/driver/components/driverPopup';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import FLAGS from '../../../constants/featureFlags';
import AddGrnViewMobile from './AddGrnViewMobile';

const AddGrnView = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { oid, sid, id } = useParams();
  const location = useLocation();
  const [initialValues, setInitialValues] = useState({});
  const [GRNview, setGRNview] = useState(false);
  const { currentPageTitle, setCurrentPageTitle, setAPILoader, organization } =
    useContext(SharedContext);
  const navigate = useNavigate();
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [, setLoading] = useState(false);
  const [warehouseDocks, setWarehouseDocks] = useState([]);
  const [dockValue, setDockValue] = useState();
  const [vehicleType, setVehicleType] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [cars, setCars] = useState([]);
  const [reversed, setReversed] = useState(false);
  const [totalOrderedQuantity, setTotalOrderedQuantity] = useState(0);
  const [totalReceivedQuantity, setTotalReceivedQuantity] = useState(0);
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [openDraftPopup, setOpenDraftPopup] = useState(false);
  const [showMoreImages, setShowMoreImages] = useState(false);
  const [savingGRN, setSavingGRN] = useState(false);
  const [returnBatches, setReturnBatches] = useState([]);

  const [selectedCar, setSelectedCar] = useState({});
  const [carriers, setCarriers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState({});
  const [selectedDriver, setSelectedDriver] = useState({});
  const [selectedVehicle, setSelectedVehicle] = useState({});

  const [driverSearch, setDriverSearch] = useState('');
  const [carrierSearch, setCarrierSearch] = useState('');
  const [vehicleTypeSearch, setVehicleTypeSearch] = useState('');
  const [vehicleSearch, setVehicleSearch] = useState('');
  const [addVehicleTypeViewOpen, setAddVehicleTypeViewOpen] = useState(false);
  const [addVehicleView, setAddVehicleView] = useState(false);
  const [addDriverView, setAddDriverView] = useState(false);
  const [addCompanyViewOpen, setAddCompanyViewOpen] = useState(false);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [totalOrderedSecQuantity, setTotalOrderedSecQuantity] = useState(0);
  const [totalReceivedSecQuantity, setTotalReceivedSecQuantity] = useState(0);

  const { subdomain } = useContext(SharedContext);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      setAPILoader(true);
      submitHandler(values, false);
      setAPILoader(false);
    }
  });

  const getCarriers = async (search) => {
    try {
      const carriers = await API.get(`companies`, {
        params: {
          search: search || '',
          type: 'VENDOR',
          filters: { colVal: { isActive: '1' }, sortable: '' },
          logisticONly: true,
          columns: ['name']
        }
      });
      setCarriers(carriers?.data?.rows ? carriers?.data?.rows : []);
      setCustomerTypes(carriers?.data?.types || []);
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const getDrivers = async (search) => {
    try {
      if (selectedCarrier?.id) {
        const drivers = await API.get(`drivers`, {
          params: { search: search || '', companyId: selectedCarrier?.id }
        });
        setDrivers(drivers?.data ? drivers?.data : []);
      }
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const getVehicle = async (search) => {
    try {
      if (selectedCarrier?.id && selectedCar?.id) {
        const drivers = await API.get(`vehicles`, {
          params: {
            search: search || '',
            companyId: selectedCarrier.id,
            carId: selectedCar.id
          }
        });
        setVehicles(drivers?.data ? drivers?.data : []);
      }
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const _getUsers = async (page, searchKeyword, filters, columns) => {
    try {
      const users = await API.get(`organizations/${subdomain}/users`, {
        params: { offset: page, search: searchKeyword, filters, columns }
      });
      setUsers(users?.data?.filter((user) => user.isActive) || []);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  useEffect(() => {
    getVehicle(vehicleSearch);
  }, [selectedCar, selectedCarrier, vehicleSearch]);

  useEffect(() => {
    _getUsers();
  }, []);

  useEffect(() => {
    getDrivers(driverSearch);
  }, [driverSearch, selectedCarrier]);

  useEffect(() => {
    getCars(vehicleTypeSearch);
  }, [vehicleTypeSearch]);

  useEffect(() => {
    getCarriers(carrierSearch);
  }, [carrierSearch]);

  const changeExpanded = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const getOrder = async () => {
    try {
      setAPILoader(true);
      let order;
      let initData = {};
      order = await API.get(`warehouses/shipments/${sid}/grns?id=${id || ''}`);
      if (order?.data?.GRNData) {
        initObjInGRNsCase(order?.data?.GRNData, order?.data?.files, initData);
        const orderData = order?.data?.GRNData;
        const isDraft = orderData?.draftObject?.isDraft;
        const companyId = isDraft
          ? orderData?.draftObject?.Order?.companyId
          : orderData?.companyId;
        const orderId = isDraft
          ? orderData?.draftObject?.Order?.customId
          : orderData?.Order?.customId;
        getVehicleType(companyId, orderId);
      } else {
        order = await API.get(`warehouses/shipments/${sid}/gdns`);
        if (order?.data?.GDNData) {
          initObjInGDNsCase(order?.data?.GDNData, order?.data?.files, initData);
        } else {
          order = await API.get(`orders/order/${oid}`);
          let shipment = await API.get(`orders/${oid}/shipments/${sid}`);
          initObjInOrderCase(order?.data, shipment?.data, initData);
        }
      }
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  const getCars = async (search) => {
    try {
      const vehicles = await API.get(`vehicles/types`, {
        params: { search: search || '' }
      });
      setCars(
        vehicles?.data
          ? vehicles?.data.filter(
              (vehicleType) => vehicleType.isActive === true
            )
          : []
      );
    } catch (err) {
      let errors = err?.response?.data?.error?.errors;
      errors.map((data) => {
        onError(data);
      });
    }
  };

  const updateDropOffWarehouse = (products, hallId) => {
    products.forEach((product) => {
      product?.GRNGroupBatches?.forEach((batch) => {
        batch.hallId = hallId;
        batch.receivedQuantity = batch.actualDispatchedQuantity;
        batch.MRP = batch?.InventoryDetail?.MRP;
      });
    });
  };

  const initObjInGRNsCase = (order, files, initData) => {
    if (order?.draftObject?.isDraft) {
      initData.Order = order?.draftObject?.Order;
      initData.Order.GRNId = order?.id;
      initData.shipment = order?.draftObject?.shipment;
      initData.images = files;
    } else {
      initData.Order = {
        ...order?.Shipment?.Order,
        memo: order?.memo,
        GRNId: order?.id,
        // eslint-disable-next-line no-unused-expressions,no-unsafe-optional-chaining
        products: [...order?.GRNGroup],
        supportingDocuments: order?.supportingDocuments || [],
        vehicleTypeId: order?.vehicleTypeId,
        vehicleType: order?.vehicleType,
        driverName: order?.driverName,
        driverPhone: order?.driverPhone,
        vehicleNumber: order?.vehicleNumber,
        activityDate: order?.activityDate,
        user: order?.User
      };
      initData.shipment = {
        ...order?.Shipment,
        supportingDocuments: order?.supportingDocuments || [],
        vehicleTypeId: order?.vehicleTypeId,
        vehicleType: order?.vehicleType,
        driverName: order?.driverName,
        driverPhone: order?.driverPhone,
        vehicleNumber: order?.vehicleNumber
      };
      initData.images = files;
      initData.reversalReason = order?.reversalReason;
      if (initData.shipment?.ShipmentLegs?.[0]?.Company) {
        setSelectedCarrier(initData.shipment?.ShipmentLegs?.[0]?.Company);
        setSelectedCar(initData.shipment?.ShipmentLegs?.[0]?.VehicleType);
      } else {
        setSelectedCarrier(initData.shipment?.Carrier);
        setSelectedCar(initData.shipment?.vehicleType);
      }
    }

    if (order.reversedAt) {
      setGRNview(true);
      setReversed(true);
    }

    const showGRNView = ['COMPLETED'].includes(initData?.shipment?.status?.key);
    if (showGRNView) {
      setGRNview(true);
    }
    setInitialValues(initData);
  };

  const initObjInGDNsCase = (order, files, initData) => {
    if (order?.draftObject?.isDraft) {
      initData = order?.draftObject;
      initData.Order.GRNId = order?.id;
      initData.images = [];
    } else {
      initData.Order = {
        ...order?.Shipment?.Order,
        memo: order?.memo,
        // eslint-disable-next-line no-unused-expressions,no-unsafe-optional-chaining
        products: [...order?.GDNGroup],
        supportingDocuments: [],
        driverName: order?.driverName,
        driverPhone: order?.driverPhone,
        vehicleNumber: order?.vehicleNumber,
        activityDate: order?.activityDate
      };
      initData.shipment = {
        ...order?.Shipment,
        supportingDocuments: [],
        vehicleTypeId: order?.vehicleTypeId,
        vehicleType: order?.vehicleType,
        driverName: order?.driverName,
        driverPhone: order?.driverPhone,
        vehicleNumber: order?.vehicleNumber,
        palletize: order?.palletize ? 'Palletized' : 'Non-Palletized'
      };
      initData.Order.products = initData.Order.products.map((element) => {
        return {
          id: element.id,
          orderedQuantity: element.quantity,
          Product: element.Product,
          ProductId: element?.Product?.id,
          GRNGroupBatches: [...element.GDNGroupBatches],
          receivedQuantity: element.quantity,
          secondaryUomId: element.secondaryUomId,
          secondaryUomQuantity: element.secondaryUomQuantity,
          SecondaryUOM: element.SecondaryUOM
        };
      });

      updateDropOffWarehouse(
        initData.Order.products,
        order?.Shipment?.Order?.warehouseDropOff?.hallId
      );
      initData.images = files;
    }

    const showGRNView = ['COMPLETED'].includes(initData?.shipment?.status?.key);
    if (showGRNView) {
      setGRNview(true);
    }
    setInitialValues(initData);
  };

  const initObjInOrderCase = (order, shipment, initData) => {
    initData.Order = {
      ...order,
      products: [
        ...order.OrderProductGroups.filter((product) => !!+product.quantity)
      ],
      supportingDocuments: []
    };

    initData.Order.products.forEach((element, index) => {
      if (location.state == 'Receive_full') {
        initData.Order.products[index]['GRNGroupBatches'] = [
          { receivedQuantity: element.orderedQuantity }
        ];

        initData.Order.products[index].receivedQuantity =
          element.orderedQuantity;
      } else {
        initData.Order.products[index]['GRNGroupBatches'] = [];
      }

      initData.Order.products[index].secondaryUomId =
        initData.Order.products[index]?.SecondaryUOM?.id;
    });

    initData.shipment = shipment;
    initData.shipment.palletize = shipment.palletize
      ? 'Palletized'
      : 'Non-Palletized';

    const showGRNView = ['COMPLETED'].includes(initData?.shipment?.status?.key);
    if (showGRNView) {
      setGRNview(true);
    }
    if (order?.referenceOrderId) {
      getReturnBatches(order?.referenceOrderId);
    }

    if (initData.shipment?.ShipmentLegs?.[0]?.Company) {
      setSelectedCarrier(initData.shipment?.ShipmentLegs?.[0]?.Company);
      setSelectedCar(initData.shipment?.ShipmentLegs?.[0]?.VehicleType);
    } else {
      setSelectedCarrier(initData.shipment?.Carrier);
      setSelectedCar(initData.shipment?.vehicleType);
    }
    setInitialValues(initData);
  };

  const getReturnBatches = async (orderId) => {
    const batches = await API.get(`/orders/${orderId}/return-batches`);
    setReturnBatches(batches);
  };

  useEffect(() => {
    if (oid) {
      getOrder();
    }
  }, [oid]);

  useEffect(() => {
    getCars();
  }, []);

  const getVehicleType = async (companyId, orderId) => {
    if (!companyId || !orderId) return;
    try {
      setAPILoader(true);
      const order = await API.get(`orders/order/${orderId}`);
      setVehicleType(order.data.vehicleType);
    } catch (err) {
      onError(err);
    } finally {
      setAPILoader(false);
    }
  };

  useEffect(() => {
    let title = 'GRN Detail';
    setCurrentPageTitle(title);
    // setCurrentPageIcon(
    //   PrintIconButton(handleClick, handleClose, 'Print GRN Details')
    // );
  }, [currentPageTitle, setCurrentPageTitle]);

  const validateQty = () => {
    const isValid = !!totalReceivedQuantity;

    if (isValid) return isValid;

    toaster('warning', 'At least 1 received qty is required to create a GRN');
    return isValid;
  };

  const submitHandler = async (values, isDraft) => {
    setAPILoader(true);
    if (isDraft || validateQty()) {
      setIsDisabledButton(true);
      let fileIds = [];
      fileIds = values?.Order.supportingDocuments?.length
        ? await upload(
            values?.Order.supportingDocuments?.filter(
              (file) => !file?.id && !Number.isInteger(file)
            ),
            'grns'
          )
        : [];
      if (!values?.Order?.activityDate) {
        values.Order['activityDate'] = new Date();
      }

      values.Order = {
        ...values.Order,
        supportingDocuments: [
          ...values.Order.supportingDocuments.filter(
            (file) => file.id || Number.isInteger(file)
          ),
          ...fileIds
        ]
      };
      values.shipment = {
        ...values.shipment,
        supportingDocuments: [...values.Order.supportingDocuments, ...fileIds]
      };
      setSavingGRN(true);
      try {
        if (!values?.Order?.GRNId) {
          await API.post(`nodes/${values?.Order?.dropOffId}/grns`, {
            Order: values.Order,
            shipment: values.shipment,
            isDraft: isDraft,
            companyId: values?.Order?.companyId,
            hostUrl: window.location.href
          });
        } else {
          await API.put(
            `nodes/${values?.Order?.dropOffId}/grns/${values?.Order?.GRNId}`,
            {
              Order: values.Order,
              shipment: values.shipment,
              isDraft: isDraft,
              companyId: values?.Order?.companyId,
              hostUrl: window.location.href
            }
          );
        }
        if (values?.Order?.dockId || values?.Order?.dropOffDockId) {
          await API.put(`orders/${values?.Order?.customId}`, {
            dockId: values?.Order?.dockId,
            referenceId: values?.Order?.referenceId,
            dropOffDockId: values?.Order?.dropOffDockId
          });
        }
        if (!isDraft) {
          setGRNview(true);
        }
        navigate(-1);
        toaster(
          'success',
          isDraft ? 'GRN has been drafted' : 'GRN has been created.'
        );
        mixpanel.track(isDraft ? 'GRN drafted' : 'GRN created', {
          order: values?.Order?.customId,
          isDraft
        });
      } catch (err) {
        onError(err);
      } finally {
        setLoading(false);
        setIsDisabledButton(false);
        setAPILoader(false);
        setSavingGRN(false);
      }
    }
  };

  useEffect(() => {
    formik.setFieldValue('files', formik.values.images);
  }, [formik.values.images]);

  const handleDock = (val) => {
    if (formik?.values?.Order?.moveType === 'TRANSFER') {
      formik.setFieldValue('shipment.dropOffDockId', val ? val.id : null);
      formik.setFieldValue(
        'shipment.dropOffDock',
        val ? { name: val.name, id: val.id } : {}
      );
    } else {
      formik.setFieldValue('shipment.dockId', val ? val.id : null);
      formik.setFieldValue(
        'shipment.WarehouseDock',
        val ? { name: val.name, id: val.id } : {}
      );
    }
  };

  const getWarehouseDocks = debounce((value) => {
    _getWarehouseDocks(value).then((warehouseDocks) => {
      setWarehouseDocks(warehouseDocks?.data);
    });
  }, DEBOUNCE_CONST);

  useEffect(() => {
    getWarehouseDocks(dockValue);
  }, [dockValue]);

  const _getWarehouseDocks = async (value) => {
    try {
      let warehouseDocks = await API.get(
        `warehouses/${formik?.values?.Order?.warehouseDropOffId}/docks`,
        { params: { search: value } }
      );
      return warehouseDocks;
    } catch (err) {
      onError(err);
    }
  };

  const isDockAssignmentEnabled = isFeatureEnabled(
    FLAGS.DOCK_CREATION_AND_ASSIGNMENT
  );

  const disableGRN = formik.values.Order?.products?.reduce((acc, curr) => {
    const occQty =
      curr[`GRNGroupBatches`]?.reduce(
        (acc, obj) => acc + (+obj['receivedQuantity'] || 0),
        0
      ) || (0).toFixed(3);

    return acc && occQty == 0;
  }, true);

  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <>
      {isMobile ? (
        <AddGrnViewMobile />
      ) : (
        <Modal
          sx={{
            minHeight: '100%',
            minWidth: '100%',
            background: 'white',
            border: 'none',
            overflowY: 'auto',
            margin: 0,
            boxShadow: 'none',
            outline: 'none'
          }}
          open
          BackdropProps={{ style: { backgroundColor: 'white' } }}
        >
          <FixedLayout
            header={
              <>
                <div className="flex justify-between w-full items-center ml-16">
                  <div className="flex">
                    <img
                      src={BackArrowIcon}
                      alt="Back Icon"
                      className="cursor-pointer"
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                    <p className="font-bold text-2xl">
                      {GRNview ? 'View GRN' : 'Receive Inventory'}
                    </p>
                  </div>
                  <div>
                    <UserProfilePic />
                  </div>
                </div>
              </>
            }
            content={
              <Grid className="flex h-full">
                <VehiclePopup
                  open={addVehicleView}
                  setOpen={setAddVehicleView}
                  refetch={getVehicle}
                  organizationType={organization?.type}
                />
                <AddVehicleTypePopup
                  open={addVehicleTypeViewOpen}
                  setOpen={setAddVehicleTypeViewOpen}
                  organizationType={organization?.type}
                  refetch={getCars}
                />
                {addCompanyViewOpen && (
                  <AddcarrierPopup
                    open={addCompanyViewOpen}
                    setOpen={setAddCompanyViewOpen}
                    organizationType={organization?.type}
                    refetch={getCarriers}
                  />
                )}
                <AddDriverPopup
                  open={addDriverView}
                  setOpen={setAddDriverView}
                  refetch={getDrivers}
                  organizationType={organization?.type}
                />
                <Grid xs={9} className="pr-10">
                  <div className="mx-24 mt-16">
                    {formik?.values?.Order?.products ? (
                      <GrnProductsTable
                        formik={formik}
                        GRNview={GRNview}
                        reversed={reversed}
                        totalOrderedQuantity={totalOrderedQuantity}
                        setTotalOrderedQuantity={setTotalOrderedQuantity}
                        totalReceivedQuantity={totalReceivedQuantity}
                        setTotalReceivedQuantity={setTotalReceivedQuantity}
                        returnBatches={returnBatches}
                        vehicleType={vehicleType}
                        totalDispatchedQuantity={0}
                        batchExpiry={isFeatureEnabled(
                          FLAGS.BATCH_AND_EXPIRY_TRACKING
                        )}
                        totalOrderedSecQuantity={totalOrderedSecQuantity}
                        setTotalOrderedSecQuantity={setTotalOrderedSecQuantity}
                        totalDispatchedSecQuantity={0}
                        totalReceivedSecQuantity={totalReceivedSecQuantity}
                        setTotalReceivedSecQuantity={
                          setTotalReceivedSecQuantity
                        }
                      />
                    ) : (
                      ''
                    )}

                    <div className="mt-16">
                      <p className="text-xl font-semibold">
                        Additional Information
                      </p>
                      {((GRNview || isFeatureEnabled(FLAGS.TMS)) && (
                        <p className="my-5 break-words">
                          {formik.values.Order?.memo}
                        </p>
                      )) || (
                        <Grid container className="py-5" spacing={1}>
                          <Grid item xs={4} className="flex">
                            <p className="font-semibold color-borderColor">
                              Enter Comments
                            </p>
                          </Grid>
                          <Grid item xs={8}>
                            <FormControl fullWidth>
                              <TextField
                                value={formik.values.Order?.memo}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    'Order.memo',
                                    e.target.value
                                  )
                                }
                                placeholder="Type here"
                                minRows={3}
                                multiline
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}
                      <Grid
                        container
                        className={clsx(
                          'border-y',
                          'border-dashed',
                          'border-gray-400',
                          GRNview && 'border-b-0',
                          'border-x-0',
                          'py-5'
                        )}
                      >
                        <Grid
                          item
                          xs={GRNview ? 12 : 4}
                          className="flex flex-col gap-1"
                        >
                          <p className="font-semibold">
                            {GRNview || 'Upload '}Proof of Document
                          </p>
                          {(GRNview && <div></div>) || (
                            <p className="text-xs">
                              File must be pdf, jpeg & png
                            </p>
                          )}
                        </Grid>
                        {GRNview ? (
                          <Grid container spacing={3} className="mt-2">
                            {formik.values.images?.map((file, index) => (
                              <Grid xs={2} item key={index}>
                                <ImagePreview file={file} />
                              </Grid>
                            ))}
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={8}
                            className="flex items-center justify-between border border-dashed p-4 border-[#C4C6CB]"
                          >
                            {!formik.values.Order?.supportingDocuments
                              ?.length ? (
                              <div className="flex items-center">
                                <img
                                  src={CLOUD_UPLOAD_ICON}
                                  alt="Cloud Upload"
                                />
                                <p className="ml-2">
                                  Upload a picture or document
                                </p>
                              </div>
                            ) : (
                              <div className="flex items-end">
                                <div className="grid grid-cols-3 gap-1">
                                  {(showMoreImages
                                    ? formik.values.Order?.supportingDocuments
                                    : formik.values.Order?.supportingDocuments.slice(
                                        0,
                                        6
                                      )
                                  )?.map((doc, id) => (
                                    <div
                                      key={id}
                                      className="rounded-full w-[120px] justify-between text-xs pt-1 pb-1 pl-3 pr-3 border border-solid border-primary flex items-center gap-2 cursor-pointer"
                                    >
                                      <p className="text-primary">
                                        {formatFileName(
                                          (formik.values?.images || [])[id] ||
                                            doc
                                        )}
                                      </p>
                                      <div
                                        className="cursor-pointer"
                                        onClick={() => {
                                          if (
                                            formik.values.Order
                                              ?.supportingDocuments.length > 1
                                          ) {
                                            let updatedDocs = [
                                              // eslint-disable-next-line no-unsafe-optional-chaining
                                              ...formik.values.Order
                                                ?.supportingDocuments
                                            ];
                                            updatedDocs = updatedDocs.filter(
                                              (_, i) => i !== id
                                            );
                                            formik.setFieldValue(
                                              'Order.supportingDocuments',
                                              updatedDocs
                                            );
                                          } else {
                                            formik.setFieldValue(
                                              'Order.supportingDocuments',
                                              []
                                            );
                                          }
                                        }}
                                      >
                                        <img
                                          src={CANCEL_ICON}
                                          className="w-2 h-2"
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <div>
                                  {!showMoreImages &&
                                    formik.values.Order?.supportingDocuments
                                      .length > 6 && (
                                      <Button
                                        variant="text"
                                        size="small"
                                        icon="Show More"
                                        onClick={() => setShowMoreImages(true)}
                                        label={
                                          <img
                                            src={DOWN_ARROW_ICON}
                                            alt="Show More Icon"
                                          />
                                        }
                                        className="p-0 ml-2 h-[20px]"
                                      />
                                    )}
                                </div>
                              </div>
                            )}
                            <SupportingDocumentUpload
                              formik={formik}
                              propertyName="Order.supportingDocuments"
                            />
                          </Grid>
                        )}
                      </Grid>
                      {GRNview || (
                        <Accordion
                          sx={{ border: 1, outline: 'none', boxShadow: 'none' }}
                          className="bg-tertiary p-5 rounded-xl border-sky-200 mt-5 mb-16"
                          expandIcon={<ExpandMoreIcon />}
                          expanded={expanded}
                          onChange={changeExpanded}
                        >
                          <AccordionSummary className="w-full">
                            <div className="flex justify-between items-center w-full px-4">
                              <div className="flex">
                                <img src={TRUCK_ICON} alt="Truck Icon" />
                                <p className="font-semibold text-lg ml-2">
                                  Shipment Detail
                                </p>
                              </div>
                              {expanded ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </div>
                          </AccordionSummary>
                          <AccordionDetails className="mb-10">
                            <Grid
                              container
                              className="border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 pt-5 pb-5"
                              spacing={1}
                            >
                              <Grid item xs={4} className="flex items-center">
                                {isDockAssignmentEnabled ? (
                                  <p className="font-semibold">
                                    Enter Dock & Carrier
                                  </p>
                                ) : (
                                  <p className="font-semibold">Enter Carrier</p>
                                )}
                              </Grid>
                              {isDockAssignmentEnabled && (
                                <Grid item xs={4}>
                                  <Autocomplete
                                    className="w-full"
                                    value={
                                      formik.values.shipment?.dropOffDock ||
                                      formik.values.shipment?.WarehouseDock
                                    }
                                    options={warehouseDocks}
                                    getOptionLabel={(dock) => dock.name || ''}
                                    onChange={(e, val) => {
                                      handleDock(val);
                                      getWarehouseDocks(val);
                                    }}
                                    getOptionSelected={(option, value) =>
                                      option.value === value.value
                                    }
                                    onKeyUp={(e) =>
                                      getWarehouseDocks(e.target.value)
                                    }
                                    onFocus={() => {
                                      setDockValue('');
                                    }}
                                    onBlur={formik?.handleBlur}
                                    disabled={
                                      !isFeatureEnabled(
                                        FLAGS.DOCK_CREATION_AND_ASSIGNMENT
                                      )
                                    }
                                  />
                                </Grid>
                              )}
                              <Grid item xs={isDockAssignmentEnabled ? 4 : 8}>
                                {isFeatureEnabled(FLAGS.TMS) ||
                                (!isFeatureEnabled(FLAGS.TMS) &&
                                  isFeatureEnabled(
                                    FLAGS.ADVANCED_SHIPMENT_CREATION
                                  )) ? (
                                  <Autocomplete
                                    className="w-[98%]"
                                    value={
                                      isFeatureEnabled(FLAGS.TMS)
                                        ? formik.values.shipment
                                            ?.ShipmentLegs?.[0]?.Company
                                        : formik.values.shipment?.Carrier ||
                                          selectedCarrier
                                    }
                                    options={[
                                      ...carriers,
                                      {
                                        id: '',
                                        name: 'addCarrier'
                                      }
                                    ]}
                                    disabled={isFeatureEnabled(FLAGS.TMS)}
                                    renderOption={(props, option) => {
                                      // eslint-disable-next-line no-unused-vars
                                      const { className, ...rest } = props;
                                      return option?.name === 'addCarrier' ? (
                                        <li
                                          className="auto-complete-option"
                                          style={{
                                            color: PRIMARY,
                                            fontSize: '16px',
                                            fontWeight: 500
                                          }}
                                          onClick={() => {
                                            setAddCompanyViewOpen(true);
                                          }}
                                        >
                                          + {'Add New Carrier'}
                                        </li>
                                      ) : (
                                        <li
                                          {...rest}
                                          className="auto-complete-option"
                                        >
                                          <span>
                                            {option && option.name
                                              ? `${option?.name}`
                                              : ''}
                                          </span>
                                        </li>
                                      );
                                    }}
                                    getOptionLabel={(carrier) => {
                                      return carrier && carrier?.name
                                        ? `${carrier?.name}`
                                        : '';
                                    }}
                                    onInputChange={(event, newValue) => {
                                      setCarrierSearch(newValue);
                                    }}
                                    onChange={(e, val) => {
                                      formik.setFieldValue(
                                        'shipment.carrier',
                                        val !== null && val
                                      );
                                      formik.setFieldValue(
                                        'shipment.carrierId',
                                        val && val.id ? val.id : ''
                                      );
                                      setSelectedCarrier(val);

                                      setSelectedDriver({});
                                      setSelectedCar({});
                                      setSelectedVehicle({});
                                      formik.setFieldValue(
                                        'shipment.Driver',
                                        {}
                                      );
                                      formik.setFieldValue(
                                        'shipment.driverPhone',
                                        ''
                                      );
                                      formik.setFieldValue(
                                        'shipment.vehicleType',
                                        {}
                                      );
                                      formik.setFieldValue(
                                        'shipment.Vehicle',
                                        {}
                                      );
                                    }}
                                    onBlur={formik?.handleBlur}
                                    placeholder="Carrier"
                                  />
                                ) : (
                                  <TextField
                                    value={formik.values?.shipment?.carrierName}
                                    onChange={(e) =>
                                      formik.setFieldValue(
                                        'shipment.carrierName',
                                        e.target.value
                                      )
                                    }
                                    placeholder="Carrier Name"
                                    size="small"
                                    className="w-full"
                                  />
                                )}
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className="border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 pt-5 pb-5"
                              spacing={1}
                            >
                              <Grid item xs={4} className="flex items-center">
                                <p className="font-semibold">
                                  Enter Vehicle information
                                </p>
                              </Grid>
                              <Grid item xs={4}>
                                {isFeatureEnabled(FLAGS.TMS) ||
                                (!isFeatureEnabled(FLAGS.TMS) &&
                                  isFeatureEnabled(
                                    FLAGS.ADVANCED_SHIPMENT_CREATION
                                  )) ? (
                                  <Autocomplete
                                    className="w-full"
                                    value={
                                      isFeatureEnabled(FLAGS.TMS)
                                        ? formik.values.shipment
                                            ?.ShipmentLegs?.[0]?.Car
                                        : formik?.values?.shipment?.vehicleType
                                    }
                                    disabled={isFeatureEnabled(FLAGS.TMS)}
                                    placeholder="Vehicle Type"
                                    options={[
                                      ...cars,
                                      {
                                        id: '',
                                        name: 'addCar'
                                      }
                                    ]}
                                    renderOption={(props, option) => {
                                      // eslint-disable-next-line no-unused-vars
                                      const { className, ...rest } = props;
                                      return option?.name === 'addCar' ? (
                                        <li
                                          className="auto-complete-option"
                                          style={{
                                            color: PRIMARY,
                                            fontSize: '16px',
                                            fontWeight: 500
                                          }}
                                          onClick={() => {
                                            setAddVehicleTypeViewOpen(true);
                                          }}
                                        >
                                          + {'Add New Vehicle Type'}
                                        </li>
                                      ) : (
                                        <li
                                          {...rest}
                                          className="auto-complete-option"
                                        >
                                          <span>
                                            {option &&
                                            option.CarMake &&
                                            option.CarModel
                                              ? option?.name
                                                ? option.name
                                                : `${option?.CarMake?.name}-${option?.CarModel?.name} ( ${option?.Category?.name} )`
                                              : ''}
                                          </span>
                                        </li>
                                      );
                                    }}
                                    getOptionLabel={(car) => {
                                      return car && car.CarMake && car.CarModel
                                        ? car?.name
                                          ? car.name
                                          : `${car?.CarMake?.name}-${car?.CarModel?.name} ( ${car?.Category?.name} )`
                                        : '';
                                    }}
                                    getOptionSelected={(option, value) =>
                                      option.value === value.value
                                    }
                                    onBlur={formik.handleBlur}
                                    onChange={(e, val) => {
                                      formik.setFieldValue(
                                        'shipment.vehicleType',
                                        val
                                      );
                                      formik.setFieldValue(
                                        'shipment.vehicleTypeId',
                                        val?.id
                                      );
                                      setSelectedCar(val);
                                    }}
                                    onKeyUp={(event) => {
                                      if (event.target.value) {
                                        setVehicleTypeSearch(
                                          event.target.value
                                        );
                                      }
                                    }}
                                  />
                                ) : (
                                  <TextField
                                    value={
                                      formik.values?.shipment?.vehicleTypeName
                                    }
                                    onChange={(e) =>
                                      formik.setFieldValue(
                                        'shipment.vehicleTypeName',
                                        e.target.value
                                      )
                                    }
                                    placeholder="Vehicle Type"
                                    size="small"
                                    className="w-full"
                                  />
                                )}
                              </Grid>
                              <Grid item xs={4} className="flex items-center">
                                {isFeatureEnabled(FLAGS.TMS) ? (
                                  <Autocomplete
                                    className="w-full"
                                    value={
                                      isFeatureEnabled(FLAGS.TMS)
                                        ? formik.values.shipment
                                            ?.ShipmentLegs?.[0]?.Vehicle
                                        : formik?.values?.shipment?.Vehicle ||
                                          selectedVehicle
                                    }
                                    disabled={isFeatureEnabled(FLAGS.TMS)}
                                    options={[
                                      ...vehicles,
                                      {
                                        id: '',
                                        name: 'addVehicle'
                                      }
                                    ]}
                                    renderOption={(props, option) => {
                                      // eslint-disable-next-line no-unused-vars
                                      const { className, ...rest } = props;
                                      return option?.name === 'addVehicle' ? (
                                        <li
                                          className="auto-complete-option"
                                          style={{
                                            color: PRIMARY,
                                            fontSize: '16px',
                                            fontWeight: 500
                                          }}
                                          onClick={() => {
                                            setAddVehicleView(true);
                                          }}
                                        >
                                          + {'Add New Vehicle'}
                                        </li>
                                      ) : (
                                        <li
                                          {...rest}
                                          className="auto-complete-option"
                                        >
                                          <span>
                                            {option && option.registrationNumber
                                              ? `${option?.registrationNumber}`
                                              : ''}
                                          </span>
                                        </li>
                                      );
                                    }}
                                    getOptionLabel={(vehicle) => {
                                      return vehicle &&
                                        vehicle.registrationNumber
                                        ? `${vehicle?.registrationNumber}`
                                        : '';
                                    }}
                                    onBlur={formik.handleBlur}
                                    onChange={(e, val) => {
                                      formik.setFieldValue(
                                        'shipment.vehicleId',
                                        val !== null && val ? val.id : ''
                                      );
                                      formik.setFieldValue(
                                        'shipment.Vehicle',
                                        val !== null && val ? val : {}
                                      );
                                      setSelectedVehicle(val);
                                    }}
                                    inputValue={vehicleSearch}
                                    onInputChange={(event, newValue) => {
                                      setVehicleSearch(newValue);
                                    }}
                                    placeholder="Vehicle"
                                  />
                                ) : (
                                  <TextField
                                    value={
                                      formik.values?.shipment?.vehicleNumber
                                    }
                                    onChange={(e) =>
                                      formik.setFieldValue(
                                        'shipment.vehicleNumber',
                                        e.target.value
                                      )
                                    }
                                    placeholder="Vehicle"
                                    size="small"
                                    className="w-full"
                                  />
                                )}
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              className="border-b border-dashed border-gray-400 border-t-0 border-r-0 border-l-0 pt-5 pb-5"
                              spacing={1}
                            >
                              <Grid item xs={4} className="flex items-center">
                                <p className="font-semibold">
                                  Enter Driver information
                                </p>
                              </Grid>
                              <Grid item xs={4} className="flex items-center">
                                {isFeatureEnabled(FLAGS.TMS) ? (
                                  <Autocomplete
                                    className="w-full"
                                    value={
                                      isFeatureEnabled(FLAGS.TMS)
                                        ? formik.values.shipment
                                            ?.ShipmentLegs?.[0]?.Driver
                                        : formik?.values.shipment?.Driver ||
                                          selectedDriver
                                    }
                                    disabled={isFeatureEnabled(FLAGS.TMS)}
                                    options={[
                                      ...drivers,
                                      {
                                        id: '',
                                        name: 'addDriver'
                                      }
                                    ]}
                                    renderOption={(props, option) => {
                                      // eslint-disable-next-line no-unused-vars
                                      const { className, ...rest } = props;
                                      return option?.name === 'addDriver' ? (
                                        <li
                                          className="auto-complete-option"
                                          style={{
                                            color: PRIMARY,
                                            fontSize: '16px',
                                            fontWeight: 500
                                          }}
                                          onClick={() => {
                                            setAddDriverView(true);
                                          }}
                                        >
                                          + {'Add New Driver'}
                                        </li>
                                      ) : (
                                        <li
                                          {...rest}
                                          className="auto-complete-option"
                                        >
                                          <span>
                                            {option && option.name
                                              ? `${option?.name}`
                                              : ''}
                                          </span>
                                        </li>
                                      );
                                    }}
                                    getOptionLabel={(driver) => {
                                      return driver && driver?.name
                                        ? `${driver?.name}`
                                        : '';
                                    }}
                                    onInputChange={(event, newValue) => {
                                      setDriverSearch(newValue);
                                    }}
                                    onBlur={formik.handleBlur}
                                    onChange={(e, val) => {
                                      formik.setFieldValue(
                                        'shipment.Driver',
                                        val !== null && val
                                      );
                                      formik.setFieldValue(
                                        'shipment.driverId',
                                        val && val.id ? val.id : ''
                                      );
                                      formik.setFieldValue(
                                        'shipment.driverPhone',
                                        val && val.phone ? val.phone : ''
                                      );
                                      setSelectedDriver(val);
                                    }}
                                    placeholder="Driver"
                                  />
                                ) : (
                                  <TextField
                                    value={formik.values?.shipment?.driverName}
                                    onChange={(e) =>
                                      formik.setFieldValue(
                                        'shipment.driverName',
                                        e.target.value
                                      )
                                    }
                                    placeholder="Driver Name"
                                    size="small"
                                    className="w-full"
                                  />
                                )}
                              </Grid>
                              <Grid item xs={4} className="flex items-center">
                                <PhoneInput
                                  country="pk"
                                  value={
                                    (isFeatureEnabled(FLAGS.TMS)
                                      ? formik.values.shipment
                                          ?.ShipmentLegs?.[0]?.Driver?.phone
                                      : formik.values.shipment?.driverPhone) ??
                                    ''
                                  }
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'shipment.driverPhone',
                                      e
                                    );
                                  }}
                                  placeholder="Driver Number"
                                  inputStyle={{
                                    height: '40px',
                                    background: TERTIARY
                                  }}
                                  specialLabel=""
                                  disabled={isFeatureEnabled(FLAGS.TMS)}
                                />
                              </Grid>
                            </Grid>

                            <Grid container className="pt-5 pb-5" spacing={1}>
                              <Grid item xs={4} className="flex items-center">
                                <p className="font-semibold">
                                  Enter Weight & Seal Number
                                </p>
                              </Grid>
                              <Grid item xs={4} className="flex items-center">
                                <TextField
                                  type="number"
                                  value={
                                    isFeatureEnabled(FLAGS.TMS)
                                      ? formik.values.shipment
                                          ?.ShipmentLegs?.[0]?.weight
                                      : formik.values.shipment?.weight
                                  }
                                  disabled={isFeatureEnabled(FLAGS.TMS)}
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      'shipment.weight',
                                      e.target.value
                                    )
                                  }
                                  placeholder="Weight"
                                  size="small"
                                  className="w-full"
                                />
                              </Grid>
                              <Grid item xs={4} className="flex items-center">
                                <TextField
                                  value={formik.values.shipment?.sealNumber}
                                  disabled={isFeatureEnabled(FLAGS.TMS)}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'shipment.sealNumber',
                                      e.target.value
                                    );
                                  }}
                                  placeholder="Seal Number"
                                  size="small"
                                  type="text"
                                  className="w-full"
                                />
                              </Grid>
                            </Grid>
                            {isFeatureEnabled(
                              FLAGS.BATCH_AND_EXPIRY_TRACKING
                            ) && (
                              <Grid
                                container
                                className="border-t border-dashed border-gray-400 border-b-0 border-r-0 border-l-0 pt-5 pb-5"
                              >
                                <Grid item xs={4} className="flex items-center">
                                  <p className="font-semibold">
                                    Pallet requirement
                                  </p>
                                </Grid>
                                <Grid item xs={8} className="flex items-center">
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      value={
                                        formik.values.shipment?.palletize ===
                                        'Palletized'
                                          ? 'Palletized'
                                          : 'Non-Palletized'
                                      }
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          'shipment.palletize',
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <FormControlLabel
                                        value="Palletized"
                                        sx={{ mr: '1rem', paddingLeft: '8px' }}
                                        control={<Radio size="small" />}
                                        label="Palletized"
                                      />
                                      <FormControlLabel
                                        value="Non-Palletized"
                                        sx={{ ml: '1rem', paddingLeft: '8px' }}
                                        control={<Radio size="small" />}
                                        label="Non-Palletized"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid xs={3}>
                  <ProgressDetails
                    total={totalOrderedQuantity}
                    occupied={totalReceivedQuantity}
                    dockName={
                      (
                        formik.values.shipment?.dropOffDock ||
                        formik.values.shipment?.WarehouseDock
                      )?.name
                    }
                    products={formik?.values?.Order?.products}
                    formik={formik}
                    view={GRNview}
                    type="GRN"
                  />
                </Grid>
              </Grid>
            }
            footer={
              reversed ||
              GRNview ||
              (formik?.values?.Order?.products && (
                <div className="flex justify-between w-full">
                  <Button
                    label="Cancel"
                    variant="tertiary"
                    onClick={() => navigate(-1)}
                  />

                  <div className="flex justify-between mr-4 gap-4">
                    <>
                      <Button
                        label="Save Draft"
                        variant="secondary"
                        onClick={() => {
                          setOpenDraftPopup(true);
                        }}
                      />
                      <Button
                        label="Generate GRN"
                        variant="primary"
                        disabled={isDisabledButton || disableGRN}
                        onClick={() => {
                          setOpenCreatePopup(true);
                        }}
                      />
                    </>
                  </div>

                  <Popup
                    title={`Generate Draft GRN for this Order?`}
                    open={openDraftPopup}
                    setOpen={setOpenDraftPopup}
                    content={
                      <Summary
                        formik={formik}
                        total={totalOrderedQuantity}
                        occupied={totalReceivedQuantity}
                        submitHandler={submitHandler}
                        setOpen={setOpenDraftPopup}
                        type="GRN"
                        draft={true}
                        saving={savingGRN}
                      />
                    }
                  />
                  <Popup
                    title={`Receive This Order?`}
                    open={openCreatePopup}
                    setOpen={setOpenCreatePopup}
                    content={
                      <Summary
                        formik={formik}
                        total={totalOrderedQuantity}
                        occupied={totalReceivedQuantity}
                        setOpen={setOpenCreatePopup}
                        submitHandler={submitHandler}
                        type="GRN"
                        saving={savingGRN}
                      />
                    }
                  />
                </div>
              ))
            }
          />
        </Modal>
      )}
    </>
  );
};

export default AddGrnView;
