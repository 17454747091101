import { Collapse, Table, TableBody, TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import { useContext, useState } from 'react';

import ShipmentIcon from '../../../../assets/icons/shipment.svg';
import CancelledIcon from '../../../../assets/icons/cancelled.svg';
import EditIcon from '../../../../assets/icons/edit-row.svg';
import LinearProgressBar from '../../../../core-components/atoms/LinerProgressBar';
import { CRIMSON_500, JADE_700, PRIMARY } from '../../../../constants/colors';
import OptionsMenu from '../../Options';
import AddShipmentTMS from '../../AddShipmentTMS';
import API from '../../../../libs/axios';
import Button from '../../../../core-components/atoms/Button';
import truckIcon from '../../../../assets/icons/TruckIcon.svg';
import {
  SharedContext,
  decimalDisplay,
  getCurrencySymbol
} from '../../../../utils/common';
import ShipmentDetail from '../../ShipmentDetail';
import EyeIcon from '../../../../assets/icons/eye.svg';
import ShipIcon from '../../../../assets/icons/ShipIcon.svg';
import AirplaneIcon from '../../../../assets/icons/AirplaneIcon.svg';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import FLAGS from '../../../../constants/featureFlags';
import PodPopup from '../PodPopup';
import ViewPodPopup from '../ViewPodPopup';

export const LogisticTMSShipmentTracking = ({
  order,
  expanded,
  setActiveShipment,
  setActiveShipmentOrder,
  shipmentIdFilter,
  activeShipment,
  refetch,
  setCancelPopUp
}) => {
  const [open, setOpen] = useState(false);
  const [shipmentDetailView, setShipmentDetailView] = useState(false);
  const [action, setAction] = useState('edit');

  return (
    <TableRow>
      <TableCell colSpan={8} sx={{ paddingLeft: '16px', paddingRight: '32px' }}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <div>
            <Table class="border-separate border-spacing-y-4 border-spacing-x-0 w-full">
              <TableBody className="w-full">
                {order.Shipments?.length > 0 &&
                  order.Shipments.map((shipment, id) => {
                    return (
                      <LogisticShipmentRow
                        key={id}
                        setActiveShipment={setActiveShipment}
                        setActiveShipmentOrder={setActiveShipmentOrder}
                        shipment={shipment}
                        order={order}
                        setCancelPopUp={setCancelPopUp}
                        setShipmentDetailView={setShipmentDetailView}
                        expanded={expanded}
                        shipmentIdFilter={shipmentIdFilter}
                        setAction={setAction}
                        refetch={refetch}
                        id={id}
                        setOpen={setOpen}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </Collapse>
      </TableCell>

      {shipmentDetailView && (
        <ShipmentDetail
          open={setShipmentDetailView}
          order={order}
          selectedShipmentMode={activeShipment?.ShipmentLegs?.[0]?.mode}
          setOpen={setShipmentDetailView}
          shipmentId={activeShipment?.customId}
          onClose={async () => await refetch()}
        />
      )}

      {open && activeShipment && (
        <AddShipmentTMS
          shipment={activeShipment}
          open={open}
          setOpen={setOpen}
          selectedOrder={order}
          edit={true}
          refetch={refetch}
          action={action}
          onClose={() => setAction('edit')}
          editable={
            !(
              (activeShipment?.status?.key == 'IN_TRANSIT' &&
                activeShipment.subStatus?.key == 'ARRIVED_AT_DESTINATION') ||
              (activeShipment?.status?.key == 'IN_TRANSIT' &&
                activeShipment?.subStatus?.key == 'UNLOADING_COMPLETE') ||
              activeShipment?.status?.key == 'COMPLETED' ||
              activeShipment?.status?.key == 'CANCELLED'
            )
          }
        />
      )}
    </TableRow>
  );
};

export const LogisticShipmentRow = ({
  shipment,
  setActiveShipment,
  setActiveShipmentOrder,
  setCancelPopUp,
  setShipmentDetailView,
  order,
  expanded,
  shipmentIdFilter,
  id,
  setAction,
  refetch,
  setOpen
}) => {
  const [podOpen, setPodOpen] = useState(false);
  const [podDownloadOpen, setPodDownloadOpen] = useState(false);
  const { organization } = useContext(SharedContext);
  const { isFeatureEnabled } = useFeatureFlags();

  let cases = {
    AWAITING_CARRIER:
      shipment.status.key == 'CREATED' &&
      shipment?.ShipmentLegs?.[0]?.status == 'AWAITING_CARRIER',
    CARRIER_ASSIGNED:
      shipment.status.key == 'CREATED' &&
      shipment?.ShipmentLegs?.[0]?.status == 'CARRIER_ASSIGNED' &&
      shipment.subStatus?.key !== 'LOADING_IN_PROGRESS',
    LOADING_IN_PROGRESS:
      shipment.status.key == 'CREATED' &&
      shipment?.subStatus?.key == 'LOADING_IN_PROGRESS',
    OUT_FOR_DELIVERY:
      shipment?.status?.key == 'IN_TRANSIT' && !shipment?.subStatus?.key,
    ARRIVED_AT_DESTINATION: shipment?.status?.key == 'ARRIVED_AT_DESTINATION',
    UNLOADING_COMPLETE:
      shipment.status.key == 'ARRIVED_AT_DESTINATION' &&
      shipment?.subStatus?.key == 'UNLOADING_COMPLETE',
    COMPLETED: shipment.status.key == 'COMPLETED',
    CANCELLED: shipment.status.key == 'CANCELLED'
  };

  const expectedDeliveryDate = moment(shipment.dropoffDateTime);
  const currentDate = moment();
  const timeDifference = expectedDeliveryDate.diff(currentDate);
  const daysDifference =
    moment.duration(timeDifference).asDays() < 0
      ? '0'
      : moment.duration(timeDifference).asDays().toFixed(0);

  const className = `mt-4 mb-4 pt-2 pb-2 w-full border
      ${
        !cases.COMPLETED &&
        !cases.CANCELLED &&
        !cases.ARRIVED_AT_DESTINATION &&
        !cases.UNLOADING_COMPLETE
          ? 'bg-bgCreated'
          : cases.COMPLETED ||
            cases.ARRIVED_AT_DESTINATION ||
            cases.UNLOADING_COMPLETE
          ? 'bg-jade100'
          : 'bg-bgCancelled'
      }`;
  const options = [];

  if (!cases.CANCELLED)
    options.push({
      label: (
        <div className="flex gap-3">
          <img src={EditIcon} className="w-4" />
          <span>Edit Shipment</span>
        </div>
      ),
      onClick: () => {
        setActiveShipment(shipment);
        setActiveShipmentOrder(order);
        setOpen(true);
      }
    });
  if (
    !cases.CANCELLED &&
    !cases.COMPLETED &&
    !cases.ARRIVED_AT_DESTINATION &&
    !cases.UNLOADING_COMPLETE &&
    isFeatureEnabled(FLAGS.SHIPMENT_CANCELLATION)
  ) {
    options.push(
      ...[
        {
          label: (
            <div className="flex gap-3">
              <img src={CancelledIcon} className="w-4" />
              <span>Cancel Shipment</span>
            </div>
          ),
          onClick: () => {
            setActiveShipment(shipment);
            setActiveShipmentOrder(order);
            setCancelPopUp(true, 'shipment');
          },
          icon: CancelledIcon
        }
      ]
    );
  }

  options.push({
    label: (
      <div className="flex gap-3">
        <img src={EyeIcon} className="w-4" />
        <span>View Shipment Detail</span>
      </div>
    ),
    onClick: () => {
      setShipmentDetailView(true);
      setActiveShipment(shipment);
      setActiveShipmentOrder(order);
    }
  });

  const bgStyle = cases.OUT_FOR_DELIVERY
    ? 'bg-[#000] text-[#fff]'
    : cases.ARRIVED_AT_DESTINATION || cases.UNLOADING_COMPLETE
    ? 'border-jade700 text-jade700'
    : cases.COMPLETED
    ? 'bg-jade700 border-jade700'
    : cases.CANCELLED
    ? 'bg-[#870000] border-none'
    : 'border-[#000]';
  const textStyle = cases.OUT_FOR_DELIVERY
    ? 'text-[#fff]'
    : cases.ARRIVED_AT_DESTINATION || cases.UNLOADING_COMPLETE
    ? 'text-jade700 font-medium'
    : cases.COMPLETED
    ? 'text-[#fff] font-medium'
    : cases.CANCELLED
    ? 'text-[#fff] font-medium'
    : '';

  const mode = shipment?.ShipmentLegs?.[0]?.mode;

  const shipmentCost = shipment?.Expenses?.reduce((acc, v) => acc + v.cost, 0);

  return (
    <TableRow
      sx={expanded && { '& > *': { borderBottom: 'unset' } }}
      className={className}
      key={id}
    >
      <TableCell sx={{ width: '30%' }} className="border-none">
        <div className="flex gap-[8px]">
          <p className="font-semibold text-sm flex items-center gap-2">
            {/* <img src={ShipmentIcon} /> */}
            <span
              className={`${
                shipmentIdFilter &&
                shipment.id == shipmentIdFilter.id &&
                'bg-highlight'
              }`}
            >
              {shipment.customId}
            </span>
          </p>
          <div className="flex gap-4">
            <div
              className={`flex items-center justify-center py-1 px-[6px] border rounded-[5px] ${bgStyle}`}
            >
              <span className={`text-[10px] ${textStyle} font-medium`}>
                {cases.AWAITING_CARRIER ||
                cases.CARRIER_ASSIGNED ||
                cases.LOADING_IN_PROGRESS
                  ? 'Ready for pickup'
                  : cases.OUT_FOR_DELIVERY
                  ? 'In Transit'
                  : cases.ARRIVED_AT_DESTINATION || cases.UNLOADING_COMPLETE
                  ? 'Arrived at destination'
                  : cases.COMPLETED
                  ? 'Delivered'
                  : 'Cancelled'}
              </span>
            </div>
            {shipment?.shipmentTracking?.currentEvent && (
              <div
                className={`flex items-center justify-center py-1 px-[6px] border rounded-[5px] ${bgStyle}`}
              >
                <span className={`text-[10px] ${textStyle} font-medium`}>
                  {shipment?.shipmentTracking?.currentEvent}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="mt-1">
          <p className="text-primaryBlue text-xs">
            {cases.CANCELLED
              ? `CANCELLED :   ${moment(shipment.updatedAt).format(
                  'DD/MM/YYYY'
                )}`
              : cases.COMPLETED
              ? `Delivered in: ${moment
                  .duration(
                    moment(shipment.completedAt).diff(shipment.createdAt)
                  )
                  .asDays()
                  .toFixed(0)} days`
              : `EST: ${
                  daysDifference && daysDifference != 'NaN'
                    ? `${daysDifference} days`
                    : '-'
                }`}
          </p>
          <p className="text-primaryBlue text-xs mt-4">
            COST: {`${getCurrencySymbol(organization.defaultCurrency)}`}{' '}
            {decimalDisplay(shipmentCost)}
          </p>
        </div>
      </TableCell>
      <TableCell
        sx={{
          width: '40%',
          paddingLeft: 0,
          paddingRight: 0,
          textAlign: 'left'
        }}
        className="border-none"
      >
        <div className="w-full">
          {cases.AWAITING_CARRIER ? (
            <div className="flex gap-2 justify-center items-center  mb-2">
              <img src={truckIcon} />
              <span className="text-xs">Awaiting Carrier Details</span>
            </div>
          ) : cases.CARRIER_ASSIGNED ? (
            <div className="flex gap-2 justify-center items-center  mb-2">
              <img src={truckIcon} />
              <span className="text-xs">Carrier Assigned</span>
            </div>
          ) : cases.LOADING_IN_PROGRESS ? (
            <div className="flex gap-2 justify-center items-center  mb-2">
              <img src={truckIcon} />
              <span className="text-xs">Loading</span>
            </div>
          ) : cases.OUT_FOR_DELIVERY ? (
            <div className="flex gap-2 justify-center items-center  mb-2">
              <img src={truckIcon} />
              <span className="text-xs">Out For Delivery</span>
            </div>
          ) : cases.UNLOADING_COMPLETE ? (
            <div className="flex gap-2 justify-center items-center  mb-2">
              <img
                src={
                  mode == 'LAND'
                    ? ShipmentIcon
                    : mode == 'SEA'
                    ? ShipIcon
                    : AirplaneIcon
                }
              />
              <span className="text-xs">Unloaded</span>
            </div>
          ) : cases.ARRIVED_AT_DESTINATION ? (
            <div className="flex gap-2 justify-center items-center  mb-2">
              <img
                src={
                  mode == 'LAND'
                    ? ShipmentIcon
                    : mode == 'SEA'
                    ? ShipIcon
                    : AirplaneIcon
                }
              />
              <span className="text-xs">Reached</span>
            </div>
          ) : cases.CANCELLED ? (
            <div className="flex justify-center mb-2 gap-2">
              <img
                src={
                  mode == 'LAND'
                    ? ShipmentIcon
                    : mode == 'SEA'
                    ? ShipIcon
                    : AirplaneIcon
                }
              />
              <span className="text-xs">
                Cancelled By{' '}
                {shipment.cancelledBy?.firstName +
                  ' ' +
                  shipment.cancelledBy?.lastName}
              </span>
            </div>
          ) : (
            <div className="flex gap-2 items-center justify-center mb-2">
              <span className="text-xs">Completed - </span>
              <span className="text-xs">
                {moment(shipment.updatedAt).format('DD/MM/YYYY')}
              </span>
              <span className="text-xs">
                {moment(shipment.updatedAt).format('hh:mm A')}
              </span>
            </div>
          )}
          <div className="flex items-center w-full">
            <div
              className={`rounded-full h-[6px] w-[6px] ${
                cases.COMPLETED ||
                cases.ARRIVED_AT_DESTINATION ||
                cases.UNLOADING_COMPLETE
                  ? 'bg-jade700'
                  : cases.CANCELLED
                  ? 'bg-crimson500'
                  : 'bg-primary'
              }`}
            ></div>
            <LinearProgressBar
              variant="determinate"
              value={100}
              sx={{
                backgroundColor: 'white',
                '& .MuiLinearProgress-bar': {
                  backgroundColor:
                    cases.COMPLETED ||
                    cases.ARRIVED_AT_DESTINATION ||
                    cases.UNLOADING_COMPLETE
                      ? JADE_700
                      : cases.CANCELLED
                      ? CRIMSON_500
                      : PRIMARY
                },
                border: `1px ${
                  cases.COMPLETED ||
                  cases.ARRIVED_AT_DESTINATION ||
                  cases.UNLOADING_COMPLETE
                    ? JADE_700
                    : shipment.status.key == 'CANCELLED'
                    ? CRIMSON_500
                    : PRIMARY
                } solid`,
                borderRadius: '1000px',
                height: '2px',
                width: '100%'
              }}
            />
            <div
              className={`rounded-full h-[6px] w-[6px] ${
                cases.COMPLETED ||
                cases.ARRIVED_AT_DESTINATION ||
                cases.UNLOADING_COMPLETE
                  ? 'bg-jade700'
                  : cases.CANCELLED
                  ? 'bg-crimson500'
                  : 'bg-primary'
              }`}
            ></div>
          </div>
          <div className="mt-2 text-center">
            <p className="flex gap-2 justify-center">
              <span className="text-sm">{order.pickupNode?.name || '-'}</span>
              {order.dropOffNode?.name && (
                <span className="text-[24px] leading-[50%]">&#8594;</span>
              )}
              <span className="text-sm">{order.dropOffNode?.name}</span>
            </p>
          </div>
        </div>
      </TableCell>
      <TableCell className="border-none" sx={{ width: '30%', border: 'none' }}>
        <div className="flex justify-end gap-2 items-center">
          <ActionBtns
            order={order}
            shipment={shipment}
            refetch={refetch}
            onAssignCarrier={() => {
              setActiveShipment(shipment);
              setActiveShipmentOrder(order);
              setOpen(true);
              setAction('assign');
            }}
            setPodOpen={setPodOpen}
            setPodDownloadOpen={setPodDownloadOpen}
          />
          <OptionsMenu options={options} />
        </div>
      </TableCell>

      <PodPopup
        setOpen={setPodOpen}
        open={podOpen}
        shipmentId={shipment.id}
        orderId={order.id}
        refetch={refetch}
      />
      <ViewPodPopup
        setOpen={setPodDownloadOpen}
        open={podDownloadOpen}
        shipmentId={shipment.id}
        orderId={order.id}
      />
    </TableRow>
  );
};

export const ActionBtns = ({
  shipment,
  order,
  refetch,
  onAssignCarrier,
  setPodOpen,
  setPodDownloadOpen
}) => {
  const { setAPILoader, currentUser } = useContext(SharedContext);
  const inProgressShipments =
    order.Shipments?.filter(
      (s) =>
        s.id !== shipment.id &&
        s.status?.key !== 'COMPLETED' &&
        s.status?.key !== 'CANCELLED'
    ) || [];
  if (
    shipment?.status?.key == 'CREATED' &&
    shipment?.ShipmentLegs?.[0]?.status == 'AWAITING_CARRIER'
  ) {
    return (
      <div>
        <Button
          label={
            <span className="text-[12px] font-medium">Assign Carrier</span>
          }
          variant="transparent"
          size="large"
          onClick={onAssignCarrier}
          className="h-9"
        />
      </div>
    );
  }
  if (
    shipment?.status?.key == 'CREATED' &&
    shipment?.ShipmentLegs?.[0]?.status == 'CARRIER_ASSIGNED' &&
    shipment?.subStatus?.key !== 'LOADING_IN_PROGRESS'
  ) {
    return (
      <div>
        <Button
          label={<span className="text-[12px] font-medium">Start Loading</span>}
          variant="transparent"
          size="large"
          onClick={async () => {
            try {
              setAPILoader(true);
              await API.put(
                `orders/${order.customId}/shipments/${shipment.customId}`,
                {
                  shipmentPayload: {
                    status: 'LOADING_IN_PROGRESS'
                  }
                }
              );
              await refetch();
              setAPILoader(false);
            } catch (e) {
              console.log(e);
            }
          }}
          className="h-9"
        />
      </div>
    );
  }

  if (
    shipment?.status?.key == 'CREATED' &&
    shipment?.subStatus?.key == 'LOADING_IN_PROGRESS'
  ) {
    return (
      <div>
        <Button
          label={
            <span className="text-[12px] font-medium">Mark as Picked</span>
          }
          variant="transparent"
          size="large"
          onClick={async () => {
            try {
              setAPILoader(true);
              await API.put(
                `orders/${order.customId}/shipments/${shipment.customId}`,
                {
                  shipmentPayload: {
                    status: 'IN_TRANSIT'
                  }
                }
              );
              await API.put(
                `orders/${order.customId}/shipments/${shipment.customId}`,
                {
                  shipmentPayload: {
                    subStatusId: null
                  }
                }
              );
              await refetch();
              setAPILoader(false);
            } catch (e) {
              console.log(e);
            }
          }}
          className="h-9"
        />
      </div>
    );
  }

  if (shipment?.status?.key == 'IN_TRANSIT' && !shipment?.subStatus?.key) {
    return (
      <div>
        <Button
          label={
            <span className="text-[12px] font-medium">Mark as Reached</span>
          }
          variant="transparent"
          size="large"
          onClick={async () => {
            try {
              setAPILoader(true);
              await API.put(
                `orders/${order.customId}/shipments/${shipment.customId}`,
                {
                  shipmentPayload: {
                    status: 'ARRIVED_AT_DESTINATION',
                    subStatus: null
                  }
                }
              );
              await refetch();
              setAPILoader(false);
            } catch (e) {
              console.log(e);
            }
          }}
          className="h-9"
        />
      </div>
    );
  }

  if (
    shipment?.status?.key == 'ARRIVED_AT_DESTINATION' &&
    !shipment?.subStatus?.key
  ) {
    return (
      <div>
        <Button
          label={
            <span className="text-[12px] font-medium">Mark as Unloaded</span>
          }
          variant="transparent"
          size="large"
          onClick={async () => {
            try {
              setAPILoader(true);
              await API.put(
                `orders/${order.customId}/shipments/${shipment.customId}`,
                {
                  shipmentPayload: {
                    status: 'UNLOADING_COMPLETE'
                  }
                }
              );
              await refetch();
              setAPILoader(false);
            } catch (e) {
              console.log(e);
            }
          }}
          className="h-9"
        />
      </div>
    );
  }

  if (
    shipment?.status?.key == 'ARRIVED_AT_DESTINATION' &&
    shipment?.subStatus?.key == 'UNLOADING_COMPLETE'
  ) {
    return (
      <div className="flex gap-2">
        <Button
          label={
            <span className="text-[12px] font-medium">Mark as Completed</span>
          }
          variant="transparent"
          size="large"
          onClick={async () => {
            try {
              setAPILoader(true);
              await API.put(`orders/order/${order.customId}`, {
                orderDetails: {
                  status:
                    inProgressShipments.length > 0
                      ? 'IN_PROGRESS'
                      : 'COMPLETED',
                  moveType: 'LOGISTIC'
                }
              });

              await API.put(
                `orders/${order.customId}/shipments/${shipment.customId}`,
                {
                  shipmentPayload: {
                    status: 'COMPLETED',
                    completeById: currentUser?.id,
                    completedAt: new Date()
                  }
                }
              );
              await refetch();
              setAPILoader(false);
            } catch (e) {
              console.log(e);
            }
          }}
          className="h-9"
        />
      </div>
    );
  }

  if (
    shipment?.status?.key == 'COMPLETED' &&
    shipment?.subStatus?.key == 'POD_PENDING'
  ) {
    return (
      <div className="flex gap-2">
        <Button
          label={<span className="text-[12px] font-medium">Upload PoD</span>}
          variant="transparent"
          size="large"
          onClick={async () => {
            setPodOpen(true);
          }}
          className="h-9"
        />
      </div>
    );
  }

  if (
    shipment?.status?.key === 'COMPLETED' &&
    (shipment?.subStatus?.key === 'POD_UPLOADED' ||
      shipment?.subStatus?.key === 'INVOICE_GENERATED')
  ) {
    return (
      <div className="flex gap-2">
        <Button
          label={<span className="text-[12px] font-medium">View PoD(s)</span>}
          variant="transparent"
          size="large"
          onClick={async () => {
            setPodDownloadOpen(true);
          }}
          className="h-9"
        />
      </div>
    );
  }

  return null;
};
