/*eslint-disable*/
import React, { useState, useEffect, useContext } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { decimalDisplay, SharedContext } from '../../../utils/common';
import CustomTextField from '../../../core-components/atoms/TextField';
import { NumericFormat } from 'react-number-format';
import Autocomplete from '../../../core-components/atoms/Autocomplete';
import { PRIMARY } from '../../../constants/colors';
import UomPopup from '../../revampedOrder/components/UomPopup';
import { Tooltip } from '@mui/material';

const O360GdnProductsTableRow = (props) => {
  const { row, parentIndex, rows, setRows, settleRemainingQty, GDNview } =
    props;

  const uomValue = row?.SecondaryUOM?.id
    ? {
        primary: false,
        conversionFactor:
          row?.Product?.ProductUOMs?.find(
            (u) => u.uomId == row?.SecondaryUOM?.id
          )?.conversionFactor || 1,
        ...row?.SecondaryUOM
      }
    : {
        ...row?.Product?.UOM,
        conversionFactor: 1,
        primary: true
      };
  const { organization } = useContext(SharedContext);
  const [remainingQty, setRemainingQty] = useState(0);
  const [dispatchedQty, setDispatchedQty] = useState(0);
  const [dispatchedQuantity, setDispatchedQuantity] = useState(
    rows[parentIndex].actualQuantity
  );
  const [uom, setUom] = useState(uomValue);
  const [uomView, setUomView] = useState(false);
  const [primaryUom, setPrimaryUom] = useState({
    ...row?.Product?.UOM,
    conversionFactor: 1,
    primary: true
  });
  const [secondaryUoms, setSecondaryUoms] = useState(
    row?.Product?.ProductUOMs?.map((uom) => ({
      id: uom?.id,
      name: uom?.UOM?.name,
      conversionFactor: uom?.conversionFactor,
      uomId: uom?.uomId
    }))
  );

  const uoms = [primaryUom, ...secondaryUoms];

  useEffect(() => {
    const [dispatchedQty] = row?.GDNGroupBatches?.reduce(
      (acc, obj) => {
        return [
          acc[0] +
            (+obj?.actualDispatchedQuantity || +obj?.actualQuantity || 0),
          acc[1] + (+obj?.recoverableDamageQuantity || 0)
        ];
      },
      [0, 0]
    ) || [0, 0];

    setDispatchedQty(parseFloat(dispatchedQty.toFixed(3)));
  }, [rows]);

  useEffect(() => {
    const remainingQty = row?.orderedQuantity
      ? row?.orderedQuantity - dispatchedQty
      : 0;
    setRemainingQty(remainingQty < 0 ? 0 : remainingQty.toFixed(3));
    settleRemainingQty(remainingQty < 0 ? 0 : remainingQty.toFixed(3));

    let toggleData = [...rows];

    if (!GDNview) {
      if (!uom?.primary) {
        toggleData[parentIndex].secondaryUomId = uom?.uomId || uom?.id;
        toggleData[parentIndex].secondaryUomQuantity =
          dispatchedQty / uom?.conversionFactor;
      } else {
        toggleData[parentIndex].secondaryUomId = null;
        toggleData[parentIndex].secondaryUomQuantity = null;
      }
      setRows(toggleData);
    }
  }, [dispatchedQty]);

  useEffect(() => {
    if (rows[parentIndex].actualQuantity && !dispatchedQuantity) {
      setDispatchedQuantity(rows[parentIndex].actualQuantity);
    }
  }, [props]);

  useEffect(() => {
    if (row?.secondaryUomId) {
      setUom(uoms?.find((uom) => uom?.uomId == row?.secondaryUomId));
    }
  }, [row]);

  return (
    <>
      <TableRow
        className="group"
        sx={{
          '& td': {
            // borderBottom: 0,
            paddingBottom: 3,
            paddingTop: 3
          }
        }}
      >
        <TableCell className="text-base">
          <span>
            <Tooltip title={`${row?.Product?.name}`}>
              <p className="text-base font-medium ">
                {row?.Product.name}-{row?.Product.description}
              </p>
            </Tooltip>
          </span>
          <div className="text-xs truncate">
            {row?.Product?.barcode && (
              <span>BARCODE: {row?.Product?.barcode}</span>
            )}
          </div>
        </TableCell>
        <TableCell className="text-base">
          <Autocomplete
            value={uom}
            onChange={(e, value) => {
              setDispatchedQty(
                (dispatchedQty * uom?.conversionFactor) / value.conversionFactor
              );
              setUom(value);
            }}
            options={[
              ...uoms,
              ...(!GDNview && organization?.customUOM
                ? [{ name: '+ Create UoM', type: 'add' }]
                : [])
            ]}
            sx={{
              // border: "1px solid blue",
              '& .MuiOutlinedInput-root': {
                // border: "1px solid yellow",
                borderRadius: '0',
                padding: '0'
              },
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                border: '0px solid #eee'
              }
            }}
            placeholder="UoM"
            renderOption={(props, option) => {
              // eslint-disable-next-line no-unused-vars
              const { className, ...rest } = props;
              return option?.type == 'add' ? (
                <li
                  className="auto-complete-option"
                  style={{
                    color: PRIMARY,
                    fontSize: '16px',
                    fontWeight: 500
                  }}
                  onClick={() => {
                    setUomView(true);
                  }}
                >
                  {option.name}
                </li>
              ) : (
                <li {...rest} className="auto-complete-option-small">
                  <span>
                    {option.name} {option.primary && '(primary)'}
                  </span>
                </li>
              );
            }}
            getOptionLabel={(uom) => uom.name}
          />
        </TableCell>
        <TableCell className="text-base">
          {decimalDisplay(row.orderedQuantity / (uom?.conversionFactor || 1))}
        </TableCell>
        <TableCell className="text-base">
          {props.GDNview ? (
            decimalDisplay(
              rows[parentIndex]?.GDNGroupBatches?.[0]
                ?.actualDispatchedQuantity / (uom?.conversionFactor || 1)
            )
          ) : (
            <NumericFormat
              value={dispatchedQuantity || 0}
              onValueChange={(e) => {
                let toggleData = [...rows];
                setDispatchedQuantity(e.value);
                toggleData[parentIndex].actualQuantity =
                  +e.value * (uom?.conversionFactor || 1);
                if (!toggleData[parentIndex].Product.batchEnabled) {
                  if (toggleData[parentIndex].GDNGroupBatches.length > 0) {
                    toggleData[
                      parentIndex
                    ].GDNGroupBatches[0].actualDispatchedQuantity =
                      +e.value * (uom?.conversionFactor || 1);
                  } else {
                    toggleData[parentIndex].GDNGroupBatches = [
                      // eslint-disable-next-line no-unsafe-optional-chaining
                      {
                        actualDispatchedQuantity:
                          +e.value * (uom?.conversionFactor || 1)
                      }
                    ];
                  }
                }
                setRows(toggleData);
              }}
              customInput={CustomTextField}
              placeholder="qty"
              variant="outlined"
              size="small"
              decimalScale={3}
              isAllowed={(values) => {
                const { floatValue } = values;

                const maxQuantity = Math.min(
                  row.quantity / (uom?.conversionFactor || 1),
                  row?.GDNGroupBatches?.[0]?.availableQuantity /
                    (uom?.conversionFactor || 1)
                );

                if (maxQuantity - floatValue < 0) {
                  return false;
                }
                return true;
              }}
            />
          )}
        </TableCell>
        <TableCell className="text-base">
          {decimalDisplay(remainingQty / (uom?.conversionFactor || 1))}
        </TableCell>
        {!props.GDNview && (
          <TableCell className="text-base">
            {decimalDisplay(
              row?.GDNGroupBatches?.[0]?.availableQuantity /
                (uom?.conversionFactor || 1)
            )}
          </TableCell>
        )}
      </TableRow>
      <UomPopup
        open={uomView}
        setOpen={setUomView}
        primaryUom={primaryUom}
        secondaryUoms={secondaryUoms}
        setPrimaryUom={setPrimaryUom}
        setSecondaryUoms={setSecondaryUoms}
        refetech={() => {}}
        value={{
          id: row?.Product?.id,
          isActive: true,
          batchEnabled: row?.Product?.batchEnabled
        }}
      />
    </>
  );
};

export default O360GdnProductsTableRow;
