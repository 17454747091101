/*eslint-disable*/
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { FormControl } from '@mui/material';
import CustomTextField from '../../../core-components/atoms/TextField';

const LatLngForm = ({ lng, lat, setLat, setLng }) => {
  return (
    <>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <NumericFormat
          customInput={CustomTextField}
          value={lat ?? ''}
          onValueChange={(e) => setLat(e.floatValue ?? null)}
          // label="Latitude"
          thousandSeparator=","
          decimalSeparator="."
          size="small"
          placeholder="Latitude"
        />
      </FormControl>

      <FormControl fullWidth sx={{ mb: 2 }} className="mt-4">
        <NumericFormat
          customInput={CustomTextField}
          value={lng ?? ''}
          onValueChange={(e) => setLng(e.floatValue ?? null)}
          // label="Longitude"
          thousandSeparator=","
          decimalSeparator="."
          size="small"
          placeholder="Longitude"
        />
      </FormControl>
    </>
  );
};

export default LatLngForm;
