/*eslint-disable*/
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendarIconSmall.svg';
import { FormControl } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '../../core-components/atoms/TextField';
import Autocomplete from '../../core-components/atoms/Autocomplete';

import API from '../../libs/axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { onError } from '../../libs/errorLib';
import { DEBOUNCE_CONST } from '../../Config';
import { debounce } from 'lodash';
import {
  SharedContext,
  getCurrencySymbol,
  isArabCountry,
  toSuperscript
} from '../../utils/common';
import { PRIMARY } from '../../constants/colors';
import AddcarrierPopup from '../logisticsRevamped/carrier/components/carrierPopup';
import AddVehicleTypePopup from '../logisticsRevamped/vehicleType/components/vehicleTypePopup';
import VehiclePopup from '../logisticsRevamped/vehicle/VehiclePopup';
import AddDriverPopup from '../logisticsRevamped/driver/components/driverPopup';
import { NumericFormat } from 'react-number-format';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import FLAGS from '../../constants/featureFlags';

const LandShipmentForm = ({
  formik,
  payloadWeightErr,
  setPayloadWeightErr,
  editable,
  edit,
  orders,
  setSelectedOrder,
  order,
  setOrderSearch
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const [carriers, setCarriers] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [carrierSearchKeyword, setCarrierSearchKeyword] = useState('');
  const [vehicleTypeSearchKeyword, setVehicleTypeSearchKeyword] = useState('');
  const [vehicleSearchKeyword, setVehicleSearchKeyword] = useState('');
  const [driverSearchKeyword, setDriverSearchKeyword] = useState('');
  const [selectedCarrier, setSelectedCarrier] = useState();
  const [selectedVehicleType, setVehicleType] = useState();
  const [addNewCarrier, setAddNewCarrier] = useState(false);
  const [addNewVehicleType, setAddNewVehicleType] = useState(false);
  const [addNewVehicle, setAddNewVehicle] = useState(false);
  const [addNewDriver, setAddNewDriver] = useState(false);

  const { organization, setAPILoader, settings } = useContext(SharedContext);

  const _getVehicles = async () => {
    if (formik.values?.carrier?.id && formik.values?.vehicleType?.id) {
      try {
        setAPILoader(true);
        const vehicles = await API.get(`vehicles`, {
          params: {
            search: vehicleSearchKeyword,
            carId: formik.values?.vehicleType?.id,
            companyId: formik.values?.carrier?.id
          }
        });
        setVehicles(vehicles?.data);
      } catch (err) {
        if (err?.response?.data?.message) {
          onError(err);
          return;
        }

        let errors = err?.response?.data?.error?.errors || [];

        errors.map((data) => {
          onError(data);
        });
      } finally {
        setAPILoader(false);
      }
    }
  };

  const _getVehicleTypes = async (vehicleTypeSearchKeyword) => {
    setAPILoader(true);
    const response = await API.get(`vehicles/types`, {
      params: {
        search: vehicleTypeSearchKeyword,
        carrierId: formik.values.carrier?.id
      }
    });
    setVehicleTypes(response?.data);
    setAPILoader(false);
  };

  const _getCarriers = async (carrierSearchKeyword) => {
    let colVal = {};
    // colVal = {
    //   isActive: 1
    // };
    let filters = {
      colVal
    };
    let columns = ['id', 'name'];
    try {
      setAPILoader(true);
      const vendors = await API.get(`companies`, {
        params: {
          filters,
          columns,
          search: carrierSearchKeyword,
          type: 'VENDOR',
          logisticOnly: true,
          mode: 'LAND'
        }
      });
      setCarriers(vendors?.data?.rows || []);
    } catch (err) {
      let errors = err.response.data.error.errors;
      errors.map((data) => {
        onError(data);
      });
    } finally {
      setAPILoader(false);
    }
  };

  const _getDrivers = async (driverSearchKeyword, selectedCarrier) => {
    setAPILoader(true);
    try {
      const drivers = await API.get(`drivers`, {
        params: {
          search: driverSearchKeyword,
          companyId: selectedCarrier
        }
      });
      setDrivers(drivers?.data);
    } catch (err) {
      if (err?.response?.data?.message) {
        onError(err);
        return;
      }
      let errors = err?.response?.data?.error?.errors || [];
      errors.map((data) => {
        onError(data);
      });
    }
    setAPILoader(false);
  };

  const getCarriers = useCallback(
    debounce((carrierSearchKeyword) => {
      _getCarriers(carrierSearchKeyword);
    }, DEBOUNCE_CONST),
    []
  );

  const getVehicleTypes = useCallback(
    debounce((vehicleTypeSearchKeyword) => {
      _getVehicleTypes(vehicleTypeSearchKeyword);
    }, DEBOUNCE_CONST),
    [selectedCarrier]
  );

  const getDrivers = useCallback(
    debounce((driverSearchKeyword, selectedCarrier) => {
      _getDrivers(driverSearchKeyword, selectedCarrier);
    }, DEBOUNCE_CONST),
    [formik.values.carrier]
  );

  useEffect(() => {
    getCarriers(carrierSearchKeyword);
  }, [carrierSearchKeyword]);

  useEffect(() => {
    if (vehicleTypeSearchKeyword) {
      getVehicleTypes(vehicleTypeSearchKeyword);
    } else {
      getVehicleTypes();
    }
  }, [vehicleTypeSearchKeyword, formik.values.carrier]);

  useEffect(() => {
    if (formik.values?.vehicleType?.id && formik.values?.carrier?.id) {
      _getVehicles();
    }
  }, [vehicleSearchKeyword, formik.values.carrier, formik.values.vehicleType]);

  useEffect(() => {
    if (formik.values.carrier) {
      getDrivers(driverSearchKeyword, formik.values.carrier?.id);
    }
  }, [driverSearchKeyword, formik.values.carrier]);

  useEffect(() => {
    _getVehicleTypes();
    _getCarriers();
  }, []);

  const sxProps = {
    '& .MuiOutlinedInput-input': {
      padding: '10px !important'
    },
    '& .MuiInputBase-root': {
      fontSize: '14px'
    },
    '& input::placeholder': {
      fontSize: '14px'
    },
    '& label': {
      fontSize: '14px'
    }
  };

  const listBoxProps = {
    fontSize: '14px !important'
  };

  const inputProps = {
    classes: {
      input: 'text-sm',
      textarea: 'text-sm',
      'MuiInputBase-root': 'py-2 px-4 text-sm'
    }
  };

  return (
    <div className="mt-10 number-input-container w-[600px]">
      <div className="w-full flex items-center gap-2 mb-6">
        {orders?.length > 0 && (
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              value={order}
              options={[...orders]}
              getOptionLabel={(order) => {
                return order.customId || '';
              }}
              onBlur={formik.handleBlur}
              onChange={(e, val) => {
                setSelectedOrder(val);
              }}
              onInputChange={(event, newValue) => {
                setOrderSearch(newValue);
              }}
              label="Order ID"
            />
          </FormControl>
        )}
        <FormControl fullWidth variant="outlined">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              components={{
                OpenPickerIcon: CalendarIcon
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onBlur={formik.handleBlur}
                  size="small"
                  sxProps={{
                    '& input::placeholder': {
                      fontSize: '14px !important'
                    },
                    '& input': {
                      fontSize: '14px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      height: '20px',
                      marginLeft: '8px'
                    },
                    '& label': {
                      fontSize: '14px'
                    },
                    '& .MuiIconButton-root': {
                      padding: 0
                    },
                    '& .MuiIconButton-edgeEnd': {
                      marginRight: '-6px'
                    },
                    ...(formik?.values?.pickupDate
                      ? {
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #0B1940 !important'
                          }
                        }
                      : [])
                  }}
                  placeholder="Pick Up Date"
                />
              )}
              inputFormat="dd/MM/yyyy"
              value={formik?.values?.pickupDate || null}
              label="Pick Up Date"
              onChange={(newValue) => {
                formik.setFieldValue('pickupDate', newValue);
              }}
              disabled={edit && !editable}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              components={{
                OpenPickerIcon: CalendarIcon
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onBlur={formik.handleBlur}
                  size="small"
                  sxProps={{
                    '& input::placeholder': {
                      fontSize: '14px'
                    },
                    '& input': {
                      fontSize: '14px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      height: '20px',
                      marginLeft: '8px'
                    },
                    '& label': {
                      fontSize: '14px'
                    },
                    '& .MuiIconButton-root': {
                      padding: 0
                    },
                    '& .MuiIconButton-edgeEnd': {
                      marginRight: '-6px'
                    },
                    ...(formik?.values?.dropoffDate
                      ? {
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #0B1940 !important'
                          }
                        }
                      : [])
                  }}
                  placeholder="Drop Off Date"
                />
              )}
              inputFormat="dd/MM/yyyy"
              value={formik?.values?.dropoffDate || null}
              label="Drop Off Date"
              onChange={(newValue) => {
                formik.setFieldValue('dropoffDate', newValue);
              }}
              disabled={edit && !editable}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <NumericFormat
            customInput={TextField}
            value={formik.values.carrierCost}
            onValueChange={(e) =>
              formik.setFieldValue('carrierCost', parseFloat(e.value))
            }
            label="Carrier Cost"
            thousandSeparator=","
            decimalSeparator="."
            sxProps={sxProps}
            size="small"
            InputProps={{
              endAdornment: `${getCurrencySymbol(
                organization?.defaultCurrency
              )}`
            }}
          />
        </FormControl>
      </div>
      <div className="w-full flex items-center gap-2 mb-6">
        <FormControl fullWidth variant="outlined">
          <Autocomplete
            options={
              [...carriers, { type: 'add', label: 'Add new Carrier' }] || []
            }
            onChange={async (e, value) => {
              formik.setFieldValue('carrier', value);
              formik.setFieldValue('driver', {});
              formik.setFieldValue('vehicle', {});
              formik.setFieldValue('trackingLink', null);
              setSelectedCarrier(value?.id);
              setCarrierSearchKeyword('');
            }}
            resetValue={() => {
              if (formik.values.carrier) {
                formik.setFieldValue('carrier', {});
                formik.setFieldValue('driver', {});
                formik.setFieldValue('vehicle', {});
                formik.setFieldValue('trackingLink', null);
                setSelectedCarrier(null);
                setVehicles([]);
                setCarrierSearchKeyword('');
                setDriverSearchKeyword('');
                setVehicleSearchKeyword('');
                setDrivers([]);
              }
            }}
            value={formik.values?.carrier || {}}
            inputValue={carrierSearchKeyword}
            onInputChange={(event, newValue) => {
              setCarrierSearchKeyword(newValue);
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderOption={(props, option) => {
              const { className, ...rest } = props;
              return option?.type == 'add' ? (
                <li
                  className="auto-complete-option"
                  style={{
                    color: PRIMARY,
                    fontSize: '14px',
                    fontWeight: 500
                  }}
                  onClick={() => {
                    setAddNewCarrier(true);
                  }}
                >
                  + {option.label}
                </li>
              ) : (
                <li {...rest} className="auto-complete-option-small">
                  <span>
                    {option.code || option.name ? `${option.name}` : ''}
                  </span>
                  <span style={{ color: '#67718C' }}>
                    {option?.code ? ` • ${option?.code}` : ''}
                  </span>
                </li>
              );
            }}
            label="Carrier Name"
            sxProps={{
              '& input::placeholder': {
                fontSize: '14px'
              },
              '& textarea::placeholder': {
                fontSize: '14px'
              },
              '& label': {
                fontSize: '14px'
              }
            }}
            clearOnBlur
            customInputProps={inputProps}
            listBoxProps={listBoxProps}
            disabled={edit && !editable}
          />
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <Autocomplete
            options={
              [
                ...vehicleTypes,
                { type: 'add', label: 'Add new Vehicle Type' }
              ] || []
            }
            onChange={async (e, value) => {
              formik.setFieldValue('vehicleType', value);
              formik.setFieldValue('vehicle', {});
              formik.setFieldValue('trackingLink', null);
              setVehicleType(value.id);
              setVehicleTypeSearchKeyword('');
              setVehicles([]);
            }}
            resetValue={() => {
              if (formik.values.vehicleType) {
                formik.setFieldValue('vehicleType', {});
                formik.setFieldValue('vehicle', {});
                formik.setFieldValue('trackingLink', null);
                setVehicleType(null);
                setVehicleSearchKeyword('');
                setVehicleTypeSearchKeyword('');
              }
            }}
            value={formik.values?.vehicleType || {}}
            inputValue={vehicleTypeSearchKeyword}
            onInputChange={(event, newValue) => {
              setVehicleTypeSearchKeyword(newValue);
            }}
            label="Vehicle Type"
            renderOption={(props, option) => {
              const { className, ...rest } = props;
              return option?.type == 'add' ? (
                <li
                  className="auto-complete-option"
                  style={{
                    color: PRIMARY,
                    fontSize: '14px',
                    fontWeight: 500
                  }}
                  onClick={() => {
                    setAddNewVehicleType(true);
                  }}
                >
                  + {option.label}
                </li>
              ) : (
                <li {...rest} className="auto-complete-option-small">
                  <span>
                    {option?.name
                      ? option.name
                      : option?.CarMake
                      ? `${option?.CarMake?.name || option?.CarMake?.carMake} ${
                          option?.CarModel?.name
                        } - ${option?.Category?.name}`
                      : ''}
                  </span>
                  <span style={{ marginLeft: '4px' }}>
                    ({option.vehicleCount})
                  </span>
                </li>
              );
            }}
            getOptionLabel={(option) =>
              option?.name
                ? option.name
                : option?.CarMake
                ? `${option?.CarMake?.name || option?.CarMake?.carMake} ${
                    option?.CarModel?.name
                  } - ${option?.Category?.name}`
                : ''
            }
            sxProps={{
              '& input::placeholder': {
                fontSize: '14px'
              },
              '& textarea::placeholder': {
                fontSize: '14px'
              },
              '& label': {
                fontSize: '14px'
              }
            }}
            customInputProps={inputProps}
            listBoxProps={listBoxProps}
            disabled={edit && !editable}
          />
        </FormControl>
        <FormControl fullWidth variant="outlined">
          {isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) &&
          !isFeatureEnabled(FLAGS.TMS) ? (
            <TextField
              value={formik.values.vehicleNumber}
              label="Vehicle Number"
              sxProps={sxProps}
              size="small"
              onChange={(e) =>
                formik.setFieldValue('vehicleNumber', e.target.value)
              }
              disabled={
                !formik.values.carrier?.id || !formik.values.vehicleType?.id
              }
            />
          ) : (
            <Autocomplete
              options={
                [...vehicles, { type: 'add', label: 'Add new Vehicle' }] || []
              }
              onChange={async (e, value) => {
                formik.setFieldValue('vehicle', value);
                setVehicleSearchKeyword('');
                if (value.trackingLink) {
                  formik.setFieldValue('trackingLink', value.trackingLink);
                } else {
                  formik.setFieldValue('trackingLink', null);
                }

                if (value.Driver && !formik.values.driver?.id) {
                  formik.setFieldValue('driver', value.Driver);
                }
              }}
              resetValue={() => {
                if (formik.values.vehicle) {
                  formik.setFieldValue('vehicle', {});
                  formik.setFieldValue('trackingLink', null);
                  formik.setFieldValue('driver', {});
                  formik.setFieldValue(
                    'vehicleNumber',
                    value.registrationNumber
                  );
                  setVehicleSearchKeyword('');
                }
              }}
              value={formik.values?.vehicle || {}}
              inputValue={vehicleSearchKeyword}
              onInputChange={(event, newValue) => {
                setVehicleSearchKeyword(newValue);
              }}
              renderOption={(props, option) => {
                const { className, ...rest } = props;
                return option?.type == 'add' ? (
                  <li
                    className="auto-complete-option"
                    style={{
                      color: PRIMARY,
                      fontSize: '14px',
                      fontWeight: 500
                    }}
                    onClick={() => {
                      setAddNewVehicle(true);
                    }}
                  >
                    + {option.label}
                  </li>
                ) : (
                  <li {...rest} className="auto-complete-option-small">
                    {option.registrationNumber}
                  </li>
                );
              }}
              label="Vehicle Number"
              getOptionLabel={(option) => option?.registrationNumber || ''}
              sxProps={{
                '& input::placeholder': {
                  fontSize: '14px'
                },
                '& textarea::placeholder': {
                  fontSize: '14px'
                },
                '& label': {
                  fontSize: '14px'
                }
              }}
              customInputProps={inputProps}
              listBoxProps={listBoxProps}
              disabled={
                (edit && !editable) ||
                !formik.values.carrier?.id ||
                !formik.values.vehicleType?.id
              }
            />
          )}
        </FormControl>
      </div>
      <div className="w-full flex items-center gap-2 mb-6">
        {isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) &&
        !isFeatureEnabled(FLAGS.TMS) ? (
          <FormControl fullWidth variant="outlined">
            <TextField
              value={formik.values.driverName}
              label="Driver Name"
              sxProps={sxProps}
              size="small"
              onChange={(e) =>
                formik.setFieldValue('driverName', e.target.value)
              }
              disabled={!formik.values.carrier?.id}
            />
          </FormControl>
        ) : (
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              options={
                [...drivers, { type: 'add', label: 'Add new Driver' }] || []
              }
              renderOption={(props, option) => {
                const { className, ...rest } = props;
                return option?.type == 'add' ? (
                  <li
                    className="auto-complete-option"
                    style={{
                      color: PRIMARY,
                      fontSize: '14px',
                      fontWeight: 500
                    }}
                    onClick={() => {
                      setAddNewDriver(true);
                    }}
                  >
                    + {option.label}
                  </li>
                ) : (
                  <li {...rest} className="auto-complete-option-small">
                    {`${option.name} ${
                      option?.cnicNumber ? `- ${option?.cnicNumber}` : ''
                    }`}
                  </li>
                );
              }}
              onChange={async (e, value) => {
                formik.setFieldValue('driver', value);
                formik.setFieldValue('driverName', value.name);
                formik.setFieldValue('driverPhone', value.phone);

                try {
                  setAPILoader(true);
                  const response = await API.get(`vehicles/driver/${value.id}`);
                  if (response) {
                    if (!formik.values.vehicle?.id) {
                      formik.setFieldValue(
                        'vehicleType',
                        response?.vehicle?.Car
                      );
                      setVehicleType(response.vehicle?.Car?.vehicleTypeId);
                      formik.setFieldValue('vehicle', response?.vehicle);
                      if (response?.vehicle?.trackingLink) {
                        formik.setFieldValue(
                          'trackingLink',
                          response?.vehicle?.trackingLink
                        );
                      } else {
                        formik.setFieldValue('trackingLink', null);
                      }
                    }
                    setVehicleSearchKeyword('');
                  }
                } catch (error) {
                  onError(error);
                } finally {
                  setAPILoader(false);
                }
              }}
              resetValue={() => {
                if (formik.values.driver) {
                  formik.setFieldValue('driver', {});
                  setDriverSearchKeyword('');
                }
              }}
              disabled={edit && !editable}
              value={formik.values?.driver || {}}
              inputValue={driverSearchKeyword}
              onInputChange={(event, newValue) => {
                setDriverSearchKeyword(newValue);
              }}
              getOptionLabel={(option) =>
                option?.name
                  ? `${option?.name} ${
                      option?.cnicNumber ? `- ${option?.cnicNumber}` : ''
                    }`
                  : ''
              }
              label="Driver Name"
              sxProps={{
                '& input::placeholder': {
                  fontSize: '14px'
                },
                '& textarea::placeholder': {
                  fontSize: '14px'
                },
                '& label': {
                  fontSize: '14px'
                }
              }}
              clearOnBlur
              customInputProps={inputProps}
              listBoxProps={listBoxProps}
            />
          </FormControl>
        )}
        <FormControl fullWidth variant="outlined">
          {isFeatureEnabled(FLAGS.ADVANCED_SHIPMENT_CREATION) &&
          !isFeatureEnabled(FLAGS.TMS) ? (
            <TextField
              value={formik.values.driverNumber}
              label="Driver Contact Number"
              sxProps={sxProps}
              size="small"
              onChange={(e) =>
                formik.setFieldValue('driverNumber', e.target.value)
              }
            />
          ) : (
            <TextField
              value={formik.values.driver?.phone}
              label="Driver Contact Number"
              disabled
              sxProps={sxProps}
              size="small"
            />
          )}
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <TextField
            value={formik.values.sealNumber}
            onChange={(e) => formik.setFieldValue('sealNumber', e.target.value)}
            label="Seal Number"
            sxProps={sxProps}
            size="small"
            disabled={edit && !editable}
          />
        </FormControl>
      </div>
      <div
        className={`w-full flex items-center gap-2 number-input-container ${
          payloadWeightErr ? 'mb-3' : 'mb-6'
        }`}
      >
        <FormControl fullWidth variant="outlined">
          <TextField
            value={formik.values.containerNumber}
            disabled={edit && !editable}
            onChange={(e) =>
              formik.setFieldValue('containerNumber', e.target.value)
            }
            label="Container Number"
            sxProps={sxProps}
            size="small"
          />
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <TextField
            value={formik.values.trackingLink}
            onChange={(e) =>
              formik.setFieldValue('trackingLink', e.target.value)
            }
            label="Tracking Link"
            sxProps={sxProps}
            size="small"
            disabled={edit && !editable}
          />
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <NumericFormat
            customInput={TextField}
            value={formik.values.weight || ''}
            disabled={edit && !editable}
            error={!!payloadWeightErr}
            onValueChange={(e) => {
              if (formik.values?.vehicleType) {
                const payloadWeight =
                  formik.values.vehicleType.VehicleType?.payloadWeight +
                  formik.values.vehicleType.VehicleType?.weight;
                if (+e.value > payloadWeight) {
                  setPayloadWeightErr(
                    'It is exceeding the maximum weight this vehicle type can carry.'
                  );
                } else {
                  setPayloadWeightErr(null);
                }
              }
              formik.setFieldValue('weight', parseFloat(e.value));
            }}
            label="Weight"
            placeholder="Weight"
            sxProps={sxProps}
            size="small"
            InputProps={{
              endAdornment: (
                <span className="text-xs">{settings?.weight?.symbol}</span>
              )
            }}
            thousandSeparator=","
            decimalSeparator="."
          />
        </FormControl>
      </div>

      {payloadWeightErr && (
        <div className="w-fit flex items-center mb-6 gap-2">
          <FormControl className="w-[191px]" variant="outlined"></FormControl>
          <FormControl className="w-[191px]" variant="outlined"></FormControl>
          <p className="mb-6 text-error text-xs w-[191px]">
            {payloadWeightErr}
          </p>
        </div>
      )}
      <div className="w-fit flex items-center mb-6 gap-2">
        <FormControl className="w-[191px]" variant="outlined">
          <NumericFormat
            value={formik.values.volume || ''}
            onValueChange={(e) =>
              formik.setFieldValue('volume', parseFloat(e.value))
            }
            label={`Total Volume (m${toSuperscript(3)})`}
            sxProps={sxProps}
            size="small"
            disabled={edit && !editable}
            customInput={TextField}
            thousandSeparator=","
            decimalSeparator="."
          />
        </FormControl>
        {organization.type !== 'MANUFACTURER' && (
          <FormControl className="w-[191px]" variant="outlined">
            <NumericFormat
              value={formik.values.sellingPrice || ''}
              onValueChange={(e) =>
                formik.setFieldValue('sellingPrice', parseFloat(e.value))
              }
              label="Selling Price"
              sxProps={sxProps}
              size="small"
              InputProps={{
                endAdornment: `${getCurrencySymbol(
                  organization?.defaultCurrency
                )}`
              }}
              customInput={TextField}
              thousandSeparator=","
              decimalSeparator="."
            />
          </FormControl>
        )}
      </div>

      {addNewCarrier && (
        <AddcarrierPopup
          open={addNewCarrier}
          setOpen={setAddNewCarrier}
          organizationType={organization.type}
          refetch={_getCarriers}
          shipmentOnlyScreen={true}
        />
      )}

      {addNewVehicleType && (
        <AddVehicleTypePopup
          open={addNewVehicleType}
          setOpen={setAddNewVehicleType}
          organizationType={organization.type}
          refetch={_getVehicleTypes}
        />
      )}

      {addNewVehicle && (
        <VehiclePopup
          open={addNewVehicle}
          setOpen={setAddNewVehicle}
          refetch={_getVehicles}
          organizationType={organization.type}
          selectedCarrier={formik.values.carrier}
          selectedCar={formik.values.vehicleType}
        />
      )}

      {addNewDriver && (
        <AddDriverPopup
          open={addNewDriver}
          setOpen={setAddNewDriver}
          refetch={async () =>
            await _getDrivers(driverSearchKeyword, selectedCarrier)
          }
          organizationType={organization.type}
          selectedCarrier={formik.values.carrier}
        />
      )}
    </div>
  );
};

export default LandShipmentForm;
