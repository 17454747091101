/*eslint-disable*/

import { FormControl } from '@mui/material';
import Autocomplete from '../../../core-components/atoms/Autocomplete';

const { useState, useEffect } = require('react');
const { getTransitNodes } = require('../services');

export const TransitNodeSelect = ({ value, onChange, label, mode }) => {
  const [nodes, setNodes] = useState([]);
  const [search, setSearch] = useState('');

  const fetchTransitNodes = async () => {
    try {
      const schedules = await getTransitNodes(search, mode);
      setNodes(schedules.data);
    } catch (e) {
      logError(e);
    } finally {
    }
  };

  const listBoxProps = {
    fontSize: '14px !important'
  };

  const inputProps = {
    classes: {
      input: 'text-sm',
      textarea: 'text-sm',
      'MuiInputBase-root': 'py-4 px-4 text-sm'
    }
  };

  useEffect(() => {
    fetchTransitNodes();
  }, [search]);

  return (
    <div className="w-40">
      <FormControl fullWidth variant="outlined">
        <Autocomplete
          value={value}
          onChange={(e, value) => onChange(value)}
          options={nodes}
          inputValue={search}
          onInputChange={(event, newValue) => {
            setSearch(newValue);
          }}
          getOptionLabel={(node) => node.name || ''}
          label={label}
          sxProps={{
            '& input::placeholder': {
              fontSize: '14px'
            },
            '& textarea::placeholder': {
              fontSize: '14px'
            },
            '& label': {
              fontSize: '14px'
            }
          }}
          listBoxProps={listBoxProps}
          customInputProps={inputProps}
        />
      </FormControl>
    </div>
  );
};
