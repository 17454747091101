import API from '../../libs/axios';

export const getVehicleTypes = async ({
  page,
  searchKeyword,
  filters,
  columns
}) => {
  return await API.get(`vehicles/types`, {
    params: {
      page,
      search: searchKeyword,
      filters,
      columns
    }
  });
};

export const getDrivers = async (
  { page, searchKeyword, filters, columns },
  setLoader
) => {
  setLoader(true);
  const driver = await API.get(`drivers`, {
    params: {
      page,
      search: searchKeyword,
      filters,
      columns
    }
  });
  setLoader(false);
  return driver;
};

export const getCarriers = async (
  { page, searchKeyword, filters, columns },
  setLoader
) => {
  setLoader(true);
  const carriers = await API.get(`companies`, {
    params: {
      page,
      search: searchKeyword,
      filters,
      columns,
      type: 'VENDOR'
    }
  });
  setLoader(false);
  return carriers;
};

export const getVendors = async (
  { page, searchKeyword, filters, columns },
  setLoader
) => {
  setLoader(true);
  const carriers = await API.get(`vendors`, {
    params: {
      page,
      search: searchKeyword,
      filters,
      columns
    }
  });
  setLoader(false);
  return carriers;
};
